<template>
  <search-page-layout title="상품추천 데이터 추출_적재">
    <template v-slot:searchBar>
      <product-data-sync-search-bar
        @getSearch="getSearch"
        :data="data"
      />
    </template>

    <template v-slot:table>
      <product-data-sync-table
        :data="data"
        :loading="loading"
        @dataSyncFile="dataSyncFile"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import productDataSyncSearchBar from "@/pages/Product/components/ProductDataSync/ProductDataSyncSearchBar";
import {axiosInstance} from "@/util/AxiosModuleDataSync";
import productDataSyncTable from "@/pages/Product/components/ProductDataSync/ProductDataSyncTable";
import {API_SERVER_URL_DEV, API_SERVER_URL_PROD} from "@/util/constant"
import store from "@/store";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [DateMixin],
  components: {SearchPageLayout, productDataSyncSearchBar, productDataSyncTable},
  mounted() {
    this.getId();
  },
  data() {
    return {
      data: [],
      loading: false,
      rowData: [],
      inParam: {},
      email: "",
    }
  },
  methods: {
    getSeverUrl(type) {
      return type == "prod" ? API_SERVER_URL_PROD : API_SERVER_URL_DEV;
    },
    async getId() {
      try {
        let {data} = await axiosInstance.get(this.getSeverUrl("prod") + '/admin/find-email', {
          params: {
            memberId: store.getters['login/memberId']
          }
        });
        this.email = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getSearch(inParam) {
      this.loading = true;
      this.inParam = inParam;
      this.inParam.email = this.email;

      try {
        const {data} = await axiosInstance.post(this.getSeverUrl("dev") + '/public/prdata/dataSynkList', this.inParam);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    dataSyncFile(selectData, idx, time, msg) {
      this.loading = true;
      const tb = selectData[idx].tb;
      const fileNm = tb+"@"+time+".csv";
      const url = this.getSeverUrl("dev") + "/public/prdata/dataSyncDownload"
          + "?prProdLineCd=" + this.inParam.prProdLineCd
          + "&insCd=" + this.inParam.insCd
          + "&prCd=" + this.inParam.prCd
          + "&tb=" + tb
          + "&time=" + time
          + "&email=" + this.inParam.email;

      fetch(url)
          .then(response => response.blob())
          .then(blob => {
            if(blob.size <= 136)
            {
              this.loading = false;
              alert("파일 사이즈가 너무 작습니다. 확인해 주세요.");
              return;
            }

            const file = new File([blob], fileNm, { type: blob.type });
            this.getBase64(file).then(data => {
              const uploadParam = {
                prProdLineCd: this.inParam.prProdLineCd,
                insCd: this.inParam.insCd,
                prCd: this.inParam.prCd,
                oldPrCd: this.inParam.oldPrCd,
                prTbId: tb,
                apStDate: this.getDateNoDashFormat(new Date()),
                rtWkYn: "Y",
                ulFileNm: fileNm,
                fileString: data
              }

              axiosInstance
                  .post(this.getSeverUrl("prod") + '/admin/prdata/dataSyncUpload', uploadParam)
                  .catch(() => {
                    msg = msg + tb + "테이블 처리 실패\n";
                  })
                  .finally(() => {
                    idx++;
                    if (idx < selectData.length)
                    {
                      this.dataSyncFile(selectData, idx, time, msg);
                    }
                    else
                    {
                      this.loading = false;
                      if(msg === "")
                      {
                        alert("등록 요청 하였습니다.");
                      }
                      else
                      {
                        alert(msg+"관리자에게 문의하세요.");
                      }
                    }
                  })
            });
          });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
}
</script>