<template>
  <v-col cols="12" class="py-0">
    <span class="text-h6">목록 (총 {{ data.length }}개)</span>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >

        <template v-slot:item.inviterNm="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.inviterTelNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.customerNm="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.customerTelNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.customerCarNo="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.customerCarIdentificationNo="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.createDate="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import {USER_IIMS_HEADER} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [TableMixin, CodeMixin, DateMixin],
  props:["loading", "data"],
  async created() {
  },
  data: () => ({
    headers: USER_IIMS_HEADER,
  }),
}
</script>

<style scoped>

</style>