var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"text-h6"},[_vm._v("목록 (총 "+_vm._s(_vm.data.length)+"개)")]),_c('outlined-btn',{staticClass:"float-right",attrs:{"disabled":!_vm.hasTableData},on:{"click":function($event){return _vm.$emit('toExcel')}}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.headers,"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},on:{"click:row":_vm.showModal},scopedSlots:_vm._u([{key:"item.telNum",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getTelNum(value))+" ")]}},{key:"item.sendCnt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" "),(_vm.type === 'DP')?_c('outlined-btn',[_vm._v("상세보기")]):_vm._e()]}},{key:"item.fromDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getDateDashFormat(value))+" ")]}},{key:"item.toDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getDateDashFormat(value))+" ")]}}])}),_c('content-history-detail-modal',{attrs:{"is-show":_vm.showDetailModal,"data":_vm.getData,"rowData":_vm.rowData},on:{"close":function($event){_vm.showDetailModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }