<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-flex>
      <v-card max-width="85vw" max-height="95vh" class="overflow-y-auto pa-5">
        <v-card-title class="headline">
          <v-col cols="12">
            <span class="font-weight-bold">{{ selectedUser.memberNm }} 님의 명함</span>
          </v-col>
        </v-card-title>

        <v-img
            v-if="!isImgNull"
            :src="BcFileImg"
            ref="img" width="600" height="280"
            contain
        />
        <p v-else class="empty-bizcard">
          명함 이미지가 없습니다.
        </p>
        <v-col cols="12" class="py-0">
          <v-btn
              color="red darken-1"
              class="white--text col-1 float-right ma-3"
              @click="$emit('close')"
          >
            닫기
          </v-btn>
        </v-col>
      </v-card>

    </v-flex>
  </v-overlay>
</template>

<script>
import _ from "lodash";

export default {
  props: ["isShow", "selectedUser"],

  data: () => ({
    isImgNull: true,
    BcFileImg: null
  }),

  mounted() {
    if(!_.isEmpty(this.selectedUser.businessCardImgStr)){
      this.isImgNull = false;
      this.stringToImg(this.selectedUser.businessCardImgStr)
    }
  },

  methods: {
    // imageURL -> img로 변환
    stringToImg(imgStr){
      const image = new Image();
      this.BcFileImg = image.src = imgStr;
    }
  }
}
</script>

<style scoped>

.empty-bizcard {
  width: 700px;
  height: 300px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code-text > span > strong {
  line-height: 80px;
  color: #1772C7;
  font-size: 35px;
}
</style>