<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>수정/변경</v-card-title>

      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">상품군</v-col>
          <v-select
              @click="getProList"
              @change="getprProdLine"
              v-model="dataDto.srhType"
              :items="srhTypeList"
              label="선택"
              background-color="white"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
          <v-select
              @change="getTableId"
              v-model="dataDto.insCd"
              :items="insurerCode"
              label="선택"
              background-color="white"
              item-text="value"
              item-value="cd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">상품코드</v-col>
          <v-select
              :items="prCdList"
              @change="prName"
              v-model="dataDto.prCd"
              label="선택"
              background-color="white"
              item-text="prCd"
              item-value="prCd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="3">상품명</v-col>
          <v-text-field
              v-model="dataDto.prNm"
              :disabled="true"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">표준플랜코드</v-col>
          <v-select
              @change="planCdName"
              @click="cvrCdList"
              v-model="dataDto.planCd"
              :items="planCdList"
              label="선택"
              background-color="white"
              item-text="planCd"
              item-value="planCd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">플랜명</v-col>
          <v-text-field
              v-model="dataDto.planNm"
              :disabled="true"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">표기순번</v-col>
          <v-text-field
            v-model="dataDto.orderNo"
            outlined dense hide-details
            background-color="white" menu-props="auto"
            />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">기준일자</v-col>
          <v-text-field
              v-model="dataDto.baseDt"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              placeholder="20200101"
          />
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">보험사연동여부</v-col>
          <v-select
              v-model="dataDto.insRtLinkYn"
              :items="yNlist"
              background-color="white"
              item-text="cname"
              item-value="cvalue"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">New표시여부</v-col>
          <v-select
              v-model="dataDto.newYn"
              :items="yNlist"
              background-color="white"
              item-text="cname"
              item-value="cvalue"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">판매중단여부</v-col>
          <v-select
              v-model="dataDto.closeYn"
              :items="yNlist"
              background-color="white"
              item-text="cname"
              item-value="cvalue"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">등록일시</v-col>
          <v-text-field
              v-model="dataDto.updateDate"
              :disabled="true"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="saveData"
        >
          신규등록
        </v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
export default {
  props : ['rowData'],
  data:() => ({
    dataDto: {
      'insCd':'',
      'prProdLineCd':'',
      'prProdLineNm':'',
      'updateDate':'',
      'planNm':'',
      'orderNo':'',
      'baseDt':'',
      'planCd':'',
      'prNm':'',
      'srhType':'',
      'prCd':'',
      'useYn': 'N',
      'insRtLinkYn': 'N',
      'newYn': 'N',
      'closeYn': 'N',
    },
    insurerCode: [
      {value: '선택', cd: ''}
    ],
    prCdList: [
      {prCd: '선택', prNm: ''}
    ],
    planCdList:[
      {planCd: '선택', planNm: ''}
    ],
    srhTypeList: [
      {prProdLineNm: '선택', prProdLineCd: ''}
    ],
    yNlist: [
      {cname:'Y', cvalue:'Y'},
      {cname:'N', cvalue:'N'}
    ]
  }),
  async created() {
    await this.selectCd();
  },
  watch: {
    rowData: function(value) {
      this.dataDto.prProdLineCd = value.prProdLineCd;
      this.dataDto.prProdLineNm = value.prProdLineNm;
      this.dataDto.insCd = value.insCd;
      this.dataDto.prCd = value.prCd;
      this.dataDto.prNm = value.prNm;
      this.dataDto.planCd = value.stPlanCd;
      this.dataDto.planNm = value.stPlanNm;
      this.dataDto.orderNo = value.orderNo;
      this.dataDto.baseDt = value.baseDt;
      this.dataDto.insRtLinkYn = value.insRtLinkYn;
      this.dataDto.newYn = value.newYn;
      this.dataDto.closeYn = value.closeYn;
      this.dataDto.updateDate = value.updateDate;
      this.dataDto.srhType = value.prProdLineCd;
      this.srhTypeList[0].prProdLineCd = value.prProdLineCd;
      this.srhTypeList[0].prProdLineNm = value.prProdLineNm;
      this.prCdList[0].prCd = value.prCd;
      this.prCdList[0].prNm = value.prNm;
      this.planCdList[0].planCd = value.stPlanCd;
      this.planCdList[0].planNm = value.stPlanNm;
      console.log(">>val", value);
    }
  },
  methods: {
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    prName() {
      for (let i = 0; i < this.prCdList.length; i++) {
        if (this.dataDto.prCd === this.prCdList[i].prCd) {
          this.dataDto.prNm = this.prCdList[i].prNm;
        }
      }
    },
    getprProdLine() {
      for (let i = 0; i < this.srhTypeList.length; i++) {
        if (this.dataDto.srhType === this.srhTypeList[i].prProdLineCd) {
          this.dataDto.prProdLineNm = this.srhTypeList[i].prProdLineNm;
          this.dataDto.prProdLineCd = this.srhTypeList[i].prProdLineCd;
        }
      }
    },
    planCdName() {
      for (let i = 0; i < this.planCdList.length; i++) {
        if (this.dataDto.planCd === this.planCdList[i].planCd) {
          this.dataDto.planNm = this.planCdList[i].planNm;
        }
      }
    },
    async cvrCdList() {
      const params = {
        insCd: this.dataDto.insCd,
        prCd: this.dataDto.prCd,
        srhType: "3"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        this.planCdList = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getProList() {
      try{
        const { data } = await axiosInstance.get('/publci/prdata/prProdLineNmList', {
          params: {
            insDiv : "A",
            srhType: "1"
          }
        });
        this.srhTypeList = data;
      } catch(e) {
        console.log(e);
      }
    },
    async getTableId() {
      this.planCdList = [];
      this.prCdList = [];
      const params = {
        insCd: this.dataDto.insCd,
        srhType: "2"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        this.prCdList = data;
      } catch (e) {
        console.log(e);
      }
    },
    async saveData() {
      const prProdGroupMapTbDto = {
        prProdLineCd: this.dataDto.prProdLineCd,
        prProdLineNm: this.dataDto.prProdLineNm,
        insCd: this.dataDto.insCd,
        prCd: this.dataDto.prCd,
        prNm: this.dataDto.prNm,
        stPlanCd: this.dataDto.planCd,
        stPlanNm: this.dataDto.planNm,
        orderNo: this.dataDto.orderNo,
        baseDt: this.dataDto.baseDt,
        insRtLinkYn: this.dataDto.insRtLinkYn,
        newYn: this.dataDto.newYn,
        closeYn: this.dataDto.closeYn,
        regId: store.getters['login/memberId']
      }
      try {
        await axiosInstance.post('/admin/prdata/prProdGroupMapSave', prProdGroupMapTbDto);
        alert("저장되었습니다.");
        this.dataDto = [];
        this.dataDto.useYn = 'N';
        this.dataDto.insRtLinkYn = 'N';
        this.dataDto.newYn = 'N';
        this.dataDto.closeYn = 'N';
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>