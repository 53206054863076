<template>
  <v-form class="search-form">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex col-xl-2 col-lg-3 col-md-3 col-6">
          <v-select
              label="상품군"
              v-model="prProdLineCd"
              :items="prProdLineList"
              background-color="white"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              outlined
          />
        </v-col>
        <v-col class="d-flex col-xl-2 col-lg-3 col-md-3 col-6">
          <v-select
              label="보험사"
              v-model="insCd"
              :items="insCdList"
              background-color="white"
              item-text="insCdNm"
              item-value="insCd"
              outlined
          />
        </v-col>
        <v-col class="d-flex col-xl-4 col-lg-6 col-md-6 col-12">
          <v-select
              label="상품코드"
              @click="getPrCdList"
              v-model="prCd"
              :items="prCdList"
              background-color="white"
              item-text="prCdNm"
              item-value="prCd"
              outlined
          />
        </v-col>
        <v-col class="d-flex col-xl-2 col-lg-4 col-md-4 col-4">
          <v-select
              label="게시판 유형"
              v-model="boardType"
              :items="boardTypeList"
              background-color="white"
              item-text="boardTypeNm"
              item-value="boardType"
              outlined
          />
        </v-col>
        <v-col class="d-flex col-xl-2 col-lg-4 col-md-4 col-4">
          <v-select
              label="사용자 범위"
              v-model="userRange"
              :items="userRangeList"
              background-color="white"
              item-text="userRangeNm"
              item-value="userRange"
              outlined
          />
        </v-col>
        <v-col class="d-flex col-xl-2 col-lg-4 col-md-4 col-4">
          <v-select
              label="회원가입 경과상태"
              v-model="elapsedState"
              :items="elapsedStateList"
              background-color="white"
              item-text="elapsedStateNm"
              item-value="elapsedState"
              outlined
          />
        </v-col>
        <v-col class="d-flex col-xl-2 col-lg-8 col-md-8 col-12">
          <v-text-field
              label="제목/내용"
              v-model="titleContent"
              background-color="white"
              outlined
          />
        </v-col>
        <v-col class="d-flex col-xl-2 col-lg-4 col-md-4 col-12">
          <custom-date-picker
              :start-date="startDate"
              :end-date="endDate"
              @updateDate="updateDate"
          />
        </v-col>

        <v-col cols="12" class="search-button">
          <v-btn @click="getSearch">조회</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DateMixin from "@/mixin/DateMixin";
import ProductRecommend from "@/util/ProductRecommend";
import CustomDatePicker from "@/components/Form/CustomDatePicker";
import store from "@/store";

export default {
  name: 'ProductRecommendBoardSearchBar',
  mixins: [DateMixin],
  components: {CustomDatePicker},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  async created() {
    this.prProdLineList = await ProductRecommend.getPrProdLineCd();
    this.insCdList = await ProductRecommend.getInsCd();
  },
  data() {
    return {
      startDate: '', endDate: '',
      prProdLineCd: '', prProdLineList: [],
      insCd: '', insCdList: [],
      prCd: '', prCdList: [],
      boardType: 'AL', boardTypeList: [
        { boardTypeNm: '전체', boardType: 'AL' },
        { boardTypeNm: '일반', boardType: 'GE' },
        { boardTypeNm: '이벤트', boardType: 'EV' },
        { boardTypeNm: '이벤트 퀴즈', boardType: 'EQ' },
        { boardTypeNm: 'FAQ', boardType: 'QA' },
      ],
      userRange: 'AL', userRangeList: [
        { userRangeNm: '전체', userRange: 'AL' },
        { userRangeNm: '원클릭', userRange: 'ON' },
        { userRangeNm: '청신호', userRange: 'GR' },
        { userRangeNm: '인카', userRange: 'IC' },
        { userRangeNm: '타FA', userRange: 'OF' },
        { userRangeNm: '제휴회사', userRange: 'AC' },
      ],
      elapsedState: 'AL', elapsedStateList: [
        { elapsedStateNm: '전체', elapsedState: 'AL' },
        { elapsedStateNm: '가입1주이내', elapsedState: 'W1' },
        { elapsedStateNm: '가입1달이내', elapsedState: 'M1' },
        { elapsedStateNm: '가입1년이내', elapsedState: 'Y1' },
        { elapsedStateNm: '가입1년이상', elapsedState: 'YF' },
      ],
      titleContent: ''
    }
  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    async getPrCdList() {
      this.prCdList = [];
      this.prCdList = await ProductRecommend.getPrCdList(this.prProdLineCd, this.insCd, 'Y');
      if (this.prCdList.length < 1) {
        this.prCdList.unshift({
          prNm: "없음",
          prCd: "",
          prCdNm: "없음"
        })
      }
    },
    getSearch() {
      const params = {};
      params.boardType = this.boardType;
      params.userRanges = this.userRange;
      params.elapsedStatus = this.elapsedState;
      params.srchTxt = this.titleContent;
      params.startDate = this.startDate.replace(/[^0-9]/gi, "");
      params.endDate = this.endDate.replace(/[^0-9]/gi, "");
      params.memberId = store.getters['login/memberId'];
      params.prProdLineCd = this.prProdLineCd;
      params.insCd = this.insCd;
      params.prCd = this.prCd;
      this.$emit('getSearch', params);
    }
  }
}
</script>

<style scoped>
.search-form {
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid #d5d5d5;
}
.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>