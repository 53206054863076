import _ from "lodash";

export default {
    props: ["value", "header", "id", "rules"],
    data: () => ({
        backup: "",
        valid: false,
        isEdit: false,
        text: ""
    }),

    methods: {
        updateColumn(text, id, header) {
            if(this.valid) this.$emit('update', text, id, header);
            else text = this.backup;
        },
        setIsEdit() {
            this.backup = _.cloneDeep(this.text);
            this.isEdit = true;
        },
        edit() {
            if(this.backup !== this.text) this.updateColumn(this.text, this.id, this.header);
            this.isEdit = false;
        }
    }
}