<template>
  <search-page-layout title="이벤트 대상자">

    <template v-slot:searchBar>
      <event-lottery-search-bar
          @search="getUserSearch"
          @change="changeType"
          @changeBaseYM="changeBaseYM"
      />
    </template>

    <template v-slot:table>
      <event-lottery-search-table
          @toExcel="toExcel"
          :data="data"
          :loading="loading"
          :type="type"
          :baseYM="baseYM"
          @updateData="updateData"
      />
    </template>

  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import EventLotterySearchBar from "@/pages/User/components/EventLottery/EventLotterySearchBar";
import EventLotterySearchTable from "@/pages/User/components/EventLottery/EventLotterySearchTable";
import _ from "lodash";
import func from "@/util/func";

export default {
  components: {
    EventLotterySearchBar,
    EventLotterySearchTable,
    SearchPageLayout
  },
  data: () => ({
    data: [],
    loading: false,
    type: "FIRST_CONTRACT",
    baseYM:""
  }),
  methods: {
    async getUserSearch(params) {
      this.loading = true;
      this.baseYM = params.baseYM;
      try {
        const {data} = await axiosInstance.post('/admin/operation/eventLotteryList', params);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    changeType(param) {
      this.data = [];
      this.type = param;
    },
    changeBaseYM(baseYM) {
      this.data = [];
      this.baseYM = baseYM;
    },
    toExcel(header) {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {};
        for(var i = 0; i < header.length; i++)
        {
          if (this.type === "KATALK_FRIEND") {
            if (item[header[i][1]] !== null) {
              if ((header[i][1] === "regDate1") || (header[i][1] === "designSupDate")) {
                item[header[i][1]] = item[header[i][1]].replace('T', ' ').substr(0, 10);
              }
            }
          }
          obj[header[i][0]] = item[header[i][1]];
        }
        returnData.push(obj);
      }

      var title = "";
      if("FIRST_CONTRACT" == this.type)
      {
        title = "최초계약_이벤트_";
      }
      else if("INVITER" == this.type)
      {
        title = "추천인_피추천인_이벤트_";
      }
      else if("CONTINUOUS_OPERATION" == this.type)
      {
        title = "연속가동_리스트_";
      }
      else if("CONVERT_MEMBER" == this.type)
      {
        title = "정회원_전환_";
      }
      else if("MISILJEUK_LINK_SEND" == this.type)
      {
        title = "미실적자_링크전달_이벤트_";
      }
      else if("QUIZ_CONTESTANT" == this.type)
      {
        title = "퀴즈_이벤트_";
      }
      else if("PRODUCT_RECOMMEND" == this.type)
      {
        title = "상품추천_이벤트_";
      }
      title = title + this.baseYM;

      const sheetName = title;
      const fileName = title;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    updateData() {
      if (_.isEmpty(this.paramsData)) return;
      this.getUserSearch(this.paramsData);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/noto";
</style>