<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-flex>
      <v-card max-width="90vw" max-height="90vh" class="overflow-y-auto pdf">
        <search-page-layout title="상품플랜 상세">
          <template v-slot:searchBar>
            <detail-modal-search-bar
              :rowData="rowData"
              @getSearch="getSearch"
            />
          </template>

          <template v-slot:table>
            <detail-modal-table
              :data="data"
              :loading="loading"
              @detailRow="detailRow"
            />
          </template>

          <template v-slot:inflowHist>
            <detail-modal-save
              :detailRowData="detailRowData"
              :rowData="rowData"
              @close="close"
            />
          </template>
        </search-page-layout>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import DetailModalSearchBar from "@/pages/Product/components/ProductPlanManagement/DetailModal/DetailModalSearchBar";
import DetailModalTable from "@/pages/Product/components/ProductPlanManagement/DetailModal/DetailModalTable";
import DetailModalSave from "@/pages/Product/components/ProductPlanManagement/DetailModal/DetailModalSave";

export default {
  components: {DetailModalSave, DetailModalTable, DetailModalSearchBar, SearchPageLayout},
  props: ["rowData", "isShow"],
  data() {
    return {
      data: [],
      loading: false,
      detailRowData: [],
    }
  },
  methods: {
    async getSearch(params) {
      this.loading = true;

      try {
        const { data } = await axiosInstance.get('/private/prdata/prPlanDetailList', { params });
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].createDate = this.data[i].createDate.replace('T', ' ').substr(0, 19);
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    close() {
      this.data = [];
      this.$emit("close");
    },
    detailRow(row) {
      this.detailRowData = row;
    },

  }
}
</script>