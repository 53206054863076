<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <field-input title="구분">
          <v-select
              :items="SERVICE_TYPE"
              background-color="white"
              v-model="type" menu-props="auto"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="보험사 유입일시">
          <v-col class="paddCol">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </field-input>
        <field-input title="보험사">
        <v-select
            v-model="searchType1"
            :items="insurerCd"
            background-color="white"
            item-text="uname"
            item-value="uvalue"
            hide-details outlined dense
        />
        </field-input>
      </search-area>

      <v-divider class="mb-2"/>

      <search-area title="고객정보">
        <field-input title="고객명">
          <v-text-field
              v-model="customerNm"
              @keypress.enter="getInflowList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="고객 전화번호">
          <v-text-field
              v-model="customerTelNum"
              @keypress.enter="getInflowList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="차량번호">
          <v-text-field
              v-model="customerCarNo"
              @keypress.enter="getInflowList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>

      <v-divider class="mb-2"/>

      <search-area title="회원정보">
        <field-input title="회원명">
          <v-text-field
              v-model="inviterNm"
              @keypress.enter="getInflowList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="회원 전화번호">
          <v-text-field
              v-model="inviterTelNum"
              @keypress.enter="getInflowList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="갱신타겟팅">
          <v-radio-group v-model="campaignYn" row>
            <v-radio label="Y" value="Y"/>
            <v-radio label="N" value="N"/>
          </v-radio-group>
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getInflowList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, FieldInput},
  props: ["name"],
  async created() {
    this.SERVICE_TYPE = await this.getSelectList('SERVICE_TYPE');
    if(this.name) {
      this.inviterNm = this.name;
      await this.getInflowList();
    }
  },
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate.substring(0,8)+'01';
    this.endDate = toDate;
    this.searchType1 = 'ALL';
  },
  data: () => ({
    SERVICE_TYPE: [],
    startDate: "",
    endDate: "",
    type: "ALL",
    customerNm: "",
    customerTelNum: "",
    customerCarNo: "",
    inviterNm: "",
    inviterTelNum: "",
    insurerCd: [
      {uname: '전체', uvalue: 'ALL'},
      {uname: '현대해상', uvalue: 'HD'},
      {uname: 'DB손해보험', uvalue: 'DB'},
      {uname: 'KB손해보험', uvalue: 'KB'},
      {uname: '하나손해보험', uvalue: 'HN'},
      {uname: '흥국화재', uvalue: 'HK'},
      {uname: '캐롯손해보험', uvalue: 'CR'},
      {uname: '한화손해보험', uvalue: 'HW'},
      {uname: '롯데손해보험', uvalue: 'LO'},
      {uname: 'AXA손해보험', uvalue: 'AXA'},
      {uname: '메리츠화재', uvalue: 'MZ'},
      {uname: '삼성화재', uvalue: 'SS'},
      {uname: 'MG손해보험', uvalue: 'MG'},
    ],
    campaignYn: "N"
  }),
  computed: {
    isDisabled() {
      return (
          // 시작일 말고 종료일은 무조건 필수여야함
          // 종료일 없는 경우 1. 시작일만 설정하고 date picker 종료 2. 시작일부터 종료일 까지가 31일 초과일떄
          !this.endDate ||
          // 컬럼 중 하나 이상은 입력되어야함
          (!this.customerNm
              && !this.customerCarNo
              && !this.customerTelNum
              && !this.inviterNm
              && !this.inviterTelNum)
      );
    },
    isEnter() {
      return (
          !!this.endDate
      );
    }
  },
  methods: {
    getInflowList() {
      if (!this.isEnter) return;

      const params = {
        "searchType": this.type,
        "startDate": this.startDate.replaceAll('-', ''),
        "endDate": this.endDate.replaceAll('-', ''),
        joinInsurerCd : this.searchType1,
        campaignYn: this.campaignYn
      };
      if (this.customerNm) params['customerNm'] = this.customerNm;
      if (this.customerCarNo) params['customerCarNo'] = this.customerCarNo.replace(/\s/g, '');
      if (this.customerTelNum) params['customerTelNum'] = this.customerTelNum.replace(/[^0-9]/gi, "");
      if (this.inviterTelNum) params['inviterTelNum'] = this.inviterTelNum.replace(/[^0-9]/gi, "");
      if (this.inviterNm) params['inviterNm'] = this.inviterNm;

      this.$emit('search', params);
    },
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    }
  }
}
</script>


<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
.paddCol {
  padding: 0;
}
</style>
