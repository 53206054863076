<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
    <v-flex>
      <v-card max-width="70vw" max-height="90vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">점검결과 확인 상세</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>

        <v-form v-model="valid">
          <v-card-text >
            <v-row class="px-6">
              <v-col :class="inputClass">
                <span class="subtitle-1">ID</span>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense
                    v-model="settlementDto.carSettlementHistId"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">서비스구분</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.serviceType"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">리워드타입</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.rewardType"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">추천인명</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.recipientNm"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">추천인 전화번호</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.recipientTelNum"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">계약일</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.contractDate"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">회원명</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.customerNm"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">회원 전화번호</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.customerTelNum"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">차량번호</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.customerCarNo"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">상태</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.rewardStatus"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">보험사</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.contractInsurer"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">계약채널</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.joinChannel"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">계약보험료</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.contractPremAmt"
                    :disabled="true"
                    dir="rtl"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">리워드</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.rewardAmt"
                    :disabled="true"
                    dir="rtl"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">지급방법</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.payMethodNm"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">만기일</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.expiryDate"
                    :disabled="true"
                />
              </v-col>
              <v-col class="inputClass">
                <span class="subtitle-1">지급예정일</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.payDueDate"
                    :disabled="true"
                />
              </v-col>
              <v-col class="inputClass">
                <span class="subtitle-1">지급완료일</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.payCompleteDate"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">점검자</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.checkerNm"
                    :disabled="true"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">확인일시</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.updateDate"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex flex-column col-6">
                <span class="subtitle-1">점검결과</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.checkReson"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex flex-column col-6">
                <span class="subtitle-1">점검확인 내용</span>
                <v-text-field
                    background-color="white"
                    outlined dense
                    v-model="settlementDto.checkerOpinion"
                />
              </v-col>
            </v-row>

          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="submit"
          >저장
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="$emit('close')"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>

import CodeMixin from "@/mixin/CodeMixin";
import {axiosInstance} from "@/util/AxiosModule";
import TableMixin from "@/mixin/TableMixin";
import store from "@/store";
import _ from "lodash";

export default {
  mixins: [CodeMixin, TableMixin],
  props: ["isShow", "settlementData", "isModify"],
  data: () => ({
    inputClass: 'd-flex flex-column col-md-3 col-sm-6 col-12',
    valid: false,
    settlementDto: {
      "carSettlementHistId": "",
      "checkResult": "",
      "checkResultYn": "",
      "checkYn": "",
      "contractDate": "",
      "contractInsurer": "",
      "contractPremAmt": "",
      "customerCarNo": "",
      "customerNm": "",
      "customerTelNum": "",
      "expiryDate": "",
      "insuredCarNo": "",
      "insuredNm": "",
      "insuredTelNum": "",
      "joinChannel": "",
      "payCompleteDate": "",
      "payDueDate": "",
      "payMethodNm": "",
      "recipientNm": "",
      "recipientTelNum": "",
      "remark": "",
      "rewardAmt": "",
      "rewardStatus": "",
      "rewardType": "",
      "serviceType": "",
      "checkerOpinion": "",
      "checkerId": "",
      "updateDate": "",
      "checkReson":"",
      "checkerNm": ""
    }
  }),
  watch: {
    settlementData: function(obj) {
      this.settlementDto = obj;
      this.settlementDto.checkReson = obj.checkResult;
      this.settlementDto.checkerId = store.getters['login/memberId'];
      this.settlementDto.recipientTelNum = this.getTelNum(obj.recipientTelNum);
      this.settlementDto.customerTelNum = this.getTelNum(obj.customerTelNum);
      this.settlementDto.contractPremAmt = this.inputComma(obj.contractPremAmt);
      this.settlementDto.rewardAmt = this.inputComma(obj.rewardAmt);
      if (!_.isEmpty(obj.updateDate)){
        const update = obj.updateDate
        this.settlementDto.updateDate = update.substring(0, 10);
      }
    }
  },
  methods: {
    async submit() {
      if(confirm("데이터를 저장하시겠습니까?")) {
        if(_.isEmpty(this.settlementDto.checkerOpinion) || this.settlementDto.checkerOpinion.length < 2){
          alert('점검확인 내용을 세 글자 이상 입력해주세요.');
          return;
        }else {
          this.savePartner();
        }
      }else{
        return;
      }
      this.$emit('close');

    },
    // 점검 확인 내용 저장
    async savePartner() {
      let CarSettleCheckOpinion = {};
      CarSettleCheckOpinion.carSettlementHistId = this.settlementDto.carSettlementHistId;
      CarSettleCheckOpinion.checkReson = this.settlementDto.checkReson;
      CarSettleCheckOpinion.checkerId = store.getters['login/memberId'];
      CarSettleCheckOpinion.checkerOpinion = this.settlementDto.checkerOpinion;

      try{
        await axiosInstance.post('/admin/settlement/check_opinion', CarSettleCheckOpinion);
        alert('저장되었습니다.');
        this.$emit('update');
      } catch(e) {
        console.log(e);
      }
    },
    getTelNum(telNum) {
      if(!telNum) return "";
      return telNum.length === 11 ? `${telNum.slice(0, 3)}-${telNum.slice(3, 7)}-${telNum.slice(7)}` : telNum;
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>

<style scoped>

</style>