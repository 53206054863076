<template>
  <search-layout title="코드 관리">
    <template v-slot:searchBar>
      <search-bar @search="getCodeList" />
    </template>

    <template v-slot:table>
      <v-col cols="12" class="py-0">
        <v-col cols="12" class="d-flex pa-0">
          <span class="text-h6 font-weight-bold">검색 결과</span>
          <v-btn
              @click="$router.push({name: 'CreateCode'})"
              class="ml-auto primary"
          >코드 생성</v-btn>
        </v-col>
        <code-table
          :isMaster="true"
          :items="data"
          :headers="headers"
        />
      </v-col>
    </template>
  </search-layout>
</template>

<script>
import SearchLayout from "@/layouts/SearchPageLayout";
import CodeTable from "@/pages/Manage/Code/components/common/CodeTable";
import { mapActions, mapGetters } from "vuex"
import { SEARCH_CODE_HEADER } from "@/util/TableHeaders";
import {axiosInstance} from "@/util/AxiosModule";
import SearchBar from "@/pages/Manage/Code/components/SearchCode/SearchBar";

export default {
  components: {
    SearchBar,
    CodeTable,
    SearchLayout
  },
  computed: {
    ...mapGetters("code", {
      getSearchType: "searchType"
    })
  },
  async created() {
    // searchType 이 있다면 할당, 없다면 'ALL'
    const type = this.getSearchType || 'ALL';
    await this.getCodeList(type)
  },
  data: () => ({
    headers: SEARCH_CODE_HEADER,
    data: [],
    condition: "ALL"
  }),
  methods: {
    ...mapActions("code", ["setSearchType"]),
    async getCodeList(type) {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-master', { params: { type } });

        this.setSearchType(type);

        this.data = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>