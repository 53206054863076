<template>
  <search-layout title="이미지&PDF 테스트">
    <template v-slot:searchBar>
      <search-bar @search="download"/>
    </template>

    <template v-slot:table>
      <v-col ref="cola" cols="12" class="py-0">
        <v-col cols="12" class="d-flex pa-0">
          <span class="text-h6 font-weight-bold">다운로드 대상</span>
        </v-col>
        <v-col cols="12">
          111
          <v-img
              max-width="70" max-height="70" src="@/assets/ain.png"
          />
        </v-col>
        <v-col cols="12">
          222
          <v-img
              max-width="70" max-height="70" src="@/assets/ain.png"
          />
        </v-col>
        <v-col cols="12">
          333
          <v-img
              max-width="70" max-height="70" src="@/assets/ain.png"
          />
        </v-col>

      </v-col>
    </template>
  </search-layout>
</template>

<script>
import SearchLayout from "@/layouts/SearchPageLayout";
import SearchBar from "@/pages/Manage/Test/components/ImgPdfSearchBar";
import { jsPDF } from "jspdf";

export default {
  components: {
    SearchBar,
    SearchLayout
  },
  computed: {
  },
  async created() {
  },
  data: () => ({
  }),
  methods: {
    download(params) {
      //DIV 만들기
      let tempDiv = document.createElement("div");
      for (let i=0; i <= params.cnt; i++){
        let tag = this.$refs.cola.childNodes[i].cloneNode(true);
        tempDiv.append(tag);
      }
      document.body.appendChild(tempDiv);

      //분기
      if(params.type == "1")
      {
        //이미지 다운로드
        this.imgDownload(tempDiv);
      }
      else if(params.type == "2")
      {
        //PDF 다운로드
        this.pdfDownload(tempDiv);
      }
    },
    //ING만들고 다운로드
    imgDownload(tempDiv){
     window.html2canvas(tempDiv).then(function(canvas){
        let uri = canvas.toDataURL();
        var link = document.createElement("a");
        link.download = "tempImg.png";
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        link.remove();
        tempDiv.remove();
      });
    },
    //PDF만들고 다운로드
    pdfDownload(tempDiv){
      window.html2canvas(tempDiv).then(function(canvas){
        var doc = new jsPDF('p', 'mm', 'a4'); //jspdf객체 생성
        var imgData = canvas.toDataURL('image/png'); //캔버스를 이미지로 변환
        doc.addImage(imgData, 'PNG', 0, 0); //이미지를 기반으로 pdf생성
        doc.save('sample-file.pdf'); //pdf저장
        tempDiv.remove();
      });
    }
  }
}
</script>

