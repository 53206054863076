<template>
  <search-page-layout title="프로그램 권한 등록 관리">
    <template v-slot:searchBar>
      <program-privilege-search-bar
          @getProgramPrivilege="getProgramPrivilege"
          :prvList="privilegeGroup"
      />
    </template>

    <template v-slot:table>
      <program-privilege-table
          :data="data"
          :loading="loading"
          @selectRow="selectRow"
          :prvList="privilegeGroup"
      />
    </template>

    <template v-slot:inflowHist>
      <program-privilege-save
          :key="saveKey"
          :rowData="rowData"
          @refresh="refresh"
          :prvList="privilegeGroup"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {mapGetters} from "vuex";
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProgramPrivilegeSearchBar from "@/pages/Manage/ProgramPrivilege/ProgramPrivilegeSearchBar";
import ProgramPrivilegeTable from "@/pages/Manage/ProgramPrivilege/ProgramPrivilegeTable";
import ProgramPrivilegeSave from "@/pages/Manage/ProgramPrivilege/ProgramPrivilegeSave";

export default {
  name: "ProgramPrivilege",
  components: {
    ProgramPrivilegeSearchBar,
    ProgramPrivilegeTable,
    ProgramPrivilegeSave,
    SearchPageLayout,
  },
  computed: {
    ...mapGetters("login", ["privilegeGroup"]),
  },
  data: () => ({
    data: [],
    loading: false,

    saveKey: 0,
    rowData: [],
    refreshParams: {}
  }),

  methods: {

    async getProgramPrivilege(params) {
      this.loading = true;
      this.refreshParams = params;

      try {
        const {data} = await axiosInstance.get('/admin/operation/getProgramPrivilege', { params });
        this.data = this.setPrvGroupTable(data);
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    // "권한그룹" String -> JSON 변환 후 append
    setPrvGroupTable(data) {
      for (let i in data) {
        data[i] = {...data[i], ...JSON.parse(data[i].prvGroupLst)}
      }
      return data;
    },

    selectRow(row) {
      this.rowData = row;
      this.saveKey++;
    },

    refresh(){
      this.getProgramPrivilege(this.refreshParams);
      // 신규 등록으로 인한 상단 메뉴 최신화
      this.$store.dispatch("login/setPersonalMenuList", this.$store.getters['login/memberId']);
    }

  }
}
</script>
