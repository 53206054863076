<template>
  <v-app id="inspire">
    <!-- 테이블 고정 헤드(App.vue 파일 스타일 참고)와 z-index 값이 3으로 동일해 -->
    <!-- GNB 의 z-index 를 4로 수정 -->
    <v-system-bar
        app window
        class="pa-9 elevation-3"
        style="z-index:4; background-color:#f0f0f0;"
        v-if="menuUpdateCnt > 0"
    >
<!--      <v-app-bar-nav-icon class="acd_menu" @click="$refs.navBar.drawer = !$refs.navBar.drawer" />-->
      <top-bar :key="topKey"></top-bar>
      <v-app-bar-nav-icon
          class="acd_menu"
          @click="$refs.navBar.drawer = !$refs.navBar.drawer"
      />
    </v-system-bar>
    <v-main v-if="menuUpdateCnt > 0">
        <nav-bar ref="navBar" :key="navKey"/>
        <router-view style="margin-top: 10px"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/pages/Main/components/Top";
import NavBar from "@/pages/Main/components/NavBar";
// import Footer from "@/pages/Main/components/Footer";
import {mapGetters} from "vuex";

export default {
  components: {
    TopBar,
    NavBar,
    // Footer
  },
  computed: {
    ...mapGetters("login", ["menuUpdateCnt"])
  },

  data: () => ({
    topKey: 0  + 't',
    navKey: 0  + 'n',
  }),

  watch: {
    menuUpdateCnt: function () {
      this.topKey++;
      this.navKey++;
    }
  },
}
</script>

<style scoped>

@media screen and (min-width: 1300px) {
  .acd_menu {
    display: none;
  }
}
</style>