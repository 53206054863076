<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="">
        <field-input title="처리구분">
          <v-radio-group
              v-model="searchData.type"
              row
          >
            <v-radio label="IMG"  value="1"/>
            <v-radio label="PDF" value="2"/>
          </v-radio-group>
        </field-input>

        <field-input title="처리개수">
          <v-radio-group
              v-model="searchData.cnt"
              row
          >
            <v-radio label="1개"  value="1"/>
            <v-radio label="2개" value="2"/>
            <v-radio label="3개" value="3"/>
          </v-radio-group>
        </field-input>

      </search-area>
    </template>
    <template v-slot:button>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
            @click="getSearch"
            color="grey" class="white--text px-10"
        >
          다운로드
        </v-btn>
      </v-col>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import FieldInput from "@/components/Form/FieldInput";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [CodeMixin],
  components: {FieldInput, SearchArea, SearchBarLayout},
  async created() {
  },
  data: () => ({
    searchData: {
      type:"1",
      cnt:"1"
    }
  }),
  methods: {
    async getSearch() {
      this.$emit('search', this.searchData);
    }
  }
}
</script>
