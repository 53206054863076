<template>
  <search-layout>
    <template v-slot:searchBar>
      <linked-security-detail-search
          :detailDto="detailDto"
        />
    </template>
    <template v-slot:table>
      <linked-security-detail-table
          :data="data"
          :detailDto="detailDto"
        />
    </template>
  </search-layout>
</template>
<script>
import SearchLayout from "../../../layouts/SearchLayout";
import {axiosInstance} from "@/util/AxiosModule";
import LinkedSecurityDetailSearch from "./components/LinkedSecurityDetailSearch";
import LinkedSecurityDetailTable from "./components/LinkedSecurityDetailTable";
export default {
  components: {LinkedSecurityDetailTable, LinkedSecurityDetailSearch, SearchLayout},
  props:["detailDto"],
  data:() => ({
    data:[]
  }),
  watch: {
    detailDto: function () {
      this.getList();
    }
  },
  methods: {
    async getList() {
      const params = {
        insCd: this.detailDto.insCd,
        prCd: this.detailDto.prCd,
        planCd: this.detailDto.planCd,
        lkCvrDivCd: this.detailDto.lkCvrDivCd
      }
      try{
        const { data } = await axiosInstance.get('/admin/prdata/prTakeLkCvrDivTypeList', { params });
        this.data = data;
        //인덱스 값 추가
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].index = i;
          this.data[i].createDate = this.data[i].createDate.replace('T', ' ').substr(0, 19);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>