<template>
  <search-page-layout title="신정원 담보코드 매핑현황">
    <template v-slot:searchBar>
      <cre-cvr-mapping-status-search-bar
        @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <cre-cvr-mapping-status-table
        :data="data"
        :loading="loading"
        @row="row"
      />
    </template>

    <template v-slot:inflowHist>
      <cre-cvr-mapping-status-save
        :rowData="rowData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import CreCvrMappingStatusSearchBar from "@/pages/Product/components/CreCvrMappingStatus/CreCvrMappingStatusSearchBar";
import CreCvrMappingStatusTable from "@/pages/Product/components/CreCvrMappingStatus/CreCvrMappingStatusTable";
import CreCvrMappingStatusSave from "@/pages/Product/components/CreCvrMappingStatus/CreCvrMappingStatusSave";

export default {
  props:["isShow"],
  components: {CreCvrMappingStatusSave, CreCvrMappingStatusTable, CreCvrMappingStatusSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      rowData: [],
    }
  },
  methods: {
    async getSearch(params) {
      try {
        const { data } = await axiosInstance.get('/private/prdata/prCreCvrList', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
    },
    row(row) {
      this.rowData = row;
    }
  }
}
</script>