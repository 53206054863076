<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-flex>
      <v-card max-width="65vw" max-height="95vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">원수사 데이터 등록</span>
        </v-card-title>
        <v-card-text>
          <v-row class="px-6">
            <v-col class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <span class="subtitle-1">보험사</span>
              <v-select
                  @click="selectCd"
                  v-model="dataDto.insCd"
                  :items="insurerCode"
                  background-color="white"
                  item-text="value"
                  item-value="cd"
                  class="subBar"
                  hide-details outlined dense
              />
            </v-col>
            <v-col  class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <span class="subtitle-1">수신데이터(영문)</span>
              <v-text-field
                v-model="dataDto.egName"
                outlined dense
                background-color="white"
                placeholder="영문명을 입력해주세요."
                />
            </v-col>
            <v-col  class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <span class="subtitle-1">적용일자</span>
              <v-text-field
                  v-model="dataDto.applyDate"
                  outlined dense
                  background-color="white"
                  placeholder="20220101"
              />
            </v-col>
          </v-row>
          <v-row class="px-6">
            <v-col class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
              <span class="subtitle-1">데이터 설명</span>
              <v-text-field
                  v-model="dataDto.dataDescription"
                  outlined dense
                  background-color="white"
                  placeholder="데이터 설명을 해주세요."
              />
            </v-col>
            <v-col  class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
              <span class="subtitle-1">파일형태</span>
              <v-text-field
                  v-model="dataDto.fileType"
                  outlined dense
                  background-color="white"
                  placeholder="xlsx, csv"
              />
            </v-col>
            <v-col  class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
              <span class="subtitle-1">열구분자</span>
              <v-text-field
                  :disabled="dataDto.fileType !== 'csv'"
                  v-model="dataDto.delimiter"
                  outlined dense
                  background-color="white"
                  placeholder="csv 파일 형식일때만 입력"
              />
            </v-col>
            <v-col class="d-flex col-xl-3 col-lg-3 col-md-6 col-12">
              <v-col cols="4">
                <v-btn
                    style="margin-top: 17px;"
                    color="light-blue" class="white--text mr-3"
                    @click="$refs.file.click()"
                >
                  파일 업로드
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
                <input @input="loadData" :accept="SheetJSFT" class="d-none" type="file" ref="file"/>
              </v-col>
              <slot />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="saveData"
          >
            저장
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
export default {
  props:["isShow"],
  data:()=> ({
    SheetJSFT: ["xlsx", "csv"].map(x => "." + x).join(","),
    dataDto:{
      insCd:'',
      egName:'',
      krName:'',
      fileNmSeq:'',
      fileNmUrl:'',
      fileType:'',
      delimiter:'',
      applyDate:'',
      dataDescription:'',
    },
    registData: {
      fileType:"",
      fileName:"",
      fileSize:"",
      fileString:null,
      width: 0,
      height: 0
    },
    insurerCode: [
      {value: '선택', cd: ''}
    ],
  }),
  methods: {
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    async saveData() {
      if (_.isEmpty(this.dataDto.insCd)) {
        alert("상품코드를 입력해주세요.");
        return;
      }
      let key = '';

      if (this.dataDto.insCd === "AOO") {
        key = "a2ZaT0V1akczUEFSZGtUNVk2TjVUdz09";
      } else if (this.dataDto.insCd === "L00") {
        key = "TUdtaHBmN1lZbGZQTnpEM2tlUkFKUT09";
      } else if (this.dataDto.insCd === "L01") {
        key = "WDFoTlR1Sk03TzMrVDVseE5TeDI0dz09";
      } else if (this.dataDto.insCd === "L02") {
        key = "TVBZYi9tRDJmdXMrUERCenNzYkdVdz09";
      } else if (this.dataDto.insCd === "L03") {
        key = "V0RROGpNYktTUW8zb3NiSGlpYnZOZz09";
      } else if (this.dataDto.insCd === "L04") {
        key = "QzY1eVE0WUVHSFRDdEpsQnF1emNhQT09";
      } else if (this.dataDto.insCd === "L05") {
        key = "Z0dOSVIydVJQVXdlM1hkMGtVUk5Ydz09";
      } else if (this.dataDto.insCd === "L11") {
        key = "Y1lvL2xFclRQRXFEMXVXMnhHMDhjUT09";
      } else if (this.dataDto.insCd === "L12") {
        key = "Njk4cEVRSnBhZWwxbkF6b2MyU25Pdz09";
      } else if (this.dataDto.insCd === "L31") {
        key = "Sm03UkFPREVGVGlId2dzcStFdExPZz09";
      } else if (this.dataDto.insCd === "L34") {
        key = "Zkx4ZnEyd1VYdmFiNE5KSGhIdktFUT09";
      } else if (this.dataDto.insCd === "L39") {
        key = "VGQ4UzdPaHM2bjU2NWppam9kcndzQT09";
      } else if (this.dataDto.insCd === "L42") {
        key = "Nm5LeG9uU2xaN1F4S2JYNytGMjZQQT09";
      } else if (this.dataDto.insCd === "L51") {
        key = "YTdmMVpXREtRQUY2cVRKdnFqa0Q5dz09";
      } else if (this.dataDto.insCd === "L52") {
        key = "Zk5pTWorZXdYSW82d2xpblYxbHZoQT09";
      } else if (this.dataDto.insCd === "L62") {
        key = "d09RVVhqWkR1aExWSU8zZFRLWjhiUT09";
      } else if (this.dataDto.insCd === "L63") {
        key = "N3lqbFNhcEVNM2hCRXc5dXROZDBKdz09";
      } else if (this.dataDto.insCd === "L71") {
        key = "Y3NiS3c1SFE4M2h0UlpBaEdpVXdVdz09";
      } else if (this.dataDto.insCd === "L72") {
        key = "dVN2TU1Ed3hqRTVhT1M1V2Ryczh1dz09";
      } else if (this.dataDto.insCd === "L74") {
        key = "cFdYbVJxazk4ekJ4VkhLdU0xaTJ0Zz09";
      } else if (this.dataDto.insCd === "N00") {
        key = "cG85MVQ4RGdiREQzellXVmxvV05Bdz09";
      } else if (this.dataDto.insCd === "N01") {
        key = "S0NnZ0Z3ZDZvWjZKd0lRZXhQeWpZQT09";
      } else if (this.dataDto.insCd === "N02") {
        key = "ZWNVdEFpVTJxSXJkYjVCNmY1NlZRdz09";
      } else if (this.dataDto.insCd === "N03") {
        key = "UHJvb2tDWmhCRmdLS1huemw0TVI0Zz09";
      } else if (this.dataDto.insCd === "N04") {
        key = "c2Z5Qld6RmJ3ak5xb0t1eUg1TFozZz09";
      } else if (this.dataDto.insCd === "N05") {
        key = "WE51RG1oSmUyd1FLcVRPYk8zcnJXQT09";
      } else if (this.dataDto.insCd === "N06") {
        key = "dVZIemd0TXZaUzBGMVVZenlzMUJDZz09";
      } else if (this.dataDto.insCd === "N07") {
        key = "ZWlPTjBvQ1ZFWlpBVXI3MnFuMXJWUT09";
      } else if (this.dataDto.insCd === "N08") {
        key = "dzA4Zk1pSUZGYTM1UkhUUkx0cXd6QT09";
      } else if (this.dataDto.insCd === "N09") {
        key = "aG9wYm13UGN4eEszbXhlU1JEZDV0QT09";
      } else if (this.dataDto.insCd === "N10") {
        key = "bmhpSkFuYnNpNHZsVTVUR0JCZDl6dz09";
      } else if (this.dataDto.insCd === "N13") {
        key = "azJ3bmNKbk9Oa043TTVpaFBDTTlZZz09";
      } else if (this.dataDto.insCd === "N14") {
        key = "SmJ1WFIrSWF3K2dlcHhGdms5ejUxZz09";
      } else if (this.dataDto.insCd === "N17") {
        key = "dlhjLy9IVDVOWkZ6Z0d0dEVCQVJEQT09";
      }
      const uploadParam = {
        insCd: this.dataDto.insCd,
        prTbId: this.dataDto.egName,
        ulFileNm: this.ulFileNm,
        fileType: this.dataDto.fileType,
        delimiter: this.dataDto.delimiter,
        description: this.dataDto.dataDescription,
        fileString: this.registData.fileString,
        applyDate: this.dataDto.applyDate,
        key: key
      }
      try {
        const { data } = await axiosInstance.post('/public/prdata/extUpload', uploadParam);
        alert(data);
        this.close();
      } catch (e) {
        console.log(e);
      }
    },
    setDialog (value) {
      this.dialog = value
    },
    close() {
      this.dataDto=[];
      this.dataDto.insCd = '';
      this.$emit('close');
    },
    loadData({target: {files}}) {
      this.selectFile = files[0];
      this.ulFileNm = files[0].name;

      if (!files || files.length < 1) {
        alert("올바른 파일이 아닙니다.");
        return;
      }
      this.fileReader(this.selectFile);
    },
    fileReader(fileData) {
      let reader = new FileReader();
      var self = this;

      reader.onload = async e => {
        this.$emit('loadImage', e.target.result);
        var data = reader.result;
        var base64 = data.replace(/^[^,]*,/, '');
        self.registData.fileString =  base64
      }
      this.registData.fileType = fileData.type.split("/")[1];
      this.registData.fileName = fileData.name.split(".")[0];
      this.registData.fileSize = fileData.size;
      reader.readAsDataURL(fileData);
    },
  }
}
</script>