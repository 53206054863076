<template>
  <v-form v-model="valid" class="w100h100">
    <div
        @submit.prevent
        @dblclick="setIsEdit" class="w100h100 cell"
        :class="commaList.includes(header) ? 'jc-fe' : 'cell-center'"
      >
    <v-text-field
        v-if="isEdit"
        v-model="text"
        :rules="rules"
        @blur="edit"
        @keydown.enter="edit"
        hide-details dense autofocus
    />
      <span class="align-center" v-else>
        {{commaList.includes(header) ? inputComma(value) : value }}
      </span>
    </div>
  </v-form>
</template>

<script>
import SettlementMixin from "@/mixin/SettlementMixin";

export default {
  mixins: [SettlementMixin],
  data: () => ({
    commaList: ["contractPremiumAmt", "rewardAmt", "premiumAmt", "contractPrem"]
  }),
  methods: {
    inputComma(num) {
      return num
              ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : num
    },
  }
}
</script>

<style scoped>
.cell-center .v-text-field input {
  text-align: center;
  width: 6vw!important;
}
div.text-right.ml-3 .v-text-field input {
  text-align: right;
}
</style>