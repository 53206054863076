<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="기준년월">
          <v-text-field
              v-model="settlementDate"
              :disabled="isUploaded"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <v-btn
            class="grey white--text"
            v-if="!isUploaded"
            :disabled="isDisabled"
            @click="search"
        >조회
        </v-btn>
        <div class="ml-auto pb-1 d-flex align-end">
          <v-btn
              class="grey white--text mr-3"
              v-if="!isUploaded"
              :disabled="isDisabled"
              @click="clickUpload"
          >엑셀 업로드
          </v-btn>
          <v-btn
              class="grey white--text"
              v-if="!isUploaded"
              :disabled="isDisabled"
              @click="deleteData"
          >자료 삭제
          </v-btn>
        </div>
      </search-area>
      <input @input="loadData" :accept="SheetJSFT" class="d-none" id="excel" type="file" ref="file"/>
    </template>
  </search-bar-layout>
</template>

<script>
import XLSX from "xlsx";
import {RENCAR_EXCEL_HEADER} from "@/util/TableHeaders";
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {FieldInput, SearchArea, SearchBarLayout},
  props: ["isUploaded"],
  computed: {
    isDisabled() {
      const d = this.settlementDate;
      return !(!!d && /[0-9]{6}/.test(d) && this.monthCheck(d));
    },
  },
  data: () => ({
    settlementDate: "",
    SheetJSFT: ["xlsx", "xlsb", "xlsm", "xls"].map(x => "." + x).join(","),
  }),
  watch: {
    settlementDate: function (value) {
      this.$emit('setSettlementDate', value);
    }
  },
  methods: {
    // 기준연월 유효성 체크
    monthCheck(value) {
      return value.length === 6
          ? (+value.slice(4) < 13 && +value.slice(4) > 0)
          : false;
    },

    //조회 버튼 클릭 시
    search(){
      this.$emit('search', this.settlementDate);
    },

    // 삭제 버튼 클릭 시
    async deleteData() {
      if (confirm(this.settlementDate+" 렌카 정산 자료를 삭제하시겠습니까?")) {
        const {data} = await axiosInstance.delete('/admin/rencarSettlement/delete', {
          params: {
            standardYm: this.settlementDate
          }
        });

        alert(this.settlementDate + " 기준년월의 정산자료 " + data + "건이 삭제되었습니다.");

        //해당월 재조회 혹은 초기화
        this.search();
      }
    },

    // 업로드 버튼 클릭시 input file
    clickUpload() {
      if (!this.isDisabled) this.$refs.file.click();
    },

    // upload excel
    async upload(data, settlementDate) {
      this.initData();
      this.search();
      if (!data || !settlementDate || settlementDate.length !== 6) {
        alert("파일의 컬럼명 또는 정산연월을 다시 확인해주세요.");
      } else {
        await axiosInstance.post('/admin/rencarSettlement/upload', data, {
          params: {
            standardYm: settlementDate
          }
        });
        alert(this.settlementDate + " 기준년월의 자료가 업로드 되었습니다.");
        this.search();
      }
    },
    loadData({target: {files}}) {
      if (!files || files.length < 1) alert("올바른 파일이 아닙니다.");
      else this._setReader().readAsBinaryString(files[0]);
    },
    _setReader() {
      const reader = new FileReader();
      reader.onload = ({target: {result}}) => {
        const {SheetNames, Sheets} = XLSX.read(result, {type: 'binary'});
        // 해당 파일 json 으로 변경
        // header 컬럼 1행, 비어있는 row 는 생략하는 조건
        const data = this.getJSON(XLSX.utils.sheet_to_json(
            Sheets[SheetNames[0]], {header: 1, blankrows: false, rawNumbers: true, defval: ""}
        ));

        // 엑셀 파일 변환 후 해당 메소드 실행
        this.upload(data, this.settlementDate);
      };
      return reader;
    },
    getJSON(data) {
      // 헤더 제거
      const body = data.slice(1);

      return body.map((item, index) => {

        const obj = {};

        for (let i = 0; i < item.length; i++) {

          // 한글로 된 컬럼명을 영어로 바꾸기 위한 작업
          const enNm = this.chgKrNmToEn(data[0][i]);

          // 친구초대 리워드가 검증 단계에서 추가될 수 있으므로 index 를 2단위로 수정
          if (enNm) {
            obj["index"] = index * 2;
            obj[enNm] = item[i];
          }

        }

        if (obj['contractDate']) obj['contractDate'] = obj['contractDate'].toString().replace(/[^0-9]/gi, "");
        if (obj['expiryDate']) obj['expiryDate'] = obj['expiryDate'].toString().replace(/[^0-9]/gi, "");
        // 엑셀에서 01을 1로 바꾸는 경우도 있기에 해당 로직 추가
        if (obj['paymentType'] === "1" || obj['paymentType'] === 1) obj['paymentType'] = "01";

        return obj;
      });
    },
    chgKrNmToEn(header) {
      const h = header.replace(/ /g, '');

      for (const item of RENCAR_EXCEL_HEADER) {
        if (item[0] === h) return item[1];
      }

      alert("파일의 컬럼명을 확인해주세요.");
      throw new Error("파일의 컬럼명을 확인해주세요.");
    },
    initData() {
      this.$refs.file.value = "";
    }

  }
}
</script>

<style>
.todayDelete {
  color: red;
  margin-left: 10px;
  text-decoration-line: underline;
  cursor: pointer;
}
.dataDetail {
  color: black;
  text-decoration-line: underline;
  cursor: pointer;
}
</style>