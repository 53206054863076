<template>
  <search-layout title="">
    <template v-slot:searchBar>
      <registration-target-search
          :baseDt = "baseDt"
          @search="getList"
        />
    </template>

    <template v-slot:table>
      <registration-target-table
          :data = "data"
          :searchList = "searchList"
          :loading="loading"
        />
    </template>
  </search-layout>
</template>
<script>
import SearchLayout from "../../../../layouts/SearchLayout";
import RegistrationTargetSearch from "./RegistrationTargetSearch";
import RegistrationTargetTable from "./RegistrationTargetTable";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {RegistrationTargetTable, RegistrationTargetSearch, SearchLayout},
  props: ['baseDt'],
  data:() => ({
    loading: false,
    data: [],
    searchList: []
  }),
  methods: {
    async getList(params) {
      this.loading = true;
      this.searchList = params;
      const custExtractInDto = params;
      try {
        const { data } = await axiosInstance.post('/admin/operation/custExtractList', custExtractInDto);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>