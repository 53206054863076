<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사코드</v-col>
          <v-select
              @click="selectCd"
              v-model="searchInsurer"
              :items="insurerCode"
              background-color="white"
              item-text="value"
              item-value="cd"
              hide-details outlined dense
          />
        </v-col>
<!--        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">-->
<!--          <v-col cols="4">선택조건</v-col>-->
<!--          <v-select-->
<!--              v-model="orderType"-->
<!--              :items="order"-->
<!--              background-color="white"-->
<!--              item-text="cname"-->
<!--              item-value="cvalue"-->
<!--              hide-details outlined dense-->
<!--          />-->
<!--        </v-col>-->
        <v-col cols="1" style="margin-left: 20px;">사용일자</v-col>
        <v-col cols="3">
          <date-input
            :start-date="startDate"
            :end-date="endDate"
            @updateDate="updateDate"
            />
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../layouts/SearchBarLayout";
import SearchArea from "../../../components/Search/SearchArea";
import SearchBtn from "../../../components/Search/SearchBtn";
import DateInput from "../../../components/Form/DateInput";
import {axiosInstance} from "@/util/AxiosModule";
import DateMixin from "@/mixin/DateMixin";
export default {
  mixins: [DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, SearchBtn},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data: () => ({
    order:[
      {cname: '선택', cvalue: '0'},
      {cname: '인기상품', cvalue: '1'},
      {cname: '추천상품', cvalue: '2'},
    ],
    orderType:"0",
    startDate: "",
    endDate: "",
    searchInsurer:"",
    insurerCode:[
      {value: '선택', cd: ''}
    ]
  }),
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    getSearch() {
      const params = {
        insCd: this.searchInsurer,
        startDate: this.startDate.replaceAll('-', ''),
        endDate: this.endDate.replaceAll('-', '')
      }
      this.$emit('getSearch', params);
    }
  }
}
</script>