<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="3">상품군</v-col>
          <v-col cols="4">
            <v-select
              @change="initComboBox('insCd')"
              v-model="prProdLineCd"
              :items="prProdLineCdList"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              background-color="white"
              class="subBar"
              hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="3">보험사</v-col>
          <v-col cols="4">
            <v-select
                @change="initComboBox('prCd')"
                v-model="insCd"
                :items="insCdList"
                background-color="white"
                item-text="insCdNm"
                item-value="insCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="3">상품코드</v-col>
          <v-col cols="9">
            <v-select
                v-model="prCd"
                :items="prCdList"
                background-color="white"
                item-text="prCdNm"
                item-value="prCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="3">변경전 상품코드</v-col>
          <v-col cols="9">
            <v-select
                v-model="oldPrCd"
                :items="oldPrCdList"
                background-color="white"
                item-text="prCdNm"
                item-value="prCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchBtn from "@/components/Search/SearchBtn";
import SearchArea from "@/components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModuleDataSync";
import {API_SERVER_URL_DEV, API_SERVER_URL_PROD} from "@/util/constant"


export default {
  props: ["data", "selectData"],
  components: {SearchArea, SearchBarLayout, SearchBtn},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      insCd: "",
      insCdList: [
        {insCdNm: "선택", insCd: ""}
      ],
      prCd: "",
      prCdList: [
        {prCdNm: "선택", prCd: ""}
      ],
      oldPrCd: "",
      oldPrCdList: [
        {prCdNm: "선택", prCd: ""}
      ]
    }
  },
  mounted() {
    this.initComboBox("PrProdLineCd");
  },
  methods: {
    getSeverUrl(type) {
      return type == "prod" ? API_SERVER_URL_PROD : API_SERVER_URL_DEV;
    },
    initComboBox(type){
      this.prCd = "";
      this.oldPrCd = "";
      if("PrProdLineCd" == type)
      {
        this.getPrProdLineCdList();
      }
      if("insCd" == type)
      {
        this.insCd = "";
        this.prCdList = [{prCdNm: "선택", prCd: ""}];
        this.oldPrCdList = [{prCdNm: "선택", prCd: ""}];

        this.getInsCdList();
      }
      if("prCd" == type)
      {
        this.getPrCdListY();
        this.getPrCdListN();
      }
    },
    async getPrProdLineCdList() {
      this.prProdLineCdList = await this.getPrProdLineCd();
      this.prProdLineCdList.unshift({
        prProdLineNm: "선택",
        prProdLineCd: ""
      })
    },
    async getInsCdList() {
      this.insCdList = await this.getInsCd();
      this.insCdList.unshift({
        insCdNm: "선택",
        insCd: ""
      })
    },
    async getPrCdListY() {
      this.prCdList = await this.getPrCdList(this.prProdLineCd, this.insCd, "Y");
      this.prCdList.unshift({
        prCdNm: "선택",
        prCd: ""
      })
    },
    async getPrCdListN() {
      this.oldPrCdList = await this.getPrCdList(this.prProdLineCd, this.insCd, "N");
      this.oldPrCdList.unshift({
        prCdNm: "선택",
        prCd: ""
      })
    },
    getSearch() {
      if(this.prProdLineCd === "")
      {
        alert("상품군을 선택하세요.");
        return;
      }
      else if(this.insCd === "")
      {
        alert("보험사를 선택하세요.");
        return;
      }
      else if(this.prCd === "")
      {
        alert("상품을 선택하세요.");
        return;
      }

      const params = {
        prProdLineCd: this.prProdLineCd,
        insCd: this.insCd,
        prCd: this.prCd,
        oldPrCd: this.oldPrCd
      }
      this.$emit("getSearch", params);
    },
    async getPrProdLineCd() {
      const { data } = await axiosInstance.get(this.getSeverUrl("dev") + '/public/prdata/prProdLineNmList', {
        params: {
          srhType: 2,
          insDiv: "A",
        }
      })
      .catch((e) => {
        console.log(e);
      })
      return data;
    },
    async getInsCd() {
      const insCdList = [];
      const { data } = await axiosInstance.get(this.getSeverUrl("dev") + '/public/prdata/code-list', {
        params: {
          cdMasterId: 'PR_INS_CODE',
          useYn: 'Y'
        }
      })
      .catch((e) => {
        console.log(e);
      })

      for (let i = 0; i < data.length; i++) {
        if (data[i].use === true) {
          const object = {};
          if(!data[i].value.includes("공통"))
          {
            object.insCdNm = data[i].value;
            object.insCd = data[i].cd;
            insCdList.push(object);
          }
        }
      }

      return insCdList;
    },
    async getPrCdList(prProdLineCd, insCd, useYn) {
      let prCdList = [];
      const { data } = await axiosInstance.get(this.getSeverUrl("dev") + '/public/prdata/prProdList', {
        params: {
          prProdCd: prProdLineCd,
          insCd: insCd,
          useYn: useYn
        }
      })
      .catch((e) => {
        console.log(e);
      })
      for (let i = 0; i < data.length; i++) {
        const object = {};
        object.prNm = data[i].prNm;
        object.prCd = data[i].prCd;
        object.prCdNm = data[i].prCd + "-" + data[i].prNm;
        prCdList.push(object);
      }
      return prCdList;
    }
  }
}
</script>