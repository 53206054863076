<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="상품종류">
          <v-select
              v-model="prProdLineCd"
              :items="prProdLineCdList"
              item-text="name"
              item-value="cd"
              class="subBar"
              background-color="white"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="보험사">
          <v-select
              v-model="insCd"
              :items="insCdList"
              item-text="value"
              item-value="cd"
              class="subBar"
              background-color="white"
              hide-details outlined dense
          />
        </field-input>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="4">작업일자</v-col>
          <v-col class="paddCol">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
                class="subBar"
            />
          </v-col>
        </v-col>
      </search-area>
      <search-area>
        <field-input title="작업대상">
          <v-select
              v-model="wkTgYn"
              :items="wkTgYnList"
              item-value="cvalue"
              item-text="cname"
              class="subBar"
              background-color="white"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="판매종료">
          <v-select
              v-model="closeYn"
              :items="closeYnItems"
              item-value="dvalue"
              item-text="dname"
              class="subBar"
              background-color="white"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="상품명">
          <v-text-field
              v-model="prNm"
              menu-props="auto"
              class="subBar"
              background-color="white"
              outlined dense hide-details
              @keypress.enter="getSearch"
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import FieldInput from "@/components/Form/FieldInput";
import SearchBtn from "@/components/Search/SearchBtn";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout, DateInput},
  mixins: [DateMixin],
  props: ['props_prProdLineCdList', 'props_insCdList'],
  data: () => ({
    prProdLineCdList : [{name: '전체', cd: 'ALL'}],
    prProdLineCd: "ALL",
    insCdLst : [{value: '전체', cd: 'ALL'}],
    insCd: "ALL",
    startDate: "",  // 날짜
    endDate: "",
    wkTgYn: null,     // 작업대상
    wkTgYnList: [
      {cname: '미결정', cvalue: null},
      {cname: '자동수집대상', cvalue: 'Y'},
      {cname: '수기수집대상', cvalue: 'M'},
      {cname: '비대상', cvalue: 'N'}
    ],
    closeYn: 'ALL',   // 판매종료
    closeYnItems: [
      {dname: '전체', dvalue: 'ALL'},
      {dname: 'Y', dvalue: 'Y'},
      {dname: 'N', dvalue: 'N'}
    ],
    prNm: "",         // 상품명
  }),

  created() {
    this.prProdLineCdList = [...this.prProdLineCdList, ...this.props_prProdLineCdList];
    this.insCdList = [...this.insCdLst, ...this.props_insCdList];
  },

  beforeMount() {
    const {fromDate, toDate} = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },

  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    getSearch() {
      const params = {
        prProdLineCd: this.prProdLineCd,
        insCd: this.insCd,
        startDt: this.startDate.replaceAll('-',''),
        endDt: this.endDate.replaceAll('-',''),
        closeYn: this.closeYn,
        wkTgYn: this.wkTgYn,
        prNm: this.prNm,
      }
      this.$emit('getSearch', params);
    }
  }
}
</script>

<style scoped>
.paddCol {
  padding: 0;
}

.subBar {
  max-width: 270px;
}

.marginLf {
  margin-left: 7px;
}
</style>