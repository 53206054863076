import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify/lib';
import VueClipboard from 'vue-clipboard2'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);
Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
});

// 특정요소에 대한 포커스 지정(v-focus)
const focus = {
  mounted: (el) => el.focus(),
};
Vue.directive('focus', focus);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
