<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-card width="40vw" min-height="48vh" style="overflow-y: scroll">
      <v-card-title>조회조건</v-card-title>
      <!-- 상품군 종류선택 -->
      <v-card-text>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="auto">상품군 종류</v-col>
          <v-col cols="auto">
            <h2>{{condArrList.prProdLineNm}}</h2>
          </v-col>
        </v-col>
      </v-card-text>

      <v-card-text>
        <dl class="input-wrap input-table">
          <div class="table-item" v-for="(item, index) in creCvrCdList" :key="index">
            <dt>{{ item.creCvrNm }}</dt>
            <dd @click="clickOptionRow(item.creCvrNm)" :class="{ on: selectedRow === item.creCvrNm }">
              {{ item.selectedCreCvr.intCondNm }}
              <ol>
                <li v-for="(cvr_item, cvr_index) in item.creCvrLst" :key="cvr_index"
                    @click="clickCreCvrOption(cvr_item.intCond, index)">
                  {{ cvr_item.intCondNm }}
                </li>
              </ol>
            </dd>
          </div>

          <div class="table-item">
            <dt>보험기간</dt>
            <dd @click="clickOptionRow('insTrm')" :class="{ on: selectedRow === 'insTrm'}">
              {{ insTrm }}
              <ol>
                <li v-for="(item, index) in insTrmList" :key="index"
                    @click="clickOption('insTrm', item.condDetail)">
                  {{ item.condDetail }}
                </li>
              </ol>
            </dd>
          </div>

          <div class="table-item">
            <dt>납입기간</dt>
            <dd @click="clickOptionRow('pyTrm')" :class="{ on: selectedRow === 'pyTrm'}">
              {{ pyTrm }}
              <ol>
                <li v-for="(item, index) in pyTrmList" :key="index"
                    @click="clickOption('pyTrm', item.condDetail)">
                  {{ item.condDetail }}
                </li>
              </ol>
            </dd>
          </div>

          <div class="table-item">
            <dt>갱신기간</dt>
            <dd :class="{ on: selectedRow === 'rnwCycle'}">
              {{ rnwCycle }}
              <ol>
                <li v-for="(item, index) in rnwCycleList" :key="index">
                  {{ item.condDetail }}
                </li>
              </ol>
            </dd>
          </div>

          <div class="table-item">
            <dt>상품타입</dt>
            <dd @click="clickOptionRow('prodType')" :class="{ on: selectedRow === 'prodType'}">
              {{ changedData(prodType, "1", "갱신형") }}
              {{ changedData(prodType, "2", "비갱신형") }}
              {{ changedData(prodType, "5", "무해지(0%,50%)") }}
              {{ changedData(prodType, "7", "연만기") }}
              {{ changedData(prodType, "A", "세만기_무해지형") }}
              {{ changedData(prodType, "C", "기본형") }}
              {{ changedData(prodType, "D", "체증형") }}
              {{ changedData(prodType, "E", "무해지(0%,50%)_325") }}
              {{ changedData(prodType, "F", "무해지(0%,50%)_335") }}
              {{ changedData(prodType, "H", "무해지(0%,50%)_355") }}
              <ol>
                <li v-for="(item, index) in prodTypeList" :key="index"
                    @click="clickOption('prodType', item.condDetail)">
                  {{ changedData(item.condDetail, "1", "갱신형") }}
                  {{ changedData(item.condDetail, "2", "비갱신형") }}
                  {{ changedData(item.condDetail, "5", "무해지(0%,50%)") }}
                  {{ changedData(item.condDetail, "7", "연만기") }}
                  {{ changedData(item.condDetail, "A", "세만기_무해지형") }}
                  {{ changedData(item.condDetail, "C", "기본형") }}
                  {{ changedData(item.condDetail, "D", "체증형") }}
                  {{ changedData(item.condDetail, "E", "무해지(0%,50%)_325") }}
                  {{ changedData(item.condDetail, "F", "무해지(0%,50%)_335") }}
                  {{ changedData(item.condDetail, "H", "무해지(0%,50%)_355") }}
                </li>
              </ol>
            </dd>
          </div>
        </dl>
      </v-card-text>
      <div class="bottom-button">
        <v-btn class="green" style="margin-left: 10px;" @click="save">적용</v-btn>
        <v-btn class="red float-right" style="margin-right: 10px;" @click="close">닫기</v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import _ from "lodash";

export default {
  mixins: [TableMixin],
  props: ["isShow", "condArrList", "productInfo", "deletedData"],
  data() {
    return {
      loading: false,
      prProdLineCd: "",
      creCvrCdList: [],
      insTrmList: [], insTrm: "",
      pyTrmList: [], pyTrm: "",
      rnwCycleList: [], rnwCycle: "",
      prodTypeList: [], prodType: "",
      selectedRow: "",

      // 적용 데이터
      saveData: {
        prProdLineCd: "",
        prProdLineNm: "",
        creCvrAmtLst: [],
        insTrm: "",   pyTrm: "",
        rnwCycle: "", prodType: "",
      },
      fieldData: [],
    }
  },
  watch: {
    condArrList: function (value) {
      this.prProdLineCd = value.prProdLineCd;
      this.creCvrCdList = value.creCvrCdLst;
      this.insTrmList = value.insTrmLst;
      this.pyTrmList = value.pyTrmLst;
      this.rnwCycleList = value.rnwCycleLst;
      this.prodTypeList = value.prodTypeLst;
      if (value.creCvrCdLst === undefined) { return true; }

      // 신규 담보리스트 및 기존 담보리스트 구분
      if (value.prProdLineCd !== this.saveData.prProdLineCd) {
        this.setCreCvrOption("new");
      } else {
        this.setCreCvrOption("old");
      }
      // 기본 데이터 전달(나머지)
      this.insTrm = this.productInfo.insTrm;
      this.pyTrm = this.productInfo.pyTrm;
      this.rnwCycle = _.isEmpty(this.productInfo.rnwCycle) ? '-' : this.productInfo.rnwCycle;
      this.prodType = this.productInfo.prodType;
    },
    deletedData: function (value) {
      if (value === true) {
        this.insTrm = "";
        this.pyTrm = "";
        this.rnwCycle = "";
        this.prodType = "";
      }
    }
  },
  methods: {
    setCreCvrOption(type) {
      if (type === 'old') { // 기존
        for (let i in this.creCvrCdList) {
          for (let j in this.creCvrCdList[i].creCvrLst) {
            if (this.creCvrCdList[i].creCvrLst[j].intCond === this.productInfo.creCvrAmtLst[i].intCond) {
              this.creCvrCdList[i]['selectedCreCvr'] = this.creCvrCdList[i].creCvrLst[j];
            }
          }
        }
      } else { // 신규 (new 세팅)
        for (let i in this.creCvrCdList) {
          for (let j in this.creCvrCdList[i].creCvrLst) {
            if (this.creCvrCdList[i].creCvrLst[j].defaultYn === 'Y') {
              this.creCvrCdList[i]['selectedCreCvr'] = this.creCvrCdList[i].creCvrLst[j];
            }
          }
        }
      }
    },
    // 타입 선택
    clickOptionRow(type) {
      this.selectedRow = type === this.selectedRow ? "" : type;
    },
    // 담보 데이터 전달
    clickCreCvrOption(intCond, index) {
      for (let i in this.creCvrCdList[index].creCvrLst) {
        if (this.creCvrCdList[index].creCvrLst[i].intCond === intCond) {
          this.creCvrCdList[index].selectedCreCvr = this.creCvrCdList[index].creCvrLst[i];
        }
      }
    },
    // 타입에 맞는 데이터 전달
    clickOption(type, value) {
      if (type === 'insTrm') {
        this.insTrm = value;
      } else if (type === 'pyTrm') {
        this.pyTrm = value;
        if (this.prProdLineCd === "PRODLN0001") {
          this.rnwCycle = value;
        }
      } else if (type === 'prodType') {
        this.prodType = value;
      } else return false;
    },
    // 변경된 조건 적용
    save() {
      if (this.isShow === true) {this.fieldData = [];}
      this.saveData.prProdLineCd = this.condArrList.prProdLineCd;
      this.saveData.prProdLineNm = this.condArrList.prProdLineNm;
      this.saveData.insTrm = this.insTrm;
      this.saveData.pyTrm = this.pyTrm;
      this.saveData.rnwCycle = this.rnwCycle;
      this.saveData.prodType = this.prodType;
      this.setCreCvrAmtLst();

      this.$emit("field", this.fieldData);
      this.$emit("save", this.saveData);
      this.close();
    },
    // 변경된 담보조건 전달
    setCreCvrAmtLst() {
      this.saveData.creCvrAmtLst = [];
      for (let i in this.creCvrCdList) {
        this.saveData.creCvrAmtLst.push({
          accAmt: this.creCvrCdList[i].selectedCreCvr.intCond,
          accAmtNm: this.creCvrCdList[i].selectedCreCvr.intCondNm,
          creCvrCd: this.creCvrCdList[i].selectedCreCvr.condDetail,
        });
        this.fieldData.push({
          intCondNm: this.creCvrCdList[i].selectedCreCvr.intCondNm,
          condDetailNm: this.creCvrCdList[i].selectedCreCvr.condDetailNm,
        })
      }
    },
    close() {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>
.input-wrap > dt:last-of-type,
.input-wrap dd:last-of-type {
  margin-bottom: 5px;
}
.input-wrap {
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(158, 158, 158, 0.7);
  font-size: min(3.5vw, 15px);
}
.input-wrap.input-table {
  /*align-items: flex-start;*/
  display: flex;
  flex-direction: column;
}
.input-wrap.input-table .table-item{
  width:100%;
  padding: 13px 0;
}
.input-wrap.input-table dt {
  width: 1px;
  height: 1px;
  overflow: visible;
  white-space: nowrap;
  font-weight: bold;
}
.input-wrap.input-table dd {
  width: calc(100% - 1px);
  height: auto;
  padding-right: 20px;
  text-align: right;
  background: url("~@/assets/arrow_bottom.png") no-repeat 99% 7.5px;
  background-size: 10px;
  font-weight: 400;
  cursor: pointer;
}
.input-wrap.input-table dd ol {
  display: none;
  position: relative;
  left: -10px;
  width: calc(100% + 40px);
  padding-right: 10px;
  margin-top: 5px;
  background-color: #f1f1f1;
  cursor: auto;
}
.input-wrap.input-table dd ol li {
  height: 40px;
  text-align: right;
  line-height: 40px;
  cursor: pointer;
  border-radius: 5px;
  padding-right: 5px;
}
.input-wrap.input-table dd ol li:hover {
  background-color: rgba(189, 189, 189, 0.5);
}
.input-wrap.input-table dd.on ol {
  display: block;
}
.bottom-button {
  margin-bottom: 10px;
}
</style>