<template>
  <search-page-layout title="회원 현황">

    <template v-slot:searchBar>
      <user-search-bar @search="getUserSearch"/>
    </template>

    <template v-slot:table>
      <user-search-table :data="data" :loading="loading" @update="updateData" :isPrvGroupTM="isPrvGroupTM" />
    </template>

  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import UserSearchBar from "@/pages/User/components/UserSearch/UserSearchBar";
import UserSearchTable from "@/pages/User/components/UserSearch/UserSearchTable";
import func from "@/util/func";

export default {
  components: {
    UserSearchTable,
    UserSearchBar,
    SearchPageLayout
  },
  computed: {
    isPrvGroupTM() {
      return func.checkUserPrvGroup(["TM", "TM_ADM"]) === "Y";
    }
  },
  data: () => ({
    data: [],
    loading: false,
    paramsData: {}
  }),
  methods: {
    async getUserSearch(param) {
      this.loading = true;

      const params = {
        searchType: param.searchType,
        memberNm: param.memberNm,
      };
      if (param.telNum) params.telNum = param.telNum.replace(/[^0-9]/gi, "");
      if (param.accountNo) params.accountNo = param.accountNo;
      this.paramsData = params;

      try {
        const {data} = await axiosInstance.get('/admin/operation/member', {params});

        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    updateData() {
      this.getUserSearch(this.paramsData);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/noto";
</style>