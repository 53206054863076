<template>
  <search-page-layout title="메뉴별 사용자그룹 권한관리">
    <template v-slot:searchBar>
      <user-group-bar
          @search="getUserList"
        />
    </template>

    <template v-slot:table>
      <user-group-table
          :data="data"
          :loading="loading"
          :update="update"
        />
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import UserGroupBar from "./components/UserGroup/UserGroupBar";
import UserGroupTable from "./components/UserGroup/UserGroupTable";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {UserGroupTable, UserGroupBar, SearchPageLayout},
  data:() => ({
    loading: false,
    data:[],
    params:[],
  }),
  methods: {
    async getUserList(params) {
      this.loading = true;
      this.params = params;

      try {
        const { data } = await axiosInstance.get('/admin/operation/menureglist', {params});
        this.data = data;
      } catch (e) {
        console.log(e)
      }

      this.loading = false;
    },
    update() {
      this.getUserList(this.params);
    }
  }
}
</script>