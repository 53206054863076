<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="">
        <v-col class="d-flex align-center col-xl-3 col-md-4 col-12">
          <v-col cols="4">사이트구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="site"
                @change="changList"
                :items="siteCheck"
                background-color="white"
                outlined dense hide-details
                item-value="cvalue"
                item-text="cname"
                class="subBar"/>
          </v-col>
          <v-col cols="4">추출분류</v-col>
          <v-col cols="7">
            <v-select
                v-model="incar"
                :items="divisionList"
                background-color="white"
                outlined dense hide-details
                item-value="bvalue"
                item-text="bname"
                class="subBar"/>
          </v-col>
          <v-col cols="4">등록기준일</v-col>
          <v-col cols="7">
            <v-menu
                v-model="menuTwo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="event"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menuTwo = !menuTwo"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" color="lime lighten-1" @input="menuTwo = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-col>
      </search-area>


    </template>
    <template v-slot:button>
      <search-btn @click="getList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateMixin from "@/mixin/DateMixin";
import store from "@/store";

export default {
  mixins: [SearchBarMixin, DateMixin],
  components: { SearchArea, SearchBarLayout},
  props: ['baseDt'],
  data: () => ({
    site:"ONECLICK",
    divisionList:[],
    siteCheck: [
      {cname: '원클릭', cvalue: 'ONECLICK'},
      {cname: '청신호', cvalue: 'GREENLIGHT'}
    ],
    incar:'11',
    oneIncarList: [
      {bname: '인카FA유실적', bvalue: '11'},
      {bname: '인카FA무실적', bvalue: '12'},
      {bname: '타사FA유실적', bvalue: '13'},
      {bname: '타사FA무실적', bvalue: '14'},
      {bname: '인카FA준회원', bvalue: '15'},
      {bname: '가입-1개월기준 만기예상', bvalue: '21'},
    ],
    greenIncarList: [
      {bname: '1개월기준 만기예상', bvalue: '21'},
    ],
    startDate: "",
    toCreateDt: "",
    menuTwo:false,
    menu: false,
  }),
  async beforeMount() {
    this.divisionList = this.oneIncarList;
  },
  computed: {
    dateRangeText () {
      if (this.startDate.length > 9) {
        return this.startDate
      }else {
        return this.baseDt ? `${this.baseDt.slice(0, 4)}-${this.baseDt.slice(4, 6)}-${this.baseDt.slice(6)}` : '';
      }
    }
  },
  methods: {
    changList() {
      if (this.site === 'ONECLICK') {
        this.incar = '11';
        this.divisionList = this.oneIncarList;
      } else {
        this.incar = '21';
        this.divisionList = this.greenIncarList;
      }
    },
    getList() {
      let d = new Date();
      let year = d.getFullYear(); // 년
      let month = d.getMonth();   // 월
      let day = d.getDate();      // 일

      let date = new Date(year, month, day - 1).toLocaleDateString().replaceAll(" ","").replaceAll(".", "");

      if (month < 10) {
        date = `${date.slice(0, 4)}0${date.slice(4, 5)}${date.slice(5)}`
      }
      if (day < 10) {
        date = `${date.slice(0, 4)}${date.slice(4, 6)}0${date.slice(6)}`
      }

      if (this.dateRangeText.replaceAll('-', '') > date) {
        alert("최대 어제 날짜까지 가능합니다.");
        return;
      }

      const params = {
        "baseDt": this.dateRangeText.replaceAll('-', ''),
        "contContentsCd": this.incar,
        "site": this.site,
        "regId" : store.getters['login/memberId'],
        "wkDiv": "2"
      }
      this.$emit('search', params, this.site, this.incar);
    }
  }
}
</script>