<template>
  <search-page-layout title="상품군 매핑/표준플랜 관리">
    <template v-slot:searchBar>
      <mapping-management-search-bar
          @getSearch = "getSearch"
        />
    </template>

    <template v-slot:table>
      <mapping-management-table
          :data="data"
          :loading="loading"
          @row="row"
        />
    </template>

    <template v-slot:inflowHist>
      <mapping-management-save
          :rowData = "rowData"
        />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import MappingManagementSearchBar from "./components/MappingManagement/MappingManagementSearchBar";
import MappingManagementTable from "./components/MappingManagement/MappingManagementTable";
import MappingManagementSave from "./components/MappingManagement/MappingManagementSave";
export default {
  components: {MappingManagementSave, MappingManagementTable, MappingManagementSearchBar, SearchPageLayout},
  data:() => ({
    loading: false,
    data: [],
    rowData: [],
  }),
  methods: {
    async getSearch(params) {
      this.loading = true;
      try {
        const {data} = await axiosInstance.get('/private/prdata/prProdGroupMapList', { params });
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].updateDate = this.data[i].updateDate.replace('T', ' ').substr(0, 19);
        }
        console.log("data", data);
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    row(row) {
      this.rowData = row;
    }
  }
}
</script>