<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-card  max-width="95vw" max-height="90vh" min-width="70vw" class="overflow-y-auto">
      <span class="d-flex justify-end close-button" @click="$emit('close')">x</span>
      <search-page-layout title="월마감 원천자료 건별 매핑현황">
        <template v-slot:searchBar>
          <settle-ins-summary-detail-search-bar
            :row-data="detailSearchData"
            @search="getSearchDetail"
          />
        </template>
        <template v-slot:table>
          <settle-ins-summary-detail-table
            :data="detailData"
            :loading="loading"
            @toExcel="toExcel"
          />
        </template>
      </search-page-layout>
    </v-card>
  </v-overlay>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import SettleInsSummaryDetailSearchBar
  from "@/pages/Settlement/components/SettlementInsSummary/Popup/SettleInsSummaryDetailSearchBar";
import SettleInsSummaryDetailTable
  from "@/pages/Settlement/components/SettlementInsSummary/Popup/SettleInsSummaryDetailTable";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import func from "@/util/func";
export default {
  components: {SettleInsSummaryDetailTable, SettleInsSummaryDetailSearchBar, SearchPageLayout},
  props: ["rowData", "isShow"],
  watch: {
    isShow(value) {
      if (value) {
        this.setParamsData();
      }
    }
  },
  data() {
    return {
      detailSearchData: {},
      detailParams: {},
      detailData: [],
      loading: false
    }
  },
  methods: {
    async setParamsData() {
      this.detailSearchData = {};
      this.detailSearchData = await this.rowData;
      await this.getSearchDetail(this.rowData);
    },
    async getSearchDetail(params) {
      this.detailData = [];
      this.loading = true;
      this.detailParams = params;
      try {
        const { data } = await axiosInstance.post("/admin/settlement/settlementInsDetail", params );
        let allData = {};
        const sumFields = ["srcContractPrem", "contractPrem", "rewardAmt"];
        for (const index in data) {
          for (const key in data[index]) {
            if (key === "srcId") {
              allData[key] = "합계";
            } else if (sumFields.includes(key)) {
              if (allData[key] == null) {
                allData[key] = 0; // 초기화
              }
              allData[key] += data[index][key];
            } else {
              allData[key] = "";
            }
          }
        }
        data.push(allData);
        this.detailData = data;
        const dateFields = ['payDueDate', 'payCompleteDate', 'contractDate'];

        for (let i in this.detailData) {
          dateFields.forEach(field => {
            if (this.detailData[i][field]) {
              this.detailData[i][field] = this.detailData[i][field].replace('T', '').substr(0, 10);
            }
          });
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    toExcel() {
      if (_.isEmpty(this.detailData)) return;
      const data = _.cloneDeep(this.detailData);
      const returnData = [];

      let day = new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '');
      day = day.replaceAll(':', '_');

      for (const item of data) {
        const obj = {
          "원천ID": item["srcId"],
          "보험사": item["contractInsurer"],
          "채널": item["joinChannel"],
          "기준년월": item["baseYm"],
          "원천_계약일자": item["srcContractDate"],
          "원천_보험료": Number(item["srcContractPrem"]),
          "원천_고객명": item["srcCustomerNm"],
          "원천_전화번호": item["srcCustomerTelNum"],
          "원천_차량번호": item["srcCarNo"],
          "조기지급ID": item["earlyId"],
          "회원명": item["memberNm"],
          "회원전화번호": item["memberTelNum"],
          "지급예정일": item["payDueDate"],
          "지급일": item["payCompleteDate"],
          "계약일자": item["contractDate"],
          "보험료": Number(item["contractPrem"]),
          "고객명": item["customerNm"],
          "전화번호": item["customerTelNum"],
          "차량번호": item["customerCarNo"],
          "광고비": Number(item["rewardAmt"])
        }
        returnData.push(obj);
      }

      const sheetName = `월마감 원천자료 매핑현황`;
      const fileName =
          `월마감 원천자료 건별-(${this.detailParams.baseYm})-(${this.detailParams.contractInsurer})-(${this.detailParams.joinChannel})-(${this.detailParams.paymentStatus})`
          + day;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  cursor: pointer;
  font-size: 30px;
  width: 100%;
  padding-right: 10px;
}
</style>