<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">상품군</v-col>
          <v-col cols="8">
            <v-select
                @change="search"
                v-model="prProdLineCd"
                :items="prProdLineCdList"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                background-color="white"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">담보구분</v-col>
          <v-col cols="8">
            <v-select
                :items="cvrTypeList"
                v-model="cvrType"
                background-color="white"
                item-text="cvrTypeNm"
                item-value="cvrType"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>

        <v-col class="col-xl-2 col-lg-2 col-md-4 col-12">
          <div class="float-center">
            <v-btn
                class="searchBtn grey lighten-1"
                @click="search"
            >조회</v-btn>
          </div>
        </v-col>

      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  components: {SearchArea, SearchBarLayout},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      cvrType: null,
      cvrTypeList: [
        {cvrTypeNm: "전체", cvrType: ""},
        {cvrTypeNm: "정액형", cvrType: "F"},
        {cvrTypeNm: "정율형", cvrType: null}
      ]
    }
  },
  mounted() {
    this.getPrProdLineCd();
    this.search();
  },
  methods: {
    async getPrProdLineCd(){
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineCdList.unshift({
        prProdLineNm: "전체",
        prProdLineCd: ""
      })
    },
    search() {
      const params = {
        prProdLineCd: this.prProdLineCd,
        cvrType: this.cvrType
      }
      this.$emit("search", params);
    },
  }
}
</script>