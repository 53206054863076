var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"30vh","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","disable-sort":"","headers":_vm.getHeader(_vm.BLACK_LIST_TABLE),"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},on:{"click:row":_vm.clickContents},scopedSlots:_vm._u([{key:"item.ssno",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? ((value.slice(0, 6)) + "-" + (value.slice(6, 13))) : '')+" ")]}},{key:"item.telNum",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? ((value.slice(0, 3)) + "-" + (value.slice(3, 7)) + "-" + (value.slice(7))) : '')+" ")]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(new Date(value).toLocaleString()))]):_vm._e()]}},{key:"item.updateDate",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(new Date(value).toLocaleString()))]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }