var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"white--text px-10",staticStyle:{"margin-right":"4px"},attrs:{"color":"grey"},on:{"click":_vm.showModal}},[_vm._v("플랜상세")]),_c('v-btn',{staticClass:"white--text px-8",staticStyle:{"margin-right":"4px"},attrs:{"color":"grey","disabled":_vm.data.length <= 0},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"30vh","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.getHeader(_vm.headers),"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},on:{"click:row":_vm.clickContents},scopedSlots:_vm._u([{key:"item.prNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 20, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.insTrmList",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 20, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getKST(value)))])]}}])})],1),_c('product-plan-management-detail-modal',{attrs:{"rowData":_vm.rowData,"is-show":_vm.isShowModal},on:{"close":function($event){_vm.isShowModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }