import SearchBtn from "@/components/Search/SearchBtn";

export default {
	components: {
		SearchBtn,
	},
	data: () => ({
		FILED_CLASS: "d-flex align-center col-lg-3 col-md-6 col-12",
		RAW_CLASS: "col-xl-1 col-lg-12 col-md-12 col-sm-12 col-12 text-no-wrap",
		CLASS1: "d-flex align-center col-xl-3 col-md-5 col-12" // UserStat, CalcStat, CalcHist 에서 사용
	}),
}