<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="정산작업">
          <v-flex>
            <v-btn
                class="grey white--text mr-3"
                @click="showCreateModal"
            >정산 Batch
              <v-icon>mdi-wrench</v-icon>
            </v-btn>
            <b> * 해당작업은 조직관리 작업 이후 실행하여 내/외부 실적을 반영합니다.</b>
            <sales-modal
                :is-show="isShow"
                :is-modify="isModify"
                :partner-data="modalData"
                @close="isShow = false"
            />
          </v-flex>
      </search-area>
      <v-divider class="mb-2"/>
      <search-area title="필수조건">
        <field-input title="조회단계">
          <v-select
              v-model="searchType"
              :items="orgGbList"
              item-text="oname"
              item-value="ovalue"
          />
        </field-input>
        <div class="col col-2">
        </div>
        <field-input title="실적년월">
          <v-text-field
              v-model="fromYm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              placeholder="yyyymm"
          />
          <div class="col col-1"> ~
          </div>
          <v-text-field
              v-model="toYm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              placeholder="yyyymm"
          />
        </field-input>
      </search-area>

      <v-divider class="mb-2"/>

      <search-area title="선택조건">
        <field-input title="조직명">
          <v-text-field
              v-model="srhNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <div class="col col-2">
        </div>
        <field-input title="조회범위">
          <div  class="col col-12">
            <v-radio-group
                v-model="occGb"
                row
            >
              <v-radio label="실적발생분"  value="1"/>
              <v-radio label="미발생분포함" value="2"/>
            </v-radio-group>
          </div>
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSalesList"/>
    </template>

  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import SalesModal from "@/pages/Settlement/components/SalesList/SalesModal";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {SearchArea, SearchBarLayout, FieldInput, SalesModal},
  async beforeMount() {
    this.fromYm = '';
    this.toYm = '';
  },
  data: () => ({
    orgGbList: [
      { oname:'전체',   ovalue:'70' },
      { oname:'부문총괄', ovalue:'10' },
      { oname:'부문',   ovalue:'20' },
      { oname:'총괄',   ovalue:'30' },
      { oname:'소속',   ovalue:'40' },
      { oname:'파트너',  ovalue:'50' },
      { oname:'부서',   ovalue:'60' },
    ],
    searchType: "70",
    fromYm: "",
    toYm: "",
    srhNm: "",
    occGb: "1",
    modalData: undefined,
    isShow: false,
    isModify: false
  }),
  computed: {

  },
  methods: {
    getSalesList() {
      const params = {
        fromYm : this.fromYm.replace(/[^0-9]/gi, ""),
        toYm : this.toYm.replace(/[^0-9]/gi, ""),
        searchType : this.searchType,
        srhNm : this.srhNm,
        occGb : this.occGb,
      }
      if(this.srhNm) params["srhNm"] = this.srhNm;
      if(this.occGb) params["occGb"] = this.occGb;
      this.$emit('getSalesList', params)
    },
    showCreateModal() {
      this.modalData = {};
      this.isModify = false;
      this.isShow = true;
    },
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>