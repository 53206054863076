<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-4 col-12">
          <v-col cols="4">직원명</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="customerNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                @keypress.enter="getSearch"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-4 col-12">
          <v-col cols="4">관리자 역할</v-col>
          <v-col cols="8">
            <v-select
                v-model="adminRole"
                :items="adminRoleList"
                background-color="white"
                item-text="adminRoleNm"
                item-value="adminRole"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-4 col-12">
          <v-col cols="4">재직 여부</v-col>
          <v-col cols="8">
            <v-select
                v-model="activeYn"
                :items="activeYnList"
                background-color="white"
                item-text="name"
                item-value="value"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>

  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchBtn from "@/components/Search/SearchBtn";
import SearchArea from "@/components/Search/SearchArea";

export default {
  components: {SearchBarLayout, SearchBtn, SearchArea},
  data() {
    return {
      customerNm: "",

      adminRole: "",
      adminRoleList: [
        {adminRoleNm: '전체', adminRole: ''},
        {adminRoleNm: '상담실장', adminRole: 'CS'}
      ],

      activeYn: "",
      activeYnList: [
        {name:'전체', value: ''},
        {name:'재직중', value: 'Y'},
        {name:'퇴사', value: 'N'}
      ]
    }
  },
  methods: {
    
    getSearch() {
      const params = {
        customerNm: this.customerNm,
        adminRole: this.adminRole,
        activeYn: this.activeYn
      }
      this.$emit('getSearch', params);
    },


  }
}
</script>