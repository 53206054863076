<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <v-col :class="CLASS1">
          <v-col cols="3">조회기간</v-col>
          <v-col>
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getCalcStat"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchArea from "@/components/Search/SearchArea";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import DateInput from "@/components/Form/DateInput";
import SearchBtn from "@/components/Search/SearchBtn";
import DateMixin from "@/mixin/DateMixin";
import StringMixin from "@/mixin/StringMixin";
import SearchBarMixin from "@/mixin/SearchBarMixin";

export default {
  mixins: [DateMixin, StringMixin, SearchBarMixin],
  components: {SearchBtn, DateInput, SearchBarLayout, SearchArea},
  beforeMount() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const [year, month] = [date.getFullYear(), date.getMonth()];
    this.startDate = `${year}-${this.addZero(month + 1)}-01`;
    this.endDate = `${year}-${this.addZero(month + 1)}-${new Date(year, month + 1, 0).getDate()}`;
  },
  data: () => ({
    startDate: "",
    endDate: "",
  }),
  methods: {
    getCalcStat() {
      this.$emit('search', {
        startYmd: this.replaceOnlyNum(this.startDate),
        endYmd: this.replaceOnlyNum(this.endDate)
      })
    },
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    }
  }
}
</script>

<style scoped>

</style>