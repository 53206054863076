<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ boardList.length }}개)</span>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="boardList"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="headers"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >

        <template v-slot:item.boardType="{ value }">
          <div class="cell">{{ getBoardTypeNm(value) }}</div>
        </template>
        <template v-slot:item.clickCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

      </v-data-table>

      <board-detail
          :dialog="dialog"
          :sparams="sparams"
          :boardRow="boardRow"
          :displayContYn="displayYn"
          @close="dialog = false"
          @updateSetting="updateSetting()"
      >
      </board-detail>
    </v-flex>
  </v-col>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import TableMixin from "@/mixin/TableMixin";
import BoardDetail from "@/pages/Manage/Board/components/BoardDetail";
import store from "@/store";

export default {

  mixins: [TableMixin],
  props: ["boardList", "loading", "sparams"],
  components: {BoardDetail},
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },

  data: () => ({
    // headers: Needs_HEADER
    headers: [
      {text: "등록번호", value: "boardNo", class: "teal lighten-4", align: "center", sortable: true},
      {text: "게시판유형", value: "boardType", class: "teal lighten-4", align: "center", sortable: false},
      {text: "제목", value: "title", class: "indigo lighten-4", align: "center", sortable: false},
      {text: "사용자범위", value: "userRangesNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "가입경과상태", value: "elapsedStatusNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "표현유형", value: "dispType", class: "teal lighten-4", align: "center", sortable: false},
      {text: "공지시작일", value: "noticeFromDt", class: "indigo lighten-4", align: "center", sortable: false},
      {text: "공지종료일", value: "noticeToDt", class: "indigo lighten-4", align: "center", sortable: false},
      {text: "종료기간이후표시여부", value: "displayContYn", class: "indigo lighten-4", align: "center", sortable: false},
      {text: "조회수", value: "clickCnt", class: "indigo lighten-4", align: "center", sortable: false},
      {text: "최종작업자", value: "regNm", class: "light-blue lighten-4", align: "center", sortable: false},
      {text: "등록일시", value: "updateDate", class: "light-blue lighten-4", align: "center", sortable: false}
    ],
    date: new Date().toISOString().substr(0, 7),
    dialog: false,
    boardNo: 0,
    boardRow: {},
    displayYn:"",
  }),
  methods: {

    clickContents(row) {
      this.dialog = false
      for (var i in this.boardList) {
        if (this.boardList[i] === row) {
          this.dialog = true;
          this.boardNo = this.boardList[i].boardNo
        }
      }
      if (this.dialog == true) {
        this.getContentsAPI(this.boardNo)
      }
      this.displayYn = row.displayContYn;
    },

    async getContentsAPI(boardNo) {
      const params = {
        boardNo: boardNo,
        memberId: store.getters['login/memberId']
      }
      let response;
      try {
        response = await axiosInstance.get("/public/board/BoardDetail", {params});
        this.boardRow = response.data;
        this.$emit('regist');
      } catch (e) {
        console.log(e)
        alert("공지사항 상세읽기에 실패했습니다.")
      }
    },
    setDialog(value) {
      this.dialog = value
    },
    updateSetting() {
      this.dialog = false
      this.$emit('refreshSetting', this.sparams)
    },
    getBoardTypeNm(boardType) {
      switch(boardType){
        case "GE":
          return "일반";
        case "EV":
          return "이벤트";
        case "EQ":
          return "이벤트퀴즈";
        case "QA":
          return "FAQ";
        default:
          return "";
      }
    },
  }
}
</script>