<template>
  <v-overlay
      v-model="show"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="70vw" max-height="90vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">공지/이벤트 입력</span>
        </v-card-title>
        <v-card-text>
          <v-row class="px-6">
            <v-col :class="inputClass">
              <span class="subtitle-1">게시판유형</span>
              <v-select
                  v-model="searchType1"
                  :items="boardType"
                  item-text="bname"
                  item-value="bvalue"
                  outlined dense
                  background-color="white"
                  @change="changeType"
              />
            </v-col>
            <v-col :class="inputClass">
              <span class="subtitle-1">회원가입경과상태</span>
              <v-select
                  v-model="searchType2"
                  :items="elapsedStatus"
                  item-text="ename"
                  item-value="evalue"
                  outlined dense
                  background-color="white"
              />
            </v-col>
            <v-col :class="inputClass">
              <span class="subtitle-1">사용자범위</span>
              <v-select
                  v-model="searchType3"
                  :items="userRanges"
                  item-text="uname"
                  item-value="uvalue"
                  outlined dense
                  background-color="white"
              />
            </v-col>
          </v-row>
          <v-row class="px-6">
            <v-col class="d-flex flex-column col-3">
              <span class="subtitle-1">공지시작일자</span>
              <input
                  class="box-detail"
                  type="date"
                  v-model="noticeFromDt"
              >
            </v-col>
            <v-col class="d-flex flex-column col-3">
              <span class="subtitle-1">시작시분</span>
              <input
                  class="box-detail"
                  type="time"
                  v-model="noticeFromHhmm"
              >
            </v-col>
            <v-col class="d-flex flex-column col-3">
              <span class="subtitle-1">공지종료일자</span>
              <input
                  class="box-detail"
                  type="date"
                  v-model="noticeToDt"
              >
            </v-col>
            <v-col class="d-flex flex-column col-3">
              <span class="subtitle-1">종료시분</span>
              <input
                  class="box-detail"
                  type="time"
                  v-model="noticeToHhmm"
              >
            </v-col>
          </v-row>
          <v-row class="px-6">
            <v-col class="d-flex flex-column col-3">
              <span class="subtitle-1">게시판 표현 유형</span>
              <v-select
                  v-model="dispType"
                  :items="dispTypes"
                  item-text="dname"
                  item-value="dvalue"
                  outlined dense
                  background-color="white"
              />
            </v-col>
            <v-col class="d-flex flex-column col9"></v-col>
          </v-row>
          <v-row class="px-6">
            <v-col class="d-flex flex-column col-12">
              <span class="subtitle-1">제목</span>
              <v-text-field
                  v-model="title"
                  outlined dense
                  background-color="white"
              />
            </v-col>
          </v-row>
          <v-row class="px-6">
            <v-col class="d-flex flex-column col-12">
              <v-textarea
                  v-model="contents"
                  background-color="white"
                  outlined dense
                  placeholder="내용을 등록해주세요"
                  rows="10"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="submit"
          >
            추가
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import {rules} from "@/util/constant"
import store from "@/store";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [CodeMixin, DateMixin],
  props: ["dialog"],
  async created() {

  },
  data: () => ({
    inputClass: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12',
    rules: rules,
    boardType: [
      {bname: '일반', bvalue: 'GE'},
      {bname: '이벤트', bvalue: 'EV'},
      {bname: '이벤트퀴즈', bvalue: 'EQ'},
      {bname: 'FAQ', bvalue: 'QA'}
    ],
    userRanges: [
      {uname: '전체', uvalue: 'AL'},
      {uname: '원클릭', uvalue: 'ON'},
      {uname: '청신호', uvalue: 'GR'},
      {uname: '인카', uvalue: 'IC'},
      {uname: '타FA', uvalue: 'OF'},
      {uname: '제휴회사', uvalue: 'AC'}
    ],
    elapsedStatus: [
      {ename: '전체', evalue: 'AL'},
      {ename: '가입1주이내', evalue: 'W1'},
      {ename: '가입1달이내', evalue: 'M1'},
      {ename: '가입1년이내', evalue: 'Y1'},
      {ename: '가입1년이상', evalue: 'YF'}
    ],
    dispTypes: [
      {dname: '리스트조회', dvalue: 'LIST'},
      {dname: '롤링조회', dvalue: 'ROLLING'},
      {dname: '팝업조회', dvalue: 'POPUP'}
    ],
    searchType1: "GE",
    searchType2: "AL",
    searchType3: "AL",
    title: "",
    contents: "",
    noticeFromDt: "",
    noticeFromHhmm: "",
    dispType:"LIST",
    noticeToDt: "",
    noticeToHhmm: "",
    registTag: "",
    EVDefaultContents: "{ \n" +
        "\"type\":\"EVENT\",\n" +
        "\"title\":\"\", \n" +
        "\"imageUrl\":\"\", \n" +
        "\"thumbnailUrl\": \"\",\n" +
        "\"btnNameLeft\": \"\", \n" +
        "\"btnRouterLeft\": \"\",\n" +
        "\"kakaoYN\": \"\", \n" +
        "}",
    QADefaultContents: "{ \n" +
        "\"type\":\"FAQ\",\n" +
        "\"title\":\"\",\n" +
        "\"imageUrl\": \"\", \n" +
        "\"thumbnailUrl\": \"\"\n" +
        "}",
    EQDefaultContents: "{ \n" +
        "\"answer1\":\"\",\n" +
        "\"answer2\":\"\",\n" +
        "\"answer3\":\"\",\n" +
        "\"answer4\":\"\",\n" +
        "\"correct_answer\":\"\", \n" +
        "\"imageUrl\":\"\", \n" +
        "\"thumbnailUrl\": \"\",\n" +
        "}",
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.$emit("close", value)
      }
    }
  },
  methods: {
    async submit() {
      if (this.noticeFromDt > this.noticeToDt) {
        alert("공지시작일자: " + this.noticeFromDt + " 가 공지종료일자: " + this.noticeToDt + " 보다 더 앞선 날짜 입니다!");
      } else {
        confirm(`자료를 추가하시겠습니까?`) && await this.saveBoard();
      }
    },
    async saveBoard() {
      try {
        let params = {};
        params.boardType = this.searchType1;
        params.userRanges = this.searchType2;
        params.elapsedStatus = this.searchType3;
        params.noticeFromDt = this.noticeFromDt.replace(/-/g, "");
        params.noticeFromHhmm = this.noticeFromHhmm.replace(/:/g, "");
        params.dispType = this.dispType;
        params.noticeToDt = this.noticeToDt.replace(/-/g, "");
        params.noticeToHhmm = this.noticeToHhmm.replace(/:/g, "");
        params.title = this.title;
        params.contents = this.contents;
        params.regId = store.getters['login/memberId'];
        await axiosInstance.post('/admin/board/Board', params);
        alert("저장되었습니다!");
        this.$emit('close');
      } catch (e) {
        console.log(e);
        alert("관리자에게 문의해주세요.");
      }
    },
    close() {
      this.noticeFromDt = "";
      this.noticeFromHhmm = "";
      this.noticeToDt = "";
      this.noticeToHhmm = "";
      this.$emit('close');
    },
    changeType() {
      if (this.searchType1 === 'GE') {
        this.contents = "";
      } else if (this.searchType1 === 'EV') {
        this.contents = this.EVDefaultContents;
      } else if (this.searchType1 === 'QA') {
        this.contents = this.QADefaultContents;
      } else if (this.searchType1 === 'EQ') {
        this.contents = this.EQDefaultContents;
      }
    },
  }
}
</script>

<style scoped>
div .box-detail {
  background-color: white;
  border: 1px solid #72819A;
  border-radius: 5px;
  font-size: 15px;
  padding: 7px;
}
</style>