<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <outlined-btn class="float-right" @click=toExcel :disabled="!hasTableData">엑셀다운로드</outlined-btn>
      <outlined-btn v-if="this.type === 'CONTINUOUS_OPERATION' || this.type === 'MISILJEUK_LINK_SEND'" @click="eventAwarding" class="float-right" style="margin-right: 10px;">
        시상입력
      </outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="10"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(setTypeHeader())"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >

        <template v-slot:item.memberNm1="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.telNum1="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-if="type === 'KATALK_FRIEND'" v-slot:item.regDate1="{ value }">
          <span v-if="value">{{ setExcelDate(value) }}</span>
        </template>
        <template v-else v-slot:item.regDate1="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

        <template v-slot:item.convertDate1="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

        <template v-slot:item.firstContractDate1="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

        <template v-slot:item.lastContractDate1="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

        <template v-slot:item.contractCnt1="{ value }">
          {{ value ? value : '0' }}
        </template>


        <template v-slot:item.memberNm2="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.telNum2="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.regDate2="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

        <template v-slot:item.firstContractDate2="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

        <template v-slot:item.lastContractDate2="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

        <template v-slot:item.contractCnt2="{ value }">
          {{ value ? value : '0' }}
        </template>

        <template v-slot:item.firstSendDate1="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>

      </v-data-table>
    </v-flex>
    <event-lottery-upload-excel-list-modal
        @close="showModal = false"
        :is-show="showModal"
        @updateData="$emit('updateData')"
    />
  </v-col>
</template>

<script>
import {EVENT_FIRST_CONTRACT_HEADER, EVENT_KATALK_FRIEND_HEADER} from "@/util/TableHeaders";
import {EVENT_CONVERT_MEMBER_HEADER} from "@/util/TableHeaders";
import {EVENT_MISILJEUK_LINK_SEND_HEADER} from "@/util/TableHeaders";
import {EVENT_INVITER_HEADER} from "@/util/TableHeaders";
import {EVENT_CONTINUOUS_OPERATION_HEADER} from "@/util/TableHeaders";
import {EVENT_QUIZ_CONTESTANT_HEADER} from "@/util/TableHeaders";
import {EVENT_PRODUCT_RECOMMEND_HEADER} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import _ from "lodash";
import EventLotteryUploadExcelListModal from "@/pages/User/components/EventLottery/EventLotteryUploadExcelListModal"


export default {
  components: {OutlinedBtn, EventLotteryUploadExcelListModal},
  mixins: [TableMixin, CodeMixin, DateMixin],
  props:["loading", "data", "type", "baseYM"],
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  async created() {
  },
  data: () => ({
    headers: null,
    oldType: "FIRST_CONTRACT",
    showModal: false,
  }),
  methods: {
    setTypeHeader(){

      if(this.oldType != this.type)
      {
        this.oldType = this.type
      }

      if("FIRST_CONTRACT" == this.type)
      {
        this.header = EVENT_FIRST_CONTRACT_HEADER;
      }
      else if("INVITER" == this.type)
      {
        this.header = EVENT_INVITER_HEADER;
      }
      else if("CONTINUOUS_OPERATION" == this.type)
      {
        this.header = _.cloneDeep(EVENT_CONTINUOUS_OPERATION_HEADER);
        if(this.baseYM.length == 6)
        {
          var mm = Number(this.baseYM.substring(4));
          for(var i=this.header.length-1; i > 3; i--)
          {
            if(i > mm+17)
            {
              this.header.splice(i,1);
            }
            if(i > 4 && i < 17 && i < mm+5)
            {
              this.header.splice(i,1);
            }
          }
        }
      }
      else if("CONVERT_MEMBER" == this.type)
      {
        this.header = EVENT_CONVERT_MEMBER_HEADER;
      }
      else if("MISILJEUK_LINK_SEND" == this.type)
      {
        this.header = EVENT_MISILJEUK_LINK_SEND_HEADER;
      }
      else if("QUIZ_CONTESTANT" == this.type)
      {
        this.header = EVENT_QUIZ_CONTESTANT_HEADER;
      }
      else if("PRODUCT_RECOMMEND" == this.type)
      {
        this.header = EVENT_PRODUCT_RECOMMEND_HEADER;
      }
      else if("KATALK_FRIEND" == this.type)
      {
        this.header = EVENT_KATALK_FRIEND_HEADER;
      }


      return this.header;
    },
    toExcel(){
      this.$emit('toExcel', this.header);
    },
    eventAwarding() {
      this.showModal = true;
    },
    setExcelDate(item) {
      return item.replace('T', ' ').substr(0, 10);
    }
  }
}
</script>

<style scoped>

</style>