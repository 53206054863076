var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShow)?_c('v-overlay',{attrs:{"light":true,"dark":false}},[_c('div',{staticClass:"close-button"},[_c('span',{on:{"click":_vm.close}},[_vm._v("x")])]),_c('custom-layout',{attrs:{"title":"정산내역 상세"}},[_c('v-card',{attrs:{"width":"90vw","max-width":"2200px"}},[_c('div',{staticClass:"d-flex justify-space-between align-center pt-3"},[_c('div',[_c('span',{staticClass:"text-h6 ml-5 mr-5"},[_vm._v("총 지급건수 : "+_vm._s(_vm.data.length)+"건")]),_c('span',{staticClass:"text-h6 mr-5"},[_vm._v("조기지급액 : "+_vm._s(_vm.inputComma(_vm.totalERewardAmt))+"원")]),_c('span',{staticClass:"text-h6 mr-5"},[_vm._v("환수대상액 : "+_vm._s(_vm.inputComma(_vm.totalHRewardAmt))+"원")]),_c('span',{staticClass:"text-h6 mr-5"},[_vm._v("월정산액 : "+_vm._s(_vm.inputComma(_vm.totalMRewardAmt))+"원")]),_c('span',{staticClass:"text-h6"},[_vm._v("지급액 : "+_vm._s(_vm.inputComma(_vm.totalSRewardAmt))+"원")])]),_c('outlined-btn',{staticClass:"mt-2 mr-3",attrs:{"disabled":_vm.data.length < 1},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-data-table',{staticClass:"pa-3",attrs:{"height":"50vh","loading":_vm.loading,"headers":_vm.getHeader(_vm.headers),"items":_vm.data,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.recipientTelNum",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getTelNum(value)))])]}},{key:"item.customerTelNum",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getTelNum(value)))])]}},{key:"item.contractPremAmt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.rewardAmt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}}],null,false,1405797433)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }