<template>
  <v-overlay
      v-model="show"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="45vw" max-height="90vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">니즈환기 자료 추가</span>
        </v-card-title>
        <v-card-text>
          <v-row class="px-6">
            <insurer-modal-input title="담보그룹명" inputClass="col-5">
              <v-select
                  v-model="searchType1"
                  :items="coverGb"
                  outlined dense
                  background-color="white"
                  item-text="cname"
                  item-value="cvalue"
              />
            </insurer-modal-input>
            <insurer-modal-input title="적용연령대" inputClass="col-5">
              <v-select
                  v-model="searchType3"
                  :items="ageGb"
                  outlined dense
                  background-color="white"
                  item-text="gname"
                  item-value="gvalue"
              />
            </insurer-modal-input>
            <insurer-modal-input title="사용용도" inputClass="col-5">
              <v-select
                  v-model="searchType2"
                  :items="orgGb"
                  background-color="white"
                  outlined dense
                  item-text="uname"
                  item-value="uvalue"
              />
            </insurer-modal-input>
            <insurer-modal-input title="성별" inputClass="col-5">
              <v-select
                  v-model="searchType4"
                  :items="sexGb"
                  background-color="white"
                  outlined dense
                  item-text="sname"
                  item-value="svalue"
              />
            </insurer-modal-input>
            <insurer-modal-input title="태그등록(html)" inputClass="col-12" >
              <v-textarea
                  v-model="contents"
                  background-color="white"
                  outlined dense
                  placeholder="html5-editor.net 에서 변환한 태그를 등록해주세요"
                  rows="6"
              />
            </insurer-modal-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              @click="htmlEditor"
          > HTML Editor 열기
          </v-btn>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="submit"
          >
           추가
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>  
  </v-overlay>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import InsurerModalInput from "@/pages/User/components/InsurerSearch/InsurerModalInput";
import CodeMixin from "@/mixin/CodeMixin";
import store from "@/store";

export default {
  mixins: [CodeMixin],
  components: {InsurerModalInput},
  props: ["dialog"],
  async created() {

    },
  data: () => ({
    inputClass: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12',
    orgGb: [
      { uname:'간지사용',  uvalue:'1' },
      { uname:'개별사용',  uvalue:'2' },
      { uname:'상품안내',  uvalue:'3' }
    ],
    ageGb: [
      { gname:'전체',     gvalue:'AL' },
      { gname:'0~9세',    gvalue:'0T' },
      { gname:'10~14세',  gvalue:'1A' },
      { gname:'15~19세',  gvalue:'1B' },
      { gname:'20~24세',  gvalue:'2A' },
      { gname:'25~29세',  gvalue:'2B' },
      { gname:'30~34세',  gvalue:'3A' },
      { gname:'35~39세',  gvalue:'3B' },
      { gname:'40~44세',  gvalue:'4A' },
      { gname:'45~49세',  gvalue:'4B' },
      { gname:'50~54세',  gvalue:'5A' },
      { gname:'55~59세',  gvalue:'5B' },
      { gname:'60~64세',  gvalue:'6A' },
      { gname:'65세이상', gvalue:'6U' }
    ],
    sexGb: [
      { sname:'전체',     svalue:'0' },
      { sname:'남성',     svalue:'1' },
      { sname:'여성',     svalue:'2' }
    ],
    coverGb: [
      { cname:'사망',     cvalue: 1 },
      { cname:'후유장해',  cvalue: 2 },
      { cname:'3대질병',  cvalue: 3 },
      { cname:'수술',     cvalue: 4 },
      { cname:'실손',     cvalue: 5 },
      { cname:'LTC간병',  cvalue: 6 },
      { cname:'생활(운전자,치아,화재,기타)',  cvalue: 7 },
      { cname:'일당',     cvalue: 8 }
    ],

    searchType0: 0,
    searchType1: 1,
    searchType2: "1",
    searchType3: "AL",
    searchType4: "0",
    searchType5: "1",
    contents:    ""
  }),
    computed: {
			show: {
				get() {
					return this.dialog
				},
				set(value) {
					this.$emit("close", value)
				}        
			}
    },  
  watch: {
  },
  methods: {
    htmlEditor: function () {   
          window.open("https://html5-editor.net/", "_blank");    
    },     
    async submit() {
      confirm(`자료를 추가하시겠습니까?`) && await this.saveInsurer();
    },
    async saveInsurer() {
      try {
        let params = {};
        params.coverageGroupSeq = this.searchType1;
        params.purposeCd = this.searchType2;
        params.ageGroupCd = this.searchType3;
        params.sex = this.searchType4;
        params.contents = this.contents;
        params.regId = store.getters['login/memberId'];
        await axiosInstance.post('/admin/operation/needsinfo', params);
        this.$emit('close');
      } catch (e) {
        console.log(e);
      }
    },
    //async modifyInsurer() {
    //  try {
    //    await axiosInstance.put('/admin/operation/needsinfo', this.params);
    //    this.$emit('afterModify');
    //    this.$emit('close');
    //  } catch (e) {
    //    console.log(e);
    //  }
    //},
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>