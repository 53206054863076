<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area >
        <field-input title="담보그룹">
        <v-select
              v-model="searchType1"
              :items="coverGb"
              background-color="white"
              item-text="cname"
              item-value="cvalue"
              menu-props="auto"
        />
        </field-input>
        <field-input title="사용 용도">
          <v-select
              v-model="searchType2"
              :items="purposeCd"
              background-color="white"
              item-text="uname"
              item-value="uvalue"
          />
        </field-input>
        <field-input title="적용연령대">
          <v-select
              v-model="searchType3"
              :items="ageGb"
              background-color="white"
              item-text="gname"
              item-value="gvalue"
          />
        </field-input>
        <field-input title="사용여부">
          <v-select
              v-model="searchUseYn"
              :items="useYnSt"
              background-color="white"
              item-text="yname"
              item-value="yvalue"
          />
        </field-input>
        <field-input title="생성날짜">              
            <date-input
                :start-date="searchFileStartDate"
                :end-date="searchFileEndDate"
                @updateDate="updateDate"
            />
        </field-input>
        <v-col cols="1" align="center" >
          <v-btn  @click="getneedsInfo">
            조회
          </v-btn>
        </v-col>
        <v-col cols="1" align="center" >
         <v-flex>
          <v-btn
            color="blue darken-4"
            class="white--text col-2"          
           @click="$emit('regist')"
          >등록
          </v-btn>
         </v-flex>
        </v-col>
     </search-area>
    </template>

  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateMixin from "@/mixin/DateMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";

export default {
  props: [],
  mixins: [SearchBarMixin, DateMixin, FieldInput, CodeMixin],
  components: { SearchArea, SearchBarLayout, FieldInput, DateInput},
	async beforeMount() {
    // 시작 날짜, 종료 날짜 초기 설정
    const { fromDate, toDate } = this.setDateDiff(-31);
    this.searchFileStartDate = fromDate;
    this.searchFileEndDate = toDate;
  },  
  async created() {

  },
  data: () => ({
    coverGb: [
      { cname:'전체',     cvalue: 0 },
      { cname:'사망',     cvalue: 1 },
      { cname:'후유장해', cvalue: 2 },
      { cname:'3대질병',  cvalue: 3 },
      { cname:'수술',     cvalue: 4 },
      { cname:'실손',     cvalue: 5 },
      { cname:'LTC간병',  cvalue: 6 },
      { cname:'생활(운전자,치아,화재,기타)',  cvalue: 7 },
      { cname:'일당',     cvalue: 8 }
    ],
    purposeCd: [
      { uname:'전체',     uvalue:'0' },
      { uname:'간지사용',  uvalue:'1' },
      { uname:'개별사용',  uvalue:'2' },
      { uname:'상품안내',  uvalue:'3' }
    ],
    ageGb: [
      { gname:'전체',     gvalue:'AL' },
      { gname:'0~9세',    gvalue:'0T' },
      { gname:'10~14세',  gvalue:'1A' },
      { gname:'15~19세',  gvalue:'1B' },
      { gname:'20~24세',  gvalue:'2A' },
      { gname:'25~29세',  gvalue:'2B' },
      { gname:'30~34세',  gvalue:'3A' },
      { gname:'35~39세',  gvalue:'3B' },
      { gname:'40~44세',  gvalue:'4A' },
      { gname:'45~49세',  gvalue:'4B' },
      { gname:'50~54세',  gvalue:'5A' },
      { gname:'55~59세',  gvalue:'5B' },
      { gname:'60~64세',  gvalue:'6A' },
      { gname:'65세이상',  gvalue:'6U' }
    ],
    useYnSt: [
      { yname:'사용',     yvalue:'Y' },
      { yname:'미사용',   yvalue:'N' }
    ],    
    searchType1: 0,
    searchType2: "0",
    searchType3: "AL",
    searchUseYn: "Y",
    registTag: "",
		searchFileStartDate: "",
		searchFileEndDate: ""    
  }),

  methods: {
    updateDate(values) {
      this.searchFileStartDate = values[0];
      this.searchFileEndDate = values[1];
    },
    getneedsInfo() {
      const params = {
        ageGroupCd : this.searchType3,
        contGb : this.searchType1,
        purpCd : this.searchType2,
        useYn  : this.searchUseYn,
				startDate: this.searchFileStartDate,
				endDate: this.searchFileEndDate        
      }
      console.log(this.params);
      this.$emit('getneedsInfo', params)
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>