<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-card width="80vw" max-width="1215px" max-height="97vh" class="overflow-y-auto py-3 px-6">
      <v-card-title class="headline">
        <v-col cols="12" style="display: flex; justify-content: space-between;">
          <span class="font-weight-bold">담보 금액 목록</span>
          <!-- 닫기 -->
          <div
              color="white darken-4"
              class="black--text col-1"
              style="cursor: pointer; padding: 3px 0; text-align: center; margin: -9px -25px 0 0;"
              @click="close">
            X
          </div>
        </v-col>
      </v-card-title>
      <div class="px-3">
        <v-col cols="12">
          <span>
            {{ cvrData.prProdLineNm }} 보험 | {{ cvrData.creCvrNm }} ({{ cvrData.creCvrCd }}) | {{ cvrData.cvrTypeNm }}
          </span>
          <br/>
          <span class="font-weight-bold">
            최소 {{ inputComma(minAmt) }}원 ~ 최대 {{ inputComma(maxAmt) }}원
          </span>
        </v-col>
      </div>
      <div class="my-2 px-3">
        <span class="text-h6">목록 (총 {{ totalCount }}개)</span>
      </div>
      <v-data-table
          :key="tableKey"
          class="px-5"
          height="50vh"
          :items="list"
          :headers="headers"
          :loading="loading"
          fixed-header
          :footer-props="{'items-per-page-options': [10, -1]}"
          :page="currentPage"
          :items-per-page="itemsPerPage"
      >
        <!-- 번호 -->
        <template v-slot:item.number="{ item }">
          <div v-if="isAddedRow(item.index)" style="text-align: center" type="text">
            +
          </div>
          <div v-else style="text-align: center" type="number">
            {{ item.index }}
          </div>
        </template>

        <!-- 담보금액 -->
        <template v-slot:item.cvrAmt="{ item }">
          <input
              v-if="isAddedRow(item.index)"
              class="jc-fe" type="number"
              color="gray"
              v-model=addedCvrAmt>
          <div v-else type="text" :class="{'bold': item.defaultYn == 'Y'}">
            {{ inputComma(item.cvrAmt) }}
          </div>
        </template>

        <!-- 적용연령 From -->
        <template v-slot:item.frAge="{ item }">
          <input
              v-if="isAddedRow(item.index)"
              class="jc-fe" type="number"
              color="gray"
              v-model=addedFrAge>
          <div v-else type="number" :class="{'bold': item.defaultYn == 'Y'}">
            {{ item.frAge }}
          </div>
        </template>

        <!-- 적용연령 To -->
        <template v-slot:item.toAge="{ item }">
          <input
              v-if="isAddedRow(item.index)"
              class="jc-fe" type="number"
              color="gray"
              v-model=addedToAge>
          <div v-else type="number" :class="{'bold': item.defaultYn == 'Y'}">
            {{ item.toAge }}
          </div>
        </template>

        <!-- 기본값 여부 -->
        <template v-slot:item.defaultYn="{ item }">
<!--          <v-select-->
<!--              v-if="isAddedRow(item.index)"-->
<!--              class="width-100"-->
<!--              v-model="isDefault"-->
<!--              :items="defaultList"-->
<!--              dense outlined hide-details-->
<!--              background-color="white"-->
<!--          />-->
          <div type="text" :class="{'bold': item.defaultYn == 'Y'}">
            {{ item.defaultYn }}
          </div>
        </template>

        <!-- 기본값 등록 -->
        <template v-slot:item.defaultBtn="{ item }">
          <v-btn
              v-if="!hasDefaultY || isAddedRow(item.index)"
              color="teal darken-4"
              class="white--text"
              @click="updateDefaultYn(item.index)"
          >
            등록
          </v-btn>
          <v-btn
              v-if="item.defaultYn == 'Y'"
              @click="updateDefaultYn(item.index)">
            해제
          </v-btn>
        </template>

        <!-- 삭제버튼 -->
        <template v-slot:item.deleteBtn="{ item }">
          <v-btn
              v-if="isAddedRow(item.index)"
              @click="addComplete"
              color="blue darken-4"
              class="white--text"
              :disabled="checkOverAmt(addedCvrAmt)">
            추가
          </v-btn>
          <v-btn
              v-else
              @click="deleteItem(item.index)"
              :disabled="item.defaultYn == 'Y'"
          >
            삭제
          </v-btn>
        </template>

      </v-data-table>

      <v-card-actions class="px-4">
        <v-spacer></v-spacer>
        <!-- 추가하기 -->
        <v-btn
            v-if="!isAdding"
            color="blue darken-4"
            class="white--text col-2"
            @click="addCvrAmt">
          추가하기
        </v-btn>
        <v-btn
            v-else
            color="gray darken-4"
            class="black--text col-2"
            @click="addCancel">
          취소
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import _ from "lodash";
import TableMixin from "@/mixin/TableMixin";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  name: "ProductSpCvrAmtDetailModal",
  mixins: [TableMixin],
  props: ["isShow", "cvrData"],

  data() {
    return {
      tableKey: 0,
      loading: false,

      list: [],
      maxAmt: 0, minAmt: 0,
      headers: [
        {text: "번호", value: "number", class: "indigo lighten-4", align: "center", sortable: false, width: "10%"},
        {text: "담보금액", value: "cvrAmt", class: "lime lighten-4", align: "center", sortable: false, width: "22%"},
        {text: "적용연령From", value: "frAge", class: "lime lighten-4", align: "center", sortable: false, width: "18%"},
        {text: "적용연령To", value: "toAge", class: "lime lighten-4", align: "center", sortable: false, width: "18%"},
        {text: "기본값여부", value: "defaultYn", class: "teal lighten-4", align: "center", sortable: false, width: "10%"},
        {text: "기본값 설정", value: "defaultBtn", class: "lime lighten-4", align: "center", sortable: false, width: "11%"},
        {text: "삭제", value: "deleteBtn", class: "lime lighten-4", align: "center", sortable: false, width: "11%"},
      ],

      isAdding: false,    // 추가하기
      addedCvrAmt: 0,     // 추가할 담보 금액 정보
      addedFrAge: 0,      // 추가할 연령 from
      addedToAge: 0,      // 추가할 연령 to
      isDefault: 'N',     // 기본값 설정
      defaultList: ['Y', 'N'],

      // 페이지
      currentPage: 1,
      itemsPerPage: 10,
    }
  },
  computed: {
    totalCount(){
      return this.isAdding ? this.list.length - 1 : this.list.length;
    },
    hasDefaultY(){
      return this.list.some(el => el.defaultYn.toUpperCase() == 'Y');
    }
  },

  created() {
    if (!_.isEmpty(this.cvrData)) {
      this.loading = true;
      this.getPrCvrGuideAmt(this.cvrData);
    }
  },

  methods: {
    // 담보금액 목록 조회
    async getPrCvrGuideAmt(cvrData){
      let params = {
        prProdLineCd: cvrData.prProdLineCd,
        creCvrCd: cvrData.creCvrCd,
      }
      try {
        let {data} = await axiosInstance.get('/admin/prdata/getPrCvrGuideAmt', {params});
        this.list = data;
      } catch (e) {
        console.log(e);
        this.loading = false;
        return false;
      }
      this.loading = false;

      this.setMaxAndMinAmt();
      this.sortList();
    },

    // row 추가
    addCvrAmt() {
      this.isAdding = true;
      this.list.push({index: this.list.length + 1, cvrAmt: 0, frAge: 0, toAge: 0, defaultYn: 'N'});
      this.initAddedFactor();

      // 추가 시 페이지가 넘어가는 경우
      if (this.list.length % this.itemsPerPage >= 0) {
        this.currentPage++;
        this.tableKey++;
      }
    },
    // 추가 - 완료
    async addComplete() {

      if (this.checkOverAmt(this.addedCvrAmt)) {
        alert("담보 금액 범위를 벗어납니다.");
        return false;
      }
      if (this.hasSameData(this.addedCvrAmt)) {
        alert("이미 존재하는 금액입니다.");
        return false;
      }
      console.log(this.list);
      let addIndex = this.list.length;
      let addObj = {
        prProdLineCd: this.cvrData.prProdLineCd,
        creCvrCd: this.cvrData.creCvrCd,
        cvrAmt: this.addedCvrAmt,
        defaultYn: this.isDefault,
        frAge: this.addedFrAge,
        toAge: this.addedToAge
      };
      this.list[addIndex - 1] = {...addObj, index: addIndex};

      let i_result = await this.setPrCompCvrAmtAge("I", addObj);
      if (i_result == 0) {
        alert('FAIL : 데이터가 추가되지 않았습니다.');
        this.addCancel();
        return false;
      }

      alert(this.inputComma(this.addedCvrAmt) + "원 담보가 추가되었습니다.");

      this.setMaxAndMinAmt();
      this.sortList();
      this.isAdding = false;
    },
    addCancel() {
      this.list.splice(-1);
      this.isAdding = false;
    },

    async updateDefaultYn(index) {
      let updateRow = this.list[index - 1];

      let updateParams = {
        prProdLineCd: this.cvrData.prProdLineCd,
        creCvrCd: this.cvrData.creCvrCd,
        cvrAmt: updateRow.cvrAmt,
        defaultYn: "",
        frAge: updateRow.frAge,
        toAge: updateRow.toAge
      }
      let msg = "";

      if (updateRow.defaultYn == 'N') {
        msg = '으로 등록';
        updateParams.defaultYn = 'Y';
      } else {
        msg = '에서 해제';
        updateParams.defaultYn = 'N';
      }

      if (confirm(this.inputComma(updateRow.cvrAmt) + '원을 기본값' + msg + '합니다.')) {
        let u_result = await this.setPrCompCvrAmtAge("U", updateParams);
        if (u_result == 0) {
          alert('FAIL : 기본값 설정에 실패 하였습니다.');
          return false;
        }
        alert("완료되었습니다.");
        this.list[index - 1].defaultYn = updateRow.defaultYn == 'N' ? 'Y' : 'N';

      }
    },
    // 제거
    async deleteItem(index) {
      let deleteRow = this.list[index -1];

      let deleteParams = {
        prProdLineCd: this.cvrData.prProdLineCd,
        creCvrCd: this.cvrData.creCvrCd,
        cvrAmt: deleteRow.cvrAmt,
        defaultYn: deleteRow.defaultYn,
        frAge: deleteRow.frAge,
        toAge: deleteRow.toAge
      }

      if (confirm(this.inputComma(deleteRow.cvrAmt) + "원 담보금액을 삭제하시겠습니까?")) {
        let d_result = await this.setPrCompCvrAmtAge("D", deleteParams);
        if (d_result == 0) {
          alert('FAIL : 삭제가 실패 하였습니다.');
          return false;
        }

        this.list.splice(index - 1, 1);
        alert("삭제되었습니다.");

        this.setMaxAndMinAmt();
        this.sortList();
      }
    },

    // 작업 구분 (wkType - I: insert / U: update / D: delete)
    async setPrCompCvrAmtAge(wkType, addObj) {
      let status = "";
      let params = {...addObj, wkType: wkType};

      try {
        let data = await axiosInstance.post('/admin/prdata/setPrCompCvrAmtAge', params);
        status = data;
      } catch (e) {
        console.log(e);
        return false;
      }
      return status; // -> 0이면 오류 1이면 성공
    },
    // 추가 row 데이터 초기화
    initAddedFactor(){
      this.addedCvrAmt = 0;
      this.addedFrAge = 0;
      this.addedToAge = 0;
      this.isDefault = 'N';
    },
    // 금액 유효 범위 체크
    checkOverAmt(amt) {
      return amt <= 0;
    },
    // 추가 및 수정시 동일 값 존재여부 확인
    hasSameData(amt) {
      let idx = this.list.findIndex(el => el.cvrAmt == amt);
      return idx >= 0;
    },
    // 추가 담보 row 체크
    isAddedRow(index) {
      return (index === this.list.length) && this.isAdding;
    },
    // 오름차순 정렬
    sortList() {
      this.list.sort((a, b) => a.cvrAmt - b.cvrAmt);
      this.list.forEach((list, i) => {
        list.index = i + 1;
      })
    },
    setMaxAndMinAmt(){
      if (_.isEmpty(this.list)) {
        this.minAmt = 0;
        this.maxAmt = 0;
        return;
      }

      let list = _.cloneDeep(this.list);
      let maxAmt = 0;
      let minAmt = list[0].cvrAmt;

      for (let item of list) {
        if (item.cvrAmt > maxAmt) {
          maxAmt = item.cvrAmt;
        }
        if (item.cvrAmt < minAmt) {
          minAmt = item.cvrAmt;
        }
      }
      this.minAmt = minAmt;
      this.maxAmt = maxAmt;
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.my-2 span {
  margin-left: 10px;
}

.my-2 a {
  text-decoration: none;
  margin: 5px 15px;
}

.my-2 button {
  margin-right: 10px;
}

.bold{
  font-weight: bold;
}
</style>