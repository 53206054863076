<template>
  <v-flex xs12 sm12 md12 lg12 xl12>
    <div class="d-flex align-end mb-1 mt-2">
      <span class="text-h6 font-weight-bold">목록(총 {{ tableData.length }}개)</span>
    </div>
    <v-data-table
        height="40vh" item-key="index"
        :items="tableData" :loading="loading"
        :headers="getHeader(header)"
        dense fixed-header calculate-widths hide-default-footer
    >

      <template v-slot:item.contractPremAmt="{ value }">
        <div class="jc-fe">{{ inputComma(value) }}</div>
      </template>

      <template v-slot:item.exRewardAmt="{ value }">
        <div class="jc-fe">{{ inputComma(value) }}</div>
      </template>

      <template v-slot:item.ainRewardAmt="{ value }">
        <div class="jc-fe">{{ inputComma(value) }}</div>
      </template>

      <template v-slot:item.rewardAmt="{ value }">
        <div class="jc-fe">{{ inputComma(value) }}</div>
      </template>

    </v-data-table>
  </v-flex>
</template>

<script>
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import {RENCAR_SETTLEMENT_HEADER} from '@/util/TableHeaders';

export default {
  mixins: [CodeMixin, DateMixin],
  components: {},
  data: () => ({
    loading: false,
    commaList: ["contractPremAmt", "exRewardAmt", "ainRewardAmt", "rewardAmt"], // ###,###,### 처리 해야 하는 컬럼
    header: RENCAR_SETTLEMENT_HEADER
  }),
  props:["tableData"],
  async created() {

  },
  methods: {
    getHeader(headers) {
      return headers.map(header => ({
        text: header[0],
        value: header[1],
        align: this.commaList.includes(header[1]) ? 'right' : 'center',
        class: 'headers text-no-wrap',
        divider: true,
        fixed: true,
      }))
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
}
</script>

<style>
.isRed, .isRed:hover {
  background-color: rgba(200, 0, 0, .5) !important;
}
</style>
