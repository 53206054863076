var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',{attrs:{"light":true,"dark":false},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('v-card',{staticClass:"overflow-y-auto py-3 px-6",attrs:{"width":"80vw","max-width":"1215px","max-height":"97vh"}},[_c('v-card-title',{staticClass:"headline"},[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("담보 금액 목록")]),_c('div',{staticClass:"black--text col-1",staticStyle:{"cursor":"pointer","padding":"3px 0","text-align":"center","margin":"-9px -25px 0 0"},attrs:{"color":"white darken-4"},on:{"click":_vm.close}},[_vm._v(" X ")])])],1),_c('div',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(_vm.cvrData.prProdLineNm)+" 보험 | "+_vm._s(_vm.cvrData.creCvrNm)+" ("+_vm._s(_vm.cvrData.creCvrCd)+") | "+_vm._s(_vm.cvrData.cvrTypeNm)+" ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" 최소 "+_vm._s(_vm.inputComma(_vm.minAmt))+"원 ~ 최대 "+_vm._s(_vm.inputComma(_vm.maxAmt))+"원 ")])])],1),_c('div',{staticClass:"my-2 px-3"},[_c('span',{staticClass:"text-h6"},[_vm._v("목록 (총 "+_vm._s(_vm.totalCount)+"개)")])]),_c('v-data-table',{key:_vm.tableKey,staticClass:"px-5",attrs:{"height":"50vh","items":_vm.list,"headers":_vm.headers,"loading":_vm.loading,"fixed-header":"","footer-props":{'items-per-page-options': [10, -1]},"page":_vm.currentPage,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [(_vm.isAddedRow(item.index))?_c('div',{staticStyle:{"text-align":"center"},attrs:{"type":"text"}},[_vm._v(" + ")]):_c('div',{staticStyle:{"text-align":"center"},attrs:{"type":"number"}},[_vm._v(" "+_vm._s(item.index)+" ")])]}},{key:"item.cvrAmt",fn:function(ref){
var item = ref.item;
return [(_vm.isAddedRow(item.index))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addedCvrAmt),expression:"addedCvrAmt"}],staticClass:"jc-fe",attrs:{"type":"number","color":"gray"},domProps:{"value":(_vm.addedCvrAmt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.addedCvrAmt=$event.target.value}}}):_c('div',{class:{'bold': item.defaultYn == 'Y'},attrs:{"type":"text"}},[_vm._v(" "+_vm._s(_vm.inputComma(item.cvrAmt))+" ")])]}},{key:"item.frAge",fn:function(ref){
var item = ref.item;
return [(_vm.isAddedRow(item.index))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addedFrAge),expression:"addedFrAge"}],staticClass:"jc-fe",attrs:{"type":"number","color":"gray"},domProps:{"value":(_vm.addedFrAge)},on:{"input":function($event){if($event.target.composing){ return; }_vm.addedFrAge=$event.target.value}}}):_c('div',{class:{'bold': item.defaultYn == 'Y'},attrs:{"type":"number"}},[_vm._v(" "+_vm._s(item.frAge)+" ")])]}},{key:"item.toAge",fn:function(ref){
var item = ref.item;
return [(_vm.isAddedRow(item.index))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addedToAge),expression:"addedToAge"}],staticClass:"jc-fe",attrs:{"type":"number","color":"gray"},domProps:{"value":(_vm.addedToAge)},on:{"input":function($event){if($event.target.composing){ return; }_vm.addedToAge=$event.target.value}}}):_c('div',{class:{'bold': item.defaultYn == 'Y'},attrs:{"type":"number"}},[_vm._v(" "+_vm._s(item.toAge)+" ")])]}},{key:"item.defaultYn",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'bold': item.defaultYn == 'Y'},attrs:{"type":"text"}},[_vm._v(" "+_vm._s(item.defaultYn)+" ")])]}},{key:"item.defaultBtn",fn:function(ref){
var item = ref.item;
return [(!_vm.hasDefaultY || _vm.isAddedRow(item.index))?_c('v-btn',{staticClass:"white--text",attrs:{"color":"teal darken-4"},on:{"click":function($event){return _vm.updateDefaultYn(item.index)}}},[_vm._v(" 등록 ")]):_vm._e(),(item.defaultYn == 'Y')?_c('v-btn',{on:{"click":function($event){return _vm.updateDefaultYn(item.index)}}},[_vm._v(" 해제 ")]):_vm._e()]}},{key:"item.deleteBtn",fn:function(ref){
var item = ref.item;
return [(_vm.isAddedRow(item.index))?_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue darken-4","disabled":_vm.checkOverAmt(_vm.addedCvrAmt)},on:{"click":_vm.addComplete}},[_vm._v(" 추가 ")]):_c('v-btn',{attrs:{"disabled":item.defaultYn == 'Y'},on:{"click":function($event){return _vm.deleteItem(item.index)}}},[_vm._v(" 삭제 ")])]}}])}),_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),(!_vm.isAdding)?_c('v-btn',{staticClass:"white--text col-2",attrs:{"color":"blue darken-4"},on:{"click":_vm.addCvrAmt}},[_vm._v(" 추가하기 ")]):_c('v-btn',{staticClass:"black--text col-2",attrs:{"color":"gray darken-4"},on:{"click":_vm.addCancel}},[_vm._v(" 취소 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }