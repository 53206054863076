<template>
  <v-dialog
      v-model="show"
      width="90vw"
      backgroundcolor="white"
  >
    <v-card height="80vh" class="overflow-y-auto modalBackground" style="padding:10px">
      <div
          style="margin: 4px 0 8px 12px; font-size:30px;">
        조 직 : <span style="font-weight: 700">{{orgName}}</span>
        <span style="float: right; font-size: 20px; margin-right: 10px;">
          조회기간 : {{ fromYm }} ~ {{ toYm }}</span>
      </div>
      <v-layout row style="margin-bottom: 2vh">
        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="28vw"
                  class="ml-5 pl-3" elevation="8">
            <v-card-title>월별 실적 현황</v-card-title>
            <v-divider></v-divider>
            <perform-chart
                style="height: 20vw; width:40vw; margin-top:1vh;"
                :monthArray="this.monthArray"
                :resData="this.resData"
            />
          </v-card>
        </v-flex>

        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="28vw"
                  class="pl-3" elevation="8">
            <v-card-title>월별 리워드 현황</v-card-title>
            <v-divider></v-divider>
            <reward-chart
                style="height: 20vw; width:40vw; margin-top:1vh;"
                :monthArray="this.monthArray"
                :resData="this.resData"
            />
          </v-card>
        </v-flex>

        </v-layout>
      <v-layout row style="margin-bottom: 2vh">

        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="28vw"
                  class="ml-5 pl-3" elevation="8">
            <v-card-title>월별 실적발생자 현황</v-card-title>
            <v-divider></v-divider>
            <performer-chart
                style="height: 20vw; width:40vw; margin-top:1vh;"
                :monthArray="this.monthArray"
                :resData="this.resData"
            />
          </v-card>
        </v-flex>

        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="28vw"
                  class="pl-3" elevation="8">
            <v-card-title>월별 다건 실적발생자 현황</v-card-title>
            <v-divider></v-divider>
            <multi-chart
                style="height: 20vw; width:40vw; margin-top:1vh;"
                :monthArray="this.monthArray"
                :resData="this.resData"
            />
          </v-card>
        </v-flex>
      </v-layout>

    </v-card>
  </v-dialog>
</template>

<script>
import PerformChart from "./Charts/PerformChart";
import RewardChart from "./Charts/RewardChart";
import PerformerChart from "./Charts/PerformerChart";
import MultiChart from "./Charts/MultiChart"

export default {

  components: {PerformChart, RewardChart, PerformerChart, MultiChart},

  props: ["dialog", "monthArray", "resData", "orgName", "fromYm", "toYm"],

  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("updateSetting", value)
      },
    },
  },
}
</script>
