<template>
  <v-container class="main-container">
    <v-card class="card-container" elevation="5">
      <v-card-text class="card-text">
        <slot name="card-text"></slot>
      </v-card-text>
      <v-card-actions class="card-footer">
        <slot name="card-button"></slot>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ShowAlertPopup",
}
</script>

<style scoped>
.main-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.card-container {
  width: 400px;
  max-height: 70vh;
  padding: 30px;
  border: 3px solid #1772C7;
  border-radius: 15px;
}

.card-text {
  text-align: center;
}

.card-footer {
  justify-content: center;
}
</style>
