<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-flex>
      <v-card max-width="80vw" max-height="100vh" class="overflow-y-auto">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">알림톡 발송 입력</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <div class="mb-2" style="margin-top: 5px;">
          <span class="text-h6" style="margin-left: 15px;">상담결과: {{rowData.consultResult}}</span>
          <v-btn @click="sendAlarm" class="light-blue white--text theme--light float-right" style="margin-right: 10px;">발송</v-btn>
        </div>
        <v-data-table
          class="mrg"
          height="30vh"
          :items="alarmData"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="headers"
          v-model="selectData"
          show-select multi-sort="multi-sort"
          item-key="index"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          >
          <template v-slot:item.message="{ value, item, header }">
            <text-input
                v-if="item.tempCd.includes('MSG')"
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                @update="changeValue"
                />
            <div v-else>{{messageList[item.index].message.message}}</div>
          </template>
<!--          <template v-slot:item.message = "{ item }">-->
<!--            {{messageList[item.index].message.message}}-->
<!--          </template>-->
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-1"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
import TextInput from "../../../Settlement/components/TextInput";
import _ from "lodash";
export default {
  components: {TextInput},
  props:["isShow", "rowData", "alarmData"],
  data:() => ({
    headers: [
      {text: "대상", value: "targetType", class: "teal lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "알림톡 유형", value: "tempCd", class: "teal lighten-4", align: "center", sortable: false, width: "15vw"},
      {text: "내용", value: "message", class: "teal lighten-4", align: "center", sortable: false, width: "40vw"},
      ],
    messageList: [],
    selectData:[]
  }),
  watch: {
    alarmData: function (obj) {
      this.messageList = obj;
      if (!this.messageList[0].tempCd.includes('MSG')) {
        for (let i = 0; i < obj.length; i++) {
          this.messageList[i].message = JSON.parse(this.messageList[i].message)
        }
      }
    }
  },
  methods: {
    close() {
      this.selectData=[];
      this.$emit('close');
    },
    changeValue(text, id, header) {
      const item = _.find(this.messageList, {index: id});
      item.imessage = text;
      item[header] = text;
    },
    async sendAlarm() {
      if (_.isEmpty(this.selectData)) return;
      const sendNotificMsgList = [];
      if (!this.messageList[0].tempCd.includes('MSG')){
        for (let i = 0; i < this.selectData.length; i++) {
          this.selectData[i].imessage = '';
        }
      }
      for (let i = 0; i < this.selectData.length; i++) {
        const listData = {
          customerNm : this.rowData.customerNm,
          customerTelNum : this.rowData.customerTelNum,
          designSupId: this.rowData.designSupId,
          infoServiceCd: this.selectData[i].tempCd,
          memberId: this.rowData.memberId,
          regId: store.getters['login/memberId'],
          imessage: this.selectData[i].imessage
        };
        sendNotificMsgList.push(listData);
      }
      if (confirm("알림톡 발송을 하겠습니까?")) {
        try {
          await axiosInstance.post('/private/operation/sendNotificMsg', sendNotificMsgList);
          alert("발송되었습니다.");
          this.$emit('seqVal', this.rowData.designSupId);
          if (this.messageList[0].tempCd.includes('MSG')) {
            await this.save();
          }
          this.close();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async save() {
      let designSupTbDto = [];
      let consultMemo = '';
      if (this.messageList[0].tempCd.includes('MSG')) {
        for (let i = 0; i < this.selectData.length; i++) {
          if (this.selectData[i].targetType === '고객') {
            consultMemo += '고객: ' + this.selectData[i].imessage + ' '
          }
          if (this.selectData[i].targetType === 'FA') {
            consultMemo += 'FA: ' + this.selectData[i].imessage + ' '
          }
        }
        this.rowData.consultMemo = consultMemo;
        designSupTbDto = this.rowData;
        delete designSupTbDto.createDate;
        delete designSupTbDto.updateDate;
      }
      try {
        await axiosInstance.post('/admin/operation/saveDesignSup', designSupTbDto);
        this.$emit('updateSetting')
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>
.mrg {
  margin-right: 10px;
  margin-left: 10px;
}
.v-data-table::v-deep td {
  white-space: normal!important;
  word-break: keep-all;
}
</style>