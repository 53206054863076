
<template>
<!-- by sk.jeon -->
  <div >
    <search-area >
      <v-col  cols="11">
      </v-col>
      <v-col cols="1" align-self='center'>
        <v-btn 
          elevation="2"
          @click="makeExcelFile">엑셀 저장
        </v-btn>
      </v-col>
    </search-area>
    <v-data-table class="mt-5" :headers="headers" :loading="loading" :items="data">
      <template v-slot:item.oneclick_total_count="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.oneclick_car_calculation="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.oneclick_link_count="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.greenlight_total_count="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.greenlight_car_calculation="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.greenlight_link_count="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TableMixin from "@/mixin/TableMixin"
import SearchArea from "@/components/Search/SearchArea";
import { axiosInstance } from "@/util/AxiosModule";
import Xlsx from "xlsx";
export default {
  mixins: [TableMixin],
  components: { SearchArea },
  data: () => ({
    components: {SearchArea},
    headers: [{ text: "날짜(월)", value: "create_month", class: "teal lighten-4", align: "center", sortable: false }, 
              { text: "원클릭 회원 수", value: "oneclick_total_count", class: "red lighten-4", align: "center", sortable: false},
              { text: "비교견적 보험 조회 수", value: "oneclick_car_calculation", class: "red lighten-4", align: "center", sortable: false },
              { text: "원클릭 링크 연결 수", value: "oneclick_link_count", class: "red lighten-4", align: "center", sortable: false},
              { text: "청신호 회원 수", value: "greenlight_total_count", class: "blue lighten-4", align: "center", sortable: false },
              { text: "비교견적 보험 조회 수", value: "greenlight_car_calculation", class: "blue lighten-4", align: "center", sortable: false },
              { text: "청신호 링크 연결 수", value: "greenlight_link_count", class: "blue lighten-4", align: "center", sortable: false },
              ],
    data: [],
    loading: false    
  }),
  
  async created() {
    this.loading = true;

    let axiosData;

    try{
      axiosData = await axiosInstance.get('/admin/operation/performance-total')

    } catch(e) {
      console.log(e)
    }
    const { data } = axiosData;
    let allData = {}
    for( var index in data) {
      for( var key in data[index]) {
        if (key == "create_month") {
          allData[key] = "합계"
        }else {
          if (allData[key] == null) {
            allData[key] = data[index][key]
          } else {
            allData[key] += data[index][key]
          }
        }
      }
    }
    data.unshift(allData);

    this.data = data
    this.loading = false;

  },
  methods: {
    makeExcelFile () {
      const workBook = Xlsx.utils.book_new()
      let export_list = []
      for (var index in this.data) {
        let item = {}
        for (var key in this.data[index]) {
          for (var header_index in this.headers) {
            if (this.headers[header_index]["value"] == key) {
              item[this.headers[header_index]["text"]] = this.data[index][key]
              break;
            }
          }
        }
        export_list.push(item)
      }
      const workSheet = Xlsx.utils.json_to_sheet(export_list)
      Xlsx.utils.book_append_sheet(workBook, workSheet, '유입현황 전체')
      Xlsx.writeFile(workBook, '유입현황_전체.xlsx')
    }
  } 
};
</script>