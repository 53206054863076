<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-card width="800px">
      <v-card-title>
        <v-col cols="12">
          <span class="font-weight-bold text-sm-h5">컨텐츠 발송 상세보기</span>
        </v-col>
      </v-card-title>
      <div class="mb-2">
        <span class="text-sm-h6" style="padding-left: 10px;">목록 (총 {{data.length}}개)</span>
      </div>
      <v-divider :dark="true" :light="false"/>
      <v-col cols="12" class="py-0" style="padding: 0;">
        <v-sheet>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left th-font" style="width: 421px;">
                  컨텐츠명: {{rowData.contTypeNm}}
                </th>
                <th class="text-left th-font">
                  발송일자: {{getDateDashFormat(rowData.toDate)}}
                </th>
              </tr>
              </thead>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-col>
      <v-data-table
          height="40vh"
          v-model="selectData"
          :items="data"
          :headers="getHeader(headers)"
          show-select
          item-key="index"
          fixed-header calculate-widths
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >

        <template v-slot:item.telNum = "{ value }">
          {{ getTelNum(value) }}
        </template>

      </v-data-table>
      <v-card-actions>
        <v-btn
            color="green darken-1"
            class="white--text col-2"
            @click="resendAlarmTalk">
          알림톡 재발송
        </v-btn>
        <v-btn
            color="red darken-4"
            class="white--text col-2"
            @click="close">
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import {DISEASE_DETAIL_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import store from "@/store";
import DateMixin from "@/mixin/DateMixin";


export default {
  mixins: [TableMixin, DateMixin],
  props: ["isShow", "data", "rowData"],
  data() {
    return {
      headers:DISEASE_DETAIL_TABLE,
      selectData: [],
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async resendAlarmTalk() {
      if (_.isEmpty(this.selectData)) return;

      let memberList = this.selectData;

      if (confirm("알림톡을 재발송하시겠습니까?")) {
        try {
          await axiosInstance.post(`/admin/operation/eventAwardingTalk`, memberList, {
            params: {
              regId: store.getters['login/memberId'],
            }
          })
          alert("재발송되었습니다.");
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
}
</script>