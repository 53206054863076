<template>
  <v-col cols="12" class="py-0">
    <div class="my-2">
      <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
    </div>
    <v-flex  xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="tableData"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="selectRow"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <!-- show-select -->
        <template v-slot:item.insCd="{ value }">
          <div>{{ getInsCdName(value) }}</div>
        </template>
        
        <template v-slot:item.stDt="{ value }">
          <div>{{ getDateFormat(value) }}</div>
        </template>
        <template v-slot:item.closeDt="{ value }">
          <div>{{ getDateFormat(value) }}</div>
        </template>

        <template v-slot:item.createDate="{ value }">
          <div>{{ replaceDate(value) }}</div>
        </template>
        <template v-slot:item.wkTgYn="{ value }">
          <div>{{ getWkTgYnName(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import {INS_TERM_INFO_MGMT_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
export default {
  mixins: [TableMixin],
  props: ["tableData", "loading", 'prProdLineCdList', 'insCdList'],
  data: () => ({
   headers: INS_TERM_INFO_MGMT_TABLE,
    wkTgYnList: [
      {name: '미결정', value: null},
      {name: '자동수집대상', value: 'Y'},
      {name: '수기수집대상', value: 'M'},
      {name: '비대상', value: 'N'}
    ],
  }),

  methods: {
    selectRow(row) {
      this.$emit('selectRow', row);
    },
    getDateFormat(date) {
      let d = date ? date.toString() : '';
      return d ? `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6)}` : '';
    },
    replaceDate(date) {
      let d = date ? date.toString() : '';
      return d ? date.replace('T', ' ').substr(0, 19) : '';
    },

    getWkTgYnName(value){
      const item = this.wkTgYnList.find(items => items.value === value);
      return item ? item.name : null;
    },
    getInsCdName(value){
      const item = this.insCdList.find(items => items.cd === value);
      return item ? item.value : null;
    }

  }
}
</script>

<style scoped>
.marginBtn {
  margin-bottom: 5px;
}
</style>