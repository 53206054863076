export const API_SERVER_URL =
	process.env.NODE_ENV === 'development'
		? "https://new-dev.greenlight.direct"
		: "https://new-prod.greenlight.direct"

export const API_SERVER_URL_DEV = "https://new-dev.greenlight.direct"
export const API_SERVER_URL_PROD = "https://new-prod.greenlight.direct"

export const rules = {
	nn: [value => !!value || '내용을 입력해주세요'],
	en: [value => /^[a-zA-Z]*$/.test(value) || '영어만 입력해주세요'],
	kr: [value => /^[가-힣]*$/.test(value) || '영어만 입력해주세요'],
	num: [value => !!/^[0-9]*$/.test(value) || '숫자만 입력해주세요'],
	enNum: [value => !!/^[A-Za-z0-9+]*$/.test(value) || '숫자 및 영어만 입력해주세요'],
	hyphenNum: [value => (!!/^[0-9](?:[0-9]|-(?!-))*[0-9]$/.test(value) || !value) || '숫자와 -(하이픈)만 입력해주세요'],
	email: [value => !!/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(value) || '이메일 형식을 입력해주세요'],
	businessNumber: [value => (/^[0-9]{3}-[0-9]{2}-[0-9]{5}/.test(value) || (value.length === 10 && !!/^[0-9]*$/.test(value)) ) || '사업자번호 양식에 맞춰주세요'],
	maxLengthRule: [value => !(value && value.length > 8) || '8자리 최대 입력']
};

const types = {
	target: [
		{
			text: "원클릭다이렉트",
			value: "ONECLICK"
		},
		{
			text: "청신호",
			value: "GREENLIGHT"
		},
	],
	rewardType: [
		{
			text: "보험가입리워드",
			value: "보험가입리워드"
		},
		{
			text: "친구초대리워드",
			value: "친구초대리워드"
		},
		{
			text: "광고비",
			value: "광고비"
		},
		{
			text: "추가리워드",
			value: "추가리워드"
		},
		{
			text: "정정리워드",
			value: "정정리워드"
		}
	]
}

const SORT_TYPE = {
	AMOUNT: "amt",
	SCORE: "score"
}

export const SettlementTableFields = [
	{
		type: "text",
		name: "customerCarNo",
		slotName: "item.customerCarNo"
	},
	{
		type: "text",
		name: "customer.nm",
		slotName: "item.customer.nm"
	},
	{
		type: "text",
		name: "customer.telNum",
		slotName: "item.customer.telNum"
	},
	{
		type: "text",
		name: "rewardAmt",
		rules: rules.num,
		slotName: "item.rewardAmt"
	},
	{
		type: "text",
		name: "contractPrem",
		rules: rules.num,
		slotName: "item.contractPrem"
	},
	{
		type: "select",
		name: "rewardType",
		selectItem: types.rewardType,
		slotName: "item.rewardType"
	},
	{
		type: "select",
		name: "settlementTarget",
		selectItem: types.target,
		slotName: "item.settlementTarget"
	},
];

// 상품추천 - 상품종류
const PR_PROD_LINE_CD = [
	{name: "암", cd: "PRODLN0001"},
	{name: "통합", cd: "PRODLN0002"},
	{name: "운전자", cd: "PRODLN0003"},
	{name: "자녀", cd: "PRODLN0004"},
	{name: "유병자", cd: "PRODLN0005"},
	{name: "종신", cd: "PRODLN0007"},
	{name: "치아", cd: "PRODLN0008"},
];

// 상품추천 - 보험사종류
const INS_CD = [
	{name: "라이나생명", cd: "L51"},
	{name: "DB생명", cd: "L71"},
	{name: "메리츠화재", cd: "N01"},
	{name: "한화손보", cd: "N02"},
	{name: "롯데손보", cd: "N03"},
	{name: "흥국화재", cd: "N05"},
	{name: "삼성화재", cd: "N08"},
	{name: "현대해상", cd: "N09"},
	{name: "KB손보", cd: "N10"},
	{name: "DB손보", cd: "N13"},
	{name: "메트라이프", cd: "L72"},
];

const INS_NAME = [
	{name: '전체', value: 'ALL'},
	{name: '현대해상', value: 'HD'},
	{name: 'DB손해보험', value: 'DB'},
	{name: 'KB손해보험', value: 'KB'},
	{name: '하나손해보험', value: 'HN'},
	{name: '흥국화재', value: 'HK'},
	{name: '캐롯손해보험', value: 'CR'},
	{name: '한화손해보험', value: 'HW'},
	{name: '롯데손해보험', value: 'LO'},
	{name: 'AXA손해보험', value: 'AXA'},
	{name: '메리츠화재', value: 'MZ'},
	{name: '삼성화재', value: 'SS'},
	{name: 'MG손해보험', value: 'MG'}
];

// 상품추천 - 상품종류
const PROD_TYPE = [
	{prodType: "",  prodTypeNm: "선택"},
	{prodType: "0", prodTypeNm: "통합1(갱신,비갱신)"},
	{prodType: "1", prodTypeNm: "갱신형"},
	{prodType: "2", prodTypeNm: "비갱신형"},
	{prodType: "3", prodTypeNm: "통합2(표준,무해지)"},
	{prodType: "4", prodTypeNm: "표준형"},
	{prodType: "5", prodTypeNm: "무해지(0%,50%)"},
	{prodType: "6", prodTypeNm: "통합3(연만기)"},
	{prodType: "7", prodTypeNm: "연만기"},
	{prodType: "9", prodTypeNm: "통합4(세만기)"},
	{prodType: "A", prodTypeNm: "세만기_무해지형"},
	{prodType: "C", prodTypeNm: "기본형"},
	{prodType: "D", prodTypeNm: "체증형"},
	{prodType: "E", prodTypeNm: "무해지(0%,50%)_325"},
	{prodType: "F", prodTypeNm: "무해지(0%,50%)_335"},
	{prodType: "H", prodTypeNm: "무해지(0%,50%)_355"}
];

const GUIDE_INSURER_CODE = [
	{insCdNm: "선택", insCd: ""},
	{insCdNm: "현대해상", insCd: "HD"},
	{insCdNm: "DB손보", insCd: "DB"},
	{insCdNm: "KB손보", insCd: "KB"},
	{insCdNm: "한화손보", insCd: "HW"},
	{insCdNm: "메리츠화재", insCd: "MZ"},
	{insCdNm: "흥국화재", insCd: "HK"},
	{insCdNm: "하나손보", insCd: "HN"},
	{insCdNm: "AXA손보", insCd: "AXA"}
];

// 프로그램 권한 관리 --
const USE_YN_LIST = [
	{name: "전체", cd: ""},
	{name: "사용", cd: "Y"},
	{name: "미사용", cd: "N"}
];
// 추출되는 엑셀의 위험 등급
const XLS_GRADE_LIST = [
	{name: "전체", cd: ""},
	{name: "높음", cd: "HIGH"},
	{name: "중간", cd: "MIDDLE"},
	{name: "낮음", cd: "LOW"},
	{name: "없음", cd: "NONE"}
];
// 상단 메뉴 그룹
const PROGRAM_MENU_GROUP_LIST = [
	{name: "전체", cd: ""},
	{name: "운영현황", cd: "0"},
	{name: "상품추천", cd: "1"},
	{name: "운영관리", cd: "2"},
	{name: "정산관리", cd: "3"},
	{name: "시스템관리", cd: "4"},
	{name: "기능테스트", cd: "5"},
];
// -- 프로그램 권한 관리


// 상품추천 고도화 - 저해약종신
const LOW_WHOLE_LIFE_LIST = [
	'PRODLN0013',
	'PRODLN0014',
	'PRODLN0015',
	'PRODLN0016'
];

// 성별 목록
const SEX_LIST = [
	{ name: "공용", cd: "0" },
	{ name: "남자", cd: "1" },
	{ name: "여자", cd: "2" }
];


export default {
	SORT_TYPE,
	PR_PROD_LINE_CD,
	INS_CD,
	INS_NAME,
	PROD_TYPE,
	GUIDE_INSURER_CODE,
	USE_YN_LIST,
	XLS_GRADE_LIST,
	PROGRAM_MENU_GROUP_LIST,
	LOW_WHOLE_LIFE_LIST,
	SEX_LIST
}