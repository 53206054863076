<template>
  <v-flex>
    <v-data-table
      :headers="getHeader(headers)"
      :items="data"
      @click:row="getRowData"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="text-h6">목록 (총 {{ data.length }}개)</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="getRegist">등록</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.boardType="{ value }">
        <div class="cell">{{ getBoardTypeNm(value) }}</div>
      </template>
    </v-data-table>

    <product-recommend-board-write
        :is-show="showWrite"
        @close="close"
    />

    <product-recommend-board-detail
      :is-show="showDetail"
      :row-data="rowData"
      @close="close"
    />
  </v-flex>
</template>

<script>
import ProductRecommendBoardWrite from "@/pages/Product/components/ProductRecommendBoard/ProductRecommendBoardWrite";
import TableMixin from "@/mixin/TableMixin";
import {PRODUCT_RECOMMEND_BOARD_TABLE} from "@/util/TableHeaders";
import ProductRecommendBoardDetail from "@/pages/Product/components/ProductRecommendBoard/ProductRecommendBoardDetail";
import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  name: "ProductRecommendBoardTable",
  mixins: [TableMixin],
  components: {ProductRecommendBoardDetail, ProductRecommendBoardWrite},
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: PRODUCT_RECOMMEND_BOARD_TABLE,
      rowData: {},
      showWrite: false,
      showDetail: false,
      boardNo: 0
    }
  },
  methods: {
    getBoardTypeNm(boardType) {
      switch(boardType){
        case "GE":
          return "일반";
        case "EV":
          return "이벤트";
        case "EQ":
          return "이벤트퀴즈";
        case "QA":
          return "FAQ";
        default:
          return "";
      }
    },
    getRowData(row) {
      for (let i in this.data) {
        if (this.data[i] === row) {
          this.boardNo = this.data[i].boardNo
          this.showDetail = true;
        }
      }
      if (this.showDetail === true) {
        this.getRowContents();
      }
    },
    async getRowContents() {
      const params = {
        boardNo: this.boardNo,
        memberId: store.getters['login/memberId']
      };
      let response;
      try {
        response = await axiosInstance.get('/public/prdata/getPrBoardDetail', { params });
        this.rowData = response.data;
      } catch (e) {
        console.log(e);
        alert("게시판 등록에 실해했습니다.");
      }
    },
    getRegist() {
      this.showWrite = true;
    },
    close() {
      this.showWrite = false;
      this.showDetail = false;
    }
  }
}
</script>