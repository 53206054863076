<template>
  <search-page-layout title="인수지침 최대가입금액 관리">
    <template v-slot:searchBar>
      <product-guidelines-search-bar
          @getSearch = "getSearch"
        />
    </template>
    <template v-slot:table>
      <product-guidelines-table
          :data="tableData"
          :loading="loading"
          :checkList="selectList"
          :searchParams="searchParams"
        />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import ProductGuidelinesSearchBar from "./components/ProductGuidelinesSearchBar";
import ProductGuidelinesTable from "./components/ProductGuidelinesTable";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {ProductGuidelinesTable, ProductGuidelinesSearchBar, SearchPageLayout},
  data:() => ({
    loading: false,
    tableData: [],
    searchParams: [],
    selectList:[]
  }),
  methods: {
    async getSearch(params) {
      this.selectList=[];
      this.searchParams = params;
      this.loading = true;
      try {
        const { data } = await axiosInstance.get('/admin/prdata/prTakeBase', { params });
        this.tableData = data;
        //인덱스 값 추가
        for (let i = 0; i < this.tableData.length; i++) {
          console.log(">>>>", this.tableData[i].mainCvrYn);
          if (this.tableData[i].mainCvrYn === 'Y') {
            this.tableData[i].mainCvrYn = true;
          } else {
            this.tableData[i].mainCvrYn = false;
          }
          this.tableData[i].index = i;
          if (this.tableData[i].selCvrYn === 'Y') {
            this.selectList.push(this.tableData[i]);
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>