<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-flex>
      <v-card max-width="90vw" max-height="95vh" class="overflow-y-auto">
        <v-card-title class="headline">
          <span v-if="division === 'detail'" class="font-weight-bold">인수지침_담보가입금액정보</span>
          <span v-else class="font-weight-bold">인수지침 연계담보 담보별 등록현황 </span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-col cols="12" class="py-0">
          <v-sheet class="background" style="padding: 0px;">
            <search-area>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">보험사</v-col>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="insCd"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
                <v-col cols="2">상품코드</v-col>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="prCd"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">플랜코드</v-col>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="planCd"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">담보코드</v-col>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="cvrCd"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
                <v-col cols="2">담보명</v-col>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    hint="cvrNm"
                    v-model="cvrNm"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">성별</v-col>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="sex"
                    :disabled="true"
                />
              </v-col>
            </search-area>
          </v-sheet>
        </v-col>
        <v-data-table
          v-if="division === 'detail'"
          class="table_sty"
          :items="tableList"
          height="45vh"
          :headers="headers"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          dense fixed-header calculate-widths disable-sort
          >
          <template v-slot:item.minCvrAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>
          <template v-slot:item.maxCvrAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>
          <template v-slot:item.prCvrAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>
          <template v-slot:item.indCvrAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>
        </v-data-table>

        <v-data-table
            v-if="division === 'link'"
            class="table_sty"
            :items="tableList"
            height="45vh"
            :headers="linkHeaders"
            :items-per-page="-1"
            :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
            dense fixed-header calculate-widths disable-sort
        >
          <template v-slot:item.frCvrAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>
          <template v-slot:item.toCvrAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import SearchArea from "../../../components/Search/SearchArea";
export default {
  components: { SearchArea},
  props: ["isShow", "detailDto", "tableList", "cvrNm", "division"],
  watch: {
    detailDto: function (obj) {
      this.insCd = obj.insCd;
      this.prCd = obj.prCd;
      this.planCd = obj.planCd;
      this.cvrCd = obj.cvrCd;
      this.sex = obj.sex;
    }
  },
  data:() => ({
    insCd:'',
    prCd:'',
    planCd:'',
    cvrCd:'',
    sex:'',
    linkHeaders: [
      {text: "연계담보구분코드", value: "lkCvrDivCd", class: "lime lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "구분유형", value: "lkCvrDivType", class: "lime lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "충족조건", value: "satCond", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "설명", value: "lkCvrDivDesc", class: "teal lighten-4", align: "center", sortable: false, width: "35vw"},
      {text: "기준값", value: "baseVal", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "위험급수", value: "riskLevel", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "위험급수2", value: "riskLevel2", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "운전형태", value: "drivingDiv", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "연령_FR", value: "frAge", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "연령_TO", value: "toAge", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "직접연계담보코드", value: "dirLkCvrCd", class: "lime lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "직접연계담보명", value: "dirLkCvrNm", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "담보금액조건", value: "cvrAmtCond", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액_FR", value: "frCvrAmt", class: "lime lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "담보금액_TO", value: "toCvrAmt", class: "lime lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "등록일시", value: "createDate", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
    ],
    headers: [
      {text: "연령_FR", value: "frAge", class: "lime lighten-4", align: "center", sortable: false},
      {text: "연령_TO", value: "toAge", class: "lime lighten-4", align: "center", sortable: false},
      {text: "위험급수", value: "riskLevel", class: "teal lighten-4", align: "center", sortable: false},
      {text: "위험급수2", value: "riskLevel2", class: "teal lighten-4", align: "center", sortable: false},
      {text: "운전형태", value: "drivingDiv", class: "teal lighten-4", align: "center", sortable: false},
      {text: "최소담보금액", value: "minCvrAmt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "최대담보금액", value: "maxCvrAmt", class: "lime lighten-4", align: "center", sortable: false},
      {text: "추천담보금액", value: "prCvrAmt", class: "lime lighten-4", align: "center", sortable: false},
      {text: "지침차이여부", value: "dataDiffYn", class: "lime lighten-4", align: "center", sortable: false},
      {text: "지침담보금액", value: "indCvrAmt", class: "lime lighten-4", align: "center", sortable: false},
    ],
  }),
  methods: {
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
.background {
  background-color: rgba(0,0,0,.05);
  border: 1px solid lightgray;
}
.table_sty {
  margin-left: 10px;
  margin-top: 15px;
  margin-right: 10px;
}
.subBar {
  margin-top: 13px;
  margin-left: 5px;
  margin-right: 20px;
}
.v-data-table::v-deep td {
  white-space: normal!important;
  word-break: keep-all;
}
</style>