<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">등록일자</v-col>
          <v-col cols="8">
            <!-- 시작일 및 마지막일 선택 -->
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
        <!-- 조회버튼 -->
        <div class="ml-auto pb-1 d-flex align-end">
          <search-btn @click="getSearch"/>
        </div>
      </search-area>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import DateMixin from "@/mixin/DateMixin";
import DateInput from "@/components/Form/DateInput";
import SearchBtn from "@/components/Search/SearchBtn";

export default {
  mixins: [DateMixin],
  components: {SearchBtn, SearchArea, SearchBarLayout, DateInput},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-29);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data() {
    return {
      startDate: "",
      endDate: "",
    }
  },
  methods: {
    // 시작일 및 마지막일 데이터 담기
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    // getSearch 함수를 통해 시작일 및 마지막일 데이터 전달
    getSearch() {
      const params = {
        frDate: this.startDate.replace(/[^0-9]/gi, ""),
        toDate: this.endDate.replace(/[^0-9]/gi, ""),
      }
      this.$emit('getSearch', params);
    }
  }
}
</script>