import constant from "@/util/constant";
import {axiosInstance} from "@/util/AxiosModule";

const initMpPrProdLineList = function (defaultNm) {
    let mpPrProdLineList = [];
    mpPrProdLineList = constant.PR_PROD_LINE_CD.map(item => ({
        mpPrProdLineNm: item.name,
        mpPrProdLineCd: item.cd
    }));
    mpPrProdLineList.unshift({
        mpPrProdLineNm: defaultNm,
        mpPrProdLineCd: ""
    });
    return mpPrProdLineList;
}

const initInsList = function (defaultNm) {
    let insList = [];
    insList = constant.INS_CD.map(item => ({
        insNm: item.name,
        insCd: item.cd
    }));
    insList.unshift({
        insNm: defaultNm,
        insCd: ""
    });
    return insList;
}

const prProdLineList = async function (cdType, insCd, iLineCd, iCdNm) {
    let prProdLineList = [];
    const { data } = await axiosInstance.get("/private/ai/aiTermPrCdList", {
        params: {
            cdType: cdType,
            insCd: insCd,
            iLineCd: iLineCd,
            iCdNm: iCdNm
        }
    });
    prProdLineList = data.map(item => ({
        prProdLineNm: item.vcdNm,
        prProdLineCd: item.vcd
    }));
    prProdLineList.unshift({
        prProdLineNm: "선택",
        prProdLineCd: ""
    });
    return prProdLineList;
}

export default {
    initMpPrProdLineList,
    initInsList,
    prProdLineList
}