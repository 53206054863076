<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">상품군</v-col>
          <v-select
              @click="getProList"
              v-model="srhType"
              :items="srhTypeList"
              background-color="white"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              class="subBar"
              hide-details outlined dense
            />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
            <v-select
                @click="selectCd"
                @change="getTableId"
                v-model="insCd"
                :items="insurerCode"
                background-color="white"
                item-text="value"
                item-value="cd"
                class="subBar"
                hide-details outlined dense
            />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-2 col-12">
          <v-col cols="7">판매중단여부</v-col>
          <v-select
              v-model="useYn"
              :items="yNlist"
              background-color="white"
              item-text="cname"
              item-value="cvalue"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModule";
import SearchBtn from "../../../../components/Search/SearchBtn";
export default {
  components: { SearchBtn, SearchArea, SearchBarLayout},
  data:() => ({
    insCd:'',
    insurerCode: [
      {value: '선택', cd: ''}
    ],
    prCdList: [],
    srhType:'',
    srhTypeList: [
      {prProdLineNm: '선택', prProdLineCd: ''}
    ],
    prCd:'',
    useYn: 'N',
    yNlist: [
      {cname:'Y', cvalue:'Y'},
      {cname:'N', cvalue:'N'}
    ]
  }),
  methods: {
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    async getProList() {
      try{
        const { data } = await axiosInstance.get('/public/prdata/prProdLineNmList', {
          params: {
            insDiv : "A",
            srhType: "1"
          }
        });
        this.srhTypeList = data;
      } catch(e) {
        console.log(e);
      }
    },
    async getTableId() {
      this.planCdList = [];
      this.prCdList = [];
      const params = {
        insCd: this.insCd,
        srhType: "2"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prCd + ' (' + data[i].prNm + ')';
          objJson.prCd = data[i].prCd;
          this.prCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    getSearch() {
      const params = {
        prProdLineCd: this.srhType,
        insCd: this.insCd,
        prCd: this.prCd,
        closeYn: this.useYn
      }

      this.$emit('getSearch', params);
    }
  }
}
</script>