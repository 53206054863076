<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건" class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <field-input title="계약일자">
          <date-input
              style="width:60vw;"
              :start-date="startDate"
              :end-date="endDate"
              @updateDate="updateDate"
          />
        </field-input>
        <field-input title="계약채널">
          <v-select
              v-model="channel"
              :items="channelList"
              item-text="name"
              item-value="cd"
              background-color="white"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="진행자">
          <v-text-field
              v-model="ctCsNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>
      <v-divider class="mb-2"/>
      <search-area title="고객정보" class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <field-input title="고객명">
          <v-text-field
              v-model="customerNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="고객 전화번호">
          <v-text-field
              v-model="customerTelNum"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="차량번호">
          <v-text-field
              v-model="customerCarNo"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>

      <search-area title="회원정보">
        <field-input title="회원명">
          <v-text-field
              v-model="memberNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="회원 전화번호">
          <v-text-field
              v-model="memberTelNum"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="보험사">
          <v-select
              v-model="insCd"
              :items="insCdList"
              background-color="white"
              item-text="name"
              item-value="value"
              hide-details outlined dense
          />
        </field-input>
      </search-area>
      <search-area title="계약정보">
        <field-input title="계약 보험료">
          <v-text-field
              v-model="contractPremAmt"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getRealtimeContract"/>
    </template>
  </search-bar-layout>
</template>

<script>
import constant from "@/util/constant";
// import func from "@/util/func";
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import func from "@/util/func";

export default {
  name: "RealtimeContractSearchBar",
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, FieldInput},

  async beforeMount() {
    const { fromDate, toDate } = this.setFirstDateFixed();
    this.startDate = fromDate;
    this.endDate = toDate;
    this.channel = this.isPrvGroupTM ? "DS" : "";
  },

  computed: {
    isPrvGroupTM() {
      return func.checkUserPrvGroup(["TM", "TM_ADM"]) === "Y";
    },
    channelList() {
      if (this.isPrvGroupTM) {
        return [
          {name: "DS", cd: "DS"}
        ];
      } else {
        return [
          {name: "전체", cd: ""},
          {name: "CM", cd: "CM"},
          {name: "TM", cd: "TM"},
          {name: "DS", cd: "DS"}
        ];
      }
    }
  },
  data: () => ({
    startDate: "",
    endDate: "",
    ctCsNm: "",
    memberNm: "",
    memberTelNum: "",
    customerNm: "",
    customerTelNum: "",
    customerCarNo: "",
    insCd: "ALL",  // 전체
    insCdList: constant.INS_NAME,
    channel: "",
    contractPremAmt: 0,
    reJoinYn: ""
  }),

  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },

    getRealtimeContract() {
      const params = {
        startDate: this.startDate.replaceAll('-', ''),
        endDate: this.endDate.replaceAll('-', ''),
        insCd: this.insCd,
        joinChannel: this.channel,
        reJoinYn: this.reJoinYn
      }
      if (this.ctCsNm) params["ctCsNm"] = this.ctCsNm.replace(/\s/g, '');
      if (this.customerNm) params["customerNm"] = this.customerNm.replace(/\s/g, '');
      if (this.customerTelNum) params["customerTelNum"] = this.customerTelNum.replace(/\s/g, '');
      if (this.customerCarNo) params["customerCarNo"] = this.customerCarNo.replace(/\s/g, '');
      if (this.memberNm) params["memberNm"] = this.memberNm.replace(/\s/g, '');
      if (this.memberTelNum) params["memberTelNum"] = this.memberTelNum.replace(/\s/g, '');
      if (this.contractPremAmt) params["contractPremAmt"] = this.contractPremAmt.replace(/[^0-9]/g, '');

      this.$emit('getRealtimeContract', params);
    },
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>