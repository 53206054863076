<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>
      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">보험사</v-col>
          <v-col cols="7">
            <v-select
                @click="getInsCd"
                v-model="dataDto.insCd"
                :items="insCdList"
                background-color="white"
                item-text="insCdNm"
                item-value="insCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">담보코드</v-col>
          <v-col cols="7">
            <v-text-field
              v-model="dataDto.cvrCd"
              outlined dense hide-details
              background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="12">
            <v-text-field
                v-model="dataDto.cvrNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="3">상품코드목록</v-col>
          <v-col cols="9">
            <v-text-field
                v-model="dataDto.prCdList"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                :disabled="dataDto.prCdList === creCvrRowData.prCdList"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">신정원담보코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.creCvrCd"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                :disabled="dataDto.creCvrCd === creCvrRowData.creCvrCd"
                append-icon="mdi-land-rows-horizontal"
                @click:append="showCreCvrCd"

            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="12">
            <v-text-field
                v-model="dataDto.creCvrNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                :disabled="dataDto.creCvrNm === creCvrRowData.creCvrNm"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">갱신형구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.rnwDiv"
                :items="rnwDivList"
                background-color="white"
                item-text="rnwDivNm"
                item-value="rnwDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">갱신형년도</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.rnwYear"
                background-color="white"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">갱신용구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.forRnwDiv"
                :items="forRnwDivList"
                background-color="white"
                item-text="forRnwDivNm"
                item-value="forRnwDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">간편가입구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.easySignUpDiv"
                :items="easySignUpDivList"
                background-color="white"
                item-text="easySignUpDivNm"
                item-value="easySignUpDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">운전자형태구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.driverTypeDiv"
                :items="driverTypeDivList"
                background-color="white"
                item-text="driverTypeDivNm"
                item-value="driverTypeDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">사용여부</v-col>
          <v-col cols="4">
            <v-select
                v-model="dataDto.useYn"
                :items="useYnList"
                item-text="useYnName"
                item-value="useYnValue"
                outlined dense hide-details
                background-color="white" menu-props="auto" class="subBar"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="deleteData"
        >초기화</v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >저장</v-btn>
      </v-card-actions>

      <cre-cvr-mapping-modal
          :is-show="isShowCreCvrCd"
          @close="isShowCreCvrCd = false"
          @row="creCvrRow"
      />
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import CreCvrMappingModal
  from "@/pages/Product/components/ProductCompareManagement/CreCvrMappingModal/CreCvrMappingModal";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  props: ["rowData"],
  components: {CreCvrMappingModal},
  data() {
    return {
      dataDto: {
        'insCd':'',
        'insCdNm':'',
        'cvrCd':'',
        'cvrNm':'',
        'prCdList':'',
        'creCvrCd':'',
        'creCvrNm':'',
        'rnwDiv':'0',
        'rnwYear':'0',
        'forRnwDiv':'0',
        'easySignUpDiv':'0',
        'driverTypeDiv':'0',
        'useYn':'Y',
      },
      insCdList: [
        {insCdNm: "선택", insCd: ""},
      ],
      rnwDivList: [
        {rnwDivNm: "전체", rnwDiv: "0"},
        {rnwDivNm: "갱신형", rnwDiv: "1"},
        {rnwDivNm: "비갱신형", rnwDiv: "2"},
      ],
      forRnwDivList: [
        {forRnwDivNm: "전체", forRnwDiv: "0"},
        {forRnwDivNm: "갱신용", forRnwDiv: "1"},
      ],
      easySignUpDivList: [
        {easySignUpDivNm: "전체", easySignUpDiv: "0"},
        {easySignUpDivNm: "간편가입(간편고지)", easySignUpDiv: "1"},
      ],
      driverTypeDivList: [
        {driverTypeDivNm: "전체", driverTypeDiv: "0"},
        {driverTypeDivNm: "운전자", driverTypeDiv: "1"},
        {driverTypeDivNm: "비운전자", driverTypeDiv: "2"},
        {driverTypeDivNm: "영업용운전자", driverTypeDiv: "3"},
      ],
      useYnList: [
        {useYnName: "Y", useYnValue: "Y"},
        {useYnName: "N", useYnValue: "N"},
      ],
      isShowCreCvrCd: false,
      creCvrRowData: [],
    }
  },
  watch: {
    rowData: function (value) {
      this.dataDto.insCd = value.insCd;
      this.dataDto.insCdNm = value.insNm;
      this.insCdList[0].insCd = value.insCd;
      this.insCdList[0].insCdNm = value.insNm;
      this.dataDto.cvrCd = value.cvrCd;
      this.dataDto.cvrNm = value.cvrNm;
      this.dataDto.prCdList = value.prCdList;
      this.dataDto.creCvrCd = value.creCvrCd;
      this.dataDto.creCvrNm = value.creCvrNm;
      this.dataDto.rnwDiv = value.rnwDiv;
      this.dataDto.rnwYear = value.rnwYear;
      this.dataDto.forRnwDiv = value.forRnwDiv;
      this.dataDto.easySignUpDiv = value.easySignUpDiv;
      this.dataDto.driverTypeDiv = value.driverTypeDiv;
      this.dataDto.useYn = value.useYn;
      console.log(">>>value", value);
    }
  },
  methods: {
    async getInsCd() {
      this.insCdList = await ProductRecommend.getInsCd();
      this.insCdList.shift();
    },
    deleteData() {
      this.insCdList[0].insCd = "";
      this.insCdList[0].insCdNm = "선택";
      this.dataDto.insCd = this.insCdList[0].insCd;
      this.dataDto.insCdNm = this.insCdList[0].insCdNm;
      this.dataDto.creCvrNm = "";
      this.dataDto.creCvrCd = "";
      this.dataDto.cvrNm = "";
      this.dataDto.prCdList = "";
      this.dataDto.cvrCd = "";
      this.dataDto.rnwDiv = "0";
      this.dataDto.rnwYear = 0;
      this.dataDto.forRnwDiv = "0";
      this.dataDto.easySignUpDiv = "0";
      this.dataDto.driverTypeDiv = "0";
      this.dataDto.useYn = "Y";
    },
    async saveData() {
      const prCvrTbDto = {
        insCd: this.dataDto.insCd,
        insNm: this.dataDto.insCdNm,
        cvrCd: this.dataDto.cvrCd,
        cvrNm: this.dataDto.cvrNm,
        prCdList: this.dataDto.prCdList,
        creCvrCd: this.dataDto.creCvrCd,
        creCvrNm: this.dataDto.creCvrNm,
        rnwDiv: this.dataDto.rnwDiv,
        rnwYear: this.dataDto.rnwYear,
        forRnwDiv: this.dataDto.forRnwDiv,
        easySignUpDiv: this.dataDto.easySignUpDiv,
        driverTypeDiv: this.dataDto.driverTypeDiv,
        useYn: this.dataDto.useYn,
      }
      try {
        await axiosInstance.post('/admin/prdata/prCvrSave', prCvrTbDto);
        alert("저장되었습니다.");
      } catch (e) {
        console.log(e);
      }
    },
    showCreCvrCd() {
      this.isShowCreCvrCd = true;
    },
    creCvrRow(row) {
      this.dataDto.creCvrCd = row.creCvrCd;
      this.dataDto.creCvrNm = row.creCvrNm;

      this.creCvrRowData = row;
    }
  }
}
</script>