<template>
  <search-page-layout title="조건별 보험료 조회">
    <template v-slot:searchBar>
      <product-cond-prem-search-bar
        @getSearch="getSearch"
        @allData="allData"
        @deleteData="deleteData"
        @prProdLineCd="getPrProdLineCd"
      />
    </template>

    <template v-slot:table>
      <product-cond-prem-table
        :nowPrProdLineCd="nowPrProdLineCd"
        :data="data"
        :excelData="excelData"
        :loading="loading"
        :monthlyPremSum="monthlyPremSum"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductCondPremSearchBar from "@/pages/Product/components/ProductCondPrem/ProductCondPremSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import ProductCondPremTable from "@/pages/Product/components/ProductCondPrem/ProductCondPremTable";
import constant from "@/util/constant";

export default {
  components: {ProductCondPremTable, ProductCondPremSearchBar, SearchPageLayout},
  data() {
    return {
      lowWholeLifeList: constant.LOW_WHOLE_LIFE_LIST,
      data: [],
      monthlyPremSum: 0,
      loading: false,
      cvrData: [],
      excelData: {},
      deleteType: "",
      searchData: {
        prCd: "",
        age: "",
        sex: "",
        cvrDiv: "",
        cvrCd: "",
        cvrNm: "",
        accAmt: "",
        accAmtNm: "",
        insTrmSub: "",
        pyTrmSub: "",
        rnwCycleSub: "",
        monthlyPrem: 0,
      },
      nowPrProdLineCd: ""
    }
  },
  methods: {
    allData(data) {
      this.cvrData = data;
    },
    deleteData(type) {
      if (type === true) {
        this.data = [];
        this.monthlyPremSum = 0;
      }
    },
    async getSearch(params, excelData) {
      this.loading = true;
      this.data = [];
      this.excelData = excelData;
      let prIn = {};

      try {
          prIn = params;
          if (params.ageF > params.ageT) {
            alert("시작나이가 끝나보디 많을 수 없습니다!");
            this.loading = false;
            return false;
          } else if (params.prCdLst[0] === "") {
            alert("조회조건을 확인해주세요!");
            this.loading = false;
            return false;
          } else {
            const { data } = await axiosInstance.post('/private/prdata/prCompPremListRangeV3', prIn);

            for (let i in data) {
              let sex = data[i].sex;
              let age = data[i].age;
              let outInsData = data[i].prProdLineCondOutSearchDiv[0].prProdLineCondOutIns
              // let cvrAmtArrList = outInsData[0].cvrAmtArrLst;
              for (let j in outInsData){
                if (outInsData === []) {
                  return;
                } else {
                  if (this.lowWholeLifeList.includes(this.nowPrProdLineCd)) {
                    let cvrAmtArrList = outInsData[j].cvrAmtArrLst;
                    let refundArrList = outInsData[j].refundArrLst;
                    for (let k in cvrAmtArrList) {
                      for (let l in refundArrList) {
                        this.searchData = {
                          prCd: outInsData[0].prCd,
                          age: age,
                          sex: sex,
                          cvrDiv: cvrAmtArrList[k].cvrDiv,
                          cvrCd: cvrAmtArrList[k].cvrCd,
                          cvrNm: cvrAmtArrList[k].cvrNm,
                          accAmt: cvrAmtArrList[k].accAmt,
                          accAmtNm: cvrAmtArrList[k].accAmtNm,
                          elapsedYear: refundArrList[l].elapsedYear,
                          refund: refundArrList[l].refund,
                          refundNm: refundArrList[l].refundNm,
                          refundRate: refundArrList[l].refundRate,
                          insTrmSub: outInsData[j].insTrm,
                          pyTrmSub: outInsData[j].pyTrm,
                          rnwCycleSub: outInsData[j].rnwCycle,
                          monthlyPrem: cvrAmtArrList[k].monthlyPrem,
                        }
                        this.data.push(this.searchData);
                      }
                    }
                  } else {
                    let cvrAmtArrList = outInsData[j].cvrAmtArrLst;
                    for (let k in cvrAmtArrList) {
                      this.searchData = {
                        prCd: outInsData[0].prCd,
                        age: age,
                        sex: sex,
                        cvrDiv: cvrAmtArrList[k].cvrDiv,
                        cvrCd: cvrAmtArrList[k].cvrCd,
                        cvrNm: cvrAmtArrList[k].cvrNm,
                        accAmt: cvrAmtArrList[k].accAmt,
                        accAmtNm: cvrAmtArrList[k].accAmtNm,
                        insTrmSub: outInsData[j].insTrm,
                        pyTrmSub: outInsData[j].pyTrm,
                        rnwCycleSub: outInsData[j].rnwCycle,
                        monthlyPrem: cvrAmtArrList[k].monthlyPrem,
                      }
                      this.data.push(this.searchData);
                    }
                  }
                }
              }
              if ((params.ageF === params.ageT) && (params.sex !== "0")) {
                this.monthlyPremSum = outInsData[0].monthlyPremSum;
              } else {
                this.monthlyPremSum = 0;
              }
            }
          }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    getPrProdLineCd(data) {
      this.nowPrProdLineCd = data;
      this.data = [];
      this.monthlyPremSum = 0;
    }
  }
}
</script>