<template>
  <search-page-layout title="실적조회">

    <template v-slot:searchBar>
      <salesList-search-bar @getSalesList="getSalesList"/>
    </template>

    <template v-slot:table>
      <salesList-table
          :data="data"
          :loading="loading"
          @toExcel="toExcel"
          @updateLoading="updateLoading"
          :toYm="toYm"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import SalesListSearchBar from "@/pages/Settlement/components/SalesList/SalesListSearchBar";
import SalesListTable from "@/pages/Settlement/components/SalesList/SalesListTable";
import _ from "lodash";
import func from "@/util/func";

export default {
  props: ["name"],
  components: {
    SalesListTable,
    SalesListSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
    toYm: ""
  }),

  methods: {
    async getSalesList(params) {
      this.loading = true;
      this.toYm = params.toYm;  // row선택시 해당 차트 데이터 출력에 필요

      try {
        const {data} = await axiosInstance.get('/admin/sales/saleslist', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
        "부문총괄": item["bmcOrgNm"],
        "부문": item["bmOrgNm"],
        "총괄": item["cgOrgNm"],
        "소속": item["belongNm"],
        "파트너": item["partnerNm"],
        "부서": item["deptNm"],
        "FA명": item["orgNm"],
        "FA보유수": item["tFaCnt"],
        "실적발생수": item["faCnt"],
        "거수보험료": item["prem"],
        "실적건수": item["salesCnt"],
        "정산건수": item["settleCnt"],
        "광고비": item["reward"],
        "정산광고비": item["pyReward"]
        }
        returnData.push(obj);
      }

      const sheetName = `실적조회`;
      const fileName = `실적List`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    updateLoading(value){
      this.loading = value;
    }
  }
}
</script>