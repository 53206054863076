<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-1 col-lg-2 col-md-2 col-12">
          <v-select
              label="보험사"
              v-model="insCd"
              :items="insCdList"
              item-text="insCdNm"
              item-value="insCd"
              class="subBar"
              background-color="white" outlined
              @change="changeData(insCd)"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-1 col-lg-2 col-md-2 col-12">
          <v-select
              label="상품군"
              v-model="prProdLineCd"
              :items="prProdLineCdList"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              class="subBar"
              background-color="white" outlined
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-6 col-md-6 col-12">
          <v-select
              label="상품코드"
              v-model="prCd"
              :items="prCdList"
              item-text="prCdNm"
              item-value="prCd"
              background-color="white" outlined
              @click="getPrCdList"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-1 col-lg-2 col-md-2 col-12">
          <v-select
              label="사용여부"
              v-model="useYn"
              :items="useYnList"
              item-text="useYnNm"
              item-value="useYn"
              background-color="white" outlined
          />
        </v-col>

        <v-col cols="12" class="search-button">
          <v-btn @click="getSearch">조회</v-btn>
        </v-col>
      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  name: "InsCvrMappingBasicDataSearchBar",
  components: {SearchArea, SearchBarLayout},
  async created() {
    this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
    this.insCdList = await ProductRecommend.getInsCd();
    this.insCdList = this.insCdList.filter(item => item.insCdNm !== "전체");
    this.insCdList.unshift({
      insCdNm: "선택",
      insCd: "",
    });
  },
  data() {
    return {
      insCd: "",
      insCdList: [
        {insCdNm: "선택", insCd: ""}
      ],
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "전체", prProdLineCd: ""}
      ],
      prCd: "",
      prCdList: [
        {prCdNm: "선택", prCd: ""}
      ],
      useYn: "Y",
      useYnList: [
        {useYnNm: "Y", useYn: "Y"},
        {useYnNm: "N", useYn: "N"},
      ]
    }
  },
  methods: {
    changeData() {
      this.prProdLineCd = "";
      this.prCd = "";
    },
    async getPrCdList() {
      this.prCdList = await ProductRecommend.getPrCdList(this.prProdLineCd, this.insCd, this.useYn);
      if (this.prCdList.length < 1) {
        this.prCdList.unshift({
          prNm: "없음",
          prCd: "",
          prCdNm: "없음",
        });
      }
    },
    getSearch() {
      const params = {
        insCd: this.insCd,
        prProdLineCd: this.prProdLineCd,
        prCd: this.prCd,
        useYn: this.useYn
      }
      this.$emit("getSearch", params);
    }
  }
}
</script>

<style scoped>
.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-button > v-btn {
  background-color: #f5f5f5;
}
</style>