<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="30vh"
        :items="data"
        :loading="loading"
        :items-per-page="-1"
        fixed-header calculate-widths
        :headers="headers"
        @click:row="clickContents"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >
      </v-data-table>
    </v-flex>
  </v-col>
</template>
<script>
export default {
  props: ["data", "loading"],
  data: () => ({
    headers: [
      {text: "상품군", value: "prProdLineNm", class: "lime lighten-4", align: "center", sortable: false},
      {text: "보험사", value: "insCd", class: "lime lighten-4", align: "center", sortable: false},
      {text: "상품코드", value: "prCd", class: "lime lighten-4", align: "center", sortable: false},
      {text: "상품명", value: "prNm", class: "lime lighten-4", align: "center", sortable: false},
      {text: "표준플랜코드", value: "stPlanCd", class: "teal lighten-4", align: "center", sortable: false},
      {text: "플랜명", value: "stPlanNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "추천 순위", value: "orderNo", class: "teal lighten-4", align: "center", sortable: false},
      {text: "기준일자", value: "baseDt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "보험사연동여부", value: "insRtLinkYn", class: "teal lighten-4", align: "center", sortable: false},
      {text: "New표시여부", value: "newYn", class: "lime lighten-4", align: "center", sortable: false},
      {text: "판매중단여부", value: "closeYn", class: "lime lighten-4", align: "center", sortable: false},
      {text: "등록일시", value: "updateDate", class: "lime lighten-4", align: "center", sortable: false},
    ],
  }),
  methods: {
    clickContents(row) {
      this.$emit('row', row);
    }
  }
}
</script>