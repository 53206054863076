<template>
  <search-page-layout title="장기 상품추천시스템 사용현황">
    <!-- 조회조건 부분 -->
    <template v-slot:searchBar>
      <product-recommend-usage-status-search-bar
          @getSearch="getSearch"
          @setData="setData"
      />
    </template>
    <!-- 조회조건 부분 -->

    <!-- 목록 부분 -->
    <template v-slot:table>
      <!-- 탭부분 -->
      <v-tabs class="tabs" v-model="tabs" @change="changeTabs(tabs)">
        <v-tab>보험사</v-tab>
        <v-tab>설계고객연령</v-tab>
      </v-tabs>
      <!-- 탭부분 -->

      <product-recommend-usage-status-table
          :tabs="tabs"
          :insData="insData"
          :ageData="ageData"
          :loading="loading"
      />
    </template>
    <!-- 목록 부분 -->
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductRecommendUsageStatusSearchBar
  from "@/pages/Product/components/ProductRecommendUsageStatus/ProductRecommendUsageStatusSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import ProductRecommendUsageStatusTable
  from "@/pages/Product/components/ProductRecommendUsageStatus/ProductRecommendUsageStatusTable";
export default {
  components: {ProductRecommendUsageStatusTable, ProductRecommendUsageStatusSearchBar, SearchPageLayout},
  data() {
    return {
      tabs: [0, 1],
      loading: false,
      insData: [],
      ageData: [],
      paramsData: [],
      settingData: {},
      isClick: ""
    }
  },
  // 페이지 생성 시 기본 tabs 번호 부여
  created() {
    this.tabs = 0;
  },
  methods: {
    // 기본 조회 조건 가져오기(기간: 기본설정 날짜, 상품군: 전체)
    setData(params) {
      this.settingData = params;
    },
    // 목록조회
    async getSearch(params, clicked) {
      this.isClick = clicked;

      this.loading = true;
      this.paramsData = params;

      let axiosData;
      try {
        axiosData = await axiosInstance.get('/private/operation/product-recommend-usage-status', {
          params: {
            startDt: params.startDt,
            endDt: params.endDt,
            prProdLineCd: params.prProdLineCd,
            // type: 목록테이블 구분값
            type: this.tabs,
          }
        });
        // 첫번째 행 합계 만들기
        const { data } = axiosData;
        let allData = {}
        for (var index in data) {
          for (var key in data[index]) {
            if (key === "createDate") {
              allData[key] = "합계";
            } else {
              if (allData[key] ===  undefined) {
                allData[key] = data[index][key];
              } else {
                allData[key] += data[index][key];
              }
            }
          }
        }
        // 첫번째 행에 합계 행 추가
        data.unshift(allData);
        // tabs 번호에 따라 가져온데이터 각각 담기
        if (this.tabs === 0) {
          this.insData = data;
        } else if (this.tabs === 1) {
          this.ageData = data;
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    changeTabs(tabs) {
      const insDataLength = this.insData.length;
      const ageDataLength = this.ageData.length;

      if ((tabs === 0 && insDataLength === 0) || (tabs === 1 && ageDataLength === 0)) {
        this.getSearch(this.settingData);
      } else if (((tabs === 0 && insDataLength > 0) || (tabs === 1 && ageDataLength > 0)) && (this.isClick === true)) {
        this.getSearch(this.paramsData);
      }
    }
  }
}
</script>

<style scoped>
.tabs {
  margin-left: 20px;
}
</style>