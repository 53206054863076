<template>
  <search-page-layout title="조기정산 관리">
    <template v-slot:searchBar>
      <settlement-early-search-bar
        @search="search"
        :select-list="selectList"
        @refresh="refreshData"
      />
    </template>
    <template v-slot:table>
      <settlement-early-table
        :data="searchData"
        @selectList="selectedData"
      />
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import SettlementEarlySearchBar from "@/pages/Settlement/components/SettlementEarly/SettlementEarlySearchBar";
import SettlementEarlyTable from "@/pages/Settlement/components/SettlementEarly/SettlementEarlyTable";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {SettlementEarlyTable, SettlementEarlySearchBar, SearchPageLayout },
  data() {
    return {
      loading: false,
      searchData: [],
      selectList: []
    }
  },
  methods: {
    async search(params) {
      this.searchData = [];
      this.loading = true;
      const { data } = await axiosInstance.get("/admin/settlement/earlySettlement", { params });
      this.loading = false;
      if (data.length < 1) {
        alert("조회된 데이터가 없습니다.");
        return false;
      } else {
        let idx = 0;
        for (const item of data) { item["index"] = idx++; }
        let checkData = [];
        for (let i = 0; i < data.length; i++) {
          checkData = data.map(x => ({
            ...x,
            isSelectable: x.payProYn === "Y" ? true : false
          }));
        }
        this.searchData = checkData;
      }
    },
    selectedData(list) {
      this.selectList = list;
    },
    refreshData() {
      this.searchData = [];
      this.selectList = [];
    }
  }
}
</script>