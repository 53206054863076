<template>
  <v-col cols="12" class="py-0" style="margin-top: 20px;">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <outlined-btn style="margin-left: 7px;" class="float-right" @click="toExcel" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
      <v-btn @click="deleteData" style="margin-left: 7px;" class="red white--text theme--light float-right marginBtn">삭제</v-btn>
      <v-btn @click="save" style="margin-left: 7px;" class="light-blue white--text theme--light float-right marginBtn">담보추가</v-btn>
      <v-btn @click="change" style="margin-left: 7px;" class="indigo white--text theme--light float-right marginBtn">변경선택</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="data"
        show-select
        v-model="selectList"
        item-key="index"
        :items-per-page="-1"
        fixed-header disable-sort calculate-widths
        :headers="headers"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >
        <template v-slot:item.frCvrAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.toCvrAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
    <linked-security-detail-save-modal
        :is-show="isShowSave"
        :detailDto="detailDto"
        :lkCvrDivCd="lkCvrDivCd"
        @close="isShowSave = false"
        />
    <linked-security-detail-modal
        :is-show="isShow"
        :data="selectList"
        @close="close"
    />
  </v-col>
</template>
<script>
import OutlinedBtn from "../../../Settlement/components/PaymentManage/outlinedBtn";
import _ from "lodash";
import func from "@/util/func";
import LinkedSecurityDetailModal from "./LinkedSecurityDetailModal";
import LinkedSecurityDetailSaveModal from "./LinkedSecurityDetailSaveModal";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {LinkedSecurityDetailSaveModal, LinkedSecurityDetailModal, OutlinedBtn},
  props:['data', 'detailDto'],
  data:() => ({
    headers: [{text: "연계담보구분코드", value: "lkCvrDivCd", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보명", value: "cvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "15vw"},
      {text: "기준값", value: "baseVal", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "성별", value: "sex", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "위험급수", value: "riskLevel", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "위험급수2", value: "riskLevel2", class: "teal lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "운전형태", value: "drivingDiv", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "연령_FR", value: "frAge", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "연령_TO", value: "toAge", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "직접연계담보코드", value: "dirLkCvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "담보금액조건", value: "cvrAmtCond", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액_FR", value: "frCvrAmt", class: "teal lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "담보금액_TO", value: "toCvrAmt", class: "teal lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "등록일시", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "15vw"},
    ],
    selectList:[],
    lkCvrDivCd: '',
    isShow:false,
    isShowSave: false
  }),
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  methods: {
    async deleteData() {
      const PrTakeLkCvrTbDto = this.selectList;
      for (let i = 0; i < PrTakeLkCvrTbDto.length; i++) {
        delete PrTakeLkCvrTbDto[i].createDate;
      }
      if (confirm("삭제 하시겠습니까?")) {
        try {
          const {data} = await axiosInstance.post('/admin/prdata/prTakeLkCvrProc', PrTakeLkCvrTbDto, {
            params: {
              wkDiv: 'D'
            }
          })
          alert('인수지침 연계담보 ' + data + '건이 삭제 되었습니다');
        } catch (e) {
          console.log(e);
        }
      }
    },
    save() {
      this.lkCvrDivCd = this.data[0].lkCvrDivCd;
      this.isShowSave = true;
    },
    change() {
      this.isShow = true;
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    close() {
      this.isShow = false;
      this.selectList = [];
    },
    toExcel() {
      if (_.isEmpty(this.selectList)) return;
      const data = _.cloneDeep(this.selectList);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "연계담보구분코드": item["lkCvrDivCd"],
          "담보코드": item["cvrCd"],
          "담보명": item["cvrNm"],
          "기준값": item["baseVal"],
          "성별": item["sex"],
          "위험급수": item["riskLevel"],
          "위험급수2": item["riskLevel2"],
          "운전형태": item["drivingDiv"],
          "연령_FR": item["frAge"],
          "연령_TO": item["toAge"],
          "직접연계담보코드": item["dirLkCvrCd"],
          "담보금액조건": item["cvrAmtCond"],
          "담보금액_FR": item["frCvrAmt"],
          "담보금액_TO": item["toCvrAmt"],
          "등록일시": item["createDate"],
        }
        returnData.push(obj);
      }
      const sheetName = `인수지침 연계담보 관리_상세`;
      const fileName = `인수지침 연계담보 관리 List_상세`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>
<style scoped>
.v-data-table::v-deep td {
  white-space: normal!important;
  word-break: keep-all;
}
</style>