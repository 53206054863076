<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-card max-width="95vw" max-height="95vh" min-width="70vw" class="overflow-y-auto modalBackground">
      <v-card-title class="headline blue-grey--text">
        <span class="font-weight-bold">상품 담보정보 변경</span>
      </v-card-title>
      <v-divider :dark="true" :light="false"/>
      <v-col cols="12" class="py-0">
        <v-sheet>
          <search-area>
            <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
              <v-radio-group
                  v-model="checkRage"
                  @change="checkSearch"
                  row
              >
                <v-radio label="약관구분"  value="1"/>
                <v-radio label="대표담보코드" value="2"/>
              </v-radio-group>
            </v-col>
            <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
              <v-select
                  :items="DATE_TYPE"
                  item-text="text"
                  item-value="value"
                  background-color="white"
                  v-model="searchType"
                  menu-props="auto"
                  hide-details outlined dense
              />
            </v-col>
          </search-area>
        </v-sheet>
      </v-col>
      <v-data-table
        class="table_sty"
        :items="listData"
        height="27vh"
        :headers="headers"
        :items-per-page="-1"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        dense fixed-header calculate-widths disable-sort
        >
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-4"
            class="white--text col-2"
            @click="save">
          저장
        </v-btn>
        <v-btn
          color="red darken-4"
          class="white--text col-2"
          @click="close">
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import SearchArea from "../../../components/Search/SearchArea";
import { axiosInstance } from "@/util/AxiosModule";
export default {
  components: {SearchArea},
  props: ["isShow", "selectData"],
  data:() => ({
    checkRage: "1",
    DATE_TYPE: [],
    listData:[],
    searchType:'',
    headers: [
      {text: "보험사", value: "insCd", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상품코드", value: "prCd", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "상품명", value: "prNm", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "담보명", value: "cvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "담보기본특약구분", value: "cvrSpDiv", class: "teal lighten-4", align: "center", sortable: false, width: "10vw"},
      {text: "약관구분", value: "cvrSpDivNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "대표담보코드", value: "cvrSeltRepCd", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "대표담보명", value: "cvrSeltCnt", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
    ],
  }),
  beforeMount() {
    this.DATE_TYPE = [
      {value: 'NONE', text: '선택'},
      {value: 'M1', text: '보통약관'},
      {value: 'S1', text: '특별약관'},
    ]
    this.searchType = "NONE";
  },
  watch: {
    selectData: function (obj) {
      this.listData = obj;
    }
  },
  methods: {
    close() {
      this.listData = [];
      this.$emit('close');
    },
    checkSearch() {
      this.DATE_TYPE = [];
      if (this.checkRage === '1') {
        this.searchType = "M1";
        this.DATE_TYPE = [
          {value: 'NONE', text: '선택'},
          {value: 'M1', text: '보통약관'},
          {value: 'S1', text: '특별약관'},
        ]
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          let objJson = new Object();
          objJson.text = this.listData[i].cvrCd + ' (' + this.listData[i].cvrNm + ')';
          objJson.value = this.listData[i].cvrCd;
          this.DATE_TYPE.push(objJson);
        }
        let objJson = new Object();
        objJson.text = "없음";
        objJson.value = "NONE";
        this.DATE_TYPE.push(objJson);
        this.searchType = this.DATE_TYPE[0].value;
      }
    },
    async save() {
      for (let i = 0; i < this.listData.length; i++) {
        delete this.listData[i].index;
        delete this.listData[i].updateDate;
      }
      try {
        const prProdCvrTbList = this.listData;
        const { data } = await axiosInstance.post('/admin/prdata/prProdCvrUpdate', prProdCvrTbList, {
          params: {
            wkDiv: this.checkRage,
            inCd: this.searchType
          }
        })
        alert(data+'건이 저장되었습니다.');
        this.checkRage = '1';
        this.DATE_TYPE = [
          {value: 'NONE', text: '선택'},
          {value: 'M1', text: '보통약관'},
          {value: 'S1', text: '특별약관'},
        ]
        this.searchType = "NONE";
        this.listData = [];
        this.$emit('update');
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>


<style scoped>
.table_sty {
  margin-left: 10px;
  margin-top: 15px;
  margin-right: 10px;
}
</style>