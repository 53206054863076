<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>

      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">상품군</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.prProdLineCd"
                :items="prProdLineList"
                :disabled="!isRowEmpty"
                background-color="white"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">보험사</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.insCd"
                :items="insCdList"
                :disabled="!isRowEmpty"
                background-color="white"
                item-text="insCdNm"
                item-value="insCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">상품코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.prCd"
                :disabled="!isRowEmpty"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="12">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="dataDto.prNm"
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                  />
                </div>
              </template>
              <span>{{ dataDto.prNm }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">상품타입</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.prodType"
                :items="prodTypeList"
                background-color="white"
                item-text="prodTypeNm"
                item-value="prodType"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">최소보험료</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.minPrem"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">적용시기</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.appFromDt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                placeholder="yyyymmdd"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">적용종기</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="dataDto.appToDt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                placeholder="yyyymmdd"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">사용성별</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.sex"
                :items="sexList"
                background-color="white"
                item-text="sexNm"
                item-value="sex"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">추천점수</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.prScore"
                background-color="white" menu-props="auto"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">업데이트표시일</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.newDispEndDt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                placeholder="yyyymmdd"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">업데이트진행중여부</v-col>
          <v-col cols="5">
            <v-select
                v-model="dataDto.updatingYn"
                :items="updatingYnList"
                item-text="updatingYnNm"
                item-value="updatingYn"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">보험기간범위</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.insTrmRange"
                :items="insTrmRangeList"
                item-text="insTrmRangeNm"
                item-value="insTrmRange"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">갱신기간범위</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.rnwTrmRange"
                :items="rnwTrmRangeList"
                item-text="rnwTrmRangeNm"
                item-value="rnwTrmRange"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">보험/납입기간</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.insPyTrmCnt"
                disabled
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">사용여부</v-col>
          <v-col cols="5">
            <v-select
                v-model="dataDto.useYn"
                :items="useYnList"
                background-color="white"
                item-text="useYnNm"
                item-value="useYn"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">가입가능연령 From</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.frAge"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">가입가능연령 To</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.toAge"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="isRowEmpty"
            color="yellow lighten-1"
            class="black--text col-1"
            @click="openEditInsTrmPopup()"
        >
          보험기간 수정
        </v-btn>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="initData"
        >
          비우기
        </v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
    <product-information-management-save-modal
        :key="modalKey"
        :is-show="showModal"
        :productInfo="rowInfo"
        :tableData="insTrmData"
        :loading="modal_loading"
        @close="showModal = false"
    />
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import TableMixin from "@/mixin/TableMixin";
import ProductInformationManagementSaveModal from "./ProductInformationManagementSaveModal";
import constant, {API_SERVER_URL_DEV} from "@/util/constant";
import _ from "lodash";

export default {
  components: {ProductInformationManagementSaveModal},
  mixins: [TableMixin],
  props: ["rowData", "prProdLineList", "insCdList"],

  data() {
    return {
      initDataDto: {},
      dataDto: {
        "prProdLineNm": "",
        "prProdLineCd": "",
        "insCd": "",
        "insNm": "",
        "prCd": "",
        "prNm": "",
        "prodType":"",
        "prodTypeNm":"",
        "minPrem": 0,
        "appFromDt": "",
        "appToDt": "",
        "sex": "",
        "prScore": "",
        "newDispEndDt": "",
        "updatingYn": "N",
        "insTrmRange": "ALL",
        "rnwTrmRange": "ALL",
        "useYn": "Y",
        "insPyTrmCnt": 0,
        "frAge": 0,
        "toAge": 0
      },
      prodTypeList: constant.PROD_TYPE,
      sexList: [
        {sexNm: "선택", sex: ""},
        {sexNm: "공용", sex: "0"},
        {sexNm: "남자", sex: "1"},
        {sexNm: "여자", sex: "2"},
      ],
      updatingYnList: [
        {updatingYnNm: "Y", updatingYn: "Y"},
        {updatingYnNm: "N", updatingYn: "N"}
      ],
      insTrmRangeList: [
        {insTrmRangeNm: "ALL", insTrmRange: "ALL"},
        {insTrmRangeNm: "100세만기", insTrmRange: "100세만기"},
        {insTrmRangeNm: "90세만기", insTrmRange: "90세만기"}
      ],
      rnwTrmRangeList: [
        {rnwTrmRangeNm: "ALL", rnwTrmRange: "ALL"},
        {rnwTrmRangeNm: "10년", rnwTrmRange: "10년"},
        {rnwTrmRangeNm: "20년", rnwTrmRange: "20년"},
        {rnwTrmRangeNm: "30년", rnwTrmRange: "30년"}
      ],
      useYnList: [
        {useYnNm: "Y", use: "Y"},
        {useYnNm: "N", use: "N"},
      ],
      modalKey: 0,
      modal_loading: false,
      showModal: false,
      isRowEmpty: true,   // 보험기간 수정 버튼 on/off
      insTrmData: [],     // 보험기간 수정 팝업 props
      rowInfo : {}        // 선택한 상품의 정보
    }
  },

  created() {
    this.initDataDto = _.cloneDeep(this.dataDto);

    if (!_.isEmpty(this.rowData)) {
      this.dataDto = this.rowData;
      this.isRowEmpty = false;
    }
  },

  methods: {
    // 저장
    async saveData() {
      // 저장시 송신하는 dto는 아래 고정
      const prProdTbDto = {
        prProdLineCd: this.dataDto.prProdLineCd,
        insCd: this.dataDto.insCd,
        prCd: this.dataDto.prCd,
        prNm: this.dataDto.prNm,
        minPrem: this.dataDto.minPrem,
        appFromDt: this.dataDto.appFromDt,
        appToDt: this.dataDto.appToDt,
        prScore: this.dataDto.prScore,
        newDispEndDt: this.dataDto.newDispEndDt,
        prodType: this.dataDto.prodType,
        useYn: this.dataDto.useYn,
        sex: this.dataDto.sex,
        updatingYn: this.dataDto.updatingYn,
        insTrmRange: this.dataDto.insTrmRange,
        rnwTrmRange: this.dataDto.rnwTrmRange,
        frAge: this.dataDto.frAge,
        toAge: this.dataDto.toAge
      }
      try {
        await axiosInstance.post('/admin/prdata/prProdSave', prProdTbDto);
        // prod -> dev 단방향 동기화
        if (process.env.NODE_ENV !== 'development') {
          await axiosInstance.post(API_SERVER_URL_DEV + '/admin/prdata/prProdSave', prProdTbDto);
        }
        alert("저장되었습니다.");

        this.$emit("resetSearch");
      } catch (e) {
        console.log(e);
      }
    },
    // 입력값 비움
    initData() {
      this.dataDto = _.cloneDeep(this.initDataDto);
      this.dataDto.prProdLineCd = this.prProdLineList[0].prProdLineCd;
      this.dataDto.prProdLineNm = this.prProdLineList[0].prProdLineNm;
      this.dataDto.insCd = this.insCdList[0].insCd;
      this.dataDto.insNm = this.insCdList[0].insCdNm;
      this.dataDto.prodType = this.prodTypeList[0].prodType;
      this.dataDto.prodTypeNm = this.prodTypeList[0].prodTypeNm;
      this.isRowEmpty = true;
    },

    async openEditInsTrmPopup() {
      this.showModal = true;
      this.modal_loading = true;
      this.rowInfo = {
        prProdLineCd: this.dataDto.prProdLineCd,
        prodLineNm: this.dataDto.prProdLineNm,
        insCd: this.dataDto.insCd,
        insNm: this.dataDto.insNm,
        prCd: this.dataDto.prCd,
        prNm: this.dataDto.prNm,
      };

      let params = {
        prProdLineCd : this.dataDto.prProdLineCd,
        insCd: this.dataDto.insCd,
        prCd : this.dataDto.prCd
      };
      try {
        const {data} = await axiosInstance.get('/admin/prdata/getPrProdInsPyTrm', {params});
        this.insTrmData = data;
      } catch (e) {
        console.log(e);
      }
      this.modal_loading = false;
      this.modalKey++;
    }
  }
}
</script>