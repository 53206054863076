<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
    <div class="close-button-layout">
      <span class="close-button" @click="closePopup">x</span>
    </div>
    <custom-layout title="답변조회 팝업">
      <v-card width="80vw" height="65vh" max-width="800" max-height="800">
        <v-row class="mx-1">
          <v-col class="align-center d-flex col-6">
            <v-col cols="3">보험사</v-col>
            <v-col>
              <v-select
                  v-model="rowData.insCd"
                  :items="insList"
                  item-text="insNm"
                  item-value="insCd"
                  hide-details outlined dense
                  background-color="white"
              />
            </v-col>
          </v-col>

          <v-col class="align-center d-flex col-6">
            <v-col cols="3">상품코드</v-col>
            <v-col>
              <v-text-field
                  v-model="rowData.prCd"
                  hide-details outlined dense disabled
                  background-color="white"
              />
            </v-col>
          </v-col>
        </v-row>

        <v-row class="mx-1">
          <v-col class="align-center d-flex col-6">
            <v-col cols="3">상품명</v-col>
            <v-col>
              <v-text-field
                  v-model="rowData.prNm"
                  hide-details outlined dense
                  background-color="white"
              />
            </v-col>
          </v-col>

          <v-col class="align-center d-flex col-6">
            <v-col cols="3">판매일자</v-col>
            <v-col>
              <v-text-field
                  v-model="rowData.stDt"
                  hide-details outlined dense disabled
                  background-color="white"
              />
            </v-col>
          </v-col>
        </v-row>

        <v-row class="mx-1">
          <v-col class="align-center d-flex col-10">
            <v-col cols="2">질문</v-col>
            <v-col>
              <v-text-field
                  v-model="rowData.priQuestion"
                  hide-details outlined dense disabled
                  background-color="white"
              />
            </v-col>
          </v-col>

          <v-col class="align-center d-flex col-2">
            <v-col class="text-right">
              <v-btn @click="getSearchResult">조회</v-btn>
            </v-col>
          </v-col>
        </v-row>

        <v-flex xs12 sm12 lg12 xl12>
          <v-data-table
              class="mx-2"
              height="20vh"
              :headers="getHeader(headers)"
              :items="data"
              :items-per-page="-1"
              @click:row="getRowData"
          />
        </v-flex>
      </v-card>
    </custom-layout>
  </v-overlay>
</template>

<script>
import _ from "lodash";
import InsAiChatManagement from "@/util/InsAiChatManagement";
import TableMixin from "@/mixin/TableMixin";
import {INS_AI_CHAT_MANAGE_PRODUCT_SEARCH_TABLE} from "@/util/TableHeaders";
import CustomLayout from "@/layouts/CustomLayout";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {CustomLayout},
  props: ["isShow", "saveData"],
  mixins: [TableMixin],
  created() {
    this.insList = InsAiChatManagement.initInsList("선택");
  },
  watch: {
    isShow(value) {
      if (value) {
        this.rowData = _.cloneDeep(this.saveData);
      }
    }
  },
  data() {
    return {
      insList: [],
      rowData: {
        insCd: "",
        priQuestion: ""
      },
      headers: INS_AI_CHAT_MANAGE_PRODUCT_SEARCH_TABLE,
      data: []
    }
  },
  methods: {
    closePopup() {
      this.rowData = {
        insCd: "",
        priQuestion: ""
      }
      this.$emit("close");
    },
    async getSearchResult() {
      let params = {
        insCd: this.rowData.insCd,
        prCd: this.rowData.prCd,
        prProdLineCd: this.rowData.prProdLineCd,
        stDt: this.rowData.stDt,
        question: this.rowData.priQuestion
      };
      const { data } = await axiosInstance.get("/admin/ai/aiChatHistList", { params });
      if (data.length < 1) {
        alert("조회된 데이터가 없습니다.");
      } else {
        this.data = data;
      }
    },
    getRowData(data) {
      this.$emit("result", data);
      this.closePopup();
    }
  }
}
</script>

<style lang="scss" scoped>
.close-button-layout {
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 20px;

  .close-button {
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
  }
}

.horizontal-icon {
  cursor: pointer;
}
.horizontal-icon:hover {
  border-radius: 5px;
  background-color: #c0c0c0;
}
</style>