<template>
  <v-card class="col-12 pa-4">
    <v-card-title style="font-weight: bold">신규/변경</v-card-title>
    <v-row class="px-6">
      <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-2 col-12">
        <v-select
            label="상품군"
            v-model="prProdLineCd"
            :items="prProdLineCdList"
            item-text="prProdLineNm"
            item-value="prProdLineCd"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-2 col-12">
        <v-select
            label="보험사"
            v-model="insCd"
            :items="insCdList"
            item-text="insCdNm"
            item-value="insCd"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-2 col-12">
        <v-select
            label="상품코드"
            v-model="prCd"
            :items="prCdList"
            item-text="prCd"
            item-value="prCd"
            background-color="white" outlined
            @click="getPrCdList"
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
        <v-text-field
            label="상품명"
            v-model="prNm"
            background-color="white" outlined disabled
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-3 col-12">
        <v-select
            label="상품타입"
            v-model="prodType"
            :items="prodTypeList"
            item-text="prodTypeNm"
            item-value="prodType"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-3 col-12">
        <v-text-field
            label="신정원코드"
            v-model="creCvrCd"
            background-color="white" outlined
            append-icon="mdi-land-rows-horizontal"
            @click:append="showCreCvrCd"
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-4 col-lg-3 col-md-4 col-12">
        <v-col cols="12">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-text-field
                    label="신정원코드명"
                    v-model="creCvrNm"
                    background-color="white" outlined disabled
                />
              </div>
            </template>
            <span>{{ creCvrNm }}</span>
          </v-tooltip>
        </v-col>
      </v-col>

      <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-2 col-12" />

      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-3 col-12">
        <v-text-field
            label="담보코드"
            v-model="cvrCd"
            background-color="white" outlined
            append-icon="mdi-land-rows-horizontal"
            @click:append="showInsCvrCd"
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-5 col-12">
        <v-col cols="12">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-text-field
                    label="담보명"
                    v-model="cvrNm"
                    background-color="white" outlined
                />
              </div>
            </template>
            <span>{{ cvrNm }}</span>
          </v-tooltip>
        </v-col>
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-2 col-12">
        <v-text-field
            label="가입안내금액"
            v-model="baseAccAmt"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-2 col-12">
        <v-text-field
            label="우선순위"
            v-model="prioOrder"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-2 col-12">
        <v-text-field
            label="보기_MIN"
            v-model="insTrmMin"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-2 col-12">
        <v-text-field
            label="보기_MAX"
            v-model="insTrmMax"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-2 col-12">
        <v-text-field
            label="납기_MIN"
            v-model="pyTrmMin"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-2 col-12">
        <v-text-field
            label="납기_MAX"
            v-model="pyTrmMax"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-2 col-12">
        <v-select
            label="사용여부"
            v-model="useYn"
            :items="useYnList"
            item-text="useYnNm"
            item-value="useYn"
            background-color="white" outlined
        />
      </v-col>
      <v-col class="d-flex align-center col-xl-9 col-lg-9 col-md-10 col-12">
        <v-col cols="9"></v-col>
        <v-col cols="3">
          <div class="float-right">
            <v-btn class="red" style="margin-right: 10px;" @click="deleteData">초기화</v-btn>
            <v-btn class="green" @click="save">저장</v-btn>
          </div>
        </v-col>
      </v-col>
    </v-row>

    <cre-cvr-mapping-modal
        :is-show="isShowCreCvrCd"
        @close="isShowCreCvrCd = false"
        @row="creCvrRow"
    />

    <ins-cvr-modal
      :is-show="isShowInsCvrCd"
      @close="isShowInsCvrCd = false"
      @row="insRow"
    />
  </v-card>
</template>

<script>
import ProductRecommend from "@/util/ProductRecommend";
import {axiosInstance} from "@/util/AxiosModule";
import CreCvrMappingModal
  from "@/pages/Product/components/ProductCompareManagement/CreCvrMappingModal/CreCvrMappingModal";
import InsCvrModal from "@/pages/Product/components/ProductCompareManagement/InsCvrModal/InsCvrModal";

export default {
  props: ["isShow", "rowData"],
  components: {InsCvrModal, CreCvrMappingModal},
  async created() {
    this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
    this.insCdList = await ProductRecommend.getInsCd();
  },
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      prodType: "",
      prodTypeList: [
        {prodTypeNm: "선택", prodType: ""},
        {prodTypeNm: "갱신형", prodType: "1"},
        {prodTypeNm: "비갱신형", prodType: "2"},
        {prodTypeNm: "무해지(0%,50%)", prodType: "5"},
        {prodTypeNm: "연만기", prodType: "7"},
        {prodTypeNm: "세만기_무해지형", prodType: "A"},
        {prodTypeNm: "기본형", prodType: "C"},
        {prodTypeNm: "체증형", prodType: "D"},
        {prodTypeNm: "무해지(0%,50%)_325", prodType: "E"},
        {prodTypeNm: "무해지(0%,50%)_335", prodType: "F"},
        {prodTypeNm: "무해지(0%,50%)_355", prodType: "H"}
      ],
      creCvrCd: "", creCvrNm: "",
      insCd: "",
      insCdList: [
        {insCdNm: "선택", insCd: ""}
      ],
      prNm: "", prCd: "",
      prCdList: [
        {prCd: ""}
      ],
      cvrCd: "", cvrNm: "",
      baseAccAmt: 0,
      prioOrder: 0,
      insTrmMin: 0,
      insTrmMax: 0,
      pyTrmMin: 0,
      pyTrmMax: 0,
      useYn: "Y",
      useYnList: [
        {useYnNm: "Y", useYn: "Y"},
        {useYnNm: "N", useYn: "N"}
      ],
      isShowCreCvrCd: false,
      creCvrRowData: [],
      isShowInsCvrCd: false,
      insRowData: [],
    }
  },
  watch: {
    rowData: function (value) {
      this.prProdLineCd = value.prProdLineCd;
      this.prProdLineCdList[0].prProdLineCd = value.prProdLineCd;
      this.prProdLineCdList[0].prProdLineNm = value.prProdLineNm;
      this.prodType = value.prodType;
      this.creCvrCd = value.creCvrCd;
      this.creCvrNm = value.creCvrNm;
      this.insCd = value.insCd;
      this.insCdList[0].insCd = value.insCd;
      this.insCdList[0].insCdNm = value.insNm;
      this.prCd = value.prCd;
      this.prCdList[0].prCd = value.prCd;
      this.prNm = value.prNm;
      this.cvrCd = value.cvrCd;
      this.cvrNm = value.cvrNm;
      this.baseAccAmt = value.baseAccAmt;
      this.prioOrder = value.prioOrder;
      this.insTrmMin = value.insTrmMin;
      this.insTrmMax = value.insTrmMax;
      this.pyTrmMin = value.pyTrmMin;
      this.pyTrmMax = value.pyTrmMax;
      this.useYn = value.useYn;
    }
  },
  methods: {
    async getPrCdList() {
      this.prCdList = await ProductRecommend.getPrCdList(this.prProdLineCd, this.insCd, this.useYn);
    },
    async save() {
      const updateDto = {
        baseAccAmt: this.baseAccAmt,
        creCvrCd: this.creCvrCd,
        cvrCd: this.cvrCd,
        insCd: this.insCd,
        prCd: this.prCd,
        prProdLineCd: this.prProdLineCd,
        prodType: this.prodType,
        prioOrder: this.prioOrder,
        insTrmMin: this.insTrmMin,
        insTrmMax: this.insTrmMax,
        pyTrmMin: this.pyTrmMin,
        pyTrmMax: this.pyTrmMax,
        useYn: this.useYn
      }
      if (confirm("저장하시겠습니까?")) {
        try {
          await axiosInstance.post('/admin/prdata/prProdCompSrcList', updateDto);
          alert("저장이 완료되었습니다!");
        } catch (e) {
          console.log(e);
        }
      }
    },
    showCreCvrCd() {
      this.isShowCreCvrCd = true;
    },
    creCvrRow(row) {
      this.creCvrCd = row.creCvrCd;
      this.creCvrNm = row.creCvrNm;

      this.creCvrRowData = row;
    },
    showInsCvrCd() {
      this.isShowInsCvrCd = true;
    },
    insRow(row) {
      this.insCdList[0].insCd = row.insCd;
      this.insCdList[0].insCdNm = row.insNm;
      this.insCd = row.insCd;
      this.insCdNm = row.insNm;
      this.cvrCd = row.cvrCd;
      this.cvrNm = row.cvrNm;

      this.insRowData = row;
    },
    deleteData() {
      this.prProdLineCd = "";
      this.prProdLineCdList[0].prProdLineCd = "";
      this.prProdLineCdList[0].prProdLineNm = "선택";
      this.prodType = "";
      this.creCvrCd = "";
      this.creCvrNm = "";
      this.insCd = "";
      this.insCdList[0].insCd = "";
      this.insCdList[0].insCdNm = "선택";
      this.prCd = "";
      this.prNm = "";
      this.cvrCd = "";
      this.cvrNm = "";
      this.baseAccAmt = 0;
      this.prioOrder = 0;
      this.insTrmMin = 0;
      this.insTrmMax = 0;
      this.pyTrmMin = 0;
      this.pyTrmMax = 0;
      this.useYn = "Y";
    }
  }
}
</script>