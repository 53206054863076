var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[(_vm.tabs === 1)?_c('span',{staticStyle:{"margin-right":"10px","font-weight":"bold","font-size":"20px"}},[_vm._v(" 단위:원")]):_vm._e(),(_vm.tabs === 1)?_c('v-btn',{staticClass:"red white--text px-7",staticStyle:{"margin":"0 3px 5px 0"},attrs:{"disabled":this.prData.length <= 0},on:{"click":_vm.removeData}},[_vm._v("삭제")]):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[(_vm.tabs === 0)?_c('v-data-table',{attrs:{"height":"50vh","items":_vm.cvrData,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","disable-sort":"","headers":_vm.getHeader(_vm.statusHeader),"hide-default-footer":""},on:{"click:row":_vm.clickStatusRow,"dblclick:row":_vm.dbClickRow},scopedSlots:_vm._u([{key:"item.accAmtList",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.insList",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.textLengthOverCut(value, 10, "..."))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.cvrDiv",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "의무사항")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "선택사항")))])]}},{key:"item.condBase",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "공통")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "개별")))])]}},{key:"item.premSumType",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "합산값")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "최대값")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "3", "최소값")))])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getKST(value)))])]}}],null,false,3722742317)}):(_vm.tabs === 1)?_c('v-data-table',{attrs:{"item-key":"index","height":"30vh","items":_vm.prData,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","disable-sort":"","headers":_vm.getHeader(_vm.cvrHeader),"show-select":""},on:{"click:row":_vm.clickCvrRow},scopedSlots:_vm._u([{key:"item.baseAccAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.prNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.textLengthOverCut(value, 15, "..."))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.cvrNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.textLengthOverCut(value, 15, "..."))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.condBase",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "공통")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "개별")))])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getKST(value)))])]}}]),model:{value:(_vm.selectData),callback:function ($$v) {_vm.selectData=$$v},expression:"selectData"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }