<template>
  <modal :is-show="isShow">
      <v-form v-model="valid" ref="form">
        <v-card class="col-12 pa-4 overflow-y-auto"
                style="background-color: #f7f7f7; max-height: 90vh; min-width: 50vw;">
          <v-icon class="ml-auto" @click="close">
            close
          </v-icon>
          <v-card-title>코드 생성</v-card-title>
          <v-divider/>
          <v-row>
            <v-col :cols="12" :class="FIELD_CLASS">
              <span :class="CREATE_FIELD_CLASS">코드</span>
              <v-text-field
                  :rules="rules.nn"
                  v-model="cd.cd"
                  class="mx-3" background-color="white"
                  hide-details outlined dense full-width
              />
            </v-col>
            <v-col :cols="12" :class="FIELD_CLASS">
              <span :class="CREATE_FIELD_CLASS">순번</span>
              <v-text-field
                  v-model="cd.no"
                  :rules="rules.num"
                  class="mx-3" background-color="white"
                  hide-details outlined dense full-width
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex align-center pr-5">
              <span :class="CREATE_FIELD_CLASS">사용 여부</span>
              <v-radio-group
                  v-model="cd.use"
                  row
              >
                <v-radio
                    label="사용"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="미사용"
                    :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" :class="CREATE_FIELD_CLASS">
              <span class="col-5">코드 내용</span>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  v-if="type === 'JSON'"
                  solo
                  v-model="cd.value"
                  rows="6"
                  :rules="[value => !!value || '내용을 입력해주세요'
                      , value => jsonVali(value) || 'json 형태의 데이터를 입력해주세요']"
                  @keydown.tab="addTabSpace"
              />
              <v-textarea
                  v-else
                  solo
                  v-model="cd.value"
                  rows="6"
                  :rules="rules.nn"
                  @keydown.tab="addTabSpace"
              />
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn :disabled="!valid || cd.value.length < 1" class="ml-auto" color="primary" @click="save">추가</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal/Modal";
import {rules} from "@/util/constant";
import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [CodeMixin],
  components: {Modal},
  created() {
    this.cd.type = store.getters['code/type'];
    this.cd.cdMasterId = store.getters['code/cdMasterId'];
  },
  data: () => ({
    valid: false,
    cd: {
      "type": "",
      "cd": "",
      "no": 0,
      "cdMasterId": "",
      "createDate": null,
      "updateDate": null,
      "use": true,
      "value": ""
    },
    kvItems: [],
    rules: rules,
  }),
  watch: {
    no: function(value) { return +value }
  },
  props: {
    type: String,
    isShow: Boolean,
    cdMasterId: String,
    isImmediately: Boolean,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async save() {
      if(this.isImmediately) {
        try{
          await axiosInstance.post('/admin/code/detail', this.cd);
        } catch (e) {
          console.log(e);
        }

        try{
          const { data } = await axiosInstance.get('/admin/code/all-detail', {
            params: {
              cdMasterId : this.cdMasterId,
              type: this.type
            }
          });
          // [프로그램 권한 관리] 변경 시 목록 재설정제 4) 개별 추가
          if (this.cdMasterId === "PROG_PRV_USER") {
            store.dispatch("login/setPrivilegeGroup");
          }
          this.$emit('refreshDetail', data);
          this.initData();
        } catch(e) {
          console.log(e);
        }
      } else {
        this.$emit('saveDetailCode', this.cd);
        this.initData();
      }
    },
    addTabSpace(event) {
      this.cd.value += "    ";
      event.preventDefault();
    },
    jsonVali(value) {
      try{
        JSON.parse(value);
        return true;
      } catch {
        return false;
      }
    },
    initData() {
      this.cd.cd = "";
      this.cd.use = true;
      this.cd.value = "";
    },
    destroyed() {this.cd ={}},
  }
}
</script>

<style scoped>
.col-12 {
  padding-bottom: 0;
}
</style>