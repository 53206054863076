<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-card max-width="95vw" max-height="95vh" min-width="70vw" class="overflow-y-auto modalBackground">
      <v-card-title class="headline blue-grey--text">
        <span class="font-weight-bold">상품가이드 등록</span>
      </v-card-title>
      <v-card-text>
        <v-row class="px-6">
          <v-col :class="inputClass">
            <span class="subtitle-1">보험사</span>
            <v-select
                @click="selectCd"
                v-model="prGuideTbDto.insCd"
                :items="insurerCode"
                background-color="white"
                item-text="value"
                item-value="cd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
          <v-col :class="inputClass">
            <span class="subtitle-1">상품코드</span>
            <v-text-field
                v-model="prGuideTbDto.prCd"
                outlined dense
                background-color="white"
                placeholder="상품코드를 입력해주세요."
            />
          </v-col>
          <v-col :class="inputClass">
            <span class="subtitle-1">상품</span>
            <v-text-field
                v-model="prGuideTbDto.prNm"
                outlined dense
                background-color="white"
                placeholder="상품먕을 입력해주세요."
            />
          </v-col>
        </v-row>
        <v-row class="px-6">
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">최소보험료</span>
            <v-text-field
                v-model="prGuideTbDto.minPrem"
                outlined dense
                class="jc-fe"
                background-color="white"
                placeholder="최소보험료를 입력해주세요."
            />
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">공지시기</span>
            <v-text-field
                v-model="prGuideTbDto.noticeFromDt"
                outlined dense
                background-color="white"
                placeholder="YYYYMMDD"
            />
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">공지종기</span>
            <v-text-field
                v-model="prGuideTbDto.noticeToDt"
                outlined dense
                background-color="white"
                placeholder="YYYYMMDD"
            />
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">게시계속여부</span>
            <v-select
                v-model="prGuideTbDto.displayContYn"
                :items="ContYn"
                item-text="cname"
                item-value="cvalue"
                outlined dense
                background-color="white"
            />
          </v-col>
        </v-row>
        <v-row class="px-6">
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">인기상품여부</span>
            <v-select
                v-model="prGuideTbDto.popPrYn"
                :items="ContYn"
                item-text="cname"
                item-value="cvalue"
                outlined dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">추천상품여부</span>
            <v-select
                v-model="prGuideTbDto.prPrYn"
                :items="ContYn"
                item-text="cname"
                item-value="cvalue"
                outlined dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">인기점수</span>
            <v-text-field
                v-model="prGuideTbDto.popScore"
                outlined dense
                background-color="white"
                placeholder="0~100"
            />
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <span class="subtitle-1">인기플랜코드</span>
            <v-text-field
                v-model="prGuideTbDto.popPlanCd"
                outlined dense
                background-color="white"
                placeholder="인기플랜코드를 입력해주세요."
            />
          </v-col>
        </v-row>
        <v-col cols="12" class="py-0">
          <v-sheet class="background" style="padding: 0px;">
            <v-row class="px-6">
              <v-col class="flex-column col-3">
                <v-btn
                    class="ml-auto mr-3"
                    color="primary"
                    style="width: 13vw;"
                    @click="showUpdate('guide')">
                  가이드파일명등록번호
                </v-btn>
              </v-col>
              <v-col class="d-flex flex-column col-1">
                <v-text-field
                    v-model="prGuideTbDto.guideFileNmSeq"
                    outlined dense hide-details
                    background-color="white"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex flex-column col-8">
                <v-text-field
                    v-model="prGuideTbDto.guideFileNmUrl"
                    outlined dense hide-details
                    background-color="white"
                    :disabled="true"
                />
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col class="flex-column col-3">
                <v-btn
                    class="ml-auto mr-3"
                    @click="showUpdate('img')"
                    style="width: 13vw;"
                    color="primary">
                  안내이미지파일명등록번호
                </v-btn>
              </v-col>
              <v-col class="d-flex flex-column col-1">
                <v-text-field
                    v-model="prGuideTbDto.imgFileNmSeq"
                    outlined dense hide-details
                    background-color="white"
                    :disabled="true"
                />
              </v-col>
              <v-col class="d-flex flex-column col-8">
                <v-text-field
                    v-model="prGuideTbDto.imgFileNmUrl"
                    outlined dense hide-details
                    background-color="white"
                    :disabled="true"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <regist-file-dialog
            :dialog="dialog"
            @close="dialog = false"
            @updateSetting="setDialog"
            @result="setGuideFileNmSeq"
            />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-4"
            class="white--text col-2"
            @click="saveData"
        >
          저장
        </v-btn>
        <v-btn
            color="red darken-4"
            class="white--text col-2"
            @click="close"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import RegistFileDialog from "../../Manage/ContentsFile/components/RegistFileDialog";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
export default {
  components: {RegistFileDialog},
  props: ["isShow"],
  data:() => ({
    inputClass: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12',
    dialog: false,
    prGuideTbDto: {
      insCd:'',
      prNm:'',
      popPrYn:'Y',
      prCd:'',
      prPrYn:'Y',
      createDate:'',
      popScore:'',
      noticeToDt:'',
      noticeFromDt:'',
      popPlanCd:'',
      minPrem:'',
      displayContYn: 'Y',
      checkVal:'',
      guideFileNmSeq:'',
      guideFileNm:'',
      imgFileNm:'',
      guideFileNmUrl:'',
      imgFileNmUrl:'',
      imgFileNmSeq:'',
    },
    insurerCode: [
      {value: '선택', cd: ''}
    ],
    ContYn: [
      {cname: 'Y', cvalue: 'Y'},
      {cname: 'N', cvalue: 'N'}
    ]
  }),
  methods: {
    close() {
      this.prGuideTbDto=[];
      this.prGuideTbDto.insCd = '';
      this.prGuideTbDto.popPrYn = 'Y';
      this.prGuideTbDto.prPrYn = 'Y';
      this.prGuideTbDto.displayContYn = 'Y';
      this.$emit('close');
    },
    showUpdate(val) {
      this.prGuideTbDto.checkVal = val;
      this.dialog = true;
    },
    setDialog (value) {
      this.dialog = value
    },
    setGuideFileNmSeq(val) {
      if (this.prGuideTbDto.checkVal === 'guide') {
        this.prGuideTbDto.guideFileNmSeq = val;
      } else {
        this.prGuideTbDto.imgFileNmSeq = val;
      }
      this.refreshContentsAPI();
    },
    async refreshContentsAPI() {
      // 갱신 API
      try{
        const {data} = await axiosInstance.get("/admin/operation/contFileInfo");
        for (let i = 0; i < data.length; i++) {
          if (data[i].contFileInfoSeq === this.prGuideTbDto.guideFileNmSeq) {
            if (this.prGuideTbDto.checkVal === 'guide') {
              this.prGuideTbDto.guideFileNm = data[i].contFileNm;
              this.prGuideTbDto.guideFileNmUrl = data[i].contFileSaveRoot;
            } else {
              this.prGuideTbDto.imgFileNm = data[i].contFileNm;
              this.prGuideTbDto.imgFileNmUrl = data[i].contFileSaveRoot;
            }
          }
        }
      }
      catch(e) {
        console.log(e)
        alert("컨텐츠 갱신에 실패했습니다.")
      }
    },
    async saveData() {
      if (_.isEmpty(this.prGuideTbDto.popScore)) {
        this.prGuideTbDto.popScore = null;
      } else {
        var codeReg =  /^[0-9]+$/;
        if (!codeReg.test(this.prGuideTbDto.popScore)) {
          alert("숫자만 입력해주세요.");
          return;
        }
      }
      const prGuideTbDto = {
        insCd: this.prGuideTbDto.insCd,
        prCd: this.prGuideTbDto.prCd,
        prNm: this.prGuideTbDto.prNm,
        minPrem: this.prGuideTbDto.minPrem.replaceAll(',', ""),
        guideFileNmSeq: this.prGuideTbDto.guideFileNmSeq,
        guideFileNm: this.prGuideTbDto.guideFileNm,
        guideFileNmUrl: this.prGuideTbDto.guideFileNmUrl,
        imgFileNm: this.prGuideTbDto.imgFileNm,
        imgFileNmUrl: this.prGuideTbDto.imgFileNmUrl,
        imgFileNmSeq: this.prGuideTbDto.imgFileNmSeq,
        noticeFromDt: this.prGuideTbDto.noticeFromDt,
        noticeToDt: this.prGuideTbDto.noticeToDt,
        displayContYn: this.prGuideTbDto.displayContYn,
        popPrYn: this.prGuideTbDto.popPrYn,
        prPrYn: this.prGuideTbDto.prPrYn,
        popScore: this.prGuideTbDto.popScore,
        popPlanCd: this.prGuideTbDto.popPlanCd,
      }
      console.log("prGuideTbDto", prGuideTbDto);
      if(confirm("저장하시겠습니까?")){
        try {
          const {data} = await axiosInstance.post('/admin/prdata/prGuideSave', prGuideTbDto);
          alert(data);
          this.close();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}
</script>