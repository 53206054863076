<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin-right: 3px;"
          @click="toExcel"
          :disabled="data.length <= 0"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
      </v-data-table>
    </v-flex>

    <product-plan-management-detail-modal
        :rowData="rowData"
        :is-show="isShowModal"
        @close="isShowModal = false"
      />
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {MAIN_CVR_STATUS_TABLE, } from "@/util/TableHeaders";
import ProductPlanManagementDetailModal
  from "@/pages/Product/components/ProductPlanManagement/ProductPlanManagementDetailModal";
import _ from "lodash";
import func from "@/util/func";

export default {
  components: {ProductPlanManagementDetailModal},
  mixins: [TableMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: MAIN_CVR_STATUS_TABLE,
      rowData: [],
      isShowModal: false,
    }
  },
  methods: {
    clickContents(row) {
      this.rowData = row;
      this.isShowModal = true;
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "상품군명": item["prProdLineNm"],
          "보험사명": item["insNm"],
          "상품코드": item["prCd"],
          "상품명": item["prNm"],
          "플랜코드": item["planCd"],
          "플랜명": item["planNm"],
          "담보코드": item["cvrCd"],
          "담보명": item["cvrNm"],
          "담보기본특약구분": item["cvrSpDiv"],
          "소스구분": item["srcType"],
          "갱신주기": item["rnwCycle"],
          "기본안내가입금액": item["baseAccAmt"],
          "FR_가입한도금액": item["frAccAmt"],
          "TO_가입한도금액": item["toAccAmt"],
          "주력담보여부": item["mainCvrYn"],
        }
        returnData.push(obj);
      }
      const sheetName = `주력담보 현황`;
      const fileName = `주력담보 현황`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>