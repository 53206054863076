var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('div',{staticClass:"d-flex align-end mb-1 mt-2"},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("목록(총 "+_vm._s(_vm.tableData.length)+"개)")])]),_c('v-data-table',{attrs:{"height":"40vh","item-key":"index","items":_vm.tableData,"loading":_vm.loading,"headers":_vm.getHeader(_vm.header),"dense":"","fixed-header":"","calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.contractPremAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.exRewardAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.ainRewardAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.rewardAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }