<template>
  <custom-layout title="표 형식 데이터 전환">
    <v-sheet class="main-sheet" max-width="100%">
      <v-card width="43vw" max-width="1000" min-height="500" elevation="5" :loading="loading">
        <template slot="progress">
          <v-progress-linear
              color="blue darken-2"
              height="5"
              indeterminate/>
        </template>
        <v-card-title class="card-title" style="justify-content: space-between;">
          <h3>입력값</h3>
          <v-card-actions class="card-footer">
            <v-btn
                class="button-title-reset"
                color="red darken-1" text outlined rounded @click="resetContext">
              <span class="reset-title">초기화</span>
            </v-btn>
          </v-card-actions>
        </v-card-title>

        <v-card-text>
          <v-row class="input-container cols-count">
            <v-col cols="12">
              <input v-model="colsCount"
                     class="text-field"
                     inputmode="numeric"
                     placeholder="column 수 입력"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row class="input-container">
            <v-col cols="12">
              <textarea v-model="inputData"
                        class="text-field"
                        placeholder="입력값을 입력해주세요"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="card-footer">
          <v-btn
              class="result-btn"
              color="blue darken-1" text outlined rounded @click="convertContext">
            <span class="reset-title">변환</span>
          </v-btn>
        </v-card-actions>
      </v-card>

      <span class="arrow-right-container">
<!--        <span class="arrow-right">→</span>-->
      </span>

      <v-card width="43vw" max-width="1000" elevation="5">
        <v-card-title class="card-title">
          <h3>결과값</h3>
        </v-card-title>
        <v-card-text>
          <v-row class="input-container">
            <v-col cols="12">
              <textarea v-model="resultData"
                        class="text-field"
                        disabled
                        placeholder="출력"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-footer">
          <v-btn
              class="result-btn" id="clipboard-btn"
              color="blue darken-1" text outlined rounded
              :disabled="isEmptyResult"
              @click="copyResult"
          >
            <span class="reset-title">복사</span>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-sheet>
  </custom-layout>
</template>

<script>
import CustomLayout from "@/layouts/CustomLayout";
import _ from "lodash";

export default {
  name: "TransChartToText",
  components: {CustomLayout},
  data() {
    return {
      loading: false,
      inputData: "",  // 입력
      colsCount: "",  // 입력 열 수
      resultData: "", // 변환된 문단
      colsKRnameList: ["첫", "두", "세", "네", "다섯", "여섯", "일곱", "여덟", "아홉", "열"]  // 필요하면 더 추가
    }
  },
  computed: {
    isEmptyResult(){
      return _.isEmpty(this.resultData);
    }
  },

  methods: {
    // 문단 변환
    convertContext() {
      let cols = this.colsCount;

      if (!/^\d+$/.test(cols)) {
        alert("정수형을 입력해주세요.")
        return false;
      }
      if (cols < 1) {
        alert("1 이상의 수를 입력해주세요.")
        return false;
      }

      let data = this.inputData.split("\n");
      let result = "표형식:\n";
      let footerText = `\n자연어 형식: "표의 `

      // 하단 자연어 형식 설명
      for (let i = 0; i < cols; i++) {
        footerText += this.colsKRnameList[i] + " 번째 컬럼은 " + data[i] + "을(를) 나타내고, "
      }
      footerText += `각 컬럼별 구분은 | 입니다."`

      // 변환 결과
      for (let i = 0; i < data.length; i++) {
        result += data[i];
        if ((i + 1) % cols == 0 && i != data.length - 1) {
          result += "\n";
        } else {
          if (i != data.length - 1) result += "|";
        }
      }

      this.resultData = result + footerText;
    },

    // 결과 복사
    copyResult() {
      this.$copyText(this.resultData)
          .then(() => {
            console.log('클립보드에 복사되었습니다');
          })
          .catch(err => {
            console.error('복사 실패:', err);
          });
    },

    resetContext() {
      this.colsCount = "";
      this.inputData = "";
      this.resultData = "";
    },

  }
}
</script>

<style scoped>
.main-sheet {
  margin-top: 50px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
}

.card-title {
  display: flex;
  height: 80px;
}

.input-container {
  border-top: 1px solid #d5d5d5;
  display: flex;
  justify-content: center;
}

.input-container .col {
  /*padding-top: 30px;*/
}

.input-container .text-field {
  resize: none !important;
  border: 1px solid darkgrey;
  border-radius: 5px;
  padding: 7px;
  height: 50vh;
  width: 100%;
}

.input-container.cols-count {
  height: 20px;
}

.input-container.cols-count .text-field {
  height: 100%;
}

/* 결과 화살표 */
.arrow-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.arrow-right {
  font-size: 50px;
}

/* 조회 및 초기화 버튼 */
.card-footer {
  justify-content: center;
}

.card-footer .v-btn.button-title-reset {
  width: 120px;
  border: 2px solid #ff3d00;
  font-weight: bolder;
}

.card-footer .v-btn.button-title-reset:hover {
  background-color: #ff3d00;
}


.card-footer .v-btn.result-btn {
  width: 160px;
  height: 40px;
  border: 2px solid #8094ff;
  font-weight: bolder;
}

.card-footer .v-btn.result-btn:hover {
  background-color: #8094ff;
}


.card-footer .v-btn:hover .reset-title {
  color: #fff;
}
</style>