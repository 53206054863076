<script>
import { Bar } from 'vue-chartjs';
import _ from "lodash";

export default {
  props: ["totArry", "monthArray", "insuredNm"],
  extends: Bar,
  data() {
    return{
      datasets : [
        {
          label: "",
          type: "bar",
          data: [],
          backgroundColor: "rgba(30,144,255,0.8)",
          hoverBackgroundColor: "rgb(30,144,255)",
          borderWidth: 3
        },
        {
          label: "",
          type: "bar",
          data: [],
          backgroundColor: "rgba(255,69,0,0.8)",
          hoverBackgroundColor: "rgb(255,69,0)",
          borderWidth: 3
        },
        {
          label: "",
          type: "bar",
          data: [],
          backgroundColor: "rgba(169,169,169,0.8)",
          hoverBackgroundColor: "rgb(169,169,169)",
          borderWidth: 3,
        }
      ]
    }
  },

  mounted() {
    //상단 라벨(년도)
    const month = this.monthArray.reverse();
    if (!_.isEmpty(month[2])){
      this.datasets[0].label = month[2];
    }
    if (!_.isEmpty(month[1])){
      this.datasets[1].label = month[1];
    }
    if (!_.isEmpty(month[0])){
      this.datasets[2].label = month[0];
    }

    let cuntVal = 0;
    cuntVal = this.totArry[0].value.length;
    // 콤마 찍혀있는 문자형 콤마 제거, 데이터 역순 출력
    for(let i in this.totArry) {
      if (this.totArry[i].value[cuntVal-2] !== 0) {
        this.datasets[2].data[i] = this.totArry[i].value[cuntVal-2].split(',').join("");
      } else {
        this.datasets[2].data[i] = 0
      }
    }
    for(let i in this.totArry) {
      if (this.totArry[i].value[cuntVal-5] !== 0) {
        this.datasets[1].data[i] = this.totArry[i].value[cuntVal-5].split(',').join("");
      } else {
        this.datasets[1].data[i] = 0
      }
    }
    for(let i in this.totArry) {
      if (this.totArry[i].value[cuntVal-8] !== 0) {
        this.datasets[0].data[i] = this.totArry[i].value[cuntVal-8].split(',').join("");
      } else {
        this.datasets[0].data[i] = 0
      }
    }
    if (this.insuredNm.length > 10 ) {
      alert("새로고을 해주세요.");
      return;
    }
    this.renderChart(
        {
          labels: this.insuredNm,
          datasets: this.datasets
        },
        { responsive: true, maintainAspectRatio: false, beginAtZero: true,
          tooltips: {
            callbacks: {
              label: function (tooltipItem) { //그래프 콤마
                return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true,
                callback: function(value) {
                  if(parseInt(value) >= 1000){
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return value
                  }
                }
              }
            }]
          }}
    );
  },
  watch: {
    totArry:function () {
      let cuntVal = 0;
      cuntVal = this.totArry[0].value.length;
      // 콤마 찍혀있는 문자형 콤마 제거, 데이터 역순 출력
      for(let i in this.totArry) {
        if (this.totArry[i].value[cuntVal-2] !== 0) {
          this.datasets[2].data[i] = this.totArry[i].value[cuntVal-2].split(',').join("");
        } else {
          this.datasets[2].data[i] = 0
        }
      }
      for(let i in this.totArry) {
        if (this.totArry[i].value[cuntVal-5] !== 0) {
          this.datasets[1].data[i] = this.totArry[i].value[cuntVal-5].split(',').join("");
        } else {
          this.datasets[1].data[i] = 0
        }
      }
      for(let i in this.totArry) {
        if (this.totArry[i].value[cuntVal-8] !== 0) {
          this.datasets[0].data[i] = this.totArry[i].value[cuntVal-8].split(',').join("");
        } else {
          this.datasets[0].data[i] = 0
        }
      }
      if (this.insuredNm.length > 10 ) {
        alert("새로고을 해주세요.");
        return;
      }
      this.$data._chart.update();
    },
    data:function () {
      this.renderChart(
          {
            labels: this.insuredNm,
            datasets: this.datasets
          },
          { responsive: true, maintainAspectRatio: false, beginAtZero: true,
            tooltips: {
              callbacks: {
                label: function (tooltipItem) { //그래프 콤마
                  return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
              },
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero:true,
                  callback: function(value) {
                    if(parseInt(value) >= 1000){
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                      return value
                    }
                  }
                }
              }]
            }}
      );
    }
  }
}
</script>