<template>
	<search-bar-layout>
	<template v-slot:contents>
		
		<search-area>
			<field-input title="태그">
				<v-text-field
					v-model="searchFileTag"
					outlined dense hide-details
					background-color="white" menu-props="auto"
					@keypress.enter="getContents"
				/>
			</field-input>
			<field-input title="파일이름">
				<v-text-field
					v-model="searchFileName"
					outlined dense hide-details
					background-color="white" menu-props="auto"
					@keypress.enter="getContents"
				/>
			</field-input>
			<v-col class="d-flex align-center  col-lg-6  col-sm-12 col-12">
				<v-col cols="2">생성날짜</v-col>
				<v-col cols="5">
            <date-input
                :start-date="searchFileStartDate"
                :end-date="searchFileEndDate"
                @updateDate="updateDate"
            />
			</v-col>
			<v-col cols="1" align="right">
				<v-btn  @click="getContents">
					조회
				</v-btn>
			</v-col>
			<v-col cols="1">
				<v-btn @click="$emit('regist')">
					등록
				</v-btn>
			</v-col>
			<v-col cols="1">
				<v-btn  class="red lighten-2" @click="delContents">
					삭제
				</v-btn>
			</v-col>
        </v-col>
		</search-area>
		
	</template>
	
	</search-bar-layout>
	
	
</template>

<script>

import SearchBarMixin from "@/mixin/SearchBarMixin";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import FieldInput from "@/components/Form/FieldInput";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
	mixins: [CodeMixin, SearchBarMixin,DateMixin],

	components: {FieldInput, SearchArea, SearchBarLayout, DateInput},
	async beforeMount() {
    // 시작 날짜, 종료 날짜 초기 설정
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const [year, month] = [date.getFullYear(), date.getMonth()];
    this.searchFileStartDate = `${year}-${this.addZero(month + 1)}-01`;
    this.searchFileEndDate = `${year}-${this.addZero(month + 1)}-${new Date(year, month + 1, 0).getDate()}`;
  },
	data: () => ({
		selectFile:null,
		registTag: "",
		registData: {
			fileType:"",
			fileName:"",
			fileSize:"",
			fileString:null,
			width: 0,
			height: 0
		},
		searchFileTag: "",
		searchFileName: "",
		searchFileStartDate: "",
		searchFileEndDate: ""
		}),

	methods: {
		updateDate(values) {
      this.searchFileStartDate = values[0];
      this.searchFileEndDate = values[1];
    },
		getContents() {

			var params = {}

			if(this.searchFileTag !== "")  params["tag"] = this.searchFileTag
			if(this.searchFileName !== "")  params["fileNm"] = this.searchFileName

			if(this.searchFileStartDate !== "" && this.searchFileEndDate !== ""){
				params["startDate"] = this.searchFileStartDate
				params["endDate"] = this.searchFileEndDate
			}

			this.$emit('search', params);
		},
		delContents() {
			this.$emit('delContents')
		}
	},



}

</script>