<template>
  <v-menu
      v-model="isShow"
      transition="scale-transition"
      :close-on-content-click="false"
      offset-y min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          @click:append="isShow = !isShow"
          v-on="on" v-bind="attrs"
          label="등록일자"
          v-model="dateRange"
          readonly
          outlined hide-details  background-color="white"
          append-icon="mdi-calendar-blank"
      />
    </template>
    <v-date-picker
        v-model="dates"
        @input="closeTwoDate"
        :show-current="false"
        :day-format="removeBackWord"
        range locale="ko-kr"
    />
  </v-menu>
</template>

<script>
export default {
  name: "CustomDatePicker",
  props: ["startDate", "endDate"],
  data() {
    return {
      isShow: false,
      isSecondClick: false,
      dates: ['', ''],
      backupDates: ['', '']
    }
  },
  created() {
    this.dates = [this.startDate, this.endDate]
  },
  computed: {
    dateRange() {
      return this.dates.join(' ~ ');
    }
  },
  methods: {
    validation() {
      if (this.isSecondClick) {
        this.$emit('updateDate', [this.dates[0], '']);
      } else {
        const s = this.dates[0].split('-');
        const e = this.dates[1].split('-');

        const diffDay = (new Date(e[0], e[1], e[2]) - new Date(s[0], s[1], s[2])) / 1000 / 60 / 60 / 24;

        if (diffDay < 0) {
          let temp = this.dates[0]
          this.dates[0] = this.dates[1];
          this.dates[1] = temp;
          this.validation();
        } else {
          if (diffDay > 62) {
            alert("일자 범위는 두달 이내로 설정해주세요.");
            this.dates[1] = '';
            this.$emit('updateDate', [this.dates[0], this.dates[1]]);
          } else {
            this.$emit('updateDate', [this.dates[0], this.dates[1]])
          }
        }
      }
    },
    removeBackWord(d) {
      return new Date(d).getDate();
    },
    closeTwoDate() {
      if (this.isSecondClick) {
        this.isSecondClick = false;
        this.isShow = false;
      } else {
        this.isSecondClick = true;
      }
      this.validation();
    }
  }
}
</script>