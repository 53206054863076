<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="usageStatus"
        :loading="loading"
        :items-per-page="-1"
        fixed-header disable-sort calculate-widths
        :headers="headers"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>

export default {
  props:["usageStatus", "loading"],
  computed: {
    hasTableData() {
      return this.usageStatus.length > 0;
    }
  },
  data:() => ({
    headers: [{text: "사용자명", value: "memberNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "이메일", value: "email", class: "teal lighten-4", align: "center", sortable: false},
      {text: "사용일시", value: "useDateTime", class: "teal lighten-4", align: "center", sortable: false},
      {text: "사용액션", value: "actionNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "결과건수", value: "resultCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "검색어", value: "searchWord", class: "teal lighten-4", align: "center", sortable: false},
      {text: "메인지역입력건", value: "mainRegion", class: "teal lighten-4", align: "center", sortable: false},
      {text: "서브지역입력건", value: "subRegion", class: "teal lighten-4", align: "center", sortable: false},
      {text: "프로그램버전", value: "progVersion", class: "teal lighten-4", align: "center", sortable: false}
    ]
  })
}
</script>