<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="조회조건">
        <field-input title="구분">
          <v-select
              :items="EVENT_TYPE"
              @change="changeType"
              background-color="white"
              v-model="type" menu-props="auto"
              hide-details outlined dense>
          </v-select>
        </field-input>
        <field-input title="기준연월">
          <v-col cols="7" v-if="type === 'INVITER' || type === 'MISILJEUK_LINK_SEND' || type === 'PRODUCT_RECOMMEND'">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
          <v-text-field v-else
              v-model="baseYM"
              @change="changeBaseYM"
              @keypress.enter="getEventList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="마케팅동의" v-if="type === 'CONTINUOUS_OPERATION'">
          <v-select
              :items="MARKETING_AGREE"
              background-color="white"
              v-model="marketingAgree" menu-props="auto"
              hide-details outlined dense>
          </v-select>
        </field-input>
      </search-area>

    </template>
    <template v-slot:button>
      <search-btn :disabled="isDisabled" @click="getEventList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import DateInput from "@/components/Form/DateInput";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {SearchArea, SearchBarLayout, FieldInput, DateInput},
  async created() {
    // this.EVENT_TYPE = await this.getSelectList('EVENT_TYPE');
    this.changeBaseYM();
  },
  async beforeMount() {
    // 시작 날짜, 종료 날짜 초기 설정
    const date = new Date();
    date.setMonth(date.getMonth());
    const [year, month] = [date.getFullYear(), date.getMonth()];
    this.baseYM = `${year}${this.addZero(month + 1)}`;

    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data: () => ({
    EVENT_TYPE: [
        {"value": "FIRST_CONTRACT","text": "최초계약자_이벤트"},
        {"value": "INVITER","text": "추천인_피추천인 이벤트"},
        {"value": "CONTINUOUS_OPERATION","text": "연속가동 리스트"},
        {"value": "CONVERT_MEMBER","text": "정회원_전환 이벤트"},
        {"value": "MISILJEUK_LINK_SEND","text": "미실적자_링크전달 이벤트"},
        {"value": "QUIZ_CONTESTANT","text": "퀴즈_이벤트"},
        {"value": "PRODUCT_RECOMMEND","text": "상품추천_이벤트"},
        {"value": "KATALK_FRIEND","text": "카톡친구"}
    ],
    MARKETING_AGREE: [
      {"value": "","text": "전체"},
      {"value": "Y","text": "동의"},
      {"value": "N","text": "미동의"}
    ],
    type: "FIRST_CONTRACT",
    baseYM: "",
    startDate: "",
    endDate: "",
    marketingAgree: "Y",

  }),
  computed: {
    isDisabled() {
      var regex = RegExp(/^\d{4}(0[1-9]|1[012])$/);
      return (
          !regex.test(this.baseYM)
      );
    }
  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    getEventList() {
      if (this.isDisabled) return;
      let stdt = this.startDate.replaceAll("-", "");
      let eddt = this.endDate.replaceAll("-", "");

      const params = {
        "baseYM": this.baseYM.replaceAll(' ', ''),
        "searchType": this.type,
        "stdt": stdt,
        "eddt": eddt,
        "marketingAgree": this.marketingAgree
      };

      this.$emit('search', params);
    },
    changeType(){
      this.$emit('change', this.type);
    },
    changeBaseYM(){
      this.$emit('changeBaseYM', this.baseYM);
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>