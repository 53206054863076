<template>
  <search-page-layout title="상품군 적용조건 관리">
    <template v-slot:searchBar>
      <application-condition-management-search-bar
          @getSearch="getSearch"
          @changePrProdLine="changePrProdLine"
          @changeCondDiv="changeCondDiv"
      />
    </template>

    <template v-slot:table>
      <application-condition-management-table
          :data="data"
          :loading="loading"
          @row="row"
      />
    </template>

    <template v-slot:inflowHist>
      <application-condition-management-save
          :rowData="rowData"
          :prProdLineValue="prProdLineValue"
          :condDivValue="condDivValue"
      />
    </template>
  </search-page-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ApplicationConditionManagementSearchBar
  from "@/pages/Product/components/ApplicationConditionManagement/ApplicationConditionManagementSearchBar";
import ApplicationConditionManagementTable
  from "@/pages/Product/components/ApplicationConditionManagement/ApplicationConditionManagementTable";
import ApplicationConditionManagementSave
  from "@/pages/Product/components/ApplicationConditionManagement/ApplicationConditionManagementSave";

export default {
  components: {
    ApplicationConditionManagementSave,
    ApplicationConditionManagementTable, ApplicationConditionManagementSearchBar, SearchPageLayout},
  data() {
    return {
      loading: false,
      data: [],
      rowData: [],
      prProdLineValue: "",
      condDivValue: "",
    }
  },
  methods: {
    changePrProdLine(prProdLineData) {
      this.prProdLineValue = prProdLineData;
    },
    changeCondDiv(condDivData) {
      this.condDivValue = condDivData;
    },
    // 조회
    async getSearch(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/private/prdata/prProdLineCondList', { params });
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].createDate = this.data[i].createDate.replace('T', ' ').substr(0, 19);
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    // 목록데이터 담기
    row(row) {
      this.rowData = row;
    }
  }
}
</script>