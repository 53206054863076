<template>
  <!-- by sk.jeon -->
  <search-page-layout title="원클릭 주요메뉴 사용현황">

    <template v-slot:tabBar>
      <v-tabs
          center-active
          v-model="active"
      >
        <v-tab>사용현황</v-tab>
        <v-tab>일자별</v-tab>
        <v-tab-item>
          <usage-status
            @updateSetting="updateSetting"
            @toExcel="toExcel"
          />
        </v-tab-item>
        <v-tab-item>
          <status-date
            :updateData="updateData"
          />
        </v-tab-item>
      </v-tabs>
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import UsageStatus from "./components/OperationStat/UsageStatus";
import StatusDate from "./components/OperationStat/StatusDate";
import _ from "lodash";
import func from "@/util/func";
export default {
  components: {StatusDate, UsageStatus, SearchPageLayout},
  data: () => ({
    loading: false,
    updateData: [],
    tgKey: 0,
    active: [0, 1],
  }),
  methods: {
    updateSetting(data) {
      this.active = 1;
      this.updateData = data;
    },
    toExcel(params) {
      if (_.isEmpty(params)) return;
      if (_.isEmpty(params.tableData)) return;
      const data = _.cloneDeep(params.tableData);
      const returnData = [];

      for (const item of data) {
        const obj = {};
        for(var i = 0; i < params.headers.length; i++)
        {
          obj[params.headers[i].text] = item[params.headers[i].value];
        }
        returnData.push(obj);
      }

      const sheetName = "주요메뉴_" + params.menuNm + "_" + params.startDt + "_" + params.endDt;
      const fileName = "주요메뉴_" + params.menuNm + "_" + params.startDt + "_" + params.endDt;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>