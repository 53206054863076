<script>
import { Doughnut } from 'vue-chartjs';
import "chartjs-plugin-piechart-outlabels";

export default {
  props: ["totArry", "monthArray", "insuredNm"],
  extends: Doughnut,
  data() {
    return{
      datasets : [
        {
          label: [],
          type: "doughnut",
          data: [],
          backgroundColor: [ "#6699FF", "#669900", "#CC9900", "#990099", "#6633FF", "#00CC99" , "#FF3333", "#f0b4b4"],
        }
      ],
    }
  },

  mounted() {
    //Array data 배열 복사
    let totalArray = [];
    this.totArry.forEach(function(item){
      totalArray.push(item);
    })
    //Array name label 배열 복사
    let totalNmArray = [];
    this.insuredNm.forEach(function(item){
      totalNmArray.push(item);
    })

    //소계깂 빼기
    for (let i = 0; i < totalArray.length; i++) {
      if (totalArray[i].nm === '소계') {
        totalArray.splice(i, 1);
      }
    }
    for (let i = 0; i < totalNmArray.length; i++) {
      if (totalNmArray[i] === '소계') {
        totalNmArray.splice(i, 1);
      }
    }

    //콤마 제거 후 합계
    let totalHD = 0;
    let totalDB = 0;
    let totalKB = 0;
    let totalHK = 0;
    let totalHW = 0;
    let totalCR = 0;
    let totalHN = 0;
    let totalAXA = 0;

    for (let i = 0; i < totalArray[0].value.length; i++) {
      if (i%3 == 1) {
        if(totalArray[0].value[i] != 0) {
          totalHD += Number(totalArray[0].value[i].split(',').join(""));
        } else {
          totalHD += 0
        }
        if(totalArray[1].value[i] != 0) {
          totalDB += Number(totalArray[1].value[i].split(',').join(""));
        } else {
          totalDB += 0
        }
        if(totalArray[2].value[i] != 0) {
          totalKB += Number(totalArray[2].value[i].split(',').join(""));
        } else {
          totalKB += 0
        }
        if(totalArray[3].value[i] != 0) {
          totalHK += Number(totalArray[3].value[i].split(',').join(""));
        } else {
          totalHK += 0
        }
        if(totalArray[4].value[i] != 0) {
          totalHW += Number(totalArray[4].value[i].split(',').join(""));
        } else {
          totalHW += 0
        }
        if(totalArray[5].value[i] != 0) {
          totalCR += Number(totalArray[5].value[i].split(',').join(""));
        } else {
          totalCR += 0
        }
        if(totalArray[6].value[i] != 0) {
          totalHN += Number(totalArray[6].value[i].split(',').join(""));
        } else {
          totalHN += 0
        }
        if(totalArray[7].value[i] != 0) {
          totalAXA += Number(totalArray[7].value[i].split(',').join(""));
        } else {
          totalAXA += 0
        }
      }
    }
    this.datasets[0].data[0] = totalHD;
    this.datasets[0].data[1] = totalDB;
    this.datasets[0].data[2] = totalKB;
    this.datasets[0].data[3] = totalHK;
    this.datasets[0].data[4] = totalHW;
    this.datasets[0].data[5] = totalCR;
    this.datasets[0].data[6] = totalHN;
    this.datasets[0].data[7] = totalAXA;

    this.renderChart(
        {
          labels: totalNmArray,
          datasets: this.datasets,
        },
        {
          responsive: true, maintainAspectRatio: false,
          layout: {
            padding: 130
          },
          legend: {
            display: true,
            position: 'right'
          },
          plugins: {
            outlabels: {
              text: '%l (%p.1)',
              color: 'white',
              stretch: 28,
              font: {
                resizable: true,
                minSize: 12,
                maxSize: 18
              }
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) { //그래프 콤마
                var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ '원';
              }
            },
          },
        }
    );
  },
  watch: {
    totArry:function () {
      //Array data 배열 복사
      let totalArray = [];
      this.totArry.forEach(function(item){
        totalArray.push(item);
      })

      //소계깂 빼기
      for (let i = 0; i < totalArray.length; i++) {
        if (totalArray[i].nm === '소계') {
          totalArray.splice(i, 1);
        }
      }

      //콤마 제거 후 합계
      let totalHD = 0;
      let totalDB = 0;
      let totalKB = 0;
      let totalHK = 0;
      let totalHW = 0;
      let totalCR = 0;
      let totalHN = 0;
      let totalAXA = 0;

      for (let i = 0; i < totalArray[0].value.length; i++) {
        if (i%3 == 2) {
          if(totalArray[0].value[i] != 0) {
            totalHD += Number(totalArray[0].value[i].split(',').join(""));
          } else {
            totalHD += 0
          }
          if(totalArray[1].value[i] != 0) {
            totalDB += Number(totalArray[1].value[i].split(',').join(""));
          } else {
            totalDB += 0
          }
          if(totalArray[2].value[i] != 0) {
            totalKB += Number(totalArray[2].value[i].split(',').join(""));
          } else {
            totalKB += 0
          }
          if(totalArray[3].value[i] != 0) {
            totalHK += Number(totalArray[3].value[i].split(',').join(""));
          } else {
            totalHK += 0
          }
          if(totalArray[4].value[i] != 0) {
            totalHW += Number(totalArray[4].value[i].split(',').join(""));
          } else {
            totalHW += 0
          }
          if(totalArray[5].value[i] != 0) {
            totalCR += Number(totalArray[5].value[i].split(',').join(""));
          } else {
            totalCR += 0
          }
          if(totalArray[6].value[i] != 0) {
            totalHN += Number(totalArray[6].value[i].split(',').join(""));
          } else {
            totalHN += 0
          }
          if(totalArray[7].value[i] != 0) {
            totalAXA += Number(totalArray[7].value[i].split(',').join(""));
          } else {
            totalAXA += 0
          }
        }
      }
      this.datasets[0].data[0] = totalHD;
      this.datasets[0].data[1] = totalDB;
      this.datasets[0].data[2] = totalKB;
      this.datasets[0].data[3] = totalHK;
      this.datasets[0].data[4] = totalHW;
      this.datasets[0].data[5] = totalCR;
      this.datasets[0].data[6] = totalHN;
      this.datasets[0].data[7] = totalAXA;
      this.$data._chart.update();
    },
    data:function () {
      //Array name label 배열 복사
      let totalNmArray = [];
      this.insuredNm.forEach(function(item){
        totalNmArray.push(item);
      })

      for (let i = 0; i < totalNmArray.length; i++) {
        if (totalNmArray[i] === '소계') {
          totalNmArray.splice(i, 1);
        }
      }

      this.renderChart(
          {
            labels: totalNmArray,
            datasets: this.datasets,
          },
          {
            responsive: true, maintainAspectRatio: false,
            layout: {
              padding: 130
            },
            legend: {
              display: true,
              position: 'right'
            },
            plugins: {
              outlabels: {
                text: '%l (%p.1)',
                color: 'white',
                stretch: 45,
                font: {
                  resizable: false,
                  minSize: 12,
                  maxSize: 18
                }
              },
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) { //그래프 콤마
                  var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ '원';
                }
              },
            },
          }
      );
    }
  }
}
</script>
