var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"white--text px-8",staticStyle:{"margin":"0 3px 0 3px"},attrs:{"color":"grey","disabled":_vm.data.length <= 0},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"30vh","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.getHeader(_vm.headers),"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},on:{"click:row":_vm.clickContents},scopedSlots:_vm._u([{key:"item.prNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 20, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.minPrem",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.prodType",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.showProdTypeNm(value)))])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getKST(value)))])]}},{key:"item.appFromDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.sex",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "0", "공용")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "남자")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "여자")))])]}},{key:"item.appToDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.newDispEndDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }