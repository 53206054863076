<template>
  <search-page-layout title="설계지원">
    <template v-slot:searchBar>
      <design-support-search-bar
          @getSearch = "getSearch"
          :consultResultList="CONSULT_RESULT_TYPE"
      />
    </template>

    <template v-slot:table>
      <design-support-table
          :data="data"
          :loading="loading"
          :search="search"
          :conditionHist="conditionHist"
          :isPrvGroupTM="isPrvGroupTM"
          @updateSetting="updateSetting"
          :consultResultList="CONSULT_RESULT_TYPE"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import DesignSupportSearchBar from "./components/DesignSupport/DesignSupportSearchBar";
import DesignSupportTable from "./components/DesignSupport/DesignSupportTable";
import {axiosInstance} from "@/util/AxiosModule";
import func from "@/util/func";
import _ from "lodash";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  components: {DesignSupportTable, DesignSupportSearchBar, SearchPageLayout},
  mixins: [CodeMixin],
  data: () => ({
    loading: false,
    data: [],
    paramsData: [],
    search: 0,
    conditionHist: {},      // 엑셀 다운로드 이력 저장을 위함
    CONSULT_RESULT_TYPE: [] // 상담결과 리스트
  }),
  async created() {
    this.CONSULT_RESULT_TYPE = await this.getSelectList("CONSULT_RESULT_TYPE");
  },
  computed: {
    isPrvGroupTM() {
      return func.checkUserPrvGroup(["TM", "TM_ADM"]) === "Y";
    }
  },
  methods: {
    async getSearch(params, search) {
      this.paramsData = params;
      this.search = search;
      this.loading = true;
      try {
        const {data} = await axiosInstance.get('/admin/operation/designSupList', {params});
        this.conditionHist = params;
        this.data = data;
        func.contentsDataFormatter(this.data);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    updateSetting() {
      if (_.isEmpty(this.paramsData)) return;
      this.getSearch(this.paramsData);
    }
  }
}
</script>