<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <custom-layout title="상품추천테이블 데이터 등록">
      <v-card width="80vw" height="100%" max-width="900" max-height="600" class="pa-3">
        <v-sheet class="px-3 py-2 background">
          <v-row class="px-5">
            <v-col class="d-flex justify-center align-center col-6">
              <v-col cols="5">직원명</v-col>
              <v-text-field
                  v-model="saveData.customerNm"
                  background-color="white"
                  hide-details outlined dense
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-6">
              <v-col cols="5">전화번호</v-col>
              <v-text-field
                  v-model="saveData.telNum"
                  background-color="white"
                  hide-details outlined dense
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-6">
              <v-col cols="5">관리자 역할</v-col>
              <v-text-field
                  v-model="saveData.adminRole"
                  background-color="white"
                  outlined dense hide-details
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-6">
              <v-col cols="5">콜시스템상담원 ID</v-col>
              <v-text-field
                  v-model="saveData.csId"
                  background-color="white"
                  outlined dense hide-details
              />
            </v-col>
          </v-row>
        </v-sheet>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="save"
          >
            등록
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </custom-layout>
  </v-overlay>
</template>
<script>
import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";
import CustomLayout from "@/layouts/CustomLayout.vue";

export default {
  components: {CustomLayout},
  props: ["isShow"],
  computed: {
    regUser(){
      return store.getters["login/userData"];
    },
  },
  data() {
    return {
      saveData: {
        customerNm: '',
        memberId: '',
        telNum: '',
        applyStartDt: '',
        applyEndDt: '',
        adminRole: '',
        csId: '',
        regId: '',
        regNm: '',
        regDate: ''
      },
    }
  },
  methods: {
    async save() {
      const data = this.setRegUser(this.saveData);

      if (confirm(data.customerNm + "님을 등록하시겠습니까?")) {
        try {
          await axiosInstance.post('/admin/member/setExcMember', data);

          alert('완료되었습니다.');
          this.refresh();
        } catch (e) {
          console.log(e);
        }
      }
    },
    setRegUser(data){
      data.regId = this.regUser.memberId;
      data.regNm = this.regUser.memberNm;
      return data;
    },
    refresh(){
      this.close();
      this.$emit('refresh');
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>


<style scoped>
.background {
  background-color: rgba(0,0,0,.05);
  border: 1px solid lightgray;
}
</style>