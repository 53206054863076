<template>
  <div class="w100h100">
    <div @dblclick="setEditMode" class="w100h100 cell">
      <v-text-field
          v-if="isEdit" v-model="text"
          @keydown.enter.once="getMember(text)"
          hide-details dense autofocus
      />
      <span class="align-center" v-else>
        {{ value }}
      </span>
    </div>
      <modal :is-show="isShow">
          <v-card class="col-12 pa-4 overflow-y-auto pb-10 px-5 cards">
            <v-card-title>
              회원 조회 결과({{ data.length }}건)
              <v-spacer/>
              <v-icon @click="close">close</v-icon>
            </v-card-title>

            <div>

              <v-row class="headerItem">
                <v-col cols="3">이름</v-col>
                <v-col cols="3">생년월일</v-col>
                <v-col cols="6">핸드폰번호</v-col>
              </v-row>

              <v-row
                  @dblclick="getColumnData(item)"
                  v-for="(item, idx) in data"
                  class="overItem"
                  :key="idx"
              >
                <v-col cols="3">{{ item.memberNm }}</v-col>
                <v-col cols="3">{{ replaceBirthday(item.birthday) }}</v-col>
                <v-col cols="6">{{ replaceTelNum(item.telNum) }}</v-col>
              </v-row>

            </div>
<!--            <simple-table-->
<!--                :data="data"-->
<!--                @getColumnData="getColumnData"-->
<!--                @closeSimpleInput="closeSimpleModal"-->
<!--            />-->
          </v-card>
      </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import Modal from "@/components/Modal/Modal";
import { axiosInstance } from "@/util/AxiosModule";

export default {
  props: ["target", "partnerList", "value", "id"],
  components: {Modal},
  data: () => ({
    text: "",
    backup: "",
    isEdit: false,
    isShow: false,
    data: [],
  }),
  methods: {
    setEditMode() {
      this.target ? this.backup = _.cloneDeep(this.text) : alert("먼저 정산타겟을 설정해주세요.");
      this.isEdit = !!this.target;
    },
    async getMember(text) {
      if(_.isEmpty(text)) return;

        const { data } = await axiosInstance.get('/admin/operation/settle-member', {
          params: { memberNm : text }
        });

      if(_.isEmpty(data)) {
        alert("해당하는 이름의 회원이 없습니다.");
        this.isShow = false;
        this.isEdit = false;
      } else {
        this.data = data;
        this.isShow = true;
      }
    },
    close() {
        this.isShow = false;
        this.isEdit = false;
        this.text = "";
    },
    getColumnData(item) {
      let partnerCd;

      if(this.target === 'ONECLICK') {
        partnerCd = this.partnerList.includes(item.memberNm) ? item.memberNm : 'INCAR';
      } else {
        partnerCd = this.target;
      }

      this.$emit('getColumnData', this.id, { ...item, partnerCd: partnerCd });
      this.isEdit = this.isShow = false;
    },

    replaceTelNum(tel) {
      const t = tel.toString();
      if(t.length !== 10 && t.length !== 11) return t;
      return t.length === 11
          ? `${t.slice(0,3)}-${t.slice(3,7)}-${t.slice(7,11)}`
          : `${t.slice(0,3)}-${t.slice(3,6)}-${t.slice(6,10)}`
    },
    replaceBirthday(birth) {
      const b = birth.toString();
      return b.length === 8
          ? `${b.slice(0,4)}/${b.slice(4,6)}/${b.slice(6,8)}`
          : b
    }
  }
}
</script>

<style>
.cell-center .v-text-field input {
  text-align: center;
  width: 6vw!important;
}
div.text-right.ml-3 .v-text-field input {
  text-align: right;
}
.cards {
  max-height: 90vh; min-width: 50vw;
}
.headerItem{
  background-color: #f2f2f2;
  font-weight: bold;
}
.overItem {
  cursor: pointer;
}
.overItem:hover {
  background-color: #FFF9F9;
  transition: 0.2s;
}
</style>