<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="보험사코드">
          <v-select
              @change="getTableId"
              @click="selectCd"
              v-model="searchInsurer"
              :items="insurerCode"
              background-color="white"
              item-text="value"
              item-value="cd"
              class="subBar"
              hide-details outlined dense
            />
        </field-input>
        <field-input title="상품추천테이블ID">
          <v-select
            v-model="productTableId"
            :items="productId"
            label="선택"
            background-color="white"
            item-text="text"
            item-value="prTbId"
            class="subBar"
            hide-details outlined dense
            />
        </field-input>
        <field-input title="상품코드">
          <v-text-field
            v-model="productCode"
            outlined dense hide-details
            background-color="white"
            menu-props="auto"
            class="subBar"
            />
        </field-input>
      </search-area>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="4">
            <v-row class="align-center">적용일자
              <v-checkbox
              v-model="checkbox"
              class="marginLf"
          ></v-checkbox>
            </v-row></v-col>
          <v-col class="paddCol">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
                class="subBar"
            />
          </v-col>
          <slot />
        </v-col>
        <field-input title="사용여부">
          <v-select
              v-model="useYn"
              :items="useYN"
              background-color="white"
              item-value="cvalue"
              item-text="cname"
              class="subBar"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="작업합계">
          <v-select
              v-model="totalData"
              :items="totalYn"
              background-color="white"
              item-value="dvalue"
              item-text="dname"
              class="subBar"
              hide-details outlined dense
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import FieldInput from "../../../../components/Form/FieldInput";
import SearchBtn from "../../../../components/Search/SearchBtn";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
export default {
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout, DateInput},
  mixins: [DateMixin],
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-15);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data: () => ({
    searchInsurer:"",
    productTableId:"",
    productCode: "",
    startDate: "",
    endDate: "",
    useYn:"Y",
    checkbox:false,
    totalData: "Y",
    insurerCode: [
      {value: '전체', cd: ''}
    ],
    productId: [],
    useYN: [
      {cname: 'Y', cvalue: 'Y'},
      {cname: 'N', cvalue: 'N'}
    ],
    totalYn: [
      {dname: 'Y', dvalue: 'Y'},
      {dname: 'N', dvalue: 'N'}
    ],
  }),
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    async getTableId(event) {
      this.productTableId = "";
      this.productId = [];
      const params = {
        insCd: event
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/getPrInsTbMngTb", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prTbId + ' (' + data[i].prTbDes + ')';
          objJson.prTbId = data[i].prTbId;
          this.productId.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    getSearch() {
      let startApStDate = this.startDate;
      let endApStDate = this.endDate;
      if (this.checkbox) {
        startApStDate = "";
        endApStDate = "";
      }
      if (_.isEmpty(this.productCode)) {
        this.productCode = null;
      } else {
        var codeReg =  /^[A-Z0-9]+$/;
        if (!codeReg.test(this.productCode)) {
          alert("상품코드는 대문자, 숫자만 입력해주세요.");
          return;
        }
      }
      const params = {
        startApStDate: startApStDate,
        endApStDate: endApStDate,
        insCd: this.searchInsurer,
        prTbId: this.productTableId,
        prCd: this.productCode,
        useYn: this.useYn,
        wkSumYn: this.totalData
      }
      this.$emit('getSearch', params);
    }
  }
}
</script>

<style scoped>
.paddCol {
  padding: 0;
}
.subBar {
  max-width: 270px;
}
.marginLf {
  margin-left: 7px;
}
</style>