<template>
  <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
    <v-col cols="5">{{ title }}</v-col>
    <slot />
  </v-col>
</template>

<script>
export default {
  props: ['title']
}
</script>