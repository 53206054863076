<template>
  <v-col cols="12" class="py-0">
    <div class="d-flex justify-space-between align-center pa-1">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <v-btn
          class="blue white--text"
          @click="isShow = true"
      >신규 등록</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="headers"
          @click:row="setRow"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.applyStartDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.applyEndDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>

    <exc-member-modal
        :is-show="isShow"
        @close="isShow = false"
        @refresh="refresh"
    />
  </v-col>
</template>

<script>
import ExcMemberModal from "@/pages/User/components/ExcMember/ExcMemberModal";
import DateMixin from "@/mixin/DateMixin";
export default {
  components: {ExcMemberModal},
  props: ["data", "loading"],
  mixins: [DateMixin],
  data() {
    return {
      headers: [
        // {text: "선택", value: "checkYn", align: "center", sortable: false, width: "4%"},
        {text: "사용자명", value: "customerNm", align: "center", sortable: false, width: "12%"},
        {text: "관리자역할", value: "adminRole", align: "center", sortable: false, width: "12%"},
        {text: "콜시스템상담원ID", value: "csId", align: "center", sortable: false, width: "16%"},
        {text: "적용시작일자", value: "applyStartDt", align: "center", sortable: false, width: "15%"},
        {text: "적용종료일자", value: "applyEndDt", align: "center", sortable: false, width: "15%"},
        {text: "등록자명", value: "regNm", align: "center", sortable: false, width: "10%"},
        {text: "등록일자", value: "regDate", align: "center", sortable: false, width: "20%"},
      ],
      isShow: false,
    }
  },
  methods: {
    setRow(row) {
      this.$emit('setRow', row);
    },

    refresh(){
      const params = {
        customerNm: '',
        adminRole: '',
        activeYn: ''
      }
      this.$emit('getSearch', params);
    }
  }
}
</script>