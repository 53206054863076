<template>
  <search-page-layout title="상품비교 담보관리">
    <template v-slot:tabBar>
      <v-tabs v-model="tabs">
        <v-tab>현황</v-tab>
        <v-tab>담보적용</v-tab>
      </v-tabs>
    </template>

    <template v-slot:searchBar>
      <product-compare-management-search-bar
        :tabs="tabs"
        :statusRowData="statusRowData"
        @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <product-compare-management-table
        :tabs="tabs"
        :cvrData="cvrData"
        :prData="prData"
        :loading="loading"
        @statusRow="statusRow"
        @cvrRow="cvrRow"
        @dbClickRow="dbClickRow"
      />
    </template>


    <template v-slot:inflowHist>
      <product-compare-management-save
        v-if="tabs === 1"
        :cvrRowData="cvrRowData"
        @updateData="updateData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductCompareManagementSearchBar
  from "@/pages/Product/components/ProductCompareManagement/ProductCompareManagementSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import ProductCompareManagementTable
  from "@/pages/Product/components/ProductCompareManagement/ProductCompareManagementTable";
import ProductCompareManagementSave
  from "@/pages/Product/components/ProductCompareManagement/ProductCompareManagementSave";
export default {
  components: {
    ProductCompareManagementSave,
    ProductCompareManagementTable, ProductCompareManagementSearchBar, SearchPageLayout},
  data() {
    return {
      tabs: [0, 1],
      cvrData: [],
      prData: [],
      loading: false,
      paramsData: [],
      statusRowData: [],
      cvrRowData: [],
    }
  },
  methods: {
    dbClickRow() {
      this.tabs = 1;
      console.log(this.statusRowData);
      this.getSearch(this.statusRowData);
    },
    async getSearch(params) {
      this.loading = true;
      this.paramsData = params;

      if (this.tabs === 0) {
        try {
          const { data } = await axiosInstance.get('/admin/prdata/prProdCompCvrList', {params});
          this.cvrData = data;
          for (let i = 0; i < this.cvrData.length; i++) {
            this.cvrData[i].createDate = this.cvrData[i].createDate.replace('T', ' ').substr(0, 19);
          }
        } catch (e) {
          console.log(e);
        }
      } else if (this.tabs === 1) {
        console.log("tabs === 1");
        try {
          const { data } = await axiosInstance.get('/admin/prdata/prProdCompPrList', {params});
          let idx = 0;
          for (const item of data) { item["index"] = idx++; }
          this.prData = data;
          for (let i = 0; i < this.prData.length; i++) {
            this.prData[i].createDate = this.prData[i].createDate.replace('T', ' ').substr(0, 19);
          }
          console.log(data);
        } catch (e) {
          console.log(e);
        }
      }
      this.loading = false;
    },
    updateData() {
      this.getSearch(this.paramsData);
    },
    statusRow(row) {
      this.statusRowData = row;
    },
    cvrRow(row) {
      this.cvrRowData = row;
    }
  }
}
</script>

<style scoped>
.v-tabs {
  margin:0 0 5px 13px;
}
.v-tab {
  font-weight: bold;
}
</style>