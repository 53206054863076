var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-2 py-5",attrs:{"cols":"12"}},[_c('div',{staticClass:"table-header mb-2"},[_c('span',{staticClass:"text-h6"},[_vm._v(" 목록 (총 "+_vm._s(_vm.data.length)+"개 | 정상리워드합 "+_vm._s(_vm.inputComma(_vm.nrRewardSum))+"원 | 환수리워드합 "+_vm._s(_vm.inputComma(_vm.rrRewardSum))+"원) ")]),_c('v-btn',{staticClass:"white--text px-8",staticStyle:{"margin":"0 3px 0 3px"},attrs:{"color":"grey","disabled":_vm.data.length < 1},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.data,"item-class":_vm.getRed,"item-key":"index","show-select":"","loading":_vm.loading,"loading-text":"조회중입니다. 잠시만 기다려주세요","items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.getHeader(_vm.headers),"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},scopedSlots:_vm._u([{key:"item.recipientTelNo",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.getTelNum(value)))])]}},{key:"item.customerTelNum",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.getTelNum(value)))])]}},{key:"item.contractPremAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.rewardAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.contractDt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.expiryDt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.payDueDt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}}]),model:{value:(_vm.selectData),callback:function ($$v) {_vm.selectData=$$v},expression:"selectData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }