<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
  <div class="close-button-layout">
    <span class="close-button" @click="closePopup">x</span>
  </div>
   <custom-layout title="상품명조회 팝업">
     <v-card width="80vw" height="50vh" max-width="800" max-height="450">
       <v-row class="mx-1">
         <v-col class="align-center d-flex col-6">
           <v-col cols="3">보험사</v-col>
           <v-col>
             <v-select
                 v-model="rowData.insCd"
                 :items="insList"
                 item-text="insNm"
                 item-value="insCd"
                 hide-details outlined dense
                 background-color="white"
             />
           </v-col>
         </v-col>

         <v-col class="align-center d-flex col-6">
           <v-col cols="3">상품종류</v-col>
           <v-col>
             <v-select
                 v-model="rowData.prProdLineCd"
                 :items="prProdLineList"
                 item-text="prProdLineNm"
                 item-value="prProdLineCd"
                 hide-details outlined dense
                 background-color="white"
             />
           </v-col>
         </v-col>
       </v-row>

       <v-row class="mx-1">
         <v-col class="align-center d-flex col-9">
           <v-col cols="2">상품명</v-col>
           <v-col>
             <v-text-field
                 v-model="rowData.prNm"
                 hide-details outlined dense
                 background-color="white"
             />
           </v-col>
         </v-col>

         <v-col class="align-center d-flex col-3">
           <v-col class="text-right">
             <v-btn @click="getSearchPrNm">조회</v-btn>
           </v-col>
         </v-col>
       </v-row>

       <v-flex>
         <v-data-table
             height="200"
             :headers="getHeader(headers)"
             :items="data"
             :items-per-page="-1"
             @click:row="getRowData"
             fixed-header
         />
       </v-flex>
     </v-card>
   </custom-layout>
  </v-overlay>
</template>

<script>
import _ from "lodash";
import InsAiChatManagement from "@/util/InsAiChatManagement";
import TableMixin from "@/mixin/TableMixin";
import {INS_AI_CHAT_MANAGE_PRODUCT_SEARCH_TABLE} from "@/util/TableHeaders";
import CustomLayout from "@/layouts/CustomLayout";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {CustomLayout},
  props: ["isShow", "saveData"],
  mixins: [TableMixin],
  created() {
    this.insList = InsAiChatManagement.initInsList();
  },
  watch: {
    isShow(value) {
      if (value) {
        this.rowData = _.cloneDeep(this.saveData);
        this.getSearchPrNm();
      }
    },
    async saveData(value) {
      if (value) {
        this.prProdLineList = await InsAiChatManagement.prProdLineList("L", this.saveData.insCd, "", "");
      }
    }
  },
  data() {
    return {
      insList: [],
      rowData: {},
      prProdLineList: [
        { prProdLineNm: "선택", prProdLineCd: "" },
      ],
      headers: INS_AI_CHAT_MANAGE_PRODUCT_SEARCH_TABLE,
      data: [],
    }
  },
  methods: {
    closePopup() {
      this.rowData = {};
      this.data = [];
      this.$emit("close");
    },
    async getSearchPrNm() {
      let { data } = await axiosInstance.get("/private/ai/aiTermPrCdList", {
        params: {
          cdType: "P",
          insCd: this.rowData.insCd,
          iLineCd: this.rowData.prProdLineCd,
          iCdNm: this.rowData.prNm
        }
      });
      data = data.map(item => ({
        insCd: this.rowData.insCd,
        prProdLineCd: this.rowData.prProdLineCd,
        prCd: item.vcd,
        prNm: item.vcdNm,
        stDt: item.vstDt
      }));
      this.data = data;
    },
    getRowData(data) {
      this.$emit("productNm", data);
      this.closePopup();
    }
  }
}
</script>

<style lang="scss" scoped>
.close-button-layout {
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 15px;

  .close-button {
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
  }
}

.horizontal-icon {
  cursor: pointer;
}
.horizontal-icon:hover {
  border-radius: 5px;
  background-color: #c0c0c0;
}
</style>