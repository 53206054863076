<template>
  <v-data-table
      @click:row="goData"
      class="my-2" v-model="selected"
      item-key="cd"
      :items="items"
      :options="option"
      :show-select="!isMaster"
      :headers="getHeader(headers)"
      :height="isMaster ? `60vh` : `35vh`"
      :item-class="() => (isCreate ? '' : 'pointer')"
      fixed-header disable-sort
      calculate-widths hide-default-footer
  >

    <template v-slot:item.createDate="{ value }">
      {{ getDataByValue(value) }}
    </template>

    <template v-slot:item.updateDate="{ value }">
      {{ getDataByValue(value) }}
    </template>

    <template v-slot:item.value="{ value }" v-if="!isKey">
      {{ sliceLongString(value) }}
    </template>

  </v-data-table>
</template>

<script>
import _ from "lodash";
import TableMixin from "@/mixin/TableMixin";

export default {
  mixins: [TableMixin],
  props: {
    items: Array,
    headers: Array,
    isMaster: Boolean,
    isCreate: Boolean,
    parentsSelected: Array,
    isKey: Boolean,
  },
  data: () => ({
    isSelect: false,
    selected: [],
    option : {
      sortBy: ['no'],
      itemsPerPage: -1,
    },
  }),
  watch: {
    selected: function(value) {
      if(this.isSelect) this.isSelect = false;
      else this.$emit('select', value);
    },
    parentsSelected: function(value) {
      if(value.length < 1) {
        this.isSelect = true;
        this.selected = [];
      }
    }
  },
  methods: {
    goData(row) {
      if(this.isCreate) return;
      if(this.isMaster) {
        this.$store.dispatch('code/setCdMasterId', {
          cdMasterId : row["cdMasterId"],
          type: row["type"]
        }).then(() => {
          this.$router.push({name: "MasterCodeInfo"});
        })
      } else {
        this.$store.dispatch('code/setCd', row.cd).then(() => {
          this.$router.push({name: "DetailCodeInfo"});
        })
      }
    },
    getDataByValue(value) {
      if(_.isEmpty(value)) return '';
      const date = new Date(value);
      return date.toLocaleString();
    },
    sliceLongString(str) {
      return str.length > 80 ? str.slice(0,80) + "..." : str;
    }
  },
}
</script>

<style>
.pointer {cursor: pointer;}
</style>