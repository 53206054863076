<template>
  <search-page-layout title="상품 담보정보 관리">
    <template v-slot:searchBar>
      <product-management-search-bar
          @getSearch = "getSearch"
        />
    </template>

    <template v-slot:table>
      <product-management-table
          :data="data"
          :loading="loading"
          @update = "update"
        />
    </template>
  </search-page-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "../../layouts/SearchPageLayout";
import ProductManagementSearchBar from "./components/ProductManagementSearchBar";
import ProductManagementTable from "./components/ProductManagementTable";
export default {
  components: {ProductManagementTable, ProductManagementSearchBar, SearchPageLayout},
  data:() => ({
    loading: false,
    data: [],
    updateDate: []
  }),
  methods: {
    async getSearch(params) {
      this.loading = true;
      this.updateDate = params;
      try {
        const { data } = await axiosInstance.get('/admin/prdata/prProdCvrList', { params });
        this.data = data;
        //인덱스 값 추가
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].index = i;
          this.data[i].updateDate = this.data[i].updateDate.replace('T', ' ');
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    update() {
      this.getSearch(this.updateDate);
    }
  }
}
</script>