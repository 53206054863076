<template>
  <v-col cols="12" class="py-0">
    <div style="width: 100%; text-align: right;">
      <outlined-btn class="mt-3 mb-1" :disabled="data.length < 1" @click="$emit('toExcel')">엑셀다운로드</outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 xl12>
      <v-data-table
        class="summary-table"
        height="70vh"
        :headers="getHeader(header)"
        :items="data"
        :loading="loading"
        :items-per-page="-1"
        fixed-header calculate-widths
      >
        <!-- 원천 -->
        <template v-slot:item.srcCnt="{ value }">
          {{ inputComma(value) }}
        </template>
        <template v-slot:item.srcPrem="{ value }">
          {{ inputComma(value) }}
        </template>
        <!-- 원천 -->

        <template v-slot:item.refine="{ item }">
          <div>
            <v-btn v-if="item.stateCd === 'N'" outlined @click="isRefine(item)" width="30" height="30">정제</v-btn>
          </div>
        </template>

        <!-- 정산완료 -->
        <template v-slot:item.completeCnt="{ item, value }">
          <span class="table-data" v-if="value >= 1" :style="checkDataStyle(value)" @click="openDetail('complete', value, item)">
            {{ inputComma(value) }}
          </span>
          <span class="table-data" v-else :style="checkDataStyle(value)">
            {{ inputComma(value) }}
          </span>
        </template>
        <template v-slot:item.completePrem="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('complete', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <template v-slot:item.completeRewardAmt="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('complete', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <!-- 정산완료 -->

        <!-- 환수대상 -->
        <template v-slot:item.refundCnt="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('refund', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <template v-slot:item.refundPrem="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('refund', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <template v-slot:item.refundRewardAmt="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('refund', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <!-- 환수대상 -->

        <!-- 신규생성 -->
        <template v-slot:item.newCnt="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('new', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <template v-slot:item.newPrem="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('new', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <template v-slot:item.newRewardAmt="{ item, value }">
          <span class="table-data" :style="checkDataStyle(value)" @click="openDetail('new', value, item)">
            {{ inputComma(value) }}
          </span>
        </template>
        <!-- 신규생성 -->
      </v-data-table>
    </v-flex>

    <settle-ins-summary-detail
      :is-show="isShowDetail"
      :row-data="rowData"
      @close="isShowDetail = false"
    />

    <settle-in-summary-refine
      :is-show="isShowRefine"
      :row-data="rowData"
      @close="closeRefine"
    />
  </v-col>
</template>
<script>
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import TableMixin from "@/mixin/TableMixin";
import {SETTLEMENT_INS_SUMMARY_TABLE} from "@/util/TableHeaders";
import SettleInsSummaryDetail from "@/pages/Settlement/components/SettlementInsSummary/Popup/SettleInsSummaryDetail";
import SettleInSummaryRefine from "@/pages/Settlement/components/SettlementInsSummary/Popup/SettleInSummaryRefine.vue";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin],
  components: {SettleInSummaryRefine, SettleInsSummaryDetail, OutlinedBtn},
  data() {
    return {
      header: SETTLEMENT_INS_SUMMARY_TABLE,
      rowData: {},
      isShowDetail: false,
      isShowRefine: false
    }
  },
  methods: {
    openDetail(type, value, item) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[this.data.length - 1] === item) {
          return false;
        } else {
          if (value === 0) {
            return false;
          } else {
            this.rowData = item;
            if (type === "complete") {
              this.rowData.paymentStatus = "정산완료";
            } else if (type === "refund") {
              this.rowData.paymentStatus = "환수대상";
            } else if (type === "new") {
              this.rowData.paymentStatus = "신규생성";
            } else {
              return false;
            }
            this.isShowDetail = true;
          }
        }
      }
    },
    checkDataStyle(item) {
      if (item === 0) {
        return {cursor: "default"};
      } else {
        return {
          cursor: "pointer"
        };
      }
    },
    isRefine(item) {
      this.isShowRefine = true;
      this.rowData = item;
    },
    closeRefine() {
      this.isShowRefine = false;
      this.$emit("updateData");
    }
  }
}
</script>

<style lang="scss" scoped>
.table-data {
  cursor: pointer;
}

.v-data-table::v-deep .v-data-table__empty-wrapper {
  background-color: #fff !important;
}

.v-data-table::v-deep tr:last-child {
  background-color: #c0c0c0;
  td {
    span {
      cursor: default !important;
    }
  }
}
</style>