<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="기본조건">
        <v-col :class="CLASS1">
          <v-col cols="3">사용일자</v-col>
          <v-col cols="9">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
          <div class="ml-auto pb-1 d-flex align-end">
          <v-btn
              elevation="2"
              color="grey"
              class="white--text px-10"
              @click="$emit('toExcel')"
            >
            엑셀저장
          </v-btn>
        </div>
      </search-area>


    </template>
    <template v-slot:button>
      <search-btn @click="getCalcList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [SearchBarMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data: () => ({
    startDate: "",
    endDate: ""
  }),
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    getCalcList() {
      const params = {
        "startDate": this.startDate.replaceAll('-', ''),
        "endDate": this.endDate.replaceAll('-', '')
      }

      this.$emit('search', params);
    },
  }
}
</script>