<template>
  <search-page-layout title="가망고객 DB 발굴현황">
    <template v-slot:tabBar>
      <v-tabs
        center-active
        >
        <v-tab @click="reItem">사용 집계현황</v-tab>
        <v-tab @click="reItem">일자별 사용현황</v-tab>
        <v-tab @click="reItem">사용토큰 발급관리</v-tab>
        <v-tab-item>
          <summary-search :key="tagkey"/>
        </v-tab-item>
        <v-tab-item>
          <status-search :key="tagkey"/>
        </v-tab-item>
        <v-tab-item>
          <token-search :key="tagkey"/>
        </v-tab-item>
      </v-tabs>
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import StatusSearch from "./components/Status/StatusSearch";
import SummarySearch from "./components/Summary/SummarySearch";
import TokenSearch from "./components/Token/TokenSearch";

export default {
  components: {
    TokenSearch,
    SummarySearch,
    StatusSearch,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    tagkey:0,
  }),
  methods: {
    reItem(){
      this.tagkey++;
    }
  }
}
</script>