<template>
  <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
    <back-btn />
    <v-card-title>코드 상세보기</v-card-title>
    <v-divider/>

    <v-form v-model="valid">

      <v-row>
        <input-wrapper
          name="코드" :cols="6"
          :modify="false" :value="cd.cd"
        />
        <input-wrapper
            name="마스터 코드" :cols="6"
            :modify="false" :value="cd.cdMasterId"
        />
      </v-row>

      <v-row>
        <input-wrapper
            name="생성일자" :cols="6"
            :modify="false" :value="cd.createDate"
        />
        <input-wrapper
            name="수정일자" :cols="6"
            :modify="false" :value="cd.updateDate"
        />
      </v-row>

      <v-row>
        <input-wrapper
            v-if="cd.type === 'KEY'"
            name="순번" :cols="6"
            :modify="modify" :value="cd.no"
            :rules="[...rules.num]"
            @update="cd.no = $event"
        />
        <v-col cols="6" class="d-flex align-center pr-5">
          <span :class="CREATE_FIELD_CLASS">사용 여부</span>
          <v-radio-group
              v-model="cd.use"
              v-if="modify"
              row
          >
            <v-radio
                label="사용"
                :value="true"
            ></v-radio>
            <v-radio
                label="미사용"
                :value="false"
            ></v-radio>
          </v-radio-group>
          <span v-else>{{ cd.use ? "사용" : "미사용" }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" :class="CREATE_FIELD_CLASS">
          <span class="col-3">값</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
              solo
              :readonly="!modify"
              :rules="cd.type === 'JSON' ? [jsonVali, ...rules.nn] : rules.nn"
              :rows="cd.type === 'JSON' ? 15 : 3"
              v-model="cd.value"
          />
        </v-col>
      </v-row>

    </v-form>

    <code-modify-btn
        :modify="modify"
        :valid="valid" :detail-codes="[]"
        @save="save" @delete="deleteDC" @set="setModifying"
    />

  </v-card>
</template>

<script>
import _ from "lodash";
import router from "@/router";
import { rules } from "@/util/constant";
import BackBtn from "@/pages/Manage/Code/components/common/BackBtn";
import CodeModifyBtn from "@/pages/Manage/Code/components/common/CodeModifyBtn";
import InputWrapper from "@/pages/Manage/Code/components/common/InputWrapper";
import { axiosInstance } from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import {mapGetters} from "vuex";

export default {
  mixins: [CodeMixin],
  components: {InputWrapper, BackBtn, CodeModifyBtn},
  async created() {
    const param = {
      cdMasterId : this.cdMasterId,
      cd : this.storeCd,
      type : this.type
    };

    try{
      const { data } = await axiosInstance.get('/admin/code/detail', {params : param});

      this.cd = data;
      this.cd.type = this.type;

      if(this.jsonVali(this.cd.value)) {
        const json = JSON.parse(this.cd.value);
        this.cd.value = JSON.stringify(json, undefined, 2);
        this.cd.type = this.type;
        this.backup = _.cloneDeep(data);
      }
    } catch (e) {
      console.log(e);
    }
  },
  data: () => ({
    valid: false,
    isMaster: false,
    modify: false,
    cd: {},
    backup: {},
    rules: rules,
  }),
  computed: {
    ...mapGetters("code", {
      cdMasterId : "cdMasterId",
      storeCd : "cd",
      type :"type"
    })
  },
  methods: {
    getValue(value, name) {
      if(value) return value;
      return this.modify ? this.backup[name] : this.cd[name]
    },
    setModifying() {
      (this.modify)
          ? this.cd = _.cloneDeep(this.backup)
          : this.backup = _.cloneDeep(this.cd);
      this.modify = !this.modify;
    },
    async save() {
      try {
        await axiosInstance.put('/admin/code/detail', this.cd);
        console.log(this.cd);
        // [프로그램 권한 관리] 변경 시 목록 재설정 2) 개별 수정
        if (this.cdMasterId === "PROG_PRV_USER") {
          this.$store.dispatch("login/setPrivilegeGroup");
        }
      } catch (e) {
        console.log(e);
        this.cd = _.cloneDeep(this.backup);
      }
      this.modify = false;
    },
    async deleteDC() {
      if (confirm("코드를 삭제하시겠습니까?")) {
        try {
          await axiosInstance.delete('/admin/code/detail', {
            data: this.cd
          })
          // [프로그램 권한 관리] 변경 시 목록 재설정 3) 개별 삭제
          if (this.cdMasterId === "PROG_PRV_USER") {
            this.$store.dispatch("login/setPrivilegeGroup");
          }
          alert("코드가 삭제 되었습니다!");
          router.back();
        } catch (e) {
          console.log(e);
        }
      }
    },
    jsonVali(value) {
      try{
        JSON.parse(value);
        return true;
      } catch {
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>