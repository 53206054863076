import { axiosInstance } from "@/util/AxiosModule"
import store from "@/store";
import CodeMixin from "@/mixin/CodeMixin";

const getDefaultState = () => {
    return {
        accessToken: "",
        refreshToken: "",
        memberId: "",
        memberRole: "",
        userData: {},
        // 메뉴 권한 프로세스
        privilegeGroup: [],         // 권한 그룹 목록 (ex. 관리자-ADM, 개발-DEV)
        personalMenuList: [],       // 개인별 사용 가능 목록
        personalMenuNameList: [],   // 페이지 이동 시 확인을 위한 메뉴명 목록 (router.beforeEach)
        prePage: "",                // 이전 페이지 저장용 (router)
        menuUpdateCnt: 0,           // 메뉴 최신화 반영을 위함
        userProgPrvList: []         // 개인별 권한 목록
    };
};

// initial state
const state = getDefaultState();

const getters = {
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    memberId: state => state.memberId,
    memberRole: state => state.memberRole,
    userData: state => state.userData,
    privilegeGroup: state => state.privilegeGroup,
    personalMenuList: state => state.personalMenuList,
    personalMenuNameList: state => state.personalMenuNameList,
    menuUpdateCnt: state => state.menuUpdateCnt,
    prePage: state => state.prePage,
    userProgPrvList: state => state.userProgPrvList
};

const actions = {
    async login({ commit, dispatch }, { accessToken, refreshToken, memberId, memberRole }) {
        commit("login", { accessToken, refreshToken, memberId, memberRole });
        dispatch("setUserData", memberId);
        dispatch("setPrivilegeGroup");
        dispatch("setPersonalMenuList", memberId);
        dispatch("setUserProgPrvList", memberId);
    },
    async initData({ commit }) {
        commit("initData");
    },

    async setUserData({ commit }, id) {
        await axiosInstance
            .get("/private/member", {
                params: {
                    memberId: id
                }
            })
            .then(res => {
                commit("setUserData", res.data);
            })
            .catch(function(error) {
                if (error.response.status === 500) {
                    if (error.response.data.code === "INVALID_TOKEN")
                        console.log(error);
                    else
                        store.dispatch("dialog/showAlert", {
                            message: "관리자에게 문의해주세요."
                        });
                }
            });
    },

    // 프로그램 권한 그룹 초기화
    async setPrivilegeGroup({commit}) {
        const list = await CodeMixin.methods.getDetailCodes.call(this,'PROG_PRV_USER');
        commit("setPrivilegeGroup", list);
    },

    // 개인별 사용 가능 메뉴 목록 수신
    async setPersonalMenuList({commit}, memberId) {

        await axiosInstance
            .get("/admin/operation/getPersonalMenuList", {
                params: {
                    memberId: memberId,
                }
            })
            .then(res => {
                const list = res.data.map(el => el.progPath);
                const params = {data: res.data, list: list};
                commit("setPersonalMenuList", params);
            })
            .catch((e) => {
                console.log(e);
            });
    },

    setPrePage({commit}, pageName) {
        commit("setPrePage", pageName);
    },
    // 개인별 권한 목록 저장
    async setUserProgPrvList({ commit }, id) {
        await axiosInstance
            .get("/admin/operation/getUserProgPrvList", {
                params: {
                    searchType: "MEMBERID",
                    searchValue: id
                }
            })
            .then(res => {
                const list = res.data.map(el => el.prvGroupCd);
                commit("setUserProgPrvList", list);
            })
            .catch((e) => {
                console.log(e);
            });
    },
};

// mutations
const mutations = {
    login(state, { accessToken, refreshToken, memberId, memberRole }) {
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        state.memberId = memberId;
        state.memberRole = memberRole;
    },
    initData(state) {
        state.accessToken = "";
        state.refreshToken = "";
        state.memberId = "";
        state.memberRole = "";
        state.userData = [];
        // 권한 프로세스
        state.privilegeGroup = [];
        state.personalMenuList = [];
        state.personalMenuNameList = [];
        state.menuUpdateCnt = 0;
        state.prePage = "";
    },
    setUserData(state, data) {
        state.userData = data;
    },
    setPrivilegeGroup(state, list) {
        state.privilegeGroup = list;
    },
    setPersonalMenuList(state, params) {
        state.personalMenuList = params.data;
        state.personalMenuNameList = params.list;
        state.menuUpdateCnt++;
    },
    setPrePage(state, data) {
        state.prePage = data;
    },
    setUserProgPrvList(state, list) {
        state.userProgPrvList = list;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};