<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="">
        <field-input title="구분">
          <v-select
              :items="SEARCH_CODE_TYPE"
              background-color="white"
              v-model="type"
              menu-props="auto"
              hide-details outlined dense
              v-on:change="changeType()"
          />
        </field-input>

        <field-input title="인증ID" v-if="type == 'REG' || type == 'TOKEN'">
          <v-text-field
              v-model="searchData.clientId"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>

        <field-input title="비밀번호" v-if="type == 'TOKEN'">
          <v-text-field
              v-model="searchData.clientSecret"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>

        <template v-if="type == 'REAL_NAME'">
          <field-input title="은행선택">
            <v-select
                :items="SEARCH_BANK_LIST"
                item-text="value"
                item-value="value"
                background-color="white"
                v-model="searchData.bankNm"
                menu-props="auto"
                hide-details outlined dense
            />
          </field-input>
          <field-input title="은행계좌">
            <v-text-field
                v-model="searchData.bankAccount"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </field-input>
          <field-input title="이름">
            <v-text-field
                v-model="searchData.memberNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </field-input>
          <field-input title="생년월일">
            <v-text-field
                v-model="searchData.ssnoFront"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </field-input>
        </template>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import FieldInput from "@/components/Form/FieldInput";
import SearchBtn from "@/components/Search/SearchBtn";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [CodeMixin],
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout},
  async created() {
    this.SEARCH_BANK_LIST = await this.getBankList();
    this.SEARCH_BANK_LIST.unshift({value:"은행선택"});
  },
  data: () => ({
    type: "REAL_NAME",
    SEARCH_BANK_LIST: [],
    SEARCH_CODE_TYPE: [
      {
        "text":"인증등록"
        ,"value":"REG"
      },
      {
        "text":"토큰인증"
        ,"value":"TOKEN"
      },
      {
        "text":"계좌실명검증"
        ,"value":"REAL_NAME"
      }
    ],
    searchData: {
      type:"",
      clientId:"",
      clientSecret:"",
      bankNm:"은행선택",
      bankAccount:"",
      memberNm:"",
      ssnoFront:""
    }
  }),
  methods: {
    async getSearch() {
      this.searchData.type = this.type;
      this.$emit('search', this.searchData);
    },
    changeType() {
      this.searchData = {bankNm:"은행선택"};
      this.$emit('resetData');
    }
  }
}
</script>

<style scoped>

</style>