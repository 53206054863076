<template>
  <search-layout>
    <template v-slot:searchBar>
      <linked-security-main-search
          @getSearch="getList"
        />
    </template>
    <template v-slot:table>
      <linked-security-main-table
          :data="data"
          :loading="loading"
          :paramsDto="paramsDto"
          @showDetail="showDetail"
        />
    </template>
  </search-layout>
</template>
<script>
import SearchLayout from "../../../layouts/SearchLayout";
import LinkedSecurityMainSearch from "./components/LinkedSecurityMainSearch";
import LinkedSecurityMainTable from "./components/LinkedSecurityMainTable";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {LinkedSecurityMainTable, LinkedSecurityMainSearch, SearchLayout},
  data:() =>({
    loading: false,
    data:[],
    paramsDto:[],
  }),
  methods: {
    async getList(params) {
      this.paramsDto = params;
      this.loading=true;

      try {
        const {data} = await axiosInstance.get("/admin/prdata/prTakeLkTypeList", {params});
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          data[i].createDate = data[i].createDate.replace('T', ' ').substr(0, 19);
        }
      } catch (e) {
        console.log(e);
      }
      this.loading=false;

    },
    showDetail(dto) {
      this.$emit("showDetail", dto);
    }
  }
}
</script>