<template>
  <v-overlay v-model="isShow" :light="true" :dark="false">
    <v-card max-width="75vw" height="90vh" min-width="75vw" class="overflow-y-auto modalBackground">
      <v-card-title class="headline blue-grey--text">
        <span class="font-weight-bold">권한 등록</span>
      </v-card-title>
      <v-card-text>
        <v-row class="px-6">
          <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
            <v-col cols="3">사용자명</v-col>
            <v-col cols="9" class="user-box">
              <v-text-field
                  v-model="memberNm"
                  outlined dense hide-details
                  background-color="white"
                  placeholder="사용자명 입력"
              />
              <a v-if="notExistUser" class="no-user"
              >{{ errorMsg }}</a>
            </v-col>
          </v-col>
          <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
            <v-col cols="3">전화번호</v-col>
            <v-col cols="9">
              <v-text-field
                  v-model="telNum"
                  outlined dense hide-details
                  background-color="white"
                  inputmode="numeric" maxlength="11"
                  placeholder="'-' 없이 입력"
                  @keypress.enter="searchUser"
              />
            </v-col>
          </v-col>
          <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-4 col-12">
            <v-btn
                color="white"
                class="black--text col-8 float-right"
                @click="searchUser"
                :disabled="memberNm == '' || telNum == ''"
            >조회
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="px-6">
          <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
            <v-col cols="3">ID</v-col>
            <v-col cols="9">
              <v-text-field
                  v-model="memberId"
                  outlined dense hide-details
                  background-color="lightgray"
                  disabled
              />
            </v-col>
          </v-col>
        </v-row>

        <v-divider class="mb-2"/>

        <v-row class="px-6">
          <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
            <v-col cols="3">조회범위</v-col>
            <v-col cols="9">
              <v-radio-group v-model="searchRange" row>
                <v-radio label="권한그룹" value="GR"/>
                <v-radio label="개별프로그램" value="IND"/>
              </v-radio-group>
            </v-col>
          </v-col>
          <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
            <v-col cols="3">권한그룹</v-col>
            <v-col cols="9">
              <v-select
                  v-model="prvGroupCd"
                  :items="prvGroupCdList"
                  background-color="white"
                  item-text="value"
                  item-value="cd"
                  hide-details outlined dense
                  :disabled="searchRange !== 'GR'"
              />
            </v-col>

          </v-col>
          <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-4 col-12">
            <v-btn
                color="blue darken-1"
                class="white--text col-8 float-right"
                @click="registUserProgPrv"
                :disabled="memberId == ''"
            >권한등록
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mb-2"/>

        <v-row class="px-6">
          <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
            <v-col cols="3">프로그램 명</v-col>
            <v-col cols="9">
              <v-text-field
                  v-model="progNm"
                  outlined dense hide-details
                  background-color="white"
                  placeholder="프로그램 명 또는 한글명 입력"
                  :disabled="searchRange !== 'IND'"
                  @keypress.enter="getProgramList"
              />
            </v-col>
          </v-col>
          <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-6 col-12">
            <v-btn
                color="white"
                class="black--text col-8 float-right"
                @click="getProgramList"
                :disabled="searchRange !== 'IND'"
            >조회
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
            :items="programList"
            height="34vh"
            :loading="loading"
            :headers="headers"
            :items-per-page="-1"
            :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
            dense fixed-header calculate-widths disable-sort
        >
          <template v-slot:item.checkYn="{ item }">
            <td class="text-center">
              <v-checkbox v-model="item.checkYn"/>
            </td>
          </template>

          <template v-slot:item.progMnGroup="{ value }">
            {{ MenuGroupNameKr(value) }}
          </template>
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              class="white--text col-1"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

  </v-overlay>
</template>
<script>
import _ from "lodash";
import {axiosInstance} from "@/util/AxiosModule";
import constant from "@/util/constant";

export default {
  props: ["isShow", "prvList", "checkedUser"],

  data: () => ({
    loading: false,
    headers: [
      {text: "선택", value: "checkYn", class: "teal lighten-5", align: "center", sortable: false, width: "10%"},
      {text: "메뉴그룹", value: "progMnGroup", class: "indigo lighten-5", align: "center", sortable: false, width: "20%"},
      {text: "프로그램명", value: "progNm", class: "teal lighten-5", align: "center", sortable: false, width: "35%"},
      {text: "프로그램 한글명", value: "progNmKr", class: "indigo lighten-5", align: "center", sortable: false, width: "35%"}
    ],

    memberNm: "",
    telNum: "",
    memberId: "",
    searchMemberYn: false,
    errorMsg: "",

    searchRange: "",
    prvGroupCd: "",
    prvGroupCdList: [],

    progNm: "",
    programList: []
  }),

  computed: {
    notExistUser(){
      return this.searchMemberYn && _.isEmpty(this.memberId);
    }
  },

  created() {
    this.prvGroupCdList = _.cloneDeep(this.prvList);
    this.prvGroupCd = this.prvGroupCdList[0].cd;

    // 하나의 유저만 체크 후 권한 등록 시 자동 정보 세팅
    if (!_.isEmpty(this.checkedUser)) {
      this.memberNm = this.checkedUser.memberNm;
      this.telNum = this.checkedUser.telNum;
      this.memberId = this.checkedUser.memberId;
    }
  },

  methods: {

    // 사용자 검색
    async searchUser() {
      this.searchMemberYn = true;
      this.memberId = "";

      const params = {
        memberNm: this.memberNm,
        telNum: this.telNum,
      };

      try {
        const {data} = await axiosInstance.get('/admin/operation/getUserInfo', {params});

        if (data.includes("ERROR")) {
          this.errorMsg = data;
          return false;
        }
        this.memberId = data;
      } catch (e) {
        console.log(e);
      }
    },

    // (개별프로그램 선택 시) 프로그램 목록 검색
    async getProgramList(){
      this.loading = true;

      if(_.isEmpty(this.progNm)) {
        alert('프로그램 이름을 입력하세요.');
        this.loading = false;
        return false;
      }

      const params = { progNm: this.progNm };

      try {
        const {data} = await axiosInstance.get('/admin/operation/getProgramList', {params});
        this.programList = data.map(item => ({ checkYn: false , ...item}));
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    // 권한 등록
    async registUserProgPrv() {

      if (confirm("권한을 등록하시겠습니까?")) {
        let programIdList = [];

        if (this.searchRange === 'GR') // 권한그룹
        {
          programIdList.push(0);    // 권한 그룹의 progId: 0 고정
        }
        else   // IND 개별
        {
          this.programList.forEach(item => {
            if (item.checkYn) {
              programIdList.push(item.progPrvId);
            }
          })
          this.prvGroupCd = "IND";
        }

        if (programIdList.length < 1) {
          alert("프로그램을 1개 이상 선택해주세요.");
          return false;
        }

        try {
          const {data} = await axiosInstance.post('/admin/operation/registUserProgPrv', programIdList, {
            params: {
              memberId: this.memberId,
              prvGroupCd: this.prvGroupCd,    // ADM, DEV, IND(개별) 등
              regId: this.$store.getters['login/memberId']
            }
          })

          alert(data);

          this.$emit('refresh');
          this.close();
        } catch (e) {
          console.log(e);
        }
      }
    },

    MenuGroupNameKr(value) {
      return constant.PROGRAM_MENU_GROUP_LIST.find(el => el.cd == value).name;
    },

    close() {
      this.$emit('close');
    },

  }
}
</script>

<style scoped>

.user-box {
  position: relative;
}

.user-box .no-user{
  color: red;
  position: absolute;
  top: 55px;
  left: 17px;
}

span.card-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  margin-right: -8px;
}

</style>