<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ infosList.length }}개)</span>
    </div>
    <v-row class="px-6">    
      <v-col
        v-for="(item, inx) in infosList"
        :key="inx"
        class="d-flex child-flex"
        cols="4"
      >
        <v-card  width="100" height="420" @click="getNeedsModal(item)">
          <div style="width: 100%; background: white;  border-radius: 8px;  padding: 10px;">
            <div v-html="item.contents" style="	height: 210px;">
            </div>
          </div>
          <div style="width: 100%; background: Beige;  border-radius: 8px;  padding: 10px;"> 
          <v-card-text class="font-weight-bold">
            <div class="font-weight-bold ml-1">
              담보그룹 : {{ item.coverageGroupNm }}
              <v-spacer></v-spacer>
              적용연령대 : {{ item.ageGroupNm  }}
            </div>
            <div class="font-weight-bold ml-1">
              성별 : {{item.sexNm}}
              <v-spacer></v-spacer>
              사용용도 : {{ item.purposeNm  }}
            </div>
            <div class="font-weight-bold ml-1">
              최종작업자 : {{item.regNm}}
              <v-spacer></v-spacer>
              등록일시 : {{ item.updateDate  }}
            </div>            
          </v-card-text>
          </div>
        </v-card>
      </v-col>
      <needs-detail-dialog
          :dialog="dialog"
          :infoRow="infoRow"
          @close="dialog = false"
          @updateSetting="updateSetting()"
        >
      </needs-detail-dialog>    
    </v-row>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import NeedsDetailDialog from "@/pages/Manage/NeedsInfo/components/NeedsDetailDialog";

export default {

  mixins: [TableMixin],
  props: ["infosList", "loading", "sparams"],
  components: {NeedsDetailDialog},  
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  data: () => ({
   // headers: Needs_HEADER
   headers: [
     { text: "SEQ",  value: "needsRaiseDataSeq", class: "teal lighten-4" , align: "center", sortable: true },
     { text: "담보그룹",  value: "coverageGroupNm", class: "teal lighten-4" , align: "center", sortable: false },
     { text: "적용연령대", value: "ageGroupNm", class: "indigo lighten-4", align: "center", sortable: false  },
     { text: "성별", value: "sexNm", class: "indigo lighten-4", align: "center", sortable: false  },
     { text: "저장형태", value: "fileType", class: "indigo lighten-4", align: "center", sortable: false  },
     { text: "사용용도", value: "purposeNm", class: "indigo lighten-4", align: "center", sortable: false  },
     { text: "내용", value: "contents", class: "lime lighten-4", align: "center", sortable: false  },
     { text: "최종작업자", value: "regNm", class: "light-blue lighten-4", align: "center", sortable: false  },
     { text: "등록일시", value: "updateDate", class: "light-blue lighten-4", align: "center", sortable: false  }
   ],
    date: new Date().toISOString().substr(0, 7),
    dialog: false, 
    infoRow: {} 
  }),
  methods: {
    getNeedsModal(item) {
      this.dialog = false
      for (var i in this.infosList) {
        if (this.infosList[i] === item) {
          this.dialog = true;
          this.infoRow = this.infosList[i]
        }
      }
      this.$emit('regist')
    },
    setDialog (value) { 
      this.dialog = value
    },
    updateSetting() {
      this.dialog = false
      console.log(this.sparams)      
      this.$emit('refreshSetting',this.sparams)
    },    
  }  
}
</script>