<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-flex>
      <v-card max-width="100vw" max-height="95vh" class="overflow-y-auto">
        <v-card-title class="headline">
          <v-col cols="12">
            <span class="font-weight-bold">인수지침 연계담보 변경</span>
          </v-col>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-data-table
            style="margin-left: 10px; margin-right: 10px;"
            height="35vh"
            :items="data"
            :items-per-page="-1"
            fixed-header disable-sort calculate-widths
            :headers="headers"
            :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
            >
          <template v-slot:item.baseVal="{ value, item, header }">
            <text-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.sex="{ value, item, header }">
            <select-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                :items="sexList"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.riskLevel="{ value, item, header }">
            <select-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                :items="riskLevelList"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.riskLevel2="{ value, item, header }">
            <select-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                :items="riskLevelList"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.drivingDiv="{ value, item, header }">
            <select-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                :items="drivingDivList"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.cvrAmtCond="{ value, item, header }">
            <select-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                :items="cvrAmtCondList"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.frAge="{ value, item, header }">
            <text-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.toAge="{ value, item, header }">
            <text-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.dirLkCvrCd="{ value, item, header }">
            <text-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.frCvrAmt="{ value, item, header }">
            <text-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                @update="changeValue"
            />
          </template>
          <template v-slot:item.toCvrAmt="{ value, item, header }">
            <text-input
                :key="`${item.index}-${item.name}`"
                :value="value" :id="item.index"
                :header="header.value"
                @update="changeValue"
            />
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darkken-4"
              class="white--text col-1"
              style="margin-right: 5px;"
              @click="changeData"
          >저장</v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-1"
              @click="$emit('close')"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import _ from "lodash";
import TextInput from "../../../Settlement/components/TextInput";
import SelectInput from "../../../Settlement/components/SelectInput";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  props:['data', 'isShow'],
  components: {SelectInput, TextInput},
  data:() => ({
    headers: [
      {text: "연계담보구분코드", value: "lkCvrDivCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보명", value: "cvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "기준값", value: "baseVal", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "성별", value: "sex", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "위험급수", value: "riskLevel", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "위험급수2", value: "riskLevel2", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "운전형태", value: "drivingDiv", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "연령_FR", value: "frAge", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "연령_TO", value: "toAge", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "직접연계담보코드", value: "dirLkCvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액조건", value: "cvrAmtCond", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액_FR", value: "frCvrAmt", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액_TO", value: "toCvrAmt", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "등록일시", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
    ],
    sex:'',
    tagkey:0,
    sexList: [
      { text: "공용", value: "0"},
      { text: "남성", value: "1"},
      { text: "여성", value: "2"},
    ],
    riskLevelList: [
      { text: "1", value: "1"},
      { text: "2", value: "2"},
      { text: "3", value: "3"},
      { text: "4", value: "4"},
      { text: "5", value: "5"},
    ],
    drivingDivList:[
      { text: "상관없음", value: ""},
      { text: "안함", value: "0"},
      { text: "개인용", value: "1"},
      { text: "영업용", value: "2"},
    ],
    cvrAmtCondList:[
      { text: "BASE_AMT", value: "BASE_AMT"},
      { text: "SET_AMT", value: "SET_AMT"},
      { text: "RANGE_AMT", value: "RANGE_AMT"},
    ]
  }),
  methods: {
    changeValue(text, id, header) {
      const item = _.find(this.data, {index: id});
      if (header === 'riskLevel2') {
        if (Number(text) < Number(item.riskLevel) || _.isEmpty(item.riskLevel)) {
          alert("위험급수2는 위험급수보다 클 수 없습니다.");
          return;
        }
      } else if (header === 'toAge') {
        if (Number(text) < Number(item.frAge) || _.isEmpty(item.frAge)) {
          alert("연령TO은 연령FR보다 클 수 없습니다.");
          return;
        }
      } else if (header === 'toCvrAmt') {
        if (Number(text) < Number(item.frCvrAmt) || _.isEmpty(item.frCvrAmt)) {
          alert("담보금액TO은 담보금액FR보다 클 수 없습니다.");
          return;
        }
      }
      item[header] = text;
      this.tagkey++;
    },
    async changeData() {
      const PrTakeLkCvrTbDto = this.data;
      for (let i = 0; i < PrTakeLkCvrTbDto.length; i++) {
        delete PrTakeLkCvrTbDto[i].createDate;
      }
      if (confirm("저장하시겠습니까?")) {
        try {
          const {data} = await axiosInstance.post('/admin/prdata/prTakeLkCvrProc', PrTakeLkCvrTbDto, {
            params: {
              wkDiv: 'U'
            }
          })
          alert('인수지침 연계담보 ' + data + '개가 변경되었습니다.');
          this.$emit('close');
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
}
</script>

<style scoped>
.v-data-table::v-deep td {
  white-space: normal!important;
  word-break: keep-all;
}
</style>