import {axiosInstance} from "@/util/AxiosModule";
import constant from "@/util/constant";

/** 상품군리스트 가져오기
 * 전달값(고정값): { srhType: 2, insDiv: 'A' }
 * data.unshift 로 data[0]에 "전체" 데이터 넣기
 */
const getPrProdLineCd = async function() {
    const { data } = await axiosInstance.get('/public/prdata/prProdLineNmList', {
        params: {
            srhType: 2,
            insDiv: "A",
        }
    })
        .catch((e) => {
            console.log(e);
        })
    data.unshift({
        prProdLineNm: "전체",
        prProdLineCd: ""
    });
    return data;
}

/** 보험사리스트 가져오기
 * 전달값(고정값): { cdMasterId: 'PR_INS_CODE', useYn: 'Y' }
 * data.splice() 로 "공통" 관련 데이터 삭제
 *
 */
const getInsCd = async function() {
    const insCdList = [];
    const { data } = await axiosInstance.get('/public/prdata/code-list', {
        params: {
            cdMasterId: 'PR_INS_CODE',
            useYn: 'Y'
        }
    })
        .catch((e) => {
            console.log(e);
        })
    let insAllCd = "A00";
    let insNonLifeCd = "N00";
    let dataList = data.filter(item => item.cd !== insAllCd && item.cd !== insNonLifeCd);
    for (let i = 0; i < dataList.length; i++) {
        if (data[i].use === true) {
            const object = {};
            object.insCdNm = dataList[i].value;
            object.insCd = dataList[i].cd;
            insCdList.push(object);
        }
    }
    insCdList.unshift({
        insCdNm: "전체",
        insCd: ""
    });
    return insCdList;
}

/** 적용조건리스트 가져오기
 * 전달값(고정값): { cdMasterId: 'PR_COND_DIV', useYn: 'Y' }
 * data.splice() 로 "공통" 관련 데이터 삭제
 *
 */
const getCondDiv = async function() {
    const condDivList = [];
    const { data } = await axiosInstance.get('/public/prdata/code-list', {
        params: {
            cdMasterId: 'PR_COND_DIV',
            useYn: 'Y'
        }
    })
        .catch((e) => {
            console.log(e);
        })
    for (let i = 0; i < data.length; i++) {
        if (data[i].use === true) {
            const object = {};
            object.condDivNm = data[i].value;
            object.condDiv = data[i].cd;
            condDivList.push(object);
        }
    }
    return condDivList;
}

/** 상품군에 대한 데이터 가져오기
 * 전달값(고정값): { prProdLineCd: prProdLineCd }
 *
 */
const getPrProdLineCondArr = async function(prProdLineCd) {
    const { data } = await axiosInstance.get('/private/prdata/prProdLineCondArr', {
        params: {
            prProdLineCd: prProdLineCd
        }
    })
        .catch((e) => {
            console.log(e);
        })
    return data;
}

/** 상품리스트 가져오기
 * 전달값(고정값): { memberId(login/memberId), customerNm(고정값), birthday, sex, baseDt,
 *                  prProdLineCd, insTrm, pyTrm, rnwCycle, prodType, cvrInfoList, sortType}
 */
const getPrList =
    async function(
        memberId, birthday, sex, baseDt, prProdLineCd, insTrm, pyTrm, rnwCycle, prodType, cvrInfoList) {
        const { data } = await axiosInstance.post('/public/newProduct/prList', {
            memberId: memberId,
            customerNm: "홍길동",
            birthday: birthday,
            sex: sex,
            baseDt: baseDt,
            prProdLineCd: prProdLineCd,
            insTrm: insTrm,
            pyTrm: pyTrm,
            rnwCycle: rnwCycle,
            prodType: prodType,
            cvrInfoList: cvrInfoList,
            sortType: constant.SORT_TYPE.AMOUNT
        })
            .catch((e) => {
                console.log(e);
            })
        return data;
    }

/** 상품코드리스트 가져오기
 * 전달값(고정값): { cdMasterId: 'PR_COND_DIV', useYn: 'Y' }
 * data.splice() 로 "공통" 관련 데이터 삭제
 *
 */
const getPrCdList = async function(prProdLineCd, insCd, useYn) {
    let prCdList = [];
    const { data } = await axiosInstance.get('/public/prdata/prProdList', {
        params: {
            prProdCd: prProdLineCd,
            insCd: insCd,
            useYn: useYn
        }
    })
        .catch((e) => {
            console.log(e);
        })
    for (let i = 0; i < data.length; i++) {
        // if (data[i].useYn === "") {
        const object = {};
        object.prNm = data[i].prNm;
        object.prCd = data[i].prCd;
        object.prCdNm = data[i].prCd + "-" + data[i].prNm;
        prCdList.push(object);
        // }
    }
    return prCdList;
}

/** 작업단계코드 리스트 가져오기
 * 전달값(고정값): { cdMasterId: 'PR_WK_PROC', type: 'KEY' }
 * cd.replace("PR_WK_PROC_", "") 로 공통부분 삭제 하여 사용
 */
const getWkStepList = async function() {
    let wkStepList = [];
    await axiosInstance.get("/admin/code/all-detail", {
        params: {
            cdMasterId: "PR_WK_PROC",
            type: "KEY"
        }
    }).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].cd.replace("PR_WK_PROC_", "") !== "1000") {
                const object = {};
                object.wkStepNm = response.data[i].value;
                object.wkStepCd = response.data[i].cd.replace("PR_WK_PROC_", "");
                wkStepList.push(object);
            }
        }
    }).catch((error) => {
        console.log(error);
    });
    return wkStepList;
};

/** 작업단계코드 리스트 가져오기
 * 전달값(고정값): { cdMasterId: 'PR_WK_PROC_${wkStepCd}', type: 'KEY' }
 * PR_WK_PROC_${wkStepCd(작업단계 코드)} 사용하여 조회
 * cd.replace() 로 공통 "PR_WK_PROC_" 삭제 하여 사용
 */
const getWkSubStepList = async function(code) {
    let wkSubStepList = [];
    await axiosInstance.get("/admin/code/all-detail", {
        params: {
            cdMasterId: `PR_WK_PROC_${code}`,
            type: "KEY"
        }
    }).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
            const object = {};
            object.wkSubStepNm = response.data[i].value;
            object.wkSubStepCd = response.data[i].cd.replace("PR_WK_PROC_", "");
            wkSubStepList.push(object);
        }
    }).catch((error) => {
        console.log(error);
    })
    return wkSubStepList;
};

export default {
    getPrProdLineCd,
    getInsCd,
    getCondDiv,
    getPrProdLineCondArr,
    getPrList,
    getPrCdList,
    getWkStepList,
    getWkSubStepList
}