<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area :key="tgKey" title="기본정보">
        <v-col cols="12" class="py-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left th-font">
                  보험사 : {{insCd}}
                </th>
                <th class="text-left th-font">
                  상품코드 : {{prCd}}
                </th>
                <th class="text-left th-font">
                  플랜코드 : {{planCd}}
                </th>
                <th class="text-left th-font">
                  연계담보구분유형 : {{lkCvrDivType}}
                </th>
                <th class="text-left th-font">
                  충족조건 : {{satCond}}
                </th>
              </tr>
              </thead>
            </template>
          </v-simple-table>
          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left th-font" style="padding-top: 15px; padding-bottom: 6px;">
                연계담보구분 설명
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{lkCvrDivDesc}}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
export default {
  components: {SearchArea, SearchBarLayout},
  props:["detailDto"],
  data:() => ({
    tgKey: 0,
    insCd:'',
    prCd:'',
    planCd:'',
    lkCvrDivType:'',
    satCond:'',
    lkCvrDivDesc:''
  }),
  watch: {
    detailDto: function () {
      this.tgKey++;
      this.insCd = this.detailDto.insCd;
      this.prCd = this.detailDto.prCd;
      this.planCd = this.detailDto.planCd;
      this.lkCvrDivType = this.detailDto.lkCvrDivType;
      this.satCond = this.detailDto.satCond;
      this.lkCvrDivDesc = this.detailDto.lkCvrDivDesc;
    }
  },
  methods: {

  }
}
</script>
<style scoped>
.th-font {
  font-size: 15px !important;
}
td {
  white-space: normal!important;
  word-break: keep-all;
}
th {
  background-color: rgba(0,0,0,.05) !important;
  border: 1px solid rgba(0,0,0,0.1);
}
</style>