<template>
  <search-page-layout title="보험사 유입이력 조회">

    <template v-slot:searchBar>
      <inflow-hist-search-bar
          :name="name"
          @search="getInflowList"
      />
    </template>

    <template v-slot:table>
      <inflow-hist-table
        :data="data"
        :loading="loading"
        :conditionHist="conditionHist"
        @getRowItem="getRowItem"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import InflowHistSearchBar from "@/pages/User/components/InflowHistSearch/InflowHistSearchBar";
import InflowHistTable from "@/pages/User/components/InflowHistSearch/InflowHistTable";

export default {
  props: ["name"],
  components: {
    InflowHistTable,
    InflowHistSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
    conditionHist: {},
  }),

  methods: {
    async getInflowList(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.post('/admin/operation/ins-inflow-hist', params);
        this.conditionHist = params;
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    getRowItem(item){
      this.$emit('getRowItem', item);
    }
  }
}
</script>