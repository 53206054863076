<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <field-input title="기준연월">
          <v-text-field
              v-model="settlementDate"
              :disabled="searching"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="조회조건">
          <v-radio-group v-model="searchType" row :disabled="searching">
            <v-radio label="원클릭" value="1"/>
            <v-radio label="렌카" value="2"/>
            <v-radio label="조기정산" value="4"/>
          </v-radio-group>
        </field-input>
        <field-input title="지급완료일자">
          <v-text-field
              v-model="payCompleteDate"
              :disabled="searching"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <v-btn class="grey white--text" :disabled="isDisabled" v-if="!searching" @click="search">불러오기</v-btn>
        <v-btn class="red white--text" v-else @click="initData">초기화</v-btn>
        <v-spacer/>
        <v-checkbox v-model="check" label="해당 기준연월 건만 보기" @change="$emit('check', check)" :disabled="checkboxDisabled" />
      </search-area>
      <v-slide-y-transition>
        <v-divider class="mb-2" v-show="searching"/>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <search-area title="상세조건" v-show="searching">
          <field-input title="상태">
            <v-select
                :items="REWARD_STATUS" v-model="status"
                background-color="white" menu-props="auto"
                hide-details outlined dense
                @change="$emit('changeStatus', status)"
            />
          </field-input>
          <field-input title="회원명">
            <v-text-field
                v-model="nm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </field-input>
          <field-input title="주민등록번호">
            <v-text-field
                v-model="ssno"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </field-input>
        </search-area>
      </v-slide-y-transition>
    </template>

    <template v-slot:button>
      <v-slide-y-transition v-show="searching">
        <search-btn
            v-if="searching"
            @click="$emit('filter', status, nm, ssno)"
        />
      </v-slide-y-transition>
    </template>
  </search-bar-layout>
</template>

<script>

import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBtn from "@/components/Search/SearchBtn";

export default {
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout},
  computed: {
    isDisabled() {
      const d = this.settlementDate;
      return !(!!d && /[0-9]{6}/.test(d) && this.monthCheck(d));
    }
  },
  props: ["searching", "REWARD_STATUS", "checkboxDisabled"],
  data: () => ({
    settlementDate: "",
    payCompleteDate: "",
    status: "ALL",
    nm: "",
    ssno: "",
    check: false,
    searchType: "1",
  }),
  watch : {
    checkboxDisabled : function(value) {
      if(value) this.check = false;
    }
  },
  methods: {
    // 기준연월 유효성 체크
    monthCheck(value) {
      return value.length === 6
          ? (+value.slice(4) < 13 && +value.slice(4) > 0)
          : false;
    },
    search() {
      const params = {
        searchType: this.searchType,
        settlementDate: this.settlementDate,
        payCompleteDate: this.payCompleteDate
      }
      this.$emit("search", params);
    },
    initData() {
      this.settlementDate = "";
      this.payCompleteDate = "";
      this.status = "ALL";
      this.$emit('initData');
    },
  }
}
</script>

<style>
.todayDelete {
  margin-left: auto;
  color: red;
  text-decoration-line: underline;
  cursor: pointer;
}
</style>