<template>
  <search-page-layout title="보험약관 우선질문 관리">
    <template v-slot:searchBar>
      <ins-ai-chat-management-search-bar
        @search="getSearch"
      />
    </template>

    <template v-slot:table>
      <ins-ai-chat-management-table
        :data="data"
        :loading="loading"
        @rowData="getRowData"
      />
    </template>

    <template v-slot:inflowHist>
      <ins-ai-chat-management-save
        :row-data="rowData"
        @result="searchResult"
        @refreshData="refreshData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import InsAiChatManagementSearchBar from "@/pages/Manage/InsAiChatManagement/components/InsAiChatManagementSearchBar";
import InsAiChatManagementTable from "@/pages/Manage/InsAiChatManagement/components/InsAiChatManagementTable";
import InsAiChatManagementSave from "@/pages/Manage/InsAiChatManagement/components/InsAiChatManagementSave";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {
    InsAiChatManagementSave, InsAiChatManagementTable, InsAiChatManagementSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      rowData: {},
      paramsData: {}
    }
  },
  methods: {
    getRowData(data) {
      this.rowData = data;
    },
    searchResult() {
      this.isShowResult = true;
    },
    async getSearch(params) {
      this.loading = true;
      this.paramsData = params;
      try {
        const { data } = await axiosInstance.get("/admin/ai/questionList", { params });
        if (data.length < 1) {
          alert("조회된 데이터가 없습니다.");
        } else {
          this.data = data;
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    refreshData() {
      this.data = [];
      this.getSearch(this.paramsData);
    }
  }
}
</script>