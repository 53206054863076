<template>
  <v-col cols="12" class="py-0">
    <!-- 엑셀저장 버튼 -->
    <v-col>
      <outlined-btn class="float-right" @click=toExcel :disabled="!hasTableData" style="margin-top: 20px">엑셀저장</outlined-btn>
    </v-col>
    <!-- 페이지 크기에 맞게 반응형 -->
    <v-flex xs12 sm12 md12 lg12 xl12>
      <!-- 집계 및 대상자 목록 이동 탭 -->
      <v-tabs>
        <v-tab style="font-weight: bold" @click="tabList('totalList')">집계</v-tab>
        <v-tab style="font-weight: bold" @click="tabList('targetList')">대상자</v-tab>
      </v-tabs>

      <!-- 집계 목록 -->
      <v-data-table
          v-if="isShowList"
          :items="totalData"
          :loading="loading"
          :headers="getHeader(totalHeader)"
          class="totalList"
      >
        <!-- 집계-일자 -->
        <template v-slot:item.stsDt="{ value }">
          <!-- 날짜형식 함수 -->
          {{ getDateDashFormat(value) }}
        </template>
      </v-data-table>

      <!-- 대상자 목록 -->
      <v-data-table
          v-if="!isShowList"
          :items="targetData"
          :loading="loading"
          :headers="getHeader(targetHeader)"
      >
        <!-- 대상자-일자 -->
        <template v-slot:item.stsDt="{ value }">
          <!-- 날짜형식 함수 -->
          {{ getDateDashFormat(value) }}
        </template>

        <!-- 대상자-생일 -->
        <template v-slot:item.birthday="{ value }">
          <!-- (년.월.일) 형식 -->
          {{ getDotsBirthday(value) }}
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import {PRODUCT_RECOMMEND_ACCOUNT_TARGET_TABLE, PRODUCT_RECOMMEND_ACCOUNT_TOTAL_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import _ from "lodash";
import func from "@/util/func";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import DateMixin from "@/mixin/DateMixin";

export default {
  props: ["loading", "totalData", "targetData"],
  mixins: [TableMixin, DateMixin],
  components: {
    OutlinedBtn,
  },
  computed: {
    // 엑셀저장 버튼 활성화 조건
    hasTableData() {
      return this.totalData.length > 0 || this.targetData.length > 0;
    },
  },
  data() {
    return {
      isShowList: true,
      totalHeader: PRODUCT_RECOMMEND_ACCOUNT_TOTAL_TABLE,
      targetHeader: PRODUCT_RECOMMEND_ACCOUNT_TARGET_TABLE,
    }
  },
  methods: {
    // 클릭 이벤트를 이용한 탭 이동
    tabList(value) {
      if(value === "totalList") {
        this.isShowList = true;
      } else if(value === "targetList") {
        this.isShowList = false;
      }
    },
    // 엑셀 다운로드
    toExcel() {
      const totalData = _.cloneDeep(this.totalData);
      const targetData = _.cloneDeep(this.targetData);
      const returnData = [];

      if (this.isShowList) {
        for (const item of totalData) {
          const obj = {
            "일자": item["stsDt"],
            "신규등록자수": item["newCnt"],
            "추가등록자수": item["addCnt"],
            "현대": item["cnt1"],
            "DB": item["cnt2"],
            "KB": item["cnt3"],
            "한화": item["cnt4"],
            "메리츠": item["cnt5"],
            "롯데": item["cnt6"],
            "그외": item["cnt7"],
          }
          returnData.push(obj);
        }
        const sheetName = `상품추천 사용자 계정 등록현황-집계`;
        const fileName = `상품추천 사용자 계정 등록현황-집계`;
        func.extractExcelFile(returnData, sheetName, fileName);
      } else {
        for (const item of targetData) {
          const obj = {
            "일자": item["stsDt"],
            "구분": item["regType"],
            "FA명": item["memberNm"],
            "이메일": item["email"],
            "생년월일": item["birthday"],
            "소속": item["joinType"],
            "기존회사": item["existComps"],
            "추가회사": item["addComps"],
          }
          returnData.push(obj);
        }
        const sheetName = `상품추천 사용자 계정 등록현황-대상자`;
        const fileName = `상품추천 사용자 계정 등록현황-대상자`;
        func.extractExcelFile(returnData, sheetName, fileName);
      }
    }
  }
}
</script>

<style scoped>
.v-data-table.totalList::v-deep tr:first-child {
  background-color: #98c0c3;
}
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>