<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">게시판유형</v-col>
            <v-col cols="6">
              <v-select
                  v-model="searchType1"
                  :items="boardType"
                  background-color="white"
                  item-text="bname"
                  item-value="bvalue"
                  menu-props="auto"
                  dense hide-details outlined
              />
            </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">사용자범위</v-col>
          <v-col cols="6">
            <v-select
                v-model="searchType2"
                :items="userRanges"
                background-color="white"
                item-text="uname"
                item-value="uvalue"
                dense hide-details outlined
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="4">회원가입경과상태</v-col>
          <v-col cols="8">
            <v-select
                v-model="searchType3"
                :items="elapsedStatus"
                background-color="white"
                item-text="ename"
                item-value="evalue"
                dense hide-details outlined
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">제목/내용</v-col>
          <v-col cols="9">
            <v-text-field
                v-model="srchTxt"
                placeholder="검색단어를 입력하세요"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">등록일자</v-col>
          <v-col cols="8">
            <date-input
                :start-date="searchFileStartDate"
                :end-date="searchFileEndDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
        <v-col class="col-xl-9 col-lg-4 col-md-6 col-12">
          <div class="float-right">
            <v-btn style="margin-right: 10px;" @click="getboard">
              조회
            </v-btn>
            <v-btn
                color="blue darken-4"
                class="white--text col-2"
                @click="$emit('regist')"
            >등록
            </v-btn>
          </div>
        </v-col>
      </search-area>
    </template>

  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateMixin from "@/mixin/DateMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import store from "@/store";

export default {
  props: [],
  mixins: [SearchBarMixin, DateMixin, CodeMixin],
  components: {SearchArea, SearchBarLayout, DateInput},
  async beforeMount() {
    // 시작 날짜, 종료 날짜 초기 설정
    const {fromDate, toDate} = this.setDateDiff(-31);
    this.searchFileStartDate = fromDate;
    this.searchFileEndDate = toDate;
  },
  async created() {

  },

  data: () => ({
    boardType: [
      {bname: '전체', bvalue: 'AL'},
      {bname: '일반', bvalue: 'GE'},
      {bname: '이벤트', bvalue: 'EV'},
      {bname: '이벤트퀴즈', bvalue: 'EQ'},
      {bname: 'FAQ', bvalue: 'QA'}
    ],
    userRanges: [
      {uname: '전체', uvalue: 'AL'},
      {uname: '원클릭', uvalue: 'ON'},
      {uname: '청신호', uvalue: 'GR'},
      {uname: '인카', uvalue: 'IC'},
      {uname: '타FA', uvalue: 'OF'},
      {uname: '제휴회사', uvalue: 'AC'}
    ],
    elapsedStatus: [
      {ename: '전체', evalue: 'AL'},
      {ename: '가입1주이내', evalue: 'W1'},
      {ename: '가입1달이내', evalue: 'M1'},
      {ename: '가입1년이내', evalue: 'Y1'},
      {ename: '가입1년이상', evalue: 'YF'}
    ],
    searchType1: "AL",
    searchType2: "AL",
    searchType3: "AL",
    srchTxt: "",
    registTag: "",
    searchFileStartDate: "",
    searchFileEndDate: ""
  }),

  methods: {
    updateDate(values) {
      this.searchFileStartDate = values[0];
      this.searchFileEndDate = values[1];
    },

    getboard() {
      const params = {};
      params.boardType = this.searchType1;
      params.userRanges = this.searchType2;
      params.elapsedStatus = this.searchType3;
      params.srchTxt = this.srchTxt;
      params.startDate = this.searchFileStartDate.replace(/[^0-9]/gi, "");
      params.endDate = this.searchFileEndDate.replace(/[^0-9]/gi, "");
      params.memberId = store.getters['login/memberId'];
      this.$emit('getboard', params)
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>