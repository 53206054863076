<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="3">상품군</v-col>
          <v-col cols="7">
            <v-select
              @click="getPrProdLineList"
              v-model="prProdLineCd"
              :items="prProdLineCdList"
              background-color="white"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              class="subBar"
              hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
          <v-col cols="8">
            <v-select
                @click="getInsCd"
                v-model="insCd"
                :items="insCdList"
                background-color="white"
                item-text="insCdNm"
                item-value="insCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">상품명</v-col>
          <v-col cols="9">
            <v-text-field
                v-model="prNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-3 col-12">
          <v-col cols="6">사용여부</v-col>
          <v-col cols="7">
            <v-select
                v-model="useYn"
                :items="useYnList"
                background-color="white"
                item-text="useYnName"
                item-value="useYnValue"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBtn from "@/components/Search/SearchBtn";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  components: {SearchArea, SearchBarLayout, SearchBtn},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "전체", prProdLineCd: ""}
      ],
      insCd: "",
      insCdList: [
        {insCdNm: "전체", insCd: ""}
      ],
      prNm: "",
      useYn: "Y",
      useYnList: [
        {useYnName: "Y", useYnValue: "Y"},
        {useYnName: "N", useYnValue: "N"},
      ]
    }
  },
  methods: {
    async getPrProdLineList() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
    },
    async getInsCd() {
      this.insCdList = await ProductRecommend.getInsCd();
    },
    getSearch() {
      const params = {
        prProdCd: this.prProdLineCd,
        insCd: this.insCd,
        prNm: this.prNm,
        useYn: this.useYn,
      }
      this.$emit("getSearch", params);
    }
  }
}
</script>