<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin: 0 3px 0 3px;"
          @click="toExcel"
          :disabled="data.length <= 0"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.prNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...") }}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.minPrem="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.prodType="{ value }">
          <div>{{ showProdTypeNm(value) }}</div>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
        <template v-slot:item.appFromDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.sex="{ value }">
          <div>{{ changedData(value, "0", "공용") }}</div>
          <div>{{ changedData(value, "1", "남자") }}</div>
          <div>{{ changedData(value, "2", "여자") }}</div>
        </template>
        <template v-slot:item.appToDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.newDispEndDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {PRODUCT_INFORMATION_MANAGEMENT_TABLE} from "@/util/TableHeaders";
import DateMixin from "@/mixin/DateMixin";
import _ from "lodash";
import func from "@/util/func";
import constant from "@/util/constant";

export default {
  mixins: [TableMixin, DateMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: PRODUCT_INFORMATION_MANAGEMENT_TABLE,
    }
  },
  methods: {
    // 목록 선택
    clickContents(row) {
      this.$emit('clickRow', row);
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        let sex = item["sex"];
        if (sex === "0") {
          sex = "공용";
        } else if (sex === "1") {
          sex = "남자";
        } else if (sex === "2") {
          sex = "여자";
        }
        const obj = {
          "상품군명": item["prProdLineNm"],
          "보험사명": item["insNm"],
          "상품코드": item["prCd"],
          "상품명": item["prNm"],
          "최소보험료": item["minPrem"],
          "적용시기": item["appFromDt"],
          "적용종기": item["appToDt"],
          "사용성별": sex,
          "추천점수": item["prScore"],
          "업데이트표시일": item["newDispEndDt"],
          "업데이트진행중여부": item["updatingYn"],
          "보험기간범위": item["insTrmRange"],
          "갱신기간범위": item["rnwTrmRange"],
          "보험/납입기간": item["insPyTrmCnt"],
          "사용여부": item["useYn"],
          "등록일시": item["createDate"],
        }
        returnData.push(obj);
      }
      const sheetName = `상품정보 관리`;
      const fileName = `상품정보 관리`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },

    showProdTypeNm(type) {
      if (_.isEmpty(type))
        return '';
      else
        return constant.PROD_TYPE.find(el => el.prodType == type).prodTypeNm;
    },
  }
}
</script>