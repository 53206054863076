<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="summary"
        :loading="loading"
        :items-per-page="-1"
        fixed-header disable-sort calculate-widths
        :headers="headers"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>

export default {
  props:["summary", "loading"],
  computed: {
    hasTableData() {
      return this.summary.length > 0;
    }
  },
  data:() => ({
    headers: [{text: "일자", value: "standardDt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "사용자수", value: "userCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "다운로드수", value: "downLoadCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "로그인수", value: "loginCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "조회수", value: "queryCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "결과건수", value: "resultCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "엑셀저장수", value: "excelCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "메인지역입력건", value: "mainResionCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "서브지역입력건", value: "subResionCnt", class: "teal lighten-4", align: "center", sortable: false}
    ]
  })
}
</script>