<template>
  <search-page-layout title="월마감 원천자료 보험사별 집계 매핑현황">
    <template v-slot:searchBar>
      <settlement-ins-summary-searchbar
        @search="getSearch"
      />
    </template>
    <template v-slot:table>
      <settlement-ins-summary-table
        :data="data"
        :loading="loading"
        :params-data="paramsData"
        @toExcel="toExcel"
        @updateData="updateData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import SettlementInsSummarySearchbar
  from "@/pages/Settlement/components/SettlementInsSummary/SettlementInsSummarySearchbar";
import SettlementInsSummaryTable from "@/pages/Settlement/components/SettlementInsSummary/SettlementInsSummaryTable";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import func from "@/util/func";
export default {
  components: {SettlementInsSummaryTable, SettlementInsSummarySearchbar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      paramsData: {}
    }
  },
  methods: {
    async getSearch(params) {
      try {
        this.loading = true;
        this.paramsData = params;
        const { data } = await axiosInstance.get("/admin/settlement/settlementInsSummary", { params });
        let allData = {};
        const noSumFields = ["joinChannel", "baseYm", "stateCd"];

        for (const index in data) {
          for (const key in data[index]) {
            if (key === "contractInsurer") {
              allData[key] = "합계";
            } else if (noSumFields.includes(key)) {
              allData[key] = "";
            } else {
              if (allData[key] == null) {
                allData[key] = data[index][key];
              } else {
                allData[key] += data[index][key];
              }
            }
          }
        }
        data.push(allData);
        this.data = data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      let day = new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '');
      day = day.replaceAll(':', '_');

      for (const item of data) {
        const obj = {
          "원천보험사": item["contractInsurer"],
          "채널": item["joinChannel"],
          "기준년월": item["baseYm"],
          "원천건수": Number(item["srcCnt"]),
          "원천보험료": Number(item["srcPrem"]),
          "상태": item["stateCd"],
          "정산완료건수": Number(item["completeCnt"]),
          "정산완료보험료": Number(item["completePrem"]),
          "정산완료광고비": Number(item["completeRewardAmt"]),
          "환수대상건수": Number(item["refundCnt"]),
          "환수대상보험료": Number(item["refundPrem"]),
          "환수대상광고비": Number(item["refundRewardAmt"]),
          "신규생성건수": Number(item["newCnt"]),
          "신규생성보험료": Number(item["newPrem"]),
          "신규생성광고비": Number(item["newRewardAmt"])
        }
        returnData.push(obj);
      }

      const sheetName = `월마감 원천자료 매핑현황`;
      const fileName = `월마감 원천자료 매핑현황-(${this.paramsData.baseYm})` + day;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    updateData() {
      this.getSearch(this.paramsData);
    }
  }
}
</script>