<template>
  <v-overlay
      v-model="show"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="150vw" max-height="95vh" width="70vw" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">일자별 사용자</span>
        </v-card-title>

        <v-card-text>
          <v-data-table
              height="50vh"
              :items="boardRow"
              fixed-header disable-sort calculate-widths
              :headers="headers"
              :footer-props="{'items-per-page-options': [ 30, 50, -1]}"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="show=false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import TableMixin from "@/mixin/TableMixin";
import StringMixin from "@/mixin/StringMixin";

export default {
  mixins: [ StringMixin,TableMixin],
  props: ["dialog", "boardRow",],

  data: () => ({
    headers: [
      { text: "메뉴명",  value: "menuNm", class: "light-blue lighten-4" , align: "center", sortable: true },
      { text: "이름",  value: "memberNm", class: "light-blue lighten-4" , align: "center", sortable: false },
      { text: "나이",  value: "age", class: "light-blue lighten-4" , align: "center", sortable: false },
      { text: "일자별",  value: "contRealDate", class: "light-blue lighten-4" , align: "center", sortable: true },
      { text: "시작사용일자",  value: "createDate", class: "light-blue lighten-4" , align: "center", sortable: false },
      { text: "소속",  value: "joinType", class: "light-blue lighten-4" , align: "center", sortable: false },
      { text: "컨텐츠 명",  value: "contFileTitle", class: "light-blue lighten-4" , align: "center", sortable: false }
    ],
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.$emit("close", value)
      },
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
