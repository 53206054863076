<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <outlined-btn class="float-right" @click="toExcel"  style="margin-left: 10px;" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
      <v-btn @click="isShowDetail" class="light-green white--text theme--light float-right" style="margin-left: 10px;">연계담보등록</v-btn>
      <v-btn @click="dataList('change')" class="light-blue white--text theme--light float-right" style="margin-left: 10px;">변경/삭제</v-btn>
      <v-btn @click="dataList('save')" class="indigo lighten-1 white--text theme--light float-right">신규등록</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="data"
        @click:row="goData"
        :items-per-page="-1"
        fixed-header disable-sort calculate-widths
        :headers="headers"
        :loading="loading"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        ></v-data-table>
    </v-flex>
    <linked-security-main-modal
        :is-show="isShow"
        :division="division"
        :checkDto="checkDto"
        :paramsDto="paramsDto"
        @close="close"
    />
  </v-col>
</template>
<script>
import OutlinedBtn from "../../../Settlement/components/PaymentManage/outlinedBtn";
import func from "@/util/func";
import _ from "lodash";
import LinkedSecurityMainModal from "./LinkedSecurityMainModal";
export default {
  components: {LinkedSecurityMainModal, OutlinedBtn},
  props:["data", "loading", "paramsDto"],
  data:() => ({
    headers: [{text: "보험사", value: "insCd", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "상품코드", value: "prCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "플랜코드", value: "planCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "연계담보구분코드", value: "lkCvrDivCd", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "연계담보구분 설명", value: "lkCvrDivDesc", class: "teal lighten-4", align: "center", sortable: false, width: "30vw"},
      {text: "연계담보구분유형", value: "lkCvrDivType", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "충족조건", value: "satCond", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "등록담보수", value: "cvrCnt", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "적용순서", value: "appOrder", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "등록일시", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "14vw"},
    ],
    isShow:false,
    division:'',
    checkDto:[]
  }),
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  methods: {
    goData(row) {
      this.checkDto = row;
    },
    isShowDetail() {
      if (this.checkDto.length <= 0) {
        alert("데이터를 먼저 선택해주세요.");
        return;
      }
      this.$emit("showDetail", this.checkDto);
    },
    dataList(val) {
      if (val === 'change') {
        if (this.checkDto.length <= 0) {
          alert("데이터를 먼저 선택해주세요.");
          return;
        }
        this.division = 'change';
      } else if (val === 'save') {
        this.checkDto = [];
        if (_.isEmpty(this.paramsDto)) {
          alert("조회를 먼저 선택해주세요.");
          return;
        }
        this.division = 'save';
      }
      this.isShow = true;
    },
    close() {
      this.isShow = false;
      this.checkDto=[];
    },
    toExcel() {
      const dataList = _.cloneDeep(this.data)
      const returnData = [];
      let day = new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '');
      day = day.replaceAll(':', '_');
      for (const item of dataList) {
        const obj = {
          "보험사": item["insCd"],
          "상품코드": item["prCd"],
          "플랜코드": item["planCd"],
          "연계담보구분코드": item["lkCvrDivCd"],
          "연계담보구분 설명": item["lkCvrDivDesc"],
          "연계담보구분유형": item["lkCvrDivType"],
          "충족조건": item["satCond"],
          "등록담보수": item["cvrCnt"],
          "적용순서": item["appOrder"],
          "등록일시": item["createDate"],
        }
        returnData.push(obj);
      }
      const sheetName = `인수지침 연계담보 관리_메인_` + day;
      const fileName = `인수지침 연계담보 관리 List_메인_` + day;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>
<style scoped>
.v-data-table::v-deep td {
  white-space: normal!important;
  word-break: keep-all;
}
</style>