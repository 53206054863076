<template>
  <search-page-layout title="상담사별 실적현황">

    <template v-slot:searchBar>
      <sales-by-officer-search-bar @getSearch="getSearch"/>
    </template>

    <template v-slot:table>
      <sales-by-officer-table
          :key="tablekey"
          :data="data"
          :type="type"
          :loading="loading"
      />
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import SalesByOfficerSearchBar from "@/pages/Settlement/components/SalesByOfficer/SalesByOfficerSearchBar";
import SalesByOfficerTable from "@/pages/Settlement/components/SalesByOfficer/SalesByOfficerTable";
import {axiosInstance} from "@/util/AxiosModule";

// import constant from "@/util/constant" // 테스트용

export default {
  components: {
    SalesByOfficerTable,
    SalesByOfficerSearchBar,
    SearchPageLayout
  },
  data:() => ({
    loading: false,
    data: [],
    type: "",
    tablekey: 0,

    days:[
      {text: "", value: "0"},
      {text: "(월)", value: "1"},
      {text: "(화)", value: "2"},
      {text: "(수)", value: "3"},
      {text: "(목)", value: "4"},
      {text: "(금)", value: "5"},
      {text: "(토)", value: "6"},
      {text: "(일)", value: "7"},
    ]
  }),
  methods: {
    async getSearch(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/operation/csPerformance', {params});
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      // this.data = constant.CsPerformanceDto; // 테스트용

      for (let i in this.data) {
        this.data[i].doW = this.days.find(day => day.value === this.data[i].doW)?.text || "";
        this.data[i].contractDt = this.data[i].contractDt + " " + this.data[i].doW;
      }

      this.type = params.dFlag;
      this.tablekey++;
      this.loading = false;
    }
  }
}
</script>