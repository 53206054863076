<template>
  <v-layout>
    <v-card class="col-12 pa-0" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>

      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">상품군</v-col>
          <v-col cols="7">
            <v-select
                @click="getProdLineNmList"
                v-model="dataDto.prProdLineCd"
                :items="prProdLineCdList"
                :disabled="this.dataDto.prProdLineCd === this.rowData.prProdLineCd"
                background-color="white"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">적용조건</v-col>
          <v-col cols="7">
            <v-select
                @click="getCondDivList"
                v-model="dataDto.condDiv"
                :items="condDivList"
                :disabled="this.dataDto.condDivNm === this.rowData.condDivNm"
                background-color="white"
                item-text="condDivNm"
                item-value="condDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">적용조건세분</v-col>
          <!-- 적용조건이 보험기간일 경우 입력부분 만기 선택으로 변경 -->
          <v-col cols="6">
            <v-select
                v-if="dataDto.condDiv === 'C2'"
                v-model="dataDto.condDetail"
                :items="condDetailInsList"
                :disabled="dataDto.condDetail === this.rowData.condDetail"
                background-color="white"
                item-text="condDetailInsNm"
                item-value="condDetailIns"
                class="subBar"
                hide-details outlined dense
            />

            <!-- 적용조건이 납입기간일 경우 입력부분 년수 선택으로 변경 -->
            <v-select
                v-else-if="dataDto.condDiv === 'C3'"
                @change="changeData('condDiv')"
                :disabled="dataDto.condDetail === this.rowData.condDetail"
                v-model="dataDto.condDetail"
                :items="condDetailList"
                background-color="white"
                item-text="condDetailNm"
                item-value="condDetail"
                class="subBar"
                hide-details outlined dense
            />
            <!-- 적용조건이 갱신주기일 경우 입력부분 년수 선택으로 변경 -->
            <v-select
                v-else-if="dataDto.condDiv === 'C4'"
                @change="changeData('condDiv')"
                :disabled="dataDto.condDetail === this.rowData.condDetail"
                v-model="dataDto.condDetail"
                :items="condDetailRnwList"
                background-color="white"
                item-text="condDetailRnwNm"
                item-value="condDetailRnw"
                class="subBar"
                hide-details outlined dense
            />

            <!-- 적용조건이 갱신/비갱신일 경우 입력부분 갱신/비갱신 선택으로 변경 -->
            <v-select
                v-else-if="dataDto.condDiv === 'C5'"
                v-model="dataDto.condDetail"
                :items="condDetailRenewalList"
                :disabled="dataDto.condDetail === this.rowData.condDetail"
                background-color="white"
                item-text="condDetailRenewNm"
                item-value="condDetailRenew"
                class="subBar"
                hide-details outlined dense
            />
            <v-text-field
                v-else
                v-model="dataDto.condDetail"
                :disabled="dataDto.condDetail === this.rowData.condDetail"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">화면순서</v-col>
          <v-col cols="4">
            <v-text-field
                @change="changeData('dispOrder')"
                v-model="dataDto.dispOrder"
                :disabled="dataDto.dispOrder === this.rowData.dispOrder"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <!-- 적용조건이 담보일 경우 숫자조건을 담보금액(만원)으로 변경 -->
          <v-col v-if="dataDto.condDiv === 'C1'" cols="4">
            담보금액(원)</v-col>
          <v-col v-else cols="4">숫자조건</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.intCond"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">숫자조건명</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.intCondNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">기본값여부</v-col>
          <v-col cols="6">
            <v-select
                v-model="dataDto.defaultYn"
                :items="defaultYnList"
                background-color="white"
                item-text="defaultYnNm"
                item-value="defaultYn"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">사용여부</v-col>
          <v-col cols="4">
            <v-select
                v-model="dataDto.useYn"
                :items="useYnList"
                background-color="white"
                item-text="useYnNm"
                item-value="useYn"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="deleteData"
        >
          초기화
        </v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  props : ["rowData", "prProdLineValue", "condDivValue"],
  data() {
    return {
      dataDto: {
        'prProdLineNm':'',
        'prProdLineCd':'',
        'condDivNm':'',
        'condDiv':'',
        'condDetail':'',
        'dispOrder':'',
        'intCond':'',
        'intCondNm':'',
        'defaultYn':'N',
        'useYn':'Y'
      },
      prProdLineCdList: [
        {prProdLineNm: '선택', prProdLineCd: ''}
      ],
      condDivList: [
        {condDivNm: '선택', condDiv: ''}
      ],
      condDetailList: [
        {condDetailNm: "선택", condDetail:""},
        {condDetailNm: "3년", condDetail:"3년"},
        {condDetailNm: "5년", condDetail:"5년"},
        {condDetailNm: "10년", condDetail:"10년"},
        {condDetailNm: "15년", condDetail:"15년"},
        {condDetailNm: "20년", condDetail:"20년"},
        {condDetailNm: "30년", condDetail:"30년"},
        {condDetailNm: "전기납", condDetail:"전기납"}
      ],
      condDetailInsList: [
        {condDetailInsNm: "선택", condDetailIns: ""},
        {condDetailInsNm: "3년", condDetailIns: "3년"},
        {condDetailInsNm: "5년", condDetailIns: "5년"},
        {condDetailInsNm: "10년", condDetailIns: "10년"},
        {condDetailInsNm: "15년", condDetailIns: "15년"},
        {condDetailInsNm: "20년만기", condDetailIns: "20년만기"},
        {condDetailInsNm: "80세만기", condDetailIns: "80세만기"},
        {condDetailInsNm: "90세만기", condDetailIns: "90세만기"},
        {condDetailInsNm: "100세만기", condDetailIns: "100세만기"},
        {condDetailInsNm: "종신", condDetailIns: "종신"}
      ],
      condDetailRnwList: [
        {condDetailRnwNm: "선택", condDetailRnw:""},
        {condDetailRnwNm: "3년", condDetailRnw:"3년"},
        {condDetailRnwNm: "5년", condDetailRnw:"5년"},
        {condDetailRnwNm: "10년", condDetailRnw:"10년"},
        {condDetailRnwNm: "15년", condDetailRnw:"15년"},
        {condDetailRnwNm: "20년", condDetailRnw:"20년"},
        {condDetailRnwNm: "30년", condDetailRnw:"30년"}
      ],
      condDetailRenewalList: [
        {condDetailRenewNm: "선택", condDetailRenew: ""},
        {condDetailRenewNm: "갱신형", condDetailRenew: "1"},
        {condDetailRenewNm: "비갱신형", condDetailRenew: "2"},
        {condDetailRenewNm: "표준형", condDetailRenew: "4"},
        {condDetailRenewNm: "무해지(0%,50%)", condDetailRenew: "5"},
        {condDetailRenewNm: "연만기", condDetailRenew: "7"},
        {condDetailRenewNm: "세만기,무해지형", condDetailRenew: "A"},
        {condDetailRenewNm: "기본형", condDetailRenew: "C"},
        {condDetailRenewNm: "체증형", condDetailRenew: "D"},
        {condDetailRenewNm: "무해지(0%,50%)_325", condDetailRenew: "E"},
        {condDetailRenewNm: "무해지(0%,50%)_335", condDetailRenew: "F"},
        {condDetailRenewNm: "무해지(0%,50%)_355", condDetailRenew: "H"},
      ],
      defaultYnList: [
        {defaultYnNm: 'Y', defaultYn: 'Y'},
        {defaultYnNm: 'N', defaultYn: 'N'}
      ],
      useYnList: [
        {useYnNm: 'Y', useYn: 'Y'},
        {useYnNm: 'N', useYn: 'N'}
      ],
    }
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  watch: {
    // 목록에서 가져온 데이터 뿌리기
    rowData: function (value) {
      this.dataDto.prProdLineCd = value.prProdLineCd;
      this.dataDto.prProdLineNm = value.prProdLineNm;
      this.dataDto.condDiv = value.condDiv;
      this.dataDto.condDivNm = value.condDivNm;
      this.dataDto.dispOrder = value.dispOrder;
      this.dataDto.condDetail = value.condDetail;
      this.dataDto.intCond = value.intCond;
      this.dataDto.intCondNm = value.intCondNm;
      this.dataDto.defaultYn = value.defaultYn;
      this.dataDto.useYn = value.useYn;

      this.dataDto.prProdLineCd = value.prProdLineCd;
      this.prProdLineCdList[0].prProdLineCd = value.prProdLineCd;
      this.prProdLineCdList[0].prProdLineNm = value.prProdLineNm;

      this.condDivList[0].condDivNm = value.condDivNm;
      this.condDivList[0].condDiv = value.condDiv;
      console.log(">>value", value);
    },
    // 검색바 상품군 데이터 가져오기
    prProdLineValue: function (value) {
      this.dataDto.prProdLineNm = value.prProdLineNm;
      this.dataDto.prProdLineCd = value.prProdLineCd;
      this.prProdLineCdList[0].prProdLineCd = value.prProdLineCd;
      this.prProdLineCdList[0].prProdLineNm = value.prProdLineNm;
    },
    // 검색바 적용조건 데이터 가져오기
    condDivValue: function (value) {
      this.dataDto.condDivNm = value.condDivNm;
      this.dataDto.condDiv = value.condDiv;
      this.condDivList[0].condDivNm = value.condDivNm;
      this.condDivList[0].condDiv = value.condDiv;
    },
  },
  methods: {
    changeData(val) {
      if (val === 'condDiv') {
        if (this.dataDto.condDetail === "전기납") {
          this.dataDto.intCond = "99";
        } else {
          this.dataDto.intCond = "";
        }
      }else if (val === 'dispOrder') {
        if (this.dataDto.dispOrder === "1") {
          this.dataDto.defaultYn = "Y";
        } else {
          this.dataDto.defaultYn = "N";
        }
      }
    },
    // 상품군리스트 가져오기
    async getProdLineNmList() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineCdList.shift();
    },
    // 적용조건리스트 가져오기
    async getCondDivList() {
      this.condDivList = await ProductRecommend.getCondDiv();
    },
    // 저장
    async saveData() {
      const prProdLineCondTbDto = {
        prProdLineCd: this.dataDto.prProdLineCd,
        condDiv: this.dataDto.condDiv,
        condDetail: this.dataDto.condDetail,
        dispOrder: this.dataDto.dispOrder,
        intCond: this.dataDto.intCond,
        intCondNm: this.dataDto.intCondNm,
        defaultYn: this.dataDto.defaultYn,
        useYn: this.dataDto.useYn,
      }
      console.log(prProdLineCondTbDto);
      try {
        await axiosInstance.post('/admin/prdata/prProdLineCondSave', prProdLineCondTbDto);
        alert("저장되었습니다.");
        this.dataDto = [];
        this.dataDto.defaultYn = 'Y';
        this.dataDto.useYn = 'Y';
      } catch (e) {
        console.log(e);
      }
    },
    // 초기화
    deleteData() {
      this.dataDto.condDetail = "";
      this.dataDto.dispOrder = "";
      this.dataDto.intCond = "";
      this.dataDto.intCondNm = "";
      this.dataDto.defaultYn = 'N';
      this.dataDto.useYn = 'Y';
    }
  }
}
</script>