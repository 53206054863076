<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 ({{ tableTitle }})</span>
      <outlined-btn class="float-right" @click="$emit('toExcel')" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>

    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          v-model="selectData"
          :items="data"
          item-key="index"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      > <!-- 제외 옵션: show-select multi-sort="multi-sort" -->
        <template v-slot:item.contractPremAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.reward="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.customerTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <template v-slot:item.telNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <template v-slot:item.contractDate="{ value }">
          <div class="justify-center">{{ setDateBySlash(value) }}</div>
        </template>
        <template v-slot:item.expiryDate="{ value }">
          <div class="justify-center">{{ setDateBySlash(value) }}</div>
        </template>
        <template v-slot:item.payCompleteDate="{ value }">
          <div class="justify-center">{{ setDateBySlash(value) }}</div>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {REALTIME_CONTRACT_TABLE_HEADER} from "@/util/TableHeaders";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";

export default {
  name: "RealtimeContractTable",
  components: {OutlinedBtn},
  mixins: [TableMixin],
  props: ["data", "loading"],
  computed: {
    hasTableData() {
      this.tableTitleUpdate();
      return this.data.length > 0;
    },
  },
  data: () => ({
    headers: REALTIME_CONTRACT_TABLE_HEADER,
    isShow: false,
    isModify: false,
    modalData: {},
    tableTitle: "총 0개",
    selectData: [],
    isModifyModal: false
  }),
  methods: {
    tableTitleUpdate(){
      let cnt = this.data.length;

      if(cnt == 0)
      {
        this.tableTitle = "총 0개";
      }
      else
      {
        //금액합산
        const summary = this.data.reduce((a, c) => ({
          contractPremAmt: a.contractPremAmt + c.contractPremAmt,
          reward: a.reward + c.reward}))

        this.tableTitle = "총 " + this.data.length + "개 | 계약보험료합 "
            + this.inputComma(summary.contractPremAmt) + "원 | 리워드합 "
            + this.inputComma(summary.reward) + "원";
      }
    },
  }
}
</script>

