<template>
  <search-page-layout title="니즈환기자료">
    <template v-slot:searchBar>
      <!-- NeedsinfoSearchBar @getOrgInfo="getOrgInfo"  @orgBatch="orgBatch" @deleteInfo="deleteInfo"/ -->
      <NeedsinfoSearchBar
          @getneedsInfo="getneedsInfo"
          @deleteInfo="deleteInfo"
          @regist="dialog=true"
      />
    </template>
    <template v-slot:table>
      <NeedsinfoTable
          :infosList="infosList"
          :loading="loading"
          :sparams="sparams"
          @refreshSetting="refreshNeedsInfoAPI"
      />
      <needs-modal
          :dialog="dialog"
          @close="dialog = false"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import NeedsinfoTable from "@/pages/Manage/NeedsInfo/components/NeedsinfoTable";
import NeedsinfoSearchBar from "@/pages/Manage/NeedsInfo/components/NeedsinfoSearchBar";
import NeedsModal from "@/pages/Manage/NeedsInfo/components/NeedsModal";

export default {
  components: {
    SearchPageLayout,
    NeedsinfoTable,
    NeedsinfoSearchBar,
    NeedsModal
  },
  data: () => ({
    dialog: false,
    loading: false,
    infosList: [],
    deleteList: [],
    sparams : {}
  }),
  async created() {
    //	await this.refreshNeedsInfoAPI();
  },
  methods: {
    async getneedsInfo(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/operation/needsinfo', { params });
        this.infosList = data;
        this.sparams = params;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async refreshNeedsInfoAPI(params) {
      // 갱신 API
      this.sparams = params
      console.log(this.sparams)
      try{
        const {data} = await axiosInstance.get("/admin/operation/needsinfo", { params });
        this.infosList = data;
        this.deleteList = []
      }
      catch(e) {
        console.log(e)
        alert("컨텐츠 갱신에 실패했습니다.")
      }
    },
    // 자료 삭제
    async deleteInfo() {
      // 삭제 API
      var queryParams = this.deleteList.join(",")
      this.deleteList.join(",")

      const params = {seq: queryParams}
      try {
        await axiosInstance.delete("/admin/operation/needsinfo", {params});
        await this.refreshNeedsInfoAPI()
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    setDialog (value) {
      this.dialog = value
    }
  },

}
</script>