<template>
  <v-col cols="12" class="py-0">
    <span class="text-h6">목록 (총 {{ data.length }}개)</span>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(header) || []"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          :item-class="getBlue"
      >
        <template v-slot:item.기준일자="{ value }">
          {{
            value.length === 8
                ? `${value.slice(0,4)}/${value.slice(4,6)}/${value.slice(6,8)}`
                : value
          }}
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";

export default {
  mixins: [TableMixin],
  props:["data", "loading", "header"],
  methods: {
    getBlue(item) {
      if(item["기준일자"] === '총합') return "sumRow";
    }
  }
}
</script>

<style scoped>

</style>