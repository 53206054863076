var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"my-2"},[_c('span',{staticClass:"text-h6"},[_vm._v("목록 (총 "+_vm._s(_vm.tableData.length)+"개)")]),_c('v-btn',{staticClass:"blue white--text theme--light float-right marginBtn",on:{"click":_vm.showUpdateModal}},[_vm._v("업로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.tableData,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.getHeader(_vm.headers),"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},on:{"click:row":_vm.clickContents},scopedSlots:_vm._u([{key:"item.tbInsCnt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.exptCnt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.apStDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateFormat(value)))])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.replaceCreate(value)))])]}}])})],1),_c('product-recommendation-modal',{attrs:{"is-show":_vm.isShow},on:{"close":function($event){_vm.isShow = false}}}),_c('product-detail-modal',{attrs:{"is-show":_vm.showModal,"detailData":_vm.detailData,"listData":_vm.listData},on:{"close":function($event){_vm.showModal = false},"updateSetting":function($event){return _vm.updateSetting()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }