<template>
  <v-col cols="12" class="py-2 px-2">
    <div>
      <v-btn
          class="float-right grey white--text"
          @click="toExcel"
          style="margin: 10px 5px 0 0;"
          :disabled="!hasTableData"
      >엑셀다운로드</v-btn>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-6 col-12">
          <div class="monthlyPremText">
            <span>보험료계: </span>
            <span
                class="jc-fe"
            >
              {{inputComma(monthlyPremSum)+ "원"}}
            </span>
          </div>
        </v-col>
      </search-area>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        v-if="isWholeLife"
        height="40vh"
        :items="data"
        :loading="loading"
        :headers="getHeader(headers)"
        fixed-header calculate-widths
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        :items-per-page="-1"
      >
        <template v-slot:item.sex="{ value }">
          <div>{{ changedData(value, "1", "남자") }}</div>
          <div>{{ changedData(value, "2", "여자") }}</div>
        </template>
        <template v-slot:item.cvrDiv="{ value }">
          <div>{{ changedData(value, "1", "의무가입") }}</div>
          <div>{{ changedData(value, "2", "선택특약") }}</div>
        </template>
        <template v-slot:item.accAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.monthlyPrem="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.refund="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.cvrNm="{ value }">
          <div>{{ textLengthOverCut(value, 30, "...") }}</div>
        </template>
      </v-data-table>

      <v-data-table
          v-else
          height="40vh"
          :items="data"
          :loading="loading"
          :headers="getHeader(headers)"
          fixed-header calculate-widths
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          :items-per-page="-1"
      >
        <template v-slot:item.sex="{ value }">
          <div>{{ changedData(value, "1", "남자") }}</div>
          <div>{{ changedData(value, "2", "여자") }}</div>
        </template>
        <template v-slot:item.cvrDiv="{ value }">
          <div>{{ changedData(value, "1", "의무가입") }}</div>
          <div>{{ changedData(value, "2", "선택특약") }}</div>
        </template>
        <template v-slot:item.accAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.monthlyPrem="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.cvrNm="{ value }">
          <div>{{ textLengthOverCut(value, 30, "...") }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import {PRODUCT_COND_PREM_TABLE, PRODUCT_COND_PREM_WHOLE_LIFE_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import SearchArea from "@/components/Search/SearchArea";
import func from "@/util/func";
import ProductRecommend from "@/util/ProductRecommend";
import constant from "@/util/constant";

export default {
  components: {SearchArea},
  props: ["nowPrProdLineCd", "data", "loading", "monthlyPremSum", "excelData"],
  mixins: [TableMixin],
  async created() {
    this.prProdLineList =  await ProductRecommend.getPrProdLineCd();
    this.insList = await ProductRecommend.getInsCd();
    this.headers = this.defaultHeaders;
  },
  data() {
    return {
      isWholeLife: false,
      lowWholeLifeList: constant.LOW_WHOLE_LIFE_LIST,
      prProdLineList: [],
      insList: [],
      headers: [],
      defaultHeaders: PRODUCT_COND_PREM_TABLE,
      wholeLifeHeaders: PRODUCT_COND_PREM_WHOLE_LIFE_TABLE,
      insTrmSub: "",
      pyTrmSub: "",
      date: new Date()
    }
  },
  watch: {
    nowPrProdLineCd(value) {
      if (this.lowWholeLifeList.includes(value)) {
        this.isWholeLife = true;
        this.headers = this.wholeLifeHeaders;
      } else {
        this.isWholeLife = false;
        this.headers = this.defaultHeaders;
      }
    },
    data: function (value) {
      for (let i = 0; i < value.length; i++) {
        this.insTrmSub = value[i].insTrmSub;
        this.pyTrmSub = value[i].pyTrmSub;
      }
    }
  },
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  methods: {
    toExcel() {
      const returnData = [];

      let prProdLineNm = this.prProdLineList.find(x => x.prProdLineCd === this.excelData.prProdLineCd).prProdLineNm;
      let insNm = this.insList.find(x => x.insCd === this.excelData.insCd).insCdNm;
      let nowDate = func.getToday(this.date);

      for (const item of this.data) {
        let sex = item["sex"];
        let cvrDiv = item["cvrDiv"];
        // 성별 데이터 변환
        if (sex === "1") {
          sex = "남자";
        } else if (sex === "2") {
          sex = "여자";
        }

        // 담보구분 데이터 변환
        if (cvrDiv === "1") {
          cvrDiv = "의무가입";
        } else if (cvrDiv === "2") {
          cvrDiv = "선택특약";
        }

        if (this.isWholeLife) {
          const obj = {
            "상품코드": item["prCd"],
            "나이": item["age"],
            "성별": sex,
            "담보구분": cvrDiv,
            "담보코드": item["cvrCd"],
            "담보명": item["cvrNm"],
            "가입금액": item["accAmt"],
            "가입금액명": item["accAmtNm"],
            "년수": item["elapsedYear"],
            "환급금": item["refund"],
            "환급금명": item["refundNm"],
            "환급률": item["refundRate"],
            "담보보험기간": item["insTrmSub"],
            "담보납입기간": item["pyTrmSub"],
            "담보갱신주기": item["rnwCycleSub"],
            "월보험료": item["monthlyPrem"],
          }
          returnData.push(obj);
        } else {
          const obj = {
            "상품코드": item["prCd"],
            "나이": item["age"],
            "성별": sex,
            "담보구분": cvrDiv,
            "담보코드": item["cvrCd"],
            "담보명": item["cvrNm"],
            "가입금액": item["accAmt"],
            "가입금액명": item["accAmtNm"],
            "담보보험기간": item["insTrmSub"],
            "담보납입기간": item["pyTrmSub"],
            "담보갱신주기": item["rnwCycleSub"],
            "월보험료": item["monthlyPrem"],
          }
          returnData.push(obj);
        }
      }
      const sheetName = `조건별 보험료_` + prProdLineNm + "_" + insNm + "_" + nowDate;
      const fileName = `조건별 보험료_` + prProdLineNm + "_" + insNm + "_" + nowDate;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style>
.monthlyPremText {
  font-weight: bold;
  font-size: 20px;
  margin-left: -20px;
}
</style>