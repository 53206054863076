export const KEY_CODE_HEADER = [
	["순번", "no"],
	["코드명", "cd"],
	["값", "value"],
	["사용 여부", "use"],
	["생성일", "createDate"],
	["수정일", "updateDate"],
];

export const JSON_CODE_HEADER = [
	["코드명", "cd"],
	["값", "value"],
	["사용 여부", "use"],
	["생성일", "createDate"],
	["수정일", "updateDate"],
];

export const SEARCH_CODE_HEADER = [
	["구분", "type"],
	["코드 ID", "cdMasterId"],
	["코드 설명", "cdDesc"],
	["생성일", "createDate"],
	["수정일", "updateDate"],
];

export const ADMIN_MANAGE_HEADER = [
	["이름", "name"],
	["아이디(이메일)", "memberId"],
	["휴대폰번호", "telNum"],
	["메뉴접근권한", "menu"],
	["메뉴접근권한 수정", "menuBtn"],
]

export const USER_SEARCH_HEADER = [
	["회원명", "memberNm"],
	["이메일", "email"],
	["생년월일", "birthday"],
	["통신사", "telecomCompany"],
	["휴대폰번호", "telNum"],
	["은행명", "bankNm"],
	["계좌번호", "bankAccount"],
	["추천인명", "inviterNm"],
	["소속", "joinType"],
	["초대코드", "inviteCd"],
	["가입일자", "createDate"],
	["보험사 유입이력", "inflowList"],
	["보험료 산출이력", "inforList"],
	["iims 유입 테스트", "iimsDetail"],
	["정보변경", "modify"],
	["비밀번호 초기화", "resetPassword"],
];

export const USER_STATUS_HEADER = [
	["기준일자", "기준일자"],
	["신규회원", "joinMember"],
	["계좌입력회원", "accountMember"],
];

export const USER_IIMS_HEADER = [
	["회원명", "inviterNm"],
	["회원전화번호", "inviterTelNum"],
	["고객명", "customerNm"],
	["고객전화번호", "customerTelNum"],
	["차량번호", "customerCarNo"],
	["차대번호", "customerCarIdentificationNo"],
	["조회일시", "createDate"]
];

export const EVENT_FIRST_CONTRACT_HEADER = [
	["회원명", "memberNm1"],
	["이메일", "email1"],
	["전화번호", "telNum1"],
	["가입일시", "regDate1"],
	["최초계약일시", "firstContractDate1"],
	["최종계약일시", "lastContractDate1"],
	["실적건수", "contractCnt1"]
];

export const EVENT_INVITER_HEADER = [
	["추천인", "memberNm1"],
	["추천인_이메일", "email1"],
	["추천인_전화번호", "telNum1"],
	["추천인_가입일시", "regDate1"],
	["추천인_최초계약일시", "firstContractDate1"],
	["추천인_최종계약일시", "lastContractDate1"],
	["추천인_실적건수", "contractCnt1"],
	["피추천인", "memberNm2"],
	["피추천인_이메일", "email2"],
	["피추천인_전화번호", "telNum2"],
	["피추천인_가입일시", "regDate2"],
	["피추천인_최초계약일시", "firstContractDate2"],
	["피추천인_최종계약일시", "lastContractDate2"],
	["피추천인_실적건수", "contractCnt2"],
	["피추천인_링크건수", "sendCnt2"]
];

export const EVENT_CONTINUOUS_OPERATION_HEADER = [
	["회원명", "memberNm1"],
	["이메일", "email1"],
	["전화번호", "telNum1"],
	["가입일시", "regDate1"],
	["회사", "joinType"],
	["시상건수", "cnt1"],
	["합계", "tot"],
	["전년1월", "preM1"],
	["전년2월", "preM2"],
	["전년3월", "preM3"],
	["전년4월", "preM4"],
	["전년5월", "preM5"],
	["전년6월", "preM6"],
	["전년7월", "preM7"],
	["전년8월", "preM8"],
	["전년9월", "preM9"],
	["전년10월", "preM10"],
	["전년11월", "preM11"],
	["전년12월", "preM12"],
	["1월", "m1"],
	["2월", "m2"],
	["3월", "m3"],
	["4월", "m4"],
	["5월", "m5"],
	["6월", "m6"],
	["7월", "m7"],
	["8월", "m8"],
	["9월", "m9"],
	["10월", "m10"],
	["11월", "m11"],
	["12월", "m12"]
];

export const EVENT_CONVERT_MEMBER_HEADER = [
	["회원명", "memberNm1"],
	["이메일", "email1"],
	["전화번호", "telNum1"],
	["가입일시", "regDate1"],
	["전환일시", "convertDate1"]
];

export const EVENT_MISILJEUK_LINK_SEND_HEADER = [
	["회원명", "memberNm1"],
	["이메일", "email1"],
	["전화번호", "telNum1"],
	["최초전달일", "firstSendDate1"],
];
export const  EVENT_QUIZ_CONTESTANT_HEADER = [
	["회원명", "memberNm1"],
	["이메일", "email1"],
	["전화번호", "telNum1"],
	["참가일시", "regDate1"],
];
export const  EVENT_PRODUCT_RECOMMEND_HEADER = [
	["회원명", "memberNm1"],
	["이메일", "email1"],
	["전화번호", "telNum1"],
	["최초비교시간", "regDate1"],
	["링크전송여부", "linkYn"],
	["최초전송시간", "firstSendDate1"],
];
export const  EVENT_KATALK_FRIEND_HEADER = [
	["회원명", "memberNm1"],
	["이메일", "email1"],
	["전화번호", "telNum1"],
	["가입일시", "regDate1"],
	["회사", "joinType"],
	["설계지원 일자", "designSupDate"]
];
export const  EVENT_LOTTERY_EXCEL_UPLOAD_MODAL_TABLE = [
	["이벤트", "eventType"],
	["이벤트년월", "yyyymm"],
	["이벤트회차", "eventNum"],
	["대상자명", "memberNm"],
	["전화번호", "telNum"],
	["시상종류", "awardingType"],
	["건수", "cnt"],
	["단가", "unitPrice"],
];
export const USER_INFLOW_HEADER = [
	["서비스구분", "serviceType"],
	["회원명", "inviterNm"],
	["회원 전화번호", "inviterTelNum"],
	["차량번호", "customerCarNo"],
	["차대번호", "customerCarIdentificationNo"],
	["보험사", "joinInsurerCd"],
	["유입 페이지", "joinType"],
	["유입채널", "joinChannel"],
	["고객명", "customerNm"],
	["고객 전화번호", "customerTelNum"],
	["보험사 유입일시", "createDate"],
	["조기정산ID", "earlySettlementHistId"],
	["이륜차여부", "twoWheelerYn"],
	["법입계약여부", "corpContYn"]
];

export const USER_CALCULATE_HEADER = [
	["회원명", "회원명"],
	["보험료 산출일","보험료 산출일"]
];

export const ADMIN_REGISTER_HEADER = [
	["이름", "name"],
	["아이디(이메일)", "memberId"],
	["휴대폰번호", "telNum"],
	["관리자여부", "isAdmin"],
	["관리자 추가/삭제", "adminBtn"],
];

export const CAR_INS_COMPANY_HEADER = [
	["Codef명", "externalInsurerNm"],
	["내부정산명", "insurerCd"],
	["상품유형", "insProductType"],
	["상품구분", "type"],
	["노출여부", "view"],
	["제휴사여부", "partner"],
	["전화가입", "telephone"],
	["전화번호", "telNum"],
	["인터넷가입", "internet"],
	["PCURL", "pcUrl"],
	["모바일URL", "mobileUrl"]
]

export const OTHER_INS_COMPANY_HEADER = [
	["Codef명", "externalInsurerNm"],
	["내부정산명", "insurerCd"],
	["상품유형", "insProductType"],
	["상품구분", "type"],
	["노출여부", "view"],
	["제휴사여부", "partner"],
	["전화가입", "telephone"],
	["전화번호", "telNum"],
	["인터넷가입", "internet"],
	["PCURL", "pcUrl"],
	["모바일URL", "mobileUrl"]
]

export const PARTNER_MANAGE_HEADER = [
	["사업자명", "businessNm"],
	["아이디", "loginId"],
	["사업자번호", "businessNum"],
	["대표자명", "representNm"],
	["대표번호", "representTelNum"],
	["제휴 담당자명", "partnerChargeNm"],
	["제휴 담당자 전화번호", "partnerChargeTelNum"],
	["정산타입", "settlementType"],
];

export const RENCAR_SETTLEMENT_HEADER = [
	["기준년월", "yyyymm"],
	["작업타입", "workerType"],
	["상태", "status"],
	["요청내용", "reqMsg"],
	["차량번호", "carNo"],
	["요청일시", "reqDate"],
	["선택일시", "selectDate"],
	["배차일시", "regDate"],
	["반납일시", "returnDate"],
	["중계사이트명", "siteNm"],
	["중계사명", "companyNm"],
	["지역1", "location1"],
	["지역2", "location2"],
	["제안차종", "proposalCarType"],
	["배차업체", "regCompany"],
	["고객차종", "custCarType"],
	["확정금액", "contractPremAmt"],
	["예상금액", "exRewardAmt"],
	["애인광고비", "ainRewardAmt"],
	["광고비", "rewardAmt"],
	["최초중계사이트명", "firstSiteNm"],
	["국상_외산", "domesticOverseas"],
	["취소사유", "cancelReason"],
	["광고비타입", "rewardType"],
	["비고", "remark"],
	["지급대상", "rewardStatus"],
	["지급타입", "payMethod"]
];

export const SETTLEMENT_HEADER = [
	["보험사", "insurerCd"],
	["계약일자", "contractDate"],
	["계약채널", "joinChannel"],
	["차량번호", "insuredCarNo"],
	["보험료", "premiumAmt"],
	["피보험자", "insuredNm"],
	["피보험자 번호", "insuredTelNum"],
	["계약자", "contractorNm"],
	["계약자 번호", "contractorTelNum"],
	["에인 연결 가입자 아이디", "joinHistId"],
	["증권번호", "policyNumber"],
	["만기일자", "expiryDate"],
	["납입구분", "paymentType"],
	["환수금액", "refundAmt"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
];

export const SETTLEMENT_DATA_HEADER = [
	["보험사", "contractInsurerNm"],
	["계약일자", "contractDate"],
	["계약채널", "joinChannel"],
	["차량번호", "insuredCarNo"],
	["보험료", "contractPremAmt"],
	["광고수수료", "contractFee"],
	["피보험자", "insuredNm"],
	["피보험자 번호", "insuredTelNum"],
	["계약자", "contractorNm"],
	["계약자 번호", "contractorTelNum"],
	["에인연결ID", "ainLinkId"],
	["증권번호", "policyNo"],
	["만기일자", "expiryDate"],
	["납입구분", "payMethodType"],
	["정산매핑ID", "carSettlementHistId"]

];



export const SETTLEMENT_TOTAL_HEADER = [
	["보험사", "contractInsurerNm"],
	["계약채널", "joinChannel"],
	["채널건수", "joinChannelCnt"],
	["정산매핑건수", "settlementIdCnt"],
	["차량번호건수", "insuredCarNoCnt"],
	["보험료합계", "contractPremAmt"],
	["광고수수료합계", "contractFee"],
	["피보험자건수", "insuredNmCnt"],
	["피보험자번호건수", "insuredTelNumCnt"],
	["계약자건수", "contractorNmCnt"],
	["계약자번호건수", "contractorTelNumCnt"],
	["에인연결ID건수", "ainLinkIdCnt"],
	["증권번호건수", "policyNoCnt"],
	["만기일자건수", "expiryDateCnt"],
	["연납구분건수", "payMethodYearCnt"],
	["월납구분건수", "payMethodMonthCnt"]

];

export const VERIFIED_HEADER = [
	["조기정산ID", "earlySettlementHistId"],
	["정산타겟", "settlementTarget"],
	["추천인", "recipientNm"],
	["리워드 타입", "rewardType"],
	["리워드 금액", "rewardAmt"],
	["보험사", "contractInsurer"],
	["계약일자", "contractDate"],
	["계약채널", "joinChannel"],
	["차량번호", "insuredCarNo"],
	["보험료", "contractPrem"],
	["피보험자", "insuredNm"],
	["피보험자 전화번호", "insuredTelNum"],
	["매칭된 고객명", "customer.nm"],
	["매칭된 전화번호", "customer.telNum"],
	["매칭된 차량번호", "customerCarNo"],
	["만기일자", "expiryDate"],
	["납입구분", "paymentType"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
];

export const RENCAR_EXCEL_HEADER = [
	["worker_type", "workerType"],
	["진행상태", "status"],
	["추가요청사항", "reqMsg"],
	["고객차량번호", "carNo"],
	["요청일시", "reqDate"],
	["선택일시", "selectDate"],
	["배차일시", "regDate"],
	["반납일시", "returnDate"],
	["realname", "siteNm"],
	["company", "companyNm"],
	["location1", "location1"],
	["location2", "location2"],
	["제안차종", "proposalCarType"],
	["배차업체", "regCompany"],
	["고객차종", "custCarType"],
	["확정금액", "contractPremAmt"],
	["예상수수료(12%)", "exRewardAmt"],
	["first_request_user_realname", "firstSiteNm"],
	["국산/수입구분", "domesticOverseas"],
	["원클릭다이렉트계정정보", "memberId"],
	["취소사유", "cancelReason"]
]

export const EXCEL_HEADER = [
	["정산기준", "settlementDate"],
	["보험사", "insurerCd"],
	["보험료", "premiumAmt"],
	["계약일자", "contractDate"],
	["계약채널", "joinChannel"],
	["계약자명", "contractorNm"],
	["계약자번호", "contractorTelNum"],
	["피보험자", "insuredNm"],
	["피보험자번호", "insuredTelNum"],
	["차량번호", "insuredCarNo"],
	["에인연결가입자아이디", "joinHistId"],
	["증권번호", "policyNumber"],
	["만기일자", "expiryDate"],
	["리워드타입", "rewardType"],
	["납입구분", "paymentType"],
	["환수금액", "refundAmt"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
]

export const PAYMENT_HEADER = [
	["기준연월", "settlementDate"],
	["지급이력", "hasSuccess"],
	["회원명", "recipientNm"],
	["휴대폰번호", "telNum"],
	["건수", "rowCount"],
	["발생금액", "plusReward"],
	["환수금액", "minusReward"],
	["지급액", "sumReward"],
	["주민등록번호", "ssno"],
	["은행명", "bankNm"],
	["계좌번호", "bankAccount"],
	["상태", "rewardStatusValue"],
	["비고", "remark"],
]

export const NEW_PAYMENT_HEADER = [
	["기준연월", "settlementDate"],
	["회원명", "recipientNm"],
	["회원전화번호", "telNum"],
	["지급이력", "hasSuccess"],
	["지급예정일", "payDate"],
	["조기지급건수", "ecnt"],
	["환수대상건수", "hcnt"],
	["월정산건수", "mcnt"],
	["지급건수", "scnt"],
	["조기지급액", "erewardAmt"],
	["환수대상액", "hrewardAmt"],
	["월정산액", "mrewardAmt"],
	["지급액", "srewardAmt"],
	["주민등록번호", "ssno"],
	["은행명", "bankNm"],
	["계좌번호", "bankAccount"],
	["상태", "rewardStatusValue"],
	["비고", "remark"],
]

export const NEW_PAYMENT_DETAIL_HEADER = [
	["지급구분", "payType"],
	["회원명", "recipientNm"],
	["회원 전화번호", "recipientTelNum"],
	["고객명", "customerNm"],
	["고객 전화번호", "customerTelNum"],
	["고객 차량번호", "customerCarNo"],
	["상태", "rewardStatus"],
	["최종상태", "endState"],
	["점검확인", "checkResultYn"],
	["보험사", "contractInsurer"],
	["계약채널", "joinChannel"],
	["계약보혐료", "contractPremAmt"],
	["리워드", "rewardAmt"],
	["계약일", "contractDate"],
	["지급예정일", "payDueDate"],
	["지급완료일", "payCompleteDate"],
	["비고", "remark"],
	["설계지원번호", "designSupId"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"],
	["조기정산ID", "earlySettlementHistId"]
]

export const SETTLEMENT_DETAIL = [
	["서비스구분", "serviceType"],
	["조기정산ID", "earlySettlementHistId"],
	["가입자명", "insuredNm"],
	["가입자 전화번호", "insuredTelNum"],
	["가입자 차량번호", "insuredCarNo"],
	["리워드타입", "rewardType"],
	["회원명", "recipientNm"],
	["회원 전화번호", "recipientTelNum"],
	["고객명", "customerNm"],
	["고객 전화번호", "customerTelNum"],
	["고객 차량번호", "customerCarNo"],
	["상태", "rewardStatus"],
	["점검결과", "checkResult"],
	["점검확인", "checkResultYn"],
	["보험사", "contractInsurer"],
	["계약채널", "joinChannel"],
	["계약보혐료", "contractPremAmt"],
	["리워드", "rewardAmt"],
	["계약일", "contractDate"],
	["만기일", "expiryDate"],
	["지급예정일", "payDueDate"],
	["지급완료일", "payCompleteDate"],
	["비고", "remark"],
	["설계지원여부", "designSupYn"],
	["설계지원번호", "designSupId"],
	["상담사", "csNm"],
	["진행자", "ctCsNm"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
];

export const  SETTLEMENT_DETAIL_MODIFY_TABLE = [
	["정산ID", "carSettlementHistId"],
	["회원ID", "recipientId"],
	["회원명", "recipientNm"],
	["가입사", "contractInsurer"],
	["가입채널", "joinChannel"],
	["실적년월", "settlementDate"],
	["보험료", "contractPremAmt"],
	["차량번호", "customerCarNo"],
	["고객명", "customerNm"],
	["계약일자", "payDueDate"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
];

export const  SETTLEMENT_DETAIL_EARLY_SETTLE_TABLE = [
	["정산ID", "carSettlementHistId"],
	["회원ID", "recipientId"],
	["회원명", "recipientNm"],
	["가입사", "contractInsurer"],
	["가입채널", "joinChannel"],
	["실적년월", "settlementDate"],
	["보험료", "contractPremAmt"],
	["차량번호", "customerCarNo"],
	["고객명", "customerNm"],
	["계약일자", "payDueDate"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
];

export const  SETTLEMENT_DETAIL_EARLY_SETTLE_ROW_TABLE = [
	["회원명", "inviterNm"],
	["회원 전화번호", "inviterTelNum"],
	["차량번호", "customerCarNo"],
	["차대번호", "customerCarIdentificationNo"],
	["보험사", "joinInsurerCd"],
	["유입 페이지", "joinType"],
	["유입채널", "joinChannel"],
	["고객명", "customerNm"],
	["고객 전화번호", "customerTelNum"],
	["보험사 유입일시", "createDate"],
	["조기정산ID", "earlySettlementHistId"],
	["이륜차여부", "twoWheelerYn"],
	["법입계약여부", "corpContYn"]
];

export const  SETTLEMENT_DETAIL_EARLY_SETTLE_JOIN_TABLE = [
	["조기정산ID", "earlySettlementHistId"],
	["설계사명", "recipientNm"],
	["설계사전화번호", "recipientTelNum"],
	["고객명", "customerNm"],
	["차량번호", "customerCarNo"],
	["전화번호", "customerTelNum"],
	["가입금액", "contractPremAmt"],
	["상태", "rewardStatus"]
];

export const REALTIME_CONTRACT_TABLE_HEADER = [
	["회원명", "memberNm"],
	["회원 연락처", "telNum"],
	["고객명", "customerNm"],
	["고객 연락처", "customerTelNum"],
	["고객 차량번호", "customerCarNo"],
	["보험사", "insCd"],
	["이전보험사", "preInsurer"],
	["계약보험료", "contractPremAmt"],
	["리워드", "reward"],
	["지급일자", "payCompleteDate"],
	["계약일", "contractDate"],
	["만기일자", "expiryDate"],
	["계약채널", "joinChannel"],
	["설계지원번호", "designSupId"],
	["상담자", "csNm"],
	["진행자", "ctCsNm"],
	["DB유형", "infoServiceTypeList"],
	["입력자", "regNm"],
	["코드4자리", "hdCode"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
	// ["요청일자", "contractDate"],
	// ["계약현황", ""]
]

export const CAR_EXPIR_INFO_HEADER = [
	["회원명", "recipientNm"],
	["전화번호", "telNum"],
	["고객명", "customerNm"],
	["고객차량번호", "customerCarNo"],
	["계약보험사", "contractInsurer"],
	["계약보험료", "contractPremAmt"],
	["계약일자", "contractDt"],
	["계약예정일자", "contractDueDt"],
	["만기일자", "expiryDt"],
	["작업단계", "jobStep"],
	["작업일자", "createDate"],
	["안내단계", "infoStep"],
	["안내일자", "updateDate"]
]

export const CAR_EXPIR_INFO_JOB_HEADER = [
	["일자", "calDt"],
	["요일", "calWeekNm"],
	["휴일여부", "holiDayYn"],
	["생성건수", "createCnt"],
	["1차안내일자", "firstInfoDt"],
	["1차안내건수", "firstInfoCnt"],
	["2차안내일자", "secondInfoDt"],
	["2차안내건수", "secondInfoCnt"],
	["3차안내일자", "thirdInfoDt"],
	["3차안내건수", "thirdInfoCnt"]
]

export const CHECK_MEMBER_HEADER = [
	["전화번호", "telNum"],
	["회원명", "memberNm"],
	["Email", "email"],
]

export const ORGINFO_HEADER = [
	["기준년월", "yyyymm"],
	["조직코드", "orgCd"],
	["조직명", "orgNm"],
	["상위조직코드", "upOrgCd"],
	["상위조직명", "upOrgNm"],
	["총괄조직코드", "cgOrgCd"],
	["총괄조직명", "cgOrgNm"],
	["부문조직코드", "bmOrgCd"],
	["부문조직명", "bmOrgNm"],
	["부문총괄조직코드", "bmcOrgCd"],
	["부문총괄조직명", "bmcOrgNm"],

];
export const  SALES_LIST_HEADER = [
	["부문총괄", "bmcOrgNm"],
	["부문", "bmOrgNm"],
	["총괄", "cgOrgNm"],
	["소속", "belongNm"],
	["파트너", "partnerNm"],
	["부서", "deptNm"],
	["FA명", "orgNm"],
	["FA보유수", "tfaCnt"],
	["실적발생자", "faCnt"],
	["거수보험료", "prem"],
	["실적건수", "salesCnt"],
	["정산건수", "settleCnt"],
	["광고비", "reward"],
	["정산광고비", "pyReward"]
];
export const  PRODUCT_RECOMMEND_HEADER = [
	["보험사", "insCd"],
	["상품추천테이블ID", "prTbId"],
	["테이블명", "prTbNm"],
	["상품코드", "prCd"],
	["작업번호", "wkNo"],
	["테이블입력건", "tbInsCnt"],
	["예상건수", "exptCnt"],
	["작업 순서", "wkOrder"],
	["사전생성테이블", "prePrTbId"],
	["적용일자", "apStDate"],
	["생성일자", "createDate"],
	["유효여부", "useYn"],
	["상태", "stat"]
];
export const  DESIGN_SUPPORT_STATUS_TABLE = [
	["일자", "stsDt"],
	["요청건(a+b)", "totCnt"],
	["FA요청건(a)", "faCnt"],
	["센터입력건(b)", "centCnt"],
	["미처리건", "yetCnt"],
	["진행중", "ingCnt"],
	["종료건", "cloCnt"],
	["계약체결건", "contCnt"],
	["체결금액", "contPrem"],
	["링크전달", "linkCnt"],
	["번호전달", "numTrsCnt"],
	["호전환", "transCnt"],
	["체결진행", "suppCnt"],
	["기타처리", "etcCnt"]
];
export const  DESIGN_SUPPORT_STATUS_TABLE_CAMP = [
	["일자", "stsDt"],
	["요청건", "totCnt"],
	["미처리건", "yetCnt"],
	["진행중", "ingCnt"],
	["진행불가건", "impossCnt"],		// 캠페인 만 사용
	["종료건", "cloCnt"],
	["계약체결건", "contCnt"],
	["체결금액", "contPrem"],
	["링크전달", "linkCnt"],
	["번호전달", "numTrsCnt"],
	["호전환", "transCnt"],
	["체결진행", "suppCnt"],
	["기타처리", "etcCnt"]
];
export const  PRODUCT_RECOMMEND_ACCOUNT_TARGET_TABLE = [
	["일자", "stsDt"],
	["구분", "regType"],
	["FA명", "memberNm"],
	["이메일", "email"],
	["생년월일", "birthday"],
	["소속", "joinType"],
	["기존회사", "existComps"],
	["추가회사", "addComps"]
];
export const  PRODUCT_RECOMMEND_ACCOUNT_TOTAL_TABLE = [
	["일자", "stsDt"],
	["신규등록자수", "newCnt"],
	["추가등록자수", "addCnt"],
	["현대", "cnt1"],
	["DB", "cnt2"],
	["KB", "cnt3"],
	["한화", "cnt4"],
	["메리츠", "cnt5"],
	["롯데", "cnt6"],
	["그외", "cnt7"]
];
export const  DISEASE_DETAIL_TABLE = [
	["이벤트타입", "eventType"],
	["회원명", "memberNm"],
	["전화번호", "telNum"],
];
export const  APPLICATION_CONDITION_MANAGEMENT_TABLE = [
	["상품군코드", "prProdLineCd"],
	["상품군명", "prProdLineNm"],
	["적용조건코드", "condDiv"],
	["적용조건명", "condDivNm"],
	["적용조건세분", "condDetail"],
	["담보명", "creCvrNm"],
	["화면순서", "dispOrder"],
	["숫자조건", "intCond"],
	["숫자조건명", "intCondNm"],
	["기본값여부", "defaultYn"],
	["사용여부", "useYn"],
	["등록일시", "createDate"],
];
export const  PRODUCT_INFORMATION_MANAGEMENT_TABLE = [
	["상품군명", "prProdLineNm"],
	["보험사명", "insNm"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["상품타입", "prodType"],
	["최소보험료", "minPrem"],
	["적용시기", "appFromDt"],
	["적용종기", "appToDt"],
	["사용성별", "sex"],
	["추천점수", "prScore"],
	["업데이트표시일", "newDispEndDt"],
	["업데이트진행중여부", "updatingYn"],
	["보험기간범위", "insTrmRange"],
	["갱신기간범위", "rnwTrmRange"],
	["보험/납입기간", "insPyTrmCnt"],
	["사용여부", "useYn"],
	["등록일시", "createDate"],
];
export const  PRODUCT_INFORMATION_MANAGEMENT_TABLE_MODAL = [
	["보험 기간", "prodInsTrm"],
	["납입 기간", "prodPyTrm"],
	["기간유형", "insTrmType"]
];
export const  PRODUCT_PLAN_MANAGEMENT_TABLE = [
	["상품군명", "prProdLineNm"],
	["보험사명", "insNm"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["플랜코드", "planCd"],
	["플랜명", "planNm"],
	["보험기간목록", "insTrmList"],
	["갱신주기목록", "rnwCycleList"],
	["납입기간목록", "pyTrmList"],
	["납입주기목록", "pyCycleList"],
	["남자가입연령_FR", "mfrAge"],
	["남자가입연령_TO", "mtoAge"],
	["여자가입연령_FR", "ffrAge"],
	["여자가입연령_TO", "ftoAge"],
	["추천점수", "prScore"],
	["사용여부", "useYn"],
	["등록일시", "createDate"],
];
export const  PRODUCT_PLAN_MANAGEMENT_DETAIL_MODAL_TABLE = [
	["담보코드", "cvrCd"],
	["담보명", "cvrNm"],
	["담보기본특약구분", "cvrSpDiv"],
	["주력담보여부", "mainCvrYn"],
	["표시순서", "dispOrder"],
	["기본안내가입금액", "baseAccAmt"],
	["FR_가입한도금액", "frAccAmt"],
	["TO_가입한도금액", "toAccAmt"],
	["소스구분", "srcType"],
	["갱신주기", "rnwCycle"],
	["등록일시", "createDate"],
];
export const  MAIN_CVR_STATUS_TABLE = [
	["상품군명", "prProdLineNm"],
	["보험사명", "insNm"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["플랜코드", "planCd"],
	["플랜명", "planNm"],
	["담보코드", "cvrCd"],
	["담보명", "cvrNm"],
	["담보기본특약구분", "cvrSpDiv"],
	["소스구분", "srcType"],
	["갱신주기", "rnwCycle"],
	["기본안내가입금액", "baseAccAmt"],
	["FR_가입한도금액", "frAccAmt"],
	["TO_가입한도금액", "toAccAmt"],
	["주력담보여부", "mainCvrYn"],
];
export const  CRE_CVR_MAPPING_STATUS_TABLE = [
	["신정원 담보코드", "creCvrCd"],
	["신정원 담보명", "creCvrNm"],
	["사용여부", "useYn"],
	["보험사명", "insNm"],
	["보험사 담보코드", "cvrCd"],
	["보험사 담보명", "cvrNm"],
];
export const  INS_CVR_STATUS_TABLE = [
	["담보코드", "cvrCd"],
	["담보명", "cvrNm"],
	["상품코드목록", "prCdList"],
	["신정원 담보코드", "creCvrCd"],
	["신정원 담보명", "creCvrNm"],
	["갱신형구분", "rnwDiv"],
	["갱신형년도", "rnwYear"],
	["갱신용구분", "forRnwDiv"],
	["간편가입구분", "easySignUpDiv"],
	["운전자형태구분", "driverTypeDiv"],
	["사용여부", "useYn"],
	["등록일자", "createDate"],
];
export const  PRODUCT_COMPARE_MANAGEMENT_STATUS_TABLE = [
	["구분", "cvrDiv"],
	["신정원 담보코드", "creCvrCd"],
	["신정원 담보명", "creCvrNm"],
	["적용조건기준", "condBase"],
	["가입금액 목록", "accAmtList"],
	["보험료합산구분", "premSumType"],
	["매핑담보 보험사수", "insList"],
	["화면순서", "dispOrder"],
	["등록일시", "createDate"],
];
export const  PRODUCT_COMPARE_MANAGEMENT_CVR_TABLE = [
	["적용조건기준", "condBase"],
	["보험사명", "insNm"],
	["보험사 상품명", "prNm"],
	["보험사 담보코드", "cvrCd"],
	["보험사 담보명", "cvrNm"],
	["가입금액", "baseAccAmt"],
	["보험기간", "insTrm"],
	["납입기간", "pyTrm"],
	["갱신주기", "rnwCycle"],
	["등록일시", "createDate"],
];
export const  INS_CVR_MAPPING_STATUS_TABLE = [
	["신정원담보코드", "creCvrCd"],
	["신정원담보명", "creCvrNm"],
	["담보타입", "cvrDiv"],
	["공통여부", "commCmpCvrYn"],
	["원수사명", "insNm"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["담보코드", "cvrCd"],
	["담보명", "cvrNm"],
	["가입금액", "baseAccAmt"],
	["보험료", "monthlyPrem"],
	["보기", "insTrm"],
	["납기", "pyTrm"],
	["사용여부", "useYn"],
	["합산구분", "premSumType"],
];
export const  INS_CVR_MAPPING_BASIC_DATA_TABLE = [
	["상품군", "prProdLineCd"],
	["상품군명", "prProdLineNm"],
	["상품타입", "prodType"],
	["상품타입명", "prodTypeNm"],
	["신정원코드", "creCvrCd"],
	["신정원코드명", "creCvrNm"],
	["보험사코드", "insCd"],
	["보험사명", "insNm"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["담보코드", "cvrCd"],
	["담보명", "cvrNm"],
	["가입안내금액", "baseAccAmt"],
	["우선순위", "prioOrder"],
	["최대_보험기간", "insTrmMax"],
	["최소_보험기간", "insTrmMin"],
	["최대_납입기간", "pyTrmMax"],
	["최소_납입기간", "pyTrmMin"],
	["사용여부", "useYn"],
	["생성일자", "createDate"],
];
export const  PRODUCT_COND_PREM_TABLE = [
	["상품코드", "prCd"],
	["나이", "age"],
	["성별", "sex"],
	["담보구분", "cvrDiv"],
	["담보코드", "cvrCd"],
	["담보명", "cvrNm"],
	["가입금액", "accAmt"],
	["가입금액명", "accAmtNm"],
	["담보보험기간", "insTrmSub"],
	["담보납입기간", "pyTrmSub"],
	["담보갱신주기", "rnwCycleSub"],
	["월보험료", "monthlyPrem"],
];
export const  PRODUCT_COND_PREM_WHOLE_LIFE_TABLE = [
	["상품코드", "prCd"],
	["나이", "age"],
	["성별", "sex"],
	["담보구분", "cvrDiv"],
	["담보코드", "cvrCd"],
	["담보명", "cvrNm"],
	["가입금액", "accAmt"],
	["가입금액명", "accAmtNm"],
	["년수", "elapsedYear"],
	["환급금", "refund"],
	["환급금명", "refundNm"],
	["환급률(%)", "refundRate"],
	["담보보험기간", "insTrmSub"],
	["담보납입기간", "pyTrmSub"],
	["담보갱신주기", "rnwCycleSub"],
	["월보험료", "monthlyPrem"],
];
export const  PRODUCT_RECOMMEND_USAGE_OPERATION_STATUS_TABLE = [
	["부문총괄", "bmcOrgNm"],
	["부문", "bmOrgNm"],
	["총괄", "cgOrgNm"],
	["소속", "belongNm"],
	["파트너", "partnerNm"],
	["부서", "deptNm"],
	["FA명", "orgNm"],
	["사번", "orgCd"],
	["연락처", "telNum"],
	["회원가입수", "joinCnt"],
	["사용자수", "userCnt"],
	["사용자건수합계", "lineCntTot"],
	["암", "lineCnt1"],
	["통합", "lineCnt2"],
	["운전자", "lineCnt3"],
	["자녀", "lineCnt4"],
	["유병자", "lineCnt5"],
];
export const  PRODUCT_RECOMMEND_USAGE_STATUS_INS_TABLE = [
	["일자", "createDate"],
	["합계(비교)", "compareSum"],
	["합계(공유)", "shareSum"],
	["메리츠(비교)", "mzCompCnt"],
	["메리츠(공유)", "mzShareCnt"],
	["한화(비교)", "hwCompCnt"],
	["한화(공유)", "hwShareCnt"],
	["롯데(비교)", "ltCompCnt"],
	["롯데(공유)", "ltShareCnt"],
	["흥국(비교)", "hkCompCnt"],
	["흥국(공유)", "hkShareCnt"],
	["삼성(비교)", "ssCompCnt"],
	["삼성(공유)", "ssShareCnt"],
	["현대(비교)", "hdCompCnt"],
	["현대(공유)", "hdShareCnt"],
	["KB(비교)", "kbCompCnt"],
	["KB(공유)", "kbShareCnt"],
	["DB(비교)", "dbCompCnt"],
	["DB(공유)", "dbShareCnt"],
];
export const  PRODUCT_RECOMMEND_USAGE_STATUS_CUSTOM_TABLE = [
	["일자", "createDate"],
	["합계(비교)", "compareSum"],
	["합계(공유)", "shareSum"],
	["30세미만[남](비교)", "mUn30CompCnt"],
	["30세미만[남](공유)", "mUn30ShareCnt"],
	["30세미만[여](비교)", "wUn30CompCnt"],
	["30세미만[여](공유)", "wUn30ShareCnt"],
	["30대[남](비교)", "m30sCompCnt"],
	["30대[남](공유)", "m30sShareCnt"],
	["30대[여](비교)", "w30sCompCnt"],
	["30대[여](공유)", "w30sShareCnt"],
	["40대[남](비교)", "m40sCompCnt"],
	["40대[남](공유)", "m40sShareCnt"],
	["40대[여](비교)", "w40sCompCnt"],
	["40대[여](공유)", "w40sShareCnt"],
	["50대[남](비교)", "m50sCompCnt"],
	["50대[남](공유)", "m50sShareCnt"],
	["50대[여](비교)", "w50sCompCnt"],
	["50대[여](공유)", "w50sShareCnt"],
	["60세이상[남](비교)", "mUp60CompCnt"],
	["60세이상[남](공유)", "mUp60ShareCnt"],
	["60세이상[여](비교)", "wUp60CompCnt"],
	["60세이상[여](공유)", "wUp60ShareCnt"],
];
export const  PRODUCT_DATA_SYNC_TABLE = [
	["테이블 한글명", "tbNm"],
	["테이블명", "tb"],
	["건수", "cnt"],
	["생성일시", "lastDate"]
];
export const  PRODUCT_SP_CVR_AMT_TABLE = [
	["상품군 명", "prProdLineNm"],
	["신정원 담보코드", "creCvrCd"],
	["신정원 담보명", "creCvrNm"],
	["사용여부", "useYn"],
	["담보구분", "cvrTypeNm"],
	["FROM 금액", "minAmt"],
	["TO 금액", "maxAmt"],
	["금액목록", "stepAmt"],
	["안내개수", "stepCnt"],
	["기본값 설정여부", "hasDefault"],
	["생성일자", "createDate"]
];
export const  PRODUCT_RECOMMEND_BOARD_TABLE = [
	["게시판유형", "boardType"],
	["사용자범위", "userRangesNm"],
	["가입경과상태", "elapsedStatusNm"],
	["제목", "title"],
	["상품군 코드", "prProdLineCd"],
	["보험사 코드", "insCd"],
	["상품 코드", "prCd"],
	["공지등록시작일", "noticeFromDt"],
	["공지등록종료일", "noticeToDt"]
];
export const INS_TERM_INFO_MGMT_TABLE = [
	["상품종류", 	"prProdLineCdNm"],
	["보험사", 	"insCd"],
	["상품명", 	"prNm"],
	["판매시작일", "stDt"],
	["판매종료일", "closeDt"],
	["작업일자", 	"createDate"],
	["상품코드", 	"prCd"],
	["백터DB생성", "state"],
	["작업대상", 	"wkTgYn"]
];
export const SIGN_UP_REQUEST_TABLE = [
	["순번", 	"index"],
	["명함보기", 	"businessCardImgStr"],
	["이름", 	"memberNm"],
	["주민번호", 	"ssno"],
	["전화번호", 	"telNum"],
	["계좌번호", 	"bankAccount"],
	["요청일자", 	"createDate"],
	["블랙리스트", "blackListYn"],
	["승인자", "approvalNm"],
	["승인일시", "approvalDate"],
	["승인", 	"approvalYn"]
];
export const BLACK_LIST_TABLE = [
	["순번", 	"index"],
	["이름", 	"memberNm"],
	["주민번호", 	"ssno"],
	["전화번호", 	"telNum"],
	["은행", 	"bankNm"],
	["계좌번호", 	"bankAccount"],
	["처리자", 	"regNm"],
	["등록일자", 	"createDate"],
	["수정일자", 	"updateDate"]
];
export const  CAMPAIGN_MANAGEMENT_TABLE = [
	["캠페인명", "campaignNm"],
	["등록회차", "regSeq"],
	["설명", "campaignDesc"],
	["사용시작일", "useFrDt"],
	["사용종료일", "useToDt"],
	["배분여부", "divYn"],
	["등록자", "regNm"],
	["정보등록일자", "createDate"],
	["자료등록일자", "dataRegDate"],
	["자료등록건수", "dataRegCnt"]
];
export const  CAMPAIGN_DATA_SAVE_TABLE = [
	["회원명", "memberNm"],
	["회원전화번호", "telNum"],
	["고객명", "customerNm"],
	["고객전화번호", "customerTelNum"],
	["고객차량번호", "customerCarNo"],
	["보험사", "preInsurerCd"],
	["배분상담사코드", "csId"],
	["메모", "memberMemo"],
	["계약안내일자", "expiryDt"],
	["처리상태", "consultResult"]
];
export const  INS_REALTIME_CONTRACT_TABLE = [
	["보험사", "contractInsurer"],
	["계약일자", "contractDate"],
	["계약채널", "joinChannel"],
	["차량번호", "insuredCarNo"],
	["보험료", "premiumAmt"],
	["피보험자", "insuredNm"],
	["피보험자 번호", "insuredTelNum"],
	["계약자", "contractorNm"],
	["계약자 번호", "contractorTelNum"],
	["에인 연결 가입자 아이디", "joinHistId"],
	["증권번호", "policyNumber"],
	["만기일자", "expiryDate"],
	["납입구분", "paymentType"],
	["환수금액", "refundAmt"]
];
export const  INS_REALTIME_CONTRACT_DELETE_TABLE = [
	["SEQ", "insRealtimeContractSeq"],
	["보험사", "insCd"],
	["채널", "joinChannel"],
	["고객명", "customerNm"],
	["차량번호", "customerCarNo"],
	["계약일자", "contractDate"],
	["보험료", "contractPremAmt"],
	["등록일자", "createDate"]
];

export const  CS_PERFORMANCE_TABLE = [
	["계약일자", "contractDt"],
	["상담사", "csNm"],
	["건수", "scnt"],		// 합계
	["보험료", "scontractPremAmt"],
	["건수", "icnt1"],		// 요청일 계약체결
	["보험료", "icontractPremAmt1"],
	["건수", "icnt2"],		// 1~3 경과체결
	["보험료", "icontractPremAmt2"],
	["건수", "icnt3"],		// 4~7 경과체결
	["보험료", "icontractPremAmt3"],
	["건수", "icnt4"],		// 8~14 경과체결
	["보험료", "icontractPremAmt4"],
	["건수", "icnt5"],		// 15 경과체결
	["보험료", "icontractPremAmt5"],
];

export const  PROGRAM_PRIVILEGE_TABLE = [
	["프로그램 명",	"progNm"],
	["프로그램 한글명",	"progNmKr"],
	// ["프로그램 경로",	"progPath"],
	["메뉴그룹", "progMnGroup"],
	["표기순서",	"dispOrder"],
	["출력엑셀등급", "xlsGrade"],
	// ["권한그룹목록", "prvGroupLst"],
	["사용여부",	"useYn"],
	["등록자",	"createNm"],
	["등록일시",	"createDate"],
	["변경자",	"updateNm"],
	["변경일시",	"updateDate"],
	// 공통 코드로부터 권한 그룹 추가
];

export const SETTLEMENT_EARLY_TABLE = [
	["상태", "stateNm"],
	["FA명", "recipientNm"],
	["FA 연락처", "recipientTelNo"],
	["고객명", "customerNm"],
	["고객전화번호", "customerTelNum"],
	["차량번호", "customerCarNo"],
	["보험사", "contractInsurer"],
	["계약보험료", "contractPremAmt"],
	["리워드", "rewardAmt"],
	["계약일자", "contractDt"],
	["만기일자", "expiryDt"],
	["계약채널", "joinChannel"],
	["설계지원번호", "designSupId"],
	["지급처리가능", "payProYn"],
	["입력일자", "createDate"],
	["지급예정일자", "payDueDt"],
	["지급일자", "payCompleteDt"],
	["이륜차여부", "twoWheelerYn"],
	["법인계약여부", "corpContYn"]
]

export const  INS_AI_CHAT_MANAGE_TABLE = [
	["상품군", "mpPrProdLineNm"],
	["보험사", "insNm"],
	["상품종류", "prProdLineNm"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["판매일자", "stDt"],
	["질문ID", "priQuestionId"],
	["질문", "priQuestion"],
	["표기우선순위", "dispOrder"],
	["사용여부", "useYn"],
	["등록일자", "createDate"]
];

export const  INS_AI_CHAT_MANAGE_PRODUCT_SEARCH_TABLE = [
	["보험사", "insCd"],
	["상품종류", "prProdLineCd"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["판매일자", "stDt"]
];

export const  SETTLEMENT_INS_SUMMARY_TABLE = [
	["원천보험사", "contractInsurer"],
	["채널", "joinChannel"],
	["기준년월", "baseYm"],
	["원천건수", "srcCnt"],
	["원천보험료", "srcPrem"],
	["상태", "stateCd"],
	["데이터정제", "refine"],
	["정산완료건수", "completeCnt"],
	["정산완료보험료", "completePrem"],
	["정산완료광고비", "completeRewardAmt"],
	["환수대상건수", "refundCnt"],
	["환수대상보험료", "refundPrem"],
	["환수대상광고비", "refundRewardAmt"],
	["신규생성건수", "newCnt"],
	["신규생성보험료", "newPrem"],
	["신규생성광고비", "newRewardAmt"]
];
export const  SETTLEMENT_INS_SUMMARY_DETAIL_TABLE = [
	["원천ID", "srcId"],
	["보험사", "contractInsurer"],
	["채널", "joinChannel"],
	["기준년월", "baseYm"],
	["원천_계약일자", "srcContractDate"],
	["원천_보험료", "srcContractPrem"],
	["원천_고객명", "srcCustomerNm"],
	["원천_전화번호", "srcCustomerTelNum"],
	["원천_차량번호", "srcCarNo"],
	["조기지급ID", "earlyId"],
	["지급상태", "paymentStatus"],
	["회원명", "memberNm"],
	["회원전화번호", "memberTelNum"],
	["지급예정일", "payDueDate"],
	["지급일", "payCompleteDate"],
	["계약일자", "contractDate"],
	["보험료", "contractPrem"],
	["고객명", "customerNm"],
	["전화번호", "customerTelNum"],
	["차량번호", "customerCarNo"],
	["광고비", "rewardAmt"]
];

export const  PRODUCT_RECOMMEND_WORK_STATUS_TOTAL_HEADER = [
	["상품군", "prProdLineNm"],
	["보험사", "insNm"],
	["대상건", "target"],
	["진행상태", "stateColor"],
	["진행건", "onProc"],
	["종료건", "offProc"],
	["개정확인", "renewal"],
	["가입설계서추출", "planExtract"],
	["자료수신", "dataRecv"],
	["자료추출", "dataExtract"],
	["업로드", "upload"],
	["보험료작업", "premCalc"],
	["개발계반영", "deployDev"],
	["보험료검증", "premVerify"],
	["운영계반영", "deployProd"]
];

export const  PRODUCT_RECOMMEND_WORK_STATUS_DETAIL_HEADER = [
	["상품군", "prProdLineNm"],
	["보험사", "insNm"],
	["상품코드", "prCd"],
	["상품명", "prNm"],
	["상품타입", "prodType"],
	["적용시기", "appFromDt"],
	["적용종기", "appToDt"],
	["사용성별", "sex"],
	["업데이트일자", "newDispEndDt"],
	["작업상태", "wkStateCd"],
	["작업단계", "wkStepNm"],
	["세부단계", "wkSubStepNm"],
	["세부상태", "wkSubStateCd"],
	["작업일시", "createDate"],
	["작업자", "regNm"]
];

export const  NONE_SETTLE_MEMBER_TABLE = [
	["회원명", "memberNm"],
	["이메일", "email"],
	["전화번호", "telNum"],
	["소속", "joinType"],
	["회원등록일자", "regDate"],
	["설계지원요청일자", "designReqDate"]
];

export const  SETTLEMENT_PAYBACK_TABLE = [
	["회차", "sendRound"],
	["회원명", "memberNm"],
	["전화번호", "telNum"],
	["환수대상액", "hrewardAmt"],
	["월정산액", "mrewardAmt"],
	["총건수", "totCnt"],
	["총금액", "totAmt"],
	["최근활동일", "lastActDate"],
	["최근실적일", "lastContractDate"],
	["최종발송회차", "lastSendRound"],
	["상담사명", "ctCsNm"],
	["발송", "send"],
	["이력", "history"],
];

export const  SETTLEMENT_PAYBACK_DETAIL_TABLE = [
	["정산월", "settlementDate"],
	["고객명", "customerNm"],
	["휴대폰번호", "customerTelNum"],
	["차량번호", "customerCarNo"],
	["만기일", "expiryDt"],
	["계약보험료", "contractPremAmt"],
	["계약일", "contractDt"],
	["지급광고비", "rewardAmt"],
	["지급일", "payCompleteDate"]
];

export const  SETTLEMENT_PAYBACK_HISTORY_TABLE = [
	["회차", "sendRound"],
	["대상금액", "totAmt"],
	["대상건", "totCnt"],
	["발송일", "sendDate"],
	["환수여부", "completeYn"],
	["환수일", "completeDate"],
	["환수처리", "complete"],
	["환수페이지", "key"]
];