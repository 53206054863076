<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-flex>
      <v-card max-width="85vw" max-height="95vh" class="overflow-y-auto">
        <template>
          <search-page-layout title="신정원 담보코드">
            <template v-slot:searchBar>
              <cre-cvr-mapping-modal-search-bar
                  @getSearch="getSearch"
              />
            </template>

            <template v-slot:table>
              <cre-cvr-mapping-modal-table
                  :data="data"
                  :loading="loading"
                  @row="row"
                  @close="close"
              />
            </template>
          </search-page-layout>
        </template>

        <div class="text-right">
          <v-btn
              class="red darken-1 white--text"
              style="margin: 0 5px 5px 0;"
              @click="close">닫기</v-btn>
        </div>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import CreCvrMappingModalSearchBar
  from "@/pages/Product/components/ProductCompareManagement/CreCvrMappingModal/CreCvrMappingModalSearchBar";
import CreCvrMappingModalTable
  from "@/pages/Product/components/ProductCompareManagement/CreCvrMappingModal/CreCvrMappingModalTable";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props:["isShow"],
  components: {CreCvrMappingModalTable, CreCvrMappingModalSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      rowData: [],
    }
  },
  methods: {
    async getSearch(params) {
      try {
        const { data } = await axiosInstance.get('/private/prdata/prCreCvrList', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
    },
    row(row) {
      this.$emit("row", row);
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>