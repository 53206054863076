<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
      <v-btn
          color="blue" class="float-right white--text px-5"
          @click="showModal"
          >
          데이터 등록
      </v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="tableData"
        :loading="loading"
        :items-per-page="-1"
        fixed-header calculate-widths
        :headers="headers"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >
        <template v-slot:item.applyDate="{ value }">
          <div>{{ getDateFormat(value) }}</div>
        </template>
        <template v-slot:item.description = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.size="{ value , item }">
          <div @click="clickDownload(item)">{{value}}</div>
        </template>
      </v-data-table>
    </v-flex>
    <reception-status-modal
      :is-show="isShow"
      @close="isShow = false"
      />
  </v-col>
</template>
<script>
import {API_SERVER_URL} from "../../../util/constant";
import ReceptionStatusModal from "./ReceptionStatusModal";
export default {
  components: {ReceptionStatusModal},
  props: ["tableData", "loading"],
  data: () => ({
    headers: [{text: "보험사코드", value: "insCd", class: "teal lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "보험사명", value: "insNm", class: "teal lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "수신데이터명(영문)", value: "prTbId", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "수신데이터명(한글)", value: "prTbNm", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "적용일자", value: "applyDate", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "데이터 설명", value: "description", class: "teal lighten-4", align: "center", sortable: false, width: "20vw"},
      {text: "파일형태", value: "fileType", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "열구분자", value: "delimiter", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "size", value: "size", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "다운로드일시", value: "downloadDate", class: "teal lighten-4", align: "center", sortable: false, width: "13vw"},
      {text: "등록일시", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "13vw"},
      ],
    isShow: false
  }),
  methods: {
    getDateFormat(date) {
      let d = date ? date.toString() : '';
      return d ? `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6)}` : '';
    },
    clickDownload(row) {
      if (row.size.includes('-')) {
        alert("잘못된 파일입니다.");
        return;
      }
        const aTag = document.createElement('a');
        aTag.download = row.ulFileNm;
        const key = encodeURIComponent(row.key);
        aTag.href = API_SERVER_URL + '/public/prdata/extDownload?key=' + key;
        aTag.click();
      this.$emit("updateSetting");
      },
    showModal() {
      this.isShow = true;
      }
    },
}
</script>
<style scoped>
.v-data-table::v-deep table{
  table-layout:fixed
}
.overflow {
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap !important;;
}
</style>