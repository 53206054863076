<template>
  <v-layout>
    <v-card class="col-12 pa-0" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>등록/수정</v-card-title>
      <v-row class="px-6">

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">이름</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="memberNm" inputmode="text"
                outlined dense hide-details
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(memberNm, originalRow.memberNm)}"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-12 col-12">
          <v-col cols="2">주민번호</v-col>
          <v-col cols="5">
            <v-text-field
                v-model="ssnoFront"
                inputmode="numeric" maxlength="6"
                outlined dense hide-details
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(ssnoFront, originalRow.ssnoFront)}"
            />
          </v-col>
          -
          <v-col cols="5">
            <v-text-field
                v-model="ssnoBack"
                inputmode="numeric" maxlength="7"
                outlined dense hide-details
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(ssnoBack, originalRow.ssnoBack)}"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">전화번호</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="telNum"
                inputmode="numeric" maxlength="11"
                outlined dense hide-details
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(telNum, originalRow.telNum)}"
            />
          </v-col>
        </v-col>

<!--        selectbox 추가 -->
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">은행</v-col>
          <v-col cols="8">
            <v-select
                :items="BANK_LIST"
                item-text="value"
                item-value="value"
                v-model="bankNm"
                menu-props="auto"
                hide-details outlined dense
                :style="{ 'background-color': chgValueColor(bankNm, originalRow.bankNm)}"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="3">계좌번호</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="bankAccount" inputmode="numeric"
                outlined dense hide-details
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(bankAccount, originalRow.bankAccount)}"
            />
          </v-col>
        </v-col>

      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="resetData"
        >
          초기화
        </v-btn>
        <v-btn
            :disabled="!checkValidValue"
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          {{ isNew ? '저장' : '수정하기'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import CodeMixin from "@/mixin/CodeMixin";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
import _ from "lodash";

export default {
  props: ["rowData"],
  mixins: [CodeMixin],
  data() {
    return {
      blackListId: 0,
      memberNm: "",
      ssnoFront: "",
      ssnoBack: "",
      telNum: "",
      bankNm: "선택하세요",
      bankAccount: "",
      BANK_LIST: [],
      originalRow: {},            // cloneDeep 용도
      
      // color
      BRIGHT_RED: "rgba(255, 0, 0, 0.1)",
      WHITE: "white"
    }
  },
  computed: {
    isNew() { return this.blackListId == 0; },
    checkValidValue() {
      if (_.isEmpty(this.memberNm))
        return false;
      if (_.isEmpty(this.ssnoFront) || _.isEmpty(this.ssnoBack))
        return false;
      if (this.ssnoFront.length !== 6)
        return false;
      if (this.ssnoBack.length !== 7)
        return false;
      if (_.isEmpty(this.telNum) || this.telNum.length < 10 || this.telNum.length > 12)
        return false;
      if (this.bankNm == '선택하세요')
        return false;
      if (_.isEmpty(this.bankAccount) || this.bankAccount.length < 8)
        return false;

      return true;
    }
  },

  async created() {
    this.BANK_LIST = await this.getBankList();
    this.BANK_LIST.unshift({code:"EMPTY", value:"선택하세요"});
  },

  mounted() {
    if (this.rowData.blackListId > 0) this.initData();
  },

  methods: {
    // row 클릭 시 데이터 세팅
    initData() {
      this.setOrignData(this.rowData);
      this.blackListId = this.rowData.blackListId;
      this.memberNm = this.rowData.memberNm;
      this.ssnoFront = this.rowData.ssno.slice(0, 6);
      this.ssnoBack = this.rowData.ssno.slice(6, 13);
      this.telNum = this.rowData.telNum;
      this.bankNm = this.rowData.bankNm;
      this.bankAccount = this.rowData.bankAccount;
    },

    setOrignData(data){
      this.originalRow = _.cloneDeep(data);
      this.originalRow['ssnoFront'] = data.ssno.slice(0, 6);
      this.originalRow['ssnoBack'] = data.ssno.slice(6, 13);
    },

    async saveData() {
      const editBlackList = {
        blackListId: this.blackListId,  // 필수
        memberNm: this.memberNm,
        telNum: this.telNum,
        ssno: this.ssnoFront + this.ssnoBack,
        bankNm: this.bankNm,
        bankAccount: this.bankAccount,
        regId: store.getters['login/memberId']
      }

      let msg = this.isNew ? ' 회원을 블랙리스트에 추가하시겠습니까?' : ' 회원의 정보를 수정하시겠습니까?';

      if (confirm("[" + this.memberNm + "]" + msg)) {
        try {
          await axiosInstance.post('/admin/blackList', editBlackList);
          alert("저장되었습니다.");
          this.resetData();
          this.$emit("refreshList");
        } catch (e) {
          console.log(e);
        }
      }
    },

    // 초기화
    resetData() {
      this.blackListId = 0;
      this.memberNm = "";
      this.ssnoFront = "";
      this.ssnoBack = "";
      this.telNum = "";
      this.bankNm = "선택하세요";
      this.bankAccount = "";
    },
    // 입력값 변경 인지
    chgValueColor(newVal, oldVal) {
      return (!this.isNew && (newVal !== oldVal)) ? this.BRIGHT_RED : this.WHITE;
    }
  }
}
</script>