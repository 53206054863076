<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="tokenStatus"
        :loading="loading"
        :items-per-page="-1"
        fixed-header disable-sort calculate-widths
        :headers="headers"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        v-model="selectSave"
      >
        <template v-slot:item.useYn="{ value, item }">
          <v-row v-if="item.lastYn === 'Y'">
            <v-col class="col-7">
            <v-select
                :items="expir"
                :id="item.index"
                background-color="white"
                outlined dense
                item-text="bname"
                item-value="bvalue"
                :value="value"
                v-on:change="changeType(value, item)"
                hide-details
            />
            </v-col>
            <v-col class="col-5">
            <v-btn class="primary mb-1" @click="saveInput(item)">저장</v-btn>
            </v-col>
          </v-row>
          <span v-else>{{value}}</span>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props:["tokenStatus", "loading"],
  computed: {
    hasTableData() {
      return this.tokenStatus.length > 0;
    }
  },
  data:() => ({
    headers: [{text: "사용자명", value: "memberNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "이메일", value: "email", class: "teal lighten-4", align: "center", sortable: false},
      {text: "토큰", value: "accTokenPc", class: "teal lighten-4", align: "center", sortable: false},
      {text: "사용만기일시", value: "expirDate", class: "teal lighten-4", align: "center", sortable: false},
      {text: "만기여부", value: "expirYn", class: "teal lighten-4", align: "center", sortable: false},
      {text: "최종여부", value: "lastYn", class: "teal lighten-4", align: "center", sortable: false},
      {text: "프로그램버전", value: "progVersion", class: "teal lighten-4", align: "center", sortable: false},
      {text: "사용가능여부", value: "useYn", class: "teal lighten-4", align: "center", sortable: false, width: "180px"},
      {text: "생성일시", value: "createDate", class: "teal lighten-4", align: "center", sortable: false},
    ],
    selectSave:[],
    expir:[
      {bname: 'N', bvalue:'N'},
      {bname: 'Y', bvalue:'Y'},
    ]
  }),
  methods: {
   async saveInput(val) {
     const item = val;
     const desktopUserTokenDto = {
       accTokenPc : item.accTokenPc,
       createDate : '',
       email: '',
       expirDate:'',
       expirYn:'',
       lastYn: item.lastYn,
       memberId:'',
       memberNm:'',
       newAccTokenPc:'',
       progId:'',
       progVersion:'',
       useYn: item.useYn,
     };
     if(confirm("저장하시겠습니까?")){
       try {
         const {data} = await axiosInstance.post('/admin/collection/token_status', desktopUserTokenDto);
         if (data === 1) {
           alert("저장되었습니다.");
         }
       } catch (e) {
         console.log(e);
       }
     }
    },
    changeType(value, item) {
      // useYn N/Y 값 변경
     if (value === 'N') {
       item.useYn = 'Y'
     } else {
       item.useYn = 'N'
     }
    }
  }
}
</script>


<style scoped>
</style>