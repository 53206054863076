<template>
  <v-col cols="12" class="py-5 px-2">
    <div class="text-right">
      <v-btn
          color="red"
          class="white--text px-8"
          style="margin-right: 3px;"
          @click="downloadFile"
      >동기화</v-btn>

    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="45vh"
          item-key="idx"
          v-model="selectData"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          :headers="getHeader(headers)"
          fixed-header calculate-widths
          :footer-props="{'items-per-page-options': [10, 30, 50 , -1]}"
          show-select
      >

        <!-- 건수 콤마 추가 -->
        <template v-slot:item.cnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <!-- 최종변경일시 -->
        <template v-slot:item.lastDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {PRODUCT_DATA_SYNC_TABLE} from "@/util/TableHeaders";
import DateMixin from "@/mixin/DateMixin";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: PRODUCT_DATA_SYNC_TABLE,
      selectData: [],
    }
  },
  methods: {
    async downloadFile() {

      if(this.selectData.length <= 0)
      {
        alert("목록을 선택하세요.");
        return;
      }

      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const date = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const time = `${year}${month}${date}${hours}${minutes}${seconds}`;

      this.$emit("dataSyncFile", this.selectData, 0, time, "");
    }
  }
}
</script>