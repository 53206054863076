<template>
<!-- by sk.jeon -->
    <div id="app">
    <v-row>
      <v-col
        cols="11"
        sm="4"
      >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="조회 기간"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>

                <v-btn
                text
                color="primary"
                @click="dateEvent(date)"
                >
                OK
                </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
</div>
</template>
<script>

export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
  }),
  methods: {
      dateEvent(date) { 
          this.$refs.menu.save(date)  
          this.date = date
          this.$emit('search', date)
      },
      total() {
          console.log("total")
      }

  }
}
</script>