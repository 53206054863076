<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card max-width="95vw" max-height="180vh" min-width="80vw" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">준회원 목록 팝업</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-row class="shShow">
          <v-col class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <span class="title-4">기준년월</span>
            <v-col cols="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model = "date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    type="month"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                      text
                      color="primary"
                      @click="dateEvent(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <span class="title-4">조회조건</span>
            <v-col cols="7">
              <v-radio-group
                  v-model="checkRage"
                  row
              >
                <v-radio label="준회원"  value="AS"/>
                <v-radio label="무료회원" value="FR"/>
                <v-radio label="준회원 플러스" value="AP"/>
                <v-btn
                    color="blue" class="white--text px-10 maginLeft"
                    @click="getList"
                >
                  조회
                </v-btn>
                <v-btn
                    v-if="checkRage !== 'FR'"
                    color="teal lighten-2" class="white--text px-10 maginLeft"
                    @click="alarmtalk"
                >알림톡 발송</v-btn>
              </v-radio-group>
            </v-col>
          </v-col>
        </v-row>
        <v-col cols="12" class="py-0">
          <div class="mb-2">
            <span class="text-h6">목록 (총 {{ items.length }}개)</span>
            <outlined-btn class="float-right" @click="toExcel" style="margin-right: 0px;" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
          </div>
        </v-col>
        <v-data-table
          :items="items"
          height="52vh" item-key="index"
          :headers="header"
          v-model="selectData"
          show-select multi-sort="multi-sort"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          dense fixed-header calculate-widths disable-sort
          >
          <template v-slot:item.birthday="{ value }">
            {{getbirtday(value)}}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
        <alarm-talk-modal
          :is-alarm-show="isAlarmShow"
          :selectData ="selectData"
          :checkRage="checkRage"
          @close="isAlarmShow = false"
          />
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import CodeMixin from "@/mixin/CodeMixin";
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import func from "@/util/func";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import AlarmTalkModal from "./AlarmTalkModal";

export default {
  props: ["isShow"],
  components: {AlarmTalkModal, OutlinedBtn},
  mixins: [ CodeMixin, TableMixin],
  computed: {
    hasTableData() {
      return this.items.length > 0;
    }
  },
  async created() {
    this.header = this.headers;
  },
  data:() => ({
    headers: [{text: "회원구분", value: "memberDiv", class: "lime lighten-4", align: "center", sortable: false},
      {text: "이름", value: "memberNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "성별", value: "sex", class: "teal lighten-4", align: "center", sortable: false},
      {text: "생년월일", value: "birthday", class: "teal lighten-4", align: "center", sortable: false},
      {text: "전화번호", value: "telNum", class: "teal lighten-4", align: "center", sortable: false},
      {text: "등록일자", value: "createDate", class: "lime lighten-4", align: "center", sortable: false},
      {text: "알림톡 개별 발송일자", value: "contRealDate", class: "lime lighten-4", align: "center", sortable: false}
    ],
    headersAP: [{text: "회원구분", value: "memberDiv", class: "lime lighten-4", align: "center", sortable: false},
      {text: "이름", value: "memberNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "성별", value: "sex", class: "teal lighten-4", align: "center", sortable: false},
      {text: "계좌번호 충족", value: "bankAccountYn", class: "teal lighten-4", align: "center", sortable: false},
      {text: "이메일 충족", value: "emailYn", class: "teal lighten-4", align: "center", sortable: false},
      {text: "생년월일", value: "birthday", class: "teal lighten-4", align: "center", sortable: false},
      {text: "전화번호", value: "telNum", class: "teal lighten-4", align: "center", sortable: false},
      {text: "등록일자", value: "createDate", class: "lime lighten-4", align: "center", sortable: false},
      {text: "알림톡 개별 발송일자", value: "contRealDate", class: "lime lighten-4", align: "center", sortable: false}
    ],
    header:[],
    selectData:[],
    checkRage: "AS",
    items:[],
    isAlarmShow: false,
    menu: false,
    date: new Date().toISOString().substr(0, 7)
  }),
  methods: {
    async getList() {
      this.selectData = [];
      let params = {
        yearMonth: this.date,
        searchType: this.checkRage
      }

      if (this.checkRage === "AP") {
        this.header = this.headersAP
      } else {
        this.header = this.headers
      }

      try {
        const {data} = await axiosInstance.get('/admin/operation/performance-memberdiv', {params});
        this.items = data;
        //인덱스 값 추가
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].index = i;
          if (this.items[i].contRealDate.length >0){
            const year = this.items[i].contRealDate.substring(0, 4);
            const mon = this.items[i].contRealDate.substring(4, 6);
            const day = this.items[i].contRealDate.substring(6, 8);
            this.items[i].contRealDate =  year + '/' + mon + '/' + day;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    dateEvent(date) {
      this.$refs.menu.save(date)
      this.date = date
      this.$emit('search', date)
    },
    close() {
      this.date = new Date().toISOString().substr(0, 7);
      this.checkRage = "AS";
      this.items = [];
      this.$emit('close');
    },
    getbirtday(birthday) {
      return birthday.length === 8 ? `${birthday.slice(0, 4)}/${birthday.slice(4, 6)}/${birthday.slice(6)}` : birthday;
    },
    toExcel() {
      const data = _.cloneDeep(this.items);
      const returnData = [];

      // 엑셀 다운로드
      for (const item of data) {
        const obj = {
          "회원구분": item["memberDiv"],
          "이름": item["memberNm"],
          "성별": item["sex"],
          "생년월일": item["birthday"],
          "전화번호": item["telNum"],
          "등록일자": item["createDate"],
          "알림톡 개별 발송일자": item["contRealDate"],
        }
        returnData.push(obj);
      }
      const sheetName = `준회원 목록`;
      const fileName = `준회원 목록List`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    alarmtalk() {
      this.isAlarmShow = true;
    }
  }
}
</script>

<style scoped>
.title-4 {
  font-size: 17px;
  font-weight: 500;
  margin-left: 28px;
  margin-right: 13px;
}
.shShow {
  margin-right: 20px;
  text-align: center;
}
.subBar {
  margin-left: 5px;
  margin-right: 20px;
}
.maginLeft {
  margin-left: 20px;
}
</style>
