<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <v-card class="pa-3" width="65vw" height="100%" max-width="900" max-height="900">
      <div class="d-flex justify-end align-center pr-10" style="position: absolute; width: 100%;">
        <span style="font-size: 30px; cursor: pointer" @click="closePopup">x</span>
      </div>
      <search-page-layout title="조기정산 환수 대상자 이력">
        <template v-slot:searchBar>
          <search-area>
            <v-row>
              <v-col class="d-flex justify-center align-center col-6">
                <v-col cols="5">회원명</v-col>
                <v-text-field
                    v-model="userData.memberNm"
                    outlined dense hide-details
                    background-color="white"
                    disabled
                />
              </v-col>
              <v-col class="d-flex justify-center align-center col-6">
                <v-col cols="5">전화번호</v-col>
                <v-text-field
                    v-model="userData.telNum"
                    outlined dense hide-details
                    background-color="white"
                    disabled
                />
              </v-col>
              <v-col class="d-flex justify-center align-center col-6">
                <v-col cols="5">최근 활동일</v-col>
                <v-text-field
                    v-model="userData.lastActDate"
                    outlined dense hide-details
                    background-color="white"
                    disabled
                />
              </v-col>
              <v-col class="d-flex justify-center align-center col-6">
                <v-col cols="5">최근 실적일</v-col>
                <v-text-field
                    v-model="userData.lastContractDate"
                    outlined dense hide-details
                    background-color="white"
                    disabled
                />
              </v-col>
            </v-row>
          </search-area>
        </template>
        <template v-slot:table>
          <v-flex>
            <v-data-table
                class="mx-2"
                height="40vh"
                :headers="getHeader(headers)"
                :items="histData"
                :loading="loading"
                :items-per-page="-1"
                @click:row="getDetailData"
                @dblclick:row="showDetailPopup"
            >
              <template v-slot:item.totAmt="{ value }">
                <div class="jc-fe">{{ inputComma(value) }}</div>
              </template>
              <template v-slot:item.completeDate="{ value }">
                <div>{{ getDateDashFormat(value) }}</div>
              </template>
              <template v-slot:item.complete="{ item }">
                <v-btn v-if="item.completeYn === 'N' && !isPrvGroupTM" @click="complete(item)">처리</v-btn>
              </template>
              <template v-slot:item.key="{ value }">
                <v-btn v-if="value" @click="showPRPage(value)">이동</v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </template>
      </search-page-layout>
    </v-card>
    <settlement-payback-detail-popup
      :is-show="isShowDetail"
      :row-data="detailSearchData"
      @close="isShowDetail = false"
    />
    <settlement-payback-save-popup
      :is-show="isShowSave"
      :row-data="saveData"
      @close="closeSavePopup"
    />
  </v-overlay>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout.vue";
import {SETTLEMENT_PAYBACK_HISTORY_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import _ from "lodash";
import SearchArea from "@/components/Search/SearchArea.vue";
import {axiosInstance} from "@/util/AxiosModule";
import SettlementPaybackDetailPopup
  from "@/pages/Settlement/components/SettlementPayback/Popup/SettlementPaybackDetailPopup.vue";
import SettlementPaybackSavePopup
  from "@/pages/Settlement/components/SettlementPayback/Popup/SettlementPaybackSavePopup.vue";
import DateMixin from "@/mixin/DateMixin";
import func from "@/util/func";

export default {
  components: {SettlementPaybackSavePopup, SettlementPaybackDetailPopup, SearchArea, SearchPageLayout},
  props: ["isShow", "rowData"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: SETTLEMENT_PAYBACK_HISTORY_TABLE,
      userData: {},
      histData: [],
      loading: false,
      detailSearchData: {},
      isShowDetail: false,
      isShowSave: false,
      saveData: {}
    }
  },
  watch: {
    isShow(value) {
      if (value) {
        this.histData = [];
        this.userData = _.cloneDeep(this.rowData);
        if (!_.isEmpty(this.userData.telNum)) {
          this.userData.telNum = this.getTelNum(this.userData.telNum);
        }
        this.getHistData(this.userData.memberId);

      }
    }
  },
  computed: {
    isPrvGroupTM() {
      return func.checkUserPrvGroup(["ADM", "DEV", "MNG_SUP"]) === "N";
    }
  },
  methods: {
    async getHistData(memberId) {
      try {
        this.loading = true;
        const { data } = await axiosInstance.get("/public/settlement/getSettlementNoticeMemberSendInfo", {
          params: {
            memberId: memberId
          }
        });
        let resultData = data.settlementNoticeMemberSendList;
        if (resultData.length < 1) {
          this.histData = [];
          this.loading = false;
        } else {
          for (let i = 0; i < resultData.length; i++) {
            resultData[i].index = i;
            resultData[i].sendDate = !_.isEmpty(resultData[i].sendDate)
                ? resultData[i].sendDate.substr(0, 10)
                : resultData[i].sendDate;
            // resultData[i].completeDate = !_.isEmpty(resultData[i].completeDate)
            //     ? resultData[i].completeDate.substr(0, 10)
            //     : resultData[i].completeDate;
            resultData[i].complete = true;
          }
          this.histData = resultData;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    complete(item) {
      this.saveData = this.userData;
      this.saveData.sendRound = item.sendRound;
      this.isShowSave = true;
    },
    getDetailData(item) {
      this.detailSearchData = this.userData;
      this.detailSearchData.sendRound = item.sendRound;
    },
    showDetailPopup() {
      this.isShowDetail = true;
    },
    closeSavePopup() {
      this.isShowSave = false;
      this.getHistData(this.userData.memberId);
    },
    showPRPage(key) {
      if (process.env.NODE_ENV === "development") {
        const url = "https://test.1click.direct/settlePaybackPR/?key=" + key;
        window.open(url, "_blank");
      } else {
        const url = "https://1click.direct/settlePaybackPR/?key=" + key;
        window.open(url, "_blank");
      }
    },
    closePopup() {
      this.$emit("close");
    }
  }
}
</script>