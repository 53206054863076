<template>
  <search-page-layout title="상품추천테이블 데이터 등록 현황">

    <template v-slot:searchBar>
      <product-recommendation-search-bar
        @getSearch = "getSearch"
        />
    </template>

    <template v-slot:table>
      <product-recommendation-table
        :tableData="data"
        :loading="loading"
        />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import ProductRecommendationSearchBar from "./components/ProductRecommendation/ProductRecommendationSearchBar";
import ProductRecommendationTable from "./components/ProductRecommendation/ProductRecommendationTable";
export default {
  components: {ProductRecommendationTable, ProductRecommendationSearchBar, SearchPageLayout},
  data: () => ({
    loading: false,
    data: [],
  }),
  methods: {
    async getSearch(params) {
      this.loading = true;
      try {
        const {data} = await axiosInstance.get('/admin/prdata/getPrInsTbMngTbList', { params });
        this.data = data;

        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].stat === null) {
            this.data[i].stat = "초기";
          } else if (this.data[i].stat === "1") {
            this.data[i].stat = "작업진행중";
          } else if (this.data[i].stat === "2") {
            this.data[i].stat = "입력 완료";
          } else if (this.data[i].stat === "3") {
            this.data[i].stat = "삭제 진행중";
          } else  if (this.data[i].stat === "4") {
            this.data[i].stat = "삭제 완료";
          } else if (this.data[i].stat === "9"){
            this.data[i].stat = "오류";
          }
        }
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    }
  }
}
</script>