<template>
  <v-container fluid grid-list-md>
    <v-card max-width="50vw" max-height="95vh" class="overflow-y-auto modalBackground" style="padding:10px">

      <v-layout column px-3>

        <v-row>
          <v-col cols="12">
            <span class="headline blue-grey--text font-weight-bold">{{ title }}</span>
          </v-col>

          <slot name="upload"/>
          <slot name="table" class="red--text"/>
        </v-row>

      </v-layout>

    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {

  }
}
</script>