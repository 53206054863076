<script>
import { Bar, Line } from "vue-chartjs";

export default {
  props: [ "monthArray", "resData" ],
  extends: Bar,Line,
  data(){
    return{
      datasets : [
        {
          label: "리워드",
          type: "line",
          data: [],
          borderColor: "rgb(2,223,255)",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3
        },
        {
          label: "실적",
          type: "bar",
          data: [],
          backgroundColor: "rgba(119,119,119,0.7)",
          hoverBackgroundColor: "rgb(119,119,119)",
          borderWidth: 3
        }
      ],
    }
  },

  mounted() {
    for (let i in this.resData) {
      this.datasets[0].data[i] = this.resData[i].reward;
      this.datasets[1].data[i] = this.resData[i].prem;
    }
    this.renderChart(
        {
          labels: this.monthArray,
          datasets: this.datasets
        },
        { responsive: true, maintainAspectRatio: false, beginAtZero: true }
    );
  },
  watch: {
    resData: function () {
      for (let i in this.resData) {
        this.datasets[0].data[i] = this.resData[i].reward;
        this.datasets[1].data[i] = this.resData[i].prem;
      }
      this.$data._chart.update();
    },
    data: function () {
      this.renderChart({
            labels: this.monthArray,
            datasets: this.datasets
          },
          {responsive: true, maintainAspectRatio: false, beginAtZero: true})
    }
  }
};
</script>
