<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <v-col class="d-flex align-center col-xl-4 col-md-6 col-12">
          <v-col cols="3">조회기간</v-col>
          <v-col>
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-md-3 col-12">
          <v-col cols="4">블랙리스트 여부</v-col>
          <v-col cols="8">
            <v-select
                :items="blackListType"
                v-model="blackListYn"
                background-color="white"
                menu-props="auto"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-md-3 col-12">
          <v-col cols="4">승인 여부</v-col>
          <v-col cols="8">
            <v-select
                :items="approvalType"
                item-text="text"
                item-value="value"
                v-model="approvalYn"
                background-color="white"
                menu-props="auto"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getCalcStat"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchArea from "@/components/Search/SearchArea";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import SearchBtn from "@/components/Search/SearchBtn";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import StringMixin from "@/mixin/StringMixin";

export default {
  mixins: [DateMixin, StringMixin, SearchBarMixin],
  components: {SearchBtn, DateInput, SearchBarLayout, SearchArea},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data: () => ({
    startDate: "",
    endDate: "",
    blackListYn: "N",
    blackListType: ['Y', 'N'],
    approvalYn: "N",
    approvalType: [
      {value: 'Y', text: '승인완료'},
      {value: 'N', text: '미승인'},
      {value: 'X', text: '승인거절'},
    ],
  }),
  methods: {
    getCalcStat() {
      this.$emit('search', {
        startYmd: this.replaceOnlyNum(this.startDate),
        endYmd: this.replaceOnlyNum(this.endDate),
        blackListYn: this.blackListYn,
        approvalYn: this.approvalYn,
      })
    },
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    }
  }
}
</script>

<style scoped>

</style>