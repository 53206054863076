<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
      :key="tagkey"
  >
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card max-width="95vw" max-height="180vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">보험사별 정산자료내역 팝업</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-tabs v-model="active">
          <v-tab @click="TotalBtn">보험사별 정산자료합계</v-tab>
          <v-tab @click="showBtn">보험사별 정산자료건별</v-tab>
        </v-tabs>
        <v-row class="shShow">
          <span class="title-1">기준년월</span>
          <v-text-field
              background-color="white"
              outlined dense
              v-model="settlementDto.settlementDate"
              class="subBar"
          />
          <span class="title-1">보험사</span>
          <v-select
              v-model="settlementDto.insuranceType"
              :items="insuranceType"
              background-color="white"
              outlined dense
              item-text="bname"
              item-value="bvalue"
              class="subBar"
          />
          <span class="title-1">채널</span>
          <v-select
              v-model="settlementDto.joinChannelType"
              :items="joinChannelType"
              background-color="white"
              outlined dense
              item-text="cname"
              item-value="cvalue"
              class="subBar"
          />
          <v-col>
            <v-btn
                color="grey" class="white--text px-10"
                @click="getboard(isShowData)"
                :disabled="isDisabled"
            >
              조회
            </v-btn>
          </v-col>
          <v-col v-if="!isShowData">
            <v-btn
                color="red darken-4"
                class="white--text col-2 float-right"
                @click="delectData(selectData)"
                >삭제</v-btn>
          </v-col>
          <v-col>
            <outlined-btn class="float-right" @click=toExcel :disabled="!hasTableData">엑셀다운로드</outlined-btn>
          </v-col>
        </v-row>
        <v-data-table
            v-if="isShowData"
            :items="items"
            height="52vh" item-key="index"
            :headers="getHeader(headers)"
            :items-per-page="-1"
            :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
            dense fixed-header calculate-widths disable-sort
        >
          <template v-slot:item="{item}">
            <tr :class="{redColor: getRedText(item)}" @click="detailInsu(item)">
              <td v-for="(head, i) in getHeader(headers)" :key="i" :class="textAl(head) ? 'textRi' : 'textCenter'">
                {{item[head.value]}}
              </td>
            </tr>
          </template>

        </v-data-table>

        <v-data-table
            v-if="!isShowData"
            :items="itemsData"
            v-model="selectData"
            item-key="index"
            height="52vh"
            :headers="getHeader(header)"
            show-select multi-sort="multi-sort"
            dense fixed-header calculate-widths disable-sort
            :items-per-page="-1"
            :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >

          <template v-slot:item.contractPremAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>

          <template v-slot:item.contractFee="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>

          <template v-slot:item.contractDate="{ value }">
            {{
              value.length === 8
                  ? `${value.slice(0,4)}/${value.slice(4,6)}/${value.slice(6,8)}`
                  : value
            }}
          </template>

          <template v-slot:item.expiryDate="{ value }">
            {{
              value.length === 8
                  ? `${value.slice(0,4)}/${value.slice(4,6)}/${value.slice(6,8)}`
                  : value
            }}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>

import CodeMixin from "@/mixin/CodeMixin";
import {SETTLEMENT_TOTAL_HEADER} from "@/util/TableHeaders";
import {SETTLEMENT_DATA_HEADER} from "@/util/TableHeaders";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import {axiosInstance} from "@/util/AxiosModule";
import TableMixin from "@/mixin/TableMixin";
import DateMixin from "@/mixin/DateMixin";
import _ from "lodash";
import func from "@/util/func";

export default {
  mixins: [CodeMixin, TableMixin, DateMixin],
  props: ["isShow"],
  components: {OutlinedBtn},
  computed: {
    hasTableData() {
      return this.items.length > 0 || this.itemsData.length > 0;
    },
    isDisabled() {
      const d = this.settlementDto.settlementDate;
      return !(!!d && /[0-9]{6}/.test(d) && this.monthCheck(d));
    }
  },
  data: () => ({
    tagkey:0,
    active: [0, 1],
    selectData:[],
    headers: SETTLEMENT_TOTAL_HEADER,
    header: SETTLEMENT_DATA_HEADER,
    insuranceType: [
      {bname: '전체', bvalue: 'ALL'},
      {bname: '현대해상화재보험', bvalue: 'HD'},
      {bname: 'DB손해보험', bvalue: 'DB'},
      {bname: 'KB손해보험', bvalue: 'KB'},
      {bname: '한화손해보험', bvalue: 'HW'},
      {bname: '흥국화재해상보험', bvalue: 'HK'},
      {bname: '하나손해보험', bvalue: 'HN'},
      {bname: 'AXA손해보험', bvalue: 'AXA'},
      {bname: '캐롯손해보험', bvalue: 'CR'},
      {bname: '삼성화재보험', bvalue: 'SS'}
    ],
    joinChannelType: [
      {cname: '전체', cvalue: 'ALL'},
      {cname: 'CM', cvalue: 'CM'},
      {cname: 'TM', cvalue: 'TM'},
      {cname: 'SP', cvalue: 'SP'}
    ],
    isShowData: true,
    settlementDto: {
      "insuranceType": 'ALL',
      "settlementDate": '',
      "joinChannelType": 'ALL',
    },
    settlementDate:'',
    items : [],
    itemsData : []
  }),
  async beforeMount() {
    // 시작 날짜, 종료 날짜 초기 설정
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const [year, month] = [date.getFullYear(), date.getMonth()];
    this.settlementDate = `${year}${this.addZero(month + 1)}`;
    this.settlementDto.settlementDate = this.settlementDate;
  },
  methods: {
    getHeader(headers) {
      return headers.map(header => ({
        text: header[0],
        value: header[1],
        class: "teal lighten-4",
        width: "130px"
      }))
    },
    // 기준연월 유효성 체크
    monthCheck(value) {
      return value.length === 6
          ? (+value.slice(4) < 13 && +value.slice(4) > 0)
          : false;
    },
    TotalBtn() {
      // 보험사별 정산자료합계에 사용할 data 재정의
      this.settlementDto.insuranceType = 'ALL';
      this.settlementDto.joinChannelType = 'ALL';
      this.insuranceType[0] = {bname: '전체', bvalue: 'ALL'};
      this.joinChannelType[0] = {cname: '전체', cvalue: 'ALL'};
      this.isShowData = true;
      this.tagkey++;
    },
    showBtn() {
      // 보험사별 정산자료건별에 사용할 data 재정의
      this.settlementDto.settlementDate = this.settlementDate;
      this.settlementDto.insuranceType = 'ALL';
      this.settlementDto.joinChannelType = 'ALL';
      this.insuranceType[0] = {bname: '선택해주세요', bvalue: 'ALL'};
      this.joinChannelType[0] = {cname: '전체', cvalue: 'ALL'};
      this.itemsData = [];
      this.selectData = [];
      this.items = [];
      this.isShowData = false;
      this.tagkey++;
    },
    async getboard(isShow) {
      this.selectData = [];
      let params = {
        standardYm: this.settlementDto.settlementDate,
        contractInsurer: this.settlementDto.insuranceType,
        joinChannel: this.settlementDto.joinChannelType,
      }
      if (isShow){
        try {
          // 보험사별 정산자료합계 data
          const {data} = await axiosInstance.get('/admin/settlement/settlsrc_cnt', {params});
          this.items = data;
        } catch (e) {
          console.log(e);
        }
      }else {
        try {
          // 보험사별 정산자료건별 data
          const {data} = await axiosInstance.get('/admin/settlement/settlsrc', {params});
          this.itemsData = data;
          //인덱스 값 추가
          for (let i = 0; i < this.itemsData.length; i++) {
            this.itemsData[i].index = i;
          }
        } catch (e) {
          console.log(e);
        }
      }
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].contractFee = this.inputComma(this.items[i].contractFee);
        this.items[i].contractPremAmt = this.inputComma(this.items[i].contractPremAmt);
      }
    },
    toExcel() {
      const data = _.cloneDeep(this.items);
      const itemsData = _.cloneDeep(this.itemsData);
      const returnData = [];
      for (let i = 0; i < data.length; i++) {
        data[i].contractFee = Number(data[i].contractFee.replace(/,/g, ""));
        data[i].contractPremAmt = Number(data[i].contractPremAmt.replace(/,/g, ""));
      }
      if (this.isShowData){
        // '보험사별 정산자료합계' 엑셀 다운로드
        for (const item of data) {
          const obj = {
            "보험사": item["contractInsurerNm"],
            "계약채널": item["joinChannel"],
            "채널건수": item["joinChannelCnt"],
            "정산매핑건수": item["settlementIdCnt"],
            "차량번호건수": item["insuredCarNoCnt"],
            "보험료합계": item["contractPremAmt"],
            "광고수수료합계": item["contractFee"],
            "피보험자건수": item["insuredNmCnt"],
            "피보험자번호건수": item["insuredTelNumCnt"],
            "계약자건수": item["contractorNmCnt"],
            "계약자번호건수": item["contractorTelNumCnt"],
            "에인연결ID건수": item["ainLinkIdCnt"],
            "증권번호건수": item["policyNoCnt"],
            "만기일자건수": item["expiryDateCnt"],
            "연납구분건수": item["payMethodYearCnt"],
            "월납구분건수": item["payMethodMonthCnt"]
          }
          returnData.push(obj);
        }
      }else{
        // '보험사별 정산자료건별' 엑셀 다운로드
        for (const item of itemsData) {
          const obj = {
            "보험사": item["contractInsurerNm"],
            "계약일자": item["contractDate"],
            "계약채널": item["joinChannel"],
            "차량번호": item["insuredCarNo"],
            "보험료": item["contractPremAmt"],
            "광고수수료": item["contractFee"],
            "피보험자": item["insuredNm"],
            "피보험자 번호": item["insuredTelNum"],
            "계약자": item["contractorNm"],
            "계약자 번호": item["contractorTelNum"],
            "에인연결ID": item["ainLinkId"],
            "증권번호": item["policyNo"],
            "만기일자": item["expiryDate"],
            "납입구분": item["payMethodType"],
            "정산매핑ID": item["carSettlementHistId"]
          }
          returnData.push(obj);
        }
      }
      let subTitle = this.isShowData ? "합계" : "건별";
      const sheetName = `보험사별 정산자료_` + subTitle;
      const fileName = `보험사별 정산자료_` + subTitle;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    getRedText(item) {
      return item.contractInsurerNm == "보험사합계";
    },
    async detailInsu(row) {
      // 보험별 정산자료건에 사용할 'insuranceType' 배열 재정의
      this.insuranceType[0] = {bname: '선택해주세요', bvalue: 'ALL'};
      this.joinChannelType[0] = {cname: '전체', cvalue: 'ALL'};
      this.selectData = [];
      this.tagkey++;

        let params = {
          standardYm: this.settlementDto.settlementDate,
          contractInsurer: row.contractInsurer,
          joinChannel: row.joinChannel,
        }

      if (params.joinChannel == "합계") {
        params.joinChannel = 'ALL';
      }

      this.settlementDto.settlementDate = params.standardYm;
      this.settlementDto.insuranceType = params.contractInsurer;
      this.settlementDto.joinChannelType = params.joinChannel;

      // contractInsurerNm의 컬럼 값이 '보험사합계'를 제외하고 클릭 시 해당 보험사의 정산자료건별 데이타 표출
      if (row.contractInsurerNm != "보험사합계"){
        const {data} = await axiosInstance.get('/admin/settlement/settlsrc', {params});
        this.isShowData = false;
        this.active = 1;
        this.itemsData = data;
        //인덱스 값 추가
        for (let i = 0; i < this.itemsData.length; i++) {
          this.itemsData[i].index = i;
        }
      }
    },
    close() {
      this.items = [];
      this.itemsData = [];
      this.selectData = [];
      this.settlementDto = [];
      this.TotalBtn();
      this.settlementDto.settlementDate = this.settlementDate;
      this.$emit('close');
    },
    async delectData(data) {
      if(!confirm(data.length+"개의 데이터를 삭제하겠습니까?")) return;
      for (let i = 0; i < this.selectData.length; i++) {
        delete  this.selectData[i].index;
      }

      let CarSettleSrcList = [];
      CarSettleSrcList = this.selectData

        try {
          // 보험사별 정산자료합계 data
          await axiosInstance.post('/admin/settlement/settlsrc_del', CarSettleSrcList, {
            params: {
              standardYm: this.settlementDto.settlementDate
            }
          });

          alert("삭제 되었습니다.");
          this.getboard(false);
        } catch (e) {
          console.log(e);
        }
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    textAl(item) {
      return item.text == '보험료합계' || item.text == '광고수수료합계';
    }
  }
}
</script>

<style scoped>
.subtitle-1 {
  margin-right: auto;
}
.subtitle-2 {
  margin-right: auto;
  margin-left: 10px;
  text-decoration-line: underline;
  cursor: pointer;
}
.title-1 {
  margin-left: 20px;
  margin-right: 15px;
  margin-top: 20px;
  font-weight: bolder;
}
.shShow {
  margin-right: 20px;
  text-align: center;
}
.subBar {
  margin-top: 13px;
  margin-left: 5px;
  margin-right: 20px;
}
.redColor {
  background-color: #CCCCCC !important;
  font-weight: 500;
}
.textRi {
  text-align: right;
}
.textCenter {
  text-align: center;
}
</style>
