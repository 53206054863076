<template>
  <v-overlay v-model="isShow" :light="true" :dark="false">
    <v-card height="90vh" width="90vw">
      <v-card-title class="header-container">
        <span class="header-title">원수사별 적재보험료 조회</span>
        <span class="close-button" @click="close">X</span>
      </v-card-title>

      <v-card-text>
        <v-row class="px-3 mb-2">
          <v-col class="d-flex flex-column col-2">
            <v-select
              label="보험사"
              v-model="data.insCd"
              :items="insCdList"
              item-text="insCdNm"
              item-value="insCd"
              background-color="white" outlined disabled
              @change="changeData('insCd')"
            />
          </v-col>
          <v-col class="d-flex flex-column col-2">
            <v-select
                label="보험기간"
                v-model="insTrm"
                :items="insTrmList"
                item-text="insTrmNm"
                item-value="insTrm"
                background-color="white" outlined
            />
          </v-col>
          <v-col class="d-flex flex-column col-2">
            <v-select
                label="납입기간"
                v-model="pyTrm"
                :items="pyTrmList"
                item-text="pyTrmNm"
                item-value="pyTrm"
                background-color="white" outlined
            />
          </v-col>
          <v-col class="d-flex flex-column col-2">
            <v-select
                label="성별"
                v-model="sex"
                :items="sexList"
                item-text="sexNm"
                item-value="sex"
                background-color="white" outlined
            />
          </v-col>
          <v-col class="d-flex flex-column col-2">
            <v-text-field
                label="연령"
                v-model="age"
                background-color="white" outlined
            />
          </v-col>
          <v-col class="d-flex flex-column col-2" />
          <v-col class="d-flex flex-column col-2">
            <v-select
                label="상품코드"
                v-model="data.prCd"
                :items="prCdList"
                item-text="prCd"
                item-value="prCd"
                background-color="white" outlined disabled
                @change="changeData('prCd')"
            />
          </v-col>
          <v-col class="d-flex flex-column col-4">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      label="상품명"
                      v-model="data.prNm"
                      background-color="white" outlined disabled
                  />
                </div>
              </template>
              <span>{{ data.prNm }}</span>
            </v-tooltip>
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <v-text-field
                label="담보코드"
                v-model="data.cvrCd"
                background-color="white" outlined disabled
                append-icon="mdi-format-list-bulleted"
                @click:append="showInsCvrCdModal"
            />
          </v-col>
          <v-col class="d-flex flex-column col-3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      label="담보명"
                      v-model="data.cvrNm"
                      background-color="white" outlined disabled
                  />
                </div>
              </template>
              <span>{{ data.cvrNm }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <div class="search-button">
              <v-btn @click="getSearch">조회</v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-data-table
                  height="30vh" fixed-header
                  :headers="getHeaders"
                  :items="searchData"
                  :loading="loading"
              >
                <template v-slot:item.cvrAmt="{ value }">
                  {{ inputComma(value)}}
                </template>

                <template v-slot:item.monthlyPrem="{ value }">
                  {{ inputComma(value)}}
                </template>

                <template v-slot:item.etcItem01="{ value }">
                  <span v-if="data.insCd === 'N05'">
                    {{ changedData(value, "0", "해당없음") }}
                    {{ changedData(value, "1", "1급") }}
                    {{ changedData(value, "2", "2급") }}
                    {{ changedData(value, "3", "3급") }}
                  </span>
                  <span v-else>{{ value }}</span>
                </template>

                <template v-slot:item.etcItem02="{ value }">
                  <span v-if="data.insCd === 'N05'">
                    {{ changedData(value, "Y030", "30년만기") }}
                    {{ changedData(value, "A060", "60세만기") }}
                  </span>
                  <span v-else-if="data.insCd === 'N08'">
                    {{ changedData(value, "1", "자가용") }}
                    {{ changedData(value, "2", "영업용") }}
                    {{ changedData(value, "5", "비운전자") }}
                  </span>
                  <span v-else>{{ value }}</span>
                </template>

                <template v-slot:item.etcItem03="{ value }">
                  <span v-if="data.insCd === 'N05'">
                    {{ changedData(value, "Y010", "10년납") }}
                  </span>
                  <span v-else-if="data.insCd === 'N08'">
                    {{ changedData(value, "1", "신규") }}
                    {{ changedData(value, "2", "갱신") }}
                    {{ changedData(value, "*", "*") }}
                  </span>
                  <span v-else>{{ value }}</span>
                </template>

                <template v-slot:item.etcItem04="{ value }">
                  <span v-if="data.insCd === 'N05'">
                    {{ changedData(value, "0", "해당없음") }}
                    {{ changedData(value, "1", "자가용") }}
                    {{ changedData(value, "2", "영업용") }}
                    {{ changedData(value, "3", "비운전자") }}
                  </span>
                  <span v-else>{{ value }}</span>
                </template>

                <template v-slot:item.etcItem05="{ value }">
                  <span v-if="data.insCd === 'N01'">
                    {{ changedData(value, "1", "해약환급금미지급형(납기후50%)") }}
                    {{ changedData(value, "2", "해약환급금 지급형") }}
                    {{ changedData(value, "9", "해약환급금미지급형 비교상품") }}
                  </span>
                  <span v-else-if="data.insCd === 'N05'">
                    {{ changedData(value, "0", "전체") }}
                    {{ changedData(value, "1", "신규") }}
                    {{ changedData(value, "2", "갱신") }}
                  </span>
                  <span v-else-if="data.insCd === 'N08'">
                    {{ changedData(value, "1", "표준") }}
                    {{ changedData(value, "2", "할증") }}
                  </span>
                  <span v-else>{{ value }}</span>
                </template>

              </v-data-table>
            </v-flex>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ins-cvr-modal
      :is-show="isShowInsCvrCd"
      @close="isShowInsCvrCd = false"
      @row="insRow"
    />
  </v-overlay>
</template>

<script>
import InsCvrModal from "@/pages/Product/components/ProductCompareManagement/InsCvrModal/InsCvrModal";
import ProductRecommend from "@/util/ProductRecommend";
import _ from "lodash";
import TableMixin from "@/mixin/TableMixin";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  name: "InsCvrMappingBasicDataDetail",
  props: ["isShow", "rowData"],
  mixins: [TableMixin],
  components: {InsCvrModal},
  async created() {
    this.insCdList = await ProductRecommend.getInsCd();
  },
  data() {
    return {
      data: {},
      loading: false,
      headers: [
        {text: '보험기간', value: 'insTrm', align: 'center'},
        {text: '납입기간', value: 'pyTrm', align: 'center'},
        {text: '가입금액', value: 'cvrAmt', align: 'right'},
        {text: '월납보험료', value: 'monthlyPrem', align: 'right'},
        {text: '생성일자', value: 'createDate', align: 'center'}
      ],
      insCd: "", insCdList: [],
      insTrm: 0,
      insTrmList: [
        {insTrmNm: "전체", insTrm: 0}
      ],
      pyTrm: 0,
      pyTrmList: [
        {pyTrmNm: "전체", pyTrm: 0}
      ],
      sex: 1, beforeSex: 1,
      sexList: [
        {sexNm: "남자", sex: 1},
        {sexNm: "여자", sex: 2}
      ],
      age: 30, beforeAge: 0,
      prCd: "", prCdList: [], prNm: "",
      cvrCd: "", cvrNm: "",
      isShowInsCvrCd: false,
      insRowData: {},
      searchData: []
    }
  },
  watch: {
    isShow() {
      this.data = _.cloneDeep(this.rowData);
      this.getPrCdList(this.data.prProdLineCd, this.data.insCd, this.data.useYn);

    }
  },
  computed: {
    getHeaders() {
      if (this.data.insCd === 'N01') { // 메리츠화재
        return this.headers.concat([
          {text: '위험급수', value: 'etcItem01', align: 'center'},
          {text: '계산전보기', value: 'etcItem02', align: 'center'},
          {text: '계산전납기', value: 'etcItem03', align: 'center'},
          {text: '담보레이아웃번호', value: 'etcItem04', align: 'center'},
          {text: '유형구분', value: 'etcItem05', align: 'center'}
        ]);
      } else if (this.data.insCd === 'N02') { // 한화손보
        return this.headers.concat([
          {text: '급수코드', value: 'etcItem01', align: 'center'},
          {text: '종구분코드', value: 'etcItem02', align: 'center'},
          {text: '운전형태코드', value: 'etcItem03', align: 'center'},
          {text: '신규갱신구분', value: 'etcItem04', align: 'center'},
          {text: '세만기', value: 'etcItem05', align: 'center'},
          {text: '요율구분코드', value: 'etcItem06', align: 'center'}
        ]);
      } else if (this.data.insCd === 'N03') { // 롯데손보
        return this.headers.concat([
          {text: '위험급수', value: 'etcItem01', align: 'center'},
          {text: '용도', value: 'etcItem02', align: 'center'},
          {text: '단체', value: 'etcItem03', align: 'center'},
          {text: '물건', value: 'etcItem04', align: 'center'},
          {text: '기타', value: 'etcItem05', align: 'center'},
          {text: '만기구분', value: 'etcItem06', align: 'center'}
        ]);
      } else if (this.data.insCd === 'N05') { // 흥국화재
        return this.headers.concat([
          {text: '요율급수', value: 'etcItem01', align: 'center'},
          {text: '보기구분', value: 'etcItem02', align: 'center'},
          {text: '납기구분', value: 'etcItem03', align: 'center'},
          {text: '운전형태', value: 'etcItem04', align: 'center'},
          {text: '신규갱신구분', value: 'etcItem05', align: 'center'},
          {text: '보이싱', value: 'etcItem06', align: 'center'}
        ]);
      } else if (this.data.insCd === 'N08') { // 삼성화재
        return this.headers.concat([
          {text: '위험급수', value: 'etcItem01', align: 'center'},
          {text: '운전자형태', value: 'etcItem02', align: 'center'},
          {text: '신규갱신구분', value: 'etcItem03', align: 'center'},
          {text: '갱신종료연령', value: 'etcItem04', align: 'center'},
          {text: '표준체여부', value: 'etcItem05', align: 'center'},
          {text: '비비례가입금액', value: 'etcItem06', align: 'center'}
        ]);
      } else if (this.data.insCd === 'N09') { // 현대해상
        return this.headers.concat([
          {text: '위험급수', value: 'etcItem01', align: 'center'},
          {text: '구분', value: 'etcItem02', align: 'center'}
        ]);
      } else if (this.data.insCd === 'N10') { // KB손보
        return this.headers.concat([
          {text: '요율급수', value: 'etcItem01', align: 'center'},
          {text: '요율키1', value: 'etcItem02', align: 'center'},
          {text: '요율키2', value: 'etcItem03', align: 'center'},
          {text: '요율키3', value: 'etcItem04', align: 'center'},
          {text: '요율키4', value: 'etcItem05', align: 'center'},
          {text: '요율키5', value: 'etcItem06', align: 'center'}
        ]);
      } else if (this.data.insCd === 'N13') { // DB손보
        return this.headers.concat([
          {text: '요율급수', value: 'etcItem01', align: 'center'},
          {text: '담보레이아웃번호', value: 'etcItem02', align: 'center'}
        ]);
      } else if (
          this.data.insCd === 'L11' || // 신한라이프
          this.data.insCd === 'L71' || // DB생명
          this.data.insCd === 'L72') { // 메트라이프
        return this.headers.concat([
          {text: '해약환급금 연결키', value: 'etcItem01', align: 'center'}
        ]);
      } else {
        return this.headers;
      }
    }
  },
  methods: {
    showInsCvrCdModal() {
      this.isShowInsCvrCd = true;
    },
    async getPrCdList(prProdLineCd, insCd, useYn) {
      this.prCdList = await ProductRecommend.getPrCdList(prProdLineCd, insCd, useYn);
      if (this.prCdList.length < 1) {
        this.prCdList.unshift({
          prCd: "없음"
        });
        this.data.prCd = _.cloneDeep("없음");
        alert("보험사에 해당하는 상품코드가 없습니다. 보험사를 다시 선택해주세요.");
      }
    },
    changeData(type) {
      if (type === 'insCd') {
        this.data.prCd = "";
        this.data.prNm = "";
        this.data.cvrCd = "";
        this.data.cvrNm = "";
        this.prCdList = [];
        this.getPrCdList(this.data.prProdLineCd, this.data.insCd, this.data.useYn);
      } else if (type === 'prCd') {
        for (let i in this.prCdList) {
          if (this.data.prCd === this.prCdList[i].prCd) {
            this.data.prNm = this.prCdList[i].prNm;
          }
        }
      }
    },
    insRow(row) {
      this.cvrCd = row.cvrCd;
      this.cvrNm = row.cvrNm;

      this.insRowData = row;
    },
    async getSearch() {
      if (this.data.prCd === "없음") {
        alert("조회조건을 맞춰 다시 조회해주세요.");
      }
      // 이전과 조회 조건이 다를경우 리스트 초기화
      if (this.beforeAge !== this.age || this.beforeSex !== this.sex) {
        this.resetData();
      }
      const params = {
        insCd: this.data.insCd,
        prCd: this.data.prCd,
        cvrCd: this.data.cvrCd,
        insTrm: this.insTrm,
        pyTrm: this.pyTrm,
        sex: this.sex,
        age: Number(this.age)
      }
      this.loading = true;
      try {
        const { data } = await axiosInstance.get('/admin/prdata/getPrInsComPrem', { params });
        this.convertEmptyData(data.outInsComPremData);
        this.searchData = data.outInsComPremData;
        for (let i in this.searchData) {
          this.searchData[i].createDate = this.searchData[i].createDate.replace('T', ' ').substr(0, 19);
        }

        this.setTrmInfo(data.outTrmData);

        // data 수신 이후 기존 값 비교를 위한 before 변수 설정
        this.beforeSex = this.sex;
        this.beforeAge = this.age;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      this.loading = false;
    },
    // 기간정보 리스트 구성
    setTrmInfo(trmData){
      let insTrmListOfData = [];
      let insPyListOfData = [];

      trmData.forEach(data => {
        if (data.type === 'ins_trm') {
          insTrmListOfData.push({
            insTrmNm: (data.trm).toString() + '년',
            insTrm: data.trm
          })
        } else if (data.type === 'py_trm') {
          insPyListOfData.push({
            pyTrmNm: (data.trm).toString() + '년',
            pyTrm: data.trm
          })
        }
      });

      this.insTrmList = [...this.insTrmList, ...insTrmListOfData];
      this.pyTrmList = [...this.pyTrmList, ...insPyListOfData];
    },
    close() {
      this.age = 30;
      this.resetData();
      this.$emit("close");
    },
    resetData(){
      this.searchData = [];
      this.insTrm = 0;
      this.pyTrm = 0;
      this.insTrmList = [{insTrmNm: "전체", insTrm: 0}];
      this.pyTrmList = [{pyTrmNm: "전체", pyTrm: 0}];
    },
    convertEmptyData(tableData) {
      for (let i in tableData) {
        for (const key in tableData[i]) {
          if (tableData[i][key] === "") {
            tableData[i][key] = "없음";
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 30px;
}
.header-title {
  font-size: 25px;
  font-weight: bolder;
  margin-top: 10px;
}
.close-button {
  cursor: pointer;
}
.search-button {
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 30px;
}
</style>