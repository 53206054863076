<template>
  <search-layout title="관리자 권한관리">

    <template v-slot:table>
      <admin-table
          :items="data"
          :headers="headers"
      />
    </template>
  </search-layout>
</template>

<script>
import { ADMIN_MANAGE_HEADER } from "@/util/TableHeaders";
import SearchLayout from "@/layouts/SearchPageLayout";
import AdminTable from "@/pages/Manage/Admin/AdminTable";

export default {
  components: {
    AdminTable,
    SearchLayout
  },
  data: () => ({
    headers: ADMIN_MANAGE_HEADER,
    data: [
      {
        name : "임대성",
        memberId : "dslim@a-in.co",
        telNum : "01087530343",
        menu : "적용"
      }
    ],
    loading: false,
    type: "ALL",
    searchText: ""
  }),
  methods: {}
}
</script>