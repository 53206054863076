<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
          <v-select
              @change="getTableId"
              @click="selectCd"
              v-model="dataDto.insCd"
              :items="insurerCode"
              label="선택"
              background-color="white"
              item-text="value"
              item-value="cd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
        <v-col cols="4">상품코드</v-col>
        <v-select
            @change="cvrCdList"
            v-model="dataDto.prCd"
            :items="prCdList"
            label="선택"
            background-color="white"
            item-text="text"
            item-value="prCd"
            class="subBar"
            hide-details outlined dense
        />
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">플랜코드</v-col>
          <v-select
              v-model="dataDto.planCd"
              :items="planCdList"
              label="선택"
              background-color="white"
              item-text="text"
              item-value="planCd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          생성저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
export default {
  data:() => ({
    dataDto: {
      'insCd': '',
      'prCd': '',
      'planCd': ''
    },
    insurerCode: [
      {value: '선택', cd: ''}
    ],
    prCdList: [],
    planCdList:[],
  }),
  methods: {
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    async getTableId() {
      this.planCdList = [];
      this.prCdList = [];
      const params = {
        insCd: this.dataDto.insCd,
        srhType: "2"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prCd + ' (' + data[i].prNm + ')';
          objJson.prCd = data[i].prCd;
          this.prCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async cvrCdList() {
      this.cvrList = [];
      const params = {
        insCd: this.dataDto.insCd,
        prCd: this.dataDto.prCd,
        srhType: "3"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].planCd + ' (' + data[i].planNm + ')';
          objJson.planCd = data[i].planCd;
          this.planCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async saveData() {
      alert("2~3분정도 시간이 소유됩니다.");
      try {
        await axiosInstance.post('/admin/prdata/prPremSampleReg', null, {
          params: {
            insCd: this.dataDto.insCd,
            prCd: this.dataDto.prCd,
            planCd: this.dataDto.planCd,
          }
        });
      } catch(e) {
        console.log(e);
      }
    }
  }
}
</script>