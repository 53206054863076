<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
    <custom-layout title="작업단계 변경">
      <v-card width="65vw" height="100%" max-width="850px" max-height="80vh">
        <v-row class="pa-5 pr-10">
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">작업단계</v-col>
            <v-select
              v-model="changeData.wkStepCd"
              @change="setWkSubStepList(changeData.wkStepCd)"
              :items="wkStepList"
              item-text="wkStepNm"
              item-value="wkStepCd"
              outlined hide-details dense
              background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">변경대상건수</v-col>
            <v-col>{{ rowData.length }}건</v-col>
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">세부단계</v-col>
            <v-select
                v-model="changeData.wkSubStepCd"
                :items="wkSubStepList"
                item-text="wkSubStepNm"
                item-value="wkSubStepCd"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">세부상태</v-col>
            <v-select
                v-model="changeData.wkSubStateCd"
                :items="wkSubStateList"
                item-text="wkSubStateNm"
                item-value="wkSubStateCd"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">업데이트일자</v-col>
            <date-input
                :isSingleDate="true"
                :singleDate="changeData.newDispEndDt"
                @updateDate="updateDate"
                :disabled="checkStepCd"
            />
          </v-col>
          <v-col class="col-12 ml-3">
            * 업데이트일자는 개정확인/개발계반영 단계에서만 표시 및 입력 가능
          </v-col>
          <v-col class="d-flex justify-start align-center col-12">
            <v-col cols="2">메모</v-col>
            <v-textarea
                v-model="changeData.wkMsgCont"
                class="custom-textarea"
                height="100%"
                max-height="300px"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
        </v-row>

        <v-card-actions class="d-flex justify-center">
          <v-btn class="red darken-1 white--text" @click="closePopup">닫기</v-btn>
          <v-btn class="blue darken-4 white--text" @click="save">저장</v-btn>
        </v-card-actions>
      </v-card>
    </custom-layout>
  </v-overlay>
</template>
<script>
import CustomLayout from "@/layouts/CustomLayout";
import DateInput from "@/components/Form/DateInput.vue";
import ProductRecommend from "@/util/ProductRecommend";
import {axiosInstance} from "@/util/AxiosModule";
import  _ from "lodash";
import store from "@/store";
import {API_SERVER_URL_DEV} from "@/util/constant"

export default {
  components: {DateInput, CustomLayout},
  props: ["isShow", "wkStepList", "wkSubStateList", "rowData"],
  data() {
    return {
      wkSubStepList: [],
      dataList: [],
      defaultChangeData: {},
      changeData: {
        wkStepCd: "",
        wkSubStepCd: "",
        wkSubStateCd: "",
        wkMsgCont: "",
        newDispEndDt: ""
      }
    }
  },
  computed: {
    checkStepCd() {
      return this.changeData.wkStepCd !== "100" && this.changeData.wkStepCd !== "700";
    }
  },
  watch: {
    isShow(value) {
      if (value) {
        this.dataList = _.cloneDeep(this.rowData);
        this.defaultChangeData = _.cloneDeep(this.changeData);
        this.wkStepList.unshift({
          wkStepNm: "선택",
          wkStepCd: ""
        });
        this.wkSubStepList.unshift({
          wkSubStepNm: "선택",
          wkSubStepCd: ""
        })
        this.wkSubStateList.unshift({
          wkSubStateNm: "선택",
          wkSubStateCd: ""
        });
      }
    }
  },
  methods: {
    setDefaultStepData() {  // 기본 데이터 초기화
      this.changeData = {
        wkStepCd: "",
        wkSubStepCd: "",
        wkSubStateCd: "",
        wkMsgCont: "",
        newDispEndDt: ""
      };
    },
    async setWkSubStepList(code) {
      if (code === "") {
        this.wkSubStepList = [];
        this.changeData.wkSubStepCd = "";
        this.wkSubStepList.unshift({wkSubStepNm: "선택", wkSubStepCd: ""});
        return false;
      }
      this.wkSubStepList = await ProductRecommend.getWkSubStepList(code);
      this.changeData.wkSubStepCd = this.wkSubStepList[0].wkSubStepCd;
    },
    updateDate(date) {
      this.changeData.newDispEndDt = date;
    },
    async save() {
      let updateIn = this.dataList.map(item => ({
        appFromDt: item.appFromDt.replace(/-/g, ""),
        appToDt: item.appToDt.replace(/-/g, ""),
        insCd: item.insCd,
        newDispEndDt: this.changeData.newDispEndDt.replace(/-/g, ""),
        prCd: item.prCd,
        prNm: item.prNm,
        prProdLineCd: item.prProdLineCd,
        regId: store.getters["login/memberId"],
        wkStepCd: this.changeData.wkStepCd,
        wkSubStepCd: this.changeData.wkSubStepCd,
        wkSubStateCd: this.changeData.wkSubStateCd,
        wkMsgCont: this.changeData.wkMsgCont,
        wkPrId: item.wkPrId,
        wkMsgId: 0,
        useYn: "Y"
      }));

      const checkUpdateIn = updateIn[0];

      // 1. 필수값 누락 확인
      if (_.isEmpty(checkUpdateIn.wkStepCd)) {
        alert("작업단계를 선택해주세요.");
        return false;
      }
      if (_.isEmpty(checkUpdateIn.wkSubStepCd)) {
        alert("세부단계를 선택해주세요.");
        return false;
      }
      if (_.isEmpty(checkUpdateIn.wkSubStateCd)) {
        alert("세부상태를 선택해주세요.");
        return false;
      }

      if (confirm("총 " + updateIn.length + "건의 변경사항을 저장하시겠습니까?")) {
        try {
          await axiosInstance.post("/admin/prdata/updatePrWkProc", updateIn);
          // prod -> dev 단방향 동기화
          if (process.env.NODE_ENV !== 'development') {
            await axiosInstance.post(API_SERVER_URL_DEV + "/admin/prdata/updatePrWkProc", updateIn);
            alert("저장 및 개발계와 동기화 완료");
          } else {
            alert("저장되었습니다.");
          }

          this.$emit("updateData");
          this.closePopup();
        } catch (error) {
          console.log(error);
        }
      }
    },
    closePopup() {
      this.setDefaultStepData();
      this.$emit("close");
    }
  }
}
</script>

<style scoped>
.custom-textarea {
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
}
</style>