<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 ({{ tableTitle }})</span>
      <outlined-btn class="float-right" @click="$emit('toExcel')" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
      <outlined-btn v-if="selectModifyRow" class="float-right" style="margin-right: 10px;" @click="openModifyModal" :disabled="!hasTableData">정보수정</outlined-btn>
      <outlined-btn v-if="selectEarlySettleRow" class="float-right" style="margin-right: 10px;" @click="openEarlySettleModal" :disabled="!hasTableData">조기정산연결</outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          v-model="selectData"
          :items="data"
          item-key="index"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          show-select multi-sort="multi-sort"
          :single-select="true"
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          @click:row="setModalData"
      >
        <template v-slot:item.contractPremAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.rewardAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.customerTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <template v-slot:item.recipientTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <template v-slot:item.checkResultYn="{ value }">
          {{ value }} <v-btn v-if="value != null" x-small class="ml-auto primary mb-1" @click="showModal(false)">상세보기</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <settlement-detail-modal
        :is-show="isShow"
        :is-modify="isModify"
        :settlement-data="modalData"
        @close="close"
        @update="update"
    />
    <settlement-detail-modify
      :selectData="selectData"
      :is-modify-modal="isModifyModal"
      @close="closeModifyModal"
      />
    <settlement-detail-early-settle-modal
      :select-data="selectData"
      :is-show="isEarlySettleModal"
      @close="isEarlySettleModal = false"
      @resetData="resetData"
    />
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {SETTLEMENT_DETAIL} from "@/util/TableHeaders";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import SettlementDetailModal from "./SettlementDetailModal";
import _ from "lodash";
import {axiosInstance} from "@/util/AxiosModule";
import SettlementDetailModify from "@/pages/Settlement/components/SettlementDetail/SettlementDetailModify";
import SettlementDetailEarlySettleModal
  from "@/pages/Settlement/components/SettlementDetail/SettlementDetailEarlySettleModal";

export default {
  components: {SettlementDetailEarlySettleModal, SettlementDetailModify, SettlementDetailModal, OutlinedBtn},
  mixins: [TableMixin],
  props: ["data", "loading"],
  computed: {
    hasTableData() {
      this.tableTitleUpdate();
      return this.data.length > 0;
    },
    selectModifyRow() {
      if (this.selectData && this.selectData.length > 0) {
        return this.selectData.every(item => item.rewardStatus === "지급예정");
      }
      return false;
    },
    selectEarlySettleRow() {
      if (this.selectData && this.selectData.length > 0) {
        return this.selectData.every(item => (item.rewardStatus === "지급예정") && (item.recipientNm === "guest"));
      }
      return false;
    }
  },
  data: () => ({
    headers: SETTLEMENT_DETAIL,
    isShow: false,
    isModify: false,
    modalData: {},
    tableTitle: "총 0개",
    selectData: [],
    isModifyModal: false,
    isEarlySettleModal: false
  }),
  methods: {
    showModal(isModify) {
      if(!isModify) this.modalData = {};
      this.isModify = isModify;
      this.isShow = true;
    },
    setModalData(evt, { item }) {
      if(item.checkResultYn != null){
        this.ckData(item.carSettlementHistId,item);
      }
    },
    async ckData(id,item){
      const cloneData =_.cloneDeep(Object.assign({},item));
      const {data} = await axiosInstance.get('/admin/settlement/check_opinion', {
        params: {
          carSettlementHistId: id
        }
      });
      cloneData.checkerNm = data.checkerNm;
      cloneData.createDate = data.createDate;
      cloneData.updateDate = data.updateDate;
      cloneData.checkerOpinion = data.checkerOpinion;

      this.modalData = cloneData;
      this.showModal(true);
    },
    update() {
      this.$emit('update');
    },
    tableTitleUpdate(){
      let cnt = this.data.length;

      if(cnt == 0)
      {
        this.tableTitle = "총 0개";
      }
      else
      {
        //금액합산
        const summary = this.data.reduce((a, c) => ({
          contractPremAmt: a.contractPremAmt + c.contractPremAmt,
          rewardAmt: a.rewardAmt + c.rewardAmt}))

        this.tableTitle = "총 " + this.data.length + "개 | 계약보험료합 " + this.inputComma(summary.contractPremAmt) + "원 | 리워드합 " + this.inputComma(summary.rewardAmt) + "원";
      }
    },
    openModifyModal() {
      this.isModifyModal = true;
    },
    close() {
      this.selectData = [];
      this.isShow = false;
    },
    closeModifyModal() {
      this.isModifyModal = false;
      this.selectData = [];
    },
    openEarlySettleModal() {
      this.isEarlySettleModal = true;
    },
    resetData() {
      this.selectData = [];
    }
  }
}
</script>