<template>
  <v-overlay
    v-model="isModifyModal"
    :light="true"
    :dark="false"
    >
    <v-flex>
      <v-card max-width="80vw" max-height="80vh" class="overflow-y-auto card-layout">
        <v-card-title class="header-title">
          <h2 class="font-weight-bold">정산자료 정보수정</h2>
          <span class="close-button" @click="close">X</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-flex xs12 sm12 md12 xl12>
          <v-col cols="12" class="py-0">
            <v-data-table
                height="20vh"
                :items="selectData"
                :headers="getHeader(headers)"
                fixed-header calculate-widths hide-default-footer
                @click:row="clickRow"
            >
              <template v-slot:item.contractPremAmt="{ value }">
                <div class="jc-fe">{{ inputComma(value) }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-flex>
        <v-form>
          <v-card-text>
            <v-row class="row-container">
              <v-col cols="5">
                <v-text-field
                    label="회원명"
                    v-model="recipientData.recipientNm"
                    background-color="white" outlined
                />
              </v-col>
              <v-col cols="5">
                <v-text-field
                    label="회원전화번호"
                    v-model="recipientData.recipientTelNum"
                    background-color="white" outlined
                />
              </v-col>
              <v-col cols="2">
                <v-btn @click="searchRecipient">조회</v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="7">
                <v-text-field
                    label="회원아이디"
                    v-model="recipientId"
                    background-color="white" outlined disabled
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="회원명"
                    v-model="recipientNm"
                    background-color="white" outlined disabled
                />
              </v-col>
              <v-col cols="2">
                <v-checkbox @click="clickCheckbox" v-model="checkBox" label="선택"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-text-field
                    label="계약고객명"
                    v-model="customerNm"
                    background-color="white" outlined
                />
              </v-col>
              <v-col cols="5">
                <v-text-field
                    label="계약차량번호"
                    v-model="customerCarNo"
                    background-color="white" outlined
                />
              </v-col>
              <v-col cols="2">
                <v-btn @click="saveData">저장</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {SETTLEMENT_DETAIL_MODIFY_TABLE} from "@/util/TableHeaders";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
import _ from "lodash";

export default {
  props: ["isModifyModal", "selectData"],
  mixins: [TableMixin],
  data() {
    return {
      headers: SETTLEMENT_DETAIL_MODIFY_TABLE,
      oldRecipientId: "", oldRecipientNm: "",
      recipientId: "", recipientNm: "",
      customerNm: "", customerCarNo: "",
      recipientData: [],
      checkBox: false,
      checkboxData: {
        newRecipientId: "",
        newRecipientNm: "",
      },
      settlementDate: "",
      carSettlementHistId: "",
      contractPremAmt: "",
      rewardStatus: ""
    }
  },
  watch: {
    selectData(value) {
      for (let i in value) {
        this.customerNm = value[i].customerNm;
        this.customerCarNo = value[i].customerCarNo;
        this.carSettlementHistId = value[i].carSettlementHistId;
      }
    }
  },
  methods: {
    async searchRecipient() {
      let searchData = this.recipientData;
      let params;

      if ((searchData.recipientNm === undefined) && (searchData.recipientTelNum === undefined)) {
        alert("회원명 및 회원전화번호를 입력 후 다시 조회해주세요.");
        return false;
      } else if (searchData.recipientNm === null) {
        alert("회원명이 없습니다. 다시 확인해 주세요.");
        return false;
      } else if (searchData.recipientTelNum === null) {
        alert("회원전화번호가 없습니다. 다시 확인해 주세요.");
        return false;
      } else {
        params = {
          recipientNm: searchData.recipientNm,
          recipientTelNum: searchData.recipientTelNum.replace(/[^0-9]/g, '')
        }
      }
      try {
        const { data } = await axiosInstance.get('/admin/settlement/recipient', { params });
        this.recipientId = data.recipientId;
        this.recipientNm = data.recipientNm;
      } catch (e) {
        console.log(e);
      }
    },
    clickCheckbox() {
      if (this.checkBox === true) {
        this.checkboxData.newRecipientId = this.recipientId;
        this.checkboxData.newRecipientNm = this.recipientNm;
      } else if (this.checkBox === false) {
        for (let i in this.selectData) {
          this.checkboxData.newRecipientId = this.selectData[i].recipientId;
          this.checkboxData.newRecipientNm = this.selectData[i].recipientNm;
        }
      }
    },
    async saveData() {
      this.clickCheckbox();
      let SettlementRecipientUpdateDto = {
        carSettlementHistId: this.carSettlementHistId,
        newRecipientId: this.checkboxData.newRecipientId,
        newRecipientNm: this.checkboxData.newRecipientNm,
        customerCarNo: this.customerCarNo,
        customerNm: this.customerNm,
        managerUserId: store.getters["login/memberId"]
      }
      console.log(SettlementRecipientUpdateDto);
      if (confirm("저장하시겠습니까?")) {
        try {
          await axiosInstance.post('/admin/settlement/recipient', SettlementRecipientUpdateDto);
          alert("저장완료되었습니다.");
          this.close();
        }catch (e) {
          console.log(e);
        }
      }
    },
    close() {
      this.checkBox = false;
      this.checkboxData.recipientId = "";
      this.checkboxData.recipientNm = "";
      this.recipientData = [];
      this.recipientId = "";
      this.recipientNm = "";
      this.oldRecipientId = "";
      this.oldRecipientNm = "";
      this.customerCarNo = "";
      this.customerNm = "";
      this.$emit('close');
    },
    clickRow(row) {
      this.recipientData = _.cloneDeep(row);
    }
  }
}
</script>

<style scoped>
.card-layout {
  padding: 10px 20px;
}

.header-title {
  display: flex;
  justify-content: space-between;
}
.header-title .close-button:hover {
  cursor: pointer;
}

.row-container .v-col {
  display: flex;
  justify-content: center;
  justify-items: center;
}
</style>