var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-5 px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right",staticStyle:{"margin-bottom":"10px"}},[_c('span',{staticClass:"total-count"},[_vm._v("총 : "+_vm._s(_vm.data.length)+" 건")]),_c('v-btn',{staticClass:"white--text px-8",staticStyle:{"margin-right":"3px"},attrs:{"color":"red"},on:{"click":_vm.save}},[_vm._v("저장")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"70vh","item-key":"idx","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"headers":_vm.getHeader(_vm.headers),"fixed-header":"","calculate-widths":"","footer-props":{'items-per-page-options': [10, 30, 50 , -1]}},scopedSlots:_vm._u([{key:"item.useYn",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"width-100",attrs:{"items":_vm.useYnList,"dense":"","outlined":"","hide-details":"","background-color":"white"},on:{"change":function($event){return _vm.changeUseYn(item.index)}},model:{value:(item.useYn),callback:function ($$v) {_vm.$set(item, "useYn", $$v)},expression:"item.useYn"}})]}},{key:"item.minAmt",fn:function(ref){
var header = ref.header;
var item = ref.item;
var value = ref.value;
return [(item.cvrType === 'F')?_c('input',{staticClass:"jc-fe",attrs:{"disabled":"","type":"text"},domProps:{"value":_vm.inputComma(value)}}):_c('input',{staticClass:"jc-fe",attrs:{"disabled":"","type":"text"},domProps:{"value":_vm.inputComma(value)}})]}},{key:"item.maxAmt",fn:function(ref){
var header = ref.header;
var item = ref.item;
var value = ref.value;
return [(item.cvrType === 'F')?_c('input',{staticClass:"jc-fe",attrs:{"disabled":"","type":"text"},domProps:{"value":_vm.inputComma(value)}}):_c('input',{staticClass:"jc-fe",attrs:{"disabled":"","type":"text"},domProps:{"value":_vm.inputComma(value)}})]}},{key:"item.stepAmt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.getRowData(item.index)}}},[_vm._v(" 상세보기 ")])]}},{key:"item.stepCnt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.stepCnt))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }