<template>
  <search-layout title="증명사진 테스트">
    <template v-slot:searchBar>
      <search-bar @search="click" @loadImage="loadImage"/>
    </template>

    <template v-slot:table>
      <v-col ref="cola" cols="6" class="py-0">
        <v-col cols="12" class="d-flex pa-0">
          <span class="text-h6 font-weight-bold">처리전</span>
        </v-col>
        <v-col cols="12">
          <v-img max-width="900" max-height="900" v-if="previewImgUrl" :src="previewImgUrl"/>
        </v-col>

      </v-col>
      <v-col ref="cola" cols="6" class="py-0">
        <v-col cols="12" class="d-flex pa-0">
          <span class="text-h6 font-weight-bold">처리후</span>
        </v-col>
        <v-col cols="12">
          <v-img max-width="900" max-height="900" v-if="newPreviewImgUrl" :src="newPreviewImgUrl"/>
        </v-col>

      </v-col>
    </template>
  </search-layout>
</template>

<script>
 import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";
import SearchLayout from "@/layouts/SearchPageLayout";
import SearchBar from "@/pages/Manage/Test/components/IdPhotoSearchBar";


export default {
  components: {
    SearchBar,
    SearchLayout
  },
  data:() => ({
    previewImgUrl:null,
    newPreviewImgUrl:null
  }),
  methods: {
    loadImage(data) {
      this.previewImgUrl = data;
      this.newPreviewImgUrl = null;
    },
    async click(selectFile) {
      let params = {};
      params.memberId = store.getters['login/memberId'];
      params.fileString = this.previewImgUrl;
      params.fileType = selectFile.type;
      params.fileName = selectFile.name;
      params.fileSize = selectFile.size;
      const { data } = await axiosInstance.post("/private/businessCard/removebg", params);
      this.newPreviewImgUrl = data;
    }
  }
}
</script>

