<template>
  <v-flex xs12 sm12 md12 lg12 xl12>
    <div class="d-flex align-end mb-1 mt-2">
      <span class="text-h6 font-weight-bold">목록(총 {{ data.length }}개)</span>
      <div class="ml-auto pb-1 d-flex align-end">
        <v-btn
            color="blue darken-4"
            class="primary darken-1 mr-2"
            @click="setModalData"
        >메뉴신규등록
        </v-btn>
      </div>
    </div>
    <v-data-table
        height="50vh"
        :items="data"
        :loading="loading"
        :items-per-page="-1"
        fixed-header disable-sort calculate-widths
        :headers="headers"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
    </v-data-table>
    <user-group-save-modal
        :is-show="isSaveShow"
        :user-data="modalData"
        @close="isSaveShow = false"
        @update="update"
    />
  </v-flex>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import UserGroupSaveModal from "./UserGroupSaveModal";

export default {
  components: {UserGroupSaveModal},
  props: ["data", "loading"],
  mixins: [TableMixin],
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  data:() =>({
    headers: [{text: "등록번호", value: "menuRegSeq", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "메뉴명", value: "menuNm", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "URL", value: "menuUrl", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "사용자그룹", value: "userGroupNm", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "사용권한여부", value: "authUseYn", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "유료여부", value: "paidUseYn", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "인원수", value: "memberCnt", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "등록자", value: "regNm", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "등록일시", value: "updateDate", class: "gray lighten-4", align: "center", sortable: false, width: "8vw"}
    ]
    ,useYN: [
      {bname: 'N', bvalue:'N'},
      {bname: 'Y', bvalue:'Y'},
    ],
    isSaveShow:false,
    modalData:[]
  }),
  methods: {
    changeType(val, item) {
      if (val === 'N') {
        item.resultCnt = 'Y'
      } else {
        item.resultCnt = 'N'
      }
    },
    setModalData() {
      this.isSaveShow = true;
    },
    update() {
      this.$emit('update');
    }
  }
}
</script>