<template>
    <v-dialog
			v-model="show"
			max-width="1200px"
			persistent
    >
		<v-layout column>
			<v-flex>
				<v-card>
					<v-card-title>
            <span class="text-h5">파일/이미지 등록</span>
          </v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
                <v-col cols="12">	
								<v-text-field
										v-model="registTag"
                    label="태그 입력 (ex: #보장분석 #컨텐츠 #이미지)"
                  ></v-text-field>
								</v-col>
							</v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="registTitle"
                      label="제목 입력"
                  ></v-text-field>
                </v-col>
              </v-row>
							<v-row>
								<v-col cols="10">
									<v-text-field
										label="이미지/PDF 파일 선택"
										v-model="registData.fileName"
										:disabled=true
										outlined dense hide-details
										background-color="white" menu-props="auto"
									/>
								</v-col>
								<v-col cols="1" >
									<v-btn
										class="blue white--text"
										@click="$refs.file.click()"
										:disabled="isDisabled"
									>파일선택
									</v-btn>
									<input @input="loadData" accept="image/*, .pdf" class="d-none" type="file" ref="file"/>

								</v-col>
								<v-col cols="1" align='center'>
									<v-btn
										class="red white--text"
										@click="show=false"
									> 취소
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<span class="ml-4 red--text">파일명은 영어, 숫자, 특수문자(_-)형식만 업로드해주세요.</span>
							</v-row>
						</v-container>	
					</v-card-text>
				</v-card>	
			</v-flex>
		</v-layout>
    </v-dialog>
</template>

<script>
import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";

export default {
    props:["dialog"],
    data: ()=> ({
			selectFile:null,
			registTag: "",
      registTitle: "",
			registData: {
				fileType:"",
				fileName:"",
				fileSize:"",
				fileString:null,
				width: 0,
				height: 0
			}
    }),
    computed: {
			show: {
				get() {
					return this.dialog
				},
				set(value) {
					this.$emit("updateSetting", value)
				}
			},
			isDisabled() {
				return this.registTag.trim().length == 0 || this.registTitle.trim().length == 0;
			}
    },
    methods: {
			// 파일 로드하여, 파일의 확장자를 구분함
			loadData({target: {files}}) {
				if (!files || files.length < 1)
				{
						return;
				}
				this.selectFile = files[0];

				//todo 파일명에 한글이 들어가면, alert 주는 내용인데, 안됨..
				// const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
				// console.log(korean.test(this.selectFile.name))
				// if(korean.test(this.selectFile.name[0])) {
				// 	alert("파일명은 영어, 숫자, 특수문자(_-)만 가능합니다.")
				// 	return
				// }
				
				// ex) type ="application/pdf" or "image/png"
				let fileType = this.selectFile.type.split("/")[1];
				
				switch(fileType) {
					case "png":
					case "jpg":
					case "jpeg":
						//확장자 이미지인경우, imageReader 호출
						this.imageReader(this.selectFile)
					break;
					case "pdf":
						//확장자 pdf인경우 pdfReader 호출
						this.pdfReader(this.selectFile)
					break;
			}
			},
			imageReader(selectFile){
				//파일크기는 최대 3MB까지
				if (selectFile.size > 3048576)
				{
					alert("파일 사이즈는 3MB 이하만 가능합니다.");
					return;
				}
				let reader = new FileReader();
				var self = this

				//image의 width, height 정보를 가져오기 위한 방법
				// LifeCycle = readAsDataURL -> reader.onload() 
				reader.onload = async e => {
					var imageURL = reader.result;
					this.$emit('loadImage', e.target.result);
					var image = new Image();
					image.onload =  async function() {   
						self.registData.width = this.naturalWidth
						self.registData.height = this.naturalHeight
						self.registData.fileString = this.src
						await self.clickUpload(self.registData)
						self.$emit('refresh', 'refresh');
					}
					image.src = imageURL;
				}
				this.registData.fileType = selectFile.type.split("/")[1];
				this.registData.fileName = selectFile.name.split(".")[0];
				this.registData.fileSize = selectFile.size;
				reader.readAsDataURL(selectFile);
			},
			pdfReader(selectFile) {

				// name : test.pdf
				// type : application/pdf
				// size : 1231423
				var self = this
				let reader = new FileReader();
				reader.onload = async e => {
					this.$emit('loadImage', e.target.result);
					var data = reader.result;
					var base64 = data.replace(/^[^,]*,/, '');
					self.registData.fileString = "data:application/pdf;base64," + base64
					await self.clickUpload(self.registData)
					self.$emit('refresh', 'refresh');
				}

				this.registData.fileName = selectFile.name.split(".")[0];
				this.registData.fileType = selectFile.type.split("/")[1];
				this.registData.fileSize = selectFile.size;
				reader.readAsDataURL(selectFile);

			},
			//백그라운드처리 요청
			async clickUpload(selectFile) {
				let params = {};
				params.regId = store.getters['login/memberId'];
				params.contFileInfoTag = this.registTag;
        params.contFileTitle = this.registTitle;
				params.fileString = selectFile.fileString;
				params.contFileType = selectFile.fileType;
				params.contFileNm = selectFile.fileName;
				params.fileSize = selectFile.fileSize;
				params.horizonSize = selectFile.width;
				params.verticalSize = selectFile.height;
				const { data } = await axiosInstance.post("/admin/operation/contFileInfo", params);
        this.$emit('result', data);
				//file upload 후, popup을 닫아준다.
				//파일명이랑 파일 정보를 초기화 해준다.
				this.show=false; // 팝업 닫기
				this.selectFile = ""; // 선택된 파일 초기화
				this.registTag = ""; // 파일 태그
        this.registTitle = ""; //제목
				this.registData.fileName = ""; // 선택된 파일명
			},
    }

}
  
</script>
