<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin: 0 3px 0 3px;"
          @click="toExcel"
          :disabled="data.length <= 0"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <!-- 숫자조건 콤마 추가 -->
        <template v-slot:item.intCond="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col></template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {APPLICATION_CONDITION_MANAGEMENT_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";
import DateMixin from "@/mixin/DateMixin";
export default {
  mixins: [TableMixin, DateMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: APPLICATION_CONDITION_MANAGEMENT_TABLE,
    }
  },
  methods: {
    clickContents(row) {
      this.$emit('row', row);
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "상품군코드": item["prProdLineCd"],
          "상품군명": item["prProdLineNm"],
          "적용조건코드": item["condDiv"],
          "적용조건명": item["condDivNm"],
          "적용조건세분": item["condDetail"],
          "담보명": item["creCvrNm"],
          "표기순서": item["dispOrder"],
          "숫자조건": item["intCond"],
          "숫자조건명": item["intCondNm"],
          "기본값여부": item["defaultYn"],
          "사용여부": item["useYn"],
          "등록일시": item["createDate"],
        }
        returnData.push(obj);
      }
      const sheetName = `상품군 적용조건 관리`;
      const fileName = `상품군 적용조건 관리`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>