<template>
  <search-page-layout title="자동차보험 만기안내List">

    <template v-slot:searchBar>
      <carExpirInfo-search-bar @getCarExpirInfo="getCarExpirInfo"/>
    </template>

    <template v-slot:table>
      <carExpirInfo-table
          :data="data"
          :loading="loading"
          @toExcel="toExcel"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import CarExpirInfoSearchBar from "@/pages/Operation/components/CarExpirInfo/CarExpirInfoSearchBar";
import CarExpirInfoTable from "@/pages/Operation/components/CarExpirInfo/CarExpirInfoTable";
import _ from "lodash";
import func from "@/util/func";

export default {
  props: ["name"],
  components: {
    CarExpirInfoTable,
    CarExpirInfoSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
    conditionHist: {}
  }),

  methods: {
    async getCarExpirInfo(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/operation/carexpirinfo', { params });
        this.conditionHist = params;
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "추천인명": item["recipientNm"],
          "전화번호": item["telNum"],
          "고객명": item["customerNm"],
          "고객차량번호": item["customerCarNo"],
          "계약보험사": item["contractInsurer"],
          "계약보혐료": item["contractPremAmt"],
          "계약일자": item["contractDt"],
          "계약예정일자": item["contractDueDt"],
          "만기일자": item["expiryDt"],
          "작업단계": item["jobStep"],
          "작업일자": item["createDate"],		  
          "안내단계": item["infoStep"],
          "안내일자": item["updateDate"],
        }
        returnData.push(obj);
      }

      const sheetName = `자동차만기`;
      const fileName = `자동차만기List`;
      let conditionHistString = JSON.stringify(this.conditionHist);

      await func.saveXlsExtractHist("자동차보험 만기안내List", returnData.length, conditionHistString, fileName, "HIGH");
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>