<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="조회조건">
        <field-input title="구분">
          <v-select
            :items="contentType"
            background-color="white"
            v-model="type.contentType"
            menu-props="auto"
            item-text="bname"
            item-value="bvalue"
            hide-details outlined dense>
          </v-select>
        </field-input>
        <field-input title="기준일자">
          <date-input
              :start-date="startDate"
              :end-date="endDate"
              @updateDate="updateDate"
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getHistoryList"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import FieldInput from "../../../../components/Form/FieldInput";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import DateInput from "@/components/Form/DateInput";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {FieldInput, SearchArea, SearchBarLayout, DateInput},
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
    this.searchType1 = 'ALL';
  },
  data: ()  => ({
    contentType: [
      {bname: '전체', bvalue: 'ALL'},
      {bname: '명함', bvalue: '03'},
      {bname: '세일즈카드', bvalue: '04'},
      {bname: '명함IMS', bvalue: 'i3'},
      {bname: '세일즈카드IMS', bvalue: 'i4'},
      {bname: 'Remind_고객', bvalue: 'RC'},
      {bname: 'Remind_FA', bvalue: 'RF'},
      {bname: '질병예측쿠폰', bvalue: 'DP'},
    ],
    type:{
      "contentType" : 'ALL'
    },
    startDate: "",
    endDate: "",
  }),
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    getHistoryList() {

      const contSendHist = {
        fromDate : this.startDate.replace(/[^0-9]/gi, ""),
        toDate : this.endDate.replace(/[^0-9]/gi, ""),
        contType: this.type.contentType,
      };

      this.$emit('search', contSendHist);
    }
  }
}
</script>