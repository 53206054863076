<template>
  <v-navigation-drawer
      v-model="drawer"
      app color="#f0f0f0"
      style="z-index: 3; top:70px;  max-height: calc(100% - 70px); width: 300px;"
      right
      disable-resize-watcher
      v-if="checkMobile"
  >
    <v-treeview
      :items="menus"
      class="menu"
      expand-icon=""
      open-on-click
      transition
      activatable
      item-key="name"
    >
      <template v-slot:append="{ item, open }">
        <v-icon
            v-if="Object.getOwnPropertyNames(item).includes('children')"
            class="py-5"
        >
          {{ open ? "expand_less" : "expand_more" }}
        </v-icon>
      </template>
      <template v-slot:label="{ item }">
        <div
          v-if="Object.getOwnPropertyNames(item).includes('children')"
          class="menu py-3"
        >
          {{ item.name }}
        </div>
        <div
          v-else
          @click="navigatePage(item.path)"
          class="menu py-3"
        >
          {{ item.name }}
        </div>
      </template>
    </v-treeview>
    <a class="logout-btn" @click="logout">로그아웃</a>
  </v-navigation-drawer>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import constant from "@/util/constant";

export default {
  name: 'NavBar',
  props: {
    isOpen: Boolean,
  },
  data: () => ({
    drawer: false,
    active: "",
    menuTitleList : [], // 메뉴 타이틀 목록
    menus: [],
    windowWidth: -1,
  }),

  computed: {
    ...mapGetters("login", {
      personalMenuList: "personalMenuList"
    }),
    checkMobile(){
      // 모바일 화면에서 사이드 메뉴 열고 창을 확장시키면 사이드 메뉴가 사라지지 않는 오류 수정
      return (this.windowWidth < 1300) ? true : false
    },
  },

  created() {
    this.menuTitleList = constant.PROGRAM_MENU_GROUP_LIST.filter((el, i) => i !== 0);
    this.getPersonalMenuList();
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.setWindowInnerWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize",this.setWindowInnerWidth)
  },

  methods: {
    ...mapActions("login", ["initData"]),

    getPersonalMenuList() {
      for (let i in this.menuTitleList) {
        let children = [];

        this.personalMenuList.forEach(item => {
          if (parseInt(item.progMnGroup) == i) {
            children.push({name: item.progNmKr, path: item.progPath});
          }
        })

        if (children.length > 0) {
          this.menus.push({name: this.menuTitleList[i].name, children: children});
        }
      }
    },

    navigatePage(path) {
      if(this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    async logout() {
      this.initData().then(() => {
        this.$router.push("/");
        location.reload();
      })
    },
    setWindowInnerWidth(){
      this.windowWidth = window.innerWidth;
    },
  }
}
</script>

<style>
.menu > div {
  border-bottom: 1px solid gray;
}
.v-treeview-node__children {
  background-color: #cfcfcf;
}
.activeMenu {
  color: white;
}
@media screen and (max-width: 1300px) {
  .logout-btn{
    float: right;
    margin: 25px 20px 20px 0;
    font-weight: 600;
  }
}
</style>