<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">보험사</v-col>
          <v-select
            @click="selectList"
            v-model="searchInsurer"
            :items="insurerCode"
            background-color="white"
            item-text="value"
            item-value="cd"
            hide-details outlined dense
            />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">등록기간</v-col>
          <v-col cols="9">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">수신명/설명</v-col>
          <v-text-field
              v-model="explanation"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getDataList"/>
    </template>
  </search-bar-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchBarLayout from "../../../layouts/SearchBarLayout";
import SearchArea from "../../../components/Search/SearchArea";
import DateInput from "../../../components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import SearchBtn from "../../../components/Search/SearchBtn";
export default {
  components: {SearchBtn, DateInput, SearchArea, SearchBarLayout},
  mixins: [ DateMixin],
  data:() => ({
    searchInsurer:"",
    insurerCode:[
      {value: '전체', cd: ''}
    ],
    startDate: "",
    endDate: "",
    explanation:'',
  }),
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-6);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    async selectList() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    getDataList() {
      const params = {
        'insCd': this.searchInsurer,
        'stdt': this.startDate.replaceAll('-', ''),
        'eddt': this.endDate.replaceAll('-', ''),
        'description': this.explanation
      }
      this.$emit('getSearch', params);
    }
  }
}
</script>