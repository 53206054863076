<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">보험사</v-col>
          <v-col cols="8">
            <v-select
                @click="getInsCdList"
                v-model="insCd"
                :items="insCdList"
                background-color="white"
                item-text="insCdNm"
                item-value="insCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="3">담보명</v-col>
          <v-col cols="9">
            <v-text-field
                v-model="cvrNm"
                background-color="white"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">담보코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="cvrCd"
                background-color="white"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">순서</v-col>
          <v-col cols="9">
            <v-radio-group v-model="dispType" row>
              <v-radio label="코드" value="1"/>
              <v-radio label="담보명" value="2"/>
            </v-radio-group>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">갱신형구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="rnwDiv"
                :items="rnwDivList"
                background-color="white"
                item-text="rnwDivNm"
                item-value="rnwDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">갱신형년도</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="rnwYear"
                background-color="white"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">갱신용구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="forRnwDiv"
                :items="forRnwDivList"
                background-color="white"
                item-text="forRnwDivNm"
                item-value="forRnwDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">간편가입구분</v-col>
          <v-col cols="8">
            <v-select
                v-model="easySignUpDiv"
                :items="easySignUpDivList"
                background-color="white"
                item-text="easySignUpDivNm"
                item-value="easySignUpDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">운전자형태구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="driverTypeDiv"
                :items="driverTypeDivList"
                background-color="white"
                item-text="driverTypeDivNm"
                item-value="driverTypeDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">사용여부</v-col>
          <v-col cols="4">
            <v-select
                v-model="useYn"
                :items="useYnList"
                background-color="white"
                item-text="useYnName"
                item-value="useYnValue"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBtn from "@/components/Search/SearchBtn";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  data() {
    return {
      insCd: "",
      insCdList: [
        {insCdNm: "선택", insCd: ""}
      ],
      cvrNm: "",
      cvrCd: "",
      dispType: "2",
      rnwDiv: "",
      rnwDivList: [
        {rnwDivNm: "전체", rnwDiv: ""},
        {rnwDivNm: "갱신형", rnwDiv: "1"},
        {rnwDivNm: "비갱신형", rnwDiv: "2"},
      ],
      rnwYear: "",
      forRnwDiv: "",
      forRnwDivList: [
        {forRnwDivNm: "전체", forRnwDiv: ""},
        {forRnwDivNm: "갱신용", forRnwDiv: "1"},
      ],
      easySignUpDiv: "",
      easySignUpDivList: [
        {easySignUpDivNm: "전체", easySignUpDiv: ""},
        {easySignUpDivNm: "간편가입(간편고지)", easySignUpDiv: "1"},
      ],
      driverTypeDiv: "",
      driverTypeDivList: [
        {driverTypeDivNm: "전체", driverTypeDiv: ""},
        {driverTypeDivNm: "운전자", driverTypeDiv: "1"},
        {driverTypeDivNm: "비운전자", driverTypeDiv: "2"},
        {driverTypeDivNm: "영업용운전자", driverTypeDiv: "3"},
      ],
      useYn: "Y",
      useYnList: [
        {useYnName: "Y", useYnValue: "Y"},
        {useYnName: "N", useYnValue: "N"},
      ],
    }
  },
  methods: {
    async getInsCdList() {
      this.insCdList = await ProductRecommend.getInsCd();
      this.insCdList.shift();
    },
    getSearch() {
      const params = {
        insCd: this.insCd,
        cvrCd: this.cvrCd,
        cvrNm: this.cvrNm,
        dispType: this.dispType,
        rnwDiv: this.rnwDiv,
        rnwYear: this.rnwYear,
        forRnwDiv: this.forRnwDiv,
        easySignUpDiv: this.easySignUpDiv,
        driverTypeDiv: this.driverTypeDiv,
        useYn: this.useYn,
      }
      this.$emit("getSearch", params);
    }
  }
}
</script>