<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <outlined-btn class="float-right" @click="$emit('toExcel')" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>

    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="data"
        :loading="loading"
        :items-per-page="-1"
        fixed-header calculate-widths
        :headers="headers"
        @click:row="showModal"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >

        <template v-slot:item.telNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <template v-slot:item.sendCnt="{ value }">
          {{ value }}
          <outlined-btn v-if="type === 'DP'">상세보기</outlined-btn>
        </template>

        <template v-slot:item.fromDate="{ value }">
          {{ getDateDashFormat(value) }}
        </template>

        <template v-slot:item.toDate="{ value }">
          {{ getDateDashFormat(value) }}
        </template>
      </v-data-table>

      <content-history-detail-modal
        :is-show="showDetailModal"
        @close="showDetailModal = false"
        :data="getData"
        :rowData="rowData"
        />
    </v-flex>
  </v-col>
</template>

<script>
import DateMixin from "@/mixin/DateMixin";
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import ContentHistoryDetailModal from "@/pages/User/components/ContentHistory/ContentHistoryDetailModal";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props:["data", "loading", "type"],
  components: {ContentHistoryDetailModal, OutlinedBtn},
  mixins: [TableMixin, DateMixin],
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  data:() => ({
    headers: [{text: "컨텐츠명", value: "contTypeNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "회원명", value: "memberNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "전화번호", value: "telNum", class: "teal lighten-4", align: "center", sortable: false},
      {text: "발송건수", value: "sendCnt", class: "teal lighten-4", align: "center", sortable: false},
      {text: "From일자", value: "fromDate", class: "teal lighten-4", align: "center", sortable: false},
      {text: "To일자", value: "toDate", class: "teal lighten-4", align: "center", sortable: false},
    ],
    showDetailModal: false,
    rowData: [],
    getData: [],
  }),
  methods: {
    async showModal(row) {
      this.rowData = row;
      if (this.type !== 'DP') {return}
      try {
        const { data } = await axiosInstance.get(`/admin/operation/eventTalkReceiver`, {
          params: {
            contTypeNm: row.contTypeNm,
            toDate: row.toDate,
            memberNm: row.memberNm,
            telNum: row.telNum,
          }
        })
        let idx = 0;
        for (const item of data) { item["index"] = idx++; }

        this.getData = data;
      } catch (e) {
        console.log(e);
      }
      this.showDetailModal = true;
    }
  }
}
</script>


<style scoped>
</style>

