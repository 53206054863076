<template>
  <div class="header-container">
    <span class="logo-image" v-if="this.$store.getters['login/memberRole'] === 'ADMIN'" @click="navigatePage('/stat/performance')">
      <v-img max-height="60" max-width="60" src="@/assets/ain.png" />
    </span>
    <span class="logo-image" v-else-if="this.$store.getters['login/memberRole'] === 'MANAGER'" @click="navigatePage('/product/condPrem')">
      <v-img max-height="60" max-width="60" src="@/assets/ain.png" />
    </span>
    <div class="menu-layout">
      <!-- 일반 관리자 메뉴 -->
      <v-row @mouseover="showMenu" @mouseleave="hideMenu" :style="{ width: menuTitleList.length * 200 + 'px' }" >
        <v-col v-for="(item, index) in menuTitleList" :key="index" style="position: relative; padding: 0;" >
          <span class="menu-title">
            {{ item.name }}
          </span>
          <v-list class="menu-list" v-if="isShowMenu === true"  @mouseleave="hideMenu" :style="{ height: maxItemLength * 50 + 'px' }">
            <v-list-item class="list-item" v-for="(menu_array, array_index) in menuList[index]" :key="array_index"
                         @click="navigatePage(menu_array.path)">
              <span style="white-space: nowrap;">
                {{ menu_array.name }}
              </span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <div class="logout-button">
        <span @click="logout">로그아웃</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import constant from "@/util/constant";
import _ from "lodash";

export default {
  computed: {
    ...mapGetters("login", {
      personalMenuList: "personalMenuList"
    })
  },
  data() {
    return {
      menuTitleList: [],
      menuList: [],
      maxItemLength: -1,
      isShowMenu: false,
    }
  },

  created() {
    this.menuTitleList = constant.PROGRAM_MENU_GROUP_LIST.filter((el, i) => i !== 0);
    this.getPersonalMenuList();
  },

  methods: {
    ...mapActions("login", ["initData"]),
    // 개인별 메뉴 세팅
    getPersonalMenuList(){
      for (let i = 0; i < this.menuTitleList.length; i++) {
        let addArr = [];

        this.personalMenuList.forEach(item => {
          if (parseInt(item.progMnGroup) == i) {
            addArr.push({name: item.progNmKr, path: item.progPath});
          }
        })
        this.menuList.push(addArr);
      }

      // 상단 바 높이 조절
      this.maxItemLength = Math.max(...this.menuList.map(eachMenu => eachMenu.length));

      // 비어있는 카테고리 hidden 처리
      const emptyMenu = this.menuList.map((value, index) => {
        if (value.length == 0) return index;
      }).filter(el => el !== undefined);

      this.menuList = this.menuList.filter((title, index) => !emptyMenu.includes(index));
      this.menuTitleList = _.isEmpty(this.menuList) ? [] : this.menuTitleList.filter((title, index) => !emptyMenu.includes(index));

    },
    showMenu() {
      this.isShowMenu = true;
    },
    hideMenu() {
      this.isShowMenu = false;
    },
    async logout() {
      this.initData()
          .then(() => {
            this.$router.push("/");
            location.reload();
          })
    },
    navigatePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
      this.isShowMenu = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  z-index: 3;
}

.logo-image {
  margin-top: 5px;
  cursor: pointer;
}

.menu-layout {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.menu-container {
  width: 1200px;
}

.menu-container-manager {
  width: 200px;
}

.menu-title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #222;
  padding: 20px 0;
}

.menu-title > span {
  padding: 10px;
}

.menu-title:hover {
  cursor: pointer;
}

.menu-list {
  position: absolute;
  width: 100%;
  top: 70px;
  padding: 0;
  //height: 690px;
  box-shadow: 5px 5px 5px 5px #c0c0c0 !important;
}

.list-item {
  justify-content: center;
  background-color: #fff;
  font-size: 16px;
  border-top: none;
}

.logout-button {
  display: flex;
  justify-content: center;
  color: #1772C7;
  font-weight: 900;
  margin-left: 20px;
  white-space: nowrap;
}

.logout-button:hover {
  cursor: pointer;
  text-shadow: 0 1px 2px #1772C7;
}

@media screen and (max-width: 1300px) {
  .menu-layout {
    display: none;
  }

  .logout-button {
    display: none;
  }
}
</style>