<template>
  <search-page-layout title="인수지침 연계담보 관리">
    <template v-slot:tabBar>
      <v-tabs
        center-active
        v-model="active"
        >
        <v-tab>메인</v-tab>
        <v-tab>상세</v-tab>
        <v-tab-item>
          <linked-security-main
              @showDetail="showDetail"
            />
        </v-tab-item>
        <v-tab-item>
          <linked-security-detail
              :detailDto="detailDto"
            />
        </v-tab-item>
      </v-tabs>
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import LinkedSecurityMain from "./Main/LinkedSecurityMain";
import LinkedSecurityDetail from "./Detail/LinkedSecurityDetail";
export default {
  components: {LinkedSecurityDetail, LinkedSecurityMain, SearchPageLayout},
  data:() => ({
    active: [0, 1],
    detailDto:[]
  }),
  methods:{
    showDetail(dto) {
      this.active = 1;
      this.detailDto = dto;
    }
  }
}
</script>