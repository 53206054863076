<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">상품군</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="rowData.prProdLineNm"
                disabled
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">보험사</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="rowData.insNm"
                disabled
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">상품코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="rowData.prCd"
                disabled
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="12">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="rowData.prNm"
                      disabled
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                  />
                </div>
              </template>
              <span>{{ rowData.prNm }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">플랜코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="rowData.planCd"
                disabled
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="12">
            <v-text-field
                v-model="rowData.planNm"
                disabled
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">주력담보여부</v-col>
          <v-col cols="6">
            <v-select
                v-model="mainCvrYn"
                :items="mainCvrYnList"
                background-color="white"
                item-text="mainCvrYnNm"
                item-value="mainCvrYn"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBtn from "@/components/Search/SearchBtn";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";

export default {
  props: ["rowData"],
  components: {SearchArea, SearchBarLayout, SearchBtn},
  data() {
    return {
      mainCvrYn: "",
      mainCvrYnList: [
        {mainCvrYnNm: "전체", mainCvrYn: ""},
        {mainCvrYnNm: "Y", mainCvrYn: "Y"},
        {mainCvrYnNm: "N", mainCvrYn: "N"},
      ]
    }
  },
  methods: {
    getSearch() {
      const params = {
        prProdCd: this.rowData.prProdLineCd,
        insCd: this.rowData.insCd,
        prCd: this.rowData.prCd,
        planCd: this.rowData.planCd,
        mainCvrYn: this.mainCvrYn,
      }
      this.$emit("getSearch", params);
    }
  }
}
</script>