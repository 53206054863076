<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {CRE_CVR_MAPPING_STATUS_TABLE} from "@/util/TableHeaders";

export default {
  mixins: [TableMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: CRE_CVR_MAPPING_STATUS_TABLE,
    }
  },
  methods: {
    clickContents(row) {
      this.$emit("row", row);
      this.$emit("close");
    },
  }
}
</script>