<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
    >
    <v-flex>
      <v-card max-width="70vw" max-height="90vw" min-height="46vw" min-width="65vw" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">메뉴등록</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>

        <v-form v-model="valid">
          <v-card-text>
            <v-row class="px-6 align-center">
              <v-col class="d-flex flex-column col-md-4 col-sm-12 col-12">
                <span class="subtitle-1">등록번호</span>
                <v-text-field
                  background-color="white"
                  color="black"
                  outlined dense
                  v-model="userGroupDto.menuRegSeq"
                  />
              </v-col>
                <v-col cols="8">
                <v-btn
                    color="blue darken-4"
                    class="white--text col-1"
                    @click="getMenuList"
                >조회
                </v-btn>
                </v-col>
              <v-col class="d-flex flex-column col-md-4 col-sm-12 col-12">
                <span class="subtitle-1">메뉴명</span>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense
                    v-model="userGroupDto.menuNm"
                />
              </v-col>
              <v-col class="d-flex flex-column col-md-4 col-sm-12 col-12">
                <span class="subtitle-1">유료여부</span>
                <v-select
                  v-model="userGroupDto.paidUseYn"
                  :items="paiYn"
                  background-color="white"
                  outlined dense
                  item-text="cname"
                  item-value="cvalue">
                </v-select>
              </v-col>
              <v-col class="d-flex flex-column col-md-4 col-sm-12 col-12">
                <span class="subtitle-1">사용자권한여부</span>
                <v-select
                    v-model="userGroupDto.useYn"
                    :items="userYn"
                    background-color="white"
                    outlined dense
                    item-text="bname"
                    item-value="bvalue">
                </v-select>
              </v-col>
              <v-col class="d-flex flex-column col-md-12 col-sm-12 col-12">
                <span class="subtitle-1">URL</span>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense
                    v-model="userGroupDto.menuUrl"
                />
              </v-col>
              <v-col class="d-flex flex-column col-md-12 col-sm-12 col-12">
                <span class="subtitle-1">URL Full명</span>
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense
                    v-model="userGroupDto.menuUrlFul"
                />
              </v-col>
              <v-col class="d-flex flex-column col-md-12 col-sm-12 col-12">
                <span class="subtitle-1">사용자그룹코드</span>
                <v-row>
                  <v-checkbox class="ckLabel" label="일반무료 회원" v-model="USER_GENERAL" value="Y"></v-checkbox>
                  <v-checkbox class="ckLabel" label="일반유료 회원" v-model="USER_PAID" value="Y"></v-checkbox>
                  <v-checkbox class="ckLabel" label="일반유료 인카회원" v-model="INCAR_GENERAL" value="Y"></v-checkbox>
                  <v-checkbox class="ckLabel" label="SENIOR 유료 인카회원" v-model="INCAR_SENIOR" value="Y"></v-checkbox>
                  <v-checkbox class="ckLabel" label="MANAGER 유료 인카회원" v-model="INCAR_MANAGER" value="Y"></v-checkbox>
                  <v-checkbox class="ckLabel" label="ADMIN" v-model="ADMIN" value="Y"></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="submit"
          >저장
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props: ["isShow", "userData"],
  data:() => ({
    inputClass: 'd-flex flex-column col-md-5 col-sm-7 col-18',
    valid: false,
    userYn:[
      {bname:'Y', bvalue:'Y'},
      {bname:'N', bvalue:'N'}
    ],
    paiYn:[
      {cname:'Y', cvalue:'Y'},
      {cname:'N', cvalue:'N'}
    ],
    USER_GENERAL:"N",
    USER_PAID:"N",
    INCAR_GENERAL:"N",
    INCAR_SENIOR:"N",
    INCAR_MANAGER:"N",
    ADMIN:"N",
    userGroupDto: {
      "menuRegSeq":"",
      "menuNm":"",
      "menuUrl":"",
      "userGroupNm":"",
      "useYn":"Y",
      "paidUseYn":"Y",
      "memberCnt":"",
      "regNm":"",
      "updateDate":"",
      "user_group_cd":"",
      "menuUrlFul":""
    }
  }),
  watch: {
    userData: function (obj) {
      this.userGroupDto = obj;
    }
  },
  methods: {
    async submit() {
      const item = this.userGroupDto;

      if (this.ADMIN == null) {
        this.ADMIN = 'N';
      }
      if (this.INCAR_GENERAL == null) {
        this.INCAR_GENERAL = 'N';
      }
      if (this.INCAR_MANAGER == null) {
        this.INCAR_MANAGER = 'N';
      }
      if (this.INCAR_SENIOR == null) {
        this.INCAR_SENIOR = 'N';
      }
      if (this.USER_GENERAL == null) {
        this.USER_GENERAL = 'N';
      }
      if (this.USER_PAID == null) {
        this.USER_PAID = 'N';
      }
      let userGroupList= [
            {
              userGroupCd: "ADMIN",
              authUseYn: this.ADMIN
            },
          {
            userGroupCd: "INCAR_GENERAL",
            authUseYn: this.INCAR_GENERAL
          },
          {
            userGroupCd: "INCAR_MANAGER",
            authUseYn: this.INCAR_MANAGER
          },
          {
            userGroupCd: "INCAR_SENIOR",
            authUseYn: this.INCAR_SENIOR
          },
          {
            userGroupCd: "USER_GENERAL",
            authUseYn: this.USER_GENERAL
          },
          {
            userGroupCd: "USER_PAID",
            authUseYn: this.USER_PAID
          },
          ]
      userGroupList = JSON.stringify(userGroupList);

      const menuRegDto = {
        authUseYn: item.authUseYn,
        createDate: item.createDate,
        memberCnt: item.memberCnt,
        menuNm: this.userGroupDto.menuNm,
        menuRegSeq: this.userGroupDto.menuRegSeq,
        menuRoute: item.menuRoute,
        menuUrl: this.userGroupDto.menuUrl,
        menuUrlFul: this.userGroupDto.menuUrlFul,
        paidUseYn: this.userGroupDto.paidUseYn,
        regId: item.regId,
        regNm: item.regNm,
        site: item.site,
        updateDate: item.updateDate,
        useYn: this.userGroupDto.useYn,
        userGroupCd: item.userGroupCd,
        userGroupList: userGroupList,
        userGroupNm: item.userGroupNm
      };
      try {
        const {data} = await axiosInstance.post('/admin/operation/savemenureg', menuRegDto);
        if (data === "Success : Update") {
          alert("저장되었습니다.");
          this.userGroupDto = [];
          this.useYn = "Y";
          this.paidUseYn = "Y";
          this.USER_GENERAL ="N";
          this.USER_PAID ="N";
          this.INCAR_GENERAL ="N";
          this.INCAR_SENIOR ="N";
          this.INCAR_MANAGER ="N";
          this.ADMIN ="N";
          this.$emit('close');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getMenuList() {
      this.USER_GENERAL ="N";
      this.USER_PAID ="N";
      this.INCAR_GENERAL ="N";
      this.INCAR_SENIOR ="N";
      this.INCAR_MANAGER ="N";
      this.ADMIN ="N";

      try {
        const {data} = await axiosInstance.get('/admin/operation/menuregdto', {
          params: {
            menuRegSeq : this.userGroupDto.menuRegSeq
          }
        });
        this.userGroupDto = data;

        const userList = JSON.parse(this.userGroupDto.userGroupList)
        for (let i = 0; i < userList.length; i++) {
          if (userList[i].userGroupCd === "ADMIN" && userList[i].authUseYn === "Y"){
            this.ADMIN ='Y'
          }
          if (userList[i].userGroupCd === "INCAR_MANAGER" && userList[i].authUseYn === "Y"){
            this.INCAR_MANAGER ='Y'
          }
          if (userList[i].userGroupCd === "INCAR_SENIOR" && userList[i].authUseYn === "Y"){
            this.INCAR_SENIOR ='Y'
          }
          if (userList[i].userGroupCd === "INCAR_GENERAL" && userList[i].authUseYn === "Y"){
            this.INCAR_GENERAL ='Y'
          }
          if (userList[i].userGroupCd === "USER_PAID" && userList[i].authUseYn === "Y"){
            this.USER_PAID ='Y'
          }
          if (userList[i].userGroupCd === "USER_GENERAL" && userList[i].authUseYn === "Y"){
            this.USER_GENERAL ='Y'
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.userGroupDto = [];
      this.useYn = "Y";
      this.paidUseYn = "Y";
      this.USER_GENERAL ="N";
      this.USER_PAID ="N";
      this.INCAR_GENERAL ="N";
      this.INCAR_SENIOR ="N";
      this.INCAR_MANAGER ="N";
      this.ADMIN ="N";

      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.ckLabel {
  margin-right: 15px;
}
</style>