<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="70vw" max-height="90vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">제휴사 정보</span>

        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-form v-model="valid">
          <v-card-text >
            <v-row class="px-4 mb-2">
              <span class="text-h6 black--text font-weight-bold">제휴정보</span>
            </v-row>
            <v-row class="px-6">
              <v-col :class="inputClass">
                <span class="subtitle-1">아이디*</span>
                <v-text-field
                    background-color="white"
                    v-model="partnerInfo.loginId"
                    :rules="[...rules.nn, ...rules.enNum]"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">비밀번호*</span>
                <v-text-field
                    type="password"
                    background-color="white"
                    v-model="partnerInfo.password"
                    :rules="rules.nn"
                    outlined dense
                />
              </v-col>
              <v-col class="d-flex flex-column col-3">
                <span class="subtitle-1">정산타입*</span>
                <v-select
                    :items="PARTNER_SETTLEMENT_TYPE"
                    v-model="partnerInfo.settlementType"
                    outlined dense
                    background-color="white"
                />
              </v-col>
            </v-row>
            <v-row class="px-6" v-if="isMain">
              <v-col :class="inputClass">
                <span class="subtitle-1">제휴사URL1</span>
                <v-text-field
                    background-color="white"
                    v-model="partnerInfo.subLoginId[0]"
                    outlined dense
                    :disabled="isModify"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">제휴사URL2</span>
                <v-text-field
                    background-color="white"
                    v-model="partnerInfo.subLoginId[1]"
                    outlined dense
                    :disabled="isModify"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">제휴사URL3</span>
                <v-text-field
                    background-color="white"
                    v-model="partnerInfo.subLoginId[2]"
                    outlined dense
                    :disabled="isModify"
                />
              </v-col>
            </v-row>

            <v-row class="px-4 mb-2">
              <span class="text-h6 black--text font-weight-bold">회사정보</span>
            </v-row>
            <v-row class="px-6">
              <v-col :class="inputClass">
                <span class="subtitle-1">사업자명*</span>
                <v-text-field
                    background-color="white"
                    :rules="rules.nn"
                    v-model="partnerInfo.businessNm"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">사업자번호*</span>
                <v-text-field
                    :rules="[...rules.nn, ...rules.businessNumber]"
                    background-color="white"
                    v-model="partnerInfo.businessNum"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">업종*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="partnerInfo.businessType"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">업태*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="partnerInfo.businessCondition"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">대표자명*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="partnerInfo.representNm"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">대표번호</span>
                <v-text-field
                    :rules="rules.hyphenNum"
                    background-color="white"
                    v-model="partnerInfo.representTelNum"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">은행명*</span>
                <v-select
                    :items="BANK"
                    v-model="partnerInfo.bankNm"
                    outlined dense
                    background-color="white"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">계좌번호*</span>
                <v-text-field
                    background-color="white"
                    v-model="partnerInfo.bankAccount"
                    outlined dense
                />
              </v-col>
              <v-col class="d-flex flex-column col-12">
                <span class="subtitle-1">사업자 주소*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="partnerInfo.businessAddr"
                    outlined dense
                />
              </v-col>
            </v-row>

            <v-row class="px-4 mb-2">
              <span class="text-h6 black--text font-weight-bold">제휴담당자 정보</span>
            </v-row>
            <v-row class="px-6">
              <v-col :class="inputClass">
                <span class="subtitle-1">담당자명*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="partnerInfo.partnerChargeNm"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">전화번호</span>
                <v-text-field
                    :rules="rules.hyphenNum"
                    background-color="white"
                    v-model="partnerInfo.partnerChargeTelNum"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">이메일*</span>
                <v-text-field
                    :rules="[...rules.nn, ...rules.email]"
                    background-color="white"
                    v-model="partnerInfo.partnerChargeEmail"
                    outlined dense
                />
              </v-col>
            </v-row>

            <v-row class="px-4 mb-2">
              <span class="text-h6 black--text font-weight-bold">세금계산서 정보</span>
            </v-row>
            <v-row class="px-6">
              <v-col :class="inputClass">
                <span class="subtitle-1">담당자명*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="partnerInfo.taxChargeNm"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">전화번호</span>
                <v-text-field
                    background-color="white"
                    v-model="partnerInfo.taxChargeTelNum"
                    outlined dense
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">이메일*</span>
                <v-text-field
                    :rules="[...rules.nn, ...rules.email]"
                    background-color="white"
                    v-model="partnerInfo.taxChargeEmail"
                    outlined dense
                />
              </v-col>
            </v-row>

          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              :disabled="!valid"
              @click="submit"
          >
            {{ isModify ? "수정" : "추가"}}
          </v-btn>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="$emit('close')"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import _ from 'lodash';
import { rules } from "@/util/constant"
import { axiosInstance } from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import StringMixin from "@/mixin/StringMixin";

export default {
  mixins: [CodeMixin, StringMixin],
  props: ["isShow", "partnerData", "isModify", "PARTNER_SETTLEMENT_TYPE"],
  async created() {
    this.BANK = await this.getSelectList('BANK');
  },
  computed: {
    isMain () {
      return this.partnerData.memberId ? this.partnerData.memberId.slice(33) === "000" : false;
    },
  },
  data: () => ({
    inputClass: 'd-flex flex-column col-md-3 col-sm-6 col-12',
    valid: false,
    BANK: [],
    rules: rules,
    partnerInfo: {
      loginId: "",
      subLoginId: ["","",""],
      password: "",
      settlementType: "SECTION_REWARD",
      businessNm: "",
      businessNum: "",
      businessType: "",
      businessCondition: "",
      representNm: "",
      representTelNum: "",
      bankNm: "KB_BANK",
      bankAccount: "",
      businessAddr: "",
      partnerChargeNm: "",
      partnerChargeTelNum: "",
      partnerChargeEmail: "",
      taxChargeNm: "",
      taxChargeTelNum: "",
      taxChargeEmail: "",
      loginPossibility: "Y",
      view: "Y"
    },
    initData: {
      loginId: "",
      subLoginId: ["","",""],
      password: "",
      settlementType: "SECTION_REWARD",
      businessNm: "",
      businessNum: "",
      businessType: "",
      businessCondition: "",
      representNm: "",
      representTelNum: "",
      bankNm: "KB_BANK",
      bankAccount: "",
      businessAddr: "",
      partnerChargeNm: "",
      partnerChargeTelNum: "",
      partnerChargeEmail: "",
      taxChargeNm: "",
      taxChargeTelNum: "",
      taxChargeEmail: "",
      loginPossibility: "Y",
      view: "Y"
    },
  }),
  watch: {
    partnerData: function(obj) {
      if(_.isEmpty(obj)) {
        this.partnerInfo = _.cloneDeep(this.initData);
      } else {
        // subLoginId 가 null 로 넘어올 경우 빈 배열로 설정해줘야 null 에러가 안뜸
        if(!obj.subLoginId) { obj.subLoginId = []; }

        this.partnerInfo = obj;
      }
    }
  },
  methods: {
    async submit() {
      this.partnerInfo.businessNum = this.replaceOnlyNum(this.partnerInfo.businessNum);
      this.partnerInfo.bankAccount = this.replaceOnlyNum(this.partnerInfo.bankAccount);

      if (this.isModify) {
        confirm(`제휴사를 수정하시겠습니까?`) && await this.modifyPartner();
      } else {
        confirm(`제휴사를 추가하시겠습니까?`) && await this.savePartner();
      }

      this.$emit('close');
      this.partnerInfo = _.cloneDeep(this.initData);
    },
    async savePartner() {
      try{
        await axiosInstance.post('/admin/operation/business-member', this.partnerInfo);
        alert(`제휴사가 저장되었습니다!`);
      } catch(e) {
        console.log(e);
      }
    },
    async modifyPartner() {
      try{
        await axiosInstance.put('/admin/operation/business-member', this.partnerInfo);
        alert(`제휴사가 저장되었습니다!`);
      } catch(e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>