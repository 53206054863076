var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-3 px-3",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2 text-right"},[_c('outlined-btn',{attrs:{"disabled":!_vm.hasTableData},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"45vh","items":_vm.data,"loading":_vm.loading,"headers":_vm.getHeader(_vm.headers),"fixed-header":"","calculate-widths":"","items-per-page":-1,"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},scopedSlots:_vm._u([{key:"item.joinCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.userCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.lineCntTot",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.lineCnt1",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.lineCnt2",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.lineCnt3",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.lineCnt4",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.lineCnt5",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.telNum",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getTelNum(value)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }