<template>
  <search-layout title="">
    <template v-slot:searchBar>
      <shipment-target-search
          :baseDt = "baseDt"
          @search="getList"
        />
    </template>

    <template v-slot:table>
      <shipment-target-table
          :data = "data"
          :loading="loading"
          :searchList = "searchList"
          :site ="site"
          :incar ="incar"
        />
    </template>
  </search-layout>
</template>
<script>
import SearchLayout from "../../../../layouts/SearchLayout";
import ShipmentTargetSearch from "./ShipmentTargetSearch";
import ShipmentTargetTable from "./ShipmentTargetTable";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {ShipmentTargetTable, ShipmentTargetSearch, SearchLayout},
  props: ['baseDt'],
  data:() => ({
    loading: false,
    data: [],
    searchList:[],
    site:'',
    incar:''
  }),
  methods: {
    async getList(params, site, incar) {
      this.site = site;
      this.incar = incar;
      this.loading = true;
      this.searchList = params;
      const custExtractInDto = params;
      try {
        const { data } = await axiosInstance.post('/admin/operation/custExtractList', custExtractInDto);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>