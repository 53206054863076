<template>
  <search-page-layout title="보험사 담보매핑 기초자료">
    <template v-slot:searchBar>
      <ins-cvr-mapping-basic-data-search-bar
        @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <ins-cvr-mapping-basic-data-table
        :data="data"
        :loading="loading"
        @reloadData="reloadData"
        @termSetting="termSetting"
        @baseMapping="baseMapping"
        @clickRow="clickRow"
      />
    </template>

    <template v-slot:inflowHist>
      <ins-cvr-mapping-basic-data-save
          :rowData="rowData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import InsCvrMappingBasicDataSearchBar
  from "@/pages/Product/components/InsCvrMappingBasicData/InsCvrMappingBasicDataSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import InsCvrMappingBasicDataTable from "@/pages/Product/components/InsCvrMappingBasicData/InsCvrMappingBasicDataTable";
import _ from "lodash";
import InsCvrMappingBasicDataSave from "@/pages/Product/components/InsCvrMappingBasicData/InsCvrMappingBasicDataSave";
export default {
  components: {InsCvrMappingBasicDataSave, InsCvrMappingBasicDataTable, InsCvrMappingBasicDataSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      paramsData: [],
      rowData: [],
    }
  },
  methods: {
    clickRow(row) {
      this.rowData = row;
    },
    async getSearch(params) {
      this.loading = true;
      this.paramsData = params;

      try {
        const { data } = await  axiosInstance.get('/admin/prdata/prProdCompSrcList', { params });
        this.data = data;

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    reloadData() {
      if (_.isEmpty(this.paramsData)) return;
      this.getSearch(this.paramsData);
    },
    async termSetting() {
      try {
        const { data } = await axiosInstance.get('/admin/prdata/prProdCompSrcTrm', {
          params: {
            wkDiv: 1,
            insCd: this.paramsData.insCd,
            prProdLineCd: this.paramsData.prProdLineCd,
            prCd: this.paramsData.prCd,
          }
        });
        alert(data + "개의 데이터가 세팅되었습니다.");
      } catch (e) {
        console.log(e);
      }
    },
    async baseMapping() {
      try {
        const { data } = await axiosInstance.get('/admin/prdata/prProdCompSrcTrm', {
          params: {
            wkDiv: 2,
            insCd: this.paramsData.insCd,
            prProdLineCd: this.paramsData.prProdLineCd,
            prCd: this.paramsData.prCd,
          }
        });
        alert(data + "개의 데이터가 매핑되었습니다.");
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>