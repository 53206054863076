<template>
  <v-container fluid grid-list-md>
    <v-layout column px-3>
      <v-row>

        <v-col cols="12">
          <span class="text-h6 font-weight-bold">{{ title }}</span>
        </v-col>
        <slot name="tabBar"/>
        <slot name="searchBar"/>
        <slot name="table"/>
      </v-row>
    </v-layout>
    <slot name="inflowHist"/>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {
    update(value, name) {
      this.$emit('update', value, name);
    },
    updateDate(values, name) {
      this.$emit('updateDate', values, name);
    },
    click() {
      if (this.valid) this.$emit('search');
    }
  }
}
</script>