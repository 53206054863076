<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="조회조건">
        <v-col class="col-lg-2 col-md-4 col-12 d-flex align-center">
          <v-select
              menu-props="auto" v-model="type"
              background-color="white"
              :items="PARTNER_SEARCH_TYPE"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="col-lg-3 col-md-8 col-12 d-flex align-center">
          <v-autocomplete
              ref="autocomplete" v-model="text"
              :items="items" :disabled="isDisabledInput"
              background-color="white"
              hide-details outlined dense
              @keydown.enter="searchPartner"
          />
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn
          :disabled="!valid"
          @click="searchPartner"
      />
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarMixin from "@/mixin/SearchBarMixin";
import {PARTNER_MANAGE_HEADER} from "@/util/TableHeaders";
import {axiosInstance} from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";

export default {
  components: {SearchArea, SearchBarLayout},
  mixins: [SearchBarMixin, CodeMixin],
  async created() {
    this.PARTNER_SEARCH_TYPE = await this.getSelectList('PARTNER_SEARCH_TYPE');
  },
  data: () => ({
    PARTNER_MANAGE_HEADER: PARTNER_MANAGE_HEADER,
    PARTNER_SEARCH_TYPE: [],
    type: "99",
    text: "",
    items: []
  }),
  watch: {
    type: async function () {
      if (this.type === "99") {
        this.text = "";
        this.items = [];
      } else {
        this.text = "";
        this.items = await this.getPartnerInfo();
      }
    }
  },
  computed: {
    isDisabledInput() {
      return this.type === '99'
    },
    valid() {
      return (
          (this.type === '99' && !this.text)
          || (this.type !== '99' && this.text)
      );
    }
  },
  methods: {
    async getPartnerInfo() {

      try {
        const {data} = await axiosInstance.get('/admin/operation/business-member/info-list', {
          params: {searchType: this.type}
        });

        return data;
      } catch (e) {
        console.log(e);
      }

    },
    searchPartner() {

      const param = {
        type: this.type,
        text: this.text
      }

      if (this.valid) {
        this.$emit('search', param);
      }
    }
  }
}
</script>

<style scoped>

</style>