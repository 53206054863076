<template>
  <div class="main-layout">
    <v-card elevation="0">
      <v-card-title>
        <span class="main-title">{{ title }}</span>
        <v-card-subtitle>
          <h4 class="sub-title">{{ subtitle }}</h4>
        </v-card-subtitle>
      </v-card-title>
    </v-card>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CustomLayout",
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
}
</script>

<style scoped>
.main-layout {
  margin-top: 0 !important;
  width: 100%;
  height: 100%;
}

.main-title {
  font-size: 35px;
  font-weight: 800;
  padding: 20px 5px;
}

.sub-title {
  font-weight: 500;
}
</style>