var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"width":"100%","text-align":"right"}},[_c('outlined-btn',{staticClass:"mt-3 mb-1",attrs:{"disabled":_vm.data.length < 1},on:{"click":function($event){return _vm.$emit('toExcel')}}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","xl12":""}},[_c('v-data-table',{staticClass:"mt-3 summary-detail-table",attrs:{"height":"55vh","headers":_vm.getHeader(_vm.header),"items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":""},scopedSlots:_vm._u([{key:"item.srcContractPrem",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.inputComma(value))+" ")]}},{key:"item.srcCustmerTelNum",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getTelNum(value))+" ")]}},{key:"item.memberTelNum",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getTelNum(value))+" ")]}},{key:"item.contractPrem",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.inputComma(value))+" ")]}},{key:"item.customerTelNum",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getTelNum(value))+" ")]}},{key:"item.rewardAmt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.inputComma(value))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }