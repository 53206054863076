import {axiosInstance} from "@/util/AxiosModule";
import _ from 'lodash';

export default {
	data: () => ({
		FIELD_CLASS: "d-flex justify-end align-center pr-5",
		CREATE_FIELD_CLASS: "font-weight-bold col-3"
	}),
	methods: {
		async getDetailCodes(cdMasterId) {
			const {data} = await axiosInstance.get('/admin/code/all-detail', {
				params: {
					cdMasterId: cdMasterId,
					type: 'KEY'
				}
			});

			return data;
		},
		searchByCode(list, cd) {
			try {
				if (_.isEmpty(cd)) return '';
				const {value} = _.find(list, (o) => (o['cd'] === cd.toUpperCase()));
				return value;
			} catch (e) {
				return '';
			}
		},
		searchByCodeInSelectList(list, cd) {
			try {
				if (_.isEmpty(cd)) return '';
				const {text} = _.find(list, (o) => (o['value'] === cd.toUpperCase()));
				return text;
			} catch (e) {
				return '';
			}
		},
		async getSelectList(code) {
			const {data} = await axiosInstance.get('/public/code/json', {
				params: {
					codeMasterId: "ADMIN_SELECT_ITEM",
					code: code
				}
			});

			return data;
		},
		async getBankList() {
			const {data} = await axiosInstance.get('/public/code/json', {
				params: {
					codeMasterId: "BANK_LIST",
					code: "LIST"
				}
			});

			return data;
		},
		// 현재 설계지원 안내가능 보험사
		async getInsuranceList() {
			const {data} = await axiosInstance.get('/public/code/json', {
				params: {
					codeMasterId: "INSURANCE_COMPANY",
					code: "LIST"
				}
			});

			return data;
		},
		async getDefaultBankList(){
			const { data } = await axiosInstance.get("/public/code/normal-list", {
				params: {
					codeMasterId: "BANK"
				}
			});
			return data;
		}
	}
}