<template>
  <search-page-layout title="IIMS 이력 조회">

    <template v-slot:searchBar>
      <iims-hist-search-bar
          :name="name"
          @search="getIimsList"
      />
    </template>

    <template v-slot:table>
      <iims-hist-table
        :data="data"
        :loading="loading"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import IimsHistSearchBar from "@/pages/User/components/IimsHistSearch/IimsHistSearchBar";
import IimsHistTable from "@/pages/User/components/IimsHistSearch/IimsHistTable";

export default {
  props: ["name"],
  components: {
    IimsHistTable,
    IimsHistSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
  }),

  methods: {
    async getIimsList(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.post('/admin/operation/car-iims-hist', params);
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
  }
}
</script>