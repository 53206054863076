<template>
  <search-page-layout title="인카소속 여부 실시간 확인">
    <template v-slot:searchBar>
      <check-incar-real-time-search-bar 
          @search="getIncarMemberCheck"
      />
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import CheckIncarRealTimeSearchBar from "./CheckIncarRealTimeSearchBar";

export default {
  components: {
    CheckIncarRealTimeSearchBar,
    SearchPageLayout
  },
  methods: {
    async getIncarMemberCheck(param) {
      const params = {
        memberNm: param.memberNm,
        ssno_7: param.ssno_7,
        telNum: param.telNum,
      };
      try {
        const {data} = await axiosInstance.get('/admin/checkIncarMemberRealTime', {params});
        let rtnMsg = data ? " 맞습니다." : " 아닙니다.";

        alert(params.memberNm + " 회원은 현재 인카 소속이" + rtnMsg);
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/noto";
</style>