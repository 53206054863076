<template>
  <search-layout title="지급처리">
    <template v-slot:searchBar>
      <payment-search-bar
          :searching="searching"
          :REWARD_STATUS="REWARD_STATUS"
          :checkbox-disabled="tableData.length < 1"
          @search="search"
          @initData="initData"
          @filter="filter"
          @check="chgOnlySettlementDate"
          @changeStatus="changeStatus"
      />
    </template>

    <template v-slot:table>
      <payment-table
          :loading="loading"
          :showButton="showButton"
          :table-data="tableData"
          :settlement-date="settlementDate"
          :REWARD_STATUS="UPDATE_REWARD_STATUS"
          :REMARKS="REMARKS"
          :statusData="statusData"
          @toExcel="toExcel"
          @updateRow="updateRow"
          @bankInputRequest="bankInputRequest"
          @sendAlarmTalk="sendAlarmTalk"
      />
    </template>
  </search-layout>
</template>

<script>
import _ from "lodash";
import func from "@/util/func";
import CodeMixin from "@/mixin/CodeMixin";
import TableMixin from "@/mixin/TableMixin";
import {axiosInstance} from "@/util/AxiosModule";
import SearchLayout from "@/layouts/SearchPageLayout";
import PaymentSearchBar from "@/pages/Settlement/components/PaymentManage/PaymentSearchBar";
import PaymentTable from "@/pages/Settlement/components/PaymentManage/PaymentTable";
export default {
  mixins: [TableMixin, CodeMixin],
  components: {
    PaymentTable,
    PaymentSearchBar,
    SearchLayout
  },
  async created() {
    this.REWARD_STATUS = await this.getSelectList("PAYMENT_REWARD_STATUS");
    this.UPDATE_REWARD_STATUS = await this.getSelectList("PAYMENT_UPDATE_REWARD_STATUS");
    this.REMARKS = await this.getSelectList("PAYMENT_REMARKS");
  },
  data: () => ({
    rawData: [],
    tableData: [],
    backupData: [],
    loading: false,
    searching: false,
    onlySettlementDate: false,
    settlementDate: "",
    REWARD_STATUS: [],
    UPDATE_REWARD_STATUS: [],
    REMARKS: [],
    paramsData: [],
    statusData: "",
    showButton: false,
    duppSnoLength: 0
  }),
  watch : {
    onlySettlementDate: function(value) {
      this.loading = true;
      if(value) {
        this.backupData = this.tableData;
        this.tableData = this.tableData.filter((x) => (x[`settlementDate`] === this.settlementDate));
      } else {
        this.tableData = this.backupData.length < 1 ? this.rawData : this.backupData;
        this.backupData = [];
      }
      this.loading = false;
    }
  },
  methods: {
    // 상태 변경 감지
    changeStatus(status) {
      this.statusData = status;
    },
    setSettlementDate(value) {
      this.settlementDate = value;
    },
    async search(params) {
      this.duppSnoLength = 0;
      this.loading = true;
      this.paramsData = params;

      try {
        const {data} = await axiosInstance.get("/admin/payment", { params });

        // 객체마다 인덱스 부여
        let idx = 0;
        for (const item of data) {
          item["index"] = idx++;
          if (item["remark"] === "DUPP_SNO") {
            this.duppSnoLength += 1;
          }
        }
        if (this.duppSnoLength > 0) {
          alert(`${this.duppSnoLength}개의 중복건이 존재합니다`);
        }

        this.settlementDate = params.settlementDate;
        this.rawData = data;
        // 목록 데이터
        // this.tableData = data;
        this.tableData = data.filter(item => !(item.sumReward === 0 && item.rewardStatusCd === "SUCCESS"));
        // 조회 시 실행
        this.searching = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    // filter(상태, 회원명, 주민등록번호)
    filter(status, nm, ssno) {
      this.loading = true;

      // 상태가 전체이고 회원명,주민번호 없을때
      if (status === "ALL" && !nm && !ssno) {
        // 전체 데이터를 필터
        // this.tableData = this.rawData;
        this.tableData = this.rawData.filter(item => !(item.sumReward === 0 && item.rewardStatusCd === "SUCCESS"));
        this.showButton = false;
        // 나머지일 경우
      } else if(status === "SUCCESS") {
        this.showButton = true;
        this.tableData = this.rawData.filter((x) => (this.filterFunc(x, status, nm, ssno)));
        this.tableData = this.tableData.filter(item => !(item.sumReward === 0 && item.rewardStatusCd === "SUCCESS"));
      } else {
        this.showButton = false;
        this.tableData = this.rawData.filter((x) => (this.filterFunc(x, status, nm, ssno)));
      }
      this.loading = false;
    },
    // 필터 함수(x, 상태, 회원명, 주민등록번호)
    filterFunc(x, status, nm, ssno) {
      // 상태가 전체가 아닐경우
      if (status !== "ALL") {
        // 회원명이 있고 주민번호가 없을경우
        if (nm && !ssno) {
          return x["rewardStatusCd"] === status && x["recipientNm"] === nm;
        // 회원명이 없고 주민번호가 있을경우
        } else if (!nm && ssno) {
          return x["rewardStatusCd"] === status && x["ssno"] === ssno;
        // 회원명, 주민번호 둘다 있는 경우
        } else if (nm && ssno) {
          return x["rewardStatusCd"] === status && x["recipientNm"] === nm && x["ssno"] === ssno;
        // 나머지
        } else {
          return x["rewardStatusCd"] === status
        }
      // 상태가 전체일 경우
      } else {
        // 회원명이 있고 주민번호가 없을경우
        if (nm && !ssno) {
          return x["recipientNm"] === nm;
          // 회원명이 없고 주민번호가 있을경우
        } else if (!nm && ssno) {
          return x["rewardStatusCd"] === status && x["ssno"] === ssno;
          // 회원명, 주민번호 둘다 있는 경우
        } else if (nm && ssno) {
          return x["rewardStatusCd"] === status && x["recipientNm"] === nm && x["ssno"] === ssno;
          // 나머지
        } else {
          return true;
        }
      }
    },
    toExcel() {
      if (_.isEmpty(this.tableData)) return;
      const data = _.cloneDeep(this.tableData);
      const returnData = [];

      let excelStatus = this.statusData;
      if (excelStatus === "") {
        excelStatus = "전체";
      } else if (excelStatus === "TARGET") {
        excelStatus = "지급예정";
      } else if (excelStatus === "SUCCESS") {
        excelStatus = "지급완료";
      } else if (excelStatus === "FAIL") {
        excelStatus = "지급불가";
      }

      for (const item of data) {
        const obj = {
          "기준연월": item["settlementDate"],
          "지급이력": item["hasSuccess"] ? "기존" : "신규",
          "회원명": item["recipientNm"],
          "휴대폰번호": item["telNum"],
          "건수": item["rowCount"],
          "발생금액": item["plusReward"],
          "환수금액": item["minusReward"],
          "지급액": item["sumReward"],
          "주민등록번호": item["ssno"],
          "은행명": item["bankNm"],
          "계좌번호": item["bankAccount"],
          "상태": this.searchByCodeInSelectList(this.REWARD_STATUS, item["rewardStatusCd"]),
          "비고": this.searchByCodeInSelectList(this.REMARKS, item["remark"])
        }
        returnData.push(obj);
      }

      const sheetName = `${this.settlementDate}`;
      const fileName = `지급처리-(${excelStatus})(${this.settlementDate})`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    async updateRow(selectData, status, remark) {
      this.loading = true;

      try {
        await axiosInstance.put("/admin/payment", selectData, {
          params: {
            rewardStatus: status,
            remark: remark
          }
        });

        for (const item of selectData) {
          const raw = _.find(this.rawData, {
            recipientId: item["recipientId"]
          });

          const table = _.find(this.tableData, {
            recipientId: item["recipientId"]
          });

          const value = this.searchByCodeInSelectList(this.REWARD_STATUS, status);
          raw["rewardStatusCd"] = status;
          raw["rewardStatusValue"] = value;
          table["rewardStatusCd"] = status;
          table["rewardStatusValue"] = value;

          raw["remark"] = table["remark"] = _.isEmpty(remark) ? "" : remark;
        }

        alert("상태가 업데이트 되었습니다!");
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async bankInputRequest(selectData) {
      this.loading = true;

      try {
        await axiosInstance.post("/admin/payment/account", selectData);
        alert("알림톡이 전송되었습니다!");
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async chgOnlySettlementDate(value) {
      this.onlySettlementDate = value;
    },
    initData() {
      this.searching = false;
      this.settlementDate = "";
      this.rawData = [];
      this.tableData = [];
      this.statusData = "";
    },
    async sendAlarmTalk(selectData) {
      this.loading = true;

      let paymentMgmtDto = selectData;

      try {
        await axiosInstance.post('/admin/payment/send', paymentMgmtDto, {
          params: {
            searchType: this.paramsData.searchType,
          }
        });
        alert(`${selectData.length}개의 알림톡이 전송되었습니다.`);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>