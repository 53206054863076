<template>
  <v-col cols="12" class="py-0">
    <!-- 엑셀업로드 -->
    <div class="text-right">
      <outlined-btn class="grey white--text px-15" @click="toExcel" >엑셀다운로드</outlined-btn>
    </div>

    <div class="my-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <v-btn @click="showSaveModal" class="blue white--text theme--light float-right marginBtn" style="margin-left: 5px;">요청입력</v-btn>
      <v-btn @click="contractPremAmt('compare')" class="purple white--text theme--light float-right marginBtn" style="margin-left: 5px;">비교견적연결</v-btn>
      <v-btn @click="contractPremAmt('PremAmt')" class="white--text float-right marginBtn" style="margin-left: 5px; background-color: cadetblue;">보험사바로가기</v-btn>
      <v-btn @click="show('alarmTalkModal')" class="orange white--text theme--light float-right marginBtn" style="margin-left: 5px;">알림톡발송</v-btn>
      <v-btn @click="refresh" class="theme--light float-right marginBtn" style="margin-left: 5px;">새로고침</v-btn>

      <!--      <v-btn @click="goCsDesign('request')" class="theme&#45;&#45;light float-right marginBtn" style="margin-left: 5px;">설계요청</v-btn>-->
      <!--      <v-btn @click="goCsDesign('main')" class="theme&#45;&#45;light float-right marginBtn" style="margin-left: 5px;">설계지원센터</v-btn>-->
    </div>

    <v-flex xs12 sm12 md12 lg12 xl12 :key="tgKey">
      <!-- 목록 -->
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="headers"
          :class="{'top-table': listIsNotEmpty}"
          @click:row="clickContent"
          @dblclick:row="modifySupport"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >

        <!-- 선택 -->
        <template v-slot:item.designSupId="{ value }">
          <v-radio-group v-model="selected">
            <v-radio :value="value"/>
          </v-radio-group>
          {{ value }}
        </template>

        <!-- 상태 -->
        <template v-slot:item.state="{ value, item, header }">
          <select-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.designSupId"
              :header="header.value"
              :items="stateNmList"
          />
        </template>

        <!-- 요청일자 -->
        <template v-slot:item.createDate = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

        <!-- 고객명 -->
        <template v-slot:item.customerNm = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

        <!-- 연락처 -->
        <template v-slot:item.customerTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <!-- 차량번호 -->
        <template v-slot:item.contractPremAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <!-- 차량번호 -->
        <template v-slot:item.customerCarNo = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

        <!-- FA생년월일 -->
        <template v-slot:item.birthday = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{getDotsBirthday(value)}}</div>
            </template>
            <span>{{getDotsBirthday(value)}}</span>
          </v-tooltip>
        </template>

        <!-- FA연락처 -->
        <template v-slot:item.telNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <!-- FA메모 -->
        <template v-slot:item.memberMemo = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-clipboard:copy="value" v-clipboard:error="onError" class="overflow" v-on="on" @click="snackbar = true">{{value}}</div>
            </template>
            <span >{{value}}</span>
            <v-snackbar v-model="snackbar" :top="y === 'top'" :timeout="timeout">"{{value}}" 복사완료</v-snackbar>
          </v-tooltip>
        </template>

        <!-- 상담메모 -->
        <template v-slot:item.consultMemo = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- 설계지원 이력 -->
      <div class="my-2">
        <span class="text-h6">설계지원 이력</span>
      </div>
      <v-data-table
          height="25vh"
          :items="detailList"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="headersDetail"
          @click:row="clickContent"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >

        <!-- 설계지원 이력-저장일시 -->
        <template v-slot:item.createDate = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

        <!-- 설계지원 이력-고객명 -->
        <template v-slot:item.customerNm = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

        <!-- 설계지원 이력-연락처 -->
        <template v-slot:item.customerTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <!-- 설계지원 이력-차량번호 -->
        <template v-slot:item.customerCarNo = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

        <!-- 설계지원 이력-상담메모 -->
        <template v-slot:item.consultMemo = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{value}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-flex>

    <!-- 요청입력 컴포넌트 -->
    <design-support-save
        :is-show="isSaveShow"
        @close="isSaveShow = false"
        @updateSetting="$emit('updateSetting')"
    />

    <!-- 알림톡 발송 컴포넌트 -->
    <design-support-alarm-talk-modal
        :is-show="isAlarmTalkShow"
        :rowData="rowData"
        :alarmData="alarmData"
        @close="isAlarmTalkShow = false"
        @seqVal="seqValData"
        @updateSetting="$emit('updateSetting')"
    />

    <!-- 설계지원 이력수정 컴포넌트 -->
    <design-support-modify-modal
        :is-show="isModifyShow"
        @close="isModifyShow = false"
        @updateSetting="$emit('updateSetting')"
        :rowData="rowData"
        :isPrvGroupTM="isPrvGroupTM"
        :consultResultList="consultResultList"
    />
  </v-col>
</template>

<script>
import func from "@/util/func";
import {axiosInstance} from "@/util/AxiosModule";
import SelectInput from "../../../Settlement/components/SelectInput";
import _ from "lodash";
import DesignSupportSave from "@/pages/Support/components/DesignSupport/DesignSupportSave";
import DesignSupportAlarmTalkModal from "@/pages/Support/components/DesignSupport/DesignSupportAlarmTalkModal";
import DesignSupportModifyModal from "@/pages/Support/components/DesignSupport/DesignSupportModifyModal";
import store from "@/store";
import { rules } from "@/util/constant"
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";


export default {
  components: {DesignSupportSave, DesignSupportAlarmTalkModal, DesignSupportModifyModal, SelectInput, OutlinedBtn},
  props: ["data", "loading", "search", "conditionHist", "isPrvGroupTM", "consultResultList"],
  mixins: [TableMixin],
  data: () => ({
    rules: rules,
    headers: [
      {text: "설계지원 ID", value: "designSupId", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "상태", value: "state", class: "teal lighten-4", align: "center", sortable: false, width: "3vw"},
      {text: "요청일자", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "고객명", value: "customerNm", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "연락처", value: "customerTelNum", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "계약현황", value: "contractStateNm", class: "indigo lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "계약보험료", value: "contractPremAmt", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "차량번호", value: "customerCarNo", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "전계약사", value: "preInsurerCd", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "상담결과", value: "consultResult", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "DB유형", value: "infoServiceTypeList", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "채널", value: "channel", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "FA명", value: "memberNm", class: "indigo lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "FA생년월일", value: "birthday", class: "indigo lighten-4", align: "center", sortable: false, width: "7vw"},
      {text: "FA연락처", value: "telNum", class: "indigo lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "FA메모", value: "memberMemo", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상담메모", value: "consultMemo", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "등록자명", value: "regNm", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "상담사명", value: "csNm", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "진행자명", value: "ctCsNm", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
    ],
    headersDetail: [
      {text: "순번", value: "designSupId", class: "teal lighten-4", align: "center", sortable: false, width: "3vw"},
      {text: "상태", value: "stateNm", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "저장일시", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "고객명", value: "customerNm", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "연락처", value: "customerTelNum", class: "teal lighten-4", align: "center", sortable: false, width: "9vw"},
      {text: "차량번호", value: "customerCarNo", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "전계약사", value: "preInsurerCd", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "입력출처", value: "inputSrc", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "상담결과", value: "consultResult", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "등록자명", value: "regNm", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "상담사명", value: "csNm", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "진행자명", value: "ctCsNm", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "상담메모", value: "consultMemo", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
    ],
    isSaveShow: false,
    isModifyShow: false,
    detailList:[],
    selectedList:[],
    selected:'',
    listIsNotEmpty: false,
    stateNmList: [
      { text: "상태값오류", value: "상태값오류"},
      { text: "대기", value: "WAIT"},
      { text: "진행중", value: "PROC"},
      { text: "종료", value: "DONE"},
    ],
    rowData:[],
    alarmData:[],
    isAlarmTalkShow: false,
    memberData: [],
    apiData:[],
    seqVal:0,
    tgKey: 0,
    snackbar: false,
    timeout: 3000,
    y: 'top',
  }),
  watch: {
    search: function (obj) {
      if (_.isEmpty(obj)) {
        this.seqVal = 0;
      }
    },
    data: function (value) {
      this.selectedList = [];
      this.rowData = [];
      this.detailList = [];
      this.selectedList = this.seqVal;
      this.tgKey++;
      this.listIsNotEmpty = (value.length >= 1)
    },
  },
  async created() {
    await this.getMember();
  },
  methods: {
    seqValData(val) {
      this.seqVal = val;
    },
    async getMember() {
      let memberId = [];
      memberId = store.getters['login/memberId']
      try {
        const {data} = await axiosInstance.post('/admin/operation/memberId', memberId);
        this.memberData = data;
      } catch (e) {
        console.log(e);
      }
      await this.getAPI();
    },
    async getAPI() {
      const membershipDto = {
        birthday : this.memberData.birthday,
        orgNm : this.memberData.memberNm,
        telNum : this.memberData.telNum
      }
      try {
        const {data} = await axiosInstance.post('public/incar/membership', membershipDto);
        this.apiData = data;
      } catch (e) {
        console.log(e);
      }
    },
    // 설계지원 이력 보기
    async clickContent(row) {
      this.selected = row.designSupId;
      const params = {
        designSupId: row.designSupId
      }
      try {
        const { data } = await axiosInstance.get('/admin/operation/designSupHistList',  { params });
        this.detailList = data;
        func.contentsDataFormatter(this.detailList);
      } catch (e) {
        console.log(e);
      }
    },
    // 보험사바로가기 및 비교견적연결
    contractPremAmt(val) {
      let rowData = [];
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].designSupId === this.selected) {
          rowData = this.data[i];
        }
      }
      if (_.isEmpty(rowData)) return;
      if (this.apiData.memberShip === "1" || this.apiData.memberShip === "2"){
        if (val === 'PremAmt') {
          if (process.env.NODE_ENV === "development") {
            const url = "https://test.1click.direct/designSupIntro/list/?token=" + this.apiData.icToken + '&designSupId=' + rowData.designSupId;
            window.open(url, "_blank","width=500, height=1000");
          } else {
            const url = "https://1click.direct/designSupIntro/list/?token=" + this.apiData.icToken + '&designSupId=' + rowData.designSupId;
            window.open(url, "_blank","width=500, height=1000");
          }
        } else {
          return new Promise(() => {

            const membershipDto = {
              birthday : rowData.birthday,
              orgNm : rowData.memberNm,
              telNum : rowData.telNum
            }

            axiosInstance
                .post("public/incar/membership", membershipDto)
                .then(res => {
                  if (process.env.NODE_ENV === "development") {
                    const url = "https://test.1click.direct/incar/carprem/" + res.data.icToken;
                    window.open(url, "_blank");
                  } else {
                    const url = "https://1click.direct/incar/carprem/" + res.data.icToken;
                    window.open(url, "_blank");
                  }
                })
          });
        }
      } else {
        if (this.apiData.memberShip === "3") {
          alert("비회원 입니다.");
        } else {
          alert("입력값 오류입니다.");
        }
      }
    },
    // 알림톡 발송 컴포넌트 연결
    show(val) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].designSupId === this.selected) {
          this.rowData = this.data[i];
        }
      }
      if (_.isEmpty(this.rowData)) return;
      if (val === 'alarmTalkModal') {
        this.isAlarmTalkShow = true;
        this.alarmList();
      }
    },
    // 요청입력 컴포넌트 연결
    showSaveModal() {
      this.isSaveShow = true;
    },
    // 알림톡 발송 종류 선택
    async alarmList() {
      try {
        const {data} = await axiosInstance.get('/admin/operation/infoServiceList');
        for (let i = 0; i < data.length; i++) {
          data[i].index = i;
        }
        this.alarmData = data;
      } catch (e) {
        console.log(e);
      }
    },
    // 지우기
    refresh() {
      this.getAPI();
    },
    onError(e) {
      alert('Failed to copy texts' + e.text)
    },
    // Excel 관련 코드
    async toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "상태": item["state"],
          "요청일자": item["createDate"],
          "고객명": item["customerNm"],
          "연락처": item["customerTelNum"],
          "계약현황": item["contractStateNm"],
          "계약보험료": item["contractPremAmt"],
          "차량번호": item["customerCarNo"],
          "전계약사": item["preInsurerCd"],
          "입력출처": item["inputSrc"],
          "상담결과": item["consultResult"],
          "DB유형": item["infoServiceTypeList"],
          "채널": item["channel"],
          "FA명": item["memberNm"],
          "FA연락처": item["telNum"],
          "FA메모": item["memberMemo"],
          "안내보험사": item["guideInsurerCdList"],
          "만기일자": item["expiryDt"],
          "상담메모": item["consultMemo"],
          "등록자명": item["regNm"],
          "상담사명": item["csNm"],
          "진행자명": item["ctCsNm"]
        }
        returnData.push(obj);
      }

      const sheetName = `설계지원`;
      const fileName = `설계지원`;
      let conditionHistString = JSON.stringify(this.conditionHist);

      await func.saveXlsExtractHist("설계지원", returnData.length, conditionHistString, fileName, "HIGH");
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    // 설계지원 이력수정
    modifySupport() {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].designSupId === this.selected) {
          this.rowData = this.data[i];
        }
      }
      this.isModifyShow = true;
    }

    // 설계지원 테스트
    , goCsDesign(type) {
      console.log(this.apiData);
      let rowData = [];
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].designSupId === this.selected) {
          rowData = this.data[i];
        }
      }
      const csKey = "CS20238b80e5f285e6";
      const csId = "user4101";
      const popNo = "2";
      if (type === "request") {
        const url = "http://localhost:8082/cs/request/?csKey=" + csKey + '&csId=' + csId;
        window.open(url, "_blank", "width=720, height=900");
      } else if (type === "main") {
        const membershipDto = {
          birthday: rowData.birthday,
          orgNm: rowData.memberNm,
          telNum: rowData.telNum
        };
        console.log(membershipDto);
        axiosInstance
            .post("/public/incar/membership", membershipDto)
            .then(res => {
              const url = "http://localhost:8082/designSupIntro/main/?popNo=" + popNo + "&token=" + res.data.icToken + "&designSupId=" + rowData.designSupId + "&csKey=" + csKey + "&csId=" + csId;
              window.open(url, "_blank", "width=1440, height=1200");
            })
      }
    }
  }
}

</script>
<style scoped>
.v-data-table::v-deep table {
  table-layout: fixed
}

.overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;;
}

.v-data-table::v-deep.top-table tr td:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>