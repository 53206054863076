<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>담보조건 입력</v-card-title>
      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">상품군</v-col>
          <v-col cols="7">
            <v-select
                @click="getPrProdLineCd"
                v-model="dataDto.prProdLineCd"
                :items="prProdLineCdList"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                class="subBar"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.cvrDiv"
                :items="cvrDivList"
                item-text="cvrDivNm"
                item-value="cvrDiv"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                class="subBar"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">신정원 담보코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.creCvrCd"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                :disabled="dataDto.creCvrCd === creCvrRowData.creCvrCd"
                append-icon="mdi-land-rows-horizontal"
                @click:append="showCreCvrCd"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">신정원 담보명</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.creCvrNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                :disabled="dataDto.creCvrNm === creCvrRowData.creCvrNm"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">적용조건기준</v-col>
          <v-col cols="7">
            <v-select
                @change="changeCondBase"
                v-model="dataDto.condBase"
                :items="condBaseList"
                item-text="condBaseNm"
                item-value="condBase"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">보험료합산구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.premSumType"
                :items="premSumTypeList"
                item-text="premSumTypeNm"
                item-value="premSumType"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                class="subBar"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">화면순서</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.dispOrder"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">가입금액</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.baseAccAmt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">보험사 담보코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.cvrCd"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                :disabled="dataDto.cvrCd === insRowData.cvrCd"
                append-icon="mdi-land-rows-horizontal"
                @click:append="showInsCvrCd"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">보험사</v-col>
          <v-col cols="7">
            <v-select
                @click="getInsCd"
                v-model="dataDto.insCd"
                :items="insCdList"
                item-text="insCdNm"
                item-value="insCd"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                :disabled=" dataDto.insCd === insRowData.insCd"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">보험사 담보명</v-col>
          <v-col cols="7">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="dataDto.cvrNm"
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                      :disabled="dataDto.cvrNm === insRowData.cvrNm"
                  />
                </div>
              </template>
              <span> {{ dataDto.cvrNm }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">보험사 상품명</v-col>
          <v-col cols="7">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-text-field
                    v-model="dataDto.prNm"
                    outlined dense hide-details
                    background-color="white" menu-props="auto"
                    :disabled="dataDto.prNm === insRowData.prNm"
                />
              </div>
            </template>
            <span>{{ dataDto.prNm }}</span>
          </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">보험기간</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.insTrm"
                :items="insTrmList"
                item-text="insTrmNm"
                item-value="insTrm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">납입기간</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.pyTrm"
                :items="pyTrmList"
                item-text="pyTrmNm"
                item-value="pyTrm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">갱신주기</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.rnwCycle"
                :items="rnwCycleList"
                item-text="rnwCycleNm"
                item-value="rnwCycle"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="deleteAllData"
        >전체초기화</v-btn>
        <v-btn
            color="orange lighten-1"
            class="white--text col-1"
            @click="deleteInsData"
        >보험초기화</v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >저장</v-btn>
      </v-card-actions>

      <cre-cvr-mapping-modal
          :is-show="isShowCreCvrCd"
          @close="isShowCreCvrCd = false"
          @row="creCvrRow"
      />

      <ins-cvr-modal
        :is-show="isShowInsCvrCd"
        @close="isShowInsCvrCd = false"
        @row="insRow"
      />
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import CreCvrMappingModal
  from "@/pages/Product/components/ProductCompareManagement/CreCvrMappingModal/CreCvrMappingModal";
import InsCvrModal from "@/pages/Product/components/ProductCompareManagement/InsCvrModal/InsCvrModal";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  components: {InsCvrModal, CreCvrMappingModal},
  props: ["cvrRowData"],
  data() {
    return {
      dataDto: {
        'prProdLineCd':'',
        'prProdLineNm':'',
        'cvrDiv':'',
        'condBase':'',
        'creCvrCd':'',
        'creCvrNm':'',
        'premSumType':'',
        'dispOrder':'',
        'baseAccAmt':'',
        'insCd':'',
        'insNm':'',
        'cvrCd':'',
        'cvrNm':'',
        'prCd':'',
        'prNm':'',
        'insTrm':'',
        'pyTrm':'',
        'rnwCycle':''
      },
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      cvrDivList: [
        {cvrDivNm: "선택", cvrDiv: ""},
        {cvrDivNm: "의무사항", cvrDiv: "1"},
        {cvrDivNm: "선택사항", cvrDiv: "2"}
      ],
      condBaseList: [
        {condBaseNm: "선택", condBase: ""},
        {condBaseNm: "공통", condBase: "1"},
        {condBaseNm: "개별", condBase: "2"}
      ],
      premSumTypeList: [
        {premSumTypeNm: "선택", premSumType: ""},
        {premSumTypeNm: "합산", premSumType: "1"},
        {premSumTypeNm: "최대값", premSumType: "2"},
        {premSumTypeNm: "최초값", premSumType: "3"}
      ],
      insCdList: [
        {insCdNm: "선택", insCd: ""},
        {insCdNm: "전체", insCd: "A00"}
      ],
      insTrmList: [
        {insTrmNm: "선택", insTrm: ""},
        {insTrmNm: "3년", insTrm: "3년"},
        {insTrmNm: "5년", insTrm: "5년"},
        {insTrmNm: "10년", insTrm: "10년"},
        {insTrmNm: "15년", insTrm: "15년"},
        {insTrmNm: "20년만기", insTrm: "20년만기"},
        {insTrmNm: "80세만기", insTrm: "80세만기"},
        {insTrmNm: "90세만기", insTrm: "90세만기"},
        {insTrmNm: "100세만기", insTrm: "100세만기"},
        {insTrmNm: "종신", insTrm: "종신"}
      ],
      pyTrmList: [
        {pyTrmNm: "선택", pyTrm: ""},
        {pyTrmNm: "3년", pyTrm: "3년"},
        {pyTrmNm: "5년", pyTrm: "5년"},
        {pyTrmNm: "7년", pyTrm: "7년"},
        {pyTrmNm: "10년", pyTrm: "10년"},
        {pyTrmNm: "15년", pyTrm: "15년"},
        {pyTrmNm: "20년", pyTrm: "20년"},
        {pyTrmNm: "30년", pyTrm: "30년"},
        {pyTrmNm: "전기납", pyTrm: "전기납"}
      ],
      rnwCycleList: [
        {rnwCycleNm: "선택", rnwCycle: ""},
        {rnwCycleNm: "3년", rnwCycle: "3년"},
        {rnwCycleNm: "5년", rnwCycle: "5년"},
        {rnwCycleNm: "10년", rnwCycle: "10년"},
        {rnwCycleNm: "15년", rnwCycle: "15년"},
        {rnwCycleNm: "20년", rnwCycle: "20년"},
        {rnwCycleNm: "30년", rnwCycle: "30년"}
      ],
      isShowCreCvrCd: false,
      isShowInsCvrCd: false,
      insRowData: [],
      creCvrRowData: [],
    }
  },
  watch: {
    cvrRowData: function (value) {
      this.dataDto.prProdLineCd = value.prProdLineCd;
      this.dataDto.prProdLineNm = value.prProdLineNm;
      this.prProdLineCdList[0].prProdLineCd = value.prProdLineCd;
      this.prProdLineCdList[0].prProdLineNm = value.prProdLineNm;
      this.dataDto.cvrDiv = value.cvrDiv;
      this.dataDto.creCvrCd = value.creCvrCd;
      this.dataDto.creCvrNm = value.creCvrNm;
      this.dataDto.condBase = value.condBase;
      this.dataDto.premSumType = value.premSumType;
      this.dataDto.dispOrder = value.dispOrder;
      this.dataDto.baseAccAmt = value.baseAccAmt;
      this.dataDto.cvrCd = value.cvrCd;
      this.dataDto.cvrNm = value.cvrNm;
      this.dataDto.insCd = value.insCd;
      this.dataDto.insCdNm = value.insCdNm;
      this.insCdList[0].insCd = value.insCd;
      this.insCdList[0].insCdNm = value.insNm;
      this.dataDto.prCd = value.prCd;
      this.dataDto.prNm = value.prNm;
      this.dataDto.insTrm = value.insTrm;
      this.dataDto.pyTrm = value.pyTrm;
      this.dataDto.rnwCycle = value.rnwCycle;
    }
  },
  methods: {
    async getPrProdLineCd() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineCdList.shift();
    },
    async getInsCd() {
      this.insCdList = await ProductRecommend.getInsCd();
      this.insCdList.shift();
    },
    showCreCvrCd() {
      this.isShowCreCvrCd = true;
    },
    showInsCvrCd() {
      this.isShowInsCvrCd = true;
    },
    deleteAllData() {
      this.dataDto.prProdLineCd = "";
      this.dataDto.prProdLineNm = "선택";
      this.prProdLineCdList[0].prProdLineCd = "";
      this.prProdLineCdList[0].prProdLineNm = "선택";
      this.dataDto.cvrDiv = "";
      this.dataDto.cvrDivNm = "선택";
      this.dataDto.creCvrCd = "";
      this.dataDto.creCvrNm = "";
      this.dataDto.condBase = "";
      this.dataDto.condBaseNm = "선택";
      this.dataDto.premSumType = "";
      this.dataDto.premSumTypeNm = "선택";
      this.dataDto.dispOrder = "";
      this.dataDto.baseAccAmt = "";
      this.dataDto.insCd = "";
      this.dataDto.insCdNm = "선택";
      this.insCdList[0].insCd = "";
      this.insCdList[0].insCdNm = "선택";
      this.dataDto.cvrCd = "";
      this.dataDto.cvrNm = "";
      this.dataDto.prNm = "";
      this.dataDto.insTrm = "";
      this.dataDto.insTrmNm = "선택";
      this.dataDto.pyTrm = "";
      this.dataDto.pyTrmNm = "선택";
      this.dataDto.rnwCycle = "";
      this.dataDto.rnwCycleNm = "선택";
    },
    deleteInsData() {
      this.dataDto.insCd = "";
      this.dataDto.insCdNm = "선택";
      this.insCdList[0].insCd = "";
      this.insCdList[0].insCdNm = "선택";
      this.dataDto.cvrCd = "";
      this.dataDto.cvrNm = "";
      this.dataDto.prNm = "";
      this.dataDto.insTrm = "";
      this.dataDto.insTrmNm = "선택";
      this.dataDto.pyTrm = "";
      this.dataDto.pyTrmNm = "선택";
      this.dataDto.rnwCycle = "";
      this.dataDto.rnwCycleNm = "선택";
    },
    async saveData() {
      let prProdCompTbList = new Array(this.dataDto);

      try {
        await axiosInstance.post('/admin/prdata/prProdCompPrSave', prProdCompTbList, {
          params: {
            jobType: "C"
          }
        });
        alert("저장되었습니다.");
      } catch (e) {
        console.log(e);
      }
      this.$emit("updateData");
    },
    insRow(row) {
      this.insCdList[0].insCd = row.insCd;
      this.insCdList[0].insCdNm = row.insNm;
      this.dataDto.insCd = row.insCd;
      this.dataDto.insCdNm = row.insNm;
      this.dataDto.cvrCd = row.cvrCd;
      this.dataDto.cvrNm = row.cvrNm;
      this.dataDto.prCd = row.prCd;
      this.dataDto.prNm = row.prNm;

      this.insRowData = row;
    },
    creCvrRow(row) {
      this.dataDto.creCvrCd = row.creCvrCd;
      this.dataDto.creCvrNm = row.creCvrNm;

      this.creCvrRowData = row;
    },
    changeCondBase() {
      if (this.dataDto.condBase === "1") {
        this.dataDto.insCdNm = "전체";
        this.dataDto.insCd = "A00";
        this.dataDto.prNm = "상품군명";
        this.dataDto.prCd = "상품군코드";
        this.dataDto.cvrNm = this.dataDto.creCvrNm;
        this.dataDto.cvrCd = this.dataDto.creCvrCd;
      }
    }
  }
}
</script>