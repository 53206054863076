<template>
  <search-page-layout title="상품추천 데이터 작업 현황">
    <template v-slot:tabBar>
      <v-tabs class="ml-3" v-model="tab">
        <v-tab>집계</v-tab>
        <v-tab>건별</v-tab>
      </v-tabs>
    </template>
    <template v-slot:searchBar>
      <product-recommend-work-status-search-bar
        :tab="tab"
        :prProdLineList="prProdLineList"
        :insListTypeList="insListTypeList"
        :insList="insList"
        @search="getSearch"
        @createTarget="createTarget"
        :isTotalSearch="isTotalSearch"
        :rowData="rowData"
        @resetDetail="resetDetail"
        @changeStYm="changeStYm"
      />
    </template>
    <template v-slot:table>
      <product-recommend-work-status-table
        :tab="tab"
        :prProdLineList="prProdLineList"
        :insList="insList"
        :wkStateList="wkStateList"
        :wkSubStateList="wkSubStateList"
        :detailParams="detailParams"
        :data="data"
        @rowClickSearch="rowClickSearch"
        :detailData="detailData"
        @toExcel="toExcel"
        :isReset="isReset"
        @updateData="updateData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductRecommendWorkStatusSearchBar
  from "@/pages/Product/components/ProductRecommendWorkStatus/ProductRecommendWorkStatusSearchBar";
import ProductRecommendWorkStatusTable
  from "@/pages/Product/components/ProductRecommendWorkStatus/ProductRecommendWorkStatusTable";
import {axiosInstance} from "@/util/AxiosModule";
import ProductRecommend from "@/util/ProductRecommend";
import CodeMixin from "@/mixin/CodeMixin";
import _ from "lodash";
import func from "@/util/func";
import store from "@/store";
import constant from "@/util/constant";
import DateMixin from "@/mixin/DateMixin";

export default {
  components: { ProductRecommendWorkStatusTable, ProductRecommendWorkStatusSearchBar, SearchPageLayout},
  mixins: [CodeMixin, DateMixin],
  data() {
    return {
      tab: [0, 1],
      prProdLineList: [],                                  // 상품군 리스트
      insListTypeList: [                                   // 세부상태 리스트
        { insListTypeNm: "전체", insListType: "" },
        { insListTypeNm: "손보", insListType: "N" },
        { insListTypeNm: "생보", insListType: "L" }
      ],
      insList: [],
      wkStepList: [],                                     // 작업단계 리스트
      wkSubStepList: [],                                  // 세부단계 리스트
      wkStateList: [                                      // 작업상태 리스트
        { wkStateNm: "진행", wkStateCd: "ON" },
        { wkStateNm: "종료", wkStateCd: "OFF" }
      ],
      wkSubStateList: [                                   // 세부상태 리스트
        { wkSubStateNm: "진행전", wkSubStateCd: "YET" },
        { wkSubStateNm: "진행중", wkSubStateCd: "PROC" },
        { wkSubStateNm: "완료", wkSubStateCd: "DONE" }
      ],
      rowData: {},
      totalParams: {},
      detailParams: {},
      loading: false,
      isReset: false,
      isTotalSearch: false, // 집계조회
      data: [],
      detailData: []
    }
  },
  async created() {
    this.prProdLineList = await ProductRecommend.getPrProdLineCd();
    this.prProdLineList.find(item => item.prProdLineNm === "전체" ? item.prProdLineCd = "ALL" : item.prProdLineCd);
    this.insList = await ProductRecommend.getInsCd();
    this.insList.find(item => item.insCdNm === "전체" ? item.insCd = "ALL" : item.insCd);
  },
  methods: {
    async getSearch(type, params) {
      this.loading = true;
      this.isReset = false;
      if (type === "total") { // 집계 조회
        this.data = [];
        this.totalParams = params;
        const data = await this.getPrWkTotalList(params);
        if (data.length < 1) {
          this.data = [];
          this.isTotalSearch = false;
          await this.createTarget(
              `${params.stYm.replace(/(\d{4})(\d{2})/, '$1년 $2월')}에 대한 대상건이 없습니다. 대상건을 선정하시겠습니까?`,
              { stYm: this.totalParams.stYm, regId: store.getters["login/memberId"] }
          );
        } else {
          data.forEach(item => {
            if (item.insNm === "전체") {
              item.insCd = "ALL";
            } else if (item.prProdLineNm === "전체") {
              item.prProdLineCd = "ALL";
            }
          });
          this.isTotalSearch = true;
          this.data = data;
        }
      } else if (type === "detail") { // 건별 조회
        this.detailData = [];
        this.detailParams = params;
        const data = await this.getPrWkDetailList(params);
        if (data.length < 1) {
          alert("조회된 데이터가 없습니다.");
        } else {
          let idx = 0;
          for (const item of data) {
            item["index"] = idx++;
          }
          this.detailData = data;
        }
      }
    },
    async createTarget(message, params) {  // 대상건 선정
      if (confirm(message)) {
        this.loading = true;
        try {
          const { data } = await axiosInstance.post("/admin/prdata/setPrWkMng", {}, { params });
          alert("대상건 선정이 완료되었습니다");
          this.data = data;
          data.forEach(item => {
            if (item.insNm === "전체") {
              item.insCd = "ALL";
            } else if (item.prProdLineNm === "전체") {
              item.prProdLineCd = "ALL";
            }
          });
          this.loading = false;
        } catch (e) {
          console.log(e);
        }
      }
    },
    async rowClickSearch(rowData) { // 집계 목록 선택 -> 건별 조회
      this.rowData = rowData;
      const params = {
        stYm: this.totalParams.stYm,
        prProdLineCd: rowData.prProdLineCd,
        insCd: rowData.insCd,
        prCd: rowData.type === "S" ? rowData.target : ""
      };
      this.detailParams = params;
      this.detailData = [];
      this.isTotalSearch = true;
      const data = await this.getPrWkDetailList(params);
      if (data.length < 1) {
        alert("조회된 데이터가 없습니다.");
      } else {
        let idx = 0;
        for (const item of data) {
          item["index"] =  idx++;
        }
        this.detailData = data;
        this.tab = 1;
      }
    },
    async getPrWkTotalList(params) {  // 집계조회 API
      let prWkTotalList = [];
      this.loading = true;
      try {
        const { data }= await axiosInstance.get("/admin/prdata/getPrWkMng", { params });
        prWkTotalList = data;
        this.loading = false;
        return prWkTotalList;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      return false;
    },
    async getPrWkDetailList(params) { // 건별조회 API
      let prWkDetailList = [];
      this.loading = true;
      try {
        const { data } = await axiosInstance.get("/admin/prdata/getPrWkProc", { params });
        prWkDetailList = data;
        this.loading = false;
        return prWkDetailList;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      return false;
    },
    async toExcel() {
      if (_.isEmpty(this.detailData)) return;
      const data = _.cloneDeep(this.detailData);
      const returnData = [];

      let nowDate = new Date();
      let nowHours = nowDate.getHours();
      let nowMinutes = nowDate.getMinutes();
      nowDate = this.getDateNoDashFormat(nowDate);

      for (const item of data) {
        const obj = {
          "상품군명": item["prProdLineNm"],
          "보험사명": item["insNm"],
          "상품코드": item["prCd"],
          "상품명": item["prNm"],
          "상품타입": constant.PROD_TYPE.find(items => items.prodType === item["prodType"]).prodTypeNm,
          "적용시기": item["appFromDt"].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
          "적용종기": item["appToDt"].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
          "사용성별": constant.SEX_LIST.find(items => items.cd === item["sex"]).name,
          "업데이트일자": item["newDispEndDt"].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
          "작업상태": item["wkStateCd"] === "-" ? "-" : this.wkStateList.find(item => item.wkStateCd === item["wkStateCd"]).wkStateNm,
          "작업단계": item["wkStepNm"],
          "세부단계": item["wkSubStepNm"],
          "세부상태": this.wkSubStateList.find(item => item.wkSubStateCd === item["wkSubStateCd"]).wkSubStateNm,
          "메모여부": !item["wkMsgCont"] ? "N" : "Y",
          "작업일시": this.getDateNoSecFormat(item["createDate"]),
          "작업자": item["regNm"],
        }
        returnData.push(obj);
      }
      const sheetName = `상품추천 데이터 작업 현황`;
      const fileName = `상품추천 데이터 작업 현황(${nowDate}_${nowHours}_${nowMinutes})`;
      let conditionHistString = JSON.stringify(this.detailParams);
      await func.saveXlsExtractHist("상품추천 데이터 작업 현황", returnData.length, conditionHistString, fileName, "MIDDLE");
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    resetDetail() {
      this.detailData = [];
      this.isReset = true;
      this.isTotalSearch = false;
    },
    changeStYm(date) {
      this.isTotalSearch = date === this.totalParams.stYm;
    },
    updateData() {
      this.getSearch("detail", this.detailParams);
    }
  }
}
</script>