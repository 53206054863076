<template>
  <search-page-layout title="상품정보 관리">
    <template v-slot:searchBar>
      <product-information-management-search-bar
          @getSearch="getSearch"
          ref="search"
      />
    </template>

    <template v-slot:table>
      <product-information-management-table
          :data="data"
          :loading="loading"
          @clickRow="clickRow"
      />
    </template>

    <template v-slot:inflowHist>
      <product-information-management-save
          :key="saveKey"
          :rowData="rowData"
          :prProdLineList="prProdLineList"
          :insCdList="insCdList"
          @resetSearch="resetSearch"
          ref="save"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductInformationManagementSearchBar
  from "@/pages/Product/components/ProductInformationManagement/ProductInformationManagementSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import ProductInformationManagementTable
  from "@/pages/Product/components/ProductInformationManagement/ProductInformationManagementTable";
import ProductInformationManagementSave
  from "@/pages/Product/components/ProductInformationManagement/ProductInformationManagementSave";
import ProductRecommend from "@/util/ProductRecommend";
import _ from "lodash";

export default {
  components: {
    ProductInformationManagementSave,
    ProductInformationManagementTable, ProductInformationManagementSearchBar, SearchPageLayout},
  data() {
    return {
      loading: false,
      data: [],
      rowData: [],

      saveKey: 0,
      prProdLineList: [],
      insCdList: [],
    }
  },

  async created() {
    // 상품군 리스트
    this.prProdLineList = await ProductRecommend.getPrProdLineCd();
    // 보험사 리스트
    this.insCdList = await ProductRecommend.getInsCd();
  },

  methods: {
    // 조회
    async getSearch(params) {
      this.loading = true;

      try {
        const { data } = await axiosInstance.get('/public/prdata/prProdList', { params });
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].createDate = this.data[i].createDate.replace('T', ' ').substr(0, 19);
        }
      } catch (e) {
        console.log(e);
      }
      this.$refs.save.initData(); // 조회누르면 clear
      this.loading = false;
    },
    // 목록데이터 담기
    clickRow(row) {
      this.rowData = _.cloneDeep(row);
      this.saveKey++;
    },
    // 신규 저장시 자동으로 재조회
    resetSearch(){
      this.$refs.search.getSearch();
    }
  }
}
</script>