<template>
  <v-flex>
    <div class="d-flex justify-space-between align-center mx-2">
      <div>
        <span class="text-h6 mr-3">목록 (총 {{ data.length }}개)</span>
        <span>* {{ lastYear }}년 회원 등록 기준</span>
      </div>
      <outlined-btn class="px-5 excel-button" @click="$emit('toExcel')" :disabled="checkDataLength">
        엑셀다운로드
      </outlined-btn>
    </div>
    <v-data-table
      class="mx-2 mt-2"
      height="65vh"
      :headers="getHeader(headers)"
      :loading="loading"
      :items="data"
      :items-per-page="15"
      fixed-header
    >
      <template v-slot:item.telNum="{ value }">
        <div>{{ getTelNum(value) }}</div>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {NONE_SETTLE_MEMBER_TABLE} from "@/util/TableHeaders";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn.vue";

export default {
  components: {OutlinedBtn},
  props: ["loading", "data"],
  mixins: [TableMixin],
  data() {
    return {
      headers: NONE_SETTLE_MEMBER_TABLE,
      lastYear: ""
    }
  },
  created() {
    this.lastYear = this.setLastYear;
  },
  computed: {
    checkDataLength() {
      return this.data.length < 1;
    },
    setLastYear() {
      const now = new Date();
      return now.getFullYear() - 1;
    }
  }
}
</script>

<style scoped>
.excel-button:hover {
  background-color: darkgreen;
  color: #fff;
  border: none;
}
</style>