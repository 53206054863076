<template>
  <search-page-layout title="메시지 전송고객 관리">
    <template v-slot:tabBar>
      <v-tabs
        center-active
        >
        <v-tab @click="reItem">등록대상Pool 추출</v-tab>
        <v-tab @click="reItem">발송대상자 추출</v-tab>
        <v-tab-item>
          <registration-target
              :baseDt = "baseDt"
              :key="tagkey"/>
        </v-tab-item>
        <v-tab-item>
          <shipment-target
              :baseDt = "baseDt"
              :key="tagkey"/>
        </v-tab-item>
      </v-tabs>
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import RegistrationTarget from "./components/Registration/RegistrationTarget";
import ShipmentTarget from "./components/Shipment/ShipmentTarget";
import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {ShipmentTarget, RegistrationTarget, SearchPageLayout},
  data: () => ({
    tagkey:0,
    baseDt:''
  }),
  async created() {
    this.reItem();
  },
  methods: {
    async reItem() {
      let d = new Date();
      let year = d.getFullYear(); // 년
      let month = d.getMonth();   // 월
      let day = d.getDate();      // 일

      let date = new Date(year, month, day - 1).toLocaleDateString().replaceAll(" ","").replaceAll(".", "");

      if (month < 10) {
        date = `${date.slice(0, 4)}0${date.slice(4, 5)}${date.slice(5)}`
      }
      if (day < 10) {
        date = `${date.slice(0, 4)}${date.slice(4, 6)}0${date.slice(6)}`
      }


      try {
        const custExtractInDto = {
          regId : store.getters['login/memberId'],
          baseDt : date,
          wkDiv: '3',
          toCreateDt:'',
          expirYn:'',
          site: '',
          contContentsCd:'',
        };
        const { data } = await axiosInstance.post('/admin/operation/custExtractList', custExtractInDto);
        this.baseDt = data[0].baseDt;
      } catch (e) {
        console.log(e);
        alert("관리자에게 문의해주세요.");
      }
    },
  }
}
</script>