<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
   <v-card class="pa-3" width="80vw" height="100%" max-width="1200" max-height="900">
     <div class="d-flex justify-end align-center pr-10" style="position: absolute; width: 100%;">
       <span style="font-size: 30px; cursor: pointer" @click="closePopup">x</span>
     </div>
     <search-page-layout title="조기정산 환수 대상자 상세">
       <template v-slot:searchBar>
        <search-area>
          <v-row>
            <v-col class="d-flex justify-center align-center col-4">
              <v-col cols="4">회원명</v-col>
              <v-text-field
                  v-model="userData.memberNm"
                  outlined dense hide-details
                  background-color="white"
                  disabled
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-4">
              <v-col cols="4">전화번호</v-col>
              <v-text-field
                  v-model="userData.telNum"
                  outlined dense hide-details
                  background-color="white"
                  disabled
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-4">
              <v-col cols="4">환수여부</v-col>
              <v-text-field
                  v-model="userData.completeYn"
                  outlined dense hide-details
                  background-color="white"
                  disabled
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-4">
              <v-col cols="4">대상건</v-col>
              <v-text-field
                  v-model="userData.totCnt"
                  outlined dense hide-details
                  background-color="white"
                  disabled
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-4">
              <v-col cols="4">합계금액</v-col>
              <v-text-field
                  v-model="userData.totAmt"
                  outlined dense hide-details
                  background-color="white"
                  disabled
              />
            </v-col>
            <v-col class="d-flex justify-center align-center col-4">
              <v-col cols="4">환수일</v-col>
              <v-text-field
                  v-model="userData.completeDate"
                  outlined dense hide-details
                  background-color="white"
                  disabled
              />
            </v-col>
          </v-row>
        </search-area>
       </template>
       <template v-slot:table>
         <v-flex>
           <v-data-table
               class="mx-2"
               height="35vh"
               :headers="getHeader(headers)"
               :items="detailData"
               :loading="detailLoading"
               :items-per-page="-1"
           >
             <template v-slot:item.customerTelNum="{ value }">
               <div>{{ getTelNum(value) }}</div>
             </template>
             <template v-slot:item.expiryDt="{ value }">
               <div>{{ getDateDashFormat(value) }}</div>
             </template>
             <template v-slot:item.contractPremAmt="{ value }">
               <div class="jc-fe">{{ inputComma(value) }}</div>
             </template>
             <template v-slot:item.contractDt="{ value }">
               <div>{{ getDateDashFormat(value) }}</div>
             </template>
             <template v-slot:item.rewardAmt="{ value }">
               <div class="jc-fe">{{ inputComma(value) }}</div>
             </template>
           </v-data-table>
         </v-flex>
       </template>
     </search-page-layout>
   </v-card>
  </v-overlay>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout.vue";
import {SETTLEMENT_PAYBACK_DETAIL_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import _ from "lodash";
import SearchArea from "@/components/Search/SearchArea.vue";
import {axiosInstance} from "@/util/AxiosModule";
import DateMixin from "@/mixin/DateMixin";

export default {
  components: {SearchArea, SearchPageLayout},
  props: ["isShow", "rowData", "paramsData"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: SETTLEMENT_PAYBACK_DETAIL_TABLE,
      userData: {},
      paramsMonth: 0,
      detailData: [],
      detailLoading: false
    }
  },
  watch: {
    isShow(value) {
      if (value) {
        this.detailData = [];
        this.userData = _.cloneDeep(this.rowData);
        if (!_.isEmpty(this.userData.telNum)) {
          this.userData.telNum = this.getTelNum(this.userData.telNum);
        }
        if (this.userData.totAmt !== null && this.userData.totAmt !== undefined) {
          this.userData.totAmt = this.inputComma(this.userData.totAmt);
        }
        if (!_.isEmpty(this.userData.completeDate)) {
          this.userData.completeDate = this.getDateDashFormat(this.userData.completeDate);
        }
        if (!_.isEmpty(this.paramsData)) {
          this.paramsMonth = _.cloneDeep(this.paramsData.months);
        }
        this.getDetailData(this.userData);
      }
    }
  },
  methods: {
    async getDetailData(paramsData) {
      try {
        this.detailLoading = true;
        const { data } = await axiosInstance.get("/public/settlement/getSettlementNoticeMemberSendDetail", {
          params: {
            memberId: paramsData.memberId,
            sendRound: paramsData.sendRound,
            months: this.paramsMonth
          }
        });
        let resultData = data.settlementNoticeMemberSendDetailList;
        if (resultData.length < 1) {
          this.detailData = [];
          this.detailLoading = false;
        } else {
          for (let i = 0; i < resultData.length; i++) {
            resultData[i].index = i;
            resultData[i].payCompleteDate =
                !_.isEmpty(resultData[i].payCompleteDate)
                  ? resultData[i].payCompleteDate.substr(0, 10)
                  : resultData[i].payCompleteDate
          }
          this.detailData = resultData;
          this.detailLoading = false;
        }
      } catch (e) {
        console.log(e);
        this.detailLoading = false;
      }
    },
    closePopup() {
      this.$emit("close");
    }
  }
}
</script>