<template>
  <search-page-layout title="보험사 담보현황">
    <template v-slot:searchBar>
      <ins-cvr-status-search-bar
        @getSearch="getSearch"
      />
    </template>
    <template v-slot:table>
      <ins-cvr-status-table
        :data="data"
        :loading="loading"
        @row="row"
      />
    </template>

    <template v-slot:inflowHist>
      <ins-cvr-status-save
        :rowData="rowData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import InsCvrStatusSearchBar from "@/pages/Product/components/InsCvrStatus/InsCvrStatusSearchBar";
import InsCvrStatusTable from "@/pages/Product/components/InsCvrStatus/InsCvrStatusTable";
import InsCvrStatusSave from "@/pages/Product/components/InsCvrStatus/InsCvrStatusSave";
export default {
  components: {InsCvrStatusSave, InsCvrStatusTable, InsCvrStatusSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      rowData: [],
    }
  },
  methods: {
    async getSearch(params) {
      this.loading = true;
      let prCvrTbDto = params;

      try {
        const { data } = await axiosInstance.post('/private/prdata/prCvrList', prCvrTbDto);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    row(row) {
      this.rowData = row;
    }
  }
}
</script>