<template>
  <search-page-layout title="자동차보험 만기작업현황">

    <template v-slot:searchBar>
      <CarExpirInfoJob-search-bar @getCarExpirInfoJob="getCarExpirInfoJob"/>
    </template>

    <template v-slot:table>
      <CarExpirInfoJob-table
          :data="data"
          :loading="loading"
          @toExcel="toExcel"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import CarExpirInfoJobSearchBar from "@/pages/Operation/components/CarExpirInfoJob/CarExpirInfoJobSearchBar";
import CarExpirInfoJobTable from "@/pages/Operation/components/CarExpirInfoJob/CarExpirInfoJobTable";
import _ from "lodash";
import func from "@/util/func";

export default {
  props: ["name"],
  components: {
    CarExpirInfoJobTable,
    CarExpirInfoJobSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
  }),

  methods: {
    async getCarExpirInfoJob(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/operation/carexpirinfojob', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "일자": item["calDt"],
          "요일": item["calWeekNm"],
          "휴일여부": item["holiDayYn"],
          "생성건수": item["createCnt"],
          "1차안내일자": item["firstInfoDt"],
          "1차안내건수": item["firstInfoCnt"],
          "2차안내일자": item["secondInfoDt"],
          "2차안내건수": item["secondInfoCnt"],
          "3차안내일자": item["thirdInfoDt"],
          "3차안내건수": item["thirdInfoCnt"]
        }
        returnData.push(obj);
      }

      const sheetName = `자동차만기작업`;
      const fileName = `자동차만기작업`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>2d