<template>
  <search-page-layout title="환수 대상자 현황">
    <template v-slot:searchBar>
      <settlement-payback-search-bar
        @search="getSearch"
      />
    </template>
    <template v-slot:table>
      <settlement-payback-table
        :params-data="paramsData"
        :data="data"
        :loading="loading"
        @toExcel="toExcel"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout.vue"
import SettlementPaybackTable from "@/pages/Settlement/components/SettlementPayback/SettlementPaybackTable.vue";
import SettlementPaybackSearchBar from "@/pages/Settlement/components/SettlementPayback/SettlementPaybackSearchBar.vue";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import func from "@/util/func";

export default {
  components: {SettlementPaybackSearchBar, SettlementPaybackTable, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      paramsData: {}
    }
  },
  methods: {
    async getSearch(params) {
      this.paramsData = params;
      try {
        this.loading = true;
        const { data } = await axiosInstance.get("/public/settlement/getSettlementNoticeTargetList", { params });
        if (data.length < 1) {
          this.data = [];
          this.loading = false;
        } else {
          for (let i = 0; i < data.length; i++) {
            if (data[i].sendRound === 0) {
              data[i].send = true;
            }
            data[i].index = i;
            data[i].lastActDate = !_.isEmpty(data[i].lastActDate)
                ? data[i].lastActDate.substr(0, 10)
                : data[i].lastActDate;
            data[i].lastContractDate = !_.isEmpty(data[i].lastContractDate)
                ? data[i].lastContractDate.substr(0, 10)
                : data[i].lastContractDate;
          }
          this.data = data;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "회차": item["sendRound"],
          "회원명": item["memberNm"],
          "전화번호": item["telNum"],
          "환수대상액": item["hrewardAmt"],
          "월정산액": item["mrewardAmt"],
          "총건수": item["totCnt"],
          "총금액": item["totAmt"],
          "최근활동일": item["lastActDate"],
          "최근실적일": item["lastContractDate"],
          "최종발송회차": item["lastSendRound"],
          "상담사명": item["ctCsNm"]
        }
        returnData.push(obj);
      }

      const sheetName = `환수 대상자 현황`;
      const fileName = `환수 대상자 현황`;
      let conditionHistString = JSON.stringify(this.paramsData);

      await func.saveXlsExtractHist("환수 대상자 현황", returnData.length, conditionHistString, fileName, "HIGH");
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>