<template>
  <v-col cols="12" class="py-0">

    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths disable-sort
          :headers="getHeader(BLACK_LIST_TABLE)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >

        <template v-slot:item.ssno="{ value }">
          {{ value ? `${value.slice(0, 6)}-${value.slice(6, 13)}` : '' }}
        </template>

        <template v-slot:item.telNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.createDate="{ value }">
          <span v-if="value">{{ new Date(value).toLocaleString() }}</span>
        </template>
        <template v-slot:item.updateDate="{ value }">
          <span v-if="value">{{ new Date(value).toLocaleString() }}</span>
        </template>

      </v-data-table>
    </v-flex>
  </v-col></template>

<script>
import {BLACK_LIST_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import DateMixin from "@/mixin/DateMixin";
// import _ from "lodash";

export default {
  mixins: [TableMixin, DateMixin],
  props: ["data", "loading"],
  data() {
    return {
      BLACK_LIST_TABLE: BLACK_LIST_TABLE,
    }
  },

  methods: {
    clickContents(row) {
      this.$emit('selectRow', row);
    },
  }
}
</script>