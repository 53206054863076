<template>
  <v-col cols="12" class="py-0">
    <div style="display: flex; justify-content: space-between">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <v-btn style="margin-bottom: 5px" outlined @click="toExcel" :disabled="!data.length > 0">엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          @dblclick:row="getRowItem"
      >
        <template v-slot:item.customerTelNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.inviterNm="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.inviterTelNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.joinType="{ value }">
          {{ value ? searchByCode(JOIN_TYPE, value) : '' }}
        </template>

        <template v-slot:item.joinInsurerCd="{ value }">
          {{ value ? searchByCode(INS_COMPANY_CODE, value) : '' }}
        </template>

        <template v-slot:item.createDate="{ value }">
          <span v-if="value">{{ getKST(value) }}</span>
        </template>



      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import {USER_INFLOW_HEADER} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import _ from "lodash";
import func from "@/util/func";

export default {
  mixins: [TableMixin, CodeMixin, DateMixin],
  props:["loading", "data", "conditionHist"],
  async created() {
    this.INS_COMPANY_CODE = await this.getDetailCodes('INS_COMPANY_CODE');
    this.JOIN_CHANNEL = await this.getDetailCodes('JOIN_CHANNEL');
    this.JOIN_TYPE = await this.getDetailCodes('JOIN_TYPE');
  },
  data: () => ({
    headers: USER_INFLOW_HEADER,
    JOIN_TYPE: [],
    JOIN_CHANNEL: [],
    INS_COMPANY_CODE: [],
  }),
  watch: {
    data(items) {
      for (let item of items) {
        if (item.stateCd === "SC") {
          item.earlySettlementHistId = item.earlySettlementHistId + "*";
        }
      }
    }
  },
  methods: {
    getRowItem(evt, { item }){
      this.$emit('getRowItem', item);
    },
    async toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "서비스구분": item["serviceType"],
          "회원명": item["inviterNm"],
          "회원 전화번호": item["inviterTelNum"],
          "차량번호": item["customerCarNo"],
          "차대번호": item["customerCarIdentificationNo"],
          "보험사": item["joinInsurerCd"],
          "유입 페이지": item["joinType"],
          "유입채널": item["joinChannel"],
          "고객명": item["customerNm"],
          "고객 전화번호": item["customerTelNum"],
          "보험사 유입일시": item["createDate"],
          "조기정산ID": item["earlySettlementHistId"],
          "이륜차여부": item["twoWheelerYn"],
          "법인계약여부": item["corpContYn"]
        }
        returnData.push(obj);
      }
      const sheetName = `보험사 유입이력`;
      const fileName = `보험사 유입이력`;
      let conditionHistString = JSON.stringify(this.conditionHist);

      await func.saveXlsExtractHist("보험사 유입이력 조회", returnData.length, conditionHistString, fileName, "HIGH");
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style scoped>

</style>