<template>
  <search-bar-layout>
    <template v-slot:contents>
      <v-row>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-6 col-12">
          <v-col cols="5">기준년월</v-col>
          <v-text-field
            v-model="baseYm"
            outlined dense hide-details
            background-color="white"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
          <v-select
              v-model="insCd"
              :items="insList"
              item-text="insNm"
              item-value="insCd"
              outlined dense hide-details
              background-color="white"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-6 col-12">
          <v-col cols="4">채널</v-col>
          <v-select
              v-model="channel"
              :items="channelList"
              item-text="channelNm"
              item-value="channel"
              outlined dense hide-details
              background-color="white"
          />
        </v-col>
        <v-col class="d-flex justify-center align-center col-xl-1 col-lg-1 col-md-12 col-12">
          <v-btn @click="search">조회</v-btn>
        </v-col>
      </v-row>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
export default {
  components: {SearchBarLayout},
  data() {
    return {
      baseYm: "",
      insCd: "",
      insList: [
        { insNm: "전체", insCd: "" },
        { insNm: "현대해상", insCd: "HD" },
        { insNm: "메리츠화재", insCd: "MZ" },
        { insNm: "삼성화재", insCd: "SS" },
        { insNm: "흥국화재", insCd: "HK" },
        { insNm: "KB손해보험", insCd: "KB" },
        { insNm: "AXA손해보험", insCd: "AXA" },
        { insNm: "한화손해보험", insCd: "HW" },
        { insNm: "DB손해보험", insCd: "DB" },
        { insNm: "하나손해보험", insCd: "HN" },
        { insNm: "캐롯손해보험", insCd: "CR" }
      ],
      channel: "",
      channelList: [
        { channelNm: "전체", channel: "" },
        { channelNm: "CM", channel: "CM" },
        { channelNm: "TM", channel: "TM" }
      ]
    }
  },
  methods: {
    search() {
      const params = {
        baseYm: this.baseYm,
        joinChannel: this.channel,
        contractInsurer: this.insCd
      };
      this.$emit("search", params);
    }
  }
}
</script>