var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-5 px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"white--text px-4",staticStyle:{"margin-right":"3px"},attrs:{"color":"grey","disabled":!_vm.hasTableData},on:{"click":_vm.toTermSetting}},[_vm._v("기간세팅(보험료기준)")]),_c('v-btn',{staticClass:"white--text px-4",staticStyle:{"margin-right":"3px"},attrs:{"color":"grey","disabled":!_vm.hasTableData},on:{"click":_vm.baseCvrMappingInput}},[_vm._v("기본담보매핑입력")]),_c('v-btn',{staticClass:"white--text px-8",staticStyle:{"margin-right":"3px"},attrs:{"color":"grey","disabled":!_vm.hasTableData},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('div',{attrs:{"id":"example-1"}},[_c('v-data-table',{staticClass:"data_table",attrs:{"height":"45vh","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"headers":_vm.getHeader(_vm.headers),"fixed-header":"","calculate-widths":"","hide-default-footer":""},on:{"click:row":_vm.clickRows,"dblclick:row":_vm.dbClickRows},scopedSlots:_vm._u([{key:"item.prNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 20, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.cvrNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 20, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.baseAccAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getKST(value))+" ")]}}])})],1),_c('ins-cvr-mapping-basic-data-detail',{attrs:{"is-show":_vm.showDetail,"rowData":_vm.rowData},on:{"close":function($event){_vm.showDetail = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }