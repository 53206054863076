<template>
  <v-col cols="12" class="py-0">
    <div class="my-2">
      <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
      <v-btn @click="showUpdateModal" class="blue white--text theme--light float-right marginBtn">업로드</v-btn>
    </div>
    <v-flex  xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="tableData"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.tbInsCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.exptCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.apStDate="{ value }">
          <div>{{ getDateFormat(value) }}</div>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ replaceCreate(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
    <product-recommendation-modal
      :is-show="isShow"
      @close="isShow = false"
      />
    <product-detail-modal
      :is-show="showModal"
      :detailData="detailData"
      :listData="listData"
      @close="showModal = false"
      @updateSetting="updateSetting()"
      />
  </v-col>
</template>

<script>
import {PRODUCT_RECOMMEND_HEADER} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import { axiosInstance } from "@/util/AxiosModule";
import ProductRecommendationModal from "./ProductRecommendationModal";
import ProductDetailModal from "./ProductDetailModal";
export default {
  components: {ProductDetailModal, ProductRecommendationModal},
  mixins: [TableMixin],
  props: ["tableData", "loading"],
  data: () => ({
   headers: PRODUCT_RECOMMEND_HEADER,
   isShow:false,
   showModal: false,
   modalData:[],
   detailData:[],
   listData:[],
   updateData:[]
  }),
  methods: {
    showUpdateModal() {
      this.isShow = true;
    },
    clickContents(row) {
      this.showModal = true;

      const params = {
        insCd: row.insCd,
        prTbId: row.prTbId
      }

      this.getOnly(params);
      this.getListData(params);
    },
    updateSetting() {
      this.getListData(this.updateData);
    },
    async getOnly(params) {
      try {
        const { data } = await axiosInstance.get("/admin/prdata/getPrInsTbMngTb", {params});
        this.detailData = data;
        this.detailData[0].createDate = this.detailData[0].createDate.substr(0, 19);
        if (this.detailData[0].delType === '1') {
          this.detailData[0].delType = "1.전체자료"
        } else if (this.detailData[0].delType === '2') {
          this.detailData[0].delType = "2.보험사코드"
        } else if (this.detailData[0].delType === '3') {
          this.detailData[0].delType = "3.보험사코드 + 상품코드"
        } else if (this.detailData[0].delType === '4') {
          this.detailData[0].delType = "4.보험사코드 + 등록일자"
        } else if (this.detailData[0].delType === '5') {
          this.detailData[0].delType = "5.보험사코드 + 상품코드 + 등록일자"
        } else if (this.detailData[0].delType === '6') {
          this.detailData[0].delType = "6.연계키일부(TK + 보험사코드+상품코드 ) + 등록일자"
        } else if (this.detailData[0].delType === '7') {
          this.detailData[0].delType = "7.등록일자"
        }

      } catch (e) {
        console.log(e);
      }

    },
    async getListData(params) {
      this.updateData = params;
      try {
        const { data } = await axiosInstance.get("/admin/prdata/getPrInsTbCrtIfTbList", {params});
        for (let i = 0; i < data.length; i++) {
          if (data[i].createDate != null) {
            data[i].createDate = data[i].createDate.replace('T', ' ');
            data[i].createDate = data[i].createDate.substr(0, 19);

            if (data[i].stat === null) {
              data[i].stat = "초기";
            } else if (data[i].stat === "1") {
              data[i].stat = "작업진행중";
            } else if (data[i].stat === "2") {
              data[i].stat = "입력 완료";
            } else if (data[i].stat === "3") {
              data[i].stat = "삭제 진행중";
            } else  if (data[i].stat === "4") {
              data[i].stat = "삭제 완료";
            } else if (data[i].stat === "9"){
              data[i].stat = "오류";
            }

          }
        }
        this.listData = data;
      } catch (e) {
        console.log(e)
      }
    },
    getDateFormat(date) {
      let d = date ? date.toString() : '';
      return d ? `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6)}` : '';
    },
    replaceCreate(birth) {
      const b = birth ? birth.toString() : '';
      return b.length >= 8
          ? `${b.slice(0,4)}/${b.slice(4,6)}/${b.slice(6,8)} ${b.slice(8)}`
          : b
    }
  }
}
</script>

<style scoped>
.marginBtn {
  margin-bottom: 5px;
}
</style>