<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-flex>
      <v-card max-width="95vw" max-height="95vh" min-width="95vw" class="overflow-y-auto">
        <v-card-title class="headline">
          <span class="font-weight-bold">상품추천테이블 상세보기</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-col cols="12" class="py-0">
          <v-sheet class="background" style="padding: 0px;">
            <search-area>
              <modal-field-input title="보험사코드">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="detailDto.insCd"
                    :disabled="true"
                />
              </modal-field-input>
              <modal-field-input title="상품추천테이블ID">
                <v-text-field
                    background-color="white"
                    color="black"
                    class="subBar"
                    outlined dense hide-details
                    v-model="detailDto.prTbId"
                    :disabled="true"
                />
              </modal-field-input>
              <modal-field-input title="상품추천테이블명">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="detailDto.prTbNm"
                    :disabled="true"
                />
              </modal-field-input>
              <modal-field-input title="상품추천테이블 설명">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="detailDto.prTbDes"
                    :disabled="true"
                />
              </modal-field-input>
              <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
                <v-col cols="5">
                  <v-row style="padding-left: 12px" @click="showPop">삭제기준 <v-img class="imgPd" max-width="20" max-height="20" src="@/assets/questionMark.png"/></v-row>
                </v-col>
                <v-text-field
                    background-color="white"
                    color="black"
                    class="subBar"
                    outlined dense hide-details
                    v-model="detailDto.delType"
                    :disabled="true"
                />
              </v-col>
              <modal-field-input title="예상건수">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="jc-fe subBar"
                    v-model="detailDto.exptCnt"
                    :disabled="true"
                    dir="rtl"
                />
              </modal-field-input>
              <modal-field-input title="사전작업테이블ID">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="detailDto.prePrTbId"
                    :disabled="true"
                />
              </modal-field-input>
              <modal-field-input title="실시간작업여부">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="detailDto.rtWkYn"
                    :disabled="true"
                />
              </modal-field-input>
              <modal-field-input title="등록일자">
                <v-text-field
                    background-color="white"
                    color="black"
                    class="subBar"
                    outlined dense hide-details
                    v-model="detailDto.createDate"
                    :disabled="true"
                />
              </modal-field-input>
              <modal-field-input title="업로드작업여부">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="detailDto.uploadYn"
                    :disabled="true"
                />
              </modal-field-input>
              <modal-field-input title="사용여부">
                <v-text-field
                    background-color="white"
                    color="black"
                    outlined dense hide-details
                    class="subBar"
                    v-model="detailDto.useYn"
                    :disabled="true"
                />
              </modal-field-input>
            </search-area>
          </v-sheet>
        </v-col>
        <div class="mb-2" style="margin-top: 5px;">
          <span class="text-h6" style="margin-left: 15px;">목록 (총 {{ listData.length }}개)</span>
          <outlined-btn class="float-right" style="margin-right: 15px; color: #1772C7; margin-top: 3px;" @click="getSampleData">입력건 샘플 보기</outlined-btn>
        </div>
        <v-data-table
            class="table_sty"
            :items="listData"
            height="27vh"
            :headers="headers"
            :items-per-page="-1"
            :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
            dense fixed-header calculate-widths disable-sort
        >
          <template v-slot:item.delete="{item}">
            <v-btn x-small class="ml-auto primary mb-1" @click="deleteData(item)">삭제</v-btn>
          </template>
          <template v-slot:item.apStDate="{ value }">
            {{ getDateFormat(value) }}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-4"
            class="white--text col-2"
            @click="close"
            >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
      <product-sample-modal
        :is-show="showModal"
        :tableData="sampleData"
        :tableHead="sampleHead"
        :prTbNm="detailDto.prTbNm"
        @close="showModal = false"/>
    </v-flex>
  </v-overlay>
</template>
<script>
import SearchArea from "../../../../components/Search/SearchArea";
import ModalFieldInput from "../../../../components/Form/ModalFieldInput";
import {axiosInstance} from "@/util/AxiosModule";
import ProductSampleModal from "./ProductSampleModal";
import OutlinedBtn from "../../../Settlement/components/PaymentManage/outlinedBtn";
export default {
  components: {OutlinedBtn, ProductSampleModal, ModalFieldInput, SearchArea},
  props: ["isShow", "detailData", "listData"],
  data:() => ({
    inputClass: 'd-flex flex-column col-md-3 col-sm-6 col-12',
    sampleData:[],
    sampleHead:[],
    showModal:false,
    detailDto: {
      apStDate:"",
      createDate:"",
      delType:"",
      exptCnt:"",
      insCd:"",
      prTbDes:"",
      prTbId:"",
      prTbNm:"",
      prePrTbId:"",
      rtWkYn:"",
      stat:"",
      tbInsCnt:"",
      updateDate:"",
      uploadYn:"",
      useYn:"",
      wkNo:"",
      wkOrder:"",
    },
    headers: [
      {text: "작입일자", value: "createDate", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상태", value: "stat", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "삭제", value: "delete", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "Upload파일명", value: "ulFileNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "테이블입력건수", value: "tbInsCnt", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "적용시작일자", value: "apStDate", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상품코드", value: "prCd", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "작업일련번호", value: "wkNo", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
    ],
  }),
  watch: {
    detailData:function (obj) {
      this.detailDto = obj[0];
      this.detailDto.exptCnt = this.inputComma(this.detailDto.exptCnt);
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async deleteData(item) {
      let dateDt = item.createDate.replaceAll('-', '');
      dateDt = dateDt.substr(0, 8);
        let params = {};
        params.insCd = this.detailDto.insCd;
        params.prTbId = this.detailDto.prTbId;
        params.delType = this.detailDto.delType.charAt(0);
        params.prCd = item.prCd;
        params.bdateDt = dateDt;
        try {
          await axiosInstance.delete('/admin/prdata/deletePrTable', {params});
          this.$emit('updateSetting');
          alert("삭제 완료 !!");
        } catch (e) {
          console.log(e);
        }
    },
    async getSampleData() {
      this.showModal = true;

      let tableNm = this.detailDto.prTbId;

      try {
        const { data } = await axiosInstance.post('/admin/prdata/getTableInfo', tableNm);
        this.sampleHead = data.tableHead;

        var completeColumnArray = new Array();
        for (let j = 0; j < data.tableData.length; j++) {
          completeColumnArray[j] = new Array();
          for(var i=0;i<data.tableHead.length;i++){
            completeColumnArray[j][i] = data.tableData[j]['column' + (i+1)];
          }
        }
        this.sampleData = completeColumnArray;
      }catch (e) {
        console.log(e);
      }
    },
    showPop() {
      alert("삭제기준 도움말\n1.전체자료\n2.보험사코드\n" +
          "3.보험사코드 + 상품코드\n4.보험사코드 + 등록일자" +
          "\n5.보험사코드 + 상품코드 + 등록일자" +
          "\n6.연계키일부(TK + 보험사코드+상품코드 ) + 등록일자\n7.등록일자");
    },
    getDateFormat(date) {
      let d = date ? date.toString() : '';
      return d ? `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6)}` : '';
    },
  }
}
</script>

<style scoped>
.background {
  background-color: rgba(0,0,0,.05);
  border: 1px solid lightgray;
}
.table_sty {
  margin-left: 10px;
  margin-top: 15px;
  margin-right: 10px;
}
.subBar {
  margin-top: 13px;
  margin-left: 5px;
  margin-right: 20px;
}
.imgPd {
  margin-left: 5px;
}
.v-data-table::v-deep th {
  font-size: 15px !important;
}
.v-data-table::v-deep td {
  font-size: 15px !important;
}
</style>