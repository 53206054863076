<template>
  <search-page-layout title="보험사 관리">

    <template v-slot:searchBar>
      <insurer-search-bar
          @search="getInsList"
          @setType="setType"
      />
    </template>

    <template v-slot:table>
      <insurer-table
        :items="data"
        :headers="headers"
        @afterModify="getInsList(type)"
      />
    </template>

  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {CAR_INS_COMPANY_HEADER, OTHER_INS_COMPANY_HEADER} from "@/util/TableHeaders"
import { axiosInstance } from "@/util/AxiosModule"
import InsurerTable from "@/pages/User/components/InsurerSearch/InsurerTable";
import CodeMixin from "@/mixin/CodeMixin";
import InsurerSearchBar from "@/pages/User/components/InsurerSearch/InsurerSearchBar";

export default {
  mixins: [CodeMixin],
  components: {
    InsurerSearchBar,
    InsurerTable,
    SearchPageLayout
  },
  data: () => ({
    headers: CAR_INS_COMPANY_HEADER,
    data: [],
    type: "ALL",
  }),
  methods: {
    async getInsList(type) {
      // type 이 CAR 일때만 Codef 이름을 담는 컬럼이 필요
      this.headers = (type === 'All' || type === 'CAR' || type === 'TWO-WHEELER') ? CAR_INS_COMPANY_HEADER : OTHER_INS_COMPANY_HEADER;

      try{
        const { data } = await axiosInstance.get('/admin/operation/insurer', {
          params: {
            searchType: type
          }
        });

        this.data = data;
      } catch(e) {
        console.log(e);
      }
    },
    setType(newType) {
      this.type = newType;
    }
  }
}
</script>