<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="조회조건">
        <field-input title="조회기간">
          <date-input
              :start-date="startDate"
              :end-date="endDate"
              @updateDate="updateDate"
          />
        </field-input>
        <field-input title="회원명">
          <v-text-field
              v-model="inviterNm"
              @keypress.enter="getIimsList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="고객명">
          <v-text-field
              v-model="customerNm"
              @keypress.enter="getIimsList"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>

    </template>
    <template v-slot:button>
      <search-btn :disabled="isDisabled" @click="getIimsList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [SearchBarMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, FieldInput},
  props: ["name"],
  async created() {
    if(this.name) {
      this.customerNm = this.name;
      await this.getIimsList();
    }
  },
  async beforeMount() {
    // 시작 날짜, 종료 날짜 초기 설정
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const [year, month] = [date.getFullYear(), date.getMonth()];
    this.startDate = `${year}-${this.addZero(month + 1)}-01`;
    this.endDate = `${year}-${this.addZero(month + 1)}-${new Date(year, month + 1, 0).getDate()}`;
  },
  data: () => ({
    startDate: new Date().toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    customerNm: "",
    inviterNm: "",
  }),
  computed: {
    isDisabled() {
      return (
          // 시작일 말고 종료일은 무조건 필수여야함
          // 종료일 없는 경우 1. 시작일만 설정하고 date picker 종료 2. 시작일부터 종료일 까지가 31일 초과일떄
          !this.endDate ||
          // 컬럼 중 하나 이상은 입력되어야함
          (!this.customerNm && !this.inviterNm)
      );
    },
    isEnter() {
      return (
          (!!this.customerNm || !!this.inviterNm)
          && !!this.endDate
      );
    }
  },
  methods: {
    getIimsList() {
      if (!this.isEnter) return;

      const params = {
        "startDate": this.startDate.replaceAll('-', ''),
        "endDate": this.endDate.replaceAll('-', ''),
      };
      if (this.customerNm) params['customerNm'] = this.customerNm;
      if (this.inviterNm) params['inviterNm'] = this.inviterNm;

      this.$emit('search', params);
    },
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>