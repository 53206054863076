<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-flex>
      <v-card max-width="95vw" max-height="95vh" min-width="95vw" class="overflow-y-auto">
        <v-card-title class="headline">
          <span class="font-weight-bold">상품추천테이블 데이터 등록</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-col cols="12" class="py-0">
          <v-sheet class="background" style="padding: 0px;">
            <search-area>
              <field-input title="보험사">
                <v-select
                    @change="getTableId"
                    v-model="insCd"
                    label="선택"
                    :items="insurerCode"
                    background-color="white"
                    outlined dense hide-details
                    item-text="value"
                    item-value="cd"
                    class="subBar"/>
              </field-input>
              <field-input title="상품추천테이블ID">
                <v-select
                    v-model="tableId"
                    :items="tableList"
                    label="선택"
                    item-text="text"
                    item-value="prTbId"
                    background-color="white"
                    outlined dense hide-details
                    class="subBar"/>
              </field-input>
              <field-input title="상품코드">
                <v-text-field
                    v-model="prCd"
                    background-color="white"
                    hide-details outlined dense
                    class="subBar"/>
              </field-input>
            </search-area>

            <search-area>
              <field-input title="기존자료 삭제여부">
                <v-select
                    v-model="removeExistFile"
                    :items="removeExistFileYn"
                    background-color="white"
                    item-value="dvalue"
                    item-text="dname"
                    class="subBar"
                    hide-details outlined dense
                />
              </field-input>
              <field-input title="실시간작업여부">
                <v-select
                    v-model="rtWkYn"
                    :items="realTimeYN"
                    background-color="white"
                    outlined dense hide-details
                    item-value="cvalue"
                    item-text="cname"
                    class="subBar"/>
              </field-input>
              <field-input title="적용일자">
                <v-text-field
                    background-color="white"
                    outlined dense hide-details
                    v-model="apStDate"
                    class="subBar"/>
              </field-input>
              <v-col class="d-flex col-xl-3 col-lg-4 col-md-6 col-12">
                <v-col cols="4">
                  <v-btn
                      color="light-blue" class="white--text mr-3"
                      @click="$refs.file.click()"
                  >
                    파일 업로드
                    <v-icon>mdi-cloud-upload</v-icon>
                  </v-btn>
                  <input @input="loadData" :accept="SheetJSFT" class="d-none" type="file" ref="file"/>
                </v-col>
                <slot />
              </v-col>
            </search-area>
            <search-area>
            </search-area>
          </v-sheet>
          <div class="d-flex justify-center col col-12">
            <v-btn
                color="grey" class="white--text px-10"
                @click="getData"
            >
              Layout조회
            </v-btn>
          </div>
        </v-col>
        <div class="mb-2 mrg" v-if="tableHead.length > 0">
          <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
          <outlined-btn class="float-right" style="margin-bottom: 10px;" @click="toExcel">엑셀다운로드</outlined-btn>
        </div>
        <div class="table-lay" style="width:99%; max-height: 32vh; overflow:auto; margin-top: 8px;">
          <table cellpadding="0" cellspacing="0" id="excel">
            <thead>
            <tr style="padding-left: 10px;">
              <th v-for="item in tableHead" :key="item.columnoid">
                <div class="tableSi">{{item.comment}}</div>
              </th>
            </tr>
            </thead>
            <tbody style="text-align: center; padding-left: 10px;">
            <tr v-for="(item, index) in tableData" :key="index" class="tr-css">
              <td v-for="(val, index) in item" :key="index" class="td-css" style="padding-left: 25px; padding-right: 25px; ">
                &#8203;{{val}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <v-card-actions style="margin-top: 15px;">
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-4"
            class="white--text col-2"
            @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import SearchArea from "../../../../components/Search/SearchArea";
import DateMixin from "@/mixin/DateMixin";
import FieldInput from "../../../../components/Form/FieldInput";
import _ from "lodash";
import XLSX from "xlsx";
import {axiosInstance} from "@/util/AxiosModule";
import OutlinedBtn from "../../../Settlement/components/PaymentManage/outlinedBtn";
export default {
  mixins: [DateMixin],
  components: {OutlinedBtn, FieldInput, SearchArea},
  props: ["isShow"],
  data: () => ({
    insCd: "",
    prCd:"",
    SheetJSFT: ["xlsx", "csv"].map(x => "." + x).join(","),
    tableId: "",
    productCode: "",
    selectFile:[],
    apStDate:"",
    rtWkYn: "Y",
    ulFileNm: "",
    startDate:"",
    productId: [
      {bname: 'PR_PREM_TB', bvalue: 'PR_PREM_TB'}
    ],
    insurerCode: [
    ],
    realTimeYN: [
      {cname: 'Y', cvalue: 'Y'},
      {cname: 'N', cvalue: 'N'}
    ],
    registData: {
      fileType:"",
      fileName:"",
      fileSize:"",
      fileString:null,
      width: 0,
      height: 0
    },
    tableHead:[],
    tableData:[],
    tableList:[],
    tableIdList:[],
    prCdList:[],
    // 기존자료 삭제여부
    removeExistFile: "N",
    removeExistFileYn: [
      {dname: 'Y', dvalue: 'Y'},
      {dname: 'N', dvalue: 'N'}
    ],
  }),
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.apStDate = toDate.replaceAll('-', '');
  },
  mounted() {
    this.selectCd();
    this.tableList = [];
  },
  methods: {
    close() {
      this.insCd = "";
      this.tableId = "";
      this.productCode = "";
      this.tableHead = [];
      this.tableData = [];
      this.$emit('close');
    },
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    async getData() {
      if (_.isEmpty(this.tableId)) {
        alert("상품추천테이블ID를 선택해주세요.");
        return;
      }
      let tableNm = this.tableId
      try {
        const { data } = await axiosInstance.post('/admin/prdata/getTableInfo', tableNm);
        this.tableHead = data.tableHead;

        var completeColumnArray = new Array();
        for (let j = 0; j < data.tableData.length; j++) {
          completeColumnArray[j] = new Array();
          for(var i=0;i<data.tableHead.length;i++){
            completeColumnArray[j][i] = data.tableData[j]['column' + (i+1)];
          }
        }
        this.tableData = completeColumnArray;
      }catch (e) {
        console.log(e);
      }
    },
    clickUpload() {
      this.$refs.file.click();
    },
    // 업로드 파일 불러오기
    loadData({target: {files}}) {
      this.selectFile = files[0];
      this.ulFileNm = files[0].name;

      if (!files || files.length < 1) {
        alert("올바른 파일이 아닙니다.");
        return;
      }

      const jsft = files[0].name.split('.')[files[0].name.split('.').length-1];
      if (jsft == "xlsx") {
        const maxSizeXl = 10 * 1024 * 1024;
        if (files[0].size > maxSizeXl) {
          alert("첨부파일 사이즈는 엑셀 10메가, CSV 30메가 이내로 등록 가능합니다.");
          return;
        }
        this.fileReader(this.selectFile);

      } else if (jsft == "csv") {
        const maxSize = 50 * 1024 * 1024;
        if (files[0].size > maxSize) {
          alert("첨부파일 사이즈는 엑셀 10메가, CSV 50메가 이내로 등록 가능합니다.");
          return;
        }
        this.fileReader(this.selectFile);
      }

    },
    // FileToString
    fileReader(fileData) {
      let reader = new FileReader();
      var self = this;

      reader.onload = async e => {
        this.$emit('loadImage', e.target.result);
        var data = reader.result;
        var base64 = data.replace(/^[^,]*,/, '');
        self.registData.fileString =  base64
        await self.checkUploadFile(self.registData);
      }
      this.registData.fileType = fileData.type.split("/")[1];
      this.registData.fileName = fileData.name.split(".")[0];
      this.registData.fileSize = fileData.size;
      reader.readAsDataURL(fileData);
    },
    // 업로드
    async checkUploadFile(selectFile) {
      if (
          (this.tableId.substr(0, 2) == 'pr' && this.tableId.substr(-7, 7) == 'prem_wk')
          || this.tableId == 'pr_prod_comp_src_tb' || this.tableId === 'pr_refund_tb'
      ) {
        if (_.isEmpty(this.prCd)) {
          alert("상품코드를 입력해주세요.");
          return;
        }
        let codeReg = /^[A-Z0-9]+$/;
        if (!codeReg.test(this.prCd)) {
          alert("상품코드는 대문자, 숫자만 입력해주세요.");
          return;
        }
      } else if (this.tableId == 'pr_cvr_tb') {
        if (!_.isEmpty(this.prCd)) {
          alert("상품코드를 비워주세요");
          return;
        }
      } else {
        alert("보험사 및 상품추천테이블 ID를 확인해주세요.");
        return;
      }
      await this.upload(selectFile);
    },
    async upload(selectFile) {
      const prdataFileDto = {
        insCd: this.insCd,                  // 보험사
        prTbId: this.tableId,               // 상품추천테이블 id
        apStDate: this.apStDate,            // 적용일자
        removeYn : this.removeExistFile,    // 기존자료 삭제여부
        rtWkYn: this.rtWkYn,                // 실시간 작업 여부
        ulFileNm: this.ulFileNm,            // 파일이름
        fileString: selectFile.fileString,  // 파일내용
        prCd: this.prCd                     // 상품코드
      }
      try {
        const { data } = await axiosInstance.post('/admin/prdata/uploadFile', prdataFileDto);
        alert(data)
      } catch (e) {
        console.log(e);
      }
    },
    toExcel() {
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('excel')); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, '상품추천테이블');  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, '상품추천테이블.xlsx'); // 엑셀파일 만듬
    },
    async getTableId(event) {
      this.clearLayout();
      this.tableList = [];
      this.tableId= "";
      const params = {
        insCd: event
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/getPrInsTbMngTb", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prTbId + ' (' + data[i].prTbDes + ')';
          objJson.prTbId = data[i].prTbId;
          objJson.delType = data[i].delType;
          this.tableList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    // layout 초기화
    clearLayout(){
      this.tableHead = [];
      this.tableData = [];
    }
  }
}
</script>

<style scoped>
.subBar {
  margin-top: 13px;
  margin-left: 5px;
  margin-right: 20px;
  max-width: 270px;
}
.background {
  background-color: rgba(0,0,0,.05);
  border: 1px solid lightgray;
}
.table-lay {
  margin-left: 10px;
  margin-right: 10px;
  overflow:auto;
}
.tableSi {
  min-width: 120px;
  height: 33px;
  padding-top: 4px;
  background-color: #F0F8FF;
}
table th {
  border: none;
}
table {
  border-collapse: collapse;
}
.tr-css {
  border-bottom: 0.1px solid gray;
}
.td-css {
  font-size: 17px;
  height: 30px;
}
.mrg {
  margin-right: 1em;
  margin-left: 1em;
}
</style>