<template>
  <v-icon @click="back">
    arrow_back
  </v-icon>
</template>

<script>
export default {
  methods: {
    back() { this.$router.go(-1) }
  }
}
</script>

<style scoped>

</style>