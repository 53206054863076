<template>
  <search-page-layout title="제휴사 관리">
    <template v-slot:searchBar>
      <partner-search-bar @search="searchPartner" />
    </template>

    <template v-slot:table>
      <partner-company-table :items="data"/>
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import PartnerCompanyTable from "@/pages/User/components/PartnerSearch/PartnerCompanyTable";
import {axiosInstance} from "@/util/AxiosModule";
import PartnerSearchBar from "@/pages/User/components/PartnerSearch/PartnerSearchBar";

export default {
  components: {
    PartnerSearchBar,
    PartnerCompanyTable,
    SearchPageLayout,
  },
  data: () => ({
    data: [],
    loading: false,
  }),
  methods: {
    async searchPartner(param) {

      try {
        const { data } = await axiosInstance.get('/admin/operation/business-member', {
          params: {
            searchType: param.type,
            searchValue: param.text
          }
        });

        this.data = data;
      } catch (e) {
        console.log(e);
      }

    }
  }
}
</script>