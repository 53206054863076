<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card max-width="95vw" max-height="180vh" width="38vw" min-height="25vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">보험사별 정산자료내역 팝업</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-row class="shShow">
          <span class="title">기준년월</span>
          <v-text-field
              background-color="white"
              outlined dense
              v-model="standardYm"
              class="subBar"
          />
          <div>
            <v-btn
                color="grey" class="white--text px-10 search"
                @click="saveData"
                :disabled="isDisabled"
            >
              저장
            </v-btn>
          </div>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-1"
              @click="$emit('close')"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import CodeMixin from "@/mixin/CodeMixin";
import TableMixin from "@/mixin/TableMixin";
import DateMixin from "@/mixin/DateMixin";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";

export default{
  mixins: [CodeMixin, TableMixin, DateMixin],
  props: ["isShow"],
  data: () => ({
    standardYm: '',
  }),
  computed: {
    isDisabled() {
      const d = this.standardYm;
      return !(!!d && /[0-9]{6}/.test(d) && this.monthCheck(d));
    }
  },
  methods: {
    // 기준연월 유효성 체크
    monthCheck(value) {
      return value.length === 6
          ? (+value.slice(4) < 13 && +value.slice(4) > 0)
          : false;
    },
    async saveData() {
      try {
        const closeSummary = {
          regId : store.getters['login/memberId'],
          standardYm : this.standardYm
        }
        const {data} = await axiosInstance.post('/admin/settlement/sales_performance', closeSummary);
        alert(data + "건이 업로드 되었습니다.");
        this.$emit('close');
      } catch (e) {
        console.log(e);
        alert("관리자에게 문의해주세요.");
      }
    }
  },
}
</script>

<style scoped>
.shShow {
  margin-right: 20px;
  text-align: center;
}
.title {
  margin-left: 35px;
  margin-right: 25px;
  margin-top: 20px;
  font-weight: bolder;
}
.subBar {
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 40px;
}
.search {
  margin-top: 17px;
}
</style>