<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.createCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
//import {CAR_EXPIR_INFO_JOB_HEADER} from "@/util/TableHeaders";
import {ORGINFO_HEADER} from "@/util/TableHeaders";

export default {

  mixins: [TableMixin],
  props: ["data", "loading"],
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  data: () => ({
    headers: ORGINFO_HEADER
  })
}
</script>