<template>
  <v-flex xs12 sm12 md12 lg12 xl12>
    <div class="d-flex align-end mb-1 mt-2">
      <span class="text-h5 font-weight-bold">목록(총 {{ items.length }}개)</span>
      <span class="text-h6 mx-2">(매칭 보험료합: {{ inputComma(mappSum) }}원 | 미매칭 보험료합: {{ inputComma(noMappSum) }}원)</span>
      <div class="ml-auto pb-1 d-flex align-end">
        <v-btn
            :disabled="isVerified || !isUploaded"
            class="primary darken-1 mr-2"
            @click="verification"
        >자료 검수
        </v-btn>
        <v-btn
            class="red white--text mr-2"
            @click="showDeleteModal"
        >삭제
        </v-btn>
        <v-btn
            :disabled="!isVerified || selectData.length === 0"
            class="primary darken-5"
            @click="complete(selectData)"
        >저장
        </v-btn>
      </div>
    </div>
    <v-data-table
        v-model="selectData" height="40vh" item-key="index"
        :items="items" :loading="loading" :item-class="getRed"
        :headers="getHeader(header)" :options="option" :show-select="isVerified"
        dense fixed-header calculate-widths hide-default-footer
        @dblclick:row="getRowData"
    >

      <template v-slot:item.recipientNm="{ value, item }">
        <reward-target-input
            v-if="item.isRed"
            :value="value" :id="item.index"
            :target="item.settlementTarget"
            :partner-list="partnerList"
            @getColumnData="getColumnData"
        />
        <span v-else>{{ value }}</span>
      </template>

      <template v-slot:item.contractInsurer="{ value, item, header }">
        <select-input
            :value="value" :id="item.index"
            :header="header.value" :items="INS_COMPANY_CD"
            @update="update"
        />
      </template>

      <template v-for="(info, index) in fields" v-slot:[info.slotName]="{ value, header, item }">

        <select-input
            v-if="item.isRed && info.type === 'select'"
            :key="`${index}-${info.name}`"
            :value="value" :id="item.index"
            :items="info.selectItem"
            :header="header.value" @update="update"
        />

        <text-input
            v-else-if="item.isRed && info.type === 'text'"
            :key="`${index}-${info.name}`"
            :value="value" :id="item.index"
            :rules="info.rules" :header="header.value"
            @update="update"
        />

        <span
            v-else :key="`${index}-${info.name}`"
            :class="commaList.includes(header) ? 'jc-fe' : 'cell-center'"
        >{{ commaList.includes(header.value) ? inputComma(value) : value }}</span>

      </template>

      <template v-slot:item.contractDate="{ value }">
        {{ getDateFormat(value) }}
      </template>

      <template v-slot:item.expiryDate="{ value }">
        {{ getDateFormat(value) }}
      </template>

      <template v-slot:item.refundAmt="{ value }">
        <div class="jc-fe">{{ inputComma(value) }}</div>
      </template>

    </v-data-table>

    <ins-realtime-contract-delete-modal
      :is-show="isShowDelete === true"
      @close="isShowDelete = false"
    />
  </v-flex>
</template>

<script>
import TextInput from "@/pages/Settlement/components/TextInput";
import SelectInput from "@/pages/Settlement/components/SelectInput";
import {SettlementTableFields} from "@/util/constant";
import RewardTargetInput from "@/pages/Settlement/components/RewardTargetInput";
import CodeMixin from "@/mixin/CodeMixin";
import _ from "lodash";
import {axiosInstance} from "@/util/AxiosModule";
import DateMixin from "@/mixin/DateMixin";
import InsRealtimeContractDeleteModal
  from "@/pages/Settlement/components/InsRealtimeContract/InsRealtimeContractDeleteModal";
import store from "@/store";

export default {
  mixins: [CodeMixin, DateMixin],
  components: {InsRealtimeContractDeleteModal, RewardTargetInput, TextInput, SelectInput},
  data: () => ({
    loading: false,
    selectData: [],
    partnerList: [],
    fields: SettlementTableFields,
    option: {
      sortBy: ['isRed'],
      sortDesc: [true],
      itemsPerPage: -1,
      multiSort: true,
    },
    commaList: ["contractPremiumAmt", "rewardAmt", "contractPrem", "premiumAmt"], // ###,###,### 처리 해야 하는 컬럼
    INS_COMPANY_CD: [],
    JOIN_CHANNEL: [],
    settlementSrcDtoList: [],
    idx: -1,
    isShowDelete: false,
    mappSum: 0,
    noMappSum: 0
  }),
  props: {
    items: {
      type: Array,
      required: true
    },
    header: {
      type: Array,
      required: true
    },
    isUploaded: {
      type: Boolean,
      required: true
    },
    isVerified: {
      type: Boolean,
      required: true
    },
    settlementDate: String,
    inflowRowItem: {}
  },
  watch: {
    inflowRowItem(newValue) {
      this.getColumnData(this.idx, newValue);
    },
    items(data) {
      if (_.isEmpty(data)) {
        this.mappSum = 0;
        this.noMappSum = 0;
      }
      if (this.isVerified === true) {
        if ((this.mappSum === 0) && (this.noMappSum === 0)) {
          for (let i in data) {
            if (data[i].isRed === true) {
              this.noMappSum += data[i].contractPrem;
            } else if (data[i].isRed === false) {
              this.mappSum += data[i].contractPrem;
            }
          }
        }
      }
    }
  },
  async created() {
    const codes = await this.getDetailCodes('PARTNER');
    this.partnerList = codes.map(code => (code["code"]));
    this.INS_COMPANY_CD = await this.getSelectList('INS_COMPANY_CD');
    this.JOIN_CHANNEL = await this.getSelectList('JOIN_CHANNEL');
  },
  methods: {
    getHeader(headers) {
      return headers.map(header => ({
        text: header[0],
        value: header[1],
        align: this.commaList.includes(header[1]) ? 'right' : 'center',
        class: 'headers text-no-wrap',
        divider: true,
        fixed: true,
      }))
    },
    update(text, id, header) {
      this.$emit('update', text, id, header)
    },
    getRed(item) {
      return item.isRed ? 'isRed' : '';
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDateFormat(date) {
      let d = date ? date.toString() : '';
      return d ? `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6)}` : '';
    },
    async verification() {
      this.loading = true;

      let settlementCarRawDataDtoList = _.cloneDeep(this.items);
      let newTableData = [];

      // table 의 data 들을 그대로 보냄(엑셀 데이터 변환은 settlement-search-bar 에 있음)
      const {data} = await axiosInstance.post('/admin/settlement/rule', settlementCarRawDataDtoList, {
        params: {
          standardYm: this.settlementDate
        }
      });

      for (const item of settlementCarRawDataDtoList) {
        const verifiedRow = this.getVerifiedRow(item, data);
        for (const v of verifiedRow) newTableData.push(v);
      }

      newTableData = [...newTableData, ...await this.addReward(newTableData)];

      this.loading = false;
      this.$emit('verified', newTableData);
    },

    getVerifiedRow(item, data) {
      const verifiedRow = _.filter(data, (o) => (o.index === item.index));
      // 넘어온 데이터에서 같은 index 를 가진 row 가 없다면 데이터 매칭 실패
      if (_.isEmpty(verifiedRow)) {
        return this.getFailVerifiedData(item);
      } else {
        return this.getSuccessVerifiedData(verifiedRow, +verifiedRow[0]['index'], item['rewardType'] === '정정리워드');
      }
    },

    getFailVerifiedData(item) {
      // 매칭 실패한 데이터는 isRed 값을 true 로 바꿔줌(붉은색 로우 설정)
      // 그리고 정산 후 테이블 헤더가 바뀌므로 해당 값에 맞는 변수명으로 변경
      item.isRed = true;
      item['contractPrem'] = item['premiumAmt'];
      item['contractInsurer'] = item['insurerCd'];
      // getSuccessVerifiedData 의 데이터가 배열로 넘어와 해당 메소드 return 값도 배열로 넘겨줌
      return [item];
    },

    getSuccessVerifiedData(verifiedRow, index, isRed) {
      // 매칭 성공한 데이터는 청신호일 경우 같은 index 가 2개로 넘어올 수 있음
      for (const item of verifiedRow) {
        // 만약 정정리워드가 넘어왔다면 isRed 를 true 로 설정해야함.
        item['isRed'] = isRed;
        // 만약 데이터가 두개라면 0번째 인덱스의 다음 인덱스로 들어가게함
        item['index'] = index++;
        // 정정리워드일 경우엔 rewardType 과 rewardAmt 를 별개로 설정해줘야함
        if (isRed) {
          item['rewardType'] = '정정리워드';
          item['rewardAmt'] = '';
        }
      }

      return verifiedRow;
    },

    async getColumnData(id, data) {
      // 현재 테이블에 들어간 데이터 할당
      let tableData = _.cloneDeep(this.items);
      // 넘어온 id 값과 일치하는 로우를 찾음
      let item = _.find(tableData, {index: id});
      item.customer = {
        "nm":data.customerNm
        , "telNum":data.customerTelNum
      };
      item.customerCarNo = data.customerCarNo;

      if(data.memberId == null)
      {
        data.memberId = data.inviterId;
        data.memberNm = data.inviterNm;
      }

      if(item.settlementTarget === 'ONECLICK')
      {
        data.partnerCd = this.partnerList.includes(data.memberNm) ? data.memberNm : 'INCAR';
      } else {
        data.partnerCd = "";
      }

      // 비어있으면 잘못된 데이터로 에러 띄워주고 종료
      if (_.isEmpty(item)) {
        alert('잘못된 데이터 입니다.');
        return;
      }

      // joinHistId값 전달
      if (item.joinHistId === "") {
        item.joinHistId = data.joinHistId;
      }

      // 이륜차, 법인계약 값 전달
      item.twoWheelerYn = data.twoWheelerYn;
      item.corpContYn = data.corpContYn;

      // 있다면 해당 item 정보와 넘어온 data 를 조합해 post
      const res = await axiosInstance.post('/admin/settlement/search', {
        'index': item.index,
        'insurerCode': item.insurerCd,
        'joinChannel': item.joinChannel,
        'memberId': data.memberId,
        'memberNm': data.memberNm,
        'partnerCd': data.partnerCd,
        'premiumAmt': item.premiumAmt
      });
      // res.data 가 비어있을 경우 찾는 데이터가 존재하지 않은 경우
      if (_.isEmpty(res.data)) {
        alert('찾는 데이터가 없습니다!');
        return;
      }

      // res.data 에서 넘어온 데이터의 index 와 같은 index 를 가지는 테이블 내의 데이터 로우 삭제를
      // 서버에서 넘어온 데이터로 갈아치우기 위함
      _.remove(tableData, {index: res.data[0].index});

      // 테이블 병합 시 문제가 되어 기존 셋팅된 정보가 있으면 삭제함.
      delete item.recipientId;
      delete item.recipientNm;

      // res.data 가 1보다 큰 경우
      // 친구 초대 리워드가 있는 경우 해당됨
      if (res.data.length > 1) {
        // 기존 데이터에 두개의 데이터(보험 가입 리워드, 친구 초대 리워드) 를 넣어줌
        tableData.push(this.mixColumnData(res.data[0], item));
        // 친구 초대 리워드의 index 는 보험 가입 리워드의 index 와 같기에 +1 해서 넣어줌
        tableData.push({...this.mixColumnData(res.data[1], item), index: +res.data[1].index + 1});

        // 추가 후 친구 초대 리워드에 대한 추가 리워드 계산을 위해 addReward 메서드 실행
        const addReward = await this.addReward(tableData);
        // 해당
        tableData = [...tableData, ...addReward];
      } else {
        // 보험 가입 리워드만 있고 추천인이 없는 경우, 광고비 일 때가 해당 됨
        tableData = [...tableData, this.mixColumnData(res.data[0], item)]
      }

      // 라인이 바뀌는걸 방지하기 위해 index로 데이터 정렬
      tableData.sort(function(a,b) {
        return parseFloat(a.index) - parseFloat(b.index);
      });

      // 매칭|미매칭 보험료합 계산
      for (let i in tableData) {
        if (tableData[i].joinHistId === data.joinHistId) {
          this.mappSum += tableData[i].contractPrem;
          this.noMappSum -= tableData[i].contractPrem;
        }
      }

      this.$emit('changeTable', tableData);
    },

    // 추가 리워드 추가 메서드
    async complete(data) {
      if (!confirm(`${data.length}개의 데이터를 저장할까요?`)) return;
      let validation = false;
      for (const item of data) {
        if (!_.isEmpty(item["customer.nm"])) {
          item["customer"].nm = item["customer.nm"].trim("");
        }
        if (!_.isEmpty(item["customer.telNum"])) {
          item["customer.telNum"] = item["customer.telNum"].trim("");
        }
        if (!_.isEmpty(item["customerCarNo"])) {
          item["customerCarNo"] = item["customerCarNo"].trim("");
        }

        if (!_.isEmpty(item["customer.telNum"])){
          item["customer"].telNum = item["customer.telNum"].replace(/[^0-9]/gi, "");
        }
        if (_.isEmpty(item["settlementTarget"])) {
          alert("정산타겟을 입력해주세요.")
          this.selectData = [];
          break;
        } else if (_.isEmpty(item["recipientId"])){
          alert("추천인을 입력해주세요.")
          this.selectData = [];
          break;
        } else if (_.isEmpty(item["rewardType"])) {
          alert("리워드 타입을 입력해주세요.")
          this.selectData = [];
          break;
        } else {
          validation = true;
        }
        if (_.isEmpty(item["customer"])) {
          item["customer"] = {
            nm: item["customer.nm"],
            telNum: item["customer.telNum"] ? item["customer.telNum"].replace(/[^0-9]/gi, "") : ""
          }
        }
      }
      if(!validation)  {
        return;
      }
      try {
        await axiosInstance.post('/admin/settlement', data, {
          params: {
            payDueDate: this.getPayDueDate(this.settlementDate),
            settlementDate: this.settlementDate,
            workType: "P",
            regId: store.getters["login/memberId"]
          }
        });

        this.$emit('deleteData', data.map(x => x.index));
        this.selectData = [];
        alert("저장되었습니다!");
      } catch (e) {
        console.log(e);
        alert("관리자에게 문의해주세요.");
      }
    },

    // 지급 예정 처리
    async addReward(dataList) {
      // 친구초대 리워드만 분류
      const findReward = _.filter(dataList, {rewardType: '친구초대리워드'});

      // 하나도 없을 경우 빈 배열 리턴
      if (findReward.length < 1) return [];

      // 친구초대리워드에서 멤버아이디, 멤버이름만 따로 객체 배열로 만듦
      const postData = findReward.map(item => ({
        memberId: item['recipientId'],
        memberNm: item['recipientNm']
      }));

      const {data} = await axiosInstance.post('/admin/settlement/add-reward', postData);
      // 결과값이 없을 경우 빈 배열 리턴
      if (_.isEmpty(data)) return [];

      // 기존에 등록된 추가 리워드 삭제
      await _.remove(this.tableData, {rewardType: '추가리워드'});

      // 테이블 데이터의 index 들은 2단위로 설정되어있음
      // 새 index 시작 기준
      let length = dataList.length * 2;

      // 날짜
      for (const addReward of data) {
        addReward['index'] = length++;
        // 추가 리워드는 청신호만 있음
        addReward['settlementTarget'] = '청신호';
      }

      return data;
    },

    // 지급예정일 계산
    // 주말 포함 해당월 마지막 일자 계산
    // 현재 수신실적 자료 등록 페이지 비활성화로 인해 사용 X
    getPayDueDate(settlementDate) {
      const tyear = +settlementDate.substr(0, 4);
      let month = +settlementDate.substr(4) === 12 ? 1 : +settlementDate.substr(4) + 1;
      const year = month === 1 ? tyear + 1 : tyear;
      const date = new Date(year, month, 0).getDate();
      return `${year}${this.addZero(month)}${date}`
    },

    mixColumnData(newData, oldData) {
      const mix = {...newData, ...oldData};
      mix["rewardType"] = newData["rewardType"];
      return mix;
    },
    getRowData(event, { item }){
      this.idx = item.index;
    },
    showDeleteModal() {
      this.isShowDelete = true;
    }
  },
}
</script>

<style>
.isRed, .isRed:hover {
  background-color: rgba(200, 0, 0, .5) !important;
}
</style>
