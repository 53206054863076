const getDefaultState = () => {
    return {
        searchType: "ALL",
        cdMasterId: "",
        type: "",
        cd: "",
    };
};

// initial state
const state = getDefaultState();

const getters = {
    searchType: state => state.searchType,
    cdMasterId: state => state.cdMasterId,
    type: state => state.type,
    cd: state => state.cd,
};

const actions = {
    setCdMasterId({commit}, { cdMasterId, type }) {
        commit('setCdMasterId', { cdMasterId, type });
    },
    setCd({commit}, cd) {
        commit('setCd', cd);
    },
    setSearchType({ commit }, condition) {
        commit("setSearchType", condition);
    },
    initData({ commit }) {
        commit("initData")
    }
};

// mutations
const mutations = {
    setSearchType(state, condition) {
        state.searchType = condition;
    },
    setCdMasterId(state, { cdMasterId, type }) {
        state.cdMasterId = cdMasterId;
        state.type = type;
    },
    setCd(state, cd) {
        state.cd = cd;
    },
    initData(state) {
        state.searchData = [];
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};