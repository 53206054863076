<template>
  <v-col cols="12" class="py-0">
    <v-btn
        color="grey"
        class="white--text px-8"
        style="margin-left: 4px;"
        @click="toExcel"
        :disabled="data.length <= 0"
    >엑셀다운로드</v-btn>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.cvrNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...")}}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {INS_CVR_STATUS_TABLE} from "@/util/TableHeaders";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin],
  data() {
    return {
      headers: INS_CVR_STATUS_TABLE,
    }
  },
  methods: {
    clickContents(row) {
      this.$emit("row", row);
      this.$emit("close");
    },
    toExcel() {

    }
  }
}
</script>