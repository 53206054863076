<template>
  <search-page-layout title="회원가입 요청현황">
    <template v-slot:searchBar>
      <sign-up-request-search-bar
          ref="search"
          @search="getSignUpRequestList"
      />
    </template>

    <template v-slot:table>
      <sign-up-request-table
          :key="tablekey"
          :data="data"
          :loading="loading"
          @refreshList="refreshList"
      />
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import SignUpRequestSearchBar from "@/pages/User/components/SignUpRequest/SignUpRequestSearchBar";
import SignUpRequestTable from "@/pages/User/components/SignUpRequest/SignUpRequestTable";

export default {
  name: "SignUpRequest",
  components: {
    SignUpRequestSearchBar,
    SignUpRequestTable,
    SearchPageLayout
  },
  data: () => ({
    data: [],
    loading: false,
    tablekey: 0
  }),

  methods: {
    // 회원가입 요청자 목록 조회
    async getSignUpRequestList(param) {
      this.loading = true;
      const params = {
        startDate: param.startYmd,
        endDate: param.endYmd,
        blackListYn: param.blackListYn,
        approvalYn: param.approvalYn
      };
      try {
        const {data} = await axiosInstance.get('/admin/registRequest', {params});
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].index = i;
        }
      }
      catch (e) {
        console.log(e);
      }
      this.tablekey++;
      this.loading = false;
    },
    refreshList(){
      this.$refs.search.getCalcStat();
    }

  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/noto";
</style>