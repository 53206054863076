<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-row>
          <v-col class="align-center d-flex" cols="4">
            <v-col cols="3">등록일자</v-col>
            <v-col>
              <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
              />
            </v-col>
          </v-col>

          <v-col class="align-center d-flex" cols="4">
            <v-col cols="3">상품군</v-col>
            <v-col>
              <v-select
                v-model="mpPrProdLineCd"
                :items="mpPrProdLineList"
                item-text="mpPrProdLineNm"
                item-value="mpPrProdLineCd"
                hide-details outlined dense
                background-color="white"
              />
            </v-col>
          </v-col>

          <v-col class="align-center d-flex" cols="4">
            <v-col cols="3">보험사</v-col>
            <v-col>
              <v-select
                v-model="insCd"
                :items="insList"
                item-text="insNm"
                item-value="insCd"
                hide-details outlined dense
                background-color="white"
                @change="getPrCdList(insCd)"
              />
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="align-center d-flex" cols="4">
            <v-col cols="3">상품종류</v-col>
            <v-col>
              <v-select
                  v-model="prProdLineCd"
                  :items="prProdLineList"
                  item-text="prProdLineNm"
                  item-value="prProdLineCd"
                  hide-details outlined dense
                  background-color="white"
              />
            </v-col>
          </v-col>

          <v-col class="align-center d-flex" cols="4">
            <v-col cols="3">상품명</v-col>
            <v-col>
              <v-text-field
                  v-model="prNm"
                  hide-details outlined dense
                  background-color="white"
              />
            </v-col>
          </v-col>

          <v-col class="align-center d-flex" cols="3">
            <v-col cols="4">사용여부</v-col>
            <v-col>
              <v-select
                  v-model="useYn"
                  :items="useYnList"
                  item-text="useYnNm"
                  item-value="useYn"
                  hide-details outlined dense
                  background-color="white"
              />
            </v-col>
          </v-col>

          <v-col class="align-center text-right" cols="1">
            <v-col>
              <v-btn @click="search">조회</v-btn>
            </v-col>
          </v-col>
        </v-row>
      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import InsAiChatManagement from "@/util/InsAiChatManagement";

export default {
  components: {DateInput, SearchBarLayout, SearchArea},
  mixins: [DateMixin],
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-62);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  created() {
    this.mpPrProdLineList = InsAiChatManagement.initMpPrProdLineList("전체");
    this.insList = InsAiChatManagement.initInsList("전체");
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      mpPrProdLineCd: "",
      mpPrProdLineList: [],
      insCd: "",
      insList: [],
      prProdLineCd: "",
      prProdLineList: [
        { prProdLineNm: "전체", prProdLineCd: "" }
      ],
      prNm: "",
      useYn: "Y",
      useYnList: [
        { useYnNm: "Y", useYn: "Y" },
        { useYnNm: "N", useYn: "N" }
      ],
      isShowRegist: false
    }
  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    async getPrCdList(insCd) {
      this.prProdLineList = [
        { prProdLineNm: "선택", prProdLineCd: "" }
      ];
      if (insCd === "") {
        return false;
      } else {
        this.prProdLineList = await InsAiChatManagement.prProdLineList("L", insCd, "", "");
      }
    },
    search() {
      const params = {
        frDt: this.startDate.replaceAll('-', ''),
        toDt: this.endDate.replaceAll('-', ''),
        mpPrProdLineCd: this.mpPrProdLineCd,
        insCd: this.insCd,
        prProdLineCd: this.prProdLineCd,
        prNm: this.prNm,
        useYn: this.useYn
      }
      this.$emit("search", params);
    }
  }
}
</script>