<template>
  <v-overlay
      v-model="show"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="80vw" max-height="95vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">공지/이벤트 사항</span>
        </v-card-title>
        <v-card-text>
          <v-row class="px-6 mb-2">
            <span class="text-h6 black--text font-weight-bold">기본정보</span>
          </v-row>
          <v-row class="px-6">
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">등록번호</span>
              <span class="subtitle-1"><b> {{ boardData.boardNo }} </b></span>
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">게시판유형</span>
              <v-select
                  v-model="boardData.boardType"
                  :items="boardType"
                  outlined dense
                  background-color="white"
                  item-text="bname"
                  item-value="bvalue"
              />
            </v-col>
            <v-col class="d-flex flex-column col-1">
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">회원가입경과</span>
              <v-select
                  v-model="boardData.elapsedStatus"
                  :items="elapsedStatus"
                  outlined dense
                  background-color="white"
                  item-text="ename"
                  item-value="evalue"
              />
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">사용자범위</span>
              <v-select
                  v-model="boardData.userRanges"
                  :items="userRanges"
                  background-color="white"
                  outlined dense
                  item-text="uname"
                  item-value="uvalue"
              />
            </v-col>
            <v-col class="d-flex flex-column col-1">
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">조회수 </span>
              <div class="cell">{{ boardData.clickCnt }}</div>
            </v-col>
          </v-row>
          <v-row class="px-6">
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">공지시작일자</span>
              <input
                  class="box-detail"
                  type="date"
                  v-model="boardData.noticeFromDt"
              >
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">시작시분</span>
              <input
                  class="box-detail"
                  type="time"
                  v-model="boardData.noticeFromHhmm"
              >
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">공지종료일자</span>
              <input
                  class="box-detail"
                  type="date"
                  v-model="boardData.noticeToDt"
              >
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">종료시분</span>
              <input
                  class="box-detail"
                  type="time"
                  v-model="boardData.noticeToHhmm"
              >
            </v-col>
            <v-col class="d-flex flex-column col-4">
              <span class="end-title">최종등록일시: {{ boardData.updateDate }}</span>
            </v-col>
          </v-row>
          <v-row class="px-6 mb-2">
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">게시판 표현 유형</span>
              <v-select
                  v-model="boardData.dispType"
                  :items="dispTypes"
                  item-text="dname"
                  item-value="dvalue"
                  outlined dense
                  background-color="white"
              />
            </v-col>
            <v-col class="d-flex flex-column col-2">
              <span class="subtitle-1">종료기간이후표시여부 </span>
              <v-select
                  v-model="boardData.displayContYn"
                  :items="displayYn"
                  item-text="cname"
                  item-value="cvalue"
                  outlined dense
                  background-color="white"
              />
            </v-col>
          </v-row>
          <v-row class="px-6 mb-2">
            <v-col class="d-flex flex-column col-12">
              <span class="subtitle-1">제목</span>
              <v-text-field
                  v-model="boardData.title"
                  background-color="white"
                  outlined dense
              />
            </v-col>
          </v-row>
          <v-row class="px-6 mb-2">
            <v-col class="d-flex flex-column col-12">
              <v-textarea
                  v-model="boardData.contents"
                  background-color="white"
                  outlined dense
                  rows="12"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="upsubmit"
          > 수정
          </v-btn>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="delsubmit"
          > 삭제
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="show=false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import StringMixin from "@/mixin/StringMixin";
import store from "@/store";
import _ from "lodash";
// import DateInput from "@/components/Form/DateInput";

export default {
  mixins: [CodeMixin, StringMixin],
  // components: {DateInput},
  props: ["dialog", "isModify", "boardRow", "sparams", "displayContYn"],
  beforeMount() {
    this.boardData.noticeFromDt = this.boardRow.noticeFromDt;
    this.boardData.noticeToDt = this.boardRow.noticeToDt;
  },
  data: () => ({
    inputClass: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12',
    regNo: "등록번호",
    viewdialog: false,
    boardType: [
      {bname: '일반', bvalue: 'GE'},
      {bname: '이벤트', bvalue: 'EV'},
      {bname: '이벤트퀴즈', bvalue: 'EQ'},
      {bname: 'FAQ', bvalue: 'QA'}
    ],
    userRanges: [
      {uname: '전체', uvalue: 'AL'},
      {uname: '원클릭', uvalue: 'ON'},
      {uname: '청신호', uvalue: 'GR'},
      {uname: '인카', uvalue: 'IC'},
      {uname: '타FA', uvalue: 'OF'},
      {uname: '제휴회사', uvalue: 'AC'}
    ],
    elapsedStatus: [
      {ename: '전체', evalue: 'AL'},
      {ename: '가입1주이내', evalue: 'W1'},
      {ename: '가입1달이내', evalue: 'M1'},
      {ename: '가입1년이내', evalue: 'Y1'},
      {ename: '가입1년이상', evalue: 'YF'}
    ],
    dispTypes: [
      {dname: '리스트조회', dvalue: 'LIST'},
      {dname: '롤링조회', dvalue: 'ROLLING'},
      {dname: '팝업조회', dvalue: 'POPUP'}
    ],

    displayYn: [
      {cname: 'Y', cvalue: 'Y'},
      {cname: 'N', cvalue: 'N'}
    ],

    searchType1: "AL",
    searchType2: "AL",
    searchType3: "AL",
    srchTxt: "",
    dispType:"LIST",
    registTag: "",
    searchFileStartDate: "",
    searchFileEndDate: "",

    boardData: {
      "boardNo": 0,
      "boardType": "GE",
      "title": "",
      "userRanges": "AL",
      "elapsedStatus": "AL",
      "noticeFromDt": "",
      "noticeFromHhmm": "",
      "dispType":"LIST",
      "noticeToDt": "",
      "noticeToHhmm": "",
      "clickCnt": 0,
      "contents": "",
      "createDate": "",
      "updateDate": "",
      "regId": "",
      "regNm": "",
      "displayContYn":""
    },
    initData: {
      "boardNo": 0,
      "boardType": "GE",
      "title": "",
      "userRanges": "AL",
      "elapsedStatus": "AL",
      "noticeFromDt": "",
      "noticeFromHhmm": "",
      "dispType":"LIST",
      "noticeToDt": "",
      "noticeToHhmm": "",
      "clickCnt": 0,
      "contents": "",
      "createDate": "",
      "updateDate": "",
      "regId": "",
      "regNm": "",
      "displayContYn":"",
    },
    startDate: "",
    endDate: ""
  }),
  computed: {

    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.$emit("close", value)
      },
    },

  },
  watch: {
    boardRow: function (obj) {
      if (_.isEmpty(obj)) {
        this.boardData = _.cloneDeep(this.initData);
        this.boardData.displayContYn = "N";
      } else {
        this.boardData = obj;
        this.boardData.displayContYn = this.displayContYn;
      }
    }
  },
  methods: {
    updateDate(values) {
      this.boardData.noticeFromDt = values[0];
      this.boardData.noticeToDt = values[1];
    },
    async upsubmit() {
      confirm(`자료를 수정하시겠습니까?`) && await this.updateContents();
    },
    async updateContents() {
      try {
        let params = {};
        params.boardNo = this.boardData.boardNo;
        params.boardType = this.boardData.boardType;
        params.userRanges = this.boardData.userRanges;
        params.elapsedStatus = this.boardData.elapsedStatus;
        params.noticeFromDt = this.boardData.noticeFromDt.replace(/[^0-9]/g, "");
        params.noticeFromHhmm = this.boardData.noticeFromHhmm.replace(/[^0-9]/g, "");
        params.dispType = this.boardData.dispType;
        params.noticeToDt = this.boardData.noticeToDt.replace(/[^0-9]/g, "");
        params.noticeToHhmm = this.boardData.noticeToHhmm.replace(/[^0-9]/g, "");
        params.title = this.boardData.title;
        params.displayContYn = this.boardData.displayContYn;
        params.contents = this.boardData.contents;
        params.regId = store.getters['login/memberId'];
        try {
          await axiosInstance.put('/admin/board/Board', params);
        } catch (e) {
          console.log(e);
        }

        this.$emit('updateSetting');
        alert("수정 완료 !!");
        this.$emit("close", false)
      } catch (e) {
        console.log(e);
      }
    },

    async delsubmit() {
      confirm(`자료를 삭제하시겠습니까?`) && await this.deleteContents();
    },
    async deleteContents() {
      try {
        let params = {};
        params.boardNo = this.boardData.boardNo;
        try {
          await axiosInstance.delete('/admin/board/Board', {params});
        } catch (e) {
          console.log(e);
        }
        this.$emit('updateSetting');
        alert("삭제 완료 !!");
        this.$emit("close", false)
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
    },
    getDate(date) {

      let res = "";
      if (date.length < 3) {
        res = date;
      } else {
        if (date.substr(0,2) === "02") {
          // 02-123-5678
          if (date.length <= 5) {
            res = date.substr(0,2) + "-" + date.substr(2,3);
            // 02-123-5678
          } else if (date.length > 5 && date.length <= 9) {
            res = date.substr(0,2) + "-" + date.substr(2,3) + "-" + date.substr(5);
            // 02-1234-5678
          } else if (date.length > 9) {
            res = date.substr(0,2) + "-" + date.substr(2,4) + "-" + date.substr(6);
          }
        } else {
          if (date.length < 8) {
            res = date;
          } else if (date.length === 8) {
            res = date.substr(0,4) + "-" + date.substr(4);
          } else if (date.length === 9) {
            res = date.substr(0,3) + "-" + date.substr(3,3) + "-" + date.substr(6);
          } else if (date.length === 10) {
            res = date.substr(0,3) + "-" + date.substr(3,3) + "-" + date.substr(6);
          } else if (date.length > 10) {
            res = date.substr(0,3) + "-" + date.substr(3,4) + "-" + date.substr(7);
          }
        }
      }
      return res;
    }
  }
}
</script>

<style scoped>
div .box-detail {
  background-color: white;
  border: 1px solid #72819A;
  border-radius: 5px;
  font-size: 15px;
  padding: 7px;
}

div .end-title {
  font-size: 16px;
  margin-top: 35px;
}
</style>
