<template>
  <v-menu
    v-model="isShow"
    transition="scale-transition"
    :close-on-content-click="false"
    offset-y min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @click:append="isShow = !isShow"
        v-on="on" v-bind="attrs"
        label="예정일"
        v-model="nowDate"
        hint="YYYY/MM/DD format"
        readonly outlined hide-details background-color="white"
        append-icon="mdi-calender-blank"
      />
    </template>
    <v-date-picker
      v-model="nowDate"
      :show-current="false"
      :day-format="removeBackWord"
      @input="inputDate"
      locale="ko-kr"
    />
  </v-menu>
</template>

<script>
export default {
  name: "SettlementEarlyDatePicker",
  props: ["date"],
  created() {
    this.nowDate = this.date;
  },
  data() {
    return {
      isShow: false,
      nowDate: ""
    }
  },
  methods: {
    removeBackWord(d) {
      return new Date(d).getDate();
    },
    inputDate() {
      this.isShow = false;
      this.$emit("updateDate", this.nowDate);
    }
  }
}
</script>