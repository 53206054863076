<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          item-key="index"
          :loading="loading"
          @click:row="selectRow"
          :items-per-page="-1"
          fixed-header calculate-widths disable-sort
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.progMnGroup="{ value }">
          {{ progMenuGroup(value) }}
        </template>
        <template v-slot:item.createDate="{ value }">
          {{ dateFormat(value) }}
        </template>
        <template v-slot:item.updateDate="{ value }">
          {{ dateFormat(value) }}
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {PROGRAM_PRIVILEGE_TABLE} from "@/util/TableHeaders";
import constant from "@/util/constant";
import func from "@/util/func";
import _ from "lodash";

export default {
  name: "ProgramPrivilegeTable",
  mixins: [TableMixin],
  props: ["data", "loading", "prvList"],
  data: () => ({
    headers: [],
    progMnGroupList: constant.PROGRAM_MENU_GROUP_LIST
  }),

  created() {
    this.headers = _.cloneDeep(PROGRAM_PRIVILEGE_TABLE);  // 헤더 중첩 문제 개선
    this.prvList.forEach(item => {
      this.headers.push([item.value, item.cd])
    });
  },

  methods: {
    selectRow(row) {
      this.$emit('selectRow', row);
    },
    progMenuGroup(value) {
      return this.progMnGroupList.find(el => el.cd == value).name;
    },
    dateFormat(data) {
      return func.contentsDataFormatter2(data);
    }
  }
}
</script>
