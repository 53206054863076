<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <outlined-btn class="float-right" @click="$emit('toExcel')" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          show-select multi-sort="multi-sort"
          @click:row="getChartDetailDialog"
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.tFaCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.faCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.prem="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.salesCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.settleCnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.reward="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.pyReward="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
      </v-data-table>

      <sales-list-dialog
          :dialog="dialog"
          @close="dialog = false"
          @updateSetting="setDialog"
          :monthArray="monthArray"
          :resData="resData"
          :orgName="orgName"
          :toYm="toYm"
          :fromYm="fromYm"
      />
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {SALES_LIST_HEADER} from "@/util/TableHeaders";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import SalesListDialog from "./SalesListDialog";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {SalesListDialog, OutlinedBtn},
  mixins: [TableMixin],
  props: ["data", "loading", "toYm"],
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  data: () => ({
    resData: [],
    headers: SALES_LIST_HEADER,
    dialog: false,
    monthArray: [],   // 차트 x축 (labels)
    orgGb: "",    // FA명 (조직명)
    orgCd: "",    // FA의 코드 (조직의 코드)
    orgName: "",   // 최하위 조직이름(소속)
    fromYm: ""
  }),
  methods: {
    // row 클릭 시
    getChartDetailDialog(row) {
      for (let i in this.data) {
        if (this.data[i] === row) {

          this.getOrgGb(this.data[i]);  // orgGb, orgCd

          this.getSalesChartData(this.orgGb, this.orgCd);
        }
      }
    },
    // orgGb
    getOrgGb(row) {
      if (row.bmcOrgNm === "합계") {
        this.orgCd = "ALL";
        this.orgGb = "10";
        this.orgName = "합계";
      } else if (row.orgCd !== null) {
        this.orgCd = row.orgCd;
        this.orgGb = "70";
        this.orgName = row.orgNm;
      } else if (row.deptCd !== null) {
        this.orgCd = row.deptCd;
        this.orgGb = "60";
        this.orgName = row.deptNm;
      } else if (row.partnerCd !== null) {
        this.orgCd = row.partnerCd;
        this.orgGb = "50";
        this.orgName = row.partnerNm;
      } else if (row.belongCd !== null) {
        this.orgCd = row.belongCd;
        this.orgGb = "40";
        this.orgName = row.belongNm;
      } else if (row.cgOrgCd !== null) {
        this.orgCd = row.cgOrgCd;
        this.orgGb = "30";
        this.orgName = row.cgOrgNm;
      } else if (row.bmOrgCd !== null) {
        this.orgCd = row.bmOrgCd;
        this.orgGb = "20";
        this.orgName = row.bmOrgNm;
      } else {
        this.orgCd = row.bmcOrgCd;
        this.orgGb = "10";
        this.orgName = row.bmcOrgNm;
      }
    },
    // Data 요청
    async getSalesChartData(orgGb, orgCd) {
      this.$emit("updateLoading", true);

      const params = {
        fromYm: String(Number(this.toYm) - 100), // 1년 전
        orgCd: orgCd,
        orgGb: orgGb,
        toYm: this.toYm,
      }
      this.fromYm = params.fromYm;

      try {
        const {data} = await axiosInstance.get('/admin/sales/salesmonthly', {params});
        this.resData = data;

        // 차트 하단 라벨
        for (let i in this.resData) {
          this.monthArray[i] = this.resData[i].yyyymm;
        }
      } catch (e) {
        console.log(e);
      }

      this.$emit("updateLoading", false);
      this.dialog = true;
    },
    setDialog(value) {
      this.dialog = value;
      if (!this.dialog) {
        this.initChartData();
      }
    },
    initChartData() {
      this.resData = [];
      this.orgGb = "";
      this.orgCd = "";
    }
  }
}
</script>