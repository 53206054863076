<script>
import { Bar, Line } from "vue-chartjs";

export default {
  props: [ "monthArray", "resData" ],
  extends: Bar,Line,
  data(){
    return{
      datasets : [
        {
          label: "전체 FA수",
          type: "bar",
          data: [],
          backgroundColor: "rgba(96,198,255,0.8)",
          hoverBackgroundColor: "rgb(96,198,255)",
          borderWidth: 3
        },
        {
          label: "실적발생 FA수",
          type: "bar",
          data: [],
          backgroundColor: "rgba(119,119,119,0.8)",
          hoverBackgroundColor: "rgb(119,119,119)",
          borderWidth: 3
        },
        {
          label: "발생자 비율",
          type: "line",
          data: [],
          borderColor: "rgb(3,80,255)",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3
        },
      ],
    }
  },

  mounted() {
    for (let i in this.resData) {
      this.datasets[0].data[i] = this.resData[i].ftCnt;    // 전체 FA수
      this.datasets[1].data[i] = this.resData[i].fsCnt;  // 실적발생 FA수
      this.datasets[2].data[i] = this.resData[i].pper;  // 발생자 비율
    }
    this.renderChart(
        {
          labels: this.monthArray,
          datasets: this.datasets
        },
        { responsive: true, maintainAspectRatio: false, beginAtZero: true }
    );
  },
  watch: {
    resData: function () {
      for (let i in this.resData) {
        this.datasets[0].data[i] = this.resData[i].ftCnt;    // 전체 FA수
        this.datasets[1].data[i] = this.resData[i].fsCnt;  // 실적발생 FA수
        this.datasets[2].data[i] = this.resData[i].pper;  // 발생자 비율
      }
      this.$data._chart.update();
    },
    data: function () {
      this.renderChart({
            labels: this.monthArray,
            datasets: this.datasets
          },
          {responsive: true, maintainAspectRatio: false, beginAtZero: true})
    }
  }
};
</script>
