<template>
  <v-overlay
    v-if="isShow"
    :light="true"
    :dark="false"
  >
    <div class="close-button">
      <span @click="close">x</span>
    </div>
    <custom-layout title="정산내역 상세">
      <v-card width="90vw" max-width="2200px">
        <div class="d-flex justify-space-between align-center pt-3">
          <div>
            <span class="text-h6 ml-5 mr-5">총 지급건수 : {{ data.length }}건</span>
            <span class="text-h6 mr-5">조기지급액 : {{ inputComma(totalERewardAmt) }}원</span>
            <span class="text-h6 mr-5">환수대상액 : {{ inputComma(totalHRewardAmt) }}원</span>
            <span class="text-h6 mr-5">월정산액 : {{ inputComma(totalMRewardAmt) }}원</span>
            <span class="text-h6">지급액 : {{ inputComma(totalSRewardAmt) }}원</span>
          </div>
          <outlined-btn class="mt-2 mr-3" @click="toExcel" :disabled="data.length < 1">엑셀다운로드</outlined-btn>
        </div>
        <v-data-table
          class="pa-3"
          height="50vh"
          :loading="loading"
          :headers="getHeader(headers)"
          :items="data"
          :items-per-page="-1"
        >
          <template v-slot:item.recipientTelNum="{ value }">
            <div>{{ getTelNum(value) }}</div>
          </template>
          <template v-slot:item.customerTelNum="{ value }">
            <div>{{ getTelNum(value) }}</div>
          </template>
          <template v-slot:item.contractPremAmt="{ value }">
            <div>{{ inputComma(value) }}</div>
          </template>
          <template v-slot:item.rewardAmt="{ value }">
            <div>{{ inputComma(value) }}</div>
          </template>
        </v-data-table>
      </v-card>
    </custom-layout>
  </v-overlay>
</template>
<script>
import CustomLayout from "@/layouts/CustomLayout.vue";
import _ from "lodash";
import {axiosInstance} from "@/util/AxiosModule";
import TableMixin from "@/mixin/TableMixin";
import {NEW_PAYMENT_DETAIL_HEADER} from "@/util/TableHeaders";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn.vue";
import func from "@/util/func";

export default {
  components: {OutlinedBtn, CustomLayout},
  props: ["isShow", "paramsData", "searchData"],
  mixins: [TableMixin],
  // computed: {

  // },
  watch: {
    isShow(value) {
      if (value) {
        this.rowData = _.cloneDeep(this.searchData[0]);
        this.searchDetailData();
      }
    },
    data(items) {
      if (this.totalERewardAmt !== 0) {
        this.totalERewardAmt = 0;
      }
      if (this.totalHRewardAmt !== 0) {
        this.totalHRewardAmt = 0;
      }
      if (this.totalMRewardAmt !== 0) {
        this.totalMRewardAmt = 0;
      }
      if (this.totalSRewardAmt !== 0) {
        this.totalSRewardAmt = 0;
      }
      this.setPayType(items);
      this.totalSRewardAmt = (this.totalERewardAmt + this.totalHRewardAmt + this.totalMRewardAmt);
    }
  },
  data() {
    return {
      headers: NEW_PAYMENT_DETAIL_HEADER,
      loading: false,
      rowData: {},
      data: [],
      totalERewardAmt: 0,
      totalHRewardAmt: 0,
      totalMRewardAmt: 0,
      totalSRewardAmt: 0
    }
  },
  methods: {
    setPayType(items) {
      for (let i in items) {
        if (items[i].earlySettlementHistId !== 0) {
          items[i].payType = "조기지급건";
          this.totalERewardAmt += items[i].rewardAmt;
        } else if (items[i].rewardAmt < 0) {
          items[i].payType = "환수대상건";
          this.totalHRewardAmt += items[i].rewardAmt;
        } else {
          items[i].payType = "월정산건";
          this.totalMRewardAmt += items[i].rewardAmt;
        }
      }
    },
    async searchDetailData() {
      const params = {
        searchType: this.paramsData.searchType === "A" ? "PAY_COMPLETE_DATE" : "PAY_DUE_HIST",
        startDate: this.paramsData.payDate,
        endDate: this.paramsData.payDate,
        recipientNm: this.rowData.recipientNm,
        recipientTelNum: this.rowData.telNum,
        contractInsurer: "ALL",
        dtType: "ALL"
      };
      try {
        const { data } = await axiosInstance.get("/admin/settlement/detail", { params });
        for (let i in data) {
          data[i].endState = this.rowData.rewardStatusValue;
        }
        this.data = data;
      } catch (e) {
        console.log(e);
      }
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      let memberNm = "";

      for (const item of data) {
        memberNm = item["recipientNm"];
        const obj = {
          "지급구분": item["payType"],
          "회원명": item["recipientNm"],
          "회원 전화번호": item["recipientTelNum"],
          "고객명": item["customerNm"],
          "고객 전화번호": item["customerTelNum"],
          "고객 차량번호": item["customerCarNo"],
          "상태": item["rewardStatus"],
          "최종상태": item["endState"],
          "점검확인": item["checkResultYn"],
          "보험사": item["contractInsurer"],
          "계약채널": item["joinChannel"],
          "계약보험료": item["contractPremAmt"],
          "리워드": item["rewardAmt"],
          "계약일": item["contractDate"],
          "지급예정일": item["payDueDate"],
          "지급완료일": item["payCompleteDate"],
          "비고": item["remark"],
          "설계지원번호": item["designSupId"],
          "이륜차여부": item["twoWheelerYn"],
          "법인계약여부": item["corpContYn"],
          "조기정산ID": item["earlySettlementHistId"]
        }
        returnData.push(obj);
      }

      const sheetName = `지급처리(신)상세`;
      const fileName = `지급처리(신)상세-(${memberNm})(${this.paramsData.payDate})`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    close() {
      this.rowData = {};
      this.data = [];
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  z-index: 999;
  span {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
  }
}
</style>