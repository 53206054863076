<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="70vw" max-height="90vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">정산작업 정보</span>

        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-form v-model="valid">
          <v-card-text >
            <v-row class="px-7">
              <v-col :class="inputClass" >
                <span class="subtitle-1">조직기준년월*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="fromYm"
                    outlined dense
                    placeholder="yyyymm"
                    maxlength="6"
                />
              </v-col>
              <v-col :class="inputClass">
                <span class="subtitle-1">정산기준년월*</span>
                <v-text-field
                    :rules="rules.nn"
                    background-color="white"
                    v-model="setYm"
                    outlined dense
                    placeholder="yyyymm"
                    maxlength="6"
                />
              </v-col>
            </v-row>

          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              :disabled="!valid"
              @click="submit"
          >
            {{ isModify ? "실행" : "실행"}}
          </v-btn>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="$emit('close')"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
//import _ from 'lodash';
import { rules } from "@/util/constant"
import { axiosInstance } from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import StringMixin from "@/mixin/StringMixin";

export default {
  mixins: [CodeMixin, StringMixin],
  props: ["isShow", "partnerData", "isModify"],
  async created() {

  },
  computed: {

  },
  data: () => ({
    inputClass: 'd-flex flex-column col-md-5 col-sm-7 col-18',
    valid: false,
    rules: rules,
    fromYm: "",
    setYm: ""

  }),
  watch: {

  },
  methods: {
    async submit() {

      confirm(`작업을 실행 하시겠습니까?`) && await this.exBatch();

      this.$emit('close');
    //  this.jobInfo = _.cloneDeep(this.jobInfo);
    },

    async exBatch() {
      this.loading = true;

      const params = {
        fromYm : this.fromYm,
        setYm : this.setYm
      }
      try {
        const {data} = await axiosInstance.get('/admin/operation/sag-batch', {params});
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>