<template>
  <v-col class="col-6 d-flex flex-column ma-auto">
  </v-col>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>