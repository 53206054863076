<template>
  <v-col cols="12" class="py-5 px-2">
    <div class="text-right">
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin-right: 3px;"
          @click="toExcel"
          :disabled="data.length <= 0"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="60vh"
          item-key="index"
          v-model="selectData"
          @input="selectedData"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          :headers="getHeader(headers)"
          fixed-header calculate-widths
          :footer-props="{'items-per-page-options': [10, 30, 50 , -1]}"
          show-select
      >
        <template v-slot:item.prNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...")}}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.cvrNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...")}}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.useYn="{ item }">
          <v-select
              style="max-width: 50px; justify-content: center;"
            v-model="item.useYn"
            :items="useYnList"
            item-text="useYnNm"
            item-value="useYn"
          />
        </template>
        <!-- 담보타입 데이터 변환 -->
        <template v-slot:item.premSumType="{ value }">
          <div>{{ changedData(value, "1", "합산") }}</div>
          <div>{{ changedData(value, "2", "최대") }}</div>
          <div>{{ changedData(value, "3", "최소") }}</div>
        </template>
        <!-- 담보타입 데이터 변환 -->
        <template v-slot:item.cvrDiv="{ value }">
          <div>{{ changedData(value, "1", "의무사항") }}</div>
          <div>{{ changedData(value, "2", "선택사항") }}</div>
        </template>
        <!-- 가입금액 콤마 추가 -->
        <template v-slot:item.baseAccAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <!-- 보험료 콤마 추가 -->
        <template v-slot:item.monthlyPrem="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {INS_CVR_MAPPING_STATUS_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";


export default {
  props: ["data", "loading"],
  mixins: [TableMixin],
  data() {
    return {
      headers: INS_CVR_MAPPING_STATUS_TABLE,
      selectData: [],
      cvrDivList: [
        {cvrDivNm: "의무사항", cvrDiv: "1"},
        {cvrDivNm: "선택사항", cvrDiv: "2"},
      ],
      useYnList: [
        {useYnNm: "Y", useYn: "Y"},
        {useYnNm: "N", useYn: "N"}
      ],
    }
  },
  methods: {
    selectedData() {
      this.$emit("select", this.selectData);
    },
    // Excel 관련 코드
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "신정원담보코드": item["creCvrCd"],
          "신정원담보명": item["creCvrNm"],
          "담보타입": item["cvrDiv"],
          "공통여부": item["commCmpCvrYn"],
          "원수사명": item["insNm"],
          "상품코드": item["prCd"],
          "상품명": item["prNm"],
          "담보코드": item["cvrCd"],
          "담보명": item["cvrNm"],
          "가입금액": item["baseAccAmt"],
          "보험료": item["monthlyPrem"],
          "보기": item["insTrm"],
          "납기": item["pyTrm"],
          "사용여부": item["useYn"],
          "합산구분": item["premSumType"],
        }
        returnData.push(obj);
      }

      const sheetName = `보험사 담보 매핑현황`;
      const fileName = `보험사 담보 매핑현황`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  },
}
</script>