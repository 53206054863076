<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-card max-width="95vw" max-height="95vh" class="overflow-y-auto">
      <v-card-title class="headline">
        <span class="font-weight-bold">{{prTbNm}}</span>
      </v-card-title>
      <div class="mb-2" style="margin-right: 15px; margin-left: 15px;">
        <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
        <outlined-btn class="float-right" @click="toExcel" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
      </div>
      <v-divider :dark="true" :light="false"/>
      <div class="table-lay" style="width:99%; max-height: 55vh; margin-left: 10px; overflow:auto; margin-top: 8px;">
        <table cellpadding="0" cellspacing="0" id="excelDownload" class="tableCss">
          <thead>
          <tr style="padding-left: 10px;">
            <th v-for="item in tableHead" :key="item.columnoid">
              <div class="tableSi">{{item.comment}}</div>
            </th>
          </tr>
          </thead>
          <tbody style="text-align: center; padding-left: 10px;">
          <tr v-for="(item, index) in tableData" :key="index" class="tr-css">
            <td id="divRow" v-for="(val, index) in item" :key="index" class="td-css" style="padding-left: 25px; padding-right: 25px;">
              &#8203;{{val}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <v-card-actions style="margin-top: 15px;">
        <v-spacer></v-spacer>
        <v-btn
            color="red darken-4"
            class="white--text col-2"
            @click="close"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import XLSX from "xlsx";
export default {
  components: {OutlinedBtn},
  props: ["isShow", "tableHead", "tableData", "prTbNm"],
  data: () => ({
  }),
  computed: {
    hasTableData() {
      return this.tableHead.length > 0;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toExcel() {
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('excelDownload')); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, this.prTbNm + '테이블');  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, this.prTbNm + '테이블.xlsx'); // 엑셀파일 만듬
    },
  }
}
</script>
<style scoped>
.table-lay {
  margin-left: 10px;
  margin-right: 10px;
  overflow:auto;
}
.tableSi {
  min-width: 111px;
  height: 33px;
  padding-top: 4px;
  background-color: #F0F8FF;
}
table th {
  border: none;
}
table {
  border-collapse: collapse;
}
.tr-css {
  border-bottom: 0.1px solid gray;
}
.td-css {
  font-size: 17px;
  height: 30px;
}
td {
  white-space: normal!important;
}
tbody #divRow {
  word-break: keep-all !important;
}
</style>