<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <custom-layout title="상품명 변경">
      <v-card width="65vw" height="100%" max-width="850px" max-height="900px">
        <v-row class="pa-5 pr-10">
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">상품군명</v-col>
            <v-text-field
                v-model="productData.prProdLineNm"
                outlined hide-details dense
                background-color="white"
                disabled
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">보험사</v-col>
            <v-text-field
                v-model="productData.insNm"
                outlined hide-details dense
                background-color="white"
                disabled
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">상품코드</v-col>
            <v-text-field
                v-model="productData.prCd"
                outlined hide-details dense
                background-color="white"
                disabled
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-12">
            <v-col cols="2">상품명</v-col>
            <v-text-field
                v-model="productData.prNm"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">적용시기</v-col>
            <date-input
                type="appFromDt"
                :isSingleDate="true"
                :singleDate="productData.appFromDt"
                @updateDate="updateDate"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">적용종기</v-col>
            <date-input
                type="appToDt"
                :isSingleDate="true"
                :singleDate="productData.appToDt"
                @updateDate="updateDate"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">업데이트일자</v-col>
            <date-input
                type="newDispEndDt"
                :isSingleDate="true"
                :singleDate="productData.newDispEndDt"
                @updateDate="updateDate"
                :disabled="checkStepCd"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">사용여부</v-col>
            <v-select
                v-model="productData.useYn"
                :items="useYnList"
                item-text="useYnNm"
                item-value="useYn"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">작업단계</v-col>
            <v-select
                v-model="productData.wkStepCd"
                @change="changeWkStepCd(productData.wkStepCd)"
                :items="wkStepList"
                item-text="wkStepNm"
                item-value="wkStepCd"
                outlined hide-details dense
                background-color="white"
                :disabled="checkUseYn"
            />
          </v-col>
          <v-col class="col-6"></v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">세부단계</v-col>
            <v-select
                v-model="productData.wkSubStepCd"
                :items="wkSubStepList"
                item-text="wkSubStepNm"
                item-value="wkSubStepCd"
                outlined hide-details dense
                background-color="white"
                :disabled="checkUseYn"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-6">
            <v-col cols="4">세부상태</v-col>
            <v-select
                v-model="productData.wkSubStateCd"
                :items="wkSubStateList"
                item-text="wkSubStateNm"
                item-value="wkSubStateCd"
                outlined hide-details dense
                background-color="white"
                :disabled="checkUseYn"
            />
          </v-col>
          <v-col class="d-flex justify-start align-center col-12">
            <v-col cols="2">메모</v-col>
            <v-textarea
                class="text-area"
                v-model="productData.wkMsgCont"
                outlined hide-details dense
                background-color="white"
                height="100%" max-height="80px"
            />
          </v-col>
        </v-row>

        <v-card-actions class="d-flex justify-center">
          <v-btn class="red darken-1 white--text" @click=closePopup>닫기</v-btn>
          <v-btn class="blue darken-4 white--text" @click="save">저장</v-btn>
        </v-card-actions>
      </v-card>
    </custom-layout>
  </v-overlay>
</template>
<script>
import CustomLayout from "@/layouts/CustomLayout";
import _ from "lodash";
import DateInput from "@/components/Form/DateInput.vue";
import ProductRecommend from "@/util/ProductRecommend";
import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";
import {API_SERVER_URL_DEV} from "@/util/constant";

export default {
  components: {DateInput, CustomLayout},
  props: ["isShow", "prProdLineList", "insList", "wkStepList", "wkSubStateList", "useYnList", "rowData"],
  data() {
    return {
      productData: {},
      useYn: "Y",
      wkSubStepList: [],
      isSingleDate: false
    }
  },
  computed: {
    checkStepCd() {
      return this.productData.wkStepCd !== "100" && this.productData.wkStepCd !== "700"
    },
    checkUseYn() {
      return this.useYn === "N";
    }
  },
  watch: {
    async isShow(value) {
      if (value) {
        this.productData = _.cloneDeep(this.rowData[0]);
        this.productData.appFromDt = this.replaceDate(this.productData.appFromDt);
        this.productData.appToDt = this.replaceDate(this.productData.appToDt);
        this.productData.newDispEndDt = this.replaceDate(this.productData.newDispEndDt);
        this.getChangeCdName("prProdLineCd", this.productData.prProdLineCd);
        this.getChangeCdName("insCd", this.productData.insCd);
        if (this.productData.wkStepCd === "1000") {
          this.productData.wkStepCd = "";
          this.productData.wkSubStepCd = "";
          this.productData.wkSubStateCd = "";
        }
        this.wkSubStepList = await this.setWkSubStepList(this.productData.wkStepCd);
        this.wkStepList.unshift({wkStepNm: "선택", wkStepCd: ""});
        this.wkSubStepList.unshift({wkSubStepNm: "선택", wkSubStepCd: ""})
        this.wkSubStateList.unshift({wkSubStateNm: "선택", wkSubStateCd: ""});
        this.productData.regId = store.getters["login/memberId"];
        this.productData.useYn = _.cloneDeep(this.useYn);
      }
    }
  },
  methods: {
    setDefaultProductData() {  // 기본 데이터 초기화
      this.productData = {
        wkStepCd: "",
        wkSubStepCd: "",
        wkSubStateCd: "",
      };
    },
    replaceDate(date) {
      return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6)}`;
    },
    setWkSubStepList(code) {
      return ProductRecommend.getWkSubStepList(code);
    },
    async changeWkStepCd(code) {
      this.wkSubStepList = await this.setWkSubStepList(code);
      this.productData.wkSubStepCd = this.wkSubStepList[0].wkSubStepCd;
      this.productData.newDispEndDt = this.replaceDate(this.rowData[0].newDispEndDt);
    },
    getChangeCdName(type, value){
      if (type === "prProdLineCd") {
        const item = this.prProdLineList.find(items => items.prProdLineCd === value);
        return item ? item.prProdLineNm : null;
      } else if (type === "insCd") {
        const item = this.insList.find(items => items.insCd === value);
        return item ? item.insCdNm : null;
      }
      return value;
    },
    updateDate(date, type) {
      if (type === "appFromDt") {
        this.productData.appFromDt = date;
      } else if (type === "appToDt") {
        this.productData.appToDt = date;
      } else if (type === "newDispEndDt") {
        this.productData.newDispEndDt = date;
      }
      return date;
    },
    async save() {
      let updateIn = [];
      updateIn.push({
        appFromDt: this.productData.appFromDt.replace(/-/g, ""),
        appToDt: this.productData.appToDt.replace(/-/g, ""),
        insCd: this.productData.insCd,
        newDispEndDt: this.productData.newDispEndDt.replace(/-/g, ""),
        prCd: this.productData.prCd,
        prNm: this.productData.prNm,
        prProdLineCd: this.productData.prProdLineCd,
        regId: store.getters["login/memberId"],
        wkMsgCont: this.productData.wkMsgCont,
        wkStepCd: this.productData.wkStepCd,
        wkSubStateCd: this.productData.wkSubStateCd,
        wkSubStepCd: this.productData.wkSubStepCd,
        wkPrId: this.productData.wkPrId,
        wkMsgId: 0,
        useYn: this.productData.useYn
      });

      const checkUpdateIn = updateIn[0];

      // 1. 필수값 누락 확인
      if (_.isEmpty(checkUpdateIn.prNm)) {
        alert("상품명을 입력해주세요.");
        return false;
      }
      if (_.isEmpty(checkUpdateIn.wkStepCd)) {
        alert("작업단계를 선택해주세요.");
        return false;
      }
      if (_.isEmpty(checkUpdateIn.wkSubStepCd)) {
        alert("세부단계를 선택해주세요.");
        return false;
      }
      if (_.isEmpty(checkUpdateIn.wkSubStateCd)) {
        alert("세부상태를 선택해주세요.");
        return false;
      }

      // 초기 데이터 변경사항 확인
      if (
          checkUpdateIn.prNm === this.rowData[0].prNm &&
          checkUpdateIn.appFromDt === this.rowData[0].appFromDt &&
          checkUpdateIn.appToDt === this.rowData[0].appToDt &&
          checkUpdateIn.newDispEndDt === this.rowData[0].newDispEndDt &&
          checkUpdateIn.wkStepCd === this.rowData[0].wkStepCd &&
          checkUpdateIn.wkSubStepCd === this.rowData[0].wkSubStepCd &&
          checkUpdateIn.wkSubStateCd === this.rowData[0].wkSubStateCd &&
          checkUpdateIn.wkMsgCont === this.rowData[0].wkMsgCont &&
          checkUpdateIn.useYn === this.useYn
      ) {
        alert("변경사항이 없습니다.")
        return false;
      }

      if (confirm("변경된 상품정보를 저장하시겠습니까?")) {
        try {
          await axiosInstance.post("/admin/prdata/updatePrWkProc", updateIn);
          // prod -> dev 단방향 동기화
          if (process.env.NODE_ENV !== 'development') {
            await axiosInstance.post(API_SERVER_URL_DEV + "/admin/prdata/updatePrWkProc", updateIn);
            alert("저장 및 개발계와 동기화 완료");
          } else {
            alert("저장되었습니다.");
          }

          this.$emit("updateData");
          this.closePopup();
        } catch (error) {
          console.log(error);
        }
      }
    },
    closePopup() {
      this.setDefaultProductData();
      this.$emit("close");
    }
  }
}
</script>

<style scoped>
.text-area {
  height: 100%;
  max-height: 80px;
  overflow-y: auto;
}
@media (min-height: 768px) {
  .text-area {
    max-height: 150px;
  }
}
@media (min-height: 1024px) {
  .text-area {
    max-height: 250px;
  }
}
</style>