<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="4">상품군</v-col>
          <v-col cols="6">
            <v-select
              @click="getPrProdLineCd"
              v-model="prProdLineCd"
              :items="prProdLineCdList"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              outlined dense hide-details
              background-color="white" class="subBar"
            />
          </v-col>
        </v-col>
        <v-col v-if="tabs === 1" class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="3">구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="cvrDiv"
                :items="cvrDivList"
                item-text="cvrDivNm"
                item-value="cvrDiv"
                outlined dense hide-details
                background-color="white" class="subBar"
            />
          </v-col>
        </v-col>
        <v-col v-if="tabs === 1" class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="6">신정원 담보코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="creCvrCd"
                outlined dense hide-details
                background-color="white"
                append-icon="mdi-land-rows-horizontal"
                @click:append="showCreCvrCd"
            />
          </v-col>
        </v-col>
        <v-col v-if="tabs === 1" class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="5">신정원 담보명</v-col>
          <v-col cols="8">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="creCvrNm"
                      outlined dense hide-details
                      background-color="white"
                  />
                </div>
              </template>
              <span>{{ creCvrNm }}</span>
            </v-tooltip>
          </v-col>
        </v-col>

        <cre-cvr-mapping-modal
            :is-show="isShowCreCvrCd"
            @close="isShowCreCvrCd = false"
            @row="creCvrRow"
        />
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBtn from "@/components/Search/SearchBtn";
import CreCvrMappingModal
  from "@/pages/Product/components/ProductCompareManagement/CreCvrMappingModal/CreCvrMappingModal";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  props: ["tabs", "statusRowData"],
  components: {CreCvrMappingModal, SearchBtn, SearchArea, SearchBarLayout},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      cvrDiv: "",
      cvrDivList: [
        {cvrDivNm: "선택", cvrDiv: ""},
        {cvrDivNm: "의무사항", cvrDiv: "1"},
        {cvrDivNm: "선택사항", cvrDiv: "2"},
      ],
      creCvrCd: "",
      creCvrNm: "",
      isShowCreCvrCd: false,
    }
  },
  watch: {
    statusRowData: function (value) {
      this.prProdLineCd = value.prProdLineCd;
      this.cvrDiv = value.cvrDiv;
      this.creCvrCd = value.creCvrCd;
      this.creCvrNm = value.creCvrNm;
    },
    tabs: function (value) {
      if (value === 0) {
        this.creCvrCd = "";
        this.creCvrNm = "";
        this.cvrDiv = "";
      }
    },
  },
  methods: {
    async getPrProdLineCd() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineCdList.shift();
    },
    getSearch() {
      const params = {
        prProdLineCd: this.prProdLineCd,
        creCvrCd: this.creCvrCd,
        cvrDiv: this.cvrDiv,
      }
      this.$emit("getSearch", params);
    },
    showCreCvrCd() {
      this.isShowCreCvrCd = true;
    },
    creCvrRow(row) {
      this.creCvrCd = row.creCvrCd;
      this.creCvrNm = row.creCvrNm;
    },
  }
}
</script>