<script>
import {Bar} from "vue-chartjs";

export default {
  props: ["monthArray", "resData"],
  extends: Bar,
  data() {
    return {
      datasets: [
        {
          label: "실적",
          type: "bar",
          data: [],
          backgroundColor: "rgba(96,198,255,0.8)",
          hoverBackgroundColor: "rgb(96,198,255)",
          borderWidth: 3
        },
      ],
    }
  },
  mounted() {
    for (let i in this.resData) {
      this.datasets[0].data[i] = this.resData[i].prem;
    }
    this.renderChart(
        {
          labels: this.monthArray,
          datasets: this.datasets
        },
        {responsive: true, maintainAspectRatio: false, beginAtZero: true}
    );
  },
  watch: {
    // dialog close -> initChartData() 실행 -> resData 초기화 -> 다시 차트 렌더링
    resData: function () {
      for (let i in this.resData) {
        this.datasets[0].data[i] = this.resData[i].prem;
      }
      this.$data._chart.update();
    },
    data: function () {
      this.renderChart({
            labels: this.monthArray,
            datasets: this.datasets
          },
          {responsive: true, maintainAspectRatio: false, beginAtZero: true})
    }
  }
};
</script>
