<template>
  <search-page-layout title="주력담보 현황">
    <template v-slot:searchBar>
      <main-cvr-status-search-bar
        @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <main-cvr-status-table
        :data="data"
        :loading="loading"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import MainCvrStatusSearchBar from "@/pages/Product/components/MainCvrStatus/MainCvrStatusSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import MainCvrStatusTable from "@/pages/Product/components/MainCvrStatus/MainCvrStatusTable";

export default {
  components: {MainCvrStatusTable, MainCvrStatusSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
    }
  },
  methods: {
    async getSearch(params) {
      this.loading = true;
      console.log(params);

      try {
        const { data } = await axiosInstance.get('/private/prdata/prPlanMainCvrList', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>