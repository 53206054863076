<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <field-input title="구분">
          <v-select
              :items="SELECT_TYPE"
              background-color="white"
              v-model="param.searchType" menu-props="auto"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="회원명">
          <v-text-field
              v-model="param.memberNm"
              @keypress.enter="getUserSearch"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="휴대폰번호">
          <v-text-field
              v-model="param.telNum"
              @keypress.enter="getUserSearch"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>
      <search-area title="선택조건">
        <field-input title="계좌번호">
          <v-text-field
              v-model="param.accountNo"
              @keypress.enter="getUserSearch"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <span class="desc">회원명, 전화번호 중 하나 이상을 입력해야 조회가 가능합니다.</span>

        <v-col cols="12" class="d-flex justify-end">
          <v-btn
              @click="checkMemberPopup"
              color="grey"
              class="white--text px-10"
          >
            회원 여부 점검
          </v-btn>
        </v-col>
        <check-member-popup
            :is-show="isShow"
            @close="isShow = false" />

      </search-area>
    </template>
    <template v-slot:button>
      <search-btn
          :disabled="isDisabled"
          @click="getUserSearch"
      />
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import CodeMixin from "@/mixin/CodeMixin";
import CheckMemberPopup from "@/pages/Stat/components/CheckMember/CheckMemberPopup";

export default {
  mixins: [SearchBarMixin, CodeMixin],
  components: {
    SearchArea,
    SearchBarLayout,
    FieldInput,
    CheckMemberPopup,
  },
  async created() {
    this.SELECT_TYPE = await this.getSelectList("USER_SEARCH_BAR_TYPE");
  },
  computed: {
    isDisabled() {
      return (
          !this.param.memberNm && !this.param.telNum
      );
    },
    valid() {
      return (
          !!this.param.memberNm || !!this.param.telNum
      );
    }
  },
  data: () => ({
    SELECT_TYPE: [],
    param: {
      searchType: "NORMAL",
      memberNm: "",
      telNum: "",
      accountNo: ""
    },
    isShow: false
  }),
  methods: {
    getUserSearch() {
      this.valid
          ? this.$emit('search', this.param)
          : alert("회원명, 전화번호 중 하나 이상을 입력해야 조회가 가능합니다.")
    },
    checkMemberPopup(){
      this.isShow = true;
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>