var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-1 px-3",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2 text-right",staticStyle:{"margin":"-40px 3px 0 0"}},[_c('outlined-btn',{attrs:{"disabled":!_vm.hasTableData},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[(_vm.tabs === 0)?_c('v-data-table',{attrs:{"height":"45vh","items":_vm.insData,"loading":_vm.loading,"headers":_vm.getHeader(_vm.insHeaders),"fixed-header":"","calculate-widths":"","items-per-page":-1,"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.compareSum",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.shareSum",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.mzCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.mzShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.hwCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.hwShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.ltCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.ltShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.hkCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.hkShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.ssCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.ssShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.hdCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.hdShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.kbCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.kbShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.dbCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.dbShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}}],null,false,1356062153)}):_vm._e(),(_vm.tabs === 1)?_c('v-data-table',{attrs:{"height":"45vh","items":_vm.ageData,"loading":_vm.loading,"headers":_vm.getHeader(_vm.customHeaders),"fixed-header":"","calculate-widths":"","items-per-page":-1,"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.compareSum",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.shareSum",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.mUn30CompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.mUn30ShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.wUn30CompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.wUn30ShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.m30sCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.m30sShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.w30sCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.w30sShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.m40sCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.m40sShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.w40sCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.w40sShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.m50sCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.m50sShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.w50sCompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.w50sShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.mUp60CompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.mUp60ShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.wUp60CompCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.wUp60ShareCnt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.inputComma(value)))])]}}],null,false,95694089)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }