<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <v-card class="pa-3" width="50vw" height="100%" max-width="700" max-height="700">
      <div class="d-flex justify-end align-center pr-10" style="position: absolute; width: 100%;">
        <span style="font-size: 30px; cursor: pointer" @click="closePopup">x</span>
      </div>
      <search-page-layout title="조기정산 환수 입금 저장">
        <template v-slot:searchBar>
          <search-area>
            <v-row>
              <v-col class="d-flex justify-center col-6">
                <v-col cols="5">회원명</v-col>
                <v-text-field
                  v-model="saveUserData.memberNm"
                  outlined dense hide-details
                  background-color="white" disabled
                />
              </v-col>
              <v-col class="d-flex justify-center col-6">
                <v-col cols="5">발송회차</v-col>
                <v-text-field
                    v-model="saveUserData.sendRound"
                    outlined dense hide-details
                    background-color="white" disabled
                />
              </v-col>
              <v-col class="d-flex justify-center col-6">
                <v-col cols="5">환수통장</v-col>
                <v-select
                    v-model="saveUserData.bankNm"
                    :items="insList"
                    item-text="value"
                    item-value="code"
                    outlined dense hide-details
                    background-color="white" disabled
                />
              </v-col>
              <v-col class="d-flex justify-center col-6">
                <v-col cols="5">입금액</v-col>
                <v-text-field
                    v-model="saveUserData.totAmt"
                    outlined dense hide-details
                    background-color="white"
                    @input="setTotAmt"
                />
              </v-col>
              <v-col class="d-flex justify-center col-6">
                <v-col cols="5">입금내용</v-col>
                <v-text-field
                    v-model="saveUserData.memo"
                    outlined dense hide-details
                    background-color="white"
                />
              </v-col>
              <v-col class="d-flex justify-center col-6">
                <v-col cols="5">입금일자</v-col>
                <date-input
                  :is-single-date="true"
                  :single-date="saveUserData.depositDt"
                  @updateDate="updateDate"
                />
              </v-col>
            </v-row>
          </search-area>
          <div class="d-flex justify-center col-12">
            <v-btn class="grey white--text" @click="save">저장</v-btn>
          </div>
        </template>
      </search-page-layout>
    </v-card>
  </v-overlay>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout.vue";
import _ from "lodash";
import SearchArea from "@/components/Search/SearchArea.vue";
import {axiosInstance} from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput.vue";
import func from "@/util/func";
import TableMixin from "@/mixin/TableMixin";

export default {
  components: {DateInput, SearchArea, SearchPageLayout},
  props: ["isShow", "rowData"],
  mixins: [CodeMixin, TableMixin],
  data() {
    return {
      insList: [],
      saveUserData: {
        depositDt: "",
      },
      loading: false
    }
  },
  async created() {
    this.insList = await this.getDefaultBankList();
    this.insList.unshift({value: "선택", code: ""});
  },
  watch: {
    isShow(value) {
      if (value) {
        this.saveUserData = _.cloneDeep(this.rowData);
        this.saveUserData.bankNm = "KB_BANK";
        this.saveUserData.totAmt = this.inputComma(Math.abs(this.saveUserData.totAmt));
        this.saveUserData.depositDt = func.getToday().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
      }
    }
  },
  methods: {
    setTotAmt() {
      this.saveUserData.totAmt = this.inputComma(this.saveUserData.totAmt.replaceAll(",", ""));
    },
    updateDate(date) {
      this.saveUserData.depositDt = date;
    },
    async save() {
      const params = {
        memberId: this.saveUserData.memberId,
        sendRound: this.saveUserData.sendRound,
        bankNm: this.saveUserData.bankNm,
        amount: Number(this.saveUserData.totAmt.replaceAll(",", "")),
        memo: this.saveUserData.memo,
        depositDt: this.saveUserData.depositDt.replaceAll("-", "")
      }
      if (_.isEmpty(params.bankNm)) {
        alert("은행명을 선택해주세요.");
        return false;
      } else if (
          params.amount === null &&
          params.amount === undefined &&
          params.amount === 0
      ) {
        alert("입금액을 입력해주세요.");
        return false;
      } else if (_.isEmpty(params.memo)) {
        alert("입금내용을 입력해주세요.");
        return false;
      } else if (_.isEmpty(params.depositDt)) {
        alert("입금일자를 선택해주세요.");
        return false;
      }
      try {
        await axiosInstance.put("/public/settlement/setSettlementNoticeTargetComplete", {}, { params })
        .then(() => {
          alert("완료처리 되었습니다.");
          this.closePopup();
        })
        .catch((error) => {
          console.log(error);
        })
      } catch (e) {
        console.log(e);
      }
    },
    closePopup() {
      this.$emit("close");
    }
  }
}
</script>