<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="50vw" max-height="90vh" class="overflow-y-auto modalBackground">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">보험사 추가</span>
        </v-card-title>
        <v-card-text>
          <v-row class="px-6">
            <insurer-modal-input title="내부정산명" :inputClass="inputClass">
              <v-text-field
                  v-model="insurerDto.insurerCd"
                  background-color="white"
                  outlined dense
              />
            </insurer-modal-input>
            <insurer-modal-input title="Codef명" :inputClass="inputClass">
              <v-text-field
                  v-model="insurerDto.externalInsurerNm"
                  background-color="white"
                  outlined dense
              />
            </insurer-modal-input>
            <insurer-modal-input title="전화번호" :inputClass="inputClass">
              <v-text-field
                  v-model="insurerDto.telNum"
                  background-color="white"
                  outlined dense
              />
            </insurer-modal-input>
          </v-row>
          <v-row class="px-6">
            <insurer-modal-input title="보험유형" inputClass="col-6">
              <v-select
                  v-model="insurerDto.type"
                  :items="TYPE_SELECT"
                  outlined dense
                  background-color="white"
              />
            </insurer-modal-input>
            <insurer-modal-input title="상품유형" inputClass="col-6">
              <v-select
                  v-model="insurerDto.insProductType"
                  :items="PRODUCT_SELECT"
                  outlined dense
                  background-color="white"
              />
            </insurer-modal-input>
            <insurer-modal-input title="제휴여부" inputClass="col-3">
              <v-select
                  v-model="insurerDto.partner"
                  :items="PARTNER_SELECT"
                  outlined dense
                  background-color="white"
              />
            </insurer-modal-input>
            <insurer-modal-input title="노출여부" inputClass="col-3">
              <v-select
                  v-model="insurerDto.view"
                  :items="SHOW_SELECT"
                  outlined dense
                  background-color="white"
              />
            </insurer-modal-input>
            <insurer-modal-input title="전화가입" inputClass="col-3">
              <v-select
                  v-model="insurerDto.telephone"
                  :items="SHOW_SELECT"
                  outlined dense
                  background-color="white"
              />
            </insurer-modal-input>
            <insurer-modal-input title="인터넷가입" inputClass="col-3">
              <v-select
                  v-model="insurerDto.internet"
                  :items="SHOW_SELECT"
                  outlined dense
                  background-color="white"
              />
            </insurer-modal-input>
            <insurer-modal-input title="PCURL" inputClass="col-12">
              <v-text-field
                  v-model="insurerDto.pcUrl"
                  background-color="white"
                  outlined dense
              />
            </insurer-modal-input>
            <insurer-modal-input title="모바일URL" inputClass="col-12">
              <v-text-field
                  v-model="insurerDto.mobileUrl"
                  background-color="white"
                  outlined dense
              />
            </insurer-modal-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="submit"
          >
            {{ isModify ? "수정" : "추가"}}
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import InsurerModalInput from "@/pages/User/components/InsurerSearch/InsurerModalInput";
import _ from "lodash";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [CodeMixin],
  components: {InsurerModalInput},
  props: ["isShow", "insurerData", "isModify"],
  async created() {
    this.SHOW_SELECT = await this.getSelectList("INSURER_SHOW");
    this.TYPE_SELECT = await this.getSelectList("INSURER_TYPE");
    this.PRODUCT_SELECT = await this.getSelectList("INSURER_PRODUCT");
    this.PARTNER_SELECT = await this.getSelectList("INSURER_PARTNER");
    },
  data: () => ({
    inputClass: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12',
    SHOW_SELECT: [],
    TYPE_SELECT: [],
    PRODUCT_SELECT: [],
    PARTNER_SELECT: [],
    insurerDto: {
      "insId": "",
      "insurerCd": "",
      "externalInsurerNm": "",
      "insProductType": "I",
      "mobileUrl": "",
      "pcUrl": "",
      "telNum": "",
      "type": "CAR",
      "twoWheelerYn": "N",
      "partner": "Y",
      "view": "Y",
      "telephone": "Y",
      "internet": "Y"
    },
    initData: {
      "insId": "",
      "insurerCd": "",
      "externalInsurerNm": "",
      "insProductType": "I",
      "mobileUrl": "",
      "pcUrl": "",
      "telNum": "",
      "type": "CAR",
      "twoWheelerYn": "N",
      "partner": "Y",
      "view": "Y",
      "telephone": "Y",
      "internet": "Y"
    }
  }),
  watch: {
    insurerData: function(obj) {
      if(_.isEmpty(obj)) {
        this.insurerDto = _.cloneDeep(this.initData);
      } else {
        this.insurerDto = obj;
       }
    },
    'insurerDto.type': function (obj) {
      this.insurerDto.twoWheelerYn = (obj == 'TWO-WHEELER') ? "Y" : "N";
    }
  },
  methods: {
    async submit() {
      if(this.isModify) {
        confirm(`보험사를 수정하시겠습니까?`) && await this.modifyInsurer();
      } else {
        confirm(`보험사를 추가하시겠습니까?`) && await this.saveInsurer();
      }
    },
    async saveInsurer() {
      try {
        await axiosInstance.post('/admin/operation/insurer', this.insurerDto);
        this.$emit('close');
      } catch (e) {
        console.log(e);
      }
    },
    async modifyInsurer() {
      try {
        await axiosInstance.put('/admin/operation/insurer', this.insurerDto);
        this.$emit('afterModify');
        this.$emit('close');
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>