<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
    </div>
    <div class="mb-2 text-right">
      <outlined-btn @click="submitPrv">권한등록</outlined-btn>
      <outlined-btn @click="deletePrv" style="margin-left:10px;">권한삭제</outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          item-key="index"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="headers"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.checkYn="{ item }">
          <td class="text-center">
            <v-checkbox v-model="item.checkYn"/>
          </td>
        </template>
        <template v-slot:item.prvGroupCd="{ value }">
          {{ progGroupNameKr(value) }}
        </template>
        <template v-slot:item.createDate="{ value }">
          {{ contentsDataFormatter(value) }}
        </template>
      </v-data-table>
    </v-flex>

    <user-program-privilege-modal
        :key="modalKey"
        :prvList="prvList"
        :is-show="isModalShow"
        :checkedUser="checkedUser"
        @close="close"
        @refresh="refresh"
    />
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import UserProgramPrivilegeModal from "@/pages/Manage/UserProgramPrivilege/UserProgramPrivilegeModal";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  name: "UserProgramPrivilegeTable",
  components: {UserProgramPrivilegeModal, OutlinedBtn},
  mixins: [TableMixin],
  props: ["data", "loading", "prvList"],
  data: () => ({
    headers: [
      {text: "선택", value: "checkYn", align: "center", sortable: false, width: "7%"},
      {text: "사용자명", value: "memberNm", align: "center", sortable: false, width: "13%"},
      {text: "권한그룹", value: "prvGroupCd", align: "center", sortable: false, width: "17%"},
      {text: "프로그램명", value: "progNm", align: "center", sortable: false, width: "24%"},
      {text: "프로그램 한글명", value: "progNmKr", align: "center", sortable: false, width: "21%"},
      {text: "등록일시", value: "createDate", align: "center", sortable: false, width: "18%"}
    ],

    modalKey: 0,
    isModalShow: false,
    checkedUser: {},
  }),

  methods: {
    async deletePrv() {
      let deleteParams = [];
      this.data.forEach(item => {
        if (item.checkYn) {
          deleteParams.push(
              {
                memberId: item.memberId,
                prvGroupCd: item.prvGroupCd,
                progPrvId: item.progPrvId
              }
          );
        }
      });

      if (deleteParams.length < 1) {
        alert("삭제할 데이터를 선택하세요.");
        return false;
      }

      if (confirm(deleteParams.length + "건의 데이터를 삭제 하시겠습니까?")) {
        try {
          await axiosInstance.post('/admin/operation/deleteUserProgPrv', deleteParams);

          alert("삭제가 완료되었습니다.");
          this.refresh();
        } catch (e) {
          console.log(e);
        }
      }
    },

    submitPrv() {
      let checkedList = this.data.filter(item => item.checkYn);

      if (checkedList.length === 1) this.checkedUser = checkedList[0];
      else this.checkedUser = null;

      this.modalKey++;
      this.isModalShow = true;
    },

    // 개별 코드 IND 표시
    progGroupNameKr(value) {
      return (value === 'IND') ? "개별" : this.prvList.find(el => el.cd === value).value;
    },
    close() {
      this.modalKey++;
      this.isModalShow = false;
    },
    refresh() {
      this.$emit('refresh');
    }

  }
}
</script>

<style scoped>

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  margin-right: -8px;
}

</style>


