<template>
  <v-dialog
      v-model="show"
      width="90vw"
      backgroundcolor="white"
  >
    <v-card height="80vh" class="overflow-y-auto modalBackground" style="padding:10px">
      <div
          style="margin: 4px 0 8px 12px; font-size:30px;">
         <span v-if="dataTy === 'cm'" style="font-weight: 700">CM</span>
         <span v-if="dataTy === 'tm'" style="font-weight: 700">TM</span>
         <span v-if="dataTy === 'total'" style="font-weight: 700">합계</span>
        <span style="float: right; font-size: 20px; margin-right: 10px;">
          조회기간 : {{ stDt }} ~ {{ edDt }}</span>
      </div>
      <v-layout row style="margin-bottom: 5vh">
        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="30vw"
                  class="ml-5 pl-3" elevation="8">
            <v-card-title>보험회사별 보험료</v-card-title>
            <v-divider></v-divider>
            <insurance-chart
              style="height: 22vw; width: 40vw; margin-top: 2vh;"
              :monthArray="this.monthArray"
              :insuredNm="insuredNm"
              :totArry="totArry"
              />
          </v-card>
        </v-flex>
        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="30vw"
                  class="ml-5 pl-3" elevation="8">
            <v-card-title>보험사별 광고비</v-card-title>
            <v-divider></v-divider>
            <insurance-fee-chart
                style="height: 22vw; width: 40vw; margin-top: 2vh;"
                :monthArray="this.monthArray"
                :insuredNm="insuredNm"
                :totArry="totArry"
            />
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row style="margin-bottom: 1vh">
        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="33vw"
                  class="ml-5 pl-3" elevation="8">
            <v-card-title>보험사별 건별</v-card-title>
            <v-divider></v-divider>
            <insurance-count-chart
                style="height: 24vw; width: 40vw; margin-top: 2vh;"
                :monthArray="this.monthArray"
                :insuredNm="insuredNm"
                :totArry="totArry"
            />
          </v-card>
        </v-flex>
        <v-flex>
          <v-card width="42vw" height="33vh" min-width="35vh" min-height="33vw"
                  class="ml-5 pl-3" elevation="8">
            <v-card-title>
              <v-col cols="6">보험사별 보험료 비율</v-col>
              <v-col cols="6"><span style="font-size: 18px; float: right">조회기간 : {{ stDt }} ~ {{ edDt }}</span></v-col>
            </v-card-title>
            <v-divider></v-divider>
            <insurance-ratio-chart
                style="height:31vw; width: 49vw; margin-top: 2vh;"
                :monthArray="this.monthArray"
                :insuredNm="insuredNm"
                :totArry="totArry"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

import InsuranceChart from "./Charts/InsuranceChart";
import InsuranceFeeChart from "./Charts/InsuranceFeeChart";
import InsuranceCountChart from "./Charts/InsuranceCountChart";
import InsuranceRatioChart from "./Charts/InsuranceRatioChart";
export default {
  components: {InsuranceRatioChart, InsuranceCountChart, InsuranceFeeChart, InsuranceChart},
  props: ["total", "stDt", "edDt", "monthArray", "insuredNm", "totArry", "cmLog", "tmLog", "dataTy"],
  computed: {
    show: {
      get() {
        return this.total;
      },
      set(value) {
        this.$emit("updateSetting", value)
      },
    },
  },
}
</script>