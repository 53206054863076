<template>
  <v-overlay v-model="isShow">
    <check-member-layout title="회원 여부 점검">

      <template v-slot:upload>
        <check-member-upload @checkingMember="checkingMember"/>
      </template>

      <template v-slot:table>
        <check-member-popup-table
            :data="data"
            :loading="loading"
            @toExcel="toExcel"
            @close="close"
        />
      </template>

    </check-member-layout>
  </v-overlay>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import func from "@/util/func";
import CheckMemberLayout from "@/pages/Stat/components/CheckMember/CheckMemberLayout";
import CheckMemberUpload from "@/pages/Stat/components/CheckMember/CheckMemberUpload";
import CheckMemberPopupTable from "@/pages/Stat/components/CheckMember/CheckMemberPopupTable";
//import TableMixin from "@/mixin/TableMixin";

export default {
  //mixins: [TableMixin],
  props: ["isShow","name"],
  components: {
    CheckMemberLayout,
    CheckMemberUpload,
    CheckMemberPopupTable,
  },
  data: () => ({
    loading: false,
    data: [],
    input: "",
    temp_param: [{
      telNum: "",
      memberNm:"",
      email:""
    }]
  }),

  methods: {
    async checkingMember(excelObject){
      this.loading = true;

      // this.temp_param = _.cloneDeep(excelObject);
      // params에 담아서 post 요청
      try {
        const {data} = await axiosInstance.post('/admin/operation/check-member', excelObject);
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    // 출력된 테이블 엑셀로 저장
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "전화번호": item["telNum"],
          "회원명": item["memberNm"],
          "회원ID": item["email"]
        }
        returnData.push(obj);
      }
      const sheetName = `회원여부점검`;
      const fileName = `회원여부점검List`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },

    close() {
      this.$emit('close');
      this.input = "";
      this.data= [];  // 업로드 파일 및 테이블 초기화
    },
  }
}
</script>

<style scoped>

</style>
