<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin-right: 3px;"
          @click="toExcel"
          :disabled="data.length <= 0"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.cvrNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...") }}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {CRE_CVR_MAPPING_STATUS_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";

export default {
  mixins: [TableMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: CRE_CVR_MAPPING_STATUS_TABLE,
    }
  },
  methods: {
    clickContents(row) {
      this.$emit("row", row);
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "신정원 담보코드": item["creCvrCd"],
          "신정원 담보명": item["creCvrNm"],
          "사용여부": item["useYn"],
          "보험사명": item["insNm"],
          "보험사 담보코드": item["cvrCd"],
          "보험사 담보명": item["cvrNm"],
        }
        returnData.push(obj);
      }
      const sheetName = `신정원 담보코드 매핑현황`;
      const fileName = `신정원 담보코드 매핑현황`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>