<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">상담구분</v-col>
          <v-col cols="6">
            <v-select
                v-model="srhType"
                :items="srhTypeList"
                @change="chgSrhType"
                background-color="white"
                item-text="dname"
                item-value="dvalue"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="4">{{ dataType }}</v-col>
          <v-col cols="8">
            <date-input
                :start-date="frDate"
                :end-date="toDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
      </search-area>

    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import _ from  "lodash";

export default {
  mixins: [SearchBarMixin, DateMixin, CodeMixin],
  components: { DateInput, SearchArea, SearchBarLayout},

  created() {
    this.dataType = "요청일자";
  },
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.frDate = fromDate;
    this.toDate = toDate;
  },
  data() {
    return {
      frDate: "",
      toDate: "",
      dataType: "",

      srhType: 'D',
      srhTypeList: [
        {dname: '설계지원', dvalue:'D'},
        {dname: '캠페인', dvalue:'C'},
      ],
    }
  },
  methods: {
    updateDate(values) {
      this.frDate = values[0];
      this.toDate = values[1];
    },
    getSearch() {
      if (_.isEmpty(this.frDate) || _.isEmpty(this.toDate)) {
        alert("시작일자와 종료일자를 모두 선택하세요.");
        return false;
      }

      const params = {
        frDate: this.frDate.replace(/[^0-9]/gi, ""),
        toDate: this.toDate.replace(/[^0-9]/gi, ""),
        dataType: this.srhType
      }
      this.$emit('getSearch', params);
    },
    chgSrhType(){
      this.dataType = (this.srhType == 'D') ? "요청일자" : "계약안내일자";
    }
  }
}
</script>