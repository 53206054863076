<template>
  <v-col :cols="cols" class="d-flex justify-end align-center pr-5">
    <span class="font-weight-bold col-3">{{ name }}</span>

    <v-text-field
        v-if="modify" style="background-color:white;"
        class="mx-3" v-model="text" :rules="rules"
        full-width hide-details outlined dense
    />
    <v-text-field
        v-else style="background-color:white;"
        class="mx-3" v-model="value" disabled
        full-width hide-details outlined dense
    />
  </v-col>
</template>

<script>
export default {
  props: ["cols", "modify", "rules", "value", "name"],
  data: () => ({ text: "" }),
  watch: {
    text: function (v) {
      this.$emit('update', v);
    },
    modify: function(v) {
      if(v) this.text = this.value;
    }
  }
}
</script>

<style scoped>

</style>