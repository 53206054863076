<template>
  <search-page-layout title="보험료 예시">
    <template v-slot:searchBar>
      <mapping-management-search-bar
          @getSearch = "getSearch"
      />
    </template>

    <template v-slot:table>
      <insurance-fee-table
          :data="data"
          :loading="loading"
          />
    </template>

    <template v-slot:inflowHist>
      <insurance-fee-save
        />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import InsuranceFeeTable from "./components/InsuranceFeeTable";
import InsuranceFeeSave from "./components/InsuranceFeeSave";
import {axiosInstance} from "@/util/AxiosModule";
import MappingManagementSearchBar from "../Product/components/MappingManagement/MappingManagementSearchBar";
export default {
  components: {MappingManagementSearchBar, InsuranceFeeSave, InsuranceFeeTable, SearchPageLayout},
  data:() => ({
    loading: false,
    data: [],
  }),
  methods: {
    async getSearch(params) {
      try {
        const {data} = await axiosInstance.get('/admin/prdata/prPremSampleList', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>