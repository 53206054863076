<template>
  <search-page-layout title="게시글/이벤트 공지">
    <template v-slot:searchBar>
      <BoardSearchBar
          @getboard="getboard"
          @deleteBoard="refreshBoardAPI"
          @regist="dialog=true"
      />
    </template>
    <template v-slot:table>
      <BoardTable
          :boardList="boardList"
          :loading="loading"
          :sparams="sparams"
          @refreshSetting="refreshBoardAPI"
      />
      <BoardWrite
          :dialog="dialog"
          @close="dialog = false"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import BoardTable from "@/pages/Manage/Board/components/BoardTable";
import BoardSearchBar from "@/pages/Manage/Board/components/BoardSearchBar";
import BoardWrite from "@/pages/Manage/Board/components/BoardWrite";

export default {
  components: {
    SearchPageLayout,
    BoardTable,
    BoardSearchBar,
    BoardWrite
  },
  data: () => ({
    dialog: false,
    loading: false,
    boardList: [],
    sparams: {}
  }),
  async created() {
    //	await this.refreshBoardAPI();
  },
  methods: {
    async getboard(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/board/BoardList', {params});
        this.boardList = data;
        this.sparams = params;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async refreshBoardAPI(params) {
      // 갱신 API
      this.sparams = params
      try {
        const {data} = await axiosInstance.get("/admin/board/BoardList", {params});
        this.boardList = data;
      } catch (e) {
        console.log(e)
        alert("컨텐츠 갱신에 실패했습니다.")
      }
    },

    setDialog(value) {
      this.dialog = value
    }
  },

}
</script>