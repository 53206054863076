<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-4 col-12">
          <v-col cols="3">상품군</v-col>
          <v-col cols="7">
            <v-select
                @click="getProdLineNmList"
                @change="changePrProdLine"
                v-model="prProdLineCd"
                :items="prProdLineCdList"
                background-color="white"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-4 col-12">
          <v-col cols="4">적용조건</v-col>
          <v-col cols="8">
            <v-select
                @click="getCondDivList"
                @change="changeCondDiv"
                v-model="condDiv"
                :items="condDivList"
                background-color="white"
                item-text="condDivNm"
                item-value="condDiv"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-3 col-12">
          <v-col cols="6">사용여부</v-col>
          <v-col cols="7">
            <v-select
                v-model="useYn"
                :items="yNlist"
                background-color="white"
                item-text="yNname"
                item-value="yNvalue"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchBtn from "@/components/Search/SearchBtn"
import SearchArea from "@/components/Search/SearchArea"
import ProductRecommend from "@/util/ProductRecommend";

export default {
  components: {SearchBarLayout, SearchBtn, SearchArea},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: '전체', prProdLineCd: ''}
      ],
      condDiv: "",
      condDivList: [
        {condDivNm: '전체', condDiv: ''}
      ],
      useYn: "Y",
      yNlist: [
        {yNname:'Y', yNvalue: 'Y'},
        {yNname:'N', yNvalue: 'N'},
      ]
    }
  },
  methods: {
    changePrProdLine() {
      const prProdLineData = {
        prProdLineCd: "",
        prProdLineNm: "",
      }
      for (let i = 0; i < this.prProdLineCdList.length; i++) {
        if (this.prProdLineCdList[i].prProdLineCd === this.prProdLineCd) {
          prProdLineData.prProdLineCd = this.prProdLineCdList[i].prProdLineCd;
          prProdLineData.prProdLineNm = this.prProdLineCdList[i].prProdLineNm;
        }
      }
      this.$emit("changePrProdLine", prProdLineData);
    },
    changeCondDiv() {
      const condDivData = {
        condDivNm: "",
        condDiv: "",
      }
      for (let i = 0; i < this.condDivList.length; i++) {
        if (this.condDivList[i].condDiv === this.condDiv) {
          condDivData.condDivNm = this.condDivList[i].condDivNm;
          condDivData.condDiv = this.condDivList[i].condDiv;
        }
      }
      this.$emit("changeCondDiv", condDivData);
    },
    // 상품군 조회
    getSearch() {
      const params = {
        prProdLineCd: this.prProdLineCd,
        condDiv: this.condDiv,
        useYn: this.useYn,
      }
      this.$emit('getSearch', params);
    },
    // 상품군리스트 가져오기
    async getProdLineNmList() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
    },
    // 적용조건 가져오기
    async getCondDivList() {
      this.condDivList = await ProductRecommend.getCondDiv();
      this.condDivList.unshift({
        condDivNm: "전체",
        condDiv: ""
      })
    },
  }
}
</script>