<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
      <span
          style="margin-right: 10px; font-weight: bold; font-size: 20px;">
        단위:원</span>
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin-left: 4px;"
          @click="toExcel"
          :disabled="data.length <= 0"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.cvrNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...") }}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.baseAccAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.frAccAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.toAccAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
        <template v-slot:item.srcType="{ value }">
          <div>{{ changedData(value, "1", "원수사") }}</div>
          <div>{{ changedData(value, "2", "수기") }}</div>
        </template>
        <template v-slot:item.cvrSpDiv="{ value }">
          <div>{{ changedData(value, "M1", "기본") }}</div>
          <div>{{ changedData(value, "S1", "특약") }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {PRODUCT_PLAN_MANAGEMENT_DETAIL_MODAL_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [TableMixin, DateMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: PRODUCT_PLAN_MANAGEMENT_DETAIL_MODAL_TABLE,
    }
  },
  methods: {
    clickContents(row) {
      this.$emit("detailRow", row);
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "담보코드": item["cvrCd"],
          "담보명": item["cvrNm"],
          "담보기본특약구분": item["cvrSpDiv"],
          "주력담보여부": item["mainCvrYn"],
          "표시순서": item["dispOrder"],
          "기본안내가입금액": item["baseAccAmt"],
          "FR_가입한도금액": item["frAccAmt"],
          "TO_가입한도금액": item["toAccAmt"],
          "소스구분": item["srcType"],
          "갱신주기": item["rnwCycle"],
          "등록일시": item["createDate"],
        }
        returnData.push(obj);
      }
      const sheetName = `상품플랜 상세`;
      const fileName = `상품플랜 상세`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>