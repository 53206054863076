<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
      <v-btn @click="saveData" style="margin-left: 10px;" class="green white--text theme--light float-right marginBtn">저장</v-btn>
      <v-btn @click="mainSecurity" class="blue white--text theme--light float-right marginBtn">주력담보저장</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12 :key="tagkey">
      <v-data-table
        height="50vh"
        :items="tableData"
        :loading="loading"
        :items-per-page="-1"
        fixed-header calculate-widths
        :headers="headers"
        :item-class="getRed"
        :options="option"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        item-key="index"
        v-model="selectList"
        show-select
        >
        <template v-slot:item.baseCvrAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.minCvrAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.maxCvrAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.cvrCdNo="{ value }">
          <v-select
            :items="SEQList"
            background-color="white"
            dense
            item-text="bname"
            item-value="bvalue"
            :value="value"
            hide-details
            style="font-size: 13.5px; margin-bottom: 7px;"
            />
        </template>

        <template v-slot:item.ageSect1="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
              />
        </template>
        <template v-slot:item.maxCvrAmt1="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.ageSect2="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.maxCvrAmt2="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.ageSect3="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.maxCvrAmt3="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.ageSect4="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.maxCvrAmt4="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.ageSect5="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.maxCvrAmt5="{ value, item, header }">
          <text-input
              :key="`${item.index}-${item.name}`"
              :value="value" :id="item.index"
              :header="header.value"
              @update="changeValue"
          />
        </template>
        <template v-slot:item.mainCvrYn="{ value, item }">
          <v-checkbox
            v-model="item.mainCvrYn"
            ></v-checkbox>
        </template>


        <template v-slot:item.dataDiffYn="{ value, item }">
          <div @click="showDetailModal(item)">{{value}}</div>
        </template>
        <template v-slot:item.lkAppCnt="{ value, item }">
          <div @click="showLinkModal(item)">{{value}}</div>
        </template>
      </v-data-table>
    </v-flex>
    <product-guide-lines-modal
      :is-show="isShow"
      :cvrNm="cvrNm"
      :division="division"
      :detailDto="detailDto"
      :tableList="tableList"
      @close="isShow = false"
      />
  </v-col>
</template>
<script>
import ProductGuideLinesModal from "./ProductGuideLinesModal";
import {axiosInstance} from "@/util/AxiosModule";
import TextInput from "../../Settlement/components/TextInput";
import _ from 'lodash';
import func from "@/util/func";
export default {
  components: {TextInput, ProductGuideLinesModal},
  props: ["data", "loading", "searchParams", "checkList"],
  data: () => ({
    SEQList:[
        {bname: '없음', bvalue:''},
    ],
    option: {
      sortBy: ['isRed'],
      sortDesc: [true],
      itemsPerPage: -1,
      multiSort: true,
    },
    tableData: [],
    isShow: false,
    detailDto:[],
    division:'',
    tagkey:0,
    tableList:[],
    selectList:[],
    cvrNm:'',
    headers: [{text: "표시번호", value: "dispOrder", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "담보명", value: "cvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "주력담보", value: "mainCvrYn", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "성별", value: "sex", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "최소가입년령", value: "frAge", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "최대가입년령", value: "toAge", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "기본가입금액", value: "baseCvrAmt", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "최소가입금액", value: "minCvrAmt", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "최대가입금액", value: "maxCvrAmt", class: "teal lighten-4", align: "center", sortable: false, width: "4vw"},
      {text: "담보군 SEQ", value: "cvrCdNo", class: "indigo lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "나이구간1", value: "ageSect1", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "최대가입금액1", value: "maxCvrAmt1", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "나이구간2", value: "ageSect2", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "최대가입금액2", value: "maxCvrAmt2", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "나이구간3", value: "ageSect3", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "최대가입금액3", value: "maxCvrAmt3", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "나이구간4", value: "ageSect4", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "최대가입금액4", value: "maxCvrAmt4", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "나이구간5", value: "ageSect5", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "최대가입금액5", value: "maxCvrAmt5", class: "indigo lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "확인필요여부", value: "dataDiffYn", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "연계룰적용수", value: "lkAppCnt", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
        ],
  }),
  watch: {
    checkList: function (obj) {
      this.selectList = obj;
    },
    data: function (table) {
      this.tableData = table;
      this.tagkey++;
    }
  },
  created() {
    for (let i = 1; i < 31 ; i++) {
      const objJson = new Object();
      objJson.bvalue = func.lpad(i, 3, "0");
      objJson.bname = func.lpad(i, 3, "0");
      this.SEQList.push(objJson);
    }
  },
  methods: {
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async mainSecurity() {
      let prProdMainCvrTbDto = [];
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].mainCvrYn === true) {
          this.tableData[i].useYn = 'Y';
        } else {
          this.tableData[i].useYn = 'N';
        }
        prProdMainCvrTbDto.push(this.tableData[i]);
      }
      try {
        const {data} = await axiosInstance.post('/admin/prdata/prProdMainCvrSave', prProdMainCvrTbDto);
        alert(data + "개의 데이터가 저장됐습니다.");
      } catch (e) {
        console.log(e);
      }
    },
    async saveData() {
      const prTakeBaseTbList = this.selectList;
      try {
        const {data} = await axiosInstance.post('/admin/prdata/prTakeBaseSave', prTakeBaseTbList);
        alert(data + "개의 데이터가 저장됐습니다.");
        this.selectList=[];
      } catch (e) {
        console.log(e);
      }
    },
    changeValue(text, id, header) {
      //담보군SEQ, 주력담보는 컬럼 값 오면 수정

      const item = _.find(this.tableData, {index: id});
      if (header === 'ageSect1') {
        if (item.frAge <= text && text <= item.toAge) {
          item[header] = text;
        } else {
          alert("최소, 최대 나이를 확인해주세요.");
          item[header] = item.ageSect1;
        }
      } else if (header === 'maxCvrAmt1') {
        if (item.minCvrAmt <= text && text <= item.maxCvrAmt) {
          item[header] = text;
        } else {
          alert("최소, 최대 금액을 확인해주세요.");
          item[header] = item.maxCvrAmt1;
        }
      } else if (header === 'ageSect2') {
        if (item.ageSect1 < text && text <= item.toAge && item.ageSect1 !== 0 || text === 0) {
          item[header] = text;
        } else {
          alert("최소, 최대 나이를 확인해주세요.");
          item[header] = item.ageSect2;
        }
      } else if (header === 'maxCvrAmt2') {
        if (item.maxCvrAmt1 < text && text <= item.maxCvrAmt && item.maxCvrAmt1 !== 0 || text === 0) {
          item[header] = text;
        } else {
          alert("최소, 최대 금액을 확인해주세요.");
          item[header] = item.maxCvrAmt2;
        }
      } else if (header === 'ageSect3') {
        if (item.ageSect2 < text && text <= item.toAge && item.ageSect1 !== 0 && item.ageSect2 !==0 || text === 0) {
          item[header] = text;
        } else {
          alert("최소, 최대 나이를 확인해주세요.");
          item[header] = item.ageSect3;
        }
      } else if (header === 'maxCvrAmt3') {
        if (item.maxCvrAmt2 < text && text <= item.maxCvrAmt && item.maxCvrAmt1 !== 0 && item.maxCvrAmt2 !==0 || text === 0 ) {
          item[header] = text;
        } else {
          alert("최소, 최대 금액을 확인해주세요.");
          item[header] = item.maxCvrAmt3;
        }
      } else if (header === 'ageSect4') {
        if (item.ageSect3 < text && text <= item.toAge && item.ageSect1 !==0 && item.ageSect2 !== 0 && item.ageSect3 !==0 || text === 0) {
          item[header] = text;
        } else {
          alert("최소, 최대 나이를 확인해주세요.");
          item[header] = item.ageSect4;
        }
      } else if (header === 'maxCvrAmt4') {
        if (item.maxCvrAmt3 < text && text <= item.maxCvrAmt && item.maxCvrAmt1 !== 0 && item.maxCvrAmt2 !== 0 && item.maxCvrAmt3 !== 0 || text === 0) {
          item[header] = text;
        } else {
          alert("최소, 최대 금액을 확인해주세요.");
          item[header] = item.maxCvrAmt4;
        }
      } else if (header === 'ageSect5') {
        if (item.ageSect4 < text && text <= item.toAge && item.ageSect1 !== 0 && item.ageSect2 !== 0 && item.ageSect3 && item.ageSect4 !== 0 || text === 0) {
          item[header] = text;
        } else {
          alert("최소, 최대 나이를 확인해주세요.");
          item[header] = item.ageSect5;
        }
      } else if (header === 'maxCvrAmt5') {
        if (item.maxCvrAmt4 < text && text <= item.maxCvrAmt && item.maxCvrAmt1 !== 0 && item.maxCvrAmt2 !== 0 && item.maxCvrAmt3 !== 0 && item.maxCvrAmt4 !== 0 || text === 0) {
          item[header] = text;
        } else {
          alert("최소, 최대 금액을 확인해주세요.");
          item[header] = item.maxCvrAmt5;
        }
      }

    },
    getRed(item) {
      return item.selCvrYn === 'N' ? 'isRed' : '';
    },
    showDetailModal(item) {
      this.division = 'detail'
      this.isShow = true;
      console.log("item", item);
      this.getList(item);
    },
    showLinkModal(item) {
      this.division = 'link'
      this.isShow = true;
      this.getLinkList(item);
    },
    async getList(item) {
      this.cvrNm = item.cvrNm;
      const params = {
        insCd : this.searchParams.insCd,
        prCd: this.searchParams.prCd,
        planCd: this.searchParams.planCd,
        cvrCd: item.cvrCd,
        sex:item.sex
      }
      try {
        const {data} = await axiosInstance.get('/admin/prdata/prTakeCvrTb', {params});
        this.tableList = data;
      } catch (e) {
        console.log(e);
      }
      this.detailDto = params;
    },
    async getLinkList(item) {
      this.cvrNm = item.cvrNm;
      const params = {
        insCd : this.searchParams.insCd,
        prCd: this.searchParams.prCd,
        planCd: this.searchParams.planCd,
        cvrCd: item.cvrCd,
        sex:item.sex
      }
      try {
        const {data} = await axiosInstance.get('/admin/prdata/prTakeLkCvrTb', {params});
        for (let i = 0; i < data.length; i++) {
          data[i].createDate = data[i].createDate.substr(0, 19).replace('T', ' ');
        }
        this.tableList = data;
      } catch (e) {
        console.log(e);
      }
      this.detailDto = params;
    }
  }
}
</script>
<style scoped>
.v-data-table::v-deep td {
  white-space: normal!important;
  word-break: keep-all;
}
</style>