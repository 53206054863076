var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-2 px-2",attrs:{"cols":"12"}},[_c('div',[_c('v-btn',{staticClass:"float-right grey white--text",staticStyle:{"margin":"10px 5px 0 0"},attrs:{"disabled":!_vm.hasTableData},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")]),_c('search-area',[_c('v-col',{staticClass:"d-flex align-center col-xl-2 col-lg-2 col-md-6 col-12"},[_c('div',{staticClass:"monthlyPremText"},[_c('span',[_vm._v("보험료계: ")]),_c('span',{staticClass:"jc-fe"},[_vm._v(" "+_vm._s(_vm.inputComma(_vm.monthlyPremSum)+ "원")+" ")])])])],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[(_vm.isWholeLife)?_c('v-data-table',{attrs:{"height":"40vh","items":_vm.data,"loading":_vm.loading,"headers":_vm.getHeader(_vm.headers),"fixed-header":"","calculate-widths":"","footer-props":{'items-per-page-options': [10, 30, 50, -1]},"items-per-page":-1},scopedSlots:_vm._u([{key:"item.sex",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "남자")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "여자")))])]}},{key:"item.cvrDiv",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "의무가입")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "선택특약")))])]}},{key:"item.accAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.monthlyPrem",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.refund",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.cvrNm",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.textLengthOverCut(value, 30, "...")))])]}}],null,false,1243532361)}):_c('v-data-table',{attrs:{"height":"40vh","items":_vm.data,"loading":_vm.loading,"headers":_vm.getHeader(_vm.headers),"fixed-header":"","calculate-widths":"","footer-props":{'items-per-page-options': [10, 30, 50, -1]},"items-per-page":-1},scopedSlots:_vm._u([{key:"item.sex",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "남자")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "여자")))])]}},{key:"item.cvrDiv",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "의무가입")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "선택특약")))])]}},{key:"item.accAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.monthlyPrem",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.cvrNm",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.textLengthOverCut(value, 30, "...")))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }