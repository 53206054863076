<template>
  <v-col cols="12" class="px-2 py-5">
    <div class="table-header mb-2">
      <span class="text-h6">
        목록 (총 {{ data.length }}개 | 정상리워드합 {{ inputComma(nrRewardSum) }}원 | 환수리워드합 {{ inputComma(rrRewardSum) }}원)
      </span>
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin: 0 3px 0 3px;"
          @click="toExcel"
          :disabled="data.length < 1"
      >엑셀다운로드</v-btn>
    </div>

    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          v-model="selectData"
          :items="data"
          :item-class="getRed"
          item-key="index" show-select
          :loading="loading"
          loading-text="조회중입니다. 잠시만 기다려주세요"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.recipientTelNo="{ value }">
          <div class="jc-fe">{{ getTelNum(value) }}</div>
        </template>

        <template v-slot:item.customerTelNum="{ value }">
          <div class="jc-fe">{{ getTelNum(value) }}</div>
        </template>

        <template v-slot:item.contractPremAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.rewardAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.contractDt="{ value }">
          <div class="jc-fe">{{ getDateDashFormat(value) }}</div>
        </template>

        <template v-slot:item.expiryDt="{ value }">
          <div class="jc-fe">{{ getDateDashFormat(value) }}</div>
        </template>

        <template v-slot:item.payDueDt="{ value }">
          <div class="jc-fe">{{ getDateDashFormat(value) }}</div>
        </template>

<!--        <template v-slot:item.createDate="{ value }">-->
<!--          <div class="jc-fe">{{ getDateDashFormat(value) }}</div>-->
<!--        </template>-->

<!--        <template v-slot:item.payDueDt="{ value }">-->
<!--          <div class="jc-fe">{{ getDateDashFormat(value) }}</div>-->
<!--        </template>-->

<!--        <template v-slot:item.payCompleteDt="{ value }">-->
<!--          <div class="jc-fe">{{ getDateDashFormat(value) }}</div>-->
<!--        </template>-->

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {SETTLEMENT_EARLY_TABLE} from "@/util/TableHeaders";
import DateMixin from "@/mixin/DateMixin";
import _ from "lodash";
import func from "@/util/func";

export default {
  name: "RealtimeContractTable",
  mixins: [TableMixin, DateMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: SETTLEMENT_EARLY_TABLE,
      isShow: false,
      isModify: false,
      modalData: {},
      selectData: [],
      isModifyModal: false,
      nrRewardSum: 0,
      rrRewardSum: 0,
      option: {
        sortBy: ['isRed']
      },
    }
  },
  watch: {
    data(value) {
      this.nrRewardSum = 0;
      this.rrRewardSum = 0;
      if (_.isEmpty(value)) {
        this.selectData = [];
      }
      for (let i in value) {
        if (value[i].stateCd === "RR") {
          this.rrRewardSum += value[i].rewardAmt;
        } else if (value[i].stateCd === "RC") {
          this.rrRewardSum += value[i].rewardAmt;
        } else {
          this.nrRewardSum += value[i].rewardAmt;
        }
      }
    },
    selectData(value) {
      this.$emit("selectList", value);
    }
  },
  methods: {
    getRed(item) {
      return item.payProYn === 'N' ? 'isRed' : '';
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "상태": item["stateNm"],
          "회원명": item["recipientNm"],
          "회원전화번호": item["recipientTelNo"],
          "고객명": item["customerNm"],
          "고객전화번호": item["customerTelNum"],
          "차량번호": item["customerCarNo"],
          "보험사": item["contractInsurer"],
          "계약보험료": item["contractPremAmt"],
          "리워드": item["rewardAmt"],
          "계약일자": item["contractDt"],
          "만기일자": item["expiryDt"],
          "계약채널": item["joinChannel"],
          "설계지원번호": item["designSupId"],
          "지급처리가능": item["payProYn"],
          "입력일자": item["createDate"],
          "지급예정일자": item["payDueDt"],
          "지급일자": item["payCompleteDt"],
          "이륜차여부": item["twoWheelerYn"],
          "법인계약여부": item["corpContYn"]
        }
        returnData.push(obj);
      }
      const sheetName = `조기정산 관리`;
      const fileName = `조기정산 관리`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>

