<template>
  <search-page-layout title="상품추천 게시판">
    <template v-slot:searchBar>
      <product-recommend-board-search-bar
          @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <product-recommend-board-table
        :data="data"
        :loading="loading"
      />
    </template>
  </search-page-layout>
</template>

<script>
import DateMixin from "@/mixin/DateMixin";
import ProductRecommendBoardSearchBar
  from "@/pages/Product/components/ProductRecommendBoard/ProductRecommendBoardSearchBar";
import TableMixin from "@/mixin/TableMixin";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductRecommendBoardTable from "@/pages/Product/components/ProductRecommendBoard/ProductRecommendBoardTable";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  name: "ProductRecommendBoard",
  mixins: [DateMixin, TableMixin],
  components: {ProductRecommendBoardTable, SearchPageLayout, ProductRecommendBoardSearchBar},
  data() {
    return {
      data: [],
      loading: false
    }
  },
  methods: {
    inputComma(num) {
      console.log(num);
    },
    async getSearch(params) {
      this.loading = true;

      const { data } = await axiosInstance.get('/admin/prdata/getPrBoardList', { params });
      this.data = data;
      this.loading = false;
    },
  }
}
</script>
