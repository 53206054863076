<template>
  <search-page-layout title="보험료 산출이력 조회">

    <template v-slot:searchBar>
      <calc-hist-search-bar
          :name="name"
          @search="getCalcList"
      />
    </template>

    <template v-slot:table>
      <calc-hist-table
        :carInfos="carInfos"
        :carShownInfos="carShownInfos"
        :insuranceAmts="insuranceAmts"
        :loading="loading"
      />
    </template>

  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import CalcHistSearchBar from "@/pages/User/components/CalcHistSearch/CalcHistSearchBar";
import CalcHistTable from "@/pages/User/components/CalcHistSearch/CalcHistTable";

var mixin = {
  methods: {
    code2value(carInfos) {
      var keys = Object.keys(carInfos)
      for(let i in keys) {
        if (Object.keys(this.codefParameter).includes(keys[i])) {
          if (Object.keys(this.codefParameter[keys[i]]).includes('list')){
            for(let j in this.codefParameter[keys[i]]["list"]) {
              if (this.codefParameter[keys[i]]["list"][j]["cd"] == carInfos[keys[i]]) {
              
                carInfos[keys[i]] = this.codefParameter[keys[i]]["list"][j]["nm"]
              }
            }
          }
        }
      }
      return carInfos;
    },
    //보험료 리스트에서 특별할인 json array를 string으로 변환
    specList2Str(insuranceAmts) {

      for(let i in insuranceAmts) {
        insuranceAmts[i]["resTotalPremium"] = this.digitRepresentation(insuranceAmts[i]["resTotalPremium"])
        insuranceAmts[i]["resMileageFirstAmt"] = this.digitRepresentation(insuranceAmts[i]["resMileageFirstAmt"])
        insuranceAmts[i]["resMileageAfterAmt"] = this.digitRepresentation(insuranceAmts[i]["resMileageAfterAmt"])
        if(insuranceAmts[i]['resSpecialDcList'].length != 0) {
          let SpecialDcList = ''
          for (let j in insuranceAmts[i]['resSpecialDcList']) {
            SpecialDcList += insuranceAmts[i]['resSpecialDcList'][j]['resSpecialDcName'] +" "
          }
          insuranceAmts[i]['resSpecialDcList'] = SpecialDcList
        } else {
          insuranceAmts[i]['resSpecialDcList'] = ''
        }
      }
      return insuranceAmts
    },
    digitRepresentation(number) {
      if(number.trim() != "")
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
      else {
        return ""
      }
    }

  }

}
export default {
  mixins: [mixin],
  props: ["name"],
  components: {
    CalcHistTable,
    CalcHistSearchBar,
    SearchPageLayout
  },
  data: () => ({
    carInfos: [],
    carShownInfos: [],
    insuranceAmts: [],
    codefParameter: {},
    loading: false,
    
  }),
  async created() {

      const {data} = await axiosInstance.get('/public/code/json', {
				params: {
					codeMasterId: "CODEF",
					code: "REQUEST_INFO"
				}
			});
      this.codefParameter = data
  },
  methods: {
    async getCalcList(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.post('/admin/operation/ins-calc-hist', params);
        var carInfos = []
        var insuranceAmts = []
        var carShownInfos = []
        for(var i = 0; i < data.length; i++) {
          var obj = this.code2value(data[i]["carInfo"])
          carInfos.push(obj);
          // 최저 금액, 최저금액 보험사 정보 추가
          obj["resCompanyNmCode"] = data[i]["insuranceAmts"][0]["resCompanyNmCode"]
          obj["resTotalPremium"] = this.digitRepresentation(data[i]["insuranceAmts"][0]["resTotalPremium"]);
          carShownInfos.push(obj)    
          insuranceAmts.push(this.specList2Str(data[i]["insuranceAmts"]));

        }
        this.carInfos = carInfos;
        this.insuranceAmts = insuranceAmts;
        this.carShownInfos = carShownInfos;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
      
    },
    
  },
  computed: {
    
  } 
  
}
</script>