<template>
  <search-page-layout title="정산자료 검수">
    <template v-slot:searchBar>
      <settlement-search-bar
          :is-uploaded="isUploaded"
          @upload="upload"
          @setSettlementDate="setSettlementDate"
          @initData="initData"
      />
    </template>

    <template v-slot:table>
      <settlement-table
          :items="tableData"
          :is-uploaded="isUploaded"
          :is-verified="isVerified"
          :settlementDate="settlementDate"
          :header="isVerified ? VERIFIED_HEADER : SETTLEMENT_HEADER"
          @update="update"
          @verified="verified"
          @changeTable="changeTable"
          @deleteData="deleteData"
          :inflowRowItem="inflowRowItem"
      />

    </template>

    <template v-slot:inflowHist >
      <inflow-hist-search
          @getRowItem="getRowItem"
      />
    </template>
  </search-page-layout>

</template>


<script>
import _ from 'lodash';
import {SETTLEMENT_HEADER, VERIFIED_HEADER} from '@/util/TableHeaders';

import SearchPageLayout from "@/layouts/SearchPageLayout";
import InflowHistSearch from "@/pages/User/InflowHistSearch";
import SettlementTable from "@/pages/Settlement/components/Settlement/SettlementTable";
import SettlementSearchBar from "@/pages/Settlement/components/Settlement/SettlementSearchBar";


export default {
  components: {SettlementTable, SettlementSearchBar, SearchPageLayout, InflowHistSearch},
  data: () => ({
    isUploaded: false,
    isVerified: false,
    tableData: [],
    SETTLEMENT_HEADER: SETTLEMENT_HEADER,
    VERIFIED_HEADER: VERIFIED_HEADER,
    settlementDate: "",
    inflowRowItem:{}
  }),
  methods: {
    setSettlementDate(value) {
      this.settlementDate = value;
    },
    update(text, id, header) {
      const item = _.find(this.tableData, {index: id});
      item[header] = text;
      if (item.isRed) this.tableData = _.cloneDeep(this.tableData);
    },

    async changeTable(newTableData) {
      this.tableData = newTableData;
    },
    verified(newTableData) {
      this.changeTable(newTableData);
      this.isVerified = true;
    },
    upload(newTableData) {
      this.changeTable(newTableData);
      this.isUploaded = true;
    },
    deleteData(data) {
      this.tableData = _.remove(this.tableData, x => !data.includes(x.index));
      if(this.tableData.length < 1) {
        this.initData();
      }
    },


    // 전체 데이터 초기화
    initData() {
      this.isUploaded = false;
      this.isVerified = false;
      this.loading = false;
      this.tableData = [];
      this.headers = this.SETTLEMENT_HEADER;
    },
    getRowItem(item){
      this.inflowRowItem = item;
    }
  }
}
</script>