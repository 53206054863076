<template>
  <search-page-layout title="엑셀자료 추출이력">
    <template v-slot:searchBar>
      <excel-extract-hist-search-bar
          @getXlsExtractHist="getXlsExtractHist"
      />
    </template>
    <template v-slot:table>
      <excel-extract-hist-table
          :data="tableData"
          :loading="loading"
          @search="refreshTableData"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ExcelExtractHistSearchBar from "@/pages/Stat/components/ExcelExtractHist/ExcelExtractHistSearchBar";
import ExcelExtractHistTable from "@/pages/Stat/components/ExcelExtractHist/ExcelExtractHistTable";

export default {
  name: "ExcelExtractHist",
  components: {
    ExcelExtractHistTable,
    ExcelExtractHistSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    tableData: [],
    searchHistParams : {}
  }),

  methods: {
    // 조회
    async getXlsExtractHist(params) {
      this.loading = true;
      this.searchHistParams = params; // 새로고침을 위함

      try {
        const {data} = await axiosInstance.post('/admin/operation/getXlsExtractHist',  params );
        this.tableData = data.map(item => {
          return { ...item, selectedYn: false };
        });
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    // 승인/거절 이후 다시 조회
    refreshTableData(){
      this.getXlsExtractHist(this.searchHistParams);
    }


  }
}
</script>


