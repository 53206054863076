<template>
  <search-page-layout title="상품가이드">

    <template v-slot:searchBar>
      <product-guide-search-bar
        @getSearch = "getSearch"
        />
    </template>

    <template v-slot:table>
      <product-guide-table
        :tableData="data"
        :loading="loading"
        @update="update"
        />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import ProductGuideSearchBar from "./components/ProductGuideSearchBar";
import ProductGuideTable from "./components/ProductGuideTable";
export default {
  components: {ProductGuideTable, ProductGuideSearchBar, SearchPageLayout},
  data:() => ({
    loading: false,
    data: [],
    updatePatams:[]
  }),
  methods: {
    async getSearch(params) {
      this.updatePatams = params;
      this.loading = true;
      try {
        const { data } = await axiosInstance.get('/admin/prdata/prGuide', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    update() {
      this.getSearch(this.updatePatams);
    }
  }
}
</script>