<template>
  <v-app id="inspire">
      <router-view v-if="this.$store.getters['login/memberRole'] === 'ADMIN'" />
      <router-view v-else-if="this.$store.getters['login/memberRole'] === 'MANAGER'" />
      <Login v-else/>
  </v-app>
</template>

<script>
import Login from "@/pages/Login/Login";
export default {
  name: 'App',
  components: {Login},
}
</script>

<style>
/* 테이블, 테이블 footer 구분을 위한 border 처리 */
.w100h100 {width:100%; height:100%;}
.cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-fe {
  justify-content: flex-end!important;
  text-align: right;
}
/* 테이블의 맨 앞부분 고정을 위한 코드 (IE는 sticky 지원하지 않습니다.) */
tbody > tr:last-child > td{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
th {
  background-color: #EEEEEE!important;
  border-right:1px solid rgba(0,0,0,0.1);
}
td {
  white-space: nowrap!important;
}
.modalBackground {
  background-color: #f2f2f2!important;
}
</style>


