<template>
  <custom-layout title="암복호화 관리" subtitle="암호화 / 복호화 관리페이지 입니다.">
    <v-sheet class="main-sheet" max-width="100%">
      <v-card width="40vw" max-width="500" elevation="5" :loading="loading">
        <template slot="progress">
          <v-progress-linear
            color="blue darken-2"
            height="5"
            indeterminate />
        </template>
        <v-card-title class="card-title">
          <h3>입력값</h3>
          <v-chip-group
              v-model="wkType" class="chip-list" active-class="primary --text" column @change="changeWkType">
            <v-chip v-for="(item, index) in wkTypeList" :key="index">
              {{ item.wkTypeNm }}
            </v-chip>
          </v-chip-group>
        </v-card-title>

        <v-card-text>
          <v-row class="input-container">
            <v-col cols="12">
              <v-text-field
                  label="입력"
                  v-model="inputData" outlined placeholder="입력값을 입력해주세요" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="card-footer">
          <v-btn
              class="button-title-reset"
              color="red darken-1" text outlined rounded @click="doReset">
            <span class="reset-title">초기화</span>
          </v-btn>
        </v-card-actions>
      </v-card>

      <span class="arrow-right-container">
        <span class="arrow-right" v-if="isSearch === true">→</span>
      </span>

      <v-card width="40vw" max-width="500" elevation="5">
        <v-card-title  class="card-title">
          <h3>결과값</h3>
        </v-card-title>

        <v-card-text>
          <v-row class="input-container">
            <v-col v-if="isSearch === true" cols="12">
              <v-text-field label="출력" v-model="resultData"  outlined />
            </v-col>

            <v-col v-else>
              <span class="before-search">데이터를 조회해주세요.</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-sheet>
  </custom-layout>
</template>

<script>
import CustomLayout from "@/layouts/CustomLayout";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  name: "EncryDecryption",
  components: {CustomLayout},
  data() {
    return {
      loading: false,
      isSearch: false,
      wkType: -1,
      selectWkType: "",
      wkTypeList: [
        { wkTypeNm: "암호화", wkType: "enc" },
        { wkTypeNm: "복호화", wkType: "dec" },
        { wkTypeNm: "가명화", wkType: "fake" },
        { wkTypeNm: "실명화", wkType: "real" },
      ],
      inputData: "",
      paramsData: {},
      resultData: "",
    }
  },
  methods: {
    async getSearch() {
      const params = {
        wkType: this.selectWkType,
        value: this.inputData
      };
      this.paramsData = params;
      this.loading = true;
      try {
        this.isSearch = true;
        const { data } = await axiosInstance.get("/admin/operation/get-encrypt-data", { params });
        this.resultData = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    changeWkType(index) {
      if (this.wkType === undefined) {
        this.wkType = -1;
      } else {
        this.selectWkType = this.wkTypeList[index].wkType;
        this.resultData = "";
        this.getSearch();
      }
    },
    doReset() {
      this.isSearch = false;
      this.inputData = "";
      this.resultData = "";
      this.wkType = -1;
    }
  }
}
</script>

<style scoped>
.main-sheet {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.card-title {
  display: flex;
  height: 80px;
}

.chip-list {
  padding-left: 15px;
}

.input-container {
  border-top: 1px solid #d5d5d5;
  display: flex;
  justify-content: center;
}

.input-container .col {
  padding-top: 30px;
}

.before-search {
  display: flex;
  justify-content: center;
}

/* 결과 화살표 */
.arrow-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.arrow-right {
  font-size: 50px;
}

/* 조회 및 초기화 버튼 */
.card-footer {
  justify-content: center;
}

.card-footer .v-btn.button-title-reset {
  border: 2px solid #ff3d00;
  font-weight: bolder;
}

.card-footer .v-btn.button-title-reset:hover {
  background-color: #ff3d00;
}

.card-footer .v-btn:hover .reset-title {
  color: #fff;
}
</style>