<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="headers"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.totPrem="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.minTotPrem="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.createDate="{ value }">
          <div>{{ getDateNoSecFormat(value) }}</div>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>
<script>
import DateMixin from "@/mixin/DateMixin";
import TableMixin from "@/mixin/TableMixin";

export default {
  mixins: [TableMixin, DateMixin],
  props: ["data", "loading"],
  data: () => ({
    headers: [
      {text: "상품군", value: "prProdLineNm", class: "lime lighten-4", align: "center", sortable: false},
      {text: "보험사", value: "insCd", class: "lime lighten-4", align: "center", sortable: false},
      {text: "상품코드", value: "prCd", class: "lime lighten-4", align: "center", sortable: false},
      {text: "상품명", value: "prNm", class: "lime lighten-4", align: "center", sortable: false},
      {text: "플랜코드", value: "planCd", class: "teal lighten-4", align: "center", sortable: false},
      {text: "플랜명", value: "planNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "성별", value: "sex", class: "teal lighten-4", align: "center", sortable: false},
      {text: "연령구분", value: "ageDiv", class: "teal lighten-4", align: "center", sortable: false},
      {text: "총보험료", value: "totPrem", class: "teal lighten-4", align: "center", sortable: false},
      {text: "최소총보험료", value: "minTotPrem", class: "lime lighten-4", align: "center", sortable: false},
      {text: "등록일시", value: "createDate", class: "lime lighten-4", align: "center", sortable: false},
    ],
  }),
  methods: {
  }
}

</script>