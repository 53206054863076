<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-card max-width="95vw" max-height="105vh" min-width="95vw" class="overflow-y-auto modalBackground">
      <v-card-title class="headline blue-grey--text">
        <span class="font-weight-bold">설계지원 요청 입력</span>
      </v-card-title>
      <v-card-text>
        <v-row class="px-6">
          <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
            <v-col cols="3">FA명</v-col>
            <v-col cols="9">
              <v-text-field
                  v-model="memberNm"
                  outlined dense hide-details
                  background-color="white"
                  placeholder="FA명을 입력해주세요."
                  @keypress.enter="getUserSearch"
              />
            </v-col>
          </v-col>
          <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
            <v-btn
                color="blue darken-1"
                class="white--text col-2 float-right"
                @click="getUserSearch"
            >조회</v-btn>
          </v-col>

        </v-row>
        <v-data-table
          :items="dataList"
          height="20vh"
          :loading="loading"
          :headers="headers"
          :items-per-page="-1"
          @click:row="clickFA"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          dense fixed-header calculate-widths disable-sort
          >
          <template v-slot:item.telNum="{ value }">
            {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
          </template>
        </v-data-table>
        <v-card-text v-if="showSave">
          <v-sheet>
            <v-row class="px-6">
              <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-6 col-12">
                <v-col cols="4">FA명</v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="DesignSupTbDto.memberNm"
                      outlined dense hide-details
                      background-color="white"
                      :disabled="true"
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
                <v-col cols="2">FA ID</v-col>
                <v-col cols="10">
                  <v-text-field
                      v-model="DesignSupTbDto.memberId"
                      outlined dense hide-details
                      background-color="white"
                      :disabled="true"
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">FA 연락처</v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="DesignSupTbDto.telNum"
                      outlined dense hide-details
                      background-color="white"
                      :disabled="true"
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">생년월일</v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="DesignSupTbDto.birthday"
                      outlined dense hide-details
                      background-color="white"
                      :disabled="true"
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-6 col-12">
                <v-col cols="4">고객명</v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="DesignSupTbDto.customerNm"
                      outlined dense hide-details
                      background-color="white"
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
                <v-col cols="4">고객연락처</v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="DesignSupTbDto.customerTelNum"
                      outlined dense hide-details
                      background-color="white"
                      placeholder="010-0000-0000"
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">차량번호</v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="DesignSupTbDto.customerCarNo"
                      outlined dense hide-details
                      background-color="white"
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">전계약사</v-col>
                <v-col cols="8">
                  <v-select
                      v-model="DesignSupTbDto.preInsurerCd"
                      :items="preInsurerCdList"
                      item-text="aname"
                      label="선택"
                      item-value="avalue"
                      background-color="white"
                      hide-details outlined dense
                  />
                </v-col>
              </v-col>
              <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
                <v-col cols="4">유입채널</v-col>
                <v-col cols="8">
                  <v-select
                      v-model="DesignSupTbDto.channel"
                      :items="channelList"
                      item-text="name"
                      item-value="value"
                      label="선택"
                      background-color="white"
                      hide-details outlined dense
                  />
                </v-col>
              </v-col>

              <v-col class="d-flex flex-column col-12">
                <v-textarea
                    v-model="DesignSupTbDto.memberMemo"
                    background-color="white"
                    outlined dense
                    placeholder="메모 내용을 등록해주세요"
                    rows="2"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              v-if="showSave"
              color="blue darken-3"
              class="white--text col-1"
              @click="save"
          >
            저장
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-1"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <!-- 요청입력 저장완료 팝업 -->
    <show-alert-popup v-if="saveSuccess === true">
      <template v-slot:card-text>
        <span class="card-text">요청하신 입력이 저장되었습니다.</span>
      </template>
      <template v-slot:card-button>
        <v-btn
            class="check-button"
            color="blue darken-1" text outlined rounded
            @keydown.space="close"
            v-focus
            @click="close">
          <span class="check-title">확인</span>
        </v-btn>
      </template>
    </show-alert-popup>

  </v-overlay>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
import ShowAlertPopup from "@/components/Form/ShowAlertPopup";

export default {
  props: ["isShow"],
  components: {ShowAlertPopup},
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      }
    }
  },
  async created() {
    const { data } = await axiosInstance.get("/public/prdata/code-list", {
      params: {
        cdMasterId: "CS_CHANNEL",
        insCd: "",
        useYn: ""
      }
    });
    for (let i in data) {
      this.channelList.push({
        name: data[i].value,
        value: data[i].value
      });
    }
  },
  data:() => ({
    memberNm:'',
    dataList:[],
    loading: false,
    headers: [
      {text: "FA명", value: "memberNm", class: "indigo lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "휴대폰번호", value: "telNum", class: "indigo lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "이메일", value: "email", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "소속", value: "joinType", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "생년월일", value: "birthday", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "ID", value: "memberId", class: "indigo lighten-4", align: "center", sortable: false, width: "8vw"},
    ],
    showSave: false,
    DesignSupTbDto: {
      memberId:'',
      memberNm:'',
      telNum:'',
      birthday:'',
      customerNm:'',
      customerTelNum:'',
      customerCarNo:'',
      preInsurerCd:'',
      channel: '',
      memberMemo:''
    },
    preInsurerCdList: [
      {aname: '삼성화재', avalue:'SS'},
      {aname: '현대해상', avalue:'HD'},
      {aname: 'DB손보', avalue:'DB'},
      {aname: 'KB손보', avalue:'KB'},
      {aname: '메리츠화재', avalue:'MZ'},
      {aname: '한화손보', avalue:'HW'},
      {aname: '흥국화재', avalue:'HK'},
      {aname: '롯데손보', avalue:'LO'},
      {aname: 'MG손보', avalue:'MG'},
      {aname: '농협손보', avalue:'NH'},
      {aname: '하나손보', avalue:'HN'},
      {aname: 'AXA손보', avalue:'AXA'},
      {aname: '캐롯손보', avalue:'CR'},
      {aname: '구매예정', avalue:'NEW'},
    ],
    channelList: [],
    saveSuccess: false
  }),
  methods: {
    async getUserSearch() {
      this.loading = true;

      const params = {
        searchType: 'NORMAL',
        memberNm: this.memberNm,
      };

      try {
        const {data} = await axiosInstance.get('/admin/operation/member', {params});

        this.dataList = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    clickFA(row) {
      this.showSave = true;
      this.DesignSupTbDto.memberId = row.memberId;
      this.DesignSupTbDto.memberNm = row.memberNm;
      this.DesignSupTbDto.telNum = row.telNum;
      this.DesignSupTbDto.birthday = row.birthday;
    },
    close() {
      this.dataList = [];
      this.memberNm = '';
      this.showSave = false;
      this.DesignSupTbDto = [];
      this.saveSuccess = false;
      this.$emit('close');
    },
    async save() {
      const DesignSupTbDto = {
        regId: store.getters['login/memberId'],
        memberId: this.DesignSupTbDto.memberId,
        memberNm: this.DesignSupTbDto.memberNm,
        telNum: this.DesignSupTbDto.telNum,
        birthday: this.DesignSupTbDto.birthday,
        customerNm: this.DesignSupTbDto.customerNm,
        customerTelNum: this.DesignSupTbDto.customerTelNum,
        customerCarNo: this.DesignSupTbDto.customerCarNo,
        preInsurerCd: this.DesignSupTbDto.preInsurerCd,
        channel: this.DesignSupTbDto.channel,
        memberMemo: this.DesignSupTbDto.memberMemo,
        inputSrc: 'ADMIN'
      };

      // 데이터 공백제거
      for (const key in DesignSupTbDto) {
        if (key !== "memberMemo" && typeof DesignSupTbDto[key] === "string") {
          DesignSupTbDto[key] = DesignSupTbDto[key].replace(/\s/g, '');
        }
      }
      if (confirm("저장하시겠습니까?")) {
        try {
          const {data} = await axiosInstance.post('/public/operation/regDesignSup', DesignSupTbDto);
          console.log(data);
          this.saveSuccess = true;
          this.$emit('updateSetting');
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
}
</script>

<style scoped>
span.card-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
}

.v-btn.check-button {
  border: 2px solid #1772C7;
  padding: 5px 10px;
  font-weight: bolder;
}

.v-btn .check-title {
  font-size: 20px;
}

.v-btn.check-button:hover {
  background-color: #1772C7;
}

.v-btn:hover .check-title {
  color: #fff;
}
</style>