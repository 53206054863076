<template>
  <v-col cols="12" class="py-5">
    <v-flex xs12 sm12 lg12 xl12>
      <v-data-table
        height="30vh"
        :headers="getHeader(headers)"
        :items="data"
        :loading="loading"
        :items-per-page="-1"
        @click:row="getRowData"
      >
        <template v-slot:item.priQuestion="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 30, "...") }}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.stDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>

        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {INS_AI_CHAT_MANAGE_TABLE} from "@/util/TableHeaders";
import DateMixin from "@/mixin/DateMixin";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: INS_AI_CHAT_MANAGE_TABLE,
      isShowMain: false,
      rowData: {}
    }
  },
  methods: {
    getRowData(data) {
      this.$emit("rowData", data);
    }
  }
}
</script>