<template>
<!-- by sk.jeon -->
  <div>
    <search-area>
      <div class="col col-10">
        <date-month-bar @search="dateEvent"/>
      </div>
      <div class="ml-auto pb-1 d-flex align-end">
        <v-btn
            elevation="2"
            class="blue white--text"
            style="margin-right: 18px;"
            @click="showModal">준회원 목록
        </v-btn>
        <v-btn
            elevation="2"
            @click="makeExcelFile">엑셀 저장
        </v-btn>
      </div>
    </search-area>
    <v-data-table :items-per-page="countPage" :headers="headers" :loading="loading" :items="data">
      <template v-for="(h,inx) in headers" v-slot:[`header.${h.value}`]>
        <v-tooltip :key="inx" top>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{h.text}}</span>
          </template>
          <span>{{h.desc}}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.oneclick_yes="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.oneclick_no="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.associateMember="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.freeMember="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.oneclick="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.link="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.insurance_inflow_sum="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.iims="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.zum="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.takhybrid="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.taekwang="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.cashnote="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.wise="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.company_inflow_sum="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.axa="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.cr="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.db="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hd="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hk="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hn="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hw="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.kb="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
    </v-data-table>
    <oneclick-modal
      :is-show="isShow"
      @close="isShow = false"
    />
  </div>
</template>

<script>
import TableMixin from "@/mixin/TableMixin"
import SearchArea from "@/components/Search/SearchArea";
import DateMonthBar from "@/pages/Stat/components/PerformanceStat/DateMonthBar";
import { axiosInstance } from "@/util/AxiosModule";
import Xlsx from "xlsx";
import OneclickModal from "./OneclickModal";

export default {
  mixins: [TableMixin],
  components: {
    OneclickModal,
    DateMonthBar, SearchArea
  },
 data: () => ({
      headers: [{ text: "날짜(일)", value: "create_date", class: "teal lighten-4" , align: "center", sortable: false, desc: "날짜"}, 
                { text: "추천인O 가입", value: "oneclick_yes", class: "indigo lighten-4", align: "center", sortable: false, desc: "추천인 등록하고 가입한 수입니다." },
                { text: "추천인X 가입", value: "oneclick_no", class: "indigo lighten-4", align: "center", sortable: false, desc: "추천인 등록하지 않고 가입한 수입니다." },
                { text: "준회원", value: "associateMember", class: "red lighten-4", align: "center", sortable: false, desc: "준회원 수입니다." },
                { text: "무료회원", value: "freeMember", class: "red lighten-4", align: "center", sortable: false, desc: "무료회원 수입니다." },
                { text: "비교견적", value: "oneclick", class: "light-blue lighten-4", align: "center", sortable: false, desc: "비교견적한 수입니다." },
                { text: "연결저장", value: "link", class: "light-blue lighten-4", align: "center", sortable: false, desc: "원클릭 바로연결 후, 고객정보 기입하여 링크 전달한 수입니다." },
                { text: "연결합계", value: "insurance_inflow_sum", class: "light-blue lighten-4", align: "center", sortable: false, desc: "제휴사 연결한 수의 총합" },
                { text: "IIMS", value: "iims", class: "light-blue lighten-4", align: "center", sortable: false, desc: "인카 iims에서 접속한 수입니다."},
                { text: "ZUM", value: "zum", class: "light-blue lighten-4", align: "center", sortable: false, desc: "[제휴사] zum 화면에서 연결한 수입니다."  },
                { text: "탁하이브리드", value: "takhybrid", class: "light-blue lighten-4", align: "center", sortable: false, desc: "[제휴사] 인천학원공제 화면에서 연결한 수입니다." },
                { text: "태광", value: "taekwang", class: "light-blue lighten-4", align: "center", sortable: false, desc: "[제휴사] 태광 화면에서 연결한 수입니다." },
                { text: "캐시노트", value: "cashnote", class: "light-blue lighten-4", align: "center", sortable: false, desc: "[제휴사] 캐시노트 화면에서 연결한 수입니다." },
                { text: "와이즈", value: "wise", class: "light-blue lighten-4", align: "center", sortable: false, desc: "[제휴사] 와이즈 화면에서 연결한 수입니다." },
                { text: "합계", value: "company_inflow_sum", class: "lime lighten-4", align: "center", sortable: false, desc: "총합" },
                { text: "AXA손해보험", value: "axa", class: "lime lighten-4", align: "center", sortable: false, desc: "AXA손해보험 사이트 접속 수입니다." },
                { text: "캐롯손해보험", value: "cr", class: "lime lighten-4", align: "center", sortable: false, desc: "캐롯손해보험 사이트 접속 수입니다." },
                { text: "DB손해보험", value: "db", class: "lime lighten-4", align: "center", sortable: false, desc: "DB손해보험 사이트 접속 수입니다." },
                { text: "현대해상", value: "hd", class: "lime lighten-4", align: "center", sortable: false, desc: "현대해상 사이트 접속 수입니다." },
                { text: "흥국화재", value: "hk", class: "lime lighten-4", align: "center", sortable: false, desc: "흥국화재 사이트 접속 수입니다." },
                { text: "하나손해보험", value: "hn", class: "lime lighten-4", align: "center", sortable: false, desc: "하나손해보험 사이트 접속 수입니다." },
                { text: "한화손해보험", value: "hw", class: "lime lighten-4", align: "center", sortable: false, desc: "한화손해보험 사이트 접속 수입니다." },
                { text: "KB손해보험", value: "kb", class: "lime lighten-4", align: "center", sortable: false, desc: "KB손해보험 사이트 접속 수입니다." },
                ],
      service: "oneclick",
      data: [],
      date: new Date().toISOString().substr(0, 7),
      loading: false,
      countPage: 32,
      isShow: false
  }),
  async created() {
    await this.dateEvent(this.date);
  },
  methods: {
    async dateEvent(date) {
      this.date=date

      this.loading = true;

      let axiosData;

      let params = {"yearMonth" : this.date}

      try{
        axiosData = await axiosInstance.get('/admin/operation/performance-oneclick', {params})

      } catch(e) {
        console.log(e)
      }
      const { data } = axiosData;
      let allData = {}
      for( var index in data) {
        data[index]['insurance_inflow_sum'] = 0
        data[index]['company_inflow_sum'] = 0
        for( var key in data[index]) {
          if(['iims', 'zum', 'takhybrid', 'taekwang', 'cashnote', 'wise'].includes(key)) {
            data[index]['insurance_inflow_sum'] += data[index][key]
          }
          else if(['axa', 'cr', 'db', 'hd', 'hk', 'hn', 'hw', 'kb'].includes(key)) {
            data[index]['company_inflow_sum'] += data[index][key]
          }
          if (key == "create_date") {
            allData[key] = "합계"
          }else {
            if (allData[key] == null) {
              allData[key] = data[index][key]
            } else {
              allData[key] += data[index][key]
            }
          }
        }
      }
      data.unshift(allData);
      this.data = data
      this.loading = false;

    },
    makeExcelFile () {
      const workBook = Xlsx.utils.book_new()
      let export_list = []
      for (var index in this.data) {
        let item = {}
        for (var key in this.data[index]) {
          for (var header_index in this.headers) {
            if (this.headers[header_index]["value"] == key) {
              item[this.headers[header_index]["text"]] = this.data[index][key]
              break;
            }
          }
        }
        export_list.push(item)
      }
      const workSheet = Xlsx.utils.json_to_sheet(export_list)
      Xlsx.utils.book_append_sheet(workBook, workSheet, `원클릭_${this.date}`)
      Xlsx.writeFile(workBook, `원클릭${this.date}.xlsx`)
    },
    showModal() {
      this.isShow = true;
    }
  }
};
</script>

<style scoped>
.floatR {
  float: right;
}
</style>