<template>
  <!--엑셀업로드 (엑셀파일만 가능)-->
          <v-col>
            <v-row>
              <v-col cols="12" md="9">
                <v-card outlined color="grey">
                  <input type="file"
                         id="excelUpload"
                         @change="importExcel"
                         class="white black--text v-picker--full-width py-1 px-1"
                         accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                </v-card>
              </v-col>

  <!--비교실행-->
              <v-col cols="6" md="3">
                <v-btn
                    right
                    elevation="5"
                    color="grey darken-7"
                    @click="checkingMember"
                >
                  회원점검
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
</template>

<script>
import XLSX from "xlsx";

export default {

  data: () => ({
    loading: false,
    data: [],
    input: "",
    //excelObject: [],
    mb_param: {
      telNum: "",
      memberNm:null,
      email:null
    },
  }),

  methods:{
    importExcel(event) {
      this.input = event.target;
      let reader = new FileReader();
      reader.onload = () => {
        let fileData = reader.result;
        let wb = XLSX.read(fileData, {type: 'binary'});
        wb.SheetNames.forEach((sheetName) => {
          // 엑셀에 telNum 컬럼만 들어가니까 맞음
          this.mb_param = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
        })
      };
      reader.readAsBinaryString(this.input.files[0]);
    },

    checkingMember(){
      if(this.input !== ""){
        this.$emit('checkingMember',this.mb_param);
      }
      else{
        alert("파일을 선택해주세요.");
      }
    },

  }
}
</script>

<style scoped>

</style>