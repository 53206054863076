<template>
  <v-layout>
    <v-card class="col-12 pa-0" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>정보 변경</v-card-title>
      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">직원 ID</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.memberId"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                disabled
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">직원명</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.customerNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                disabled
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">관리자 역할</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.adminRole"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">콜시스템상담원ID</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.csId"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">적용시작일자</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.applyStartDt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">적용종료일자</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.applyEndDt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">등록자명</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.regNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                disabled
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">등록일자</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="saveData.regDate"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                disabled
            />
          </v-col>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-btn
            color="red lighten-1"
            class="white--text col-1"
            @click="save('OUT')"
            :disabled="isRowEmpty"
        >
          퇴사 처리
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="white lighten-5"
            class="blue--text col-1"
            @click="reset"
        >
          초기화
        </v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="save('IN')"
            :disabled="isRowEmpty"
        >
          수정
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import store from "@/store";
import _ from "lodash";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props : ["rowData"],

  data() {
    return {
      saveData: {
        customerNm: '',
        memberId: '',
        telNum: '',
        applyStartDt: '',
        applyEndDt: '',
        adminRole: '',
        csId: '',
        regId: '',
        regNm: '',
        regDate: ''
      },
    }
  },
  computed: {
    regUser(){
      return store.getters["login/userData"];
    },
    isRowEmpty(){
      return _.isEmpty(this.saveData.memberId);
    }
  },
  mounted() {
    if (!_.isEmpty(this.rowData)) {
      this.saveData = _.cloneDeep(this.rowData);
    }
  },
  methods: {
    async save(type) {
      const data = this.setRegUser(this.saveData);

      const msg = type === 'IN' ? "의 정보를 변경" : '을 퇴사 처리';

      if (confirm(data.customerNm + "님" + msg + "하시겠습니까?")) {
        try {
          await axiosInstance.post('/admin/member/updateExcMember', data, {
                params: {
                  wkType: type
                }
              }
          );

          alert('완료되었습니다.');
          this.refresh();
        } catch (e) {
          console.log(e);
        }
      }
    },

    // 등록자 세팅
    setRegUser(data){
      data.regId = this.regUser.memberId;
      data.regNm = this.regUser.memberNm;

      return data;
    },
    reset(){
      this.$emit('reset', null);
    },
    refresh(){
      this.$emit('refresh');
    }

  }
}
</script>
