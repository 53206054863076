<template>
  <v-col cols="12" class="py-5 px-2">
    <div class="text-right">
      <v-btn
          color="grey"
          class="white--text px-4"
          style="margin-right: 3px;"
          @click="toTermSetting"
          :disabled="!hasTableData"
      >기간세팅(보험료기준)</v-btn>
      <v-btn
          color="grey"
          class="white--text px-4"
          style="margin-right: 3px;"
          @click="baseCvrMappingInput"
          :disabled="!hasTableData"
      >기본담보매핑입력</v-btn>
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin-right: 3px;"
          @click="toExcel"
          :disabled="!hasTableData"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <div id="example-1">
        <v-data-table
            class="data_table"
            height="45vh"
            :items="data"
            :loading="loading"
            :items-per-page="-1"
            :headers="getHeader(headers)"
            @click:row="clickRows"
            @dblclick:row="dbClickRows"
            fixed-header calculate-widths hide-default-footer
        >
          <template v-slot:item.prNm="{ value }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">{{ textLengthOverCut(value, 20, "...")}}</div>
              </template>
              <span>{{ value }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.cvrNm="{ value }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">{{ textLengthOverCut(value, 20, "...")}}</div>
              </template>
              <span>{{ value }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.baseAccAmt="{ value }">
            <div class="jc-fe">{{ inputComma(value) }}</div>
          </template>
          <template v-slot:item.createDate="{ value }">
            {{ getKST(value) }}
          </template>
        </v-data-table>
      </div>

      <ins-cvr-mapping-basic-data-detail
          :is-show="showDetail"
          :rowData="rowData"
          @close="showDetail = false"
      />
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {INS_CVR_MAPPING_BASIC_DATA_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";
import DateMixin from "@/mixin/DateMixin";
import InsCvrMappingBasicDataDetail
  from "@/pages/Product/components/InsCvrMappingBasicData/InsCvrMappingBasicDataDetail";

export default {
  components: {InsCvrMappingBasicDataDetail},
  props: ["data", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: INS_CVR_MAPPING_BASIC_DATA_TABLE,
      rowData: {},
      showDetail: false
    }
  },
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  methods: {
    clickRows(row) {
      this.rowData = row;
      this.$emit("clickRow", row);
    },
    dbClickRows() {
      this.showDetail = true;
    },
    // Excel 관련 코드
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "상품군": item["prProdLineCd"],
          "상품군명": item["prProdLineNm"],
          "상품타입": item["prodType"],
          "상품타입명": item["prodTypeNm"],
          "신정원코드": item["creCvrCd"],
          "신정원코드명": item["creCvrNm"],
          "보험사코드": item["insCd"],
          "보험사명": item["insNm"],
          "상품코드": item["prCd"],
          "상품명": item["prNm"],
          "담보코드": item["cvrCd"],
          "담보명": item["cvrNm"],
          "가입안내금액": item["baseAccAmt"],
          "우선순위": item["prioOrder"],
          "최대_보험기간": item["insTrmMax"],
          "최소_보험기간": item["insTrmMin"],
          "최대_납입기간": item["pyTrmMax"],
          "최소_납입기간": item["pyTrmMin"],
          "사용여부": item["useYn"],
          "등록일시": item["createDate"],
        }
        returnData.push(obj);
      }

      const sheetName = `보험사 담보매핑 기초자료`;
      const fileName = `보험사 담보매핑 기초자료`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    toTermSetting() {
      this.$emit("termSetting");
      this.$emit("reloadData");
    },
    baseCvrMappingInput() {
      this.$emit("baseMapping");
      this.$emit("reloadData");
    }
  }
}
</script>

<style scoped>
.data_table {
  cursor: pointer;
}
</style>