<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <field-input title="조회구분">
          <v-select
              v-model="searchType"
              :items="searchTypeList"
              @change="searchValue = ''"
              background-color="white"
              item-text="name"
              item-value="cd"
              hide-details outlined dense
          />
        </field-input>

        <field-input :title="changeTypeNm(searchType)">
          <v-select
              v-if="searchType == 'PRV'"
              v-model="prvGrp"
              :items="prvGrpList"
              background-color="white"
              item-text="value"
              item-value="cd"
              hide-details outlined dense
          />
          <v-text-field
              v-else
              v-model="searchValue"
              @keypress.enter="getSearch"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              :placeholder="searchType == 'PROG' ? '화면 한글명 입력' : '사용자 이름 입력'"
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateMixin from "@/mixin/DateMixin";
import _ from 'lodash';

export default {
  name: "UserProgramPrivilegeSearchBar",
  mixins: [SearchBarMixin, DateMixin],
  components: {SearchArea, SearchBarLayout, FieldInput}, // DateInput
  props: ["prvList"],

  data: () => ({
    searchType: "USER",
    searchTypeList: [
      {name: "사용자 명", cd: "USER"},
      {name: "권한 그룹", cd: "PRV"},
      {name: "프로그램 명", cd: "PROG"}
    ],
    searchValue: "",   // 사용자명 or 프로그램명
    prvGrp: "",
    prvGrpList: [],
  }),

  created() {
    this.prvGrpList = [
      {cd: 'ALL', value: '전체', use: true},
      ..._.cloneDeep(this.prvList),
      {cd: 'IND', value: '개별', use: true}
    ];
    this.prvGrp = 'ALL';
  },

  mounted() {
    this.getSearch();
  },

  methods: {
    getSearch() {
      let params = {
        searchType: this.searchType,
        searchValue: this.searchValue
      };
      if (this.searchType == 'PRV') params.searchValue = this.prvGrp;

      this.$emit('getSearch', params);
    },

    changeTypeNm(value){
      return this.searchTypeList.find(el => el.cd == value).name;
    },
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}

.calender {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
</style>