<template>
  <search-page-layout title="무실적 회원현황">
    <template v-slot:searchBar>
      <none-settle-member-search-bar
          @search="getSearch"
      />
    </template>
    <template v-slot:table>
      <none-settle-member-table
        :loading="loading"
        :data="data"
        @toExcel="toExcel"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout.vue";
import NoneSettleMemberSearchBar from "@/pages/Operation/components/NoneSettleMember/NoneSettleMemberSearchBar.vue";
import {axiosInstance} from "@/util/AxiosModule";
import NoneSettleMemberTable from "@/pages/Operation/components/NoneSettleMember/NoneSettleMemberTable.vue";
import _ from "lodash";
import func from "@/util/func";
import DateMixin from "@/mixin/DateMixin";

export default {
  name: "NoneSettleMember",
  components: {NoneSettleMemberTable, NoneSettleMemberSearchBar, SearchPageLayout},
  mixins: [DateMixin],
  data() {
    return {
      loading: false,
      data: []
    }
  },
  methods: {
    async getSearch(params) {
      try {
        this.loading = true;
        const { data } = await axiosInstance.get("/admin/operation/donePerformance/memberList", { params });
        if (data.length < 1) {
          alert("조회된 목록이 없습니다.");
          this.loading = false;
          return false;
        } else {
          for (let i in data) {
            data[i].regDate =
              !_.isEmpty(data[i].regDate)
                  ? data[i].regDate.replace("T", " ").replace(/\..*/, '')
                  : data[i].regDate;
            data[i].designReqDate =
              !_.isEmpty(data[i].designReqDate)
                  ? data[i].designReqDate.replace("T", " ").replace(/\..*/, '')
                  : data[i].designReqDate;
          }
          this.data = data;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      let nowDate = new Date();
      let nowHours = nowDate.getHours();
      let nowMinutes = nowDate.getMinutes();
      nowDate = this.getDateNoDashFormat(nowDate);

      for (const item of data) {
        const obj = {
          "회원명": item["memberNm"],
          "이메일": item["email"],
          "전화번호": item["telNum"],
          "소속": item["joinType"],
          "회원등록일자": !_.isEmpty(item["regDate"]) ? this.getDateNoSecFormat(item["regDate"]) : item["regDate"],
          "설계지원요청일자": !_.isEmpty(item["designReqDate"]) ? this.getDateNoSecFormat(item["designReqDate"]) : item["designReqDate"]
        }
        returnData.push(obj);
      }
      const sheetName = `무실적 회원조회`;
      const fileName = `무실적 회원조회(${nowDate}_${nowHours}${nowMinutes})`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>