<template>
  <v-col cols="12" class="py-0">
        <div class="mb-2">
          <span class="text-h6" style="visibility: hidden">설계지원 운영현황표</span>
          <outlined-btn class="float-right" @click="toExcel" >엑셀다운로드</outlined-btn>
        </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :headers="getHeader(headers)"
          :items-per-page="-1"
          fixed-header
          disable-sort
          calculate-widths>

        <template v-slot:item.contPrem="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.stsDt="{ value }">
          <div >{{ getDateDashFormat(value) }}</div>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import DateMixin from "@/mixin/DateMixin";
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import {DESIGN_SUPPORT_STATUS_TABLE, DESIGN_SUPPORT_STATUS_TABLE_CAMP} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";

export default {
  components: {OutlinedBtn},
  mixins: [DateMixin, TableMixin],
  props: ["data", "type", "loading"],
  data:() => ({
    headers: DESIGN_SUPPORT_STATUS_TABLE,
    isCampaign: false,
  }),

  mounted() {
    this.isCampaign = this.type === 'C';

    this.headers = this.isCampaign
        ? DESIGN_SUPPORT_STATUS_TABLE_CAMP
        : DESIGN_SUPPORT_STATUS_TABLE;
  },
  methods: {
    // Excel 관련 코드
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "일자": item["stsDt"],
          ...(this.isCampaign ? {"요청건": item["totCnt"]} : {"요청건(a+b)": item["totCnt"]}),
          ...(this.isCampaign ? {} : {"FA요청건(a)": item["faCnt"]}),
          ...(this.isCampaign ? {} : {"센터입력건(b)": item["centCnt"]}),
          "미처리건": item["yetCnt"],
          "진행중": item["ingCnt"],
          ...(this.isCampaign ? {"진행불가건": item["impossCnt"]} : {}),
          "종료건": item["cloCnt"],
          "계약체결건": item["contCnt"],
          "체결금액": item["contPrem"],
          "링크전달": item["linkCnt"],
          "번호전달": item["numTrsCnt"],
          "체결진행": item["suppCnt"],
          "기타처리": item["etcCnt"]
        }
        returnData.push(obj);
      }
      let campName = this.isCampaign ? "_캠페인" : "";
      const sheetName = `설계지원 운영현황`;
      const fileName = `설계지원 운영현황` + campName;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style scoped>
.v-data-table::v-deep tr:first-child {
  background-color: #98c0c3;
}
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>