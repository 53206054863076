<template>
  <v-form
      v-model="valid"
      class="w100h100"
  >
    <div @dblclick="isEdit = !isEdit" class="w100h100 cell">
      <v-select
          :items="items"
          @blur="isEdit = false"
          v-if="isEdit" v-model="text"
          hide-details dense
      />
      <span v-else>{{ getText(this.items, value) }}</span>
    </div>
  </v-form>
</template>

<script>
import _ from 'lodash';
import SettlementMixin from "@/mixin/SettlementMixin";

export default {
  mixins: [SettlementMixin],
  props: ["items"],
  watch: {
    text : function() {
      if(this.valid) this.updateColumn(this.text, this.id, this.header);
      this.isEdit = false;
    }
  },
  methods: {
    getText(list, value) {
      try{
        if (_.isEmpty(value)) return "";
        return _.find(list, {value: value}).text
      } catch (e) {
        return _.find(list, {text: value}).text
      }
    }
  }
}
</script>

<style scoped>
</style>