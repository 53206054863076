import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=c0605756&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0605756",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VOverlay})
