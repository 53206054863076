<template>
  <v-col cols="12" class="py-0">
    <v-sheet class="px-3 py-2 background">
      <slot name="contents" />
    </v-sheet>
    <slot name="button" />
  </v-col>
</template>

<script>
export default {

}
</script>

<style scoped>
  .background {
    background-color: rgba(0,0,0,.05);
    border: 1px solid lightgray;
  }
</style>