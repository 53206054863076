<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="기간">
          <div class="col col-6">
          <v-text-field
              v-model="fromYm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              placeholder="yyyymm"
              />
          </div>
        <div class="col col-1"> ~
        </div>
        <div class="col col-6">
          <v-text-field
              v-model="toYm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              placeholder="yyyymm"
          />
        </div>
        </field-input>
        <div class="ml-auto pb-1 d-flex align-end">
          <v-btn
              elevation="2"
              color="grey"
              class="white--text px-10"
              @click="showModal"
          >
            월마감 작업
          </v-btn>
        </div>
      </search-area>
      <car-sales-list-modal
          :is-show="isShow"
          @close="isShow = false"
        />
    </template>
    <template v-slot:button>
      <search-btn :disabled="isDisabled" @click="getCarSalesList"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import SearchBtn from "../../../../components/Search/SearchBtn";
import DateMixin from "@/mixin/DateMixin";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import FieldInput from "../../../../components/Form/FieldInput";
import CarSalesListModal from "./CarSalesListModal";

export default {
  mixins: [SearchBarMixin, DateMixin],
  components: {CarSalesListModal, FieldInput, SearchBtn, SearchArea, SearchBarLayout},
  async beforeMount() {
    const { fromDate, toDate } = this.setDateMonthNoDash(10);

    this.fromYm = fromDate;
    this.toYm = toDate;
  },
  data: () => ({
    fromYm: "",
    toYm: "",
    edFrom:'',
    isShow: false,
  }),
  computed: {
    isDisabled(){
      const from = this.fromYm;
      const to = this.toYm;
      return(
        //시작일, 종료일 필수
        !this.fromYm || !this.toYm || !(!!from && /[0-9]{6}/.test(from) && this.monthCheck(from))
        || !(!!to && /[0-9]{6}/.test(to) && this.monthCheck(to))
      )
    }
  },
  methods: {
    getCarSalesList() {
      const sY = this.fromYm.substring(0,4);
      const sM = this.fromYm.substring(4,6);
      const eY = this.toYm.substring(0,4);
      const eM= this.toYm.substring(4,6);
      const diffDay = (new Date(eY, eM) - new Date(sY, sM)) / 1000 / 60 / 60 /24;
      if(diffDay > 366) {
        alert("일자 범위는 1년 이내로 설정해주세요.")
        return false;
      }

      const params = {
        "startYm": this.fromYm,
        "endYm": this.toYm
      };

      this.$emit('search', params, diffDay);
    },
    // 기준연월 유효성 체크
    monthCheck(value) {
      return value.length === 6
          ? (+value.slice(4) < 13 && +value.slice(4) > 0)
          : false;
    },
    showModal() {
      this.isShow = true;
    }
  }
}
</script>
<style scoped>

</style>