<template>
  <div>
    <search-bar-layout>
      <template v-slot:contents>
        <search-area>
          <v-col class="d-flex align-center col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <v-col cols="3">조회기간</v-col>
            <v-col cols="9">
              <date-input
                  :start-date="startDate"
                  :end-date="endDate"
                  @updateDate="updateDate"
              />
            </v-col>
            <v-col cols="3">콘텐츠조회</v-col>
              <v-checkbox
                  v-model="contYn"
                  class="marginLf"
                  @change="contYnChange"
              ></v-checkbox>
          </v-col>
        </search-area>
      </template>
      <template v-slot:button>
        <search-btn @click="getperfInfo" />
      </template>
    </search-bar-layout>
    <v-col cols="12" class="py-0">
      <div class="mb-2">
        <span class="text-h6">목록 (총 {{ usageStatus.length }}개)</span>
        <v-btn class="float-right" @click=toExcel :disabled="!hasTableData">엑셀다운로드</v-btn>
      </div>
      <v-data-table
          height="50vh"
          :items="usageStatus"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="headers"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.totCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.incarCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import DateInput from "@/components/Form/DateInput";
import SearchBtn from "../../../../components/Search/SearchBtn";
import TableMixin from "@/mixin/TableMixin";
import DateMixin from "@/mixin/DateMixin";
import CodeMixin from "@/mixin/CodeMixin";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import { axiosInstance } from "@/util/AxiosModule";

export default {
  mixins: [TableMixin, SearchBarMixin, CodeMixin, DateMixin],
  components: {SearchBtn, SearchArea, SearchBarLayout, DateInput},
  computed: {
    hasTableData() {
      return this.usageStatus.length > 0;
    }
  },
  data:() => ({
    startDate: "",
    endDate: "",
    contYn: "",
    loading:false,
    usageStatus: [],
    headers: [{text: "메뉴명", value: "menuNm", class: "lime lighten-4" , align: "center", sortable: false},
              {text: "시작사용일자", value: "minContRealDate", class: "light-blue lighten-4" , align: "center", sortable: false},
              {text: "최종사용일자", value: "maxContRealDate", class: "light-blue lighten-4" , align: "center", sortable: false},
              {text: "누적 사용 건수", value: "totCnt", class: "lime lighten-4" , align: "center", sortable: false},
              {text: "인카FA 사용 건", value: "incarCnt", class: "lime lighten-4" , align: "center", sortable: false},]
  }),
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    contYnChange() {
      if(this.contYn)
      {
        this.headers = [{text: "메뉴명", value: "menuNm", class: "lime lighten-4" , align: "center", sortable: false},
          {text: "컨텐츠", value: "contentsJoinSeq", class: "light-blue lighten-4" , align: "center", sortable: false},
          {text: "시작사용일자", value: "minContRealDate", class: "light-blue lighten-4" , align: "center", sortable: false},
          {text: "최종사용일자", value: "maxContRealDate", class: "light-blue lighten-4" , align: "center", sortable: false},
          {text: "누적 사용 건수", value: "totCnt", class: "lime lighten-4" , align: "center", sortable: false},
          {text: "인카FA 사용 건", value: "incarCnt", class: "lime lighten-4" , align: "center", sortable: false},];
      }
      else
      {
        this.headers = [{text: "메뉴명", value: "menuNm", class: "lime lighten-4" , align: "center", sortable: false},
          {text: "시작사용일자", value: "minContRealDate", class: "light-blue lighten-4" , align: "center", sortable: false},
          {text: "최종사용일자", value: "maxContRealDate", class: "light-blue lighten-4" , align: "center", sortable: false},
          {text: "누적 사용 건수", value: "totCnt", class: "lime lighten-4" , align: "center", sortable: false},
          {text: "인카FA 사용 건", value: "incarCnt", class: "lime lighten-4" , align: "center", sortable: false},];
      }

      this.getperfInfo();
    },
    async getperfInfo() {

      this.loading = true;

      const params = {
        startDt :  this.startDate.replaceAll('-', ''),
        endDt : this.endDate.replaceAll('-', ''),
        contYn : this.contYn ? "Y" : "N",
      }
      try{
        const { data } = await axiosInstance.get('/admin/operation/perf_menu_info', {params})
        this.usageStatus = data;
        for (let i = 0; i < this.usageStatus.length; i++) {
          this.usageStatus[i].minContRealDate = this.usageStatus[i].minContRealDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
          this.usageStatus[i].maxContRealDate = this.usageStatus[i].maxContRealDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }
      }
      catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    clickContents(row){
      const params = {
        startDt :  this.startDate,
        endDt : this.endDate,
        menuNm : row.menuNm
      }
      this.$emit("updateSetting", params)
    },
    toExcel(){
      const params = {
        headers :  this.headers,
        tableData : this.usageStatus,
        startDt : this.startDate,
        endDt : this.endDate,
        menuNm : "사용현황"
      }
      this.$emit('toExcel', params);
    }

  }
}
</script>