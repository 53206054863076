<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="기준년월">
          <v-text-field
              v-model="settlementDate"
              :disabled="isUploaded"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <v-btn
            class="grey white--text"
            v-if="!isUploaded"
            :disabled="isDisabled"
            @click="clickUpload"
        >엑셀 업로드
        </v-btn>
        <v-btn v-else class="red white--text" @click="deleteData">초기화</v-btn>
        <div class="ml-auto pb-1 d-flex align-end">
          <span class="dataDetail" @click="showModal">원수사별 자료 내역</span>
          <span class="todayDelete" @click="deleteTodayData">오늘 검수내역 삭제</span>
        </div>
      </search-area>
      <input @input="loadData" :accept="SheetJSFT" class="d-none" id="excel" type="file" ref="file"/>
      <settlement-modal
          :is-show="isShow"
          @close="isShow = false"
      />
    </template>
  </search-bar-layout>
</template>

<script>
import XLSX from "xlsx";
import {EXCEL_HEADER} from "@/util/TableHeaders";
import {axiosInstance} from "@/util/AxiosModule";

import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SettlementModal from "./SettlementModal";

export default {
  components: {FieldInput, SearchArea, SearchBarLayout, SettlementModal},
  props: ["isUploaded"],
  computed: {
    isDisabled() {
      const d = this.settlementDate;
      return !(!!d && /[0-9]{6}/.test(d) && this.monthCheck(d));
    },
  },
  data: () => ({
    settlementDate: "",
    SheetJSFT: ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"].map(x => "." + x).join(","),
    isShow: false,
    modalData: {},
  }),
  watch: {
    settlementDate: function (value) {
      this.$emit('setSettlementDate', value);
    }
  },
  methods: {
    // 기준연월 유효성 체크
    monthCheck(value) {
      return value.length === 6
          ? (+value.slice(4) < 13 && +value.slice(4) > 0)
          : false;
    },

    // 삭제 버튼 클릭시
    deleteData() {
      if (confirm("업로드한 자료를 모두 삭제하시겠습니까?")) {
        this.$refs.file.value = "";
        this.settlementDate = "";
        this.$emit("initData")
      }
    },

    async deleteTodayData() {
      if (!confirm("오늘 지급예정 처리한 내역을 모두 삭제하시겠습니까?")) return;
      try {
        const {data} = await axiosInstance.delete('/admin/settlement');
        alert(`${data}건의 데이터가 삭제되었습니다.`);
      } catch (e) {
        console.log(e);
      }
    },

    // upload excel
    async upload(data, settlementDate) {
      if (!data || !settlementDate || settlementDate.length !== 6) {
        alert("파일의 컬럼명 또는 정산연월을 다시 확인해주세요.");
        this.initData();
      } else {
        this.tableData = data;
        this.$emit('upload', data);
      }
    },
    loadData({target: {files}}) {
      if (!files || files.length < 1) alert("올바른 파일이 아닙니다.");
      else this._setReader().readAsBinaryString(files[0]);
    },
    _setReader() {
      const reader = new FileReader();
      reader.onload = ({target: {result}}) => {
        const {SheetNames, Sheets} = XLSX.read(result, {type: 'binary'});
        // 해당 파일 json 으로 변경
        // header 컬럼 1행, 비어있는 row 는 생략하는 조건
        const data = this.getJSON(XLSX.utils.sheet_to_json(
            Sheets[SheetNames[0]], {header: 1, blankrows: false, rawNumbers: true, defval: ""}
        ));

        // 데이터 공백제거
        for (const item in data) {
          for (const key in data[item]) {
            if (data[item][key] !== "index" && typeof data[item][key] === "string") {
              data[item][key] = data[item][key].replace(/\s/g, '');
            }
          }
        }
        // 엑셀 파일 변환 후 해당 메소드 실행
        this.upload(data, this.settlementDate);
      };
      return reader;
    },
    getJSON(data) {
      // 헤더 제거
      const body = data.slice(1);

      return body.map((item, index) => {

        const obj = {};

        for (let i = 0; i < item.length; i++) {

          // 한글로 된 컬럼명을 영어로 바꾸기 위한 작업
          const enNm = this.chgKrNmToEn(data[0][i]);

          // 친구초대 리워드가 검증 단계에서 추가될 수 있으므로 index 를 2단위로 수정
          if (enNm) {
            obj["index"] = index * 2;
            obj[enNm] = item[i];
          }

        }

        if (obj['contractDate']) obj['contractDate'] = obj['contractDate'].toString().replace(/[^0-9]/gi, "");
        if (obj['expiryDate']) obj['expiryDate'] = obj['expiryDate'].toString().replace(/[^0-9]/gi, "");
        // 엑셀에서 01을 1로 바꾸는 경우도 있기에 해당 로직 추가
        if (obj['paymentType'] === "1" || obj['paymentType'] === 1) obj['paymentType'] = "01";

        return obj;
      });
    },
    chgKrNmToEn(header) {
      const h = header.replace(/ /g, '');

      for (const item of EXCEL_HEADER) {
        if (item[0] === h) return item[1];
      }

      alert("파일의 컬럼명을 확인해주세요.");
      throw new Error("파일의 컬럼명을 확인해주세요.");
    },

    // 업로드 버튼 클릭시 input file
    clickUpload() {
      if (!this.isDisabled) this.$refs.file.click();
    },

    showModal() {
      this.isShow = true;
    }
  }
}
</script>

<style>
.todayDelete {
  color: red;
  margin-left: 10px;
  text-decoration-line: underline;
  cursor: pointer;
}
.dataDetail {
  color: black;
  text-decoration-line: underline;
  cursor: pointer;
}
</style>