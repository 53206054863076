<template>
  <search-layout title="보험료 조회 현황">

    <template v-slot:searchBar>
      <calc-stat-search-bar @search="getCalcStat" />
    </template>

    <template v-slot:table>
      <calc-stat-table
        :data="data"
        :loading="loading"
        :header="headers"
      />
    </template>

  </search-layout>
</template>

<script>
import _ from "lodash";
import SearchLayout from "@/layouts/SearchPageLayout";
import { axiosInstance } from "@/util/AxiosModule";
import CalcStatSearchBar from "@/pages/Stat/components/CalcStatSearch/CalcStatSearchBar";
import CalcStatTable from "@/pages/Stat/components/CalcStatSearch/CalcStatTable";

export default {
  components: {
    CalcStatTable,
    CalcStatSearchBar,
    SearchLayout
  },
  data: () => ({
    headers: [["기준일자", "기준일자"]],
    data: [],
    loading: false
  }),
  methods: {
    async getCalcStat(params) {
      this.loading = true;

      let axiosData;

      try{
        axiosData = await axiosInstance.get('/admin/operation/partner-inflows', { params });
      } catch (e) {
        console.log(e);
      }

      const { data } = axiosData;

      if(!data) return;

      const { header, body } = data;
      const headerData = (header.map(head => ([head, head])));
      const response = [];

      _.forIn(body, function(value, key) {
        const obj = {};
        obj["기준일자"] = key;
        for(const index in value) {
          obj[header[index]] = value[index];
        }
        response.push(obj);
      });

      this.headers = headerData;
      this.data = response;
      this.loading = false;
    },
  }
}
</script>

<style>
.sumRow > td {
  font-weight: bold;
  background-color: #f0f0ff;
  position: sticky !important;
  position: -webkit-sticky !important;
  bottom: -1px;
  z-index: 9999!important;
}
</style>