<template>
  <custom-layout title="캠페인 관리" subtitle="캠페인 관리를 위한 페이지입니다.">
    <v-sheet class="main-sheet" max-width="100%">
      <v-card width="100vw" elevation="1">
        <!-- 조회 조건 -->
        <v-row style="display: flex; justify-content: space-between; padding: 10px 20px; border-bottom: 1px solid #d5d5d5;">
          <!-- 조회 캠페인명 선택-->
          <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-5 col-12" style="display: flex; justify-content: center; align-items: center">
            <span style="padding-right: 50px; font-weight: 800; font-size: 15px; width: 150px">캠페인명</span>
            <v-select
                v-model="campaignCd"
                :items="campaignCdList"
                item-text="campaignCdNm"
                item-value="campaignCd"
                hide-details outlined
            />
          </v-col>
          <!-- 조회 캠페인명 선택-->

          <!-- 조회 날짜 선택 -->
          <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-5 col-12" style="display: flex; justify-content: center; align-items: center">
            <span style="margin-right: 30px; font-weight: 800; font-size: 15px; width: 100px">정보등록일자</span>
            <custom-date-picker
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
          <!-- 조회 날짜 선택 -->

          <v-col style="display: flex; align-items: center;">
            <v-btn class="search-button" @click="getSearch">조회</v-btn>
          </v-col>
        </v-row>
        <!-- 조회 조건 -->

        <!-- 조회 목록 -->
        <v-data-table
          :headers="getHeader(headers)"
          :items="campaignSearchList"
          :loading="loading"
          height="100%"
          style="margin-top: 50px"
          fixed-header calculate-widths
          item-key="index"
          show-select multi-sort="multi-sort"
          v-model="selectedItems"
        />
        <!-- 조회 목록 -->

        <!-- 하단 팝업 버튼 -->
        <div class="footer-button">
          <v-btn class="button-layout" @click="showPopup('infoSave')">정보등록</v-btn>
          <v-btn class="button-layout" @click="showPopup('campaignSave')">건별자료등록</v-btn>
        </div>
        <!-- 하단 팝업 버튼 -->

      </v-card>
    </v-sheet>
    <campaign-info-save-popup
        :is-show="showInfoSave"
        @close="showInfoSave = false"
    />

    <campaign-data-save-popup
      :is-show="showCampaignSave"
      :selected="selectedItems"
      @close="closeCampaignSave"
    />
  </custom-layout>
</template>

<script>
import CustomLayout from "@/layouts/CustomLayout";
import CustomDatePicker from "@/components/Form/CustomDatePicker";
import DateMixin from "@/mixin/DateMixin";
import TableMixin from "@/mixin/TableMixin";
import {CAMPAIGN_MANAGEMENT_TABLE} from "@/util/TableHeaders";
import {axiosInstance} from "@/util/AxiosModule";
import CampaignInfoSavePopup from "@/pages/Manage/Campaign/Popup/CampaignInfoSavePopup";
import CampaignDataSavePopup from "@/pages/Manage/Campaign/Popup/CampaignDataSavePopup";

export default {
  components: {CampaignDataSavePopup, CampaignInfoSavePopup, CustomLayout, CustomDatePicker },
  mixins: [DateMixin, TableMixin],
  created() {
    this.getCampaignCdList();
  },
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-31);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data() {
    return {
      startDate: "", endDate: "",
      campaignCd: "",
      campaignCdList: [
        { campaignCdNm: "선택", campaignCd: "" }
      ],
      headers: CAMPAIGN_MANAGEMENT_TABLE,
      loading: false,
      campaignSearchList: [],
      showInfoSave: false,
      showCampaignSave: false,
      selectedItems: []
    }
  },
  watch: {
    selectedItems(value) {
      if (value.length  < 2) {
        return true;
      } else {
        alert("항목을 한개만 선택해주세요.");
        this.selectedItems = [];
        return false;
      }
    }
  },
  methods: {
    async getCampaignCdList() {
      const { data } = await axiosInstance.get("/public/prdata/code-list", {
        params: {
          cdMasterId: "CAMPAIGN",
          useYn: "Y"
        }
      });
      if (data.length < 1) {
        return false;
      } else {
        for (let i in data) {
          const object = {};
          object.campaignCdNm = data[i].value;
          object.campaignCd = data[i].cd;
          this.campaignCdList.push(object);
        }
      }
    },
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    async getSearch() {
      const { data } = await axiosInstance.get("/private/operation/campaignInfoList", {
        params: {
          campaignCd: this.campaignCd,
          crtFrDt: this.startDate.replaceAll('-', ''),
          crtToDt: this.endDate.replaceAll('-', '')
        }
      });
      // 배열에 인덱스 추가
      let idx = 0;
      for (const item of data) { item["index"] = idx++; }

      // 데이터 형식 추가
      for (let i in data) {
        for (let key in data[i]) {
          if (data[i][key] !== null) {
              data[i].useFrDt = data[i].useFrDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
              data[i].useToDt = data[i].useToDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
              data[i].createDate = data[i].createDate.replace('T', ' ').substr(0, 11);
              if (data[i].dataRegDate !== null) {
                data[i].dataRegDate = data[i].dataRegDate.replace('T', ' ').substr(0, 11);
              }
          }
        }
      }
      this.campaignSearchList = data;
    },
    showPopup(type) {
      if (type === "infoSave") {
        this.showInfoSave = true;
      } else if (type === "campaignSave") {
        this.showCampaignSave = true;
      }
    },
    closeCampaignSave() {
      this.showCampaignSave = false;
      this.selectedItems = [];
    }
  }
}
</script>

<style lang="scss" scoped>
.main-sheet {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.search-button {
  background-color: #c0c0c0;
}

.footer-button {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.button-layout {
  margin-left: 10px;
}
</style>