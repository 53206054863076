<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col>
          <v-row class="px-6 align-center">
            <v-col cols="1" class="margin0">메뉴명</v-col>
            <v-col cols="2">
              <v-text-field
                  v-model="menuNm"
                  outlined dense hide-details
                  background-color="white" menu-props="auto"
              />
            </v-col>
            <v-col cols="1" class="margin1">사용자그룹</v-col>
            <v-col cols="2">
              <v-select
                v-model="userGroupCd"
                :items="userGroupType"
                background-color="white"
                outlined dense
                hide-details
                item-text="bname"
                item-value="bvalue"
                class="subBar"
                />
            </v-col>
            <v-col cols="1" class="margin1">유료여부</v-col>
            <v-col cols="2">
              <v-select
                  v-model="paidUseYn"
                  :items="PaymentYN"
                  background-color="white"
                  outlined dense
                  hide-details
                  item-text="cname"
                  item-value="cvalue"
                  class="subBar"
              />
            </v-col>
            <v-col cols="1" class="margin1">권한 등록여부</v-col>
            <v-col cols="2">
              <v-select
                  v-model="authUseYn"
                  :items="systemRight"
                  background-color="white"
                  outlined dense
                  hide-details
                  item-text="dname"
                  item-value="dvalue"
                  class="subBar"
              />
            </v-col>
          </v-row>
        </v-col>
      </search-area>


    </template>
    <template v-slot:button>
      <search-btn @click="getUserList"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import SearchBtn from "../../../../components/Search/SearchBtn";
export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  data: () => ({
    menuNm:"",
    userGroupCd: 'ALL',
    paidUseYn: 'A',
    authUseYn: 'A',
    userGroupType:[
      {bname: '전체', bvalue: 'ALL'},
      {bname: '일반무료 회원', bvalue: 'USER_GENERAL'},
      {bname: '일반유료 회원', bvalue: 'USER_PAID'},
      {bname: '일반유료 인카회원', bvalue: 'INCAR_GENERAL'},
      {bname: 'SENIOR 유료 인카회원', bvalue: 'INCAR_SENIOR'},
      {bname: 'MANAGER 유료 인카회원', bvalue: 'INCAR_MANAGER'},
      {bname: 'ADMIN', bvalue: 'ADMIN'}
    ],
    PaymentYN:[
      {cname: '전체', cvalue: 'A'},
      {cname: '유료', cvalue: 'Y'},
      {cname: '무료', cvalue: 'N'}
    ],
    systemRight:[
      {dname: '전체', dvalue:'A'},
      {dname: '등록', dvalue:'Y'},
      {dname: '미등록', dvalue:'N'}
    ]
  }),
  methods: {
    getUserList() {
      const params = {
        "menuNm": this.menuNm,
        "userGroupCd": this.userGroupCd,
        "paidUseYn": this.paidUseYn,
        "authUseYn": this.authUseYn
      }

      this.$emit('search', params);
    }
  }
}
</script>


<style scoped>
.margin1 {
  padding: 11px;
  font-weight:600;
}
.margin0 {
  padding: 15px;
  font-weight:600;
}
</style>