<template>
  <v-overlay
    v-model="isShow"
    :light="true" :dark="false"
    >
    <div style="position: absolute; z-index: 999; display: flex; justify-content: flex-end; width: 100%; padding: 10px 20px;">
      <span @click="closePopup" style="font-size: 20px; font-weight: 700; cursor: pointer;">X</span>
    </div>
    <custom-layout title="건별자료등록" subtitle="건별자료를 등록하는 페이지입니다.">
      <v-card width="90vw" height="80vh" max-width="1000px" max-height="800px" style="padding: 20px;">
        <!-- 조회 조건 -->
        <v-row>
          <v-col style="display: flex;" cols="10">
            <!-- 조회 캠페인명 선택-->
            <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-5 col-12" style="display: flex; justify-content: center; align-items: center">
              <span style="padding-right: 50px; font-weight: 800; font-size: 15px; width: 180px">캠페인명</span>
              <v-select
                  v-model="campaignCd"
                  :items="campaignCdList"
                  item-text="campaignCdNm"
                  item-value="campaignCd"
                  hide-details outlined dense
              />
            </v-col>
            <!-- 조회 캠페인명 선택-->

            <!-- 등록회차 입력 -->
            <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-4 col-12">
              <span style="padding-right: 50px; font-weight: 800; font-size: 15px; width: 150px">등록회차</span>
              <v-text-field
                  v-model="regSeq"
                  hide-details outlined dense
              />
            </v-col>
            <!-- 등록회차 입력 -->
          </v-col>
          <v-col cols="2" style="display: flex; align-items: center;">
            <v-btn class="search-button" @click="getSearch">조회</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col style="display: flex;" cols="10">
            <!-- 사용시작일 -->
            <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-5 col-12" style="display: flex; justify-content: center; align-items: center">
              <span style="padding-right: 50px; font-weight: 800; font-size: 15px; width: 150px">사용시작일</span>
              <v-text-field
                  v-model="useFrDt"
                  hide-details outlined dense disabled
              />
            </v-col>
            <!-- 사용시작일 -->

            <!-- 사용기한 -->
            <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-4 col-12" style="display: flex; justify-content: center; align-items: center">
              <span style="padding-right: 50px; font-weight: 800; font-size: 15px; width: 150px">사용기한</span>
              <v-text-field
                  v-model="useToDt"
                  hide-details outlined dense disabled
              />
            </v-col>
            <!-- 사용기한 -->

            <!-- 배분여부 -->
            <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-4 col-12" style="display: flex; justify-content: center; align-items: center">
              <span style="padding-right: 50px; font-weight: 800; font-size: 15px; width: 150px">배분여부</span>
              <v-select
                  v-model="divYn"
                  :items="divYnList"
                  item-text="divYnNm"
                  item-value="divYn"
                  hide-details outlined dense disabled
              />
            </v-col>
            <!-- 배분여부 -->

            <v-col cols="1" style="display: flex; align-items: center; justify-content: flex-end;">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a class="excel-example"
                     href="/files/campaign_sample.xlsx"
                     download="캠페인관리 엑셀양식.xlsx"
                     v-on="on"
                  >
                    <v-icon>mdi-file-excel</v-icon>
                  </a>
                </template>
                <span>양식 다운로드</span>
              </v-tooltip>
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <!-- 설명 -->
            <v-col class="d-flex align-center col-12" style="display: flex; justify-content: center; align-items: center">
              <span style="padding-right: 50px; font-weight: 800; font-size: 15px; width: 130px">설명</span>
              <v-text-field
                v-model="campaignDesc"
                hide-details outlined disabled
              />
            </v-col>
            <!-- 설명 -->
          </v-col>
          <v-col cols="1" style="display: flex; align-items: center;">
            <v-btn @click="clickUpload" :disabled="campaignList.length > 0">업로드</v-btn>
          </v-col>
          <v-col cols="1" style="display: flex; align-items: center;">
            <v-btn @click="saveData" :disabled="campaignList.length > 0">등록</v-btn>
          </v-col>
        </v-row>
        <!-- 조회 조건 -->

        <!-- 조회 목록 -->
        <v-data-table
          v-if="uploadData.length < 1"
          :headers="getHeader(headers)"
          :items="campaignList"
          :loading="loading"
          item-key="index"
          show-select multi-sort="multi-sort"
          height="30vh"
          max-height="300px"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          fixed-header
        />

        <v-data-table
            v-else-if="uploadData.length > 0"
            :headers="getHeader(headers)"
            :items="uploadData"
            :loading="loading"
            :items-per-page="-1"
            :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
            item-key="index"
            show-select multi-sort="multi-sort"
            :item-class="getRed"
            v-model="selectedItems"
            height="30vh"
            max-height="300px"
            fixed-header
        />
        <div>
          <span style="font-weight: 600; font-size: 15px;">* 메모사항 작성 예: "[캠페인명/계약일/계약보험료/설계지원번호]"</span>
        </div>
      </v-card>
      <input @input="loadData" :accept="SheetJSFT" class="d-none" id="excel" type="file" ref="file" />
    </custom-layout>
  </v-overlay>
</template>

<script>
import CustomLayout from "@/layouts/CustomLayout";
import {axiosInstance} from "@/util/AxiosModule";
import TableMixin from "@/mixin/TableMixin";
import {CAMPAIGN_DATA_SAVE_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import XLSX from "xlsx";
import store from "@/store";

export default {
  props: ["isShow", "selected"],
  mixins: [TableMixin],
  components: {CustomLayout},
  created() {
    this.getCampaignCdList();
  },
  watch: {
    selected(value) {
      if (!_.isEmpty(this.selected)) {
        for (let i = 0; i < value.length; i++) {
          this.campaignCd = value[i].campaignCd;
          this.regSeq = value[i].regSeq;
          this.useFrDt = value[i].useFrDt;
          this.useToDt = value[i].useToDt;
          this.divYn = value[i].divYn;
          this.campaignDesc = value[i].campaignDesc;
        }
      }
    },
    isShow(value) {
      if (value && (!_.isEmpty(this.selected))) {
        this.getSearch();
      }
    }
  },
  data() {
    return {
      campaignCd: "",
      campaignCdList: [
        { campaignCdNm: "선택", campaignCd: "" }
      ],
      regSeq: 0,
      useFrDt: "",
      useToDt: "",
      divYn: "Y",
      campaignDesc: "",
      divYnList: [
        { divYnNm: "Y", divYn: "Y" },
        { divYnNm: "N", divYn: "N" },
      ],
      headers: CAMPAIGN_DATA_SAVE_TABLE,
      campaignInfoData: {},
      campaignList: [],
      loading: false,
      option: {
        sortBy: ['isRed']
      },
      SheetJSFT: ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"].map(x => "." + x).join(","),
      uploadData: [],
      selectedItems: []
    }
  },
  methods: {
    async getCampaignCdList() {
      const { data } = await axiosInstance.get("/public/prdata/code-list", {
        params: {
          cdMasterId: "CAMPAIGN",
          useYn: "Y"
        }
      });
      if (data.length < 1) {
        return false;
      } else {
        for (let i in data) {
          const object = {};
          object.campaignCdNm = data[i].value;
          object.campaignCd = data[i].cd;
          this.campaignCdList.push(object);
        }
      }
    },
    async getSearch() {
      const isCheckCampaignData = this.checkCampaignData();
      if (isCheckCampaignData) {
        const { data } = await axiosInstance.get("/private/operation/campaignList", {
          params: {
            campaignCd: this.campaignCd,
            regSeq: this.regSeq
          }
        });
        if (data.length < 1) {
          alert("등록된 자료가 없습니다. 업로드로 엑셀자료를 등록해주세요.");
        } else {
          this.campaignList = data;
        }
      }
    },
    checkCampaignData() {
      if (this.useToDt === "" || this.useFrDt === "") {
        alert("건별 자료 등록 전 캠페인 목록을 선택 후 다시 이용해주세요.");
        return false;
      }
      return true;
    },
    resetData() {
      this.campaignCd = "";
      this.regSeq = 0;
      this.useFrDt = "";
      this.useToDt = "";
      this.divYn = "Y";
      this.campaignDesc = "";
      this.campaignList = [];
      this.uploadData = [];
      this.selectedItems =[];
    },
    closePopup() {
      this.resetData();
      this.$emit("close");
    },
    getRed(item) {
      return item.state === 'N' ? 'isRed' : '';
    },
    // 업로드 버튼 클릭시 input file
    clickUpload() {
      const isCheckCampaignData = this.checkCampaignData();
      if (isCheckCampaignData) {
        this.$refs.file.click();
      }
    },
    loadData({target: { files }}) {
      if (!files || files.length < 1) {
        alert("올바른 파일이 아닙니다.");
      } else {
        this._setReader().readAsBinaryString(files[0]);
      }
    },
    _setReader() {
      const reader = new FileReader();
      reader.onload = ({target: { result }}) => {
        const {SheetNames, Sheets} = XLSX.read(result, {type: 'binary'});

        Sheets[SheetNames].A1.w = "memberNm";
        Sheets[SheetNames].B1.w = "telNum";
        Sheets[SheetNames].C1.w = "customerNm";
        Sheets[SheetNames].D1.w = "customerTelNum";
        Sheets[SheetNames].E1.w = "customerCarNo";
        Sheets[SheetNames].F1.w = "preInsurerCd";
        Sheets[SheetNames].G1.w = "csId";
        Sheets[SheetNames].H1.w = "memberMemo";
        Sheets[SheetNames].I1.w = "expiryDt";

        const dataList = XLSX.utils.sheet_to_json(Sheets[SheetNames]);
        this.upload(dataList);
      }
      return reader;
    },
    async upload(campaignDtoList) {
      try {
        if (confirm("엑셀을 업로드하시겠습니까?")) {
          const { data } = await axiosInstance.post(`/private/operation/campaignSave`, campaignDtoList,{
            params: {
              actionCd: "U",
              campaignCd: this.campaignCd,
              regSeq: Number(this.regSeq)
            }
          });
          let idx = 0;
          for (const item of data) { item["index"] = idx++; }
          let checkData = [];
          for (let i = 0; i < data.length; i++) {
            checkData = data.map(x => ({
              ...x,
              isSelectable: x.state === "Y" ? true : false
            }));
          }
          this.uploadData = checkData;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async saveData() {
      const isCheckCampaignData = this.checkCampaignData();
      if (isCheckCampaignData) {
        let campaignDtoList = [];
        campaignDtoList = this.selectedItems.map(obj => ({
          csId: obj.csId,
          customerCarNo: obj.customerCarNo,
          customerNm: obj.customerNm,
          customerTelNum: obj.customerTelNum,
          memberMemo: obj.memberMemo,
          memberId: obj.memberId,
          memberNm: obj.memberNm,
          preInsurerCd: obj.preInsurerCd,
          regId: store.getters["login/memberId"],
          telNum: obj.telNum,
          state: obj.state,
          expiryDt: obj.expiryDt
        }));
        try {
          if (confirm(`${this.selectedItems.length}건의 자료를 등록하시겠습니까?`)) {
            await axiosInstance.post(`/private/operation/campaignSave`, campaignDtoList, {
              params: {
                actionCd: "C",
                campaignCd: this.campaignCd,
                regSeq: Number(this.regSeq)
              }
            });
            alert("자료 등록이 완료되었습니다.");
            this.closePopup();
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
}
</script>

<style scoped>
.search-button {
  background-color: #c0c0c0;
}
</style>