var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"text-h6"},[_vm._v("목록 (총 "+_vm._s(_vm.tableData.length)+"개)")]),_c('v-btn',{staticClass:"float-right white--text px-5",attrs:{"color":"blue"},on:{"click":_vm.showModal}},[_vm._v(" 데이터 등록 ")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.tableData,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.headers,"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},scopedSlots:_vm._u([{key:"item.applyDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateFormat(value)))])]}},{key:"item.description",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(value))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.size",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.clickDownload(item)}}},[_vm._v(_vm._s(value))])]}}])})],1),_c('reception-status-modal',{attrs:{"is-show":_vm.isShow},on:{"close":function($event){_vm.isShow = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }