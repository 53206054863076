<template>
  <v-col cols="12" class="py-0">
        <div class="mb-2">
          <span class="text-h6" style="visibility: hidden">상담사별 실적현황표</span>
          <outlined-btn class="float-right" @click="toExcel" >엑셀다운로드</outlined-btn>
        </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :headers="hiddenHeaders"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header
          disable-sort
          calculate-widths>

        <template v-slot:header>
          <thead>
          <!-- 상위 헤더 -->
          <tr class="header-tr">
            <th :rowspan="2">계약일자</th>
            <th :rowspan="2">상담사</th>
            <!-- 경과 별 헤더 병합 -->
            <th colspan="2" v-for="(item, i) in firstHeaderType" :key="i">{{ item }}</th>
          </tr>
          <!-- 하위 헤더 -->
          <tr class="header-tr">
            <th v-for="(item, j) in secondHeader" :key="j">{{ item }}</th>
          </tr>
          </thead>
        </template>

        <template v-slot:item.contractDt="{ value }">
              <div style="text-align: center">{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.csNm="{ value }">
              <div style="text-align: center">{{ value }}</div>
        </template>

        <template v-slot:item.scnt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.scontractPremAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icnt1="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icontractPremAmt1="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icnt2="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icontractPremAmt2="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icnt3="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icontractPremAmt3="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icnt4="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icontractPremAmt4="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icnt5="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.icontractPremAmt5="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import DateMixin from "@/mixin/DateMixin";
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import func from "@/util/func";
import _ from "lodash";

export default {
  components: {OutlinedBtn},
  mixins: [DateMixin, TableMixin],
  props: ["data", "type", "loading"],
  data:() => ({
    firstHeaderType: [],
    header_designSup: ["합계", "요청일 계약체결", "1~3일 경과체결", "4~7일 경과체결", "8~14일 경과체결", "15일이상 경과체결"],
    header_campaign: ["합계", "계약안내일 체결", "1~7일 선 체결", "8일이상 선 체결", "1~7일 경과체결", "8일이상 경과체결"],
    secondHeader: ["건수","보험료","건수","보험료","건수","보험료","건수","보험료","건수","보험료","건수","보험료"],
    hiddenHeaders: [
      {text: "계약일자", value: "contractDt"},
      {text: "상담사", value: "csNm"},
      {text: "건수", value: "scnt"},
      {text: "보험료", value: "scontractPremAmt"}, 		  // 합계
      {text: "건수", value: "icnt1"},
      {text: "보험료", value: "icontractPremAmt1"}, 		// 요청일 계약체결
      {text: "건수", value: "icnt2"},
      {text: "보험료", value: "icontractPremAmt2"}, 		// 1~3 경과체결
      {text: "건수", value: "icnt3"},
      {text: "보험료", value: "icontractPremAmt3"},		  // 4~7 경과체결
      {text: "건수", value: "icnt4"},
      {text: "보험료", value: "icontractPremAmt4"}, 		// 8~14 경과체결
      {text: "건수", value: "icnt5"},
      {text: "보험료", value: "icontractPremAmt5"}, 		// 15 경과체결
    ],
    isCampaign: false,
  }),

  mounted() {
    this.isCampaign = this.type === 'CP';
    this.firstHeaderType = this.isCampaign ? this.header_campaign : this.header_designSup;

    // 실제 헤더 숨김처리
    document.getElementsByClassName("v-data-table-header")[0].style = "display:none;";

    if (!_.isEmpty(this.data)) this.mergeContractDt();
  },

  methods: {
    mergeContractDt() {
      let compareDate = this.data[0].contractDt;
      let count = 0;
      let topSeq = 0; // rowspan을 적용할 최상단 부분

      this.data.forEach((row, i) => {
        if (compareDate === row.contractDt) {
          if (row.contractDt === "합계 ") this.mainTotalColor(i); // 합계 영역 강조
          if (row.csNm === "계") this.subTotalColor(i); // 소계 행 강조
          count++;
        } else {
          this.setRowSpanConDt(topSeq, count);
          this.setTotalGroupLine(i);
          compareDate = row.contractDt;
          topSeq = i;
          count = 1;
        }
      });
      // 마지막 행 처리
      this.setRowSpanConDt(topSeq, count);
    },

    // 계약일자 == "합계" 강조
    mainTotalColor(index){
      document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")[index]
          .getElementsByTagName("td")[0].className = "main-total";
    },
    // 계약일자 하단 라인
    setTotalGroupLine(index){
      document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")[index]
          .getElementsByTagName("td")[0].className = "total-group";
    },
    // 상담사 == "계" 강조
    subTotalColor(index){
      document
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr")[index].className = "sub-total";
    },

    // rowSpan 처리
    setRowSpanConDt(startIdx, count) {
      if (count > 1) {
        document
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[startIdx]
            .getElementsByTagName("td")[0].rowSpan = count;
        // 중복된 행 제거
        for (let i = 1; i < count; i++) {
          document
              .getElementsByTagName("tbody")[0]
              .getElementsByTagName("tr")[startIdx + i]
              .getElementsByTagName("td")[0].style = "display: none";
        }
      }
    },

    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "계약일자": item["contractDt"],
          "상담사": item["csNm"],
          "합계_건수": item["scnt"],
          "합계_보험료": item["scontractPremAmt"],
          ...(!this.isCampaign ? {"요청일 계약체결_건수": item["icnt1"]} : {"계약안내일 체결_건수": item["icnt1"]}),
          ...(!this.isCampaign ? {"요청일 계약체결_보험료": item["icontractPremAmt1"]} : {"계약안내일 체결_보험료": item["icontractPremAmt1"]}),
          ...(!this.isCampaign ? {"1~3일 경과체결_건수": item["icnt2"]} : {"1~7일 선 체결_건수": item["icnt2"]}),
          ...(!this.isCampaign ? {"1~3일 경과체결_보험료": item["icontractPremAmt2"]} : {"1~7일 선 체결_보험료": item["icontractPremAmt2"]}),
          ...(!this.isCampaign ? {"4~7일 경과체결_건수": item["icnt3"]} : {"8일이상 선 체결_건수": item["icnt3"]}),
          ...(!this.isCampaign ? {"4~7일 경과체결_보험료": item["icontractPremAmt3"]} : {"8일이상 선 체결_보험료": item["icontractPremAmt3"]}),
          ...(!this.isCampaign ? {"8~14일 경과체결_건수": item["icnt4"]} : {"1~7일 경과체결_건수": item["icnt4"]}),
          ...(!this.isCampaign ? {"8~14일 경과체결_보험료": item["icontractPremAmt4"]} : {"1~7일 경과체결_보험료": item["icontractPremAmt4"]}),
          ...(!this.isCampaign ? {"15일이상 경과체결_건수": item["icnt5"]} : {"8일이상 경과체결_건수": item["icnt5"]}),
          ...(!this.isCampaign ? {"15일이상 경과체결_보험료": item["icontractPremAmt5"]} : {"8일이상 경과체결_보험료": item["icontractPremAmt5"]}),
        }
        returnData.push(obj);
      }
      let subName = this.isCampaign ? "_캠페인" : "_설계지원";

      const sheetName = `상담사별 실적현황`;
      const fileName = `상담사별 실적현황` + subName;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>

<style scoped>
.v-data-table::v-deep tr:hover {
  background-color: white !important;
}

.v-data-table::v-deep tr > td.main-total,
.v-data-table::v-deep tr.sub-total {
  font-weight: bold;
}

.v-data-table::v-deep tr > td.main-total {
  background-color: rgb(255 165 165 / 0.3);
}

.v-data-table::v-deep tr > td.total-group,
.v-data-table::v-deep tr > td.main-total,
.v-data-table::v-deep tr.sub-total > td {
  border-bottom: 1px solid #868686 !important;
}

::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.header-tr > th{
  text-align: center !important;
}
</style>