<template>
  <v-layout style="border-top: 1px solid #e0e0e0">
    <v-tabs v-model="tab">
      <v-tab>신규등록</v-tab>
      <v-tab>상세조회</v-tab>

      <v-tab-item>
        <ins-ai-chat-management-regist
          :row-data="data"
          @productNm="searchProductNm"
          @result="searchResult"
          @refreshData="refreshData"
        />
      </v-tab-item>
      <v-tab-item>
        <ins-ai-chat-management-detail
          :row-data="data"
          :table-data="tableData"
          @productNm="searchProductNm"
          @result="searchResult"
          @refreshData="refreshData"
        />
      </v-tab-item>
    </v-tabs>

    <product-name-search-popup
        :is-show="isShowProductNm"
        :save-data="productNmData"
        @productNm="getProductNm"
        @close="isShowProductNm = false"
    />

    <result-search-popup
        :is-show="isShowResult"
        :save-data="searchData"
        @result="getResult"
        @close="isShowResult = false"
    />
  </v-layout>
</template>

<script>
import InsAiChatManagementDetail from "@/pages/Manage/InsAiChatManagement/components/InsAiChatManagementDetail";
import InsAiChatManagementRegist from "@/pages/Manage/InsAiChatManagement/components/InsAiChatManagementRegist";
import ProductNameSearchPopup from "@/pages/Manage/InsAiChatManagement/components/Popup/ProductNameSearchPopup";
import ResultSearchPopup from "@/pages/Manage/InsAiChatManagement/components/Popup/ResultSearchPopup";
import _ from "lodash";

export default {
  components: {ResultSearchPopup, ProductNameSearchPopup, InsAiChatManagementRegist, InsAiChatManagementDetail},
  props: ["rowData"],
  created() {
    this.tab = 1;
  },
  watch: {
    rowData(value) {
      if (value) {
        this.tab = 1;
        this.tableData = _.cloneDeep(value);
      }
    }
  },
  data() {
    return {
      tab: [0, 1],
      data: {},
      tableData: {},
      productNmData: {},
      searchData: {},
      isShowProductNm: false,
      isShowResult: false,
      defaultData: {
        mpPrProdLineCd: "",
        insCd: "",
        prProdLineCd: "",
        useYn: "Y"
      }
    }
  },
  methods: {
    searchProductNm(data) {
      this.productNmData = data;
      this.isShowProductNm = true;
    },
    searchResult(data) {
      this.searchData = data;
      this.isShowResult = true;
    },
    getProductNm(productData) {
      this.data = _.cloneDeep(productData);
    },
    getResult(resultData) {
      this.data = _.cloneDeep(resultData);
    },
    refreshData() {
      this.tab = 1;
      this.data = {};
      this.tableData = this.defaultData;
      this.$emit("refreshData");
    }
  }
}
</script>