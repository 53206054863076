<template>
  <search-bar-layout>
    <template v-slot:contents>
      <v-row>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">기준년월</v-col>
          <v-text-field
            v-model="detailParams.baseYm"
            outlined dense hide-details
            background-color="white"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
          <v-select
              v-model="detailParams.contractInsurer"
              :items="contractInsurerList"
              item-text="contractInsurerNm"
              item-value="contractInsurer"
              outlined dense hide-details
              background-color="white"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">채널</v-col>
          <v-select
              v-model="detailParams.joinChannel"
              :items="joinChannelList"
              item-text="joinChannelNm"
              item-value="joinChannel"
              outlined dense hide-details
              background-color="white"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">회원명</v-col>
          <v-text-field
              v-model="detailParams.memberNm"
              outlined dense hide-details
              background-color="white"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">전화번호</v-col>
          <v-text-field
              v-model="detailParams.memberTelNum"
              outlined dense hide-details
              background-color="white"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">지급상태</v-col>
          <v-select
            v-model="detailParams.paymentStatus"
            :items="paymentStatusList"
            item-text="paymentStatusNm"
            item-value="paymentStatus"
            outlined dense hide-details
            background-color="white"
          />
        </v-col>
        <v-col class="d-flex align-center justify-end col-xl-3 col-lg-3 col-md-12 col-12">
          <v-btn @click="search">조회</v-btn>
        </v-col>
      </v-row>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import _ from "lodash";

export default {
  components: {SearchBarLayout},
  props: ["rowData"],
  watch: {
    rowData(value) {
      this.detailParams = _.cloneDeep(value);
    }
  },
  data() {
    return {
      contractInsurerList: [
        { contractInsurerNm: "전체", contractInsurer: "" },
        { contractInsurerNm: "현대해상", contractInsurer: "HD" },
        { contractInsurerNm: "메리츠화재", contractInsurer: "MZ" },
        { contractInsurerNm: "삼성화재", contractInsurer: "SS" },
        { contractInsurerNm: "흥국화재", contractInsurer: "HK" },
        { contractInsurerNm: "KB손해보험", contractInsurer: "KB" },
        { contractInsurerNm: "AXA손해보험", contractInsurer: "AXA" },
        { contractInsurerNm: "한화손해보험", contractInsurer: "HW" },
        { contractInsurerNm: "DB손해보험", contractInsurer: "DB" },
        { contractInsurerNm: "하나손해보험", contractInsurer: "HN" },
        { contractInsurerNm: "캐롯손해보험", contractInsurer: "CR" }
      ],
      joinChannelList: [
        { joinChannelNm: "전체", joinChannel: "" },
        { joinChannelNm: "CM", joinChannel: "CM" },
        { joinChannelNm: "TM", joinChannel: "TM" }
      ],
      paymentStatusList: [
        { paymentStatusNm: "전체", paymentStatus: "" },
        { paymentStatusNm: "정산완료", paymentStatus: "정산완료" },
        { paymentStatusNm: "환수대상", paymentStatus: "환수대상" },
        { paymentStatusNm: "신규생성", paymentStatus: "신규생성" }
      ],
      detailParams: {}
    }
  },
  methods: {
    search() {
      const params = {
        baseYm: this.detailParams.baseYm ? this.detailParams.baseYm.replace(/\s/g, '') : '',
        contractInsurer: this.detailParams.contractInsurer,
        joinChannel: this.detailParams.joinChannel,
        memberNm: this.detailParams.memberNm ? this.detailParams.memberNm.replace(/\s/g, '') : '',
        memberTelNum: this.detailParams.memberTelNum ? this.detailParams.memberTelNum.replace(/\D/g, '') : '',
        paymentStatus: this.detailParams.paymentStatus
      };
      this.$emit("search", params);
    }
  }
}
</script>