<script>
import { Line } from 'vue-chartjs';

export default {
  props: ["totArry", "monthArray", "insuredNm"],
  extends: Line,
  data() {
    return{
      datasets : [
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#6699FF",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#669900",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#CC9900",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#990099",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#6633FF",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#00CC99",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#FF3333",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
        {
          label: "",
          type: "line",
          data: [],
          borderColor: "#f0b4b4",
          backgroundColor: "rgb(0,0,0,0)",
          borderWidth: 3,
          tension: 0
        },
      ],
    }
  },

  mounted() {
    let reverse = this.monthArray.reverse();

    //Array name label 배열 복사
    let totalNmArray = [];
    this.insuredNm.forEach(function(item){
      totalNmArray.push(item);
    })

    for (let i = 0; i < totalNmArray.length; i++) {
      if (totalNmArray[i] === '소계') {
        totalNmArray.splice(i, 1);
      }
    }

    for (let i = 0; i < totalNmArray.length; i++) {
      this.datasets[i].label = totalNmArray[i];
    }

    for (let j = 0; j < this.totArry[0].value.length; j++) {
          if (j%3 === 0) {
          this.datasets[0].data.push(this.totArry[0].value[j])
          this.datasets[1].data.push(this.totArry[1].value[j])
          this.datasets[2].data.push(this.totArry[2].value[j])
          this.datasets[3].data.push(this.totArry[3].value[j])
          this.datasets[4].data.push(this.totArry[4].value[j])
          this.datasets[5].data.push(this.totArry[5].value[j])
          this.datasets[6].data.push(this.totArry[6].value[j])
          this.datasets[7].data.push(this.totArry[7].value[j])
      }
    }

    this.renderChart(
        {
          labels: reverse,
          datasets: this.datasets,
        },
        {
          responsive: true, maintainAspectRatio: false, beginAtZero: true,
          tooltips: {
            callbacks: {
              label: function (tooltipItem) { //그래프 콤마
                return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true,
                callback: function(value) {
                  if(parseInt(value) >= 1000){
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return value
                  }
                }
              }
            }]
          }
        }
    );
  },
  watch: {
    totArry: function () {
      //Array name label 배열 복사
      let totalNmArray = [];

      this.insuredNm.forEach(function(item){
        totalNmArray.push(item);
      })

      for (let i = 0; i < totalNmArray.length; i++) {
        if (totalNmArray[i] === '소계') {
          totalNmArray.splice(i, 1);
        }
      }

      for (let i = 0; i < totalNmArray.length; i++) {
        this.datasets[i].label = totalNmArray[i];
      }
      for (let i = 0; i < 8; i++) {
        this.datasets[i].data = [];
      }
      for (let j = 0; j < this.totArry[0].value.length; j++) {
        if (j%3 === 0) {
          this.datasets[0].data.push(this.totArry[0].value[j])
          this.datasets[1].data.push(this.totArry[1].value[j])
          this.datasets[2].data.push(this.totArry[2].value[j])
          this.datasets[3].data.push(this.totArry[3].value[j])
          this.datasets[4].data.push(this.totArry[4].value[j])
          this.datasets[5].data.push(this.totArry[5].value[j])
          this.datasets[6].data.push(this.totArry[6].value[j])
          this.datasets[7].data.push(this.totArry[7].value[j])
        }
      }
      if (this.insuredNm.length > 10 ) {
        alert("새로고을 해주세요.");
        return;
      }
      this.$data._chart.update();
    },
    data: function () {
      let reverse = this.monthArray.reverse();

      this.renderChart(
          {
            labels: reverse,
            datasets: this.datasets,
          },
          {
            responsive: true, maintainAspectRatio: false, beginAtZero: true,
            tooltips: {
              callbacks: {
                label: function (tooltipItem) { //그래프 콤마
                  return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
              },
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero:true,
                  callback: function(value) {
                    if(parseInt(value) >= 1000){
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                      return value
                    }
                  }
                }
              }]
            }
          }
      );
    }
  }
}
</script>