var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"text-h6"},[_vm._v("목록 (총 "+_vm._s(_vm.data.length)+"개)")]),_c('outlined-btn',{staticClass:"float-right",attrs:{"disabled":_vm.isEmptySelectedList},on:{"click":function($event){return _vm.checkApproval('N')}}},[_vm._v(" 불인 ")]),_c('outlined-btn',{staticClass:"float-right",staticStyle:{"margin-right":"10px"},attrs:{"disabled":_vm.isEmptySelectedList},on:{"click":function($event){return _vm.checkApproval('Y')}}},[_vm._v(" 승인 ")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.data,"item-key":"index","loading":_vm.loading,"items-per-page":-1,"headers":_vm.headers,"footer-props":{'items-per-page-options': [10, 30, 50, -1]},"fixed-header":"","calculate-widths":""},scopedSlots:_vm._u([{key:"item.selectedYn",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-checkbox',{on:{"change":function($event){return _vm.selectRow(item, value)}},model:{value:(item.selectedYn),callback:function ($$v) {_vm.$set(item, "selectedYn", $$v)},expression:"item.selectedYn"}})],1)])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.contentsDataFormatter(value)))])]}},{key:"item.menuNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(value))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.condition",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(_vm._s(_vm.sliceLongString(value)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }