<template>
  <search-page-layout title="블랙리스트 관리">
    <template v-slot:searchBar>
      <black-list-search-bar
          ref="search"
          @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <black-list-table
          :data="data"
          :loading="loading"
          @selectRow="selectRow"
      />
    </template>

    <template v-slot:inflowHist>
      <black-list-save
          ref="saveTable"
          :key="editKey"
          :rowData="rowData"
          @refreshList="refreshList"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import BlackListSearchBar from "@/pages/User/components/BlackList/BlackListSearchBar";
import BlackListTable from "@/pages/User/components/BlackList/BlackListTable";
import BlackListSave from "@/pages/User/components/BlackList/BlackListSave";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {BlackListSave, BlackListTable, BlackListSearchBar, SearchPageLayout},
  data() {
    return {
      loading: false,
      data: [],
      rowData: [],
      editKey: 0
    }
  },
  methods: {
    // 조회
    async getSearch(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/blackList', {params});
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].index = i;
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.$refs.saveTable.resetData();
    },

    selectRow(row) {
      this.rowData = row;
      this.editKey++;
    },

    refreshList(){
      this.$refs.search.searchBlackList();
    }
  }
}
</script>