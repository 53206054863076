import _ from 'lodash';
import axios from 'axios';
import store from "@/store";

export const axiosInstance = axios.create({
   timeout: 60000,
   headers: {
       "Content-Type": "application/json",
   }
});
axiosInstance.interceptors.response.use(
    (res) => (res),
    (error) => {
        const { response: { status } } = error;
        if (status === 401) { alert("로그인 유지 시간이 만료되었습니다. 다시 로그인하여 주세요.") }
        else if (status === 403) { alert("관리자만 접속 가능합니다.") }
        else if (status === 400 && !_.isEmpty(error.response.data.message)) {alert(error.response.data.message)}
        // else { alert("관리자에게 문의해주세요.")}
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.request.use(
    (config) => {
        const token = store.state.login.accessToken;
        if(token) { config.headers.Authorization = `Bearer ${token}` }
        return config;
    },
    (error) => (Promise.reject(error))
)
