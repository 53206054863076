<template>
  <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
    <back-btn />
    <v-card-title>마스터 코드</v-card-title>
    <v-divider/>

    <v-form v-model="valid">
      <v-row>
        <v-col cols="6" :class="FIELD_CLASS">
          <span :class="CREATE_FIELD_CLASS">코드 ID</span>
          <v-text-field style="background-color:white;"
                        class="mx-3"
                        :disabled="true"
                        :rules="[ value => !!value || '내용을 입력해주세요 ']"
                        v-model="cd.cdMasterId"
                        full-width hide-details outlined dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="d-flex align-center pr-5">
          <span :class="CREATE_FIELD_CLASS">데이터 형식</span>
          <span>{{ cd.type }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" :class="FIELD_CLASS">
          <span :class="CREATE_FIELD_CLASS">생성일자</span>
          <v-text-field style="background-color:white;"
                        class="mx-3" :disabled="true"
                        v-model="cd.createDate"
                        full-width hide-details outlined dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" :class="FIELD_CLASS">
          <span :class="CREATE_FIELD_CLASS">수정일자</span>
          <v-text-field style="background-color:white;"
                        class="mx-3" :disabled="true"
                        v-model="cd.updateDate"
                        full-width hide-details outlined dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :class="CREATE_FIELD_CLASS">
          <span class="col-3">설명</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
              solo
              :disabled="!isModifying"
              :rules="[ value => !!value || '내용을 입력해주세요 ']"
              rows="3"
              v-model="cd.cdDesc"
          />
        </v-col>
      </v-row>
    </v-form>

    <code-modify-btn
        :modify="isModifying"
        :valid="valid"
        :detail-codes="detailCodes"
        @save="save"
        @delete="deleteMC"
        @set="setModifying"
        @cancel="cancelModifying"
    />

    <v-divider class="my-3"/>

    <master-code-table-header
      :cd-master-id="cd.cdMasterId"
      :item-length="selected.length"
      :total-length="detailCodes.length"
      :is-immediately="true"
      :type="cd.type"
      @delete="deleteDC"
      @refreshDetail="refreshDetail"
    />
    <v-col cols="12" class="py-0">
      <code-table
          :isMaster="false"
          :headers="cd.type === 'KEY' ? KEY_CODE_HEADER : JSON_CODE_HEADER"
          :items="detailCodes"
          :parentsSelected="selected"
          :isKey="cd.type === 'KEY'"
          @select="select"
      />
    </v-col>
  </v-card>
</template>

<script>
import _ from 'lodash';
import {axiosInstance} from "@/util/AxiosModule";
import { KEY_CODE_HEADER, JSON_CODE_HEADER } from "@/util/TableHeaders";
import CodeTable from "@/pages/Manage/Code/components/common/CodeTable";
import CodeModifyBtn from "@/pages/Manage/Code/components/common/CodeModifyBtn";
import MasterCodeTableHeader from "@/pages/Manage/Code/components/MasterCode/MasterCodeTableHeader";
import BackBtn from "@/pages/Manage/Code/components/common/BackBtn";
import router from "@/router";
import CodeMixin from "@/mixin/CodeMixin";
import {mapGetters} from "vuex";

export default {
  mixins: [CodeMixin],
  components: {BackBtn, MasterCodeTableHeader, CodeModifyBtn, CodeTable},
  async created() {
    const { data : {cdMasterDto, cdDetailDtoList} } = await axiosInstance.get('/admin/code/master-detail', {
      params : {
        cdMasterId : this.cdMasterId,
        type: this.type
      }
    });

    if(_.isEmpty(cdMasterDto)) {
      alert("존재하지 않는 코드입니다.");
      router.back();
    } else {
      this.cd = cdMasterDto;
      this.detailCodes = cdDetailDtoList;
      this.backup = _.cloneDeep(cdMasterDto);
    }
  },
  computed: {
    ...mapGetters("code", ["cdMasterId", "type"])
  },
  data: () => ({
    cd: {},
    backup: {},
    selected: [],
    detailCodes: [],
    KEY_CODE_HEADER: KEY_CODE_HEADER,
    JSON_CODE_HEADER: JSON_CODE_HEADER,
    valid: false,
    isModifying: false,
  }),
  methods: {
    setModifying() {
      this.backup = _.cloneDeep(this.cd);
      this.isModifying = true;
    },
    cancelModifying() {
      this.isModifying = false;
      this.cd = this.backup;
    },
    async save() {
      if(!confirm("데이터를 수정하시겠습니까?")) return;
      try{
        await axiosInstance.put('/admin/code/master', this.cd);
        this.isModifying = false;
      } catch(e) {
        console.log(e);
        this.cd = this.backup;
        this.isModifying = false;
      }
    },
    update(data, name) {
      this.backup[name] = data;
    },
    getValue(value, name) {
      if(value) return value;
      return this.isModifying ? this.backup[name] : this.cd[name]
    },
    async deleteMC() {
      if(!confirm("해당 Master Code 를 삭제하시겠습니까?")) return;

      try{

        await axiosInstance.delete('/admin/code/master', {
          data : this.cd
        });

        alert("코드가 삭제 되었습니다!");

        await router.push({name : 'SearchCode'});

      } catch(e) {
        console.log(e);
      }
    },
    refreshDetail(detailCode) {
      this.detailCodes = detailCode;
    },
    select(selected) {
      this.selected = selected
    },
    async deleteDC() {
      if(!confirm(`${this.selected.length}개의 디테일 코드를 삭제하시겠습니까?`)) return;

      try{
        for(const item of this.selected) {
          item.type = this.cd.type;

          await axiosInstance.delete('/admin/code/detail', {
            data: item
          });

          // [프로그램 권한 관리] 변경 시 목록 재설정 - 1) 일괄 삭제
          if (this.cdMasterId === "PROG_PRV_USER") {
            this.$store.dispatch("login/setPrivilegeGroup");
          }
        }

        alert("선택한 코드들이 삭제되었습니다!");

        this.selected = [];

      } catch (e) {
        console.log(e);
        return;
      }

      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : this.cd.cdMasterId,
            type: this.cd.type
          }
        });

        this.detailCodes = data;
      } catch(e) {
        console.log(e);
      }
    },

  }
}
</script>
<style scoped>
</style>