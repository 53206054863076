<template>
  <v-col cols="12" class="py-0">
    <div class="my-2">
      <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
      <span class="total-sumReward">총 지급액 : {{ inputComma(totalSumReward) }}원</span>
      <outlined-btn class="float-right" @click="$emit('toExcel')" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
      <outlined-btn class="float-right" style="margin-right: 10px; background-color: #ffc400"
                    v-show="showButton"
                    :disabled="!hasSelectData"
                    @click="sendAlarmTalk">알림톡 발송
      </outlined-btn>
      <v-divider class="my-2"/>
      <div class="d-flex align-center">
        <field-input title="일괄 상태 업데이트">
          <v-select
              v-model="status" class="mr-2"
              :disabled="!hasSelectData" :items="REWARD_STATUS"
              background-color="white" menu-props="auto"
              hide-details outlined dense
          />
          <v-select
              v-model="remarks" class="mr-2" :items="REMARKS"
              :disabled="!hasSelectData || checkRemarksDisabled"
              background-color="white" menu-props="auto"
              hide-details outlined dense
          />
        </field-input>
        <outlined-btn @click="updateRow" :disabled="checkSaveBtn">선택건 저장</outlined-btn>
        <outlined-btn @click="bankInputRequest" :disabled="!hasSelectData" class="ml-auto">계좌입력요청</outlined-btn>
      </div>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          v-model="selectData"
          item-key="index"
          :items="tableData"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          show-select multi-sort="multi-sort"
          :headers="getHeader(header)"
      >

        <template v-slot:item.hasSuccess="{ value }">
          {{ value ? '기존' : '신규' }}
        </template>

        <template v-slot:item.plusReward="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.minusReward="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.sumReward="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.telNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <template v-slot:item.ssno="{ value }">
          {{ value ? `${value.slice(0, 6)}-${value.slice(6)}` : '' }}
        </template>

        <template v-slot:item.rewardStatusValue="{ value, item }">
          <span :class="getColorRewardStatusValue(value)">{{ item["remark"] == 'ERROR_BLACK_LIST' ? '지급정지' : value }}</span>
        </template>

        <template v-slot:item.remark="{ value }">
          <span class="red--text">{{ searchByCodeInSelectList(REMARKS, value) }}</span>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import _ from 'lodash';
import {PAYMENT_HEADER} from "@/util/TableHeaders"
import CodeMixin from "@/mixin/CodeMixin";
import FieldInput from "@/components/Form/FieldInput";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";

export default {
  components: {OutlinedBtn, FieldInput},
  mixins: [CodeMixin],
  props: ["loading", "tableData", "settlementDate", "REWARD_STATUS", "REMARKS", "statusData", "showButton"],
  computed: {
    hasTableData() {
      return this.tableData.length > 0;
    },
    hasSelectData() {
      return this.selectData.length > 0;
    },
    checkRemarksDisabled() {
      return this.status !== "FAIL";
    },
    checkSaveBtn() {
      if (this.status === "TARGET" || this.status === "SUCCESS") return false;
      else if (this.status === "FAIL" && this.remarks !== "") return false;
      else return true;
    }
  },
  watch: {
    tableData: function (value) {
      if (_.isEmpty(value)) {
        this.status = this.remarks = "";
        this.selectData = [];
      }
      if (this.totalSumReward !== 0) {
        this.totalSumReward = 0;
      }
      for (let i in this.tableData) {
        this.totalSumReward += this.tableData[i].sumReward;
      }
    },
    status: function () {
      if (this.checkRemarksDisabled) {
        this.remarks = "";
      }
    },
    selectData: function () {
      if (!this.hasSelectData) this.status = this.remarks = "";
    }
  },
  data: () => ({
    header: PAYMENT_HEADER,
    commaList: ["sumReward"],
    status: "",
    remarks: "",
    selectData: [],
    totalSumReward: 0
  }),
  methods: {
    getHeader(headers) {
      return headers.map(header => ({
        text: header[0],
        value: header[1],
        align: 'center',
        class: this.commaList.includes(header[1]) ? 'tar headers text-no-wrap' : 'headers text-no-wrap',
        divider: true,
        fixed: true,
      }))
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getTelNum(telNum) {
      if(!telNum) return "";
      return telNum.length === 11 ? `${telNum.slice(0, 3)}-${telNum.slice(3, 7)}-${telNum.slice(7)}` : telNum;
    },
    updateRow() {
      if (!confirm(`선택한 ${this.selectData.length}건에 대한 상태를 업데이트 하시겠습니까?`)) return;

      try {
        this.$emit("updateRow", this.selectData, this.status, this.remarks);
        this.selectData = [];
        this.status = this.remarks = "";
      } catch (e) {
        console.log(e);
      }
    },
    bankInputRequest() {
      if (!confirm(`선택한 ${this.selectData.length}건에 대해 계좌입력 요청 하시겠습니까?`)) return;

      try {
        this.$emit("bankInputRequest", this.selectData);
        this.selectData = [];
        this.status = this.remarks = "";
      } catch (e) {
        console.log(e);
      }
    },
    getColorRewardStatusValue(rewardStatusValue) {
      if (rewardStatusValue === "지급불가") {
        return "red--text bolder";
      } else if (rewardStatusValue === "지급완료") {
        return "green--text";
      }
    },
    sendAlarmTalk() {
      if (!confirm(`선택한 ${this.selectData.length}건에 대한 알림톡을 발송하시겠습니까?`)) return;

      try {
        this.$emit("sendAlarmTalk", this.selectData);
        this.selectData = [];
        this.status = this.remarks = "";
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>
.tar {
  text-align: right;
}
.total-sumReward {
  margin-left: 20px;
  font-weight: 600;
  font-size: 19px;
}
.bolder {
  font-weight: bold;
}
</style>