<template>
  <search-page-layout title="컨텐츠 발송이력 조회">

    <template v-slot:searchBar>
      <content-history-search-bar
      @search="getHistory"
      />
    </template>

    <template v-slot:table>
      <content-history-search-table
          :type="typeNm"
        :data="data"
        :loading = "loading"
        @toExcel="toExcel"
      />
    </template>

  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "../../layouts/SearchPageLayout";
import ContentHistorySearchBar from "./components/ContentHistory/ContentHistorySearchBar";
import ContentHistorySearchTable from "./components/ContentHistory/ContentHistorySearchTable";
import _ from "lodash";
import func from "@/util/func";

export default {
  components: {ContentHistorySearchTable, ContentHistorySearchBar, SearchPageLayout},
  data: () => ({
    loading: false,
    data: [],
    typeNm: [],
  }),
  methods: {
    async getHistory(contSendHist) {
      this.loading = true;
      this.typeNm = contSendHist.contType;

      try {
        const {data} = await axiosInstance.post('/admin/operation/contSendHist', contSendHist);
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "컨텐츠명": item["contTypeNm"],
          "회원명": item["memberNm"],
          "전화번호": item["telNum"],
          "발송건수": item["sendCnt"],
          "From일자": item["fromDate"],
          "To일자": item["toDate"],
        }
        returnData.push(obj);
      }

      const sheetName = `컨텐츠 발송이력 조회`;
      const fileName = `컨텐츠 발송이력 조회 List`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>