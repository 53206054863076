<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
    <v-btn
        color="grey"
        class="white--text px-10"
        style="margin-right: 4px;"
        @click="showModal"
    >플랜상세</v-btn>
    <v-btn
        color="grey"
        class="white--text px-8"
        style="margin-right: 4px;"
        @click="toExcel"
        :disabled="data.length <= 0"
    >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.prNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...") }}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.insTrmList="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...") }}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>

    <product-plan-management-detail-modal
      :rowData="rowData"
      :is-show="isShowModal"
      @close="isShowModal = false"
    />
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {PRODUCT_PLAN_MANAGEMENT_TABLE} from "@/util/TableHeaders";
import ProductPlanManagementDetailModal
  from "@/pages/Product/components/ProductPlanManagement/ProductPlanManagementDetailModal";
import _ from "lodash";
import func from "@/util/func";
import DateMixin from "@/mixin/DateMixin";

export default {
  components: { ProductPlanManagementDetailModal},
  mixins: [TableMixin, DateMixin],
  props: ["data", "loading"],
  data() {
    return {
      headers: PRODUCT_PLAN_MANAGEMENT_TABLE,
      isShowModal: false,
      rowData: [],
    }
  },
  methods: {
    clickContents(row) {
      this.rowData = row;
      this.$emit("row", row);
    },
    showModal() {
      this.isShowModal = true;
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "상품군명": item["prProdLineNm"],
          "보험사명": item["insNm"],
          "상품코드": item["prCd"],
          "상품명": item["prNm"],
          "플랜코드": item["planCd"],
          "플랜명": item["planNm"],
          "보험기간목록": item["insTrmList"],
          "갱신주기목록": item["rnwCycleList"],
          "납입기간목록": item["pyTrmList"],
          "납입주기목록": item["pyCycleList"],
          "남자가입연령_FR": item["mfrAge"],
          "남자가입연령_TO": item["mtoAge"],
          "여자가입연령_FR": item["ffrAge"],
          "여자가입연령_TO": item["ftoAge"],
          "추천점수": item["prScore"],
          "사용여부": item["useYn"],
          "등록일시": item["createDate"],
        }
        returnData.push(obj);
      }
      const sheetName = `상품플랜 관리`;
      const fileName = `상품플랜 관리`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>