<template>
  <v-overlay
    v-model="isAlarmShow"
    :light="true"
    :dark="false"
    >
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card max-width="95vw" max-height="100vh" class="overflow-y-auto" style="background:#F6F8FC">
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">알림톡 발송</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <main id="subscription">
          <div v-if="checkRage === 'AS' || checkRage === 'AP'" class="main-inner">
            <ul class="btn-box clear">
              <li>
                <p class="sum" style="margin-top:16px; margin-left:15px; margin-right: 15px;">
                  ※인카금융서비스 자회사 원클릭다이렉트※<br>
                  ㅇㅇㅇ님 안녕하세요.<br>
                  인카금융서비스 입사를 축하드립니다.<br>
                  입사 등록과 함께 원클릭다이렉트 정회원 등록이 자동으로 되어 다이렉트자동차보험계약 등 영업 지원 서비스 이용이 가능합니다.<br>
                  초기 설정 비밀번호는 !@생년월일 입니다. (예: !@720405 )<br>
                  로그인 후 비밀번호를 설정해주세요.<br>
                  ▼ 원클릭다이렉트 접속하기 ▼</p>
                <button type="button" @click="submit('06')">전송</button>
              </li>
            </ul>
          </div>
        </main>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import store from "@/store";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props: ["isAlarmShow", "selectData", "checkRage"],
  data:() => ({

  }),
  methods: {
    async submit(val) {
      for (let i = 0; i < this.selectData.length; i++) {
        this.selectData[i].chkYn = 'Y';
        delete this.selectData[i].index;
      }

      const memberList = this.selectData;

      try {
        const {data} = await axiosInstance.post('/admin/operation/performance-memberdiv-talk', memberList, {
          params: {
            contContentsCd: val,
            regId: store.getters['login/memberId']
          }
        });
        if (data !== 0 ) {
          alert("전송되었습니다.");
          this.$emit('close');
        } else {
          alert("전송 실패했습니다.");
        }
      }catch (e) {
        console.log(e);
      }

    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
#subscription {
  background:#F6F8FC;
  overflow:visible;
}

#subscription .main-inner {
  padding:30px 30px 20px 30px;
  margin:0 auto;
}
.btn-box {
  padding-right: 24px;
}
#subscription .main-inner .btn-box li {
  background:#FFFFFF;
  box-shadow:0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius:12px;
  overflow:hidden;
  padding-bottom:22px;
  text-align:center;
}
#subscription .main-inner .btn-box li + li {
  margin-top:30px;
}
#subscription .main-inner .btn-box .sum {
  font-size:16px;
  line-height:36px;
  font-weight:700;
  color:#222;
  margin-top:20px;
}
#subscription .main-inner .btn-box li + li .sum {
  margin-top:2px;
}
#subscription .main-inner .btn-box button {
  font-size:16px;
  font-weight:800;
  color:#1772C7;
  display:block;
  width:70px; height:30px;
  margin:20px auto 0;
  border-radius:4px;
  border:1px solid #1772C7;
}
#subscription .main-inner .btn-box li + li button {
  color:#4745B5;
  border-color:#4745B5;
}
#subscription .main-inner .btn-box li + li + li button {
  color:#ff3d00;
  border-color:#ff3d00;
}
#subscription .main-inner .btn-box + p {
  font-size:16px;
  font-weight:700;
  line-height:150%;
  color:#72819A;
  margin:24px 0 60px;
  text-align:center;
}
#subscription .main-inner {
  background:#F6F8FC;
  border-radius:12px;
  border:1px solid #F6F8FC;
  padding:16px 16px 24px;
  color:#222;
  letter-spacing:-0.02em;
}
#subscription .main-inner .account p {
  font-size:16px;
  font-weight:700;
}
</style>