<template>
  <v-overlay
      v-model="isShow"
      :key="popupkey"
      :light="true"
      :dark="false">
    <v-card width="80vw" max-width="1015px" max-height="97vh" class="overflow-y-auto py-3 px-6">
      <v-card-title class="headline">
        <v-col cols="12">
          <span class="font-weight-bold">상품 기간 정보</span>
        </v-col>
      </v-card-title>
      <div class="px-3">
        <v-col cols="12">
          <span class="font-weight-bold">
            {{ productInfo.prodLineNm }} 보험 | {{ productInfo.insNm }}
            </span>
          <br/>
          <span>
            {{ productInfo.prCd }} | {{ productInfo.prNm }}
          </span>
        </v-col>
      </div>
      <div class="my-2 px-3">
        <span class="text-h6">목록 (총 {{ rowData.length }}개)</span>
      </div>
      <v-data-table
          class="px-5"
          height="50vh"
          :items="rowData"
          :headers="getHeader(headers)"
          fixed-header calculate-widths
          :footer-props="{'items-per-page-options': [10, -1]}"
          :loading = "loading"
          @click:row="clickRow"
      >
      </v-data-table>
      <!-- 기간정보 수정 영역 -->
      <v-row>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">보험기간</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="selectedRow.prodInsTrm"
                :disabled="!isEditing"
                outlined dense hide-details
                type="number"
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">납입기간</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="selectedRow.prodPyTrm"
                :disabled="!isEditing"
                outlined dense hide-details
                type="number"
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">기간유형</v-col>
          <v-col cols="8">
            <v-select
                v-model="selectedRow.insTrmType"
                :items="insTrmTypeList"
                item-text="insTrmTypeNm"
                item-value="insTrmType"
                :disabled="!isEditing"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
      </v-row>

      <v-card-actions class="px-4">
        <!-- 수정하기 -->
        <v-btn
            v-if="!isEditing"
            color="green darken-4"
            class="white--text col-1"
            :disabled="selectedIndex == -1"
            @click="editTrmData">
          수정
        </v-btn>
        <v-btn
            v-else
            color="yellow darken-4"
            class="white--text col-1"
            :disabled="selectedIndex == -1"
            @click="editComplete">
          수정완료
        </v-btn>
        <v-btn
            v-if="!isEditing"
            color="black darken-4"
            class="white--text col-1"
            :disabled="selectedIndex == -1"
            @click="deleteTrmData">
          삭제
        </v-btn>
        <v-btn
            v-else
            color="grey darken-4"
            class="white--text col-1"
            :disabled="selectedIndex == -1"
            @click="editCancel">
          취소
        </v-btn>
        <v-spacer></v-spacer>
        <!-- 추가하기 -->
        <v-btn
            v-if="!isAdding"
            color="blue darken-4"
            class="white--text col-2"
            :disabled="isEditing"
            @click="addTrmData">
          추가하기
        </v-btn>
        <v-btn
            v-if="isAdding"
            color="blue darken-4"
            class="white--text col-1"
            :disabled="invalidTrmData"
            @click="addComplete">
          추가완료
        </v-btn>
        <v-btn
            v-if="isAdding"
            color="black darken-4"
            class="white--text col-1"
            @click="addCancel">
          취소
        </v-btn>
        <!-- 닫기 -->
        <v-btn
            color="red darken-4"
            class="white--text col-1"
            @click="close">
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
// import store from "@/store";
import _ from "lodash";
import TableMixin from "@/mixin/TableMixin";
import {axiosInstance} from "@/util/AxiosModule";
import {PRODUCT_INFORMATION_MANAGEMENT_TABLE_MODAL} from "@/util/TableHeaders";

export default {
  mixins: [TableMixin],
  props: ["isShow", "productInfo", "tableData", "loading"],

  data() {
    return {
      popupkey: 0,
      rowData: [],
      headers: PRODUCT_INFORMATION_MANAGEMENT_TABLE_MODAL,

      insTrmTypeList: [
        {insTrmTypeNm: "세만기_고정형", insTrmType: "1"},
        {insTrmTypeNm: "세만기_연령차감", insTrmType: "2"},
        {insTrmTypeNm: "년만기", insTrmType: "3"}
      ],

      isAdding: false,   // 추가하기
      isEditing: false,  // 수정 시 버튼 변경

      // 입력란에 사용
      selectedRow: {
        prProdLineCd: "",
        insCd: "",
        prCd: "",
        prodInsTrm: 0,
        prodPyTrm: 0,
        insTrmType: ""
      },
      // update시 변경할 row 설정
      originalRow: {},
      selectedIndex: -1,
    }
  },
  computed: {
    // 보험기간 & 납입기간 & 기간타입 유효체크
    invalidTrmData() {
      return this.selectedRow.prodInsTrm <= 0
          || this.selectedRow.prodPyTrm <= 0
          || _.isEmpty(this.selectedRow.insTrmType)
    }
  },
  created(){
    this.rowData = _.cloneDeep(this.tableData);
  },

  mounted() {
    // 추가하기 기본 설정
    this.initProductInfo();
  },
  methods: {
    clickRow(row) {
      this.isAdding = false;
      let idx = this.rowData.findIndex(el => el == row);
      if (this.selectedIndex !== idx) {
        this.selectedIndex = idx;
        this.isEditing = false;
        this.originalRow = row;
        this.selectedRow = _.cloneDeep(row);
      }
    },
    // 추가하기
    addTrmData() {
      this.initTrmData();
      this.isEditing = true;
      this.isAdding = true;
    },
    // 추가 - 완료
    async addComplete() {
      if (this.invalidTrmData) {
        alert('유효한 값을 입력해 주세요.');
        return false;
      }
      if (this.hasSameData()) {
        alert('데이터가 이미 존재합니다.');
        return false;
      }

      let i_result = await this.setPrProdInsPyTrm("I", this.selectedRow);

      if(i_result == 0){
        alert('FAIL : 데이터가 추가되지 않았습니다.');
        return false;
      }

      alert("상품 기간 정보가 추가되었습니다.");
      this.rowData.push(_.cloneDeep(this.selectedRow));
      this.initTrmData();
      this.isEditing = false;
      this.isAdding = false;
      this.popupkey++;
    },
    addCancel() {
      this.initTrmData();
      this.isEditing = false;
      this.isAdding = false;
    },

    // 수정하기
    editTrmData() {
      this.isEditing = true;
    },
    // 수정 - 완료
    async editComplete() {
      if (this.invalidTrmData) {
        alert('유효한 값을 입력해 주세요.');
        return false;
      }
      if (this.hasSameData()) {
        alert('데이터가 이미 존재합니다.');
        return false;
      }

      let oldData = {
        oldProdInsTrm: this.originalRow.prodInsTrm,
        oldProdPyTrm: this.originalRow.prodPyTrm,
        oldInsTrmType: this.originalRow.insTrmType
      }
      this.selectedRow.prodInsTrm = parseInt(this.selectedRow.prodInsTrm);
      this.selectedRow.prodPyTrm = parseInt(this.selectedRow.prodPyTrm);

      let params = {...this.selectedRow, ...oldData};
      let u_result = await this.setPrProdInsPyTrm("U", params);

      if(u_result == 0){
        alert('FAIL : 데이터가 수정되지 않았습니다.');
        return false;
      }

      this.rowData[this.selectedIndex] = _.cloneDeep(this.selectedRow);
      alert("상품 기간 정보가 변경되었습니다.");
      this.isEditing = false;
    },
    // 수정 - 취소
    editCancel() {
      this.initTrmData();
      this.isEditing = false;
    },

    // 제거
    async deleteTrmData(){
      if (this.invalidTrmData) {
        alert('유효한 값을 입력해 주세요.');
        return false;
      }
      if (confirm(this.selectedRow.prodInsTrm + "(세/년 만기) / " + this.selectedRow.prodPyTrm + "(년 납) 데이터를 삭제하시겠습니까?")) {
        this.selectedRow.prodInsTrm = parseInt(this.selectedRow.prodInsTrm);
        this.selectedRow.prodPyTrm = parseInt(this.selectedRow.prodPyTrm);

        let d_result = await this.setPrProdInsPyTrm("D", this.selectedRow);

        if(d_result == 0){
          alert('FAIL : 삭제가 되지 않았습니다.');
          return false;
        }

        alert("삭제되었습니다.");
        let rmvIndex = this.rowData.findIndex(el =>
            el.prodInsTrm === this.selectedRow.prodInsTrm
            && el.prodPyTrm === this.selectedRow.prodPyTrm
            && el.insTrmType === this.selectedRow.insTrmType);
        this.rowData.splice(rmvIndex, 1);
        this.initTrmData();
      }
    },

    // wkType - I: insert / U: update / D: delete 로 사용
    async setPrProdInsPyTrm(wkType, params) {
      let status = "";
      params = {...params, ...{wkType: wkType}};

      try {
        let data = await axiosInstance.post('/admin/prdata/setPrProdInsPyTrm', params);
        status = data;
      } catch (e) {
        console.log(e);
        return false;
      }
      return status; // -> 0이면 오류 1이면 성공
    },

    // 첫 액션 추가하기 대비
    initProductInfo(){
      this.selectedRow.prProdLineCd = this.productInfo.prProdLineCd;
      this.selectedRow.insCd = this.productInfo.insCd;
      this.selectedRow.prCd = this.productInfo.prCd;
    },
    // 입력란 초기화
    initTrmData() {
      this.selectedIndex = -1;

      this.selectedRow.prodInsTrm = 0;
      this.selectedRow.prodPyTrm = 0;
      this.selectedRow.insTrmType = "";
    },
    // 추가 및 수정시 동일 값 존재여부 확인
    hasSameData() {
      // rowData의 값 중 selectedRow와 동일한 값이 있는지 확인
      let idx = this.rowData.findIndex(
          el => el.prodInsTrm == this.selectedRow.prodInsTrm
              && el.prodPyTrm == this.selectedRow.prodPyTrm
      );
      return idx >= 0;
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.my-2 span {
  margin-left: 10px;
}

.my-2 a {
  text-decoration: none;
  margin: 5px 15px;
}

.my-2 button {
  margin-right: 10px;
}
</style>