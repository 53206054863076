<template>
  <v-overlay v-if="isShow" :light="true" :dark="false">
    <v-card class="card-layout" elevation="2">
      <v-card-title class="card-title-layout font-weight-bold text-h5">
        <span>조기정산 지급예정일</span>
        <span class="close-button" @click="closePopup('close')">x</span>
      </v-card-title>

      <v-card-actions class="card-content-layout">
        <settlement-early-date-picker
            :date="dueDate"
            @updateDate="updateDate"
        />
        <v-btn class="save-button" @click="save">저장</v-btn>
      </v-card-actions>

    </v-card>
  </v-overlay>
</template>

<script>
import SettlementEarlyDatePicker from "@/pages/Settlement/components/SettlementEarly/Popup/SettlementEarlyDatePicker";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
import DateMixin from "@/mixin/DateMixin";

export default {
  components: {SettlementEarlyDatePicker},
  props: ["isShow", "saveData"],
  mixins: [DateMixin],
  async beforeMount() {
    await this.getEarlyDate();
    this.dueDate = this.saveDate;
  },
  data() {
    return {
      dueDate: "",
      saveDate: ""
    }
  },
  methods: {
    closePopup(work) {
      this.$emit("close", work);
    },
    async getEarlyDate() {
      const { data } = await axiosInstance.get("/admin/settlement/earlyPayDueDt", {
        params: {
          dayInt: 2
        }
      });
      this.saveDate = String(data);
      this.saveDate = this.saveDate.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
    },
    updateDate(date) {
      this.dueDate = date;
    },
    async save() {
      let earlyList = [];
      earlyList = this.saveData;
      try {
        if(confirm(`${earlyList.length}개의 데이터를 저장하시겠습니까?`)) {
          await axiosInstance.post("/admin/settlement/earlySettlement", earlyList, {
            params: {
              payDueDt: this.dueDate.replaceAll('-', ''),
              regId: store.getters["login/memberId"]
            }
          })
          .then(() => {
            alert("저장완료되었습니다.");
            this.closePopup("save");
          })
          .catch(e => {
            console.log(e);
          })
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-layout {
  width: 350px;
  height: 200px;
  background-color: #fff;
  z-index: 999;

  .card-title-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-button {
      cursor: pointer;
    }
  }

  .card-content-layout {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .save-button {
      margin-top: 20px;
    }
  }
}

</style>