<template>
  <search-page-layout title="사용자 프로그램 권한 관리">
    <template v-slot:searchBar>
      <user-program-privilege-search-bar
          @getSearch="getUserProgPrvList"
          :prvList="privilegeGroup"
      />
    </template>

    <template v-slot:table>
      <user-program-privilege-table
          :key="tableKey"
          :data="tableData"
          :loading="loading"
          :prvList="privilegeGroup"
          @refresh="refresh"
      />
    </template>

  </search-page-layout>
</template>

<script>
import {mapGetters} from "vuex";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import UserProgramPrivilegeSearchBar from "@/pages/Manage/UserProgramPrivilege/UserProgramPrivilegeSearchBar";
import UserProgramPrivilegeTable from "@/pages/Manage/UserProgramPrivilege/UserProgramPrivilegeTable";
import {axiosInstance} from "@/util/AxiosModule";

// import constant from "@/util/constant"; // 임시

export default {
  name: "UserProgramPrivilege",
  components: {
    UserProgramPrivilegeSearchBar,
    UserProgramPrivilegeTable,
    SearchPageLayout,
  },
  computed: {
    ...mapGetters("login", ["privilegeGroup"]),
  },
  data: () => ({
    loading: false,
    tableData: [],
    tableKey: 0,
    refreshParams: {}
  }),

  methods: {

    async getUserProgPrvList(params) {
      this.loading = true;
      this.tableData = [];
      this.refreshParams = params;

      try {
        const {data} = await axiosInstance.get('/admin/operation/getUserProgPrvList', {params});

        this.tableData = data.map(item => ({ checkYn: false , ...item}));
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    refresh(){
      this.getUserProgPrvList(this.refreshParams);
      this.tableKey++;
    }

  }
}
</script>
