var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-5 px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"white--text px-8",staticStyle:{"margin-right":"3px"},attrs:{"color":"grey","disabled":_vm.data.length <= 0},on:{"click":_vm.toExcel}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"60vh","item-key":"index","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"headers":_vm.getHeader(_vm.headers),"fixed-header":"","calculate-widths":"","footer-props":{'items-per-page-options': [10, 30, 50 , -1]},"show-select":""},on:{"input":_vm.selectedData},scopedSlots:_vm._u([{key:"item.prNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 20, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.cvrNm",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 20, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.useYn",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"max-width":"50px","justify-content":"center"},attrs:{"items":_vm.useYnList,"item-text":"useYnNm","item-value":"useYn"},model:{value:(item.useYn),callback:function ($$v) {_vm.$set(item, "useYn", $$v)},expression:"item.useYn"}})]}},{key:"item.premSumType",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "합산")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "최대")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "3", "최소")))])]}},{key:"item.cvrDiv",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.changedData(value, "1", "의무사항")))]),_c('div',[_vm._v(_vm._s(_vm.changedData(value, "2", "선택사항")))])]}},{key:"item.baseAccAmt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.monthlyPrem",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}}]),model:{value:(_vm.selectData),callback:function ($$v) {_vm.selectData=$$v},expression:"selectData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }