<template>
  <v-card-actions>
    <div class="ml-auto" v-if="modify">
      <v-btn
          class="ml-auto mr-3"
          color="primary"
          @click="$emit('save')"
          :disabled="!valid"
      >적용</v-btn>
      <v-btn
          class="ml-auto white--text"
          color="red darken-1"
          @click="$emit('cancel')"
      >취소</v-btn>
    </div>
    <div class="ml-auto" v-else>
      <v-btn class="ml-auto mr-3"
             color="primary"
             @click="$emit('set')"
      >수정</v-btn>
      <v-btn
          :disabled="detailCodes.length > 0"
          class="ml-auto white--text"
          color="red darken-1"
          @click="$emit('delete')"
      >삭제</v-btn>
    </div>

  </v-card-actions>
</template>

<script>
export default {
  props: {
    detailCodes: Array,
    valid: Boolean,
    modify: Boolean
  }
}
</script>

<style scoped>

</style>