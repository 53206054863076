<template>
  <search-page-layout title="보험약관 수집 상품 관리">
    <template v-slot:searchBar>
      <ins-term-info-mgmt-search-bar
          ref="search"
          :key="searchKey + 's'"
          @getSearch= "getSearch"
          :props_insCdList="insCdList"
          :props_prProdLineCdList="prProdLineCdList"
      />
    </template>

    <template v-slot:table>
      <ins-term-info-mgmt-table
          :key="tableKey + 't'"
          :tableData="data"
          :loading="loading"
          @selectRow="selectRow"
          :insCdList="insCdList"
          :prProdLineCdList="prProdLineCdList"
      />
    </template>

    <template v-slot:inflowHist>
      <ins-term-info-mgmt-save
          ref="saveTable"
          :key="saveKey + 'v'"
          :rowData="rowData"
          @refreshList="refreshList"
          :insCdList="insCdList"
          :prProdLineCdList="prProdLineCdList"
      />
    </template>
  </search-page-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import constant from "@/util/constant";

import SearchPageLayout from "@/layouts/SearchPageLayout";
import InsTermInfoMgmtSearchBar from "./components/InsTermInfoMgmtSearchBar";
import InsTermInfoMgmtTable from "./components/InsTermInfoMgmtTable";
import InsTermInfoMgmtSave from "./components/InsTermInfoMgmtSave";

export default {
  components: {SearchPageLayout, InsTermInfoMgmtSearchBar, InsTermInfoMgmtTable, InsTermInfoMgmtSave},
  data: () => ({
    loading: false,
    data: [],
    rowData: [],
    searchKey: 0,
    tableKey: 0,
    saveKey: 0,

    prProdLineCdList: [],
    insCdList: [],
  }),

  created() {
    this.initCommonElement();
  },

  methods: {
    // 상품종류, 보험사코드 초기화
    initCommonElement() {
      this.prProdLineCdList = constant.PR_PROD_LINE_CD;
      this.getInsCdList();
    },

    async getSearch(params) {
      this.loading = true;
      try {
        const {data} = await axiosInstance.get('/admin/operation/insTermInfoList', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    async getInsCdList() {
      try {
        const {data} = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId: "PR_INS_CODE",
            type: "KEY"
          }
        });
        this.insCdList = data.filter(x => x.use);
      } catch (e) {
        console.log(e);
      }
      this.tableKey++;
      this.searchKey++;
    },
    selectRow(row) {
      this.rowData = row;
      this.saveKey++;
    },
    refreshList(){
      this.$refs.search.getSearch();
    }
  }
}
</script>