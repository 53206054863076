<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="">
        <v-col class="d-flex align-center col-xl-3 col-md-5 col-12">
          <v-col cols="4">추가등록기준일</v-col>
          <v-col cols="7">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="toCreateDt"
                    prepend-icon="event"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="toCreateDt" color="green lighten-1" @input="menu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">등록기준일</v-col>
          <v-col cols="7">
            <v-menu
                v-model="menuTwo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="event"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menuTwo = !menuTwo"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" color="green lighten-1" @input="menuTwo = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-col>
      </search-area>


    </template>
    <template v-slot:button>
      <search-btn @click="getList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateMixin from "@/mixin/DateMixin";
import store from "@/store";

export default {
  mixins: [SearchBarMixin, DateMixin],
  components: { SearchArea, SearchBarLayout},
  props: ['baseDt'],
  computed: {
    dateRangeText () {
      if (this.startDate.length > 9) {
        return this.startDate
      }else {
        return this.baseDt ? `${this.baseDt.slice(0, 4)}-${this.baseDt.slice(4, 6)}-${this.baseDt.slice(6)}` : '';
      }
    }
  },
  data: () => ({
    startDate: "",
    toCreateDt: "",
    menuTwo:false,
    menu: false,
    toDate:"",
  }),
  methods: {
    getList() {
      let d = new Date();
      let year = d.getFullYear(); // 년
      let month = d.getMonth();   // 월
      let day = d.getDate();      // 일

      let date = new Date(year, month, day - 1).toLocaleDateString().replaceAll(" ","").replaceAll(".", "");

      if (month < 10) {
        date = `${date.slice(0, 4)}0${date.slice(4, 5)}${date.slice(5)}`
      }
      if (day < 10) {
        date = `${date.slice(0, 4)}${date.slice(4, 6)}0${date.slice(6)}`
      }

      if (this.toCreateDt.replaceAll('-', '') > date || this.dateRangeText.replaceAll('-', '') > date) {
        alert("최대 어제 날짜까지 가능합니다.");
        return;
      }

      const params = {
        "toCreateDt": this.toCreateDt.replaceAll('-', ''),
        "baseDt": this.dateRangeText.replaceAll('-', ''),
        "regId" : store.getters['login/memberId'],
        "wkDiv": '1',
        "expirYn": '',
        "site": '',
        "contContentsCd": '',
      }
      this.$emit('search', params);
    }
  }
}
</script>