<template>
  <v-col cols="12" class="py-0">
    <span class="text-h6">목록 (총 {{ carShownInfos.length }}개)</span>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="carShownInfos"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          @click:row="getHistDetailDialog"
          :headers="headers"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
  
      </v-data-table>
      <calc-hist-dialog
        :dialog="dialog"
        @close="dialog = false"
        @updateSetting="setDialog"
        :carInfo="carInfo"
        :insuranceAmt="insuranceAmt"
      />
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import CalcHistDialog from "@/pages/User/components/CalcHistSearch/CalcHistDialog";

export default {
  mixins: [TableMixin],
  props: ["carInfos", "insuranceAmts", "loading", "carShownInfos"],
  components: {CalcHistDialog},
  data: () => ({
    dialog: false,
    carInfo: {},
    insuranceAmt: [],
    headers: [{text: "회원명", value: "customerNm", class: "teal lighten-4", align: "center", sortable: false},
              {text: "고객명", value: "inviterNm", class: "teal lighten-4", align: "center", sortable: false},
              {text: "차량번호", value: "carNo", class: "teal lighten-4", align: "center", sortable: false},
              {text: "모델명", value: "carName", class: "teal lighten-4", align: "center", sortable: false},
              {text: "대인배상2", value: "basicAgreement1", class: "teal lighten-4", align: "center", sortable: false},
              {text: "대물배상", value: "basicAgreement2", class: "teal lighten-4", align: "center", sortable: false},
              {text: "자기신체손해", value: "basicAgreement3", class: "teal lighten-4", align: "center", sortable: false},
              {text: "무보험상해", value: "basicAgreement4", class: "teal lighten-4", align: "center", sortable: false},
              {text: "긴급출동서비스", value: "basicAgreement5", class: "teal lighten-4", align: "center", sortable: false},
              {text: "자기차량손해", value: "basicAgreement6", class: "teal lighten-4", align: "center", sortable: false},
              {text: "물적사고할증기준금액", value: "basicAgreement7", class: "teal lighten-4", align: "center", sortable: false},
              {text: "최저가보험사", value: "resCompanyNmCode", class: "teal lighten-4", align: "center", sortable: false},
              {text: "최저금액", value: "resTotalPremium", class: "teal lighten-4", align: "center", sortable: false},
              {text: "조회날짜", value: "createDate", class: "teal lighten-4", align: "center", sortable: false},
              ]
  }),
  methods: {
    getHistDetailDialog(row) {
      for (var i in this.carShownInfos) {
        if (this.carShownInfos[i] === row) {
          this.dialog = true;
          this.carInfo = this.carInfos[i]
          this.insuranceAmt = this.insuranceAmts[i]
        }
      }
    },
    setDialog (value) { 
      this.dialog = value
    }
  }
}
</script>

<style scoped>

</style>