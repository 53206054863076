<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>

      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">상품군</v-col>
          <v-col cols="7">
            <v-select
                @click="getPrProdLineList"
                v-model="dataDto.prProdLineCd"
                :items="prProdLineList"
                :disabled="this.dataDto.prProdLineNm === this.rowData.prProdLineNm"
                background-color="white"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">보험사</v-col>
          <v-col cols="7">
            <v-select
                @click="getInsCdList"
                v-model="dataDto.insCd"
                :items="insCdList"
                :disabled="this.dataDto.insCdNm === this.rowData.insCdNm"
                background-color="white"
                item-text="insCdNm"
                item-value="insCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">상품코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.prCd"
                :disabled="this.dataDto.prCd === this.rowData.prCd"
                background-color="white"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="12">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="dataDto.prNm"
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                  />
                </div>
              </template>
              <span>{{ dataDto.prNm }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">플랜코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.planCd"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="1"></v-col>
          <v-col cols="12">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="dataDto.planNm"
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                  />
                </div>
              </template>
              <span>{{ dataDto.planNm }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">보험기간목록</v-col>
          <v-col cols="7">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="dataDto.insTrmList"
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                  />
                </div>
              </template>
              <span>{{ dataDto.insTrmList }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">갱신주기목록</v-col>
          <v-col cols="6">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="dataDto.rnwCycleList"
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                  />
                </div>
              </template>
              <span>{{ dataDto.rnwCycleList }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">납입기간목록</v-col>
          <v-col cols="7">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                      v-model="dataDto.pyTrmList"
                      outlined dense hide-details
                      background-color="white" menu-props="auto"
                  />
                </div>
              </template>
              <span>{{ dataDto.pyTrmList }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">납입주기목록</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.pyCycleList"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">남자가입연령_FR</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.mfrAge"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">남자가입연령_TO</v-col>
          <v-col cols="6">
            <v-text-field
                v-model="dataDto.mtoAge"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">여자가입연령_FR</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.ffrAge"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">여자가입연령_TO</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.ftoAge"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">추천점수</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.prScore"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">사용여부</v-col>
          <v-col cols="6">
            <v-select
                v-model="dataDto.useYn"
                :items="useYnList"
                background-color="white"
                item-text="useYnName"
                item-value="useYnValue"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="deleteData"
        >
          초기화
        </v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  props: ["rowData"],
  data() {
    return {
      dataDto: {
        'prProdLineNm':'',
        'prProdLineCd':'',
        'insCdNm':'',
        'insCd':'',
        'prCd':'',
        'prNm':'',
        'prCdNm':'',
        'planCd':'',
        'planNm':'',
        'insTrmList':'',
        'rnwCycleList':'',
        'pyTrmList':'',
        'pyCycleList':'',
        'mFrAge':'',
        'mToAge':'',
        'fFrAge':'',
        'fToAge':'',
        'prScore':'',
        'useYn':'Y',
      },
      prProdLineList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      insCdList: [
        {insCdNm: "선택", insCd: ""}
      ],
      useYnList: [
        {useYnName: "Y", useYnValue: "Y"},
        {useYnName: "N", useYnValue: "N"},
      ]
    }
  },
  watch: {
    rowData: function (value) {
      this.dataDto.prProdLineNm = value.prProdLineNm;
      this.dataDto.prProdLineCd = value.prProdLineCd;
      this.dataDto.insCdNm = value.insCdNm;
      this.dataDto.insCd = value.insCd;
      this.dataDto.prNm = value.prNm;
      this.dataDto.prCd = value.prCd;
      this.dataDto.prCdNm = value.prCd;
      this.dataDto.planNm = value.planNm;
      this.dataDto.planCd = value.planCd;
      this.dataDto.insTrmList = value.insTrmList;
      this.dataDto.rnwCycleList = value.rnwCycleList;
      this.dataDto.pyTrmList = value.pyTrmList;
      this.dataDto.pyCycleList = value.pyCycleList;
      this.dataDto.mfrAge = value.mfrAge;
      this.dataDto.mtoAge = value.mtoAge;
      this.dataDto.ffrAge = value.ffrAge;
      this.dataDto.ftoAge = value.ftoAge;
      this.dataDto.prScore = value.prScore;
      this.dataDto.useYn = value.useYn;

      this.prProdLineList[0].prProdLineCd = value.prProdLineCd;
      this.prProdLineList[0].prProdLineNm = value.prProdLineNm;

      this.insCdList[0].insCdNm = value.insNm;
      this.insCdList[0].insCd = value.insCd;
      console.log(">>value", value);
    }
  },
  methods: {
    // 상품군 리스트 가져오기
    async getPrProdLineList() {
      this.prProdLineList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineList.shift();
    },
    // 보험사 리스트 가져오기
    async getInsCdList() {
      this.insCdList = await ProductRecommend.getInsCd();
      this.insCdList.shift();
    },
    deleteData() {
      this.dataDto = [];
      this.prProdLineList[0].prProdLineCd = "";
      this.prProdLineList[0].prProdLineNm = "선택";
      this.dataDto.prProdLineCd = this.prProdLineList[0].prProdLineCd;
      this.dataDto.prProdLineNm = this.prProdLineList[0].prProdLineNm;

      this.insCdList[0].insCd = "";
      this.insCdList[0].insCdNm = "선택";
      this.dataDto.insCd = this.insCdList[0].insCd;
      this.dataDto.insCdNm = this.insCdList[0].insCdNm;

      this.dataDto.prCd = "";
      this.dataDto.prCdNm = "";
      this.dataDto.planCd = "";
      this.dataDto.planNm = "";
      this.dataDto.insTrmList = "";
      this.dataDto.rnwCycleList = "";
      this.dataDto.pyTrmList = "";
      this.dataDto.pyCycleList = "";
      this.dataDto.mfrAge = "";
      this.dataDto.mtoAge = "";
      this.dataDto.ffrAge = "";
      this.dataDto.ftoAge = "";
      this.dataDto.prScore = "";

      this.dataDto.useYn = "Y";
    },
    async saveData() {
      const prPlanTbDto = {
        prProdLineCd: this.dataDto.prProdLineCd,
        insCd: this.dataDto.insCd,
        prCd: this.dataDto.prCd,
        planCd: this.dataDto.planCd,
        planNm: this.dataDto.planNm,
        insTrmList: this.dataDto.insTrmList,
        rnwCycleList: this.dataDto.rnwCycleList,
        pyTrmList: this.dataDto.pyTrmList,
        pyCycleList: this.dataDto.pyCycleList,
        mfrAge: this.dataDto.mfrAge,
        mtoAge: this.dataDto.mtoAge,
        ffrAge: this.dataDto.ffrAge,
        ftoAge: this.dataDto.ftoAge,
        prScore: this.dataDto.prScore,
        useYn: this.dataDto.useYn,
      }
      try {
        await axiosInstance.post('/admin/prdata/prPlanSave', prPlanTbDto);
        alert("저장되었습니다.");
        this.dataDto = [];
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>