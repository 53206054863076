<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
      <v-btn
          color="grey"
          class="white--text px-8"
          style="margin-right: 3px;"
          @click="toExcel"
          :disabled="data.length <= 0"
      >엑셀다운로드</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="30vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="getHeader(headers)"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.cvrNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ textLengthOverCut(value, 20, "...") }}</div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <!-- 갱신형구분 -->
        <template v-slot:item.rnwDiv="{ value }">
          <div>{{ changedData(value, "0", "전체") }}</div>
          <div>{{ changedData(value, "1", "갱신형") }}</div>
          <div>{{ changedData(value, "2", "비갱신형") }}</div>
        </template>
        <!-- 갱신형구분 -->

        <!-- 갱신용구분 -->
        <template v-slot:item.forRnwDiv="{ value }">
          <div>{{ changedData(value, "0", "전체") }}</div>
          <div>{{ changedData(value, "1", "갱신용") }}</div>
        </template>
        <!-- 갱신용구분 -->

        <!-- 간편가입구분 -->
        <template v-slot:item.easySignUpDiv="{ value }">
          <div>{{ changedData(value, "0", "전체") }}</div>
          <div>{{ changedData(value, "1", "간편가입(간편고지)") }}</div>
        </template>
        <!-- 간편가입구분 -->

        <!-- 운전자형태구분 -->
        <template v-slot:item.driverTypeDiv="{ value }">
          <div>{{ changedData(value, "0", "전체") }}</div>
          <div>{{ changedData(value, "1", "운전자") }}</div>
          <div>{{ changedData(value, "2", "비운전자") }}</div>
          <div>{{ changedData(value, "3", "영업용운전자") }}</div>
        </template>
        <!-- 운전자형태구분 -->

        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {INS_CVR_STATUS_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";
import DateMixin from "@/mixin/DateMixin";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: INS_CVR_STATUS_TABLE,
    }
  },
  methods: {
    clickContents(row) {
      this.$emit("row", row);
    },
    toExcel() {
      // 리스트 빈 값 체크
      if(_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      // 엑셀 다운로드
      for (const item of data) {
        const obj = {
          "담보코드": item["cvrCd"],
          "담보명": item["cvrNm"],
          "상품명": item["prNm"],
          "신정원 담보코드": item["creCvrCd"],
          "신정원 담보명": item["creCvrNm"],
          "사용여부": item["useYn"],
          "등록일자": item["createDate"],
        }
        returnData.push(obj);
      }

      const sheetName = `보험사 담보현황`;
      const fileName = `보험사담보현황`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>