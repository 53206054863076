<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <outlined-btn class="float-right"
                    @click="checkApproval('N')" :disabled="isEmptySelectedList">
        불인
      </outlined-btn>
      <outlined-btn class="float-right" style="margin-right: 10px;"
                    @click="checkApproval('Y')" :disabled="isEmptySelectedList">
        승인
      </outlined-btn>
    </div>

    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          item-key="index"
          :loading="loading"
          :items-per-page="-1"
          :headers="headers"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          fixed-header calculate-widths
      >
        <template v-slot:item.selectedYn="{ item, value }">
          <tr>
            <td class="text-center">
              <v-checkbox v-model="item.selectedYn"
                          @change="selectRow(item, value)"
              ></v-checkbox>
            </td>
          </tr>
        </template>

        <template v-slot:item.createDate="{ value }">
          <div class="justify-center">{{ contentsDataFormatter(value) }}</div>
        </template>

        <template v-slot:item.menuNm = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{ value }}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.condition = "{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">{{sliceLongString(value)}}</div>
            </template>
            <span>{{value}}</span>
          </v-tooltip>
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  name: "ExcelExtractHistTable",
  components: {OutlinedBtn},
  mixins: [TableMixin],

  props: ["data", "loading"],
  computed: {
    hasTableData() {
      return this.data.length > 0;
    },
    isEmptySelectedList() {
      return this.selectedData.length < 1;
    },

    // 승인이 가능한 인원 체크 -> 승인,거절 버튼 비활성화 or 숨김처리
  },
  data: () => ({
    headers: [
      {text: "선택", value: "selectedYn", align: "center", sortable: false, width: "3vw"},
      {text: "추출일자", value: "createDate", align: "center", sortable: false, width: "6vw"},
      {text: "추출자", value: "memberNm", align: "center", sortable: false, width: "6vw"},
      {text: "메뉴명", value: "menuNm", align: "center", sortable: false, width: "10vw"},
      {text: "등급", value: "grade", align: "center", sortable: false, width: "4vw"},
      {text: "추출건수", value: "extCnt", align: "center", sortable: false, width: "4vw"},
      {text: "점검자", value: "checkMemberNm", align: "center", sortable: false, width: "6vw"},
      {text: "승인여부", value: "approvalYn", align: "center", sortable: false, width: "4vw"},
      {text: "조건", value: "condition", align: "center", sortable: false, width: "15vw"},
    ],

    selectedData: [],
  }),

  methods: {
    selectRow(item, value) {
      if (!value) {
        this.selectedData.push(item.xlsExtId);
      } else {
        this.selectedData = this.selectedData.filter(id => id !== item.xlsExtId);
      }
    },
    checkApproval(status) {
      const inviteCd = this.$store.getters['login/userData'].inviteCd;
      if (inviteCd !== "4AAA708vRv" || inviteCd !== "JLDJRtxfSY"){
        alert("권한이 없습니다.");
        return false;
      }

      let type = status === 'Y' ? "승인" : "불인";
      if (!confirm(`${this.selectedData.length}개의 이력을 ${type} 하시겠습니까?`)) return;

      let params = {
        checkMemberId: this.$store.getters['login/memberId'],
        approvalYn: status, // Y, N
        approvalYnList: []
      };
      this.selectedData.forEach(id => { params.approvalYnList.push(id); })

      this.setXlsExtractApproval(params);
    },

    async setXlsExtractApproval(params) {

      try {
        await axiosInstance.post('/admin/operation/setXlsExtractApproval', params);
      } catch (e) {
        console.log(e);
      }

      alert("완료되었습니다.");
      this.refreshTableData();
    },
    refreshTableData() {
      this.selectedData = [];
      this.$emit("search");
    },

    // 유틸
    sliceLongString(str) {
      return str.length > 80 ? str.slice(0, 80) + "..." : str;
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
  display-outside: flex;
}
</style>