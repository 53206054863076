<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <field-input title="추출일자">
          <date-input
              style="width:60vw;"
              :start-date="startDate"
              :end-date="endDate"
              @updateDate="updateDate"
          />
        </field-input>
        <field-input title="등급">
          <v-select
              v-model="grade"
              :items="gradeList"
              item-text="name"
              item-value="cd"
              background-color="white"
              hide-details outlined dense
          />
        </field-input>
      </search-area>

      <search-area class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <field-input title="점검여부">
          <v-select
              v-model="approvalStatus"
              :items="approvalStatusList"
              item-text="name"
              item-value="cd"
              background-color="white"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="점검결과">
        <v-select
            :disabled="approvalStatus === 'N'"
            v-model="approvalYn"
            :items="approvalYnList"
            item-text="name"
            item-value="cd"
            background-color="white"
            hide-details outlined dense
        />
        </field-input>

<!--        <field-input title="추출자">-->
<!--          <v-text-field-->
<!--              v-model="memberNm"-->
<!--              outlined dense hide-details-->
<!--              background-color="white" menu-props="auto"-->
<!--          />-->
<!--        </field-input>-->
<!--        <field-input title="메뉴명">-->
<!--          <v-text-field-->
<!--              v-model="menuNm"-->
<!--              outlined dense hide-details-->
<!--              background-color="white" menu-props="auto"-->
<!--          />-->
<!--        </field-input>-->
      </search-area>

    </template>
    <template v-slot:button>
      <search-btn @click="getXlsExtractHist"/>
    </template>
  </search-bar-layout>
</template>

<script>
import _ from "lodash";
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  name: "ExcelExtractHistSearchBar",
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, FieldInput},

  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },

  computed: {},
  data: () => ({
    startDate: "",    endDate: "",
    grade: "",          // 등급
    gradeList: [
      {name: "전체", cd: ""},
      {name: "높음", cd: "HIGH"},
      {name: "중간", cd: "MIDDLE"},
      {name: "낮음", cd: "LOW"}
    ],
    approvalStatus: "ALL",  // 점검 여부
    approvalStatusList: [
      {name: "전체", cd: "ALL"},
      {name: "점검완료", cd: "Y"},
      {name: "미점검", cd: "N"}
    ],
    approvalYn: "",     // 점검결과
    approvalYnList: [
      {name: "전체", cd: ""},
      {name: "승인", cd: "Y"},
      {name: "불인", cd: "N"}  // 미점검 선택시 점검 결과는 disabled 처리
    ],
    memberNm: "",       // 추출자 이름
    menuNm: "",         // 메뉴 이름
  }),

  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },

    getXlsExtractHist() {
      const params = {
        startDate: this.startDate.replaceAll('-', ''),
        endDate: this.endDate.replaceAll('-', ''),
        grade: this.grade,
        approvalYn: this.approvalYn,
        approvalStatus: this.approvalStatus
      }
      // 추출자, 메뉴명 입력 영역 추가되면 해제
      // if (this.memberNm) params["memberNm"] = this.memberNm.replace(/\s/g, '');
      // if (this.menuNm) params["menuNm"] = this.menuNm.replace(/\s/g, '');

      if(_.isEmpty(this.endDate)){
        alert("마지막 일자를 설정해주세요.");
        return false;
      }

      this.$emit('getXlsExtractHist', params);
    },
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>