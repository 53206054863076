<template>
  <search-layout title=" ">

    <template v-slot:searchBar>
      <status-bar
          @search="getCalcList"
          />
    </template>

    <template v-slot:table>
      <status-table
        :tokenStatus = "tokenStatus"
        :loading="loading"
        />
    </template>

  </search-layout>
</template>

<script>
import SearchLayout from "@/layouts/SearchLayout";
import StatusBar from "./TokenBar";
import {axiosInstance} from "@/util/AxiosModule";
import StatusTable from "./TokenTable";

export default {
  components: {
    StatusTable,
    StatusBar,
    SearchLayout
  },
  data: () => ({
    loading: false,
    tokenStatus: [],
  }),
  methods: {
    async getCalcList(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/collection/token_status', {params});
        this.tokenStatus = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>