<template>
  <v-app id="inspire">
    <v-container class="pa-0">
      <v-layout class="w100h100">
        <v-card class="px-12 py-10 loginCard" style="background: rgba(0,0,0,.05);">
          <v-card-text>
            <v-img class="ainLogo ma-auto" src="@/assets/ain.png"/>
          </v-card-text>
          <v-card-text class="text-center black--text text-h5 font-weight-black">
            관리자 로그인
          </v-card-text>
          <v-form
              class="pb-10"
              v-model="valid"
          >
            <v-text-field
                autofocus solo @keypress.enter="valid && login()"
                v-model="email" placeholder="이메일"
                background-color="white" class="border_clear elevation-0"
                :rules="[
                    value => !!value || '내용을 작성해주세요.',
                    value => regExp.test(value) || '이메일 양식이 아닙니다.'
                  ]"
            />
            <v-text-field
                solo type="password" @keypress.enter="valid && login()"
                v-model="password" placeholder="비밀번호"
                background-color="white" class="border_clear elevation-0 mb-3"
                :rules="[ value => !!value || '패스워드 작성해주세요' ]"
            />
            <v-btn
                color="blue darken-1"
                class="white--text col-12 transition-ease-in-out hello"
                :disabled="!valid" @click="login"
            >
              로그인
            </v-btn>
          </v-form>
        </v-card>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>

import {axiosInstance} from "@/util/AxiosModule";
import router from "@/router";

export default {
  data: () => ({
    valid: false,
    email: "",
    password: "",
    regExp: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
  }),
  methods: {
    async login() {
      const body = {
        email: this.email,
        password: this.password
      };

      try {
        const {data} = await axiosInstance.post(`/public/login`, body);
        let role = data.memberRole;

        if(role === 'ADMIN' || role === 'MANAGER')
        {
          await this.$store.dispatch('login/login', data, {root: true});

          let routeName = role === 'ADMIN' ? 'PerformanceStat' : 'ProductCondPrem';
          router.push({name: routeName});
        }
        else
        {
          alert("관리자만 접근할 수 있습니다.");
        }

      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped>
.w100h100 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginCard {
  width: 23rem;
  background: rgba(0, 0, 0, .1);
  border-radius: 3em !important;
}

.ainLogo {
  width: 10em;
}
</style>