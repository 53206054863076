<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
    <v-card width="450px" height="200px">
      <v-card-title class="text-h5 font-weight-bold">정제결과</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="refineMessage"
          outlined dense background-color="white"
          v-clipboard:copy="refineMessage" @click="snackbar = true"
        />
      </v-card-text>
      <v-snackbar v-model="snackbar" :top="'top'" :timeout="timeout">"{{ refineMessage }}" 복사완료</v-snackbar>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="$emit('close')">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props: ["isShow", "rowData"],
  data() {
    return {
      refineMessage: "",
      snackbar: false,
      timeout: 1500
    }
  },
  watch: {
    isShow(value) {
      if (value) {
        this.setRefineData();
      }
    }
  },
  methods: {
    async setRefineData() {
      const params = {
        baseYm: this.rowData.baseYm,
        joinChannel: this.rowData.joinChannel,
        contractInsurer: this.rowData.contractInsurer
      };
      console.log("params");
      console.log(params);
      try {
        await axiosInstance.get("/admin/settlement/settlement_refine", { params })
          .then((response) => {
            this.refineMessage = response.data;
          })
            .catch((error) => {
              console.log(error);
            })
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>