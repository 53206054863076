<template>
  <search-page-layout title="실시간 계약현황">
    <template v-slot:searchBar>
      <realtime-contract-search-bar
          @getRealtimeContract="getRealtimeContract"
      />
    </template>
    <template v-slot:table>
      <realtime-contract-table
          :data="data"
          :loading="loading"
          @toExcel="toExcel"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import RealtimeContractSearchBar from "@/pages/Settlement/components/RealtimeContract/RealtimeContractSearchBar";
import RealtimeContractTable from "@/pages/Settlement/components/RealtimeContract/RealtimeContractTable";
import _ from "lodash";
import func from "@/util/func";

export default {
  name: "RealtimeContract",
  components: {
    RealtimeContractTable,
    RealtimeContractSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
    dataBack: [],
    conditionHist: {},
    searchCheckMap: "ALL",
  }),

  methods: {
    async getRealtimeContract(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/settlement/realtime-contract', { params });
        this.conditionHist = params;
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].index = i;
        }
        this.dataBack = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    async toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "회원명": item["memberNm"],
          "회원 연락처": item["telNum"],
          "고객명": item["customerNm"],
          "고객 연락처": item["customerTelNum"],
          "고객 차량번호": item["customerCarNo"],
          "보험사": item["insCd"],
          "이전보험사": item["preInsurer"],
          "계약보험료": item["contractPremAmt"],
          "리워드": item["reward"],
          "지급일자": item["payCompleteDate"],
          "계약일": item["contractDate"],
          "만기일자": item["expiryDate"],
          "계약채널": item["joinChannel"],
          "설계지원번호": item["designSupId"],
          "상담자": item["csNm"],
          "진행자": item["ctCsNm"],
          "DB유형": item["infoServiceTypeList"],
          "입력자": item["regNm"],
          "코드4자리": item["hdCode"],
          "이륜차여부": item["twoWheelerYn"],
          "법인계약여부": item["corpContYn"]
        }
        returnData.push(obj);
      }

      const sheetName = `실시간_계약현황`;
      const fileName = '실시간_계약현황_' + this.setFileCreateTime();
      let conditionHistString = JSON.stringify(this.conditionHist);

      await func.saveXlsExtractHist("실시간 계약현황", returnData.length, conditionHistString, fileName, "HIGH");
      func.extractExcelFile(returnData, sheetName, fileName);
    },

    setFileCreateTime(){
      let date = new Date();
      return this.setYearShort(date.getFullYear())+""+
          this.setTwoDigit((date.getMonth()+1))+""+
          this.setTwoDigit((date.getDate()))+"_"+
          this.setTwoDigit((date.getHours()))+""+
          this.setTwoDigit((date.getMinutes()))
    },
    setYearShort(year){
      return year.toString().slice(2);
    },
    setTwoDigit(time){
      return (time < 10) ? ('0' + time.toString()) : time;
    },

  }
}
</script>


