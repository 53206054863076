<template>
  <v-container fluid>
    <v-layout column>
      <v-form v-model="valid" ref="form">
        <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
          <back-btn/>
          <v-card-title>코드 생성하기</v-card-title>
          <v-divider/>

          <v-row>
            <v-col :cols="12" :class="FIELD_CLASS">
              <span :class="CREATE_FIELD_CLASS">마스터 코드</span>
              <v-text-field
                  class="mx-3"
                  v-model="code.cdMasterId"
                  style="background-color:white"
                  hide-details outlined dense full-width
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex align-center pr-5">
              <span :class="CREATE_FIELD_CLASS">데이터 형태</span>
              <v-radio-group
                  v-model="code.type"
                  :disabled="detailCds.length > 0"
                  row
              >
                <v-radio label="KEY" value="KEY"/>
                <v-radio label="JSON" value="JSON"/>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" :class="CREATE_FIELD_CLASS">
              <span class="col-3">설명</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                  placeholder="입력해주세요"
                  solo
                  rows="3"
                  :rules="rules.nn"
                  v-model="code.cdDesc"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn
                :disabled="!valid"
                class="primary ma-auto"
                @click="saveCode"
            >생성
            </v-btn>
          </v-card-actions>

          <v-divider class="my-3"/>

          <master-code-table-header
              :type="code.type"
              :is-immediately="false"
              :item-length="selected.length"
              :master-code="code.cdMasterId"
              @delete="deleteDC"
              @saveDetailCode="saveDetailCode"
          />
          <v-col cols="12" class="py-0">
            <code-table
                :isMaster="false"
                :is-create="true"
                :headers="headers"
                :items="detailCds"
                :parents-selected="selected"
                @select="select"
            />
          </v-col>
        </v-card>
      </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import router from "@/router";
import {KEY_CODE_HEADER} from "@/util/TableHeaders";
import CodeTable from "@/pages/Manage/Code/components/common/CodeTable";
import MasterCodeTableHeader from "@/pages/Manage/Code/components/MasterCode/MasterCodeTableHeader";
import BackBtn from "@/pages/Manage/Code/components/common/BackBtn";
import {axiosInstance} from "@/util/AxiosModule";
import {rules} from "@/util/constant";
import CodeMixin from "@/mixin/CodeMixin";


export default {
  mixins: [CodeMixin],
  components: {BackBtn, MasterCodeTableHeader, CodeTable},
  data: () => ({
    selected: [],
    valid: false,
    code: {
      cdMasterId: "",
      cdDesc: "",
      createDate: new Date().toJSON(),
      updateDate: new Date().toJSON(),
      type: "KEY",
    },
    detailCds: [],
    headers: KEY_CODE_HEADER,
    rules: rules
  }),
  methods: {
    saveDetailCode(data) {
      this.detailCds.push(_.cloneDeep(data));
    },
    select(selected) {
      this.selected = selected
    },
    deleteDC() {
      for (const item of this.selected) {
        this.detailCds = _.remove(this.detailCds, (n) => (n.code !== item.code))
      }
      this.selected = [];
    },
    async saveCode() {
      if (!(this.code.cdMasterId && this.code.cdDesc)) {
        alert("값을 모두 입력해주세요!");
        return;
      }

      axiosInstance.post('/admin/code/master', this.code).then(async () => {
        try {
          for (const item of this.detailCds) {
            item.cdMasterId = this.code.cdMasterId;
            await axiosInstance.post('/admin/code/detail', item);
          }

          alert("생성이 완료되었습니다!");
          await router.push({name: 'SearchCode'});
        } catch (e) {
          console.log(e);
        }
      })
      .catch((e) => console.log(e));
    }
  }
}
</script>

<style scoped>

</style>