<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-card max-width="85vw" max-height="95vh" class="overflow-y-auto card-layout">
      <div class="close-layout">
        <span class="close-button" @click="closeModal">x</span>
      </div>
      <v-card-title class="headline">
        <span class="header-title">수신 실적자료 삭제</span>
      </v-card-title>
      <v-divider :dark="true" :light="false" />
      <v-row class="content-layout">
        <v-col class="content">
          <span class="content-title">보험사</span>
          <v-select
              v-model="insCd"
              :items="insList"
              item-text="insCdNm"
              item-value="insCd"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="content">
          <span class="content-title">채널</span>
          <v-select
              v-model="joinChannel"
              :items="joinChannelList"
              item-text="joinChannelNm"
              item-value="joinChannel"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="content">
          <span class="content-title">등록일</span>
          <v-text-field
              v-model="createDt"
              hide-details outlined dense
              maxLength="8"
          />
        </v-col>
        <v-col class="buttons">
          <button class="search-button" @click="getRealtimeList">
            <span class="search-title">조회</span>
          </button>
          <button class="delete-button" @click="deleteRealtimeData(selectData)">
            <span class="delete-title">삭제</span>
          </button>
        </v-col>
      </v-row>

      <div class="table-layout">
        <span>건수: {{ inputComma(realtimeList.length) }}건</span>
        <span>보험료: {{ inputComma(totalSum) }}원</span>
      </div>
      <v-data-table
        class="px-5"
        v-model="selectData"
        :headers="getHeader(headers)"
        :items="realtimeList"
        show-select item-key="index"
      >
        <template v-slot:item.contractDate="{ value }">
          {{ getDateDashFormat(value) }}
        </template>

        <template v-slot:item.contractPremAmt="{ value }">
          {{ inputComma(value) }}
        </template>

        <template v-slot:item.createDate="{ value }">
          {{ getDateDashFormat(value) }}
        </template>
      </v-data-table>
    </v-card>
  </v-overlay>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {INS_REALTIME_CONTRACT_DELETE_TABLE} from "@/util/TableHeaders";
import {axiosInstance} from "@/util/AxiosModule";
import constant from "@/util/constant";
import DateMixin from "@/mixin/DateMixin";
import func from "@/util/func";

export default {
  props: ["isShow"],
  mixins: [TableMixin, DateMixin],
  created() {
    this.createDt = func.getToday();
  },
  data() {
    return {
      insCd: "",
      insList: constant.GUIDE_INSURER_CODE,
      joinChannel: "CM",
      joinChannelList: [
        { joinChannelNm: "CM", joinChannel: "CM" },
        { joinChannelNm: "TM", joinChannel: "TM" }
      ],
      createDt: "",
      headers: INS_REALTIME_CONTRACT_DELETE_TABLE,
      realtimeList: [],
      selectData: [],
      totalSum: 0
    }
  },
  watch: {
    realtimeList(value) {
      if (this.totalSum !== 0) {
        this.totalSum = 0;
      }
      for (let i in value) {
        this.totalSum += value[i].contractPremAmt;
      }
    }
  },
  methods: {
    resetModalData() {
      this.selectData = [];
      this.realtimeList = [];
      this.insCd = "";
      this.joinChannel = "CM";
      this.createDt = func.getToday();
    },
    closeModal() {
      this.resetModalData();
      this.$emit('close');
    },
    async getRealtimeList() {
      if (this.insCd === "") {
        alert("보험사를 선택해주세요.");
        return false;
      } else if (this.createDt === "") {
        alert("등록일을 입력해주세요.");
        return false;
      } else {
        this.realtimeList = [];
        const { data } = await axiosInstance.get("/admin/settlement/getRealtimeContract", {
          params: {
            insCd: this.insCd,
            joinChannel: this.joinChannel,
            createDt: this.createDt
          }
        });
        if (data.length < 1) {
          alert("조회된 데이터가 없습니다.");
          return false;
        }
        for (let i = 0; i < data.length; i++) {
          data[i].index = i;
        }
        this.realtimeList = data;
      }
    },
    async deleteRealtimeData(insRealtimeContractTbList) {
      if (insRealtimeContractTbList.length < 1) {
        alert("선택된 데이터가 없습니다.");
        return false;
      } else {
        if (confirm(`${insRealtimeContractTbList.length}개의 데이터를 삭제하시겠습니까?`)) {
          await axiosInstance.delete("/admin/settlement/delRealtimeContract", {
            data: insRealtimeContractTbList,
            params: {
              insCd: this.insCd.replace(/\s/g, ''),
              joinChannel: this.joinChannel.replace(/\s/g, ''),
              createDt: this.createDt.replace(/\s/g, '')
            }
          })
              .then(() => {
                this.resetModalData();
                alert("삭제가 완료되었습니다.");
              })
              .catch((e) => {
                console.log(e);
              })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.close-layout {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;

  .close-button {
    cursor: pointer;
    font-size: 25px;
  }
}

.header-title {
  font-size: 25px;
  font-weight: 800;
}

.content-layout {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;

  .content {
    display: flex;
    align-items: center;
    .content-title {
      width: 50%;
      font-weight: bolder;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    button {
      margin-left: 30px;
    }
    .search-button {
      padding: 5px 15px;
      border: 1px solid #222;
      border-radius: 10px;
      .search-title {
        font-weight: bolder;
      }
    }
    .search-button:hover {
      background-color: #1772C7;
      .search-title {
        color: #fff;
      }
    }

    .delete-button {
      padding: 5px 15px;
      border: 1px solid #222;
      border-radius: 10px;
      .delete-title {
        font-weight: bolder;
      }
    }
    .delete-button:hover {
      background-color: red;
      .delete-title {
        color: #fff;
      }
    }
  }
}

.table-layout {
  padding: 10px 20px;

  span {
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
  }
}
</style>