<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <field-input title="상품구분">
          <v-select
              :items="INSURANCE_COMPANY_TYPE"
              background-color="white"
              v-model="type" menu-props="auto"
              hide-details outlined dense
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn
          color="grey"
          @click="getInsList(type)"
      />
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [SearchBarMixin, CodeMixin],
  components: {SearchArea, SearchBarLayout, FieldInput},
  async created() {
    this.INSURANCE_COMPANY_TYPE = await this.getSelectList("INSURANCE_COMPANY_TYPE");
  },
  data: () => ({
    type: "ALL",
    INSURANCE_COMPANY_TYPE: [],
  }),
  watch: {
    type : function(value) {
      this.$emit('setType', value)
    }
  },
  methods: {
    getInsList(type) {
      this.$emit('search', type);
    },
  }
}
</script>

<style scoped>

</style>