<template>
  <v-layout>
    <v-card class="col-12 pa-0" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>
      <v-row class="px-6">
        <!-- 1번째 줄 -->
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">상품군</v-col>
          <v-col cols="8">
            <v-select
                :items="prProdLineCdList"
                item-text="name"
                item-value="cd"
                v-model="prProdLineCd"
                label="선택" menu-props="auto"
                hide-details outlined dense
                :style="{ 'background-color': chgValueColor(prProdLineCd, originData.prProdLineCd) }"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">보험사</v-col>
          <v-col cols="8">
            <v-select
                :items="insCdList"
                item-text="value"
                item-value="cd"
                v-model="insCd"
                :disabled="!isNew"
                :class="{'fixed-input': !isNew}"
                label="선택" menu-props="auto"
                hide-details outlined dense
                :style="{ 'background-color': chgValueColor(insCd, originData.insCd) }"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-12 col-12">
          <v-col cols="2">상품명</v-col>
          <v-col cols="10">
            <v-text-field
                v-model="prNm" inputmode="text"
                :disabled="!isNew"
                :class="{'fixed-input': !isNew}"
                outlined dense hide-details
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(prNm, originData.prNm) }"
            />
          </v-col>
        </v-col>
        <!-- 2번째 줄 -->
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">판매시작</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="stDt"
                inputmode="numeric" maxlength="8"
                outlined dense hide-details
                placeholder="yyyymmdd"
                menu-props="auto"
                :disabled="!isNew"
                :class="{'fixed-input': !isNew}"
                :style="{ 'background-color': chgValueColor(stDt, originData.stDt) }"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">판매종료</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="closeDt"
                inputmode="numeric" maxlength="8"
                outlined dense hide-details
                placeholder="yyyymmdd"
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(closeDt, originData.closeDt) }"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">상품코드</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="prCd"
                outlined dense hide-details
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(prCd, originData.prCd) }"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">작업대상</v-col>
          <v-col cols="8">
            <v-select
                :items="wkTgYnList"
                item-text="cname"
                item-value="cvalue"
                v-model="wkTgYn"
                label="선택" menu-props="auto"
                hide-details outlined dense
                :style="{ 'background-color': chgValueColor(wkTgYn, originData.wkTgYn) }"
            />
          </v-col>
        </v-col>
        <!-- 3번째 줄 -->
        <v-col class="d-flex align-center col-12">
          <v-col cols="1">메모</v-col>
          <v-col cols="11">
            <v-text-field
                v-model="memo"
                outlined dense hide-details
                height="100px"
                menu-props="auto"
                :style="{ 'background-color': chgValueColor(memo, originData.memo) }"
            />
          </v-col>
        </v-col>
        <!-- 4번째 줄 -->
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">작업일자</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="createDate"
                disabled
                outlined dense hide-details
                menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">변경일자</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="updateDate"
                disabled
                outlined dense hide-details
                menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-12 col-12">
          <v-col cols="4">벡터DB 등록일자</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="vecStDt"
                disabled
                outlined dense hide-details
                menu-props="auto"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="resetData"
        >
          초기화
        </v-btn>
        <v-btn
            :disabled="!checkValidValue"
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          {{ isNew ? '저장' : '수정하기' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import {mapGetters} from "vuex";
import _ from "lodash";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";

export default {
  props: ["rowData", 'prProdLineCdList', 'insCdList'],
  mixins: [CodeMixin, DateMixin],
  data() {
    return {
      originData: {}, // cloneDeep
      prProdLineCd: "",
      insCd: "",
      prNm: "",
      stDt: "", closeDt: "",
      prCd: "",
      wkTgYn: null,
      wkTgYnList: [
        {cname: '미결정', cvalue: null},
        {cname: '자동수집대상', cvalue: 'Y'},
        {cname: '수기수집대상', cvalue: 'M'},
        {cname: '비대상', cvalue: 'N'}
      ],
      memo: "",
      createDate: "",
      updateDate: "",
      vecStDt: "",
      // color
      BRIGHT_RED: "rgba(255, 0, 0, 0.1)",
      WHITE: "white"
    }
  },
  computed: {
    ...mapGetters("login", ["memberId"]),

    isNew() {
      return _.isEmpty(this.createDate);
    },
    checkValidValue() {
      if (_.isEmpty(this.insCd))
        return false;
      if (_.isEmpty(this.prNm))
        return false;
      if (_.isEmpty(this.stDt) || this.stDt.length != 8)
        return false;
      if (!_.isEmpty(this.closeDt) && this.closeDt.length != 8)
        return false;
      if (_.isEmpty(this.prProdLineCd))
        return false;
      if (_.isEmpty(this.prCd))
        return false;

      return true;
    },
  },

  created() {
    this.initData();
  },

  methods: {
    // row 클릭 시 데이터 세팅
    initData() {
      this.originData = _.cloneDeep(this.rowData);
      this.insCd = this.rowData.insCd;
      this.prNm = this.rowData.prNm;
      this.stDt = this.rowData.stDt;
      this.wkTgYn = this.rowData.wkTgYn;
      this.prProdLineCd = this.rowData.prProdLineCd;
      this.prCd = this.rowData.prCd;
      this.closeDt = this.rowData.closeDt;
      this.memo = this.rowData.memo;
      this.createDate = this.replaceDate(this.rowData.createDate);
      this.updateDate = this.replaceDate(this.rowData.updateDate);
      this.vecStDt = this.replaceDate(this.rowData.vecStDt);
    },

    async saveData() {

      if (!this.checkValidDate(this.stDt)) {
        alert("판매시작 날짜를 정확히 입력해주세요.");
        return false;
      }
      if (!this.checkValidDate(this.closeDt)) {
        alert("판매종료 날짜를 정확히 입력해주세요.");
        return false;
      }

      let msg = this.isNew ? '등록하시겠습니까?' : '수정하시겠습니까?';

      if (confirm("[" + this.prNm + "] 상품을 " + msg)) {
        try {
          const {data} = await axiosInstance.post('/admin/operation/insTermInfoSave', {
            insCd: this.insCd,
            prNm: this.prNm,
            stDt: this.stDt,
            wkTgYn: this.wkTgYn,
            prProdLineCd: this.prProdLineCd,
            prCd: this.prCd,
            closeDt: this.closeDt,
            memo: this.memo,
            chgId: this.memberId
          });
          alert(data);
          this.resetData();
          this.$emit("refreshList");
        } catch (e) {
          console.log(e);
        }
      }
    },

    // 초기화
    resetData() {
      this.insCd = "";
      this.prNm = "";
      this.stDt = "";
      this.wkTgYn = null;
      this.prProdLineCd = "";
      this.prCd = "";
      this.closeDt = "";
      this.memo = "";
      this.createDate = "";
      this.updateDate = "";
      this.state = "";
      this.vecStDt = "";
    },
    //
    chgValueColor(newVal, oldVal) {
      return !this.isNew && (newVal !== oldVal)
          ? this.BRIGHT_RED
          : this.WHITE;
    },
    replaceDate(date) {
      let d = date ? date.toString() : '';
      return d ? date.replace('T', ' ').substr(0, 19) : '';
    }
  }
}
</script>

<style scoped>

.fixed-input {
  font-style: italic;
  background-color: rgba(75, 143, 255, 0.54);
}
</style>