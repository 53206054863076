<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-card  max-width="95vw" max-height="95vh" min-width="70vw" class="overflow-y-auto modalBackground">
      <v-card-title class="headline blue-grey--text">
        <span class="font-weight-bold">담보추가</span>
      </v-card-title>
      <v-divider :dark="true" :light="false"/>
      <v-sheet style="margin-left: 10px; margin-right: 10px;">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left th-font">
                보험사 : {{insCd}}
              </th>
              <th class="text-left th-font">
                상품코드 : {{prCd}}
              </th>
              <th class="text-left th-font">
                플랜코드 : {{planCd}}
              </th>
              <th class="text-left th-font">
                연계담보구분유형 : {{lkCvrDivType}}
              </th>
              <th class="text-left th-font">
                충족조건 : {{satCond}}
              </th>
            </tr>
            </thead>
          </template>
        </v-simple-table>
        <search-area>
          <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
            <v-col cols="3">담보검색명</v-col>
            <v-text-field
                v-model="srhTxt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
            <v-col cols="2">
            <v-btn
              color="blue darken-4"
              class="white--text"
              @click="getList"
              >조회</v-btn>
            </v-col>
            <v-btn
              style="margin-left: 5px;"
              color="blue darken-1"
              class="white--text"
              @click="addList"
              >담보 List 추가</v-btn>
          </v-col>
        </search-area>
      </v-sheet>
      <v-data-table
        style="margin-top: 10px;"
        v-model="checkMappingList" height="23vh" item-key="index"
        :items="mappingList"
        :headers="headers"
        dense fixed-header calculate-widths hide-default-footer
        show-select
        :items-per-page="-1"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        ></v-data-table>
      <v-col cols="12" class="py-0" style="margin-top: 30px;">
        <div class="my-2">
          <span class="text-h6">목록 (총 {{ checkMapping.length }}개)</span>
          <v-btn @click="dataSave" class="light-blue white--text theme--light float-right" style="margin-left: 10px;">저장</v-btn>
          <v-btn @click="copyData" class="light-green white--text theme--light float-right" style="margin-left: 10px;">복사하기</v-btn>
        </div>
        <v-flex xs12 sm12 md12 lg12 xl12 :key="tagkey">
          <v-data-table
              height="38vh"
              :items="checkMapping"
              :items-per-page="-1"
              fixed-header calculate-widths
              :headers="headersMapping"
              dense hide-default-footer
              :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
              >
            <template v-slot:item.baseVal="{ value, item, header }">
              <text-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.sex="{ value, item, header }">
              <select-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  :items="sexList"
                  @update="changeValue"
                  />
            </template>
            <template v-slot:item.riskLevel="{ value, item, header }">
              <select-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  :items="riskLevelList"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.riskLevel2="{ value, item, header }">
              <select-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  :items="riskLevelList"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.drivingDiv="{ value, item, header }">
              <select-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  :items="drivingDivList"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.cvrAmtCond="{ value, item, header }">
              <select-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  :items="cvrAmtCondList"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.frAge="{ value, item, header }">
              <text-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.toAge="{ value, item, header }">
              <text-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.dirLkCvrCd="{ value, item, header }">
              <text-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.frCvrAmt="{ value, item, header }">
              <text-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  @update="changeValue"
              />
            </template>
            <template v-slot:item.toCvrAmt="{ value, item, header }">
              <text-input
                  :key="`${item.index}-${item.name}`"
                  :value="value" :id="item.index"
                  :header="header.value"
                  @update="changeValue"
              />
            </template>
          </v-data-table>
        </v-flex>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="red darken-4"
            class="white--text col-2"
            @click="close"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchArea from "../../../../components/Search/SearchArea";
import TextInput from "../../../Settlement/components/TextInput";
import _ from "lodash";
import SelectInput from "../../../Settlement/components/SelectInput";
export default {
  components: {SelectInput, TextInput, SearchArea},
  props:["detailDto", 'isShow', "lkCvrDivCd"],
  data:() => ({
    mappingList:[],
    checkMappingList:[],
    tagkey:0,
    checkMapping:[],
    srhTxt:'',
    insCd:'',
    prCd:'',
    planCd:'',
    lkCvrDivType:'',
    satCond:'',
    lkCvrDivDesc:'',
    sexList: [
      { text: "공용", value: "0"},
      { text: "남성", value: "1"},
      { text: "여성", value: "2"},
    ],
    riskLevelList: [
      { text: "1", value: "1"},
      { text: "2", value: "2"},
      { text: "3", value: "3"},
      { text: "4", value: "4"},
      { text: "5", value: "5"},
    ],
    drivingDivList:[
      { text: "상관없음", value: ""},
      { text: "안함", value: "0"},
      { text: "개인용", value: "1"},
      { text: "영업용", value: "2"},
    ],
    cvrAmtCondList:[
      { text: "BASE_AMT", value: "BASE_AMT"},
      { text: "SET_AMT", value: "SET_AMT"},
      { text: "RANGE_AMT", value: "RANGE_AMT"},
    ],
    headers: [
      {text: "보험사", value: "insCd", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "입력담보명", value: "icvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "담보명", value: "cvrNm", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
    ],
    headersMapping: [
      {text: "연계담보구분코드", value: "lkCvrDivCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보명", value: "cvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "기준값", value: "baseVal", class: "lime lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "성별", value: "sex", class: "lime lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "위험급수", value: "riskLevel", class: "lime lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "위험급수2", value: "riskLevel2", class: "lime lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "운전형태", value: "drivingDiv", class: "lime lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "연령_FR", value: "frAge", class: "lime lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "연령_TO", value: "toAge", class: "lime lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "직접연계담보코드", value: "dirLkCvrCd", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액조건", value: "cvrAmtCond", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액_FR", value: "frCvrAmt", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보금액_TO", value: "toCvrAmt", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "등록일시", value: "createDate", class: "lime lighten-4", align: "center", sortable: false, width: "6vw"},
    ],
  }),
  created() {
    this.insCd = this.detailDto.insCd;
    this.prCd = this.detailDto.prCd;
    this.planCd = this.detailDto.planCd;
    this.lkCvrDivType = this.detailDto.lkCvrDivType;
    this.satCond = this.detailDto.satCond;
    this.lkCvrDivDesc = this.detailDto.lkCvrDivDesc;
  },
  methods: {
    close() {
      this.srhTxt = '';
      this.checkMappingList = [];
      this.mappingList=[];
      this.checkMapping = [];
      this.$emit('close');
    },
    addList() {
      this.checkMapping = this.checkMappingList;
      for (let i = 0; i < this.checkMapping.length; i++) {
        this.checkMapping[i].lkCvrDivCd = this.lkCvrDivCd;
        this.checkMapping[i].insCd = this.detailDto.insCd;
        this.checkMapping[i].prCd = this.detailDto.prCd;
        this.checkMapping[i].planCd = this.detailDto.planCd;
        this.checkMapping[i].lkCvrDivType = this.detailDto.lkCvrDivType;
        this.checkMapping[i].satCond = this.detailDto.satCond;
      }
      this.checkMappingList = [];
    },
    async dataSave() {
      const PrTakeLkCvrTbDto = this.checkMapping;

      if (confirm("저장하시겠습니까?")) {
        try {
          const {data} = await axiosInstance.post('/admin/prdata/prTakeLkCvrSave', PrTakeLkCvrTbDto);
          alert('인수지침 연계담보 ' + data + '개가 등록되었습니다.');
          this.close();
        } catch (e) {
          console.log(e);
        }
      }
    },
    changeValue(text, id, header) {
      const item = _.find(this.checkMapping, {index: id});
      if (header === 'riskLevel2') {
        if (Number(text) < Number(item.riskLevel) || _.isEmpty(item.riskLevel)) {
          alert("위험급수2는 위험급수보다 클 수 없습니다.");
          return;
        }
      } else if (header === 'toAge') {
        if (Number(text) < Number(item.frAge) || _.isEmpty(item.frAge)) {
          alert("연령TO은 연령FR보다 클 수 없습니다.");
          return;
        }
      } else if (header === 'toCvrAmt') {
        if (Number(text) < Number(item.frCvrAmt) || _.isEmpty(item.frCvrAmt)) {
          alert("담보금액TO은 담보금액FR보다 클 수 없습니다.");
          return;
        }
      }
      item[header] = text;
      this.tagkey++;
    },
    async getList() {
      const params = {
        insCd: this.detailDto.insCd,
        prCd: this.detailDto.prCd,
        srhTxt: this.srhTxt,
      }
      try {
        const { data } = await axiosInstance.get('/admin/prdata/prProdCvrList', { params });
        this.mappingList = data;
        //인덱스 값 추가
        for (let i = 0; i < this.mappingList.length; i++) {
          this.mappingList[i].index = i;
          this.mappingList[i].updateDate = this.mappingList[i].updateDate.replace('T', ' ');
        }
      } catch (e) {
        console.log(e);
      }
    },
    copyData() {
      for (let i = 1; i < this.checkMapping.length; i++) {
        this.checkMapping[i].baseVal = this.checkMapping[0].baseVal;
        this.checkMapping[i].sex = this.checkMapping[0].sex;
        this.checkMapping[i].riskLevel = this.checkMapping[0].riskLevel;
        this.checkMapping[i].riskLevel2 = this.checkMapping[0].riskLevel2;
        this.checkMapping[i].drivingDiv = this.checkMapping[0].drivingDiv;
        this.checkMapping[i].frAge = this.checkMapping[0].frAge;
        this.checkMapping[i].toAge = this.checkMapping[0].toAge;
        this.checkMapping[i].dirLkCvrCd = this.checkMapping[0].dirLkCvrCd;
        this.checkMapping[i].cvrAmtCond = this.checkMapping[0].cvrAmtCond;
        this.checkMapping[i].frCvrAmt = this.checkMapping[0].frCvrAmt;
        this.checkMapping[i].toCvrAmt = this.checkMapping[0].toCvrAmt;
      }
      this.tagkey++;
    }
  }
}
</script>

<style scoped>
.v-data-table::v-deep td {
  white-space: normal!important;
  word-break: keep-all;
}
.th-font {
  font-size: 15px !important;
}
</style>