<template>
    <v-dialog
			v-model="show"
			max-width="1000px"
			max-height="800px"
			persistent
    >
		<v-layout column>
			<v-flex>
				<v-card>
					<v-card-title>
            <span class="text-h5">{{data.contFileNm}}</span>
          </v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12" align='center'>
								<v-img :src="data.contFileSaveRoot" :width="data.horizonSize" :height="data.verticalSize"/>
								</v-col>
							</v-row>
							<v-divider></v-divider>

							<v-row>
								<v-col cols="12">
									<v-simple-table>
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">
														정보
													</th>
													<th class="text-left">
														내용
													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>컨텐츠 태그</td>
													<!-- 조건부 렌더링 진행 버튼을 이용하여, 수정 <-> 완료  -->
													<td v-if="updateStatus">
														<div style="display:flex; flex-direction:row;">		
															<v-text-field
															v-model="updateContFileInfoTag"
															height=26
															style="width:300px !important; margin:0 !important; padding:1 0 0 0 !important; font-size: 12px;"
															/>
															<v-btn @click="isUpdateStatus" :disabled= "isUpdateTagNullCheck" class="ml-5 mt-1 mb-0 pl-3 pb-0">완료</v-btn>
														</div>
													</td>
													<td v-else>
														{{ data.contFileInfoTag }}
														<v-btn @click="isUpdateStatus" class="ml-5 pl-3">수정</v-btn>
													</td>

												</tr>
                        <tr>
                          <td>컨텐츠 제목</td>
                          <!-- 조건부 렌더링 진행 버튼을 이용하여, 수정 <-> 완료  -->
                          <td v-if="updateStatus">
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="updateContFileTitle"
                                  height=26
                                  style="width:300px !important; margin:0 !important; padding:1 0 0 0 !important; font-size: 12px;"
                              />
                              <v-btn @click="isUpdateStatus" :disabled= "isUpdateTagNullCheck" class="ml-5 mt-1 mb-0 pl-3 pb-0">완료</v-btn>
                            </div>
                          </td>
                          <td v-else>
                            {{ data.contFileTitle }}
                            <v-btn @click="isUpdateStatus" class="ml-5 pl-3">수정</v-btn>
                          </td>

                        </tr>
												<tr>
													<td>원본파일 링크</td>
													<td>
														<a id="clipboard-btn">{{ data.contFileSaveRoot }}</a>
														<v-btn class="ml-5 pl-3" v-clipboard:copy="data.contFileSaveRoot"  v-clipboard:error="onError">복사</v-btn>
													</td>
												</tr>
												<tr>
													<td>파일 명</td>
													<td>{{ data.contFileNm }}</td>
												</tr>
												<tr>
													<td>파일 사이즈</td>
													<td>{{ data.fileSize/1000 }}KB</td>
												</tr>
												<tr>
													<td>가로 * 세로</td>
													<td>{{ data.verticalSize }} * {{ data.horizonSize }}</td>
												</tr>
												<tr>
													<td>작성자</td>
													<td>{{ data.regManager }}</td>
												</tr>
												<tr>
													<td>생성일</td>
													<td>{{ data.createDate }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-col>
							</v-row>
						</v-container>	
					</v-card-text>
					<v-card-actions>
						<v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Close
            </v-btn>
					</v-card-actions>
				</v-card>	
			</v-flex>
		</v-layout>
    </v-dialog>

</template>

<script>

export default {
	props: ["data", "dialog"],

	data: () =>({
		updateStatus: false,
		updateContFileInfoTag: "",
		updateContFileTitle: ""

	}),
	computed: {
		show: {
			get() {
				return this.dialog
			},
			set(value) {
				this.$emit("updateSetting", value)
			}
		},
		isUpdateTagNullCheck() {
			return this.updateContFileInfoTag == undefined || this.updateContFileInfoTag.trim().length == 0 || this.updateContFileTitle == undefined || this.updateContFileTitle.trim().length == 0;
		}
	},

	methods: {
		
    onError: function (e) {
      alert('Failed to copy texts' + e.text)
    },
		isUpdateStatus() {
			if(this.updateStatus) {
				console.log("업데이트 진행")
				const params = {
					contFileInfoSeq: this.data.contFileInfoSeq,
					contFileInfoTag: this.updateContFileInfoTag,
          contFileTitle: this.updateContFileTitle,
				}
				this.$emit("update", params)
			}
			this.updateContFileInfoTag = this.data.contFileInfoTag
      this.updateContFileTitle = this.data.contFileTitle
			return this.updateStatus = !this.updateStatus
		},
    close() {
      this.updateStatus = false;
      this.show = false;
    }
		
	}
}
</script>