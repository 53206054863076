<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false"
  >
    <div class="d-flex justify-end" style="position:absolute; width: 100%; z-index: 999; padding-right: 15px;">
        <span style="cursor: pointer; font-size: 30px;" @click="close">x</span>
      </div>
    <custom-layout title="회원정보 변경/삭제">
      <v-card width="80vw" height="100%" max-width="1200" max-height="550">
        <v-row class="px-5 py-3">
          <v-col class="d-flex align-center col-5">
            <v-col cols="4">회원명</v-col>
            <v-text-field
                v-model="rowData.memberNm"
               hide-details outlined dense background-color="white"
              disabled
            />
          </v-col>
          <v-col class="d-flex align-center col-5">
            <v-col cols="4">전화번호</v-col>
            <v-text-field
                v-model="rowData.telNum"
                hide-details outlined dense background-color="white"
                disabled
            />
          </v-col>
          <v-col class="d-flex align-center col-5">
            <v-col cols="4">초대코드</v-col>
            <v-text-field
                v-model="rowData.inviteCd"
                hide-details outlined dense background-color="white"
                disabled
            />
          </v-col>
          <v-col class="d-flex align-center col-5">
            <v-col cols="4">회원ID</v-col>
            <v-text-field
                v-model="rowData.memberId"
                hide-details outlined dense background-color="white"
                disabled
            />
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-radio-group v-model="tableType" row>
            <v-radio label="정보변경" value="modify" />
            <v-radio v-if="!isPrvGroupTM" label="중복건삭제" value="delete" />
          </v-radio-group>
        </v-row>
        <v-flex class="d-flex justify-center table-layout">
          <table v-if="tableType === 'modify'" border="1" class="modify-table">
            <thead>
              <tr class="modify-table-headers">
                <th class="modify-table-header" v-for="(header, index) in modifyHeaders" :key="index">{{ header }}</th>
              </tr>
            </thead>
            <tbody class="modify-table-container">
              <tr class="modify-table-1">
                <td class="modify-table-1-header" v-for="(sub_header, sub_index) in modifySubHeaders" :key="sub_index">{{ sub_header }}</td>
              </tr>
              <tr class="modify-table-2">
                <td class="modify-table-input" v-for="(field, index) in modifyFields" :key="index">
                  <span v-if="index === 2">{{ getTelecomCompanyNm(existData[field]) }}</span>
                  <span v-else>{{ existData[field] }}</span>
                </td>
              </tr>
              <tr class="modify-table-2">
                <td :style="setBackgroundColor(field)" class="modify-table-input" v-for="(field, index) in modifyFields" :key="index">
                  <input
                    v-if="index !== 2"
                    type="text"
                    v-model="changeData[field]"
                  />
                  <template v-else>
                    <select
                        v-model="changeData[field]"
                    >
                      <option selected disabled>선택</option>
                      <option v-for="(item, index) in TELECOM_COMPANY" :key="index" :value="item.cd">
                        {{ item.value }}
                      </option>
                    </select>
                    <v-icon class="d-flex justify-end menu-icon">mdi-menu-down</v-icon>
                  </template>
                </td>
              </tr>
              <tr class="modify-table-3">
                <td class="modify-table-input" v-for="(field, index) in modifyFields" :key="index">
                  <input
                      type="checkbox"
                      v-model="changeDataCheck[field]"
                      @change="changeFinalData(field, changeDataCheck[field])"
                      :style="setBackgroundColor(field)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <table v-else-if="tableType === 'delete'" class="delete-table" border="1">
            <tbody class="delete-table-1">
              <tr v-for="(field, index) in deleteFields" :key="index">
                <td>{{ deleteHeaders[field] }}</td>
                <td>{{ dupPrMemberData[field] }}</td>
              </tr>
            </tbody>
            <tbody class="delete-table-2">
              <tr>
                <td>삭제가능여부</td>
                <td>{{ dupPrMemberData.chkYn }}</td>
              </tr>
            </tbody>
            <tbody class="delete-table-3">
            <tr>
              <td>메시지</td>
              <td>{{ dupPrMemberData.chkMsg }}</td>
            </tr>
            </tbody>
          </table>
        </v-flex>
        <div class="buttons-layout mt-3 pr-8">
          <v-btn v-if="tableType === 'modify'" class="save-button" color="blue darken-3 white--text" @click="saveData">
            저장
          </v-btn>
          <v-btn v-else-if="tableType === 'delete'" class="delete-button" color="red darken-1 white--text" @click="deletedData" :disabled="dupPrMemberData.chkYn === 'N'">
            삭제
          </v-btn>
        </div>
      </v-card>
    </custom-layout>
  </v-overlay>
</template>
<script>
import CustomLayout from "@/layouts/CustomLayout.vue";
import _ from "lodash";
import CodeMixin from "@/mixin/CodeMixin";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";

export default {
  components: {CustomLayout},
  props: ["isShow", "modifyData", "isPrvGroupTM"],
  mixins: [CodeMixin],
  async created() {
    this.TELECOM_COMPANY = await this.getDetailCodes('TELECOM_COMPANY');
    this.TELECOM_COMPANY.push({ cd: "9", value: "없음" });
  },
  watch: {
    async isShow(value) {
      if (value) {
        this.rowData = _.cloneDeep(this.modifyData);
        this.existData = _.cloneDeep(this.modifyData);
        this.changeData = _.cloneDeep(this.modifyData);
        this.finalData = _.cloneDeep(this.modifyData);
        await this.getDeletePrMember();
      }
    }
  },
  data() {
    return {
      TELECOM_COMPANY: [],
      rowData: {},
      tableType: "modify",
      modifyHeaders: ["구분", "기존", "변경", "변경선택"],
      modifySubHeaders: ["성명", "이메일", "휴대폰가입회사", "휴대폰번호"],
      modifyFields: ["memberNm", "email", "telecomCompany", "telNum"],
      existData: {},        // 기존 데이터
      changeData: {},       // 변경 데이터
      changeDataCheck: {},  // 변경 항목 체크 데이터
      finalData: {},        // 저장 데이터
      deleteHeaders: { settlementCnt: "실적발생건수", joinCnt: "유입이력건수", designSupCnt: "설계지원신청" },
      deleteFields: ["settlementCnt", "joinCnt", "designSupCnt"],
      dupPrMemberData: {},
      chgId: store.getters["login/memberId"]
    }
  },
  methods: {
    getTelecomCompanyNm(value) {
      if (value === undefined || value === null) {
        return '';
      } else {
        return this.TELECOM_COMPANY.find(item => item.cd === value).value;
      }
    },
    async getDeletePrMember() {
      const memberId = this.rowData.memberId;
      try {
        const { data } = await axiosInstance.get("/admin/dupPersonalMemberChk", {
          params: {
            memberId: memberId
          }});
        this.dupPrMemberData = data;
      } catch (e) {
        console.log(e);
      }
    },
    changeFinalData(field, isCheck) {
      if (isCheck) {
        this.finalData[field] = this.changeData[field];
      } else {
        this.finalData[field] = this.existData[field];
      }

    },
    setBackgroundColor(field) {
      const isChanged = this.existData[field] !== this.changeData[field];
      return isChanged ? { backgroundColor: "#FFD700" } : {};
    },
    async saveData() {
      let personalMemberDto = _.cloneDeep(this.changeData);

      // 초기 데이터 변경사항 확인
      if (
          personalMemberDto.memberNm === this.modifyData.memberNm &&
          personalMemberDto.email === this.modifyData.email &&
          personalMemberDto.telecomCompany === this.modifyData.telecomCompany &&
          personalMemberDto.telNum === this.modifyData.telNum
      ) {
        this.changeDataCheck = {};
        alert("변경사항이 없습니다.");
        return false;
      }

      // 체크선택 여부 확인
      if (Object.values(this.changeDataCheck).every(value => value !== true)) {
        alert("저장할 데이터를 선택해주세요.");
        return false;
      }

      if (confirm("저장하시겠습니까?")) {
        try {
          await axiosInstance.post("/admin/updatePersonalMember", personalMemberDto, {
            params: {
              chgId: this.chgId
            }
          }).then(() => {
            alert("저장완료되었습니다.");
            this.close();
          })
        } catch (e) {
          console.log(e);
        }
      }
    },
    async deletedData() {
      const dupMemberDto = this.dupPrMemberData;
      dupMemberDto.delYn = "Y";
      if (confirm("삭제하시겠습니까?")) {
        try {
          await axiosInstance.post("/admin/deletePersonalMember", dupMemberDto, {
            params: {
             chgId: this.chgId
            }
          }).then(() => {
            alert("삭제완료되었습니다.");
            this.close();
          })
        } catch (e) {
          console.log(e);
        }
      }
    },
    close() {
      this.setDefaultData();
      this.$emit("close");
    },
    setDefaultData() {
      this.existData = {};        // 기존 데이터
      this.changeData = {};       // 변경 데이터
      this.changeDataCheck = {};  // 변경 항목 체크 데이터
    }
  }
}
</script>

<style lang="scss" scoped>
.table-layout {
  width: 100%;
  table {
    td {
      height: 50px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // 정보변경
  .modify-table {
    max-width: 1000px;
    border-collapse: collapse;
    .modify-table-headers {
      display: flex;
      flex-direction: row;
      .modify-table-header {
        width: 20vw;
        max-width: 300px;
        height: 40px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e0e0e0 !important;
      }
      .modify-table-header:nth-child(1),
      .modify-table-header:nth-last-child(1) {
        width: 18vw;
        max-width: 250px;
      }
    }

    .modify-table-container {
      display: flex;
      .modify-table-1 {
        width: 18vw;
        max-width: 250px;
        .modify-table-1-header {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #eeeeee;
        }
      }
      .modify-table-2 {
        display: flex;
        flex-direction: column;
        width: 20vw;
        max-width: 300px;
        .modify-table-input {
          input,
          select {
            width: 20vw;
            max-width: 300px;
            text-align: center;
            z-index: 1;
            border: 1px solid #868686;
            border-radius: 20px;
            margin: 0 5px;
            padding: 5px;
          }
        }
      }
      .modify-table-3 {
        display: flex;
        flex-direction: column;
        width: 18vw;
        max-width: 250px;
        .modify-table-input {
          input {
            width: 18vw;
            max-width: 250px;
            text-align: center;
          }
        }
      }
    }
  }

  // 중복건삭제
  .delete-table {
    max-width: 1000px;
    border: none;
    border-collapse: collapse;
    .delete-table-1 {
      display: flex;
      tr {
        border: 1px solid #222;
        display: flex;
        td {
          width: 10vw;
          max-width: 150px;
        }
      }
      tr:nth-child(1) {
        td:nth-child(1) {
          width: 18vw;
          max-width: 250px;
        }
      }
      tr:nth-last-child(1) {
        td:nth-last-child(1) {
          width: 18vw;
          max-width: 250px;
        }
      }
      tr:nth-child(1),
      tr:nth-child(2) {
        border-right: none;
      }
    }
    .delete-table-2 {
      display: flex;
      tr {
        display: flex;
        border: 1px solid #222;
        border-top: none;
        border-bottom: none;
        td:nth-child(1) {
          width: 18vw;
          max-width: 250px;
        }
        td:nth-last-child(1) {
          width: 10vw;
          max-width: 150px;
        }
      }
    }
    .delete-table-3 {
      display: flex;
      tr {
        border: 1px solid #222;
        display: flex;
        td:nth-child(1) {
          width: 18vw;
          max-width: 250px;
        }
        td:nth-last-child(1) {
          width: 58vw;
          max-width: 850px;
          color: red;
        }
      }
    }
  }
}
.buttons-layout {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.menu-icon {
  position: absolute;
  width: 20vw;
  max-width: 300px;
  padding: 0 5px;
}
</style>