<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
          <v-select
              @click="getInsCdList"
              v-model="insCd"
              :items="insCdList"
              background-color="white"
              item-text="insCdNm"
              item-value="insCd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="4">신정원 담보명</v-col>
          <v-text-field
              v-model="creCvrNm"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-5 col-md-6 col-12">
          <v-col cols="4">신정원 담보코드</v-col>
          <v-text-field
              v-model="creCvrCd"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="4">순서</v-col>
          <v-radio-group v-model="dispType" row>
            <v-radio label="코드" value="1"/>
            <v-radio label="담보명" value="2"/>
          </v-radio-group>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="4">사용여부</v-col>
          <v-select
              v-model="useYn"
              :items="useYnList"
              background-color="white"
              item-text="useYnName"
              item-value="useYnValue"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBtn from "@/components/Search/SearchBtn";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  data() {
    return {
      insCd: "",
      insCdList: [
        {insCdNm: "전체", insCd: ""},
      ],
      creCvrNm: "",
      creCvrCd: "",
      dispType: "2",
      useYn: "Y",
      useYnList: [
        {useYnName: "Y", useYnValue: "Y"},
        {useYnName: "N", useYnValue: "N"},
      ],
    }
  },
  methods: {
    async getInsCdList() {
      this.insCdList = await ProductRecommend.getInsCd();
    },
    getSearch() {
      const params = {
        insCd: this.insCd,
        creCvrCd: this.creCvrCd,
        creCvrNm: this.creCvrNm,
        dispType: this.dispType,
        useYn: this.useYn,
      }
      this.$emit("getSearch", params);
    }
  }
}
</script>