<template>
  <search-page-layout title="장기 상품추천시스템 조직별 사용현황">
    <template v-slot:searchBar>
      <product-recommend-usage-operation-status-search-bar
          @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <product-recommend-usage-operation-status-table
          :data="data"
          :loading="loading"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductRecommendUsageOperationStatusSearchBar
  from "@/pages/Product/components/ProductRecommendUsageOperationStatus/ProductRecommendUsageOperationStatusSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import ProductRecommendUsageOperationStatusTable
  from "@/pages/Product/components/ProductRecommendUsageOperationStatus/ProductRecommendUsageOperationStatusTable";
export default {
  components: {
    ProductRecommendUsageOperationStatusTable,
    ProductRecommendUsageOperationStatusSearchBar, SearchPageLayout
  },
  data() {
    return {
      loading: false,
      data: [],
    }
  },
  methods: {
    async getSearch(params) {
      this.loading = true;

      try {
        const { data } =  await axiosInstance.get('/private/operation/prProdUseList', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>