<template>
  <search-layout title=" ">

    <template v-slot:searchBar>
      <status-bar
          @search="getCalcList"
          @toExcel="toExcel"
          />
    </template>

    <template v-slot:table>
      <status-table
        :usageStatus = "usageStatus"
        :loading="loading"
        />
    </template>

  </search-layout>

</template>

<script>
import SearchLayout from "@/layouts/SearchLayout";
import StatusBar from "./StatusBar";
import {axiosInstance} from "@/util/AxiosModule";
import StatusTable from "./StatusTable";
import func from "@/util/func";
import _ from "lodash";

export default {
  components: {
    StatusTable,
    StatusBar,
    SearchLayout
  },
  data: () => ({
    loading: false,
    usageStatus: [],
  }),
  methods: {
    async getCalcList(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/collection/usage_status', {params});
        this.usageStatus = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    toExcel() {
      // 리스트 빈 값 체크
      if(_.isEmpty(this.usageStatus)) return;
      const data = _.cloneDeep(this.usageStatus);
      const returnData = [];

      // 엑셀 다운로드
      for (const item of data) {
        const obj = {
          "사용자명": item["memberNm"],
          "이메일": item["email"],
          "사용일시": item["useDateTime"],
          "사용액션": item["actionNm"],
          "결과건수": item["resultCnt"],
          "검색어": item["searchWord"],
          "메인지역입력건": item["mainRegion"],
          "서브지역입력건": item["subRegion"],
          "프로그램버전": item["progVersion"],
        }
        returnData.push(obj);
      }
      const sheetName = `가망고객 DB 수집 프로그램 일자별 사용현황`;
      const fileName = '가망고객 DB 수집 프로그램 일자별 사용현황List';
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style scoped>

</style>