<template>
  <v-dialog
      v-model="show"
      max-width="1000px"
      max-height="800px"
      persistent
  >
    <v-layout column>
      <v-flex>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{detailData.imgFileNm}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" align='center'>
                  <v-img :src="detailData.imgFileNmUrl"  :width="700" :height="680"/>
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <v-row>
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          정보
                        </th>
                        <th class="text-left">
                          내용
                        </th>
                        <th class="text-left">
                          정보
                        </th>
                        <th class="text-left">
                          내용
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>보험</td>
                          <td>{{ prGuideTbDto.insCd }}</td>
                          <td>상품코드</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="prGuideTbDto.prCd"
                                  height=26
                                  style="width:300px !important; margin:0 !important; font-size: 15px;"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>상품</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="prGuideTbDto.prNm"
                                  height=26
                                  style="width:300px !important; margin:0 !important; font-size: 15px;"
                              />
                            </div>
                          </td>
                          <td>최소보험료</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="prGuideTbDto.minPrem"
                                  height=26
                                  style="width:300px !important; margin:0 !important; font-size: 15px;"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>공지시기</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="prGuideTbDto.noticeFromDt"
                                  height=26
                                  style="width:300px !important; margin:0 !important; font-size: 15px;"
                              />
                            </div>
                          </td>
                          <td>공지종기</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="prGuideTbDto.noticeToDt"
                                  height=26
                                  style="width:300px !important; margin:0 !important; font-size: 15px;"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>등록일시</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="prGuideTbDto.createDate"
                                  height=26
                                  hide-details
                                  style="width:300px !important; margin:0 !important; font-size: 15px;"
                              />
                            </div>
                          </td>
                          <td>게시계속여부</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-col
                                  class="d-flex"
                                  cols="12"
                                  sm="12"
                              >
                                <v-select
                                    v-model="prGuideTbDto.displayContYn"
                                    :items="ContYn"
                                    hide-details
                                    item-text="cname"
                                    item-value="cvalue"
                                ></v-select>
                              </v-col>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>인기상품여부</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-col
                                  class="d-flex"
                                  cols="12"
                                  sm="12"
                              >
                                <v-select
                                    v-model="prGuideTbDto.popPrYn"
                                    :items="ContYn"
                                    hide-details
                                    item-text="cname"
                                    item-value="cvalue"
                                ></v-select>
                              </v-col>
                            </div>
                          </td>
                          <td>추천상품여부</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-col
                                  class="d-flex"
                                  cols="12"
                                  sm="12"
                              >
                                <v-select
                                    v-model="prGuideTbDto.prPrYn"
                                    :items="ContYn"
                                    hide-details
                                    item-text="cname"
                                    item-value="cvalue"
                                ></v-select>
                              </v-col>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>인기점수</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-text-field
                                  v-model="prGuideTbDto.popScore"
                                  height=26
                                  hide-details
                                  style="width:300px !important; margin:0 !important; font-size: 15px;"
                              />
                            </div>
                          </td>
                          <td>인기플랜코드</td>
                          <td>
                            <div style="display:flex; flex-direction:row;">
                              <v-col
                                  class="d-flex"
                                  cols="12"
                                  sm="12"
                              >
                                <v-text-field
                                    v-model="prGuideTbDto.popPlanCd"
                                    height=26
                                    hide-details
                                    style="width:300px !important; margin:0 !important; font-size: 15px;"
                                />
                              </v-col>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-col cols="12" class="py-0">
                <v-sheet class="background" style="padding: 0px;">
                  <v-row>
                    <v-col class="flex-column col-3">
                      <v-btn
                          class="ml-auto mr-3"
                          color="primary"
                          outlined dense hide-details
                          style="width:200px;"
                          @click="showUpdate('guide')">
                        가이드파일명등록번호
                      </v-btn>
                    </v-col>
                    <v-col class="d-flex flex-column col-1">
                      <div style="display:flex; flex-direction:row;">
                        <v-text-field
                            v-model="prGuideTbDto.guideFileNmSeq"
                            height=26
                            style="margin:0 !important; font-size: 15px;"
                        />
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-column col-6">
                      <div style="display:flex; flex-direction:row;">
                        <v-text-field
                            v-model="prGuideTbDto.guideFileNmUrl"
                            height=26
                            style="margin:0 !important; font-size: 15px;"
                        />
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-column col-2">
                      <div style="display:flex; flex-direction:row;">
                        <v-btn
                            style="margin-right: 10px; width: 8vw; background-color:#D5F1E2"
                            @click="htmlEditor(prGuideTbDto.guideFileNmUrl)"
                        > 가이드 열기
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="flex-column col-3">
                      <v-btn
                          class="ml-auto mr-3"
                          @click="showUpdate('img')"
                          style="width: 200px;"
                          outlined dense hide-details
                          color="primary">
                        안내이미지파일명등록번호
                      </v-btn>
                    </v-col>
                    <v-col class="d-flex flex-column col-1">
                      <div style="display:flex; flex-direction:row;">
                        <v-text-field
                            v-model="prGuideTbDto.imgFileNmSeq"
                            height=26
                            style="margin:0 !important; font-size: 15px;"
                        />
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-column col-6">
                      <div style="display:flex; flex-direction:row;">
                        <v-text-field
                            v-model="prGuideTbDto.imgFileNmUrl"
                            height=26
                            style="margin:0 !important; font-size: 15px;"
                        />
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-column col-2">
                      <div style="display:flex; flex-direction:row;">
                        <v-btn
                            style="margin-right: 10px; width: 8vw; background-color:#D5F1E2"
                            @click="htmlEditor(prGuideTbDto.imgFileNmUrl)"
                        > 안내이미지 열기
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="saveData"
            >
              Save
            </v-btn>
            <v-btn
                color="red darken-1"
                text
                @click="close"
            >
              Close
            </v-btn>
          </v-card-actions>
          <regist-file-dialog
              :dialog="dialog"
              @close="dialog = false"
              @updateSetting="setDialog"
              @result="setGuideFileNmSeq"
            />
        </v-card>
      </v-flex>
    </v-layout>
  </v-dialog>

</template>

<script>

import RegistFileDialog from "../../Manage/ContentsFile/components/RegistFileDialog";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {RegistFileDialog},
  props: ["detailData", "show"],
  data: () =>({
    ContYn: [
      {cname: 'Y', cvalue: 'Y'},
      {cname: 'N', cvalue: 'N'}
    ],
    prGuideTbDto: {
      insCd:'',
      prNm:'',
      prCd:'',
      createDate:'',
      noticeToDt:'',
      noticeFromDt:'',
      minPrem:'',
      popPrYn:'Y',
      prPrYn:'Y',
      popScore:'',
      popPlanCd:'',
      displayContYn: 'Y',
      checkVal:'',
      guideFileNmSeq:'',
      guideFileNm:'',
      imgFileNm:'',
      guideFileNmUrl:'',
      imgFileNmUrl:'',
      imgFileNmSeq:'',
    },
    dialog: false,
  }),
  watch: {
    detailData: function (obj) {
      this.prGuideTbDto.insCd = obj.insCd;
      this.prGuideTbDto.prNm = obj.prNm;
      this.prGuideTbDto.prCd = obj.prCd;
      this.prGuideTbDto.noticeToDt = obj.noticeToDt;
      this.prGuideTbDto.noticeFromDt = obj.noticeFromDt;
      this.prGuideTbDto.minPrem = this.inputComma(obj.minPrem);
      this.prGuideTbDto.displayContYn = obj.displayContYn;
      this.prGuideTbDto.checkVal = obj.checkVal;
      this.prGuideTbDto.guideFileNmSeq = obj.guideFileNmSeq;
      this.prGuideTbDto.guideFileNm = obj.guideFileNm;
      this.prGuideTbDto.imgFileNm = obj.imgFileNm;
      this.prGuideTbDto.guideFileNmUrl = obj.guideFileNmUrl;
      this.prGuideTbDto.imgFileNmUrl = obj.imgFileNmUrl;
      this.prGuideTbDto.imgFileNmSeq = obj.imgFileNmSeq;
      this.prGuideTbDto.createDate = obj.createDate;
      this.prGuideTbDto.popPrYn = obj.popPrYn;
      this.prGuideTbDto.prPrYn = obj.prPrYn;
      this.prGuideTbDto.popScore = obj.popScore;
      this.prGuideTbDto.popPlanCd = obj.popPlanCd;
      this.updateData = true;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setDialog (value) {
      this.dialog = value
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    showUpdate(val) {
      this.prGuideTbDto.checkVal = val;
      this.dialog = true;
    },
    setGuideFileNmSeq(val) {
      if (this.prGuideTbDto.checkVal === 'guide') {
        this.prGuideTbDto.guideFileNmSeq = val;
      } else {
        this.prGuideTbDto.imgFileNmSeq = val;
      }
      this.refreshContentsAPI();
    },
    async refreshContentsAPI() {
      // 갱신 API
      try{
        const {data} = await axiosInstance.get("/admin/operation/contFileInfo");
        for (let i = 0; i < data.length; i++) {
          if (data[i].contFileInfoSeq === this.prGuideTbDto.guideFileNmSeq) {
            if (this.prGuideTbDto.checkVal === 'guide') {
              this.prGuideTbDto.guideFileNm = data[i].contFileNm;
              this.prGuideTbDto.guideFileNmUrl = data[i].contFileSaveRoot;
            } else {
              this.prGuideTbDto.imgFileNm = data[i].contFileNm;
              this.prGuideTbDto.imgFileNmUrl = data[i].contFileSaveRoot;
            }
          }
        }
      }
      catch(e) {
        console.log(e)
        alert("컨텐츠 갱신에 실패했습니다.")
      }
    },
    async saveData() {
      const prGuideTbDto = {
        insCd: this.prGuideTbDto.insCd,
        prCd: this.prGuideTbDto.prCd,
        prNm: this.prGuideTbDto.prNm,
        minPrem: this.prGuideTbDto.minPrem.replaceAll(',', ""),
        guideFileNmSeq: this.prGuideTbDto.guideFileNmSeq,
        guideFileNm: this.prGuideTbDto.guideFileNm,
        guideFileNmUrl: this.prGuideTbDto.guideFileNmUrl,
        imgFileNm: this.prGuideTbDto.imgFileNm,
        imgFileNmUrl: this.prGuideTbDto.imgFileNmUrl,
        imgFileNmSeq: this.prGuideTbDto.imgFileNmSeq,
        noticeFromDt: this.prGuideTbDto.noticeFromDt,
        noticeToDt: this.prGuideTbDto.noticeToDt,
        displayContYn: this.prGuideTbDto.displayContYn,
        createDate: this.prGuideTbDto.createDate,
        popPrYn: this.prGuideTbDto.popPrYn,
        prPrYn: this.prGuideTbDto.prPrYn,
        popScore: this.prGuideTbDto.popScore,
        popPlanCd: this.prGuideTbDto.popPlanCd,
      }
      console.log("prGuideTbDto")
      if(confirm("저장하시겠습니까?")){
        try {
          const {data} = await axiosInstance.post('/admin/prdata/prGuideSave', prGuideTbDto);
          alert(data);
          this.$emit('update');
        } catch (e) {
          console.log(e);
        }
      }
    },
    htmlEditor: function (url) {
      window.open(url, "_blank");
    }
  }
}
</script>