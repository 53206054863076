<template>
    <v-list-item>
        <v-list-item-content>{{ title }}</v-list-item-content>
        <v-list-item-content class="align-end">
        {{ value }}
        </v-list-item-content>
    </v-list-item>
</template>


<script>

export default {
    props: ["title", "value"]
}
</script>
