<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false"
  >
    <v-flex>
      <v-card max-width="95vw" max-height="85vh" class="overflow-y-auto card-layout">
        <v-card-title class="header-title">
          <h2 class="font-weight-bold">선정산화면</h2>
          <span class="close-button" @click="close">X</span>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-flex xs12 sm12 md12 xl12>
          <div class="mx-3">
            <span style="font-size: 20px; font-weight: 500">정산내역 상세 선택</span>
          </div>
          <v-col cols="12" class="py-0">
            <v-data-table
                height="150"
                :items="selectData"
                :headers="getHeader(selectHeaders)"
                fixed-header calculate-widths hide-default-footer
            >
              <template v-slot:item.contractPremAmt="{ value }">
                <div class="jc-fe">{{ inputComma(value) }}</div>
              </template>
            </v-data-table>
          </v-col>

          <div class="mx-3 mt-2">
            <span style="font-size: 20px; font-weight: 500;">보험사 유입이력 선택</span>
          </div>
          <v-col cols="12" class="py-0">
            <v-data-table
                height="150"
                :items="rowData"
                :headers="getHeader(rowHeaders)"
                fixed-header calculate-widths hide-default-footer
            >
              <template v-slot:item.inviterTelNum="{ value }">
                <div>{{ getTelNum(value) }}</div>
              </template>
              <template v-slot:item.customerTelNum="{ value }">
                <div>{{ getTelNum(value) }}</div>
              </template>
              <template v-slot:item.createDate="{ value }">
                <div>{{ getKST(value) }}</div>
              </template>
            </v-data-table>
          </v-col>

          <div class="d-flex justify-center">
            <v-btn style="background-color: #9e9e9e; color: #fff;"
                   @click="joinedData"
                   :disabled="isJoin || rowData.length < 1">
              연결하기
            </v-btn>
          </div>

          <div class="mx-3 mt-2">
            <span style="font-size: 20px; font-weight: 500;">연결 결과</span>
          </div>
          <v-col cols="12" class="py-0">
            <v-data-table
                height="150"
                :items="joinData"
                :headers="getHeader(joinHeaers)"
                fixed-header calculate-widths hide-default-footer
            >
              <template v-slot:item.recipientTelNum="{ value }">
                <div>{{ getTelNum(value) }}</div>
              </template>
              <template v-slot:item.customerTelNum="{ value }">
                <div>{{ getTelNum(value) }}</div>
              </template>
              <template v-slot:item.contractPremAmt="{ value }">
                <div class="jc-fe">{{ inputComma(value) }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-flex>

        <v-spacer></v-spacer>

        <inflow-hist-search
          @getRowItem="getRowItem"
        />
      </v-card>
    </v-flex>
  </v-overlay>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {
  SETTLEMENT_DETAIL_EARLY_SETTLE_JOIN_TABLE,
  SETTLEMENT_DETAIL_EARLY_SETTLE_ROW_TABLE,
  SETTLEMENT_DETAIL_EARLY_SETTLE_TABLE
} from "@/util/TableHeaders";
import InflowHistSearch from "@/pages/User/InflowHistSearch";
import {axiosInstance} from "@/util/AxiosModule";
import DateMixin from "@/mixin/DateMixin";

export default {
  components: {InflowHistSearch},
  props: ["isShow", "selectData"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      selectHeaders: SETTLEMENT_DETAIL_EARLY_SETTLE_TABLE,
      rowHeaders: SETTLEMENT_DETAIL_EARLY_SETTLE_ROW_TABLE,
      joinHeaers: SETTLEMENT_DETAIL_EARLY_SETTLE_JOIN_TABLE,
      rowData: [],
      joinData: [],
      isJoin: false
    }
  },
  methods: {
    close() {
      this.resetData();
      this.$emit("close");
    },
    getRowItem(item) {
      this.rowData = [];
      this.rowData.push(item);
    },
    async joinedData() {
      if (confirm("연결을 진행하시겠습니까?")) {
        try {
          const { data } = await axiosInstance.get("/admin/settlement/guestEarlySettlementJoin", {
            params: {
              memberId: this.$store.getters["login/memberId"],
              geustSettlementId: this.selectData[0].carSettlementHistId,
              carPartnerJoinHistId: this.rowData[0].carPartnerJoinHistId
            }
          });
          data.rewardStatus = "정산완료";
          this.joinData.push(data);
          this.isJoin = true;
          alert("연결이 완료되었습니다.");
        } catch (e) {
          console.log(e);
        }
      }
    },
    resetData() {
      this.isJoin = false;
      this.rowData = [];
      this.joinData = [];
      this.$emit("resetData");
    }
  }
}
</script>

<style scoped>
.card-layout {
  padding: 10px 20px;
}

.header-title {
  display: flex;
  justify-content: space-between;
}
.header-title .close-button:hover {
  cursor: pointer;
}

.row-container .v-col {
  display: flex;
  justify-content: center;
  justify-items: center;
}
</style>