<template>
  <v-col cols="12" class="py-3">
    <!-- 엑셀업로드 -->
    <div v-if="tab === 1" class="mt-2 d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <span class="text-h6 mx-3">목록 (총 {{ detailData.length }}개)</span>
        <outlined-btn class="mr-3 progress-button" @click="showPopup('progress')" :disabled="selectData.length < 1">
          작업단계 변경
        </outlined-btn>
        <outlined-btn class="product-button" @click="showPopup('product')" :disabled="selectData.length !== 1">
          상품명 변경
        </outlined-btn>
      </div>
      <div class="text-right">
        <outlined-btn class="px-5 excel-button" @click="$emit('toExcel')" :disabled="checkDataLength">
          엑셀다운로드
        </outlined-btn>
      </div>
    </div>
    <span v-if="tab === 0" class="text-h6 ml-2">목록 (총 {{ totalDataLength }}개)</span>
    <v-flex xl12 lg12 md12 sm12 xs12>
      <v-data-table
        v-if="tab === 0"
        height="50vh"
        :headers="getHeader(totalHeaders)"
        :items="styledItems(data)"
        :loading="loading"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        :items-per-page="-1" fixed-header calculate-widths
      >
        <template v-slot:item="{ item }">
          <tr @dblclick="rowClickSearch(item)" style="cursor: pointer">
            <td v-for="header in totalHeaders" :key="header[1]" :style="item.styles ? item.styles[header[1]] : ''">
              <template v-if="header[1] === 'stateColor'">
                <div :style="getCircleStyle(item[header[1]])"></div>
              </template>
              <template v-else>
                {{ item[header[1]] }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-data-table
          v-if="tab === 1"
          height="50vh"
          :headers="getHeader(detailHeaders)"
          :items="detailData"
          :loading="loading"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          :items-per-page="-1" fixed-header calculate-widths
          v-model="selectData"
          show-select item-key="index"
          :single-select="isDataHistory"
      >
        <template v-slot:item.prodType="{ value }">
          <div>{{ getChangeCdName("prodType", value) }}</div>
        </template>
        <template v-slot:item.sex="{ value }">
          <div>{{ getChangeCdName("sex", value) }}</div>
        </template>
        <template v-slot:item.appFromDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.appToDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.newDispEndDt="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.wkStateCd="{ value }">
          <div>{{ getChangeCdName("wkStateCd", value) }}</div>
        </template>
        <template v-slot:item.wkSubStateCd="{ item }">
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on }">
              <div>
                {{ getChangeCdName("wkSubStateCd", item.wkSubStateCd) }}
                <v-icon @click="snackbar = true" v-on="on" v-clipboard:copy="item.wkMsgCont">
                  mdi-content-copy
                </v-icon>
              </div>
            </template>
            <span>{{ item.wkMsgCont }}</span>
          </v-tooltip>
          <v-snackbar v-model="snackbar" :top="'top'" :timeout="timeout">메모 복사완료</v-snackbar>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ getDateNoSecFormat(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>

    <progress-stage-change
      :isShow="isProgress"
      :wkStepList="wkStepList"
      :wkSubStepList="wkSubStepList"
      :wkSubStateList="wkSubStateList"
      :rowData="selectData"
      @close="closePopup"
      @updateData="updateData"
    />

    <product-name-change
      :isShow="isProduct"
      :prProdLineList="prProdLineList"
      :insList="insList"
      :wkStepList="wkStepList"
      :wkSubStepList="wkSubStepList"
      :wkSubStateList="wkSubStateList"
      :useYnList="useYnList"
      :rowData="selectData"
      @close="closePopup"
      @updateData="updateData"
    />
  </v-col>
</template>

<script>
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import TableMixin from "@/mixin/TableMixin";
import {
  PRODUCT_RECOMMEND_WORK_STATUS_DETAIL_HEADER,
  PRODUCT_RECOMMEND_WORK_STATUS_TOTAL_HEADER
} from "@/util/TableHeaders";
import ProgressStageChange from "@/pages/Product/components/ProductRecommendWorkStatus/Popup/ProgressStageChange";
import ProductNameChange from "@/pages/Product/components/ProductRecommendWorkStatus/Popup/ProductNameChange";
import constant from "@/util/constant";
import DateMixin from "@/mixin/DateMixin";
import ProductRecommend from "@/util/ProductRecommend";
import _ from "lodash";

export default {
  components: {ProductNameChange, ProgressStageChange, OutlinedBtn },
  props: [
    "tab",
    "prProdLineList", "insList", "wkSubStepList", "wkStateList", "wkSubStateList",
    "detailParams", "data", "detailData", "loading", "isReset"
  ],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      totalHeaders: PRODUCT_RECOMMEND_WORK_STATUS_TOTAL_HEADER,
      detailHeaders: PRODUCT_RECOMMEND_WORK_STATUS_DETAIL_HEADER,
      wkStepList: [],
      useYnList: [
        { useYnNm: "Y", useYn: "Y" },
        { useYnNm: "N", useYn: "N" }
      ],
      snackbar: false,
      timeout: 1500,
      selectData: [],
      isProgress: false,
      isProduct: false,
      isDataHistory: false
    }
  },
  computed: {
    totalDataLength() {
      if (this.data.length > 1) {
        return this.data.length - 1;
      }
      return 0;
    },
    checkDataLength() {
      return this.detailData.length <= 0;
    }
  },
  async created() {
    this.wkStepList = await ProductRecommend.getWkStepList();
  },
  watch: {
    isReset(value) {
      if (value) {
        this.selectData = [];
      }
    },
    tab(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.selectData = [];
      }
    },
    detailData(value) {
      if (value) {
        if (this.detailParams.prCd !== "") {
          this.isDataHistory = true;
          let filteredValue = value.filter((item, index) => index !== 0);
          filteredValue = filteredValue.map(item => {
            item.wkStateCd = "-";
            item.isSelectable = false;
            return item;
          });
          value = [value[0], ...filteredValue];
        } else {
          this.isDataHistory = false;
        }
      }
    }
  },
  methods: {
    styledItems(items) {
      if (!_.isEmpty(items)) {
        return items.map((item, index) => {
          // 기본 스타일
          const defaultStyle = 'text-align:center;';
          // 첫번째 열 스타일
          const firstRowStyle = 'font-weight: bold; border-bottom: 1px solid #868686; text-align: center;';
          // 첫번째 열 타이틀 스타일(프로퍼티 "key"값이 "prProdLineNm"(상품군), "insNm"(보험사)만 해당)
          const firstRowTitleStyle = 'backgroundColor: rgb(255 165 165 / 0.3); font-weight: bold; text-align: center; border-bottom: 1px solid #868686;';
          // 최대값 스타일 제외 목록헤더 프로퍼티 "key"값
          const exceptionList = ["prProdLineNm", "prProdLineCd", "insNm", "insCd", "target", "inProcState", "onProc", "offProc", "stateColor", "type", "index"];

          // 집계테이블 목록 첫번째 열
          // 합계 목록 열에 대한 스타일 적용
          if (index === 0) {
            const styles = Object.keys(item).reduce((acc, key) => {
              if (key === "prProdLineNm" || key === "insNm") {
                acc[key] = firstRowTitleStyle;
              } else {
                acc[key] = firstRowStyle;
              }
              return acc;
            }, {});
            return { ...item, styles};
          }

          // 집계테이블 목록 첫번째 열 제외
          // 목록이 이력관력 데이터일 경우 실행
          if (item.type === "S" && index !== 0) {
            const values = Object.keys(item)
                .filter(key => !exceptionList.includes(key))
                .map(key => {
                  const value = item[key];
                  return value !== null ? Number(value.replace("-", "")) : value;
                });

            const max = Math.max(...values);

            // 최대값에 대해 'color:red' 스타일을 적용
            const styles = Object.keys(item).reduce((acc, key) => {
              if (exceptionList.includes(key)) {
                acc[key] = defaultStyle;
                if (key === "onProc" || key === "offProc") {
                  if (item[key] !== null && item[key].startsWith("0")) {
                    item[key] = item[key].slice(1);
                  }
                }
              } else {
                const value = item[key];
                const numericValue = value !== null ? Number(value.replace("-", "")) : value;
                acc[key] = numericValue === max ? 'color:red; text-align:center;' : defaultStyle;
                if (item[key] !== null && item[key].startsWith("0")) {
                  item[key] = item[key].slice(1);
                }
              }
              return acc;
            }, {});
            return { ...item, styles };
          }

          // 나머지 스타일(defaultStyle 적용)
          const styles = Object.keys(item).reduce((acc, key) => {
            acc[key] = defaultStyle;
            return acc;
          }, {});

          return { ...item, styles };
        });
      }
    },
    getChangeCdName(type, value) {
      if (type === "wkStateCd") {
        const item = this.wkStateList.find(items => items.wkStateCd === value);
        return item ? item.wkStateNm : "-";
      } else if (type === "wkSubStateCd") {
        const item = this.wkSubStateList.find(items => items.wkSubStateCd === value);
        return item ? item.wkSubStateNm : "-";
      } else if (type === "prodType") {
        const item = constant.PROD_TYPE.find(items => items.prodType === value);
        return item ? item.prodTypeNm : "-";
      } else if (type === "sex") {
        const item = constant.SEX_LIST.find(items => items.cd === value);
        return item ? item.name : "-";
      }
      return value;
    },
    getCircleStyle(value) {
      let color;
      switch (value) {
        case 'r':
          color = 'red';
          break;
        case 'y':
          color = '#ffc400';
          break;
        case 'g':
          color = 'green';
          break;
        default:
          color = 'gray';
      }
      return {
        backgroundColor: color,
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        display: 'inline-block'
      };
    },
    showPopup(type) {
      if (type === "progress") {
        this.isProgress = true;
      } else if (type === "product") {
        this.isProduct = true;
      } else {
        return false;
      }
      return false;
    },
    closePopup() {
      this.selectData = [];
      this.isProgress = false;
      this.isProduct = false;
    },
    rowClickSearch(row) {
      this.$emit("rowClickSearch", row);
    },
    updateData() {
      this.selectData = [];
      this.$emit("updateData");
    }
  }
}
</script>

<style scoped>
.progress-button:hover {
  background-color: #ff9800;
  color: #fff;
  border: 1px solid #ff9800;
}
.product-button:hover {
  background-color: #1976d2;
  color: #fff;
  border: 1px solid #1976d2;
}
.excel-button:hover {
  background-color: darkgreen;
  color: #fff;
  border: none;
}
</style>