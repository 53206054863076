<template>
  <search-page-layout title="원수사 데이터 수신 현황">
    <template v-slot:searchBar>
      <reception-status-search-bar
          @getSearch = "getSearch"
        />
    </template>

    <template v-slot:table>
      <reception-status-table
          :tableData="data"
          :loading="loading"
          @updateSetting = "updateSetting"
        />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import ReceptionStatusSearchBar from "./components/ReceptionStatusSearchBar";
import ReceptionStatusTable from "./components/ReceptionStatusTable";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: {ReceptionStatusTable, ReceptionStatusSearchBar, SearchPageLayout},
  data:() => ({
    loading: false,
    data: [],
    params: []
  }),
  methods: {
    async getSearch(params) {
      this.params = params;
      const inParam = {
        insCd: params.insCd,
        description: params.description,
        stdt: params.stdt,
        eddt: params.eddt
      }
      try {
        const { data } = await axiosInstance.post('/admin/prdata/getExtFileInfo', inParam);
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].downloadDate !== null) {
            this.data[i].downloadDate = this.data[i].downloadDate.substr(0, 19);
          }
          if (this.data[i].createDate !== null) {
            this.data[i].createDate = this.data[i].createDate.substr(0, 19);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    updateSetting() {
      this.getSearch(this.params);
    }
  }
}
</script>