<template>
  <v-col cols="12" class="py-1 px-3">
    <!-- 엑셀 다운로드 버튼 -->
    <div class="mb-2 text-right" style="margin: -40px 3px 0 0;">
      <outlined-btn @click="toExcel" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>
    <!-- 엑셀 다운로드 버튼 -->

    <v-flex xs12 sm12 md12 lg12 xl12>
      <!-- 보험사 목록 -->
        <!-- TableMixin.js 파일에 있는 inputComma 함수로 숫자 3자리 마다 콤마추가 -->
        <!-- DateMixin.js 파일에 있는 getDateDashFormat 함수로 yyyyMMdd ==> yyyy-MM-dd 포맷으로 변경 -->
      <v-data-table
        v-if="tabs === 0"
        height="45vh"
        :items="insData"
        :loading="loading"
        :headers="getHeader(insHeaders)"
        fixed-header calculate-widths
        :items-per-page="-1"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >
        <template v-slot:item.createDate="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.compareSum="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.shareSum="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.mzCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.mzShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.hwCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.hwShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.ltCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.ltShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.hkCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.hkShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.ssCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.ssShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.hdCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.hdShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.kbCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.kbShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.dbCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.dbShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
      </v-data-table>
      <!-- 보험사 목록 -->

      <!-- 설계고객연령 목록 -->
        <!-- TableMixin.js 파일에 있는 inputComma 함수로 숫자 3자리 마다 콤마추가 -->
        <!-- DateMixin.js 파일에 있는 getDateDashFormat 함수로 yyyyMMdd ==> yyyy-MM-dd 포맷으로 변경 -->
      <v-data-table
          v-if="tabs === 1"
          height="45vh"
          :items="ageData"
          :loading="loading"
          :headers="getHeader(customHeaders)"
          fixed-header calculate-widths
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.createDate="{ value }">
          <div>{{ getDateDashFormat(value) }}</div>
        </template>
        <template v-slot:item.compareSum="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.shareSum="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.mUn30CompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.mUn30ShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.wUn30CompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.wUn30ShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.m30sCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.m30sShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.w30sCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.w30sShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.m40sCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.m40sShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.w40sCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.w40sShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.m50sCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.m50sShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.w50sCompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.w50sShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.mUp60CompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.mUp60ShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.wUp60CompCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.wUp60ShareCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
      </v-data-table>
      <!-- 설계고객연령 목록 -->
    </v-flex>
  </v-col>
</template>


<script>
import TableMixin from "@/mixin/TableMixin";
import {
  PRODUCT_RECOMMEND_USAGE_STATUS_CUSTOM_TABLE,
  PRODUCT_RECOMMEND_USAGE_STATUS_INS_TABLE,
} from "@/util/TableHeaders";
import DateMixin from "@/mixin/DateMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import func from "@/util/func";

export default {
  components: {OutlinedBtn},
  props: ["tabs", "insData", "ageData", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      // 보험사 목록 헤더
      insHeaders: PRODUCT_RECOMMEND_USAGE_STATUS_INS_TABLE,
      // 설계고객연력 목록 헤더
      customHeaders: PRODUCT_RECOMMEND_USAGE_STATUS_CUSTOM_TABLE
    }
  },
  computed: {
    // 목록에 데이터의 길이가 0일때 엑셀다운로드 버튼 비활성화
    hasTableData() {
      return this.insData.length > 0 || this.ageData > 0;
    }
  },
  methods: {
    // 엑셀다운로드 기능
    toExcel() {
      const returnData = [];
      for (const item of this.insData) {
        if (this.tabs === 0) {
          const obj = {
            "일자": item["createDate"],
            "합계(비교)": item["compareSum"],
            "합계(공유)": item["shareSum"],
            "메리츠(비교)": item["mzCompCnt"],
            "메리츠(공유)": item["mzShareCnt"],
            "한화(비교)": item["hwCompCnt"],
            "한화(공유)": item["hwShareCnt"],
            "롯데(비교)": item["ltCompCnt"],
            "롯데(공유)": item["ltShareCnt"],
            "흥국(비교)": item["hkCompCnt"],
            "흥국(공유)": item["hkShareCnt"],
            "삼성(비교)": item["ssCompCnt"],
            "삼성(공유)": item["ssShareCnt"],
            "현대(비교)": item["hdCompCnt"],
            "현대(공유)": item["hdShareCnt"],
            "KB(비교)": item["kbCompCnt"],
            "KB(공유)": item["kbShareCnt"],
            "DB(비교)": item["dbCompCnt"],
            "DB(공유)": item["dbShareCnt"],
          }
          returnData.push(obj);
        }
        for (const item of this.ageData) {
          if (this.tabs === 1) {
            const obj = {
              "일자": item["createDate"],
              "합계(비교)": item["compareSum"],
              "합계(공유)": item["shareSum"],
              "30세미만[남](비교)": item["mUn30CompCnt"],
              "30세미만[남](공유)": item["mUn30ShareCnt"],
              "30세미만[여](비교)": item["wUn30CompCnt"],
              "30세미만[여](공유)": item["wUn30ShareCnt"],
              "30대[남](비교)": item["m30sCompCnt"],
              "30대[남](공유)": item["m30sShareCnt"],
              "30대[여](비교)": item["w30sCompCnt"],
              "30대[여](공유)": item["w30sShareCnt"],
              "40대[남](비교)": item["m40sCompCnt"],
              "40대[남](공유)": item["m40sShareCnt"],
              "40대[여](비교)": item["w40sCompCnt"],
              "40대[여](공유)": item["w40sShareCnt"],
              "50대[남](비교)": item["m50sCompCnt"],
              "50대[남](공유)": item["m50sShareCnt"],
              "50대[여](비교)": item["w50sCompCnt"],
              "50대[여](공유)": item["w50sShareCnt"],
              "60세이상[남](비교)": item["mUp60CompCnt"],
              "60세이상[남](공유)": item["mUp60ShareCnt"],
              "60세이상[여](비교)": item["wUp60CompCnt"],
              "60세이상[여](공유)": item["wUp60ShareCnt"],
            }
            returnData.push(obj);
          }
        }
      }
      let subTitle = "";
      if (this.tabs === 0) subTitle = `(보험사)`;
      else if (this.tabs === 1) subTitle = `(설계고객연령)`;

      const sheetName = `장기 상품추천시스템 사용현황` + subTitle;
      const fileName = `장기 상품추천시스템 사용현황` + subTitle;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style scoped>
/* 목록 첫번째 행(합계 행) 바탕색 추가 */
.v-data-table::v-deep tr:first-child {
  background-color: #98c0c3;
}
/* 목록에 데이터가 없을 시 첫번째 열 숨김 */
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>