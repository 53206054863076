<template>
    <v-dialog
        v-model="show"
        width="1200"
    >
        <v-layout column>
            <v-layout row>
            <v-flex>
                <v-card height="100%">
                    <v-card-title class="teal lighten-4 justify-center subheading font-weight-bold">
                        고객 및 차량정보
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list class="pa-3" dense>
                       <calc-hist-modal-item :title="customerNm" :value="carInfo.customerNm"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="inviterNm" :value="carInfo.inviterNm"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="brandCode" :value="carInfo.brandCode"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="carName" :value="carInfo.carName"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="detailCarName" :value="carInfo.detailCarName"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="regYear" :value="carInfo.regYear"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="carNo" :value="carInfo.carNo"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="blackBoxDc" :value="carInfo.blackBoxDc"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="purchaseYear" :value="carInfo.purchaseYear"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="purchaseAmt" :value="carInfo.purchaseAmt"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="driverRange" :value="carInfo.driverRange"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="youngestBirth" :value="carInfo.youngestBirth"></calc-hist-modal-item>
                     </v-list>    
                </v-card>
            </v-flex>
            <v-flex> 
                <v-card height="100%">
                    <v-card-title class="teal lighten-4 justify-center subheading font-weight-bold">  
                        담보내용
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list dense>
                       <calc-hist-modal-item :title="basicAgreement1" :value="carInfo.basicAgreement1"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="basicAgreement2" :value="carInfo.basicAgreement2"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="basicAgreement3" :value="carInfo.basicAgreement3"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="basicAgreement4" :value="carInfo.basicAgreement4"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="basicAgreement5" :value="carInfo.basicAgreement5"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="basicAgreement6" :value="carInfo.basicAgreement6"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="basicAgreement7" :value="carInfo.basicAgreement7"></calc-hist-modal-item>
                    </v-list>    
                </v-card> 
            </v-flex>    
            <v-flex>
                <v-card height="100%">
                    <v-card-title class="teal lighten-4 justify-center subheading font-weight-bold">  
                        특약내용
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list dense>
                       <calc-hist-modal-item :title="specialDc1" :value="carInfo.specialDc1"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDcDetail1" :value="carInfo.specialDcDetail1"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc2" :value="carInfo.specialDc2"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc3" :value="carInfo.specialDc3"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDcDetail3" :value="carInfo.specialDcDetail3"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc4" :value="carInfo.specialDc4"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc7" :value="carInfo.specialDc7"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDcDetail71" :value="carInfo.specialDcDetail71"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDcDetail72" :value="carInfo.specialDcDetail72"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc8" :value="carInfo.specialDc8"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc9" :value="carInfo.specialDc9"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc10" :value="carInfo.specialDc10"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDcDetail10" :value="carInfo.specialDcDetail10"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc11" :value="carInfo.specialDc11"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDcDetail11" :value="carInfo.specialDcDetail11"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc12" :value="carInfo.specialDc12"></calc-hist-modal-item>
                       <calc-hist-modal-item :title="specialDc13" :value="carInfo.specialDc13"></calc-hist-modal-item>
                       </v-list>    
                </v-card>
            </v-flex>
            </v-layout>
            <v-flex>
                <v-data-table
                width="100%"
                :headers="headers"
                :items="insuranceAmt"
                :items-per-page="countPage">
                </v-data-table>   
            </v-flex>
        </v-layout>
    </v-dialog>
</template>


<script>
import CalcHistModalItem from "@/pages/User/components/CalcHistSearch/CalcHistModalItem";

export default {
    components: {CalcHistModalItem},
    props: ["dialog", "carInfo", "insuranceAmt"],
    computed: {
        show: {
            get() {
                return this.dialog
            },
            set(value) {
                this.$emit("updateSetting", value)
            }
        }
    },
    data: () => ({
        customerNm: "이름",
        inviterNm: "추천인",
        carNo: "차량번호",
        brandCode: "브랜드명",
        carName: "모델명",
        regYear: "등록연식",
        detailCarName: "모델 상세",
        basicAgreement1: "대인배상2",
        basicAgreement2: "대물배상",
        basicAgreement3: "자기신체손해",
        basicAgreement4: "무보험상해",
        basicAgreement5: "긴급출동서비스",
        basicAgreement6: "자기차량손해",
        basicAgreement7: "물적사고할증기준금액",
        driverRange: "운전자범위",
        youngestBirth: "최소운전자 생년월일",
        blackBoxDc: "블랙박스",
        purchaseYear: "블랙박스구입시기",
        purchaseAmt: "블랙박스구입가격",
        specialDc1: "마일리지할인",
        specialDcDetail1: "마일리지할인 상세",
        specialDc2: "Email할인",
        specialDc3: "자녀할인",
        specialDcDetail3: "자녀나이",
        specialDc4: "대중교통할인",
        specialDc7: "과거주행거리연동",
        specialDcDetail71: "과거연평균주행거리",
        specialDcDetail72: "전년도주행거리",
        specialDc8: "커넥티드할인",
        specialDc9: "서민우대",
        specialDc10: "안전운전습관",
        specialDcDetail10: "안전운전점수",
        specialDc11: "현대안전운전습관",
        specialDcDetail11: "현대안전운전점수",
        specialDc12: "차선이탈경고장치",
        specialDc13: "전방충돌방지장치",
        headers: [{text: "보험사명", value: "resCompanyNmCode", class: "teal lighten-4", align: "center", sortable: false},
                  {text: "보험료", value: "resTotalPremium", class: "teal lighten-4", align: "center", sortable: false},
                  {text: "마일리지 적용 보험료 선할인", value: "resMileageFirstAmt", class: "teal lighten-4", align: "center", sortable: false},
                  {text: "마일리지 적용 보험료 후할인", value: "resMileageAfterAmt", class: "teal lighten-4", align: "center", sortable: false},
                  {text: "특별할인목록", value: "resSpecialDcList", class: "teal lighten-4", align: "center", sortable: false},
                  ],
        countPage: 11


    })
   
}
</script>
