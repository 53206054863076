<template>
  <v-col cols="12" class="py-0">
    <div class="my-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <v-btn @click="dataMapping" class="light-blue white--text theme--light float-right" style="margin-left: 10px;">담보명검색</v-btn>
      <v-btn @click="selectDataSave" class="indigo lighten-1 white--text theme--light float-right">정보변경</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          item-key="index"
          v-model="selectData"
          show-select multi-sort="multi-sort"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          :headers="headers"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >
      </v-data-table>
    </v-flex>
    <product-management-save-modal
        @close="close"
        :is-show="isShow"
        @update="update"
        :selectData="selectData"
      />
    <product-management-mapping-modal
      @close="showModal = false"
      :is-show="showModal"
      />
  </v-col>
</template>
<script>
import ProductManagementSaveModal from "./ProductManagementSaveModal";
import ProductManagementMappingModal from "./ProductManagementMappingModal";
export default {
  components: {ProductManagementMappingModal, ProductManagementSaveModal},
  props: ["data", "loading"],
  data: () => ({
    isShow:false,
    showModal:false,
    headers: [{text: "보험사", value: "insNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상품코드", value: "prCd", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상품명", value: "prNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "담보명", value: "cvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "담보기본특약구분", value: "cvrSpDiv", class: "teal lighten-4", align: "center", sortable: false, width: "10vw"},
      {text: "약관구분", value: "cvrSpDivNm", class: "teal lighten-4", align: "center", sortable: false, width: "10vw"},
      {text: "대표담보코드", value: "cvrSeltRepCd", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "대표담보명", value: "cvrSeltRepNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "담보선택수", value: "cvrSeltCnt", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "저장일시", value: "updateDate", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
    ],
    selectData:[]
  }),
  methods: {
    selectDataSave() {
      for (let i = 0; i < this.selectData.length; i++) {
        if (this.selectData[0].insNm !== this.selectData[i].insNm) {
          alert('정보변경은 보험사, 상품코드가 동일한 경우에 가능합니다.');
          return;
        }
        if (this.selectData[0].prCd !== this.selectData[i].prCd) {
          alert('정보변경은 보험사, 상품코드가 동일한 경우에 가능합니다.');
          return;
        }
      }
      this.isShow = true;
    },
    close() {
      this.selectData = [];
      this.isShow = false;
    },
    update() {
      this.selectData = [];
      this.isShow = false;
      this.$emit('update');
    },
    dataMapping() {
      this.showModal = true;
    }
  }
}
</script>