<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="조회조건">
        <field-input title="구분">
          <v-select
              :items="SEARCH_CODE_TYPE"
              background-color="white"
              v-model="type" menu-props="auto"
              hide-details outlined dense
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getCodeList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import FieldInput from "@/components/Form/FieldInput";
import SearchBtn from "@/components/Search/SearchBtn";
import {mapActions} from "vuex";
import store from "@/store";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [CodeMixin],
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout},
  async created() {
    this.SEARCH_CODE_TYPE = await this.getSelectList("SEARCH_CODE_TYPE");
  },
  data: () => ({
    type: "ALL",
    SEARCH_CODE_TYPE: []
  }),
  methods: {
    ...mapActions("code", ["setSearchType"]),
    async getCodeList() {
      this.$emit('search', this.type);
      await store.dispatch('code/setSearchType', this.type);
    }
  }
}
</script>

<style scoped>

</style>