<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-row>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
            <v-col cols="5">발송구분</v-col>
            <v-radio-group row v-model="gb" style="flex: 1 1 auto;">
              <v-radio label="대상" value="target" />
              <v-radio label="완료" value="complete" />
            </v-radio-group>
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
            <v-col cols="5">회원명</v-col>
            <v-text-field
                v-model="memberNm"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
            <v-col cols="5">최근 미실적기간</v-col>
              <v-text-field
                  v-model="months"
                  outlined dense hide-details
                  background-color="white"
              />
            <v-col cols="5">개월</v-col>
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
            <v-col cols="5">최소 금액</v-col>
            <v-text-field
                v-model="amt"
                outlined dense hide-details
                background-color="white"
                @input="setAmt"
            />
            <v-col cols="1">원</v-col>
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
            <v-col cols="5">상담사명</v-col>
            <v-text-field
                v-model="ctCsNm"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-row>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout.vue";
import SearchArea from "@/components/Search/SearchArea.vue";
import SearchBtn from "@/components/Search/SearchBtn.vue";
import TableMixin from "@/mixin/TableMixin";

export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  mixins: [TableMixin],
  data() {
    return {
      gb: "target",
      memberNm: "",
      months: 2,
      amt: 100000,
      ctCsNm: ""
    }
  },
  mounted() {
    this.amt = this.inputComma(this.amt);
  },
  methods: {
    setAmt() {
      this.amt = this.inputComma(this.amt.replaceAll(",", ""));
    },
    getSearch() {
      const params = {
        gb: this.gb,
        memberNm: this.memberNm,
        months: Number(this.months),
        amt: Number(this.amt.replaceAll(",", "")),
        ctCsNm: this.ctCsNm
      };
      this.$emit("search", params);
    }
  }
}
</script>