import XLSX from "xlsx";
import store from "@/store";
// import _ from "lodash";
import {axiosInstance} from "@/util/AxiosModule";

const getToday = function getToday() {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return year + "" + month + "" + day;
};

const getYearMonth = function getYearMonth() {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  if (month < 10) {
    month = "0" + month;
  }
  return year + "" + month;
};

const numberWithCommas = function numberWithCommas(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
};

const maxLengthCheck = function maxLengthCheck(obj) {
  return obj.value.length > obj.maxLength
      ? obj.value.slice(0, obj.maxLength)
      : obj.value;
};

const findIndex = function(list, obj) {
  return list.findIndex(data => data.cd === obj);
};

const strSpaceCheck = function(str) {
  return str.replace(/ /gi, "");
};

const valueNullCheck = function(value) {
  return value === null ? "-" : value;
};

const jsonIsEmpty = function(obj) {
  return JSON.stringify(obj) === JSON.stringify({});
};

const isEmpty = function(value) {
  if (
      value == "" ||
      value == null ||
      value == undefined ||
      (value != null && typeof value == "object" && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};

const calculateAge = function(temp) {
  const today = new Date();
  const year = temp.substring(0, 4);
  const month = temp.substring(4, 6);
  const day = temp.substring(6, 8);
  const birthday = new Date(year, month - 1, day);
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
};

//주민번호 유효성체크 21.08.10 hp9990
const validatorssno = function(ssnFront, ssnBack) {

  if(ssnBack.substring(0,1) > 4)
  {
    //동일한 숫자가 연속4번이상 나오는 경우 false 리턴
    return !/(\d)\1\1\1/.test(ssnBack.substring(1,7));
  }


  var arrNum1 = new Array();
  var arrNum2 = new Array();

  for (var i = 0; i < ssnFront.length; i++) {
    arrNum1[i] = ssnFront.charAt(i);
  }
  for (i = 0; i < ssnBack.length; i++) {
    arrNum2[i] = ssnBack.charAt(i);
  }
  var tempSum = 0;

  for (i = 0; i < ssnFront.length; i++) {
    tempSum += arrNum1[i] * (2 + i);
  } // 주민번호 앞 번호를 모두 계산하여 더함

  for (i = 0; i < ssnBack.length - 1; i++) {
    if (i >= 2) {
      tempSum += arrNum2[i] * i;
    } else {
      tempSum += arrNum2[i] * (8 + i);
    }
  } // 같은방식으로 앞 번호 계산한것의 합에 뒷번호 계산한것을 모두 더함

  return (11 - (tempSum % 11)) % 10 == arrNum2[6];
};

function lpad(src, len, padStr) {
  let retStr = "";
  let padCnt = Number(len) - String(src).length;
  if (Number(padCnt) < 1) {
    return String(src).substring(0, Number(len));
  }
  for(let i=0;i<padCnt;i++) {
    retStr += String(padStr);
  }
  return retStr+src;
}

function rpad(src, len, padStr){
  let retStr = "";
  let padCnt = Number(len) - String(src).length;
  if (Number(padCnt) < 1) {
    return String(src).substring(0,Number(len));
  }
  for(let i=0;i<padCnt;i++) {
    retStr += String(padStr);
  }
  return src+retStr;
}
//금액 형식 표시
function inputComma(num) {
  return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function contentsDataFormatter(contents) {

  // 시간 yyyy-mm-dd로 맞춰줌
  for ( var data in contents) {
    //ex) 2021-11-02T17:47:13.677395
    let d = contents[data].createDate ? contents[data].createDate : '';
    contents[data].createDate =  d ? `${d.slice(0, 4)}-${d.slice(5, 7)}-${d.slice(8,10)} ${d.slice(11,16)}` : ''; // 2021-11-02 17:47:13
  }
  return contents
}

function contentsDataFormatter2(date) {
  let d = date ? date.toString() : '';
  return d ? `${d.slice(0, 10)} ${d.slice(11, 19)}` : '';
  // 2024-02-19 14:06:54.001875+09 -> 2024-02-19 14:06:54
}

/**
 * 엑셀 추출
 * @param excelData {Object} 추출내용
 * @param sheetName {string} 시트 이름
 * @param fileName {string} 파일명
 */
function extractExcelFile(excelData, sheetName, fileName) {
  const json2sheet = XLSX.utils.json_to_sheet(excelData);

  const board = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(board, json2sheet, sheetName);
  XLSX.writeFile(board, fileName + `.xlsx`);
}

/**
 * 엑셀 추출 이력 저장
 * @param menuNm {String} 페이지명
 * @param extCnt {number} 추출된 데이터 수
 * @param condition {String} 조회 조건
 * @param xlsNm {String} 엑셀 파일 명
 * @param grade {String} 주의 등급 (HIGH, MIDDLE, LOW)
 */
async function saveXlsExtractHist(menuNm, extCnt, condition, fileNm, grade) {
  const params = {
    memberId: store.getters['login/memberId'],
    menuNm: menuNm,
    extCnt: extCnt,
    condition: condition,
    xlsNm: fileNm,
    grade: grade,
  }

  try {
    await axiosInstance.post('/admin/operation/saveXlsExtractHist', params);
    console.log("다운로드 이력 저장됨")
  } catch (e) {
    console.log(e);
  }
}

/**
 * 사용자별 권한 체크
 * @param checkPrvList {Array} 체크할 권한 리스트
 * @returns {string}
 */
function checkUserPrvGroup(checkPrvList) {
  let userProgPrvList = store.getters["login/userProgPrvList"];
  const adminPrvList = ["ADM", "DEV"];

  // 관리자 권한 리스트와 비교
  if (adminPrvList.some(item => new Set(userProgPrvList).has(item))) {
    return "A";
  }
  // 체크 권한 리스트와 비교
  return checkPrvList.some(item => new Set(userProgPrvList).has(item)) ? "Y" : "N";
}

export default {
  jsonIsEmpty,
  isEmpty,
  getToday,
  numberWithCommas,
  maxLengthCheck,
  findIndex,
  strSpaceCheck,
  valueNullCheck,
  calculateAge,
  getYearMonth,
  validatorssno,
  lpad,
  rpad,
  inputComma,
  contentsDataFormatter,
  contentsDataFormatter2,
  extractExcelFile,
  saveXlsExtractHist,
  checkUserPrvGroup
};
