<template>
  <search-layout title=" ">

    <template v-slot:searchBar>
      <status-bar
          @search="getCalcList"
          @toExcel="toExcel"
          />
    </template>

    <template v-slot:table>
      <status-table
        :summary = "summary"
        :loading="loading"
        />
    </template>

  </search-layout>

</template>

<script>
import SearchLayout from "@/layouts/SearchLayout";
import StatusBar from "./SummaryBar";
import {axiosInstance} from "@/util/AxiosModule";
import StatusTable from "./SummaryTable";
import func from "@/util/func";
import _ from "lodash";

export default {
  components: {
    StatusTable,
    StatusBar,
    SearchLayout
  },
  data: () => ({
    loading: false,
    summary: [],
  }),
  created() {
  },
  methods: {
    async getCalcList(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/collection/summary', {params});
        this.summary = data;
        for (let i = 0; i < data.length; i++) {
          if(_.isEmpty(data[i].standardDt)){
            this.summary[i].standardDt = ''
          }else {
            const year = data[i].standardDt.substring(0, 4);
            const mon = data[i].standardDt.substring(4, 6);
            const day = data[i].standardDt.substring(6, 8);
            this.summary[i].standardDt =  year + '/' + mon + '/' + day;
          }
        }
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    toExcel() {
      if(_.isEmpty(this.summary)) return;
      const data = _.cloneDeep(this.summary);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "일자": item["standardDt"],
          "사용자수": item["userCnt"],
          "다운로드수": item["downLoadCnt"],
          "로그인수": item["loginCnt"],
          "조회수": item["queryCnt"],
          "결과건수": item["resultCnt"],
          "엑셀저장수": item["excelCnt"],
          "메인지역입력건": item["mainResionCnt"],
          "서브지역입력건": item["subResionCnt"],
        }
        returnData.push(obj);
      }
      const sheetName = `가망고객 DB 수집 프로그램 사용 집계현황`;
      const fileName = `가망고객 DB 수집 프로그램 사용 집계현황List`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style scoped>

</style>