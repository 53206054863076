var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-3",attrs:{"cols":"12"}},[(_vm.tab === 1)?_c('div',{staticClass:"mt-2 d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-h6 mx-3"},[_vm._v("목록 (총 "+_vm._s(_vm.detailData.length)+"개)")]),_c('outlined-btn',{staticClass:"mr-3 progress-button",attrs:{"disabled":_vm.selectData.length < 1},on:{"click":function($event){return _vm.showPopup('progress')}}},[_vm._v(" 작업단계 변경 ")]),_c('outlined-btn',{staticClass:"product-button",attrs:{"disabled":_vm.selectData.length !== 1},on:{"click":function($event){return _vm.showPopup('product')}}},[_vm._v(" 상품명 변경 ")])],1),_c('div',{staticClass:"text-right"},[_c('outlined-btn',{staticClass:"px-5 excel-button",attrs:{"disabled":_vm.checkDataLength},on:{"click":function($event){return _vm.$emit('toExcel')}}},[_vm._v(" 엑셀다운로드 ")])],1)]):_vm._e(),(_vm.tab === 0)?_c('span',{staticClass:"text-h6 ml-2"},[_vm._v("목록 (총 "+_vm._s(_vm.totalDataLength)+"개)")]):_vm._e(),_c('v-flex',{attrs:{"xl12":"","lg12":"","md12":"","sm12":"","xs12":""}},[(_vm.tab === 0)?_c('v-data-table',{attrs:{"height":"50vh","headers":_vm.getHeader(_vm.totalHeaders),"items":_vm.styledItems(_vm.data),"loading":_vm.loading,"footer-props":{'items-per-page-options': [10, 30, 50, -1]},"items-per-page":-1,"fixed-header":"","calculate-widths":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"dblclick":function($event){return _vm.rowClickSearch(item)}}},_vm._l((_vm.totalHeaders),function(header){return _c('td',{key:header[1],style:(item.styles ? item.styles[header[1]] : '')},[(header[1] === 'stateColor')?[_c('div',{style:(_vm.getCircleStyle(item[header[1]]))})]:[_vm._v(" "+_vm._s(item[header[1]])+" ")]],2)}),0)]}}],null,false,3003574976)}):_vm._e(),(_vm.tab === 1)?_c('v-data-table',{attrs:{"height":"50vh","headers":_vm.getHeader(_vm.detailHeaders),"items":_vm.detailData,"loading":_vm.loading,"footer-props":{'items-per-page-options': [10, 30, 50, -1]},"items-per-page":-1,"fixed-header":"","calculate-widths":"","show-select":"","item-key":"index","single-select":_vm.isDataHistory},scopedSlots:_vm._u([{key:"item.prodType",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getChangeCdName("prodType", value)))])]}},{key:"item.sex",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getChangeCdName("sex", value)))])]}},{key:"item.appFromDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.appToDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.newDispEndDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.wkStateCd",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getChangeCdName("wkStateCd", value)))])]}},{key:"item.wkSubStateCd",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getChangeCdName("wkSubStateCd", item.wkSubStateCd))+" "),_c('v-icon',_vm._g({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.wkMsgCont),expression:"item.wkMsgCont",arg:"copy"}],on:{"click":function($event){_vm.snackbar = true}}},on),[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.wkMsgCont))])]),_c('v-snackbar',{attrs:{"top":'top',"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("메모 복사완료")])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateNoSecFormat(value)))])]}}],null,false,3955951046),model:{value:(_vm.selectData),callback:function ($$v) {_vm.selectData=$$v},expression:"selectData"}}):_vm._e()],1),_c('progress-stage-change',{attrs:{"isShow":_vm.isProgress,"wkStepList":_vm.wkStepList,"wkSubStepList":_vm.wkSubStepList,"wkSubStateList":_vm.wkSubStateList,"rowData":_vm.selectData},on:{"close":_vm.closePopup,"updateData":_vm.updateData}}),_c('product-name-change',{attrs:{"isShow":_vm.isProduct,"prProdLineList":_vm.prProdLineList,"insList":_vm.insList,"wkStepList":_vm.wkStepList,"wkSubStepList":_vm.wkSubStepList,"wkSubStateList":_vm.wkSubStateList,"useYnList":_vm.useYnList,"rowData":_vm.selectData},on:{"close":_vm.closePopup,"updateData":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }