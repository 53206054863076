<template>
  <v-col cols="12" class="py-0">
    <div style="width: 100%; text-align: right;">
      <outlined-btn class="mt-2 mr-3" @click="$emit('toExcel')" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>
    <v-divider class="my-2"/>
    <div class=" my-2">
      <div class="flex-column align-center">
        <div class="d-flex align-center">
          <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
          <span class="total-sumReward">지급건수 : {{ inputComma(totalSCnt) }}건</span>
          <span class="total-sumReward">조기지급액 : {{ inputComma(totalERewardAmt) }}원</span>
          <span class="total-sumReward">환수대상액 : {{ inputComma(totalHRewardAmt) }}원</span>
          <span class="total-sumReward">월정산액 : {{ inputComma(totalMRewardAmt) }}원</span>
          <span class="total-sumReward">지급액 : {{ inputComma(totalSRewardAmt) }}원</span>
          <div class="float-right ml-auto">
            <outlined-btn style="margin-right: 10px; background-color: #ffc400"
                          v-show="showButton"
                          :disabled="!hasSelectData"
                          @click="sendAlarmTalk">알림톡 발송
            </outlined-btn>
            <outlined-btn style="margin-right: 10px"
                          @click="bankInputRequest"
                          :disabled="!hasSelectData">계좌입력요청
            </outlined-btn>
          </div>
        </div>
        <v-col class="d-flex align-center col-xl-4 col-lg-6 col-md-6 col-6">
          <v-col cols="3">일괄 상태 업데이트</v-col>
          <v-select
              v-model="status" class="mr-2"
              :disabled="checkSuccessData" :items="REWARD_STATUS"
              background-color="white" menu-props="auto"
              hide-details outlined dense
          />
          <v-select
              v-model="remarks" class="mr-2" :items="REMARKS"
              :disabled="!hasSelectData || checkRemarksDisabled"
              background-color="white" menu-props="auto"
              hide-details outlined dense
          />
          <outlined-btn class="mr-2" @click="updateRow" :disabled="checkSaveBtn">선택건 저장</outlined-btn>
          <outlined-btn @click="showDetail" :disabled="checkShowDetail">상세보기</outlined-btn>
        </v-col>
      </div>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          v-model="selectData"
          item-key="index"
          :items="tableData"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths
          show-select multi-sort="multi-sort"
          :headers="getHeader(header)"
      >

        <template v-slot:item.hasSuccess="{ value }">
          {{ value ? '기존' : '신규' }}
        </template>

        <template v-slot:item.eCnt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.hcnt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.mcnt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.scnt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.erewardAmt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.hrewardAmt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.mrewardAmt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.srewardAmt="{ value }">
          <div class="tar">{{ inputComma(value) }}</div>
        </template>

        <template v-slot:item.telNum="{ value }">
          {{ getTelNum(value) }}
        </template>

        <template v-slot:item.ssno="{ value }">
          {{ value ? `${value.slice(0, 6)}-${value.slice(6)}` : '' }}
        </template>

        <template v-slot:item.rewardStatusValue="{ value, item }">
          <span :class="getColorRewardStatusValue(value)">{{ item["remark"] == 'ERROR_BLACK_LIST' ? '지급정지' : value }}</span>
        </template>

        <template v-slot:item.remark="{ value }">
          <span class="red--text">{{ searchByCodeInSelectList(REMARKS, value) }}</span>
        </template>

      </v-data-table>
    </v-flex>

    <new-payment-detail-popup
        :is-show="isShowDetail"
        :paramsData="paramsData"
        :searchData="selectData"
        @close="closePopup"
    />
  </v-col>
</template>

<script>
import _ from 'lodash';
import {NEW_PAYMENT_HEADER} from "@/util/TableHeaders"
import CodeMixin from "@/mixin/CodeMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import NewPaymentDetailPopup from "@/pages/Settlement/components/NewPaymentManage/Popup/NewPaymentDetailPopup.vue";

export default {
  components: {NewPaymentDetailPopup, OutlinedBtn},
  mixins: [CodeMixin],
  props: ["loading", "isFilter", "searchType", "tableData", "settlementDate", "REWARD_STATUS", "REMARKS", "statusData", "showButton", "isResetData", "paramsData"],
  computed: {
    hasTableData() {
      return this.tableData.length > 0;
    },
    hasSelectData() {
      return this.selectData.length > 0;
    },
    checkSuccessData() {
      return !this.hasSelectData || (this.searchType === "A");
    },
    checkRemarksDisabled() {
      return this.status !== "FAIL";
    },
    checkSaveBtn() {
      if (this.status === "TARGET" || this.status === "SUCCESS") return false;
      else if (this.status === "FAIL" && this.remarks !== "") return false;
      else return true;
    },
    checkShowDetail() {
      return this.selectData.length !== 1 || this.paramsData.payDate === "";
    }
  },
  watch: {
    searchType(value) {
      for (let i in this.header) {
        for (let j in this.header[i]) {
          if (this.header[i][j] === "payDate") {
            if (value === "A") {
              this.header[i][0] = "지급완료일";
            } else {
              this.header[i][0] = "지급예정일";
            }
          }
        }
      }
    },
    tableData: function (value) {
      if (_.isEmpty(value)) {
        this.status = this.remarks = "";
        this.selectData = [];
      }

      if (this.totalSCnt !== 0) {
        this.totalSCnt = 0;
      }
      if (this.totalERewardAmt !== 0) {
        this.totalERewardAmt = 0;
      }
      if (this.totalHRewardAmt !== 0) {
        this.totalHRewardAmt = 0;
      }
      if (this.totalMRewardAmt !== 0) {
        this.totalMRewardAmt = 0;
      }
      if (this.totalSRewardAmt !== 0) {
        this.totalSRewardAmt = 0;
      }

      for (let i in this.tableData) {
        this.totalSCnt += this.tableData[i].scnt;
        this.totalERewardAmt += this.tableData[i].erewardAmt;
        this.totalHRewardAmt += this.tableData[i].hrewardAmt;
        this.totalMRewardAmt += this.tableData[i].mrewardAmt;
        this.totalSRewardAmt += this.tableData[i].srewardAmt;
      }
    },
    status: function () {
      if (this.checkRemarksDisabled) {
        this.remarks = "";
      }
    },
    selectData: function () {
      if (!this.hasSelectData) this.status = this.remarks = "";
    }
  },
  data: () => ({
    header: NEW_PAYMENT_HEADER,
    commaList: ["sumReward"],
    status: "",
    remarks: "",
    selectData: [],
    totalSCnt: 0,
    totalERewardAmt: 0,
    totalHRewardAmt: 0,
    totalMRewardAmt: 0,
    totalSRewardAmt: 0,
    isShowDetail: false
  }),
  methods: {
    getHeader(headers) {
      return headers.map(header => ({
        text: header[0],
        value: header[1],
        align: 'center',
        class: this.commaList.includes(header[1]) ? 'tar headers text-no-wrap' : 'headers text-no-wrap',
        divider: true,
        fixed: true,
      }))
    },
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getTelNum(telNum) {
      if(!telNum) return "";
      return telNum.length === 11 ? `${telNum.slice(0, 3)}-${telNum.slice(3, 7)}-${telNum.slice(7)}` : telNum;
    },
    updateRow() {
      if (!confirm(`선택한 ${this.selectData.length}건에 대한 상태를 업데이트 하시겠습니까?`)) return;

      try {
        this.$emit("updateRow", this.selectData, this.status, this.remarks);
        this.selectData = [];
        this.status = this.remarks = "";
      } catch (e) {
        console.log(e);
      }
    },
    bankInputRequest() {
      if (!confirm(`선택한 ${this.selectData.length}건에 대해 계좌입력 요청 하시겠습니까?`)) return;

      try {
        this.$emit("bankInputRequest", this.selectData);
        this.selectData = [];
        this.status = this.remarks = "";
      } catch (e) {
        console.log(e);
      }
    },
    getColorRewardStatusValue(rewardStatusValue) {
      if (rewardStatusValue === "지급불가") {
        return "red--text bolder";
      } else if (rewardStatusValue === "지급완료") {
        return "green--text";
      }
    },
    sendAlarmTalk() {
      if (!confirm(`선택한 ${this.selectData.length}건에 대한 알림톡을 발송하시겠습니까?`)) return;

      try {
        this.$emit("sendAlarmTalk", this.selectData);
        this.selectData = [];
        this.status = this.remarks = "";
      } catch (e) {
        console.log(e);
      }
    },
    showDetail() {
      this.isShowDetail = true;
    },
    closePopup() {
      this.selectData = [];
      this.isShowDetail = false;
    }
  }
}
</script>

<style scoped>
.tar {
  text-align: right;
}
.total-sumReward {
  margin-left: 20px;
  font-weight: 600;
  font-size: 19px;
}
.bolder {
  font-weight: bold;
}
</style>