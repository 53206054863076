<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <field-input title="보험사">
          <v-select
              @click="selectCd"
              @change="getPrCd"
              :items="insurerCode"
              v-model="insCd"
              background-color="white"
              item-text="value"
              item-value="cd"
              hide-details outlined dense
          />
        </field-input>
        <v-col class="d-flex align-center col-xl-3 col-lg-5 col-md-6 col-12">
          <v-col cols="3">상품코드</v-col>
          <v-select
              @change="cvrCdList"
              @click="selectCd"
              :items="prCdList"
              v-model="prCd"
              background-color="white"
              item-text="text"
              item-value="prCd"
              hide-details outlined dense
          />
        </v-col>
      </search-area>
      <v-divider class="mb-2"/>
      <search-area>
        <field-input title="담보코드">
          <v-select
              @click="selectCd"
              :items="cvrList"
              v-model="cvrCd"
              background-color="white"
              item-text="text"
              item-value="cvrCd"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="담보검색명">
          <v-text-field
              v-model="srhTxt"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="대표담보여부">
          <v-checkbox
              v-model="cvrSeltRepYn"
              class="marginLf"
          ></v-checkbox>
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../layouts/SearchBarLayout";
import {axiosInstance} from "@/util/AxiosModule";
import FieldInput from "../../../components/Form/FieldInput";
import SearchArea from "../../../components/Search/SearchArea";
import SearchBtn from "../../../components/Search/SearchBtn";
export default {
  components: {SearchBtn, SearchArea, FieldInput, SearchBarLayout},
  data: () => ({
    insCd:"",
    prCd:"",
    planCd:"",
    srhTxt:"",
    cvrCd:'',
    prCdList:[],
    cvrList:[],
    cvrSeltRepYn: false,
    insurerCode:[
      {value: '선택', cd: ''}
    ]
  }),
  methods: {
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    getSearch() {
      let cvrSeltRepYn ="";
      if (this.cvrSeltRepYn === false) {
        cvrSeltRepYn = 'N'
      } else {
        cvrSeltRepYn = 'Y'
      }
      const params = {
        insCd: this.insCd,
        cvrCd: this.cvrCd,
        prCd: this.prCd,
        srhTxt: this.srhTxt,
        cvrSeltRepYn: cvrSeltRepYn
      }
      this.$emit('getSearch', params);
    },
    async getPrCd() {
      this.prCdList = [];
      const params = {
        insCd: this.insCd,
        srhType: "2"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prCd + ' (' + data[i].prNm + ')';
          objJson.prCd = data[i].prCd;
          this.prCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async cvrCdList() {
      this.cvrList = [];
      const params = {
        insCd: this.insCd,
        prCd: this.prCd,
        srhType: "4"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].cvrCd + ' (' + data[i].cvrNm + ')';
          objJson.cvrCd = data[i].cvrCd;
          this.cvrList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>