<template>
  <v-col class="d-flex flex-column" :class="inputClass">
    <v-col cols="4">{{ title }}</v-col>
    <slot />
  </v-col>
</template>

<script>
export default {
  props: ['title', 'inputClass']
}
</script>