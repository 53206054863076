<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="기준년월">
          <v-text-field
              v-model="settlementDate"
              outlined dense hide-details
              background-color="white"
          />
        </field-input>
        <field-input :title="searchType === 'A' ? '지급완료일' : '지급예정일'">
          <v-text-field
              v-model="payDate"
              outlined dense hide-details
              background-color="white"
          />
        </field-input>
        <field-input>
          <v-radio-group row v-model="searchType">
            <v-radio label="지급액" value="A" />
            <v-radio label="미지급액" value="B" />
            <v-radio label="렌카" value="C" />
          </v-radio-group>
        </field-input>
        <!-- 현재 미사용기능으로 비활성화 -->
        <!-- <v-col class="d-flex justify-center align-center"> -->
        <!-- <v-checkbox v-model="check" label="해당 기준연월 건만 보기" @change="$emit('check', check)" :disabled="checkboxDisabled" /> -->
        <!-- </v-col> -->
      </search-area>

      <v-divider class="mb-2" />

      <search-area>
        <field-input title="상태">
          <v-select
              :items="filteredStatusList" v-model="status"
              background-color="white" menu-props="auto"
              hide-details outlined dense
              @change="$emit('changeStatus', status)"
          />
        </field-input>
        <field-input title="회원명">
          <v-text-field
              v-model="nm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="주민등록번호">
          <v-text-field
              v-model="ssno"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="search" :disabled="isDisabled" />
    </template>
  </search-bar-layout>
</template>

<script>

import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBtn from "@/components/Search/SearchBtn";
import _ from "lodash";

export default {
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout},
  computed: {
    isDisabled() {
      const settlementDate = this.settlementDate;
      const payDate = this.payDate;

      // 정규 표현식을 이용한 날짜 형식 검증
      const isValidSettlementDate = settlementDate && settlementDate.length === 6 && /[0-9]{6}/.test(settlementDate) && this.monthCheck(settlementDate);
      const isValidPayDate = payDate && payDate.length === 8 && /[0-9]{8}/.test(payDate) && this.monthCheck(payDate);
      const isDoubleDate = !_.isEmpty(settlementDate) && !_.isEmpty(payDate); // 기준년월 및 지급예정일 동시 입력 체크

      return !(isValidSettlementDate || isValidPayDate) || isDoubleDate;
    },
    filteredStatusList() {
      return this.setRewardStatus();
    }
  },
  props: ["REWARD_STATUS"],
  data: () => ({
    settlementDate: "",
    payDate: "",
    status: "ALL",
    nm: "",
    ssno: "",
    check: false,
    searchType: "B",
  }),
  watch : {
    // 현재 미사용기능으로 비활성화
    // checkboxDisabled : function(value) {
    //   if(value) this.check = false;
    // },
    searchType(value) {
      this.$emit("searchType", value);
    }
  },
  methods: {
    // 기준연월 유효성 체크
    monthCheck(value) {
      if (value.length === 6) {
        // settlementDate의 경우: 연도(앞 4자리), 월(뒤 2자리)
        const month = +value.slice(4, 6);
        return month > 0 && month < 13;
      } else if (value.length === 8) {
        // payDate의 경우: 연도(앞 4자리), 월(중간 2자리), 일(뒤 2자리)
        const month = +value.slice(4, 6);
        const day = +value.slice(6, 8);
        return month > 0 && month < 13 && day > 0 && day <= 31;
      }
      return false;
    },
    search() {
      if (_.isEmpty(this.settlementDate || this.payDate)) {
        alert("기준년월 혹은 지급예정일/지급완료일을 입력해주세요.");
        return false;
      } else {
        const params = {
          searchType: this.searchType,
          settlementDate: this.settlementDate,
          payDate: this.payDate
        };
        const filters = {
          status: this.status,
          nm: this.nm,
          ssno: this.ssno
        };
        this.$emit("search", params, filters);
      }
    },
    initData() {
      this.settlementDate = "";
      this.payDate = "";
      this.searchType = "B";
      this.status = "ALL";
      this.$emit('initData');
    },
    setRewardStatus() {
      let statusList = this.REWARD_STATUS;
      if (this.searchType === "A") {
        this.status = "SUCCESS";
        return statusList.filter(item => item.value === "SUCCESS");
      } else if (this.searchType === "B") {
        this.status = "ALL";
        return statusList.filter(item => item.value !== "SUCCESS");
      }
      return statusList;
    }
  }
}
</script>

<style>
.todayDelete {
  margin-left: auto;
  color: red;
  text-decoration-line: underline;
  cursor: pointer;
}
</style>