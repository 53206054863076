<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <!-- 상품군리스트 -->
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="3">상품군</v-col>
          <v-col cols="6">
            <v-select
                @click="getPrProdLineCd"
                @change="changeType('prProdLineCd', prProdLineCd)"
                v-model="prProdLineCd"
                :items="prProdLineCdList"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                background-color="white"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <!-- 상품군리스트 -->

        <!-- 보험사리스트 -->
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="3">보험사</v-col>
          <v-col cols="7">
            <v-select
                @click="getInsCd"
                v-model="insCd"
                :items="insCdList"
                item-text="insCdNm"
                item-value="insCd"
                background-color="white"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <!-- 보험사리스트 -->

        <!-- 담보구분 -->
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="4">담보구분</v-col>
          <v-col cols="8">
            <v-select
                v-model="searchDiv"
                :items="searchDivList"
                item-text="searchDivNm"
                item-value="searchDiv"
                background-color="white"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <!-- 담보구분 -->

        <!-- 성별 -->
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="3">성별</v-col>
          <v-col cols="12">
            <v-radio-group v-model="sex" row>
              <v-radio label="전체" value="0"/>
              <v-radio label="남자" value="1"/>
              <v-radio label="여자" value="2"/>
            </v-radio-group>
          </v-col>
        </v-col>
        <!-- 성별 -->

        <!-- 보험나이 -->
        <v-col class="d-flex align-center col-xl-4 col-lg-6 col-md-8 col-12">
          <v-col style="font-weight: bold" cols="2">보험나이</v-col>
          <v-col cols="12">
            <v-row style="align-items: center">
              <v-col cols="2">시작나이</v-col>
              <v-col cols="2">
                <v-text-field
                    @change="changeAge('ageF')"
                    v-model="ageF"
                    background-color="white"
                    hide-details
                    outlined
                    dense
                />
              </v-col>
              <v-col cols="1" style="vertical-align: middle"><span>~</span></v-col>
              <v-col cols="2">끝나이</v-col>
              <v-col cols="2">
                <v-text-field
                    @change="changeAge('ageT')"
                    v-model="ageT"
                    background-color="white"
                    hide-details
                    outlined
                    dense
                />
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <!-- 보험나이 -->

        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-4 col-12">
          <v-col cols="4">나이간격</v-col>
          <v-col cols="4">
            <v-select
                v-model="ageS"
                :items="ageSList"
                item-text="ageSNm"
                item-value="ageS"
                background-color="white"
                hide-details
                outlined
                dense
            />
          </v-col>
        </v-col>

        <!-- 조회조건 팝업 -->
        <v-col class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
          <v-btn style="font-weight: bold" @click="showModal">조회조건</v-btn>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="auto">보험기간</v-col>
          <span
              style="background-color: white;
              text-align: center;
              border: 1px solid grey;
              line-height: 40px;
              border-radius: 6px;
              min-width: 10vw;
              min-height: 40px;">
            {{ saveData.insTrm }}</span>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="auto">납입기간</v-col>
          <span
              style="background-color: white;
              text-align: center;
              border: 1px solid grey;
              line-height: 40px;
              border-radius: 6px;
              min-width: 10vw;
              min-height: 40px;">
            {{ saveData.pyTrm }}</span>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="auto">갱신주기</v-col>
          <span
              style="background-color: white;
              text-align: center;
              border: 1px solid grey;
              line-height: 40px;
              border-radius: 6px;
              min-width: 10vw;
              min-height: 40px;">
            {{ saveData.rnwCycle }}</span>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="auto">상품타입</v-col>
          <span
              style="background-color: white;
              text-align: center;
              border: 1px solid grey;
              line-height: 40px;
              border-radius: 6px;
              min-width: 10vw;
              min-height: 40px;">
            {{ changedData(saveData.prodType, "1", "갱신형") }}
            {{ changedData(saveData.prodType, "2", "비갱신형") }}
            {{ changedData(saveData.prodType, "5", "무해지(0%,50%)") }}
            {{ changedData(saveData.prodType, "7", "연만기") }}
            {{ changedData(saveData.prodType, "A", "세만기_무해지형") }}
            {{ changedData(saveData.prodType, "C", "기본형") }}
            {{ changedData(saveData.prodType, "D", "체증형") }}
            {{ changedData(saveData.prodType, "E", "무해지(0%,50%)_325") }}
            {{ changedData(saveData.prodType, "F", "무해지(0%,50%)_335") }}
            {{ changedData(saveData.prodType, "H", "무해지(0%,50%)_355") }}
          </span>
        </v-col>
        <!-- 상품코드 -->
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col style="font-weight: bold" cols="auto">상품코드</v-col>
          <v-select
              @change="changePrCd(prCd)"
              v-model="prCd"
              :items="prList"
              item-text="prCd"
              item-value="prCd"
              dense hide-details outlined
              background-color="white"
              :disabled="prList.length < 2"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-8 col-md-5 col-12">
          <v-col style="font-weight: bold" cols="auto">상품명</v-col>
          <span
              style="background-color: white;
              text-align: center;
              border: 1px solid grey;
              line-height: 40px;
              border-radius: 6px;
              min-width: 94.5%;
              min-height: 40px;">
            {{ prNm }}
          </span>
        </v-col>
        <!-- 상품코드 -->
        <v-col class="d-flex align-center col-xl-8 col-lg-11 col-md-11 col-12">
          <v-col style="font-weight: bold" cols="auto">선택담보</v-col>
          <span
              style="background-color: white;
              border: 1px solid grey;
              line-height: 40px;
              border-radius: 6px;
              padding-left: 10px;
              min-width: 95%;
              min-height: 40px;">
            {{ fieldDataText }}</span>
        </v-col>
        <v-col class="col-12">
          <div class="float-right">
            <v-btn class="red" style="margin-right: 10px; font-weight: bold;" @click="deleteData">초기화</v-btn>
            <v-btn
                class="searchBtn green lighten-1"
                style="margin-right: 10px; font-weight: bold;"
                @click="getSearch"
            >조회</v-btn>
          </div>
        </v-col>
      </search-area>
      <product-cond-prem-modal
          :is-show="isShowModal"
          :condArrList="condArrList"
          :productInfo="productInfo"
          :deletedData="deletedData"
          @save="savedData"
          @field="fieldData"
          @close="close"
      />
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import store from "@/store";
import ProductCondPremModal from "@/pages/Product/components/ProductCondPrem/ProductCondPremModal";
import _ from "lodash";
import DateMixin from "@/mixin/DateMixin";
import TableMixin from "@/mixin/TableMixin";
import ProductRecommend from "@/util/ProductRecommend";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  mixins: [DateMixin, TableMixin],
  components: {ProductCondPremModal, SearchArea, SearchBarLayout},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      insCd: "",
      insCdList: [
        {insCdNm: "선택", insCd: ""},
      ],
      prCd: "",
      prNm: "",
      prList: [],
      searchDiv: "",
      searchDivList: [
        {searchDivNm: "선택", searchDiv: ""},
        {searchDivNm: "상품상세담보", searchDiv: "3"},
        {searchDivNm: "상품비교담보", searchDiv: "2"},
        {searchDivNm: "주요공통담보", searchDiv: "1"},
      ],
      ageF: 0,
      ageT: 0,
      ageS: 0,
      ageSList: [
        {ageSNm: "0", ageS: 0},
        {ageSNm: "1", ageS: 1},
        {ageSNm: "3", ageS: 3},
        {ageSNm: "5", ageS: 5}
      ],
      sex: "1",
      isShowModal: false,
      productInfo: {},
      condArrList: [],
      prCdData: [],
      cvrInfoList: [],
      date: new Date(),
      baseDt: "",
      birthday: "",
      saveData: {},
      fieldDataText: "",
      deletedData: false,
      memberId: store.getters["login/memberId"],
    }
  },
  watch: {
    insCd(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.saveData = {};
        this.prList = [];
        this.prNm = "";
        this.prCd = "";
        this.fieldDataText = "";
      }
    },
    sex(value) {
      for (let i in this.prList) {
        if ((value === "0") && (this.prList[i].sex !== "0")) {
          alert("해당 상품은 전체 성별에 대한 데이터가 없습니다.");
          return false;
        } else {
          if (value === this.prList[i].sex) {
            this.prNm = this.prList[i].prNm;
            this.prCd = this.prList[i].prCd;
          }
        }
      }
    }
  },
  methods: {
    changePrCd(code) {
      for (let i in this.prList) {
        if (code === this.prList[i].prCd) {
          this.prNm = this.prList[i].prNm;
        }
      }
    },
    changeAge(type) {
      if (type === "ageF") {
        this.ageT = this.ageF;
        this.ageS = 0;
      } else if (type === "ageT") {
        if (Number(this.ageF) > Number(this.ageT)) {
          alert("시작나이가 끝나이보다 많을 수는 없습니다.");
          return false;
        }
      }
    },
    changeType(type, data) {
      if (type === "prProdLineCd") {
        this.insCdList[0].insCdNm = "선택";
        this.insCdList[0].insCd = "";
        this.insCd = "";
        this.ageF = 0;
        this.ageT = 0;
        this.ageS = 0;
        this.saveData = "";
        this.prCd = "";
        this.prNm = "";
        this.fieldDataText = "";
      }
      this.$emit("prProdLineCd", data);
    },
    // 선택담보 보여주기
    fieldData(data) {
      this.fieldDataText = "";
      let text ="";
      for (let i in data) {
        text += data[i].condDetailNm + ": " + data[i].intCondNm + ", ";
      }
      this.fieldDataText = text.slice(0, text.length - 2);
    },
    // 상품군리스트 가져오기
    async getPrProdLineCd() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineCdList.shift();
    },
    // 보험사리스트 가져오기
    async getInsCd() {
      this.insCdList = await ProductRecommend.getInsCd();
      this.insCdList.shift();
    },
    // 상품군에 대한 데이터 가져오기
    async getPrProdLineCondArr() {
      this.productInfo = {
        prProdLineCd: "",
        prProdLineNm: "",
        creCvrAmtLst: [],
        insTrm: "",   pyTrm: "",
        rnwCycle: "", prodType: "",
      };
      if (this.prProdLineCd === "") {
        alert("상품군을 선택해주세요!");
        return false;
      }
      let data = await ProductRecommend.getPrProdLineCondArr(this.prProdLineCd);
      this.condArrList = data;

      for (let i in data.creCvrCdLst) {
        if (data.creCvrCdLst[i].creCvrLst.find(el => el.defaultYn === 'Y')) {
          let input = data.creCvrCdLst[i].creCvrLst.find(el => el.defaultYn === 'Y');
          this.productInfo.creCvrAmtLst.push({
            intCond: input.intCond,
            intCondNm: input.intCondNm,
            condDetail: input.condDetail,
            condDetailNm: input.condDetailNm,
          })
        }
      }
      this.productInfo.prProdLineCd = data.prProdLineCd;
      this.productInfo.prProdLineNm = data.prProdLineNm;
      this.productInfo.insTrm = this.setCondDetail(data.insTrmLst);
      this.productInfo.pyTrm = this.setCondDetail(data.pyTrmLst);
      this.productInfo.rnwCycle = this.setCondDetail(data.rnwCycleLst);
      this.productInfo.prodType = this.setCondDetail(data.prodTypeLst);

      this.productInfo.rnwCycle =
          this.productInfo.rnwCycle === "" ? "-" : this.productInfo.rnwCycle;
    },
    setCondDetail(list) {
      return _.isEmpty(list) ? "" : list.find(el => el.defaultYn === 'Y').condDetail;
    },
    savedData(data) {
      this.saveData = data;
      this.getPrList();
    },
    // 조회 파라미터
    getSearch() {
      let params = {};
      if ((Number(this.ageF) !== Number(this.ageT)) && (Number(this.ageS)) === 0) {
        alert("시작나이 와 끝나이가 다를때 나이간격은 '0'이 올 수 없습니다!");
        return false;
      } else if ((Number(this.ageF) === Number(this.ageT)) && Number(this.ageS) !== 0) {
        alert("시작나이 와 끝나이가 같다면 나이간격을 '0'으로 설정해주세요!");
        return false;
      }
      params = {
        memberId : this.memberId,
        searchDiv: this.searchDiv,
        prProdLineCd: this.prProdLineCd,
        insTrm: this.saveData.insTrm,
        pyTrm: this.saveData.pyTrm,
        rnwCycle: this.saveData.rnwCycle,
        prodType: this.saveData.prodType,
        sex: this.sex,
        ageF: Number(this.ageF),
        ageT: Number(this.ageT),
        ageS: this.ageS,
        callDiv: "2",
        insCdLst: [this.insCd],
        prCdLst: [this.prCd],
        creCvrAmtLst: [
          {
            "accAmt": 0,
            "accAmtNm": "",
            "creCvrCd": ""
          }
        ]
      }
      let excelData = {
        prProdLineCd: this.prProdLineCd,
        insCd: this.insCd,
      }
      this.$emit("getSearch", params, excelData);
    },
    // 조회조건 모달 열기
    showModal() {
      if (this.prProdLineCd === "" && this.insCd === "" && this.searchDiv === "" && this.age === 0) {
        alert("검색조건을 선택해주세요!");
      }else if (this.prProdLineCd === "") {
        alert("상품군을 선택해주세요!");
        // return false;
      } else if (this.insCd === "") {
        alert("보험사를 선택해주세요!");
        // return false;
      } else if (this.searchDiv === "") {
        alert("담보구분을 선택해주세요!");
        // return false;
      } else if (this.age === 0) {
        alert("보험나이를 입력해주세요!");
        // return false;
      } else {
        if (this.prProdLineCd !== this.saveData.prProdLineCd) {
          this.getPrProdLineCondArr();
        }
        this.isShowModal = true;
      }
    },
    // 상품리스트 가져오기
    async getPrList() {
      this.prList = [];
      this.prCd = "";
      this.prNm = "";
      const { data } = await axiosInstance.get("/public/prdata/prProdList", {
        params: {
          prProdCd: this.prProdLineCd,
          insCd: this.insCd,
          prNm: this.prNm,
          useYn: "Y"
        }
      });
      if (data.length < 1) {
        this.prList = [];
      }
      for (let i in data) {
        if ((data[i].sex !== "0") && (this.sex === "0")) {
          alert("해당 상품은 전체 성별에 대한 데이터가 없습니다.");
          return false;
        } else {
          this.prList.push({
            prNm: data[i].prNm,
            prCd: data[i].prCd,
            sex: data[i].sex,
            prodType: data[i].prodType
          });
        }
        if ((this.sex === "0") && (data[i].sex !== "0")) {
          alert("해당 상품은 전체 성별에 대한 데이터가 없습니다.");
          return false;
        } else {
          if (this.insCd === "N03") {
            if (this.sex === this.prList[i].sex) {
              this.prNm = this.prList[i].prNm;
              this.prCd = this.prList[i].prCd;
            }
          }
          if (this.saveData.prodType === this.prList[i].prodType) {
            this.prNm = this.prList[i].prNm;
            this.prCd = this.prList[i].prCd;
          }
        }
      }
      if (this.prCd === "") {
        alert("조회조건이 잘못되었습니다. 다시한번 확인해 주세요!");
      }
    },
    // 닫기
    close() {
      this.isShowModal = false;
    },
    // 초기화
    deleteData() {
      this.deletedData = true;
      this.prProdLineCd = "";
      this.prProdLineCdList[0].prProdLineNm = "선택";
      this.prProdLineCdList[0].prProdLineCd = "";
      this.insCd = "";
      this.insCdList[0].insCdNm = "선택";
      this.insCdList[0].insCd = "";
      this.prCd = "";
      this.prNm = "";
      this.searchDiv = "";
      this.searchDivList[0].searchDivNm = "선택";
      this.searchDivList[0].searchDiv = "";
      this.ageF = 0;
      this.ageT = 0;
      this.ageS = 0;
      this.sex = "1";
      this.cvrInfoList = [];
      this.condArrList = [];

      this.productInfo = {
        prProdLineCd: "",
        prProdLineNm: "",
        creCvrAmtLst: [],
        insTrm: "",   pyTrm: "",
        rnwCycle: "", prodType: "",
      };
      this.fieldDataText = "";
      this.saveData = "";
      this.$emit("deleteData", this.deletedData);
    }
  }
}
</script>