<template>
  <v-col cols="12" class="py-5 px-2">
    <div class="text-right" style="margin-bottom: 10px;">
      <span class="total-count">총 : {{ data.length }} 건</span>
      <v-btn
          color="red"
          class="white--text px-8"
          style="margin-right: 3px;"
          @click="save"
      >저장</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="70vh"
          item-key="idx"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          :headers="getHeader(headers)"
          fixed-header calculate-widths
          :footer-props="{'items-per-page-options': [10, 30, 50 , -1]}"
      >

        <!-- 사용여부 -->
        <template v-slot:item.useYn="{ item }">
          <v-select
              class="width-100"
              v-model="item.useYn"
              :items="useYnList"
              @change="changeUseYn(item.index)"
              dense outlined hide-details
              background-color="white"
          />
        </template>

        <!-- 최소금액 -->
        <template v-slot:item.minAmt="{ header, item, value }">
          <input
              v-if="item.cvrType === 'F'"
              disabled
              class="jc-fe" type="text"
              :value= inputComma(value) >
          <input
              v-else
              disabled
              class="jc-fe" type="text"
              :value= inputComma(value) >
        </template>

        <!-- 최대금액 -->
        <template v-slot:item.maxAmt="{ header, item, value }">
          <input
              v-if="item.cvrType === 'F'"
              disabled
              class="jc-fe" type="text"
              :value= inputComma(value) >
          <input
              v-else
              disabled
              class="jc-fe" type="text"
              :value= inputComma(value) >
        </template>

        <!-- 금액목록 -->
        <template v-slot:item.stepAmt="{ item }">
          <v-btn
              @click="getRowData(item.index)"
          >
            상세보기
          </v-btn>
        </template>

        <!-- 안내건수 -->
        <template v-slot:item.stepCnt="{ item }">
          <span>{{ item.stepCnt }}</span>
          <!-- <span :class="{'isRed': 10 < getStepCnt(item)}">{{ getStepCnt(item) }}</span> -->
        </template>

      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {PRODUCT_SP_CVR_AMT_TABLE} from "@/util/TableHeaders";
import DateMixin from "@/mixin/DateMixin";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: PRODUCT_SP_CVR_AMT_TABLE,
      selectData:[],
      useYnList: ['Y', 'N']
    }
  },
  methods: {
    async save() {
      this.$emit('save');
    },
    // changeRow(event, colNm, value, index) {
    //
    //   let newVal = event.target.value.replaceAll(/[^0-9]/g, "");
    //   if(newVal != value)
    //   {
    //     this.$emit("changeRow", colNm, newVal, index);
    //   }
    // },

    changeUseYn(index){
      let useYn = this.data[index-1].useYn;
      this.$emit("changeUseYn", index, useYn);
    },
    getRowData(index){
      this.$emit("getRowData", index);
    }
    /* 기존 안내개수 체크로직
    getStepCnt(item) {
      let stepCnt = 0;
      let minAmt = Number(item.minAmt);
      let maxAmt = Number(item.maxAmt);
      let stepAmt = Number(item.stepAmt);
      if(minAmt > maxAmt || (minAmt == 0 && maxAmt == 0))
      {
        return 0;
      }
      for (let amt = minAmt; amt <= maxAmt;) {
        stepCnt++;
        amt = amt + stepAmt;
        if(stepAmt <= 0)
        {
          return stepCnt;
        }
      }
      return stepCnt;
    },
    */
  }
}
</script>

<style>
.isRed, .isRed:hover {
  background-color: rgba(200, 0, 0, .5) !important;
}
.width-100{
  width: 100px !important;
  margin: 0 auto !important;
}
.total-count{
  float: left;
  margin-left: 8px;
  font-size: 22px;
  font-weight: 500;
}
</style>