<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-card  max-width="95vw" max-height="90vh" min-width="70vw" class="overflow-y-auto modalBackground">
      <v-card-title class="headline blue-grey--text">
        <span class="font-weight-bold">담보 엑셀매핑</span>
      </v-card-title>
      <v-divider :dark="true" :light="false"/>
      <v-col cols="12" class="py-0">
        <v-sheet>
          <search-area>
            <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
              <v-col cols="4">보험사</v-col>
              <v-select
                  @change="getTableId"
                  @click="selectCd"
                  v-model="searchInsurer"
                  :items="insurerCode"
                  background-color="white"
                  item-text="value"
                  item-value="cd"
                  hide-details outlined dense
                  />
            </v-col>
            <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
              <v-col cols="3">상품코드</v-col>
              <v-select
                  @change="cvrCdList"
                  :items="prCdList"
                  v-model="prCd"
                  label="선택"
                  background-color="white"
                  item-text="text"
                  item-value="prCd"
                  class="subBar"
                  hide-details outlined dense
              />
            </v-col>
            <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
              <v-col cols="4">플랜코드</v-col>
              <v-select
                  v-model="planCd"
                  :items="planCdList"
                  label="선택"
                  background-color="white"
                  item-text="text"
                  item-value="planCd"
                  class="subBar"
                  hide-details outlined dense
              />
            </v-col>
            <v-col cols="12">
              <div class="mb-2">
                <outlined-btn @click="toExcel" class="float-right" :disabled="!checkMapping.length > 0">선택건 엑셀다운로드</outlined-btn>
                <v-btn
                    class="table_sty green lighten-2 white--text float-right"
                    :disabled="isDisabled"
                    @click="clickUpload"
                >담보명엑셀업로드
                </v-btn>
                <input @input="loadData" :accept="SheetJSFT" class="d-none" id="excel" type="file" ref="file"/>
              </div>
            </v-col>
          </search-area>
        </v-sheet>
      </v-col>
      <v-data-table
          style="margin-top: 10px;"
          v-model="checkMapping" height="40vh" item-key="index"
          :items="mappingList" :item-class="getRed"
          :headers="headers" :options="option"
          dense fixed-header calculate-widths hide-default-footer
          show-select
        >
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-4"
          class="white--text col-2"
          @click="close">
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import SearchArea from "../../../components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModule";
import XLSX from "xlsx";
import func from "@/util/func";
import _ from "lodash";
import OutlinedBtn from "../../Settlement/components/PaymentManage/outlinedBtn";
export default {
  components: {OutlinedBtn, SearchArea},
  props: ['isShow'],
  data: () => ({
    searchInsurer:"",
    planCd:'',
    prCd:'',
    planCdList:[],
    prCdList: [],
    checkMapping:[],
    mappingList:[],
    SheetJSFT: ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"].map(x => "." + x).join(","),
    insurerCode:[
      {value: '선택', cd: ''}
    ],
    option: {
      sortBy: ['isRed'],
      sortDesc: [true],
      itemsPerPage: -1,
      multiSort: true,
    },
    headers: [
      {text: "보험사", value: "insCd", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "입력담보명", value: "icvrNm", class: "teal lighten-4", align: "center", sortable: false, width: "6vw"},
      {text: "담보코드", value: "cvrCd", class: "teal lighten-4", align: "center", sortable: false, width: "5vw"},
      {text: "담보명", value: "cvrNm", class: "lime lighten-4", align: "center", sortable: false, width: "8vw"},
    ],
  }),
  computed: {
    isDisabled() {
      return !this.searchInsurer.length > 0;
    }
  },
  methods: {
    close() {
      this.searchInsurer = "";
      this.mappingList = [];
      this.checkMapping = [];
      this.$emit('close');
    },
    async getTableId() {
      this.planCdList = [];
      this.prCdList = [];
      const params = {
        insCd: this.searchInsurer,
        srhType: "2"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prCd + ' (' + data[i].prNm + ')';
          objJson.prCd = data[i].prCd;
          this.prCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    getRed(item) {
      return item.isRed ? 'isRed' : '';
    },
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    async cvrCdList() {
      this.cvrList = [];
      const params = {
        insCd: this.searchInsurer,
        prCd: this.prCd,
        srhType: "3"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].planCd + ' (' + data[i].planNm + ')';
          objJson.planCd = data[i].planCd;
          this.planCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 업로드 버튼 클릭시 input file
    clickUpload() {
      if (!this.isDisabled) this.$refs.file.click();
    },
    loadData({target: {files}}) {
      if (!files || files.length < 1) alert("올바른 파일이 아닙니다.");
      else this._setReader().readAsBinaryString(files[0]);
    },
    async upload(data) {
      let prProdCvrNmList = new Array();
      for (let i = 0; i < data.length; i++) {
        prProdCvrNmList = prProdCvrNmList.concat(data[i]);
      }
      try {
        const { data } = await axiosInstance.post('/admin/prdata/prProdCvrMapping', prProdCvrNmList, {
          params: {
            insCd: this.searchInsurer,
            prCd: this.prCd,
            planCd: this.planCd
          }
        })
        this.mappingList = data;
        //인덱스 값 추가
        for (let i = 0; i < this.mappingList.length; i++) {
          this.mappingList[i].index = i;
        }
        this.getRedRow(this.mappingList);
      }catch (e) {
        console.log(e)
      }
    },
    _setReader() {
      const reader = new FileReader();
      reader.onload = ({target: {result}}) => {
        const {SheetNames, Sheets} = XLSX.read(result, {type: 'binary'});

        const data = XLSX.utils.sheet_to_json(
            Sheets[SheetNames[0]], {header: 1, blankrows: false, rawNumbers: true, defval: ""})
        // 엑셀 파일 변환 후 해당 메소드 실행
        this.upload(data);
      };
      return reader;
    },
    getRedRow(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].mappOkYn === 'N'){
          data[i].isRed = true;
        } else {
          data[i].isRed = false;
        }
      }
      return data;
    },
    toExcel() {
      let day = new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '');
      day = day.replaceAll(':', '_');
      if (_.isEmpty(this.checkMapping)) return;
      const data = _.cloneDeep(this.checkMapping);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "보험사": item["insCd"],
          "입력담보명": item["icvrNm"],
          "담보코드": item["cvrCd"],
          "담보명": item["cvrNm"],
          "매핑여부": item["mappOkYn"]
        }
        returnData.push(obj);
      }
      const sheetName = `담보 엑셀매핑_` +day;
      const fileName = `담보 엑셀매핑`+ day;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>
<style scoped>
.table_sty {
  margin-left: 10px;
  margin-right: 10px;
}
</style>