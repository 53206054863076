var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-5",attrs:{"cols":"12"}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"30vh","headers":_vm.getHeader(_vm.headers),"items":_vm.data,"loading":_vm.loading,"items-per-page":-1},on:{"click:row":_vm.getRowData},scopedSlots:_vm._u([{key:"item.priQuestion",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.textLengthOverCut(value, 30, "...")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.stDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateDashFormat(value)))])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getKST(value)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }