<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="회원명">
          <v-text-field
              v-model="memberNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="생년월일(8자리)">
          <v-text-field
              v-model="birthday"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              placeholder="ex) 19900101"
              inputmode="numeric" maxlength="8"
          />
        </field-input>
        <field-input title="성별">
          <v-radio-group
              v-model="sex"
              row
          >
            <v-radio label="남" value="M"/>
            <v-radio label="여" value="F"/>
          </v-radio-group>
        </field-input>
        <field-input title="휴대폰번호">
          <v-text-field
              v-model="telNum"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              inputmode="numeric" maxlength="11"
              placeholder="'-' 없이 입력"
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn
          :disabled="isDisabled"
          @click="checkValidParams"
      />
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [SearchBarMixin, CodeMixin],
  components: {
    SearchArea,
    SearchBarLayout,
    FieldInput,
  },
  async created() {
  },
  computed: {
    isDisabled() {
      return (
          !this.memberNm || !this.telNum || !this.birthday || !this.sex
      );
    },
  },
  data: () => ({
    memberNm: "",
    telNum: "",
    birthday: "",
    sex: "M"
  }),
  methods: {
    checkValidParams(){
      if (this.telNum.includes('-') || this.telNum.includes(' ')) {
        alert("'-'이나 공백 없이 입력해주세요.")
        return false;
      }
      if(this.birthday.length !== 8){
        alert("생년월일은 8자리로 입력해주세요.")
        return false;
      }
      if (this.birthday.substring(0, 2) !== "19" && this.birthday.substring(0, 2) !== "20") {
        alert("유효하지 않은 생년월일")
        return false;
      }
      if(this.telNum.length < 10){
        alert("전화번호를 정확히 입력해주세요.")
        return false;
      }
      this.getIncarMemberCheck();
    },

    getIncarMemberCheck() {
      let param = {};
      param['memberNm'] = this.memberNm;
      param['telNum'] = this.telNum;

      let birthCentury = this.birthday.substring(0, 2);
      let birthdayParam = this.birthday.substring(2);
      let sexParam = "";
      if (birthCentury === "19") {
        sexParam = this.sex === "M" ? "1" : "2";
      } else {
        sexParam = this.sex === "M" ? "3" : "4";
      }
      param['ssno_7'] = birthdayParam + sexParam;

      this.$emit('search', param);
    },
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>