<template>
  <search-page-layout title="렌카정산 등록">
    <template v-slot:searchBar>
      <rencar-settlement-search-bar
          @search="search"
      />
    </template>

    <template v-slot:table>
      <rencar-settlement-table
          :tableData="tableData"
      />

    </template>

  </search-page-layout>

</template>


<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import RencarSettlementTable from "@/pages/Settlement/components/RencarSettlement/RencarSettlementTable";
import RencarSettlementSearchBar from "@/pages/Settlement/components/RencarSettlement/RencarSettlementSearchBar";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {RencarSettlementTable, RencarSettlementSearchBar, SearchPageLayout},
  data: () => ({
    tableData:[]
  }),
  methods: {
    async search(date) {
      const {data} = await axiosInstance.get('/admin/rencarSettlement/search', {
        params: {
          standardYm: date
        }
      });
      this.tableData = data;
    }

  }
}
</script>