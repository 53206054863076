<template>
  <!-- by sk.jeon -->
  <div>
    <search-area>
      <v-col cols="11"  align-self='center' >
        <date-month-bar @search="dateEvent"/>
      </v-col>
      <v-col  cols="1"  align-self='center'>
        <v-btn
            elevation="2"
            @click="toExcel">엑셀다운로드
        </v-btn>
      </v-col>
    </search-area>
    <v-data-table
        :items-per-page="countPage"
        :headers="headers"
        :loading="loading"
        :items="data"
    >
      <template v-slot:item.greenlight_yes="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TableMixin from "@/mixin/TableMixin";
import DateMonthBar from "@/pages/Stat/components/PerformanceStat/DateMonthBar";
import { axiosInstance } from "@/util/AxiosModule";
import SearchArea from "@/components/Search/SearchArea";
import _ from "lodash";
import func from "@/util/func";

export default {
  mixins: [TableMixin],
  components: {
    DateMonthBar, SearchArea
  },
  data: () => ({
    headers: [
      { text: "날짜", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "15vw" },
      { text: "합계", value: "totalInflow", class: "lime lighten-4", align: "center", sortable: false },
      { text: "인카", value: "incarCnt", class: "indigo lighten-3", align: "center", sortable: false },
      { text: "원클릭", value: "oneCnt", class: "red lighten-3", align: "center", sortable: false },
      { text: "청신호", value: "greenCnt", class: "green lighten-2", align: "center", sortable: false },
      { text: "페이스북", value: "fbCnt", class: "blue lighten-2", align: "center", sortable: false },
    ],
    date: new Date().toISOString().substr(0, 7),
    service: "greenlight",
    data: [],
    loading: false,
    countPage: 32
  }),
  created() {
    this.dateEvent(this.date);
  },
  methods: {
    async dateEvent(date) {
      this.date = date

      this.loading = true;

      let axiosData;

      let params = {"yearMonth": this.date}

      try {
        axiosData = await axiosInstance.get('/admin/operation/advertisement-inflow-status', {params})

      } catch (e) {
        console.log(e)
      }
      const {data} = axiosData;
      let allData = {}
      for (var index in data) {
        for (var key in data[index]) {
          if (key === "createDate") {
            allData[key] = "합계"
          } else {
            if (allData[key] == null) {
              allData[key] = data[index][key]
            } else {
              allData[key] += data[index][key]
            }
          }
        }
      }
      data.unshift(allData);
      this.data = data
      this.loading = false;
    },
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "날짜": item["createDate"],
          "합계": item["sum"],
          "인카": item["incar"],
          "원클릭": item["oneclick"],
          "청신호": item["greenlight"],
          "페이스북": item["facebook"],
        }
        returnData.push(obj);
      }

      const sheetName = `유입현황(회원유입처)`;
      const fileName = `유입현황(회원유입처)`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
};
</script>


<style>
.v-data-table td {
  border-color: rgba(1, 0, 0, 0.12);;
}
</style>