<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6">목록 (총 {{ data.length }}개)</span>
      <outlined-btn class="float-right" @click="saveExcel" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="headers"
          :loading="loading"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import func from "@/util/func";
import _ from "lodash";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";

export default {
  components: {OutlinedBtn},
  props:["data", "loading", "searchList"],
  data:() => ({
    headers: [{text: "회원명", value: "memberNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "등록회원명", value: "regMemberNm", class: "teal lighten-4", align: "center", sortable: false},
      {text: "전화번호", value: "telNum", class: "teal lighten-4", align: "center", sortable: false},
      {text: "사이트", value: "site", class: "teal lighten-4", align: "center", sortable: false},
      {text: "인카소속여부", value: "incarYn", class: "teal lighten-4", align: "center", sortable: false},
      {text: "등록일자", value: "createDt", class: "teal lighten-4", align: "center", sortable: false},
    ],
  }),
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  methods: {
    toExcel() {
      const dataList = _.cloneDeep(this.data)
      const returnData = [];
      let day = new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '');
      day = day.replaceAll(':', '_');
      for (const item of dataList) {
        const obj = {
          "등록회원명": item["regMemberNm"],
          "전화번호": item["telNum"],
        }
        returnData.push(obj);
      }
      const sheetName = `01.목록등록_` + day;
      const fileName = `01.목록등록` + day;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    async saveExcel() {
      const searchList = this.searchList;
      const custExtractTbList = this.data;
      try {
        await axiosInstance.post('/admin/operation/custExtract', custExtractTbList, {
          params: {
            wkDiv: "1",
            regId: store.getters['login/memberId'],
            baseDt: searchList.toCreateDt
          }
        })
        this.toExcel();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>