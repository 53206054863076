<template>
  <v-col cols="12" class="py-0">
    <div class="mb-2">
      <span class="text-h6" v-if="cunt == 0">목록 (총 {{ 0 }}개)</span>
      <span class="text-h6" v-else>목록 (총 {{ cunt }}개)</span>
      <outlined-btn class="float-right" @click="$emit('toExcel')" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <div style="width:99%; overflow:auto; margin-bottom: 55px">
        <table v-show="tableData.length >0">
          <thead>
          <tr>
            <th rowspan="2" colspan="2" class="fixed">
              <div class="fixed2">구분</div></th>
            <th v-for="index in cunt" :key="index" colspan="3">
              <div v-if="stMonth + (index - 1) < 13" class="tableSi">{{stMonth + (index - 1)}} 월</div>
              <div v-if="stMonth + (index - 1) > 12" class="tableSi">{{ stMonth + (index - 13) }} 월</div>
            </th>
          </tr>
          <tr>
            <th v-for="(item, index) in titleArray" :key="index" width=110>
              {{item.text}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th rowspan="11" class="fixed" @click="getChartDiv('cm')">CM</th>
          </tr>
          <tr v-for="item in cmArry" :key="item.nm.index">
            <th class="fixed1" @click="getChartDiv('cm')">{{item.nm}}</th>
            <td v-for="(val, index) in item.value" :key="index" class="rigAl" :class="{pitColor: subText(item)}" v-on:mouseover="mouseover(index, item.value)">
              <div>{{val}}</div>
              <div class="arrow_box" v-if="index % 3 === 1">평균: {{average}}원</div>
            </td>
          </tr>
          <tr>
            <th rowspan="11" class="fixed" @click="getChartDiv('tm')">TM</th>
          </tr>
          <tr v-for="item in tmArry" :key="item.nm.index">
            <th class="fixed1" @click="getChartDiv('tm')">{{item.nm}}</th>
            <td v-for="(val, index) in item.value" :key="index" class="rigAl" :class="{pitColor: subText(item)}" v-on:mouseover="mouseover(index, item.value)">
              <div>{{val}}</div>
              <div class="arrow_box" v-if="index % 3 === 1">평균: {{average}}원</div>
            </td>
          </tr>
          <tr>
            <th rowspan="11" class="fixed" @click="getChartDiv('total')">합계</th>
          </tr>
          <tr v-for="item in totArry" :key="item.nm.index">
            <th class="fixed1" @click="getChartDiv('total')">{{item.nm}}</th>
            <td v-for="(val, index) in item.value" :key="index" class="rigAl"  :class="{YlwColor: subText(item)}" v-on:mouseover="mouseover(index, item.value)">
              <div>{{val}}</div>
              <div class="arrow_box" v-if="index % 3 === 1">평균: {{average}}원</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <car-sales-list-chart
          :total="totalLog"
          @close="totalLog = false"
          @updateSetting="setDialog"
          :stDt="fromYm"
          :edDt="edDt"
          :monthArray="monthArray"
          :insuredNm="insuredName"
          :totArry="chartArry"
          :dataTy="dataTy"
      />
    </v-flex>
  </v-col>
</template>
<script>
import TableMixin from "@/mixin/TableMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import CarSalesListChart from "./CarSalesListChart";

export default {
  mixins: [TableMixin, CodeMixin, DateMixin],
  components: {CarSalesListChart, OutlinedBtn},
  props:["tableData", "diff", "stDt", "edDt", "fromYm"],
  data: () => ({
    title: ["건수", "보험료", "광고비"],
    titleArray: [],
    insuredName: [],
    insuredNm: [
      {
        nm: "현대",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "DB",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "KB",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "흥국",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "한화",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "삼성",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "하나",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "AXA",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "메리츠",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      },
      {
        nm: "소계",
        value: ["totContCnt", "totContPremAmt", "totContFee"]
      }
    ],
    insuredArray:[],
    cmArry: [],
    tmArry: [],
    totArry: [],
    totalLog: false,
    monthArray:[],
    cunt: 0,
    chartArry:[],
    dataTy: '',
    average: 0,
    stMonth:0
  }),
  computed: {
    hasTableData() {
      return this.diff > 0;
    }
  },
  watch: {
    tableData: function (obj) {
      this.cunt = 0;
      this.cmArry = [];
      this.tmArry = [];
      this.totArry = [];
      this.tableData = obj;
      this.titleArray = [];

      if (Number(this.tableData[0].standardYm.substring(4, 5)) === 0) {
        this.stMonth = Number(this.tableData[0].standardYm.substring(5, 6));
      } else {
        this.stMonth = Number(this.tableData[0].standardYm.substring(4, 6));
      }

      for (let i = 0; i < obj.length; i++) {
        if(this.tableData[i].joinChannel == "CM"){
          this.cmArry.push(this.tableData[i]);
        }else if(this.tableData[i].joinChannel == "TM"){
          this.tmArry.push(this.tableData[i]);
        }else{
          this.totArry.push(this.tableData[i]);
        }
      }

      let cmJsonArray = new Array();
      let insured_v = this.insuredNm;

      //cm 리스트 담기
      this.cmArry.forEach(function (item){
        insured_v.forEach(function(target){
          let objJson = new Object();
          let objArray = new Array()
          if (target.nm === item.contractInsurerNm){
            target.value.forEach(function(val){
              objArray.push(item[val]);
            })
          }else {
            return;
          }
          objJson.nm = target.nm
          objJson.value= objArray;
          cmJsonArray.push(objJson);
        })
      })
      //같은 이름 배열 합치기
      let cmTArray = [];
      for (let i = 0; i < cmJsonArray.length; i++) {
        var idx = this.getKeyIndex(cmTArray, cmJsonArray[i]);
        if(idx > -1) {
          for (let j = 0; j < cmJsonArray[i].value.length; j++) {
            cmTArray[idx].value.push(cmJsonArray[i].value[j]);
          }
        }else {
          cmTArray.push(cmJsonArray[i]);
        }
      }
      this.cmArry = cmTArray
      let tmJsonArray = new Array();

      //tm 리스트 담기
      this.tmArry.forEach(function (item){
        insured_v.forEach(function(target){
          let objJson = new Object();
          let objArray = new Array();
          if (target.nm === item.contractInsurerNm){
            target.value.forEach(function(val){
              objArray.push(item[val]);
            })
          }else {
            return;
          }
          objJson.nm = target.nm
          objJson.value= objArray;
          tmJsonArray.push(objJson);
        })
      })
      //같은 이름 배열 합치기
      let tmTArray = [];
      for (let i = 0; i < tmJsonArray.length; i++) {
        var tmIdx = this.getKeyIndex(tmTArray, tmJsonArray[i]);
        if(tmIdx > -1) {
          for (let j = 0; j < tmJsonArray[i].value.length; j++) {
            tmTArray[tmIdx].value.push(tmJsonArray[i].value[j]);
          }
        }else {
          tmTArray.push(tmJsonArray[i]);
        }
      }
      this.tmArry = tmTArray


      let totJsonArray = new Array();

      //total 리스트 담기
      this.totArry.forEach(function (item){
        insured_v.forEach(function(target){
          let objJson = new Object();
          let objArray = new Array();
          if (target.nm === item.contractInsurerNm){
            target.value.forEach(function(val){
              objArray.push(item[val]);
            })
          }else {
            return;
          }
          objJson.nm = target.nm
          objJson.value= objArray;
          totJsonArray.push(objJson);
        })
      })
      //같은 이름 배열 합치기
      let totTArray = [];
      for (let i = 0; i < totJsonArray.length; i++) {
        var totIdx = this.getKeyIndex(totTArray, totJsonArray[i]);
        if(totIdx > -1) {
          for (let j = 0; j < totJsonArray[i].value.length; j++) {
            totTArray[totIdx].value.push(totJsonArray[i].value[j]);
          }
        }else {
          totTArray.push(totJsonArray[i]);
        }
      }
      this.totArry = totTArray
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].joinChannel === "CM" && this.tableData[i].contractInsurerNm === "현대" ){
          this.monthArray.push(this.tableData[i].standardYm);
          this.cunt++;
        }
      }

      //header 갯수 구하기
      for (let i = 0; i < this.cunt; i++) {
        for (let j = 0; j < 3; j++) {
          let object = new Object();
          object.text = this.title[j];
          this.titleArray.push(object);
        }
      }
      //금액 표시
      for (let i = 0; i < this.cmArry.length; i++) {
        for (let j = 0; j < this.cmArry[i].value.length; j++) {
          this.cmArry[i].value[j] = this.inputComma(this.cmArry[i].value[j]);
          this.tmArry[i].value[j] = this.inputComma(this.tmArry[i].value[j]);
          this.totArry[i].value[j] = this.inputComma(this.totArry[i].value[j]);
        }
      }
    }
  },
  methods : {
    inputComma(num) {
      return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    subText(val) {
      return val.nm === '소계'
    },
    getKeyIndex(arr, obj) {
      // 중복 체크
      for(var i = 0; i < arr.length; i++){
        if(arr[i].nm === obj.nm){
          return i;
        }
      }
      return -1;
    },
    getCmChart() {
      this.cmLog = true;
    },
    getTmChart() {
      this.tmLog = true;
    },
    getChartDiv(data){
      this.chartArry = [];
      this.dataTy = data;
      this.insuredName = [];

      //보험사 별 리스트
      if (data === "cm"){
        this.chartArry = this.cmArry
      } else if(data === "tm") {
        this.chartArry = this.tmArry
      } else {
        this.chartArry = this.totArry
      }

      //보험사 이름 리스트
      for (let i = 0; i < this.insuredNm.length; i++) {
        this.insuredName.push(this.insuredNm[i].nm);
      }

      this.getChart();
    },
    getChart() {
      this.totalLog = true;
    },
    setDialog(value) {
      this.totalLog = value;
    },
    mouseover(idx, val) {
      let divCnt = 0;
      let divReward = 0;

      if (idx % 3 != 0){
        //광고비 평균 계산
        if (idx % 3 ==1 ){
          if (val[idx -1] != 0) {
            divCnt = Number(val[idx -1].replaceAll(',', ''))
          }
          if (val[idx] != 0) {
            divReward = Number(val[idx].replaceAll(',', ''))
            this.average = this.inputComma(parseInt(divReward / divCnt));
          } else {
            this.average = 0;
          }
        }
      }
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
}
tr, th, td {
  border: 0.1px solid #444444;
}
.tableSi {
  width: 330px;
  padding: 4px;
}
.rigAl {
  text-align: right;
  padding:2px;
  padding-right: 4px;
  position: relative;
  z-index: 1;
}
.pitColor {
  background-color: #EBFBFF;
  font-weight: bold;
}
.YlwColor {
  background-color: #FAFAA0;
  font-weight: bold;
}
.fixed {
  position: sticky;
  left: 0px;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 2;
}
.fixed1 {
  position: sticky;
  left: 36px;
  width: 60px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
  padding-left: 5px;
  z-index: 2;
}
.fixed2 {
  width: 90px;
}
.arrow_box {
  display: none;
  position: relative;
  width: 110px;
  padding: 1px;
  left: 3px;
  background-position: top;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #FFD700;
  color: #444444;
  font-weight: bold;
  font-size: 13px;
  z-index: 20;
}
div:hover + div.arrow_box {
  display: block;
  z-index: 20;
}
</style>
