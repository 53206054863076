<template>
<!-- by sk.jeon -->
  <div>
    <search-area>
      <v-col cols="11"  align-self='center' >
        <date-month-bar @search="dateEvent"/>
      </v-col>
      <v-col  cols="1"  align-self='center'>   
        <v-btn 
          elevation="2"
          @click="makeExcelFile">엑셀 저장
        </v-btn>
      </v-col>
    </search-area>
    <v-data-table :items-per-page="countPage" :headers="headers" :loading="loading" :items="data">
      <template v-slot:item.greenlight_yes="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.greenlight_no="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.greenlight="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.axa="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.cr="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.db="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hd="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hk="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hn="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.hw="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
      <template v-slot:item.kb="{ value }">
        <div>{{ inputComma(value) }}</div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TableMixin from "@/mixin/TableMixin";
import DateMonthBar from "@/pages/Stat/components/PerformanceStat/DateMonthBar";
import { axiosInstance } from "@/util/AxiosModule";
import Xlsx from "xlsx";
import SearchArea from "@/components/Search/SearchArea";

export default {
  mixins: [TableMixin],
  components: {
    DateMonthBar, SearchArea
  },
  data: () => ({
      headers: [{ text: "날짜(일)", value: "create_date", class: "teal lighten-4", align: "center", sortable: false }, 
                { text: "추천인O 가입", value: "greenlight_yes", class: "indigo lighten-4", align: "center", sortable: false },
                { text: "추천인X 가입", value: "greenlight_no", class: "card-panel indigo lighten-4", align: "center", sortable: false },
                { text: "비교견적", value: "greenlight", class: "light-blue lighten-4", align: "center", sortable: false },
                { text: "AXA손해보험", value: "axa", class: "lime lighten-4", align: "center", sortable: false },
                { text: "캐롯손해보험", value: "cr", class: "lime lighten-4", align: "center", sortable: false },
                { text: "DB손해보험", value: "db", class: "lime lighten-4", align: "center", sortable: false },
                { text: "현대해상", value: "hd", class: "lime lighten-4", align: "center", sortable: false },
                { text: "흥국화재", value: "hk", class: "lime lighten-4", align: "center", sortable: false },
                { text: "하나손해보험", value: "hn", class: "lime lighten-4", align: "center", sortable: false },
                { text: "한화손해보험", value: "hw", class: "lime lighten-4", align: "center", sortable: false },
                { text: "KB손해보험", value: "kb", class: "lime lighten-4", align: "center", sortable: false },
                ],
      date: new Date().toISOString().substr(0, 7),
      service: "greenlight",
      data: [],
      loading: false,
      countPage: 32
  }),
  async created() {
    await this.dateEvent(this.date);
  },
  methods: {
    async dateEvent(date) {
      this.date=date

      this.loading = true;

      let axiosData;

      let params = {"yearMonth" : this.date}

      try{
        axiosData = await axiosInstance.get('/admin/operation/performance-greenlight', {params})

      } catch(e) {
        console.log(e)
      }
      const { data } = axiosData;
      let allData = {}
      for( var index in data) {
        for( var key in data[index]) {
          if (key == "create_date") {
            allData[key] = "합계"
          }else {
            if (allData[key] == null) {
              allData[key] = data[index][key]
            } else {
              allData[key] += data[index][key]
            }
          }
        }
      }
      data.unshift(allData);
      this.data = data
      this.loading = false;

    },
     makeExcelFile () {
      const workBook = Xlsx.utils.book_new()
      let export_list = []
      for (var index in this.data) {
        let item = {}
        for (var key in this.data[index]) {
          for (var header_index in this.headers) {
            if (this.headers[header_index]["value"] == key) {
              item[this.headers[header_index]["text"]] = this.data[index][key]
              break;
            }
          }
        }
        export_list.push(item)
      }
      const workSheet = Xlsx.utils.json_to_sheet(export_list)
      Xlsx.utils.book_append_sheet(workBook, workSheet, `청신호_${this.date}`)
      Xlsx.writeFile(workBook, `청신호${this.date}.xlsx`)
    }
  }

};
</script>


<style>
.v-data-table td {
    border-color: rgba(1, 0, 0, 0.12);;
}
</style>