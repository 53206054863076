<template>
     
    <v-row>
      
      <v-col
        v-for="(data, inx) in contents"
        :key="inx"
        class="d-flex child-flex"
        cols="2"
      >
        <v-checkbox :true-value="{seq: data.contFileInfoSeq, check: true}" :false-value="{seq: data.contFileInfoSeq, check: false}" @change="changeCheckbox">
        </v-checkbox>
        <v-card  width="100">
          
          <v-img
            height="100px"
            :src="data.thumbnailContFileSaveRoot"
            @click="clickContents(data)"
          >
          
          </v-img>
          
          <v-card-text>

            <div class="font-weight-bold ml-1">
              Name : {{data.contFileNm}}
            </div>

            <div class="font-weight-bold ml-1">
              create : {{data.createDate}}
            </div>
          </v-card-text>
        
        </v-card>
        
        
          
      </v-col>

      <contents-detail-dialog
          :dialog="dialog"
          :data="contentsData"
          @close="dialog = false"
          @updateSetting="setDialog"
          @update="putContentsAPI"
        >
      </contents-detail-dialog>

    </v-row>
</template>

<script>
import contentsDetailDialog from "@/pages/Manage/ContentsFile/components/contentsDetailDialog"
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props:["contents", "deletes"],
  components: {contentsDetailDialog},

  data: () =>({
    deleteList: [],
    dialog: false,
    contentsData : {}
    }
  ),
  methods: {
    
    changeCheckbox(params) {
      this.$emit('deletelist', params)
    },
    setDialog (value) { 
			this.dialog = value
		},
    async clickContents(data) {

      await this.getContentsAPI(data.contFileInfoSeq)
      this.dialog = true
    },
    async getContentsAPI(seq) {
      const params= {
        seq: seq
      }
			try{
				const {data} = await axiosInstance.get("/admin/operation/contFileInfo", {params});
        //this.contentsData는 json,
        // this.contentsDataFormatter(data) => list형태, 첫번째 인덱스로 값 셋팅
				this.contentsData = this.contentsDataFormatter(data)[0];
			}
			catch(e) {
				console.log(e)
				alert("컨텐츠 GET 실패했습니다.")
			}
		},
    async putContentsAPI(params) {
			try{
				const {data} = await axiosInstance.put("/admin/operation/contFileInfo", params);
        //{} json 형태로 리턴 받음. contentsDataFormatter에서는 list 형태로 date format을 변경하는거라, list로 바꿔주고, 
        //return 값도 첫번째 index여서, [0]을 붙여줌
				this.contentsData = this.contentsDataFormatter([data])[0];
			}
			catch(e) {
				console.log(e)
				alert("태그 업데이트 실패했습니다.")
			}
		},
    contentsDataFormatter(contents) {
			
			// 시간 yyyy-mm-dd로 맞춰줌
			for ( var data in contents) {
				//ex) 2021-11-02T17:47:13.677395
				let d = contents[data].createDate ? contents[data].createDate : '';
				contents[data].createDate =  d ? `${d.slice(0, 4)}-${d.slice(5, 7)}-${d.slice(8,10)} ${d.slice(11,19)}` : ''; // 2021-11-02 17:47:13
			}
			return contents
		},
    
  }
}
</script>
