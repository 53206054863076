<template>
  <v-overlay
    v-model="isShow"
    :light="true"
    :dark="false">
    <v-flex>
      <v-card max-width="85vw" max-height="95vh" class="overflow-y-auto">
        <v-card-title class="headline">
          <v-col cols="12">
          <span class="font-weight-bold" v-if="division === 'save'">연계담보구분코드 등록</span>
          <span class="font-weight-bold" v-else>연계담보구분코드( {{lkCvrDivCd}} ) 수정</span>
            <v-btn
                color="black darken-1"
                class="float-right"
                style="font-size: 20px;"
                text
                @click="close"
            >
              X
            </v-btn>
          </v-col>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-col cols="12" class="py-0">
          <v-sheet class="background" style="padding: 0px;">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left th-font">
                    보험사 : {{insCd}}
                  </th>
                  <th class="text-left th-font">
                    상품코드 : {{prCd}}
                  </th>
                  <th class="text-left th-font">
                    플랜코드 : {{planCd}}
                  </th>
                </tr>
                </thead>
              </template>
            </v-simple-table>
            <search-area>
              <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-5 col-12">
                <v-col cols="4">연계담보구분유형</v-col>
                <v-select
                    v-model="lkCvrDivType"
                    @change="setCond"
                    label="선택"
                    background-color="white"
                    class="subBar"
                    :items="lkCvrDivTypeList"
                    item-value="cvalue"
                    item-text="cname"
                    hide-details outlined dense
                  />
              </v-col>
              <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-5 col-12">
                <v-col cols="4">충족조건</v-col>
                <v-select
                    v-model="satCond"
                    label="선택"
                    :items="satCondList"
                    item-value="bvalue"
                    item-text="bname"
                    background-color="white"
                    class="subBar"
                    hide-details outlined dense
                />
              </v-col>
              <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-2 col-12" v-if="division === 'change'">
                <v-col cols="6">적용순서</v-col>
                <v-text-field
                    v-model="appOrder"
                    outlined dense hide-details
                    background-color="white" menu-props="auto"
                />
              </v-col>
              <v-col class="col-12"></v-col>
              <v-row class="px-6" style="margin-left: 1px;">연계담보구분 설명</v-row>
              <v-col class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
                <v-textarea
                  v-model="contents"
                  background-color="white"
                  outlined dense
                  placeholder="내용을 입력해주세요."
                  rows="5"
                  />
              </v-col>
            </search-area>
          </v-sheet>
          <v-card-actions v-if="division === 'save'">
            <v-spacer></v-spacer>
            <v-btn
                color="blue darkken-4"
                class="white--text col-1 float-right"
                style="margin-right: 5px;"
                @click="saveData()"
            >저장</v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-4"
                class="white--text col-1 float-right"
                style="margin-right: 12px;"
                @click="changeData('delete')"
            >삭제</v-btn>
            <v-btn
                color="blue darkken-4"
                class="white--text col-1 float-right"
                style="margin-right: 7px;"
                @click="changeData('update')"
            >저장</v-btn>
          </v-card-actions>
        </v-col>
      </v-card>
    </v-flex>
  </v-overlay>
</template>
<script>
import SearchArea from "../../../../components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModule";
export default {
  components: { SearchArea},
  props: ["isShow", "division", "checkDto", "paramsDto"],
  data:() => ({
    prCd:'',
    planCd:'',
    contents:'',
    planCdList:[],
    prCdList: [],
    satCondList:[],
    lkCvrDivTypeList: [
      {cvalue: 'PREM_LK', cname: '보험료연계'},
      {cvalue: 'SAT_ESS_LK', cname: '충족필수'},
      {cvalue: 'RATIO_LK', cname: '비율연계'},
      {cvalue: 'SET_LK', cname: '세트연계'},
      {cvalue: 'SUM_LK', cname: '합산연계'},
      {cvalue: 'OR_LK', cname: 'OR연계'},
      {cvalue: 'EXCESS_LK', cname: '초과연계'},
    ],
    insCd:'',
    lkCvrDivType:'',
    satCond:'',
    appOrder: '',
    lkCvrDivCd:''
  }),
  watch: {
    checkDto: function (obj) {
      this.lkCvrDivType = obj.lkCvrDivType;
      this.satCond = obj.satCond;
      this.contents = obj.lkCvrDivDesc;
      this.appOrder = obj.appOrder;
      this.lkCvrDivCd = obj.lkCvrDivCd;
      this.setCond();
    },
    paramsDto: function (dto) {
      this.prCd = dto.prCd;
      this.insCd = dto.insCd;
      this.planCd = dto.planCd;
    }
  },
  async created() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setCond() {
      if (this.lkCvrDivType === 'PREM_LK') {
        this.satCondList = [
          {bvalue: 'TOT_MIN_PREM_GE', bname: '총보험료_최소기준보험료'},
          {bvalue: 'SUM_PREM_RATIO_LE', bname: '총보험료대비_기준비율이하'},
        ]
      } else if (this.lkCvrDivType === 'RATIO_LK') {
        this.satCondList = [
          {bvalue: 'RATIO_EQ', bname: '연계담보_기준비율동일'},
          {bvalue: 'RATIO_GE', bname: '연계담보_기준비율이상'},
          {bvalue: 'RATIO_LE', bname: '연계담보_기준비율이하'},
        ]
      } else if (this.lkCvrDivType === 'SAT_ESS_LK') {
        this.satCondList = [
          {bvalue: 'CNT_GE_AMT_SAT', bname: '금액만족건수_기준이상'}
        ]
      } else if (this.lkCvrDivType === 'SET_LK') {
        this.satCondList = [
          {bvalue: 'SET_1', bname: 'SET1형'},
          {bvalue: 'SET_2', bname: 'SET2형'},
          {bvalue: 'SET_3', bname: 'SET3형'},
        ]
      } else if (this.lkCvrDivType === 'SUM_LK') {
        this.satCondList = [
          {bvalue: 'SUM_AMT_EQ', bname: '합산금액_기준금액동일'},
          {bvalue: 'SUM_AMT_LE', bname: '합산금액_기준금액이하'},
          {bvalue: 'SUM_CNT_EQ', bname: '합산가입건수_기준건수동일'},
        ]
      } else if (this.lkCvrDivType === 'EXCESS_LK') {
        this.satCondList = [
          {bvalue: 'AMT_GT_UP', bname: '연계담보가입금액_기준금액이상'},
          {bvalue: 'AMT_GT_ZERO', bname: '기준금액초과시_연계담보가입제한'},
        ]
      } else if (this.lkCvrDivType === 'OR_LK') {
        this.satCondList = [
          {bvalue: 'AMT_GT_UP', bname: '연계담보가입금액_기준금액이상'},
          {bvalue: 'RATIO_GE', bname: '연계담보_기준비율이상'},
        ]
      }
    },
    async selectCd() {
      try {
        const {data} = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId: "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getTableId(event) {
      this.tableList = [];
      this.tableId = "";
      const params = {
        insCd: event
      }
      try {
        const {data} = await axiosInstance.get("/admin/prdata/getPrInsTbMngTb", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prTbId + ' (' + data[i].prTbDes + ')';
          objJson.prTbId = data[i].prTbId;
          objJson.delType = data[i].delType;
          this.tableList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async saveData() {
      const prTakeLkTypeTbDto = {
        insCd: this.insCd,
        prCd: this.prCd,
        planCd: this.planCd,
        lkCvrDivType: this.lkCvrDivType,
        satCond: this.satCond,
        lkCvrDivDesc: this.contents
      }

      if (confirm("저장하시겠습니까?")) {
        try {
          const {data} = await axiosInstance.post('/admin/prdata/prTakeLkTypeSave', prTakeLkTypeTbDto);
          alert('연계담보구분코드 ' + data + '가 등록되었습니다.');
          this.$emit('close');
        } catch (e) {
          console.log(e);
        }
      }
    },
    async changeData(val) {
      let wkDiv = '';
      const prTakeLkTypeTbDto = {
        insCd: this.insCd,
        prCd: this.prCd,
        planCd: this.planCd,
        lkCvrDivType: this.lkCvrDivType,
        satCond: this.satCond,
        lkCvrDivDesc: this.contents,
        lkCvrDivCd: this.lkCvrDivCd,
        appOrder: this.appOrder
      }
      try {
        if (val === 'update') {
          confirm("변경하시겠습니까?");
          wkDiv = 'U';
        } else if (val === 'delete') {
          confirm('삭제하시겠습니까?');
          wkDiv = 'D';
        }
        const {data} = await axiosInstance.post('/admin/prdata/prTakeLkTypeProc', prTakeLkTypeTbDto, {
          params: {
            wkDiv: wkDiv
          }
        })
        alert(data);
        this.$emit('close');
      } catch (e) {
        console.log(e);
      }
    },
    async cvrCdList() {
      this.cvrList = [];
      const params = {
        insCd: this.searchInsurer,
        prCd: this.prCd,
        srhType: "3"
      }
      try {
        const {data} = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].planCd + ' (' + data[i].planNm + ')';
          objJson.planCd = data[i].planCd;
          this.planCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>
.background {
  background-color: rgba(0,0,0,.05);
  border: 1px solid lightgray;
}
.th-font {
  font-size: 15px !important;
}
</style>