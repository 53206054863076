<template>
<!-- by sk.jeon -->
  <search-page-layout title="유입 현황">

  <template v-slot:tabBar>
    <v-tabs
        center-active
    >
      <v-tab>전체</v-tab>
      <v-tab>원클릭</v-tab>
      <v-tab>청신호</v-tab>
      <v-tab>회원유입처</v-tab>
      <v-tab-item>
        <all-table/>
      </v-tab-item>
      <v-tab-item>
        <oneclick-table/>
      </v-tab-item>
      <v-tab-item>
        <greenlight-table/>
      </v-tab-item>
      <v-tab-item>
        <advertising-table/>
      </v-tab-item>
    </v-tabs>
  </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import AllTable from "@/pages/Stat/components/PerformanceStat/AllTable";
import GreenlightTable from "@/pages/Stat/components/PerformanceStat/GreenlightTable";
import OneclickTable from "@/pages/Stat/components/PerformanceStat/OneclickTable";
import AdvertisingTable from "@/pages/Stat/components/PerformanceStat/AdvertisingTable";


export default {
  components: {
    AdvertisingTable,
    SearchPageLayout,
    AllTable,
    GreenlightTable,
    OneclickTable
  },
  data: () => ({
      loading: false,
  }),
  methods: {
  }
}
</script>
