<template>
  <v-col cols="12" class="py-0">
    <div class="d-flex justify-space-between mb-2">
      <div>
        <span class="text-h6 mr-3">목록 (총 {{ data.length }}개)</span>
        <span class="text-h6">총금액 ({{ inputComma(totalRewardSum) }}원)</span>
      </div>
      <div>
        <outlined-btn class="px-5 excel-button" @click="$emit('toExcel')" :disabled="data.length < 1">
          엑셀다운로드
        </outlined-btn>
      </div>
    </div>
    <v-flex>
      <v-data-table
          height="65vh"
          :headers="getHeader(headers)"
          :items="data"
          :loading="loading"
          :items-per-page="-1" fixed-header
          @click:row="getRowData"
          @dblclick:row="showDetailPopup"
      >
        <template v-slot:item.telNum="{ value }">
          <div>{{ getTelNum(value) }}</div>
        </template>
        <template v-slot:item.hrewardAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.mrewardAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.totAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.send="{ item, value }">
          <v-btn v-if="value" @click="sendMessage(item)">발송</v-btn>
        </template>
        <template v-slot:item.history="{ item }">
          <v-btn @click="showHistoryPopup(item)">이력</v-btn>
        </template>
      </v-data-table>
      <settlement-payback-detail-popup
          :is-show="isShowDetail"
          :params-data="paramsData"
          :row-data="rowData"
          @close="isShowDetail = false"
      />

      <settlement-payback-hist-popup
          :is-show="isShowHistory"
          :row-data="rowData"
          @close="isShowHistory = false"
      />
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {SETTLEMENT_PAYBACK_TABLE} from "@/util/TableHeaders";
import SettlementPaybackDetailPopup
  from "@/pages/Settlement/components/SettlementPayback/Popup/SettlementPaybackDetailPopup.vue";
import SettlementPaybackHistPopup
  from "@/pages/Settlement/components/SettlementPayback/Popup/SettlementPaybackHistPopup.vue";
import {axiosInstance} from "@/util/AxiosModule";
import DateMixin from "@/mixin/DateMixin";
import _ from "lodash";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn.vue";

export default {
  components: {OutlinedBtn, SettlementPaybackHistPopup, SettlementPaybackDetailPopup},
  props: ["paramsData", "data", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: SETTLEMENT_PAYBACK_TABLE,
      rowData: {},
      isShowDetail: false,
      isShowHistory: false
    }
  },
  computed: {
    totalRewardSum() {
      let totalReward = 0;
      if (!_.isEmpty(this.data)) {
        for (let i = 0; i < this.data.length; i++) {
          totalReward += this.data[i].hrewardAmt;
        }
        return totalReward;
      }
      return totalReward;
    }
  },
  methods: {
    async sendMessage(item) {
      try {
        await axiosInstance.put("/public/settlement/setSettlementNoticeTargetSend", {}, {
          params: {
            memberId: item.memberId
          }
        })
        .then(() => {
          alert("알림톡이 발송되었습니다.");
          this.data[item.index].send = false;
        })
        .catch((error) => {
          console.log(error);
          })
      } catch (e) {
        console.log(e);
      }
    },
    getRowData(row) {
      this.rowData = row;
    },
    showDetailPopup() {
      this.isShowDetail = true;
    },
    showHistoryPopup(item) {
      this.rowData = item;
      this.isShowHistory = true;
    }
  }
}
</script>

<style scoped>
.excel-button:hover {
  border: 1px solid green;
  color: #fff;
  background-color: green;
}
</style>