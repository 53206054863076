<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>
      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">프로그램 ID</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="params.progPrvId"
                outlined dense hide-details
                disabled
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">프로그램 명</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="params.progNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">프로그램 한글명</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="params.progNmKr"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>

      </v-row>

      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">메뉴그룹</v-col>
          <v-col cols="8">
            <v-select
                v-model="params.progMnGroup"
                :items="progMnGroupList"
                item-text="name"
                item-value="cd"
                outlined dense hide-details
                background-color="white" menu-props="auto" class="subBar"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">표시 순서</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="params.dispOrder"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                inputmode="numeric"
                placeholder="상단 메뉴 정렬 순서"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">화면 URL</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="params.progPath"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                placeholder="예) /path/program"
            />
          </v-col>
        </v-col>
      </v-row>

      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">등록자</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="createNm"
                outlined dense hide-details
                disabled
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">등록일시</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="createDate"
                outlined dense hide-details
                disabled
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">엑셀 등급</v-col>
          <v-col cols="8">
            <v-select
                v-model="params.xlsGrade"
                :items="xlsGradeList"
                item-text="name"
                item-value="cd"
                outlined dense hide-details
                background-color="white" menu-props="auto" class="subBar"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">변경자</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="updateNm"
                outlined dense hide-details
                disabled
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">변경일시</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="updateDate"
                outlined dense hide-details
                disabled
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">사용여부</v-col>
          <v-col cols="8">
            <v-select
                v-model="params.useYn"
                :items="useYnList"
                item-text="name"
                item-value="cd"
                outlined dense hide-details
                background-color="white" menu-props="auto" class="subBar"
            />
          </v-col>
        </v-col>
      </v-row>

      <!-- 권한 체크 박스  -->
      <v-row class="px-6" style="margin: 12px 10px 15px;">
        <span class="prv-title">권한</span>
        <v-col class="d-flex col-12 prv-box">
          <v-col>
            <v-checkbox :label="'전체'"
                        v-model="selectAll"
                        @click="checkAll(selectAll)"
            />
          </v-col>
          <v-col v-for="(item, key) in selectedPrivilegeList" :key="key + prvGrpKey">
            <v-checkbox :label="prvGrpName(key)"
                        v-model="selectedPrivilegeList[key]"
                        false-value="N" true-value="Y"
                        @click="checkStatus"
            />
          </v-col>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="red lighten-1"
            class="white--text col-1"
            @click="initData"
        >초기화
        </v-btn>
        <v-btn
            :color="isNewData ? 'blue darken-3' : 'green darken-3'"
            class="white--text col-1"
            @click="checkValid"
            :disabled="checkSaveValid"
        >{{ isNewData ? "저장" : "수정" }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import constant from "@/util/constant";
import func from "@/util/func";
import _ from "lodash";

export default {
  props: ["rowData" ,"prvList"],
  data() {
    return {
      params: {
        progPrvId: 0, // PK
        progNm: "",   // 프로그램 명
        progNmKr: "", // 한글 화면명
        progPath: "", // url
        useYn: "",    // 사용여부
        progMnGroup: "", // 메뉴 그룹
        dispOrder: "",
        xlsGrade: "", // 엑셀등급
      },
      useYnList: constant.USE_YN_LIST,
      progMnGroupList: constant.PROGRAM_MENU_GROUP_LIST,
      xlsGradeList: constant.XLS_GRADE_LIST,
      prvGroupList: [],
      createNm: "",
      createDate: "",
      updateNm: "",
      updateDate: "",

      selectedPrivilegeList: {},  // 선택된 권한(승인) 그룹
      selectAll: false,
      prvGrpKey: 0,
    }
  },

  computed: {
    checkSaveValid() {
      return _.isEmpty(this.params.progNm)
          || _.isEmpty(this.params.progNmKr)
          || _.isEmpty(this.params.progPath)
          || _.isEmpty(this.params.useYn)
          || _.isEmpty(this.params.progMnGroup)
          || (this.params.dispOrder == 0)
          || _.isEmpty(this.params.xlsGrade)
    },
    isNewData(){
      return this.params.progPrvId == 0;
    }
  },

  created() {
    this.prvGroupList = _.cloneDeep(this.prvList);
    this.prvGroupList.forEach(item => {
      this.selectedPrivilegeList[item.cd] = "N";
    })

    if (!_.isEmpty(this.rowData)) {
      this.setProps(this.rowData);
    }
  },

  methods: {
    // 테이블 row 선택 시 세팅
    setProps(data) {
      this.params.progPrvId = data.progPrvId;
      this.params.progNm = data.progNm;
      this.params.progNmKr = data.progNmKr;
      this.params.progPath = data.progPath;
      this.params.useYn = data.useYn;
      this.params.progMnGroup = data.progMnGroup;
      this.params.dispOrder = parseInt(data.dispOrder);
      this.params.xlsGrade = data.xlsGrade;
      this.createNm = data.createNm;
      this.createDate = func.contentsDataFormatter2(data.createDate);
      this.updateNm = data.updateNm;
      this.updateDate = func.contentsDataFormatter2(data.updateDate);

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(this.selectedPrivilegeList, key)) {
          this.selectedPrivilegeList[key] = data[key];
        }
      }
    },

    // 유효값 확인
    checkValid() {
      if (!this.isEngOrNumberic(this.params.progNm)) {
        alert("프로그램 명은 영문 알파벳 혹은 숫자로만 이루어져야 합니다.");
        return false;
      }
      if (_.isEmpty(this.params.progNm)) {
        alert("프로그램 명 누락");
        return false;
      }
      if (_.isEmpty(this.params.progNmKr)) {
        alert("프로그램 한글명 누락");
        return false;
      }
      if (_.isEmpty(this.params.progPath)) {
        alert("프로그램 경로 누락");
        return false;
      }
      if (!this.params.progPath.startsWith('/') || this.params.progPath.endsWith('/')) {
        alert("프로그램 경로 양식에 맞게 입력하세요.");
        return false;
      }
      if (_.isEmpty(this.params.useYn)) {
        alert("사용여부 누락");
        return false;
      }
      if (_.isEmpty(this.params.progMnGroup)) {
        alert("메뉴 그룹 누락");
        return false;
      }
      if (this.params.dispOrder < 1) {
        alert("메뉴 표시 순서는 1이상의 수를 입력하세요.");
        return false;
      }
      if (_.isEmpty(this.params.xlsGrade)) {
        alert("엑셀 등급 누락");
        return false;
      }

      this.saveData();
    },

    // 저장
    async saveData(){

      const paramObj = {
        progPrvId: this.params.progPrvId,
        progNm: this.params.progNm,
        progNmKr: this.params.progNmKr,
        progPath: this.params.progPath,
        progMnGroup: this.params.progMnGroup,
        dispOrder: this.params.dispOrder,
        prvGroupLst: JSON.stringify(this.selectedPrivilegeList),
        xlsGrade: this.params.xlsGrade,
        useYn: this.params.useYn,
        memberId: this.$store.getters['login/memberId'],
      }

      try {
        await axiosInstance.post('/admin/operation/setProgramPrivilege', paramObj);
        alert("저장되었습니다.");

        this.initData();
        this.$emit("refresh");
      } catch (e) {
        console.log(e);
      }
    },

    // 초기화
    initData() {
      this.checkAll(false);
      this.selectAll = false;
      this.params.progPrvId = 0;
      this.params.progNm = "";
      this.params.progNmKr = "";
      this.params.progPath = "";
      this.params.useYn = "";
      this.params.progMnGroup = "";
      this.params.dispOrder = 0;
      this.params.xlsGrade = "";
      this.createNm = "";
      this.createDate = "";
      this.updateNm = "";
      this.updateDate = "";
    },

    // 권한그룹 한글 표기
    prvGrpName(key) {
      return this.prvList.find(item => item.cd == key).value;
    },
    // 전체 선택
    checkAll(status) {
      let newValue = status ? "Y" : "N";
      for (let key in this.selectedPrivilegeList) {
        this.selectedPrivilegeList[key] = newValue;
      }
      this.prvGrpKey++;
    },
    // 전체 선택 여부 확인
    checkStatus() {
      this.selectAll = Object.values(this.selectedPrivilegeList).every(value => value === "Y");
    },
    // 영문 or 숫자로만 이루어진 문자열 체크
    isEngOrNumberic(str) {
      return /^[a-zA-Z0-9]+$/.test(str);
    },
  }
}
</script>

<style>

.prv-title {
  margin: 20px 0 10px 3px;
}

.prv-box {
  background-color: white;
  border-radius: 15px;
}

</style>