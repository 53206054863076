<template>
  <v-col cols="12" class="py-0" >
    <div class="mb-2">
      <span class="text-h7" style="color: black; margin-left:8px">목록 (총 {{ data.length }}개)</span>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(headers)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
          class="black--text"
          style="background-color: white;"
      >
        <!--Header 디자인-->
        <template v-slot:header.telNum="{ header }">
          <div class="idHeader"> {{ header.text.toUpperCase() }} </div>
        </template>
        <template v-slot:header.memberNm="{ header }">
          <div class="idHeader"> {{ header.text.toUpperCase() }} </div>
        </template>
        <template v-slot:header.email="{ header }">
          <div class="idHeader"> {{ header.text.toUpperCase() }} </div>
        </template>

        <!-- 전화번호, 이름, 이메일 순서 출력-->
        <!-- 전화번호는 받을때 그냥 숫자만 있는 종류로 받고, 받은상태에서 -로 나눔-->
        <template v-slot:item.telNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.memberNm="{ value }">
          {{ value ? value : '' }}
        </template>

        <template v-slot:item.email="{ value }">
          {{ value ? value : '' }}
        </template>

      </v-data-table>

      <v-col>
        <outlined-btn
            class="float-left black--text"
            @click="$emit('toExcel')"
            :disabled="!hasTableData"
        >엑셀다운로드
        </outlined-btn>
      </v-col>

      <!--닫기-->
      <v-col class="py-1">
          <v-btn
              color="grey"
              class="white--text col-1 float-right"
              style="margin: 15px 0"
              @click="close"
          > 닫기
          </v-btn>
      </v-col>

    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {CHECK_MEMBER_HEADER} from "@/util/TableHeaders";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";

export default {
  components: {OutlinedBtn},
  mixins: [TableMixin],
  props: ["data", "loading"],

  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },

  data: () => ({
    headers: CHECK_MEMBER_HEADER,
  }),

  methods: {
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

.idHeader {
  width: 100%;
  height: 90%;
  line-height: 50px;
  font-size: 1rem;
  color: dimgray;
  text-align: center;

}
</style>