<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">보험사</v-col>
          <v-select
              @change="getTableId"
              @click="selectCd"
              v-model="searchInsurer"
              :items="insurerCode"
              background-color="white"
              item-text="value"
              item-value="cd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-5 col-lg-5 col-md-6 col-12">
          <v-col cols="3">상품코드</v-col>
          <v-select
              @change="cvrCdList"
              :items="prCdList"
              v-model="prCd"
              label="선택"
              background-color="white"
              item-text="text"
              item-value="prCd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="4">플랜코드</v-col>
          <v-select
              v-model="planCd"
              :items="planCdList"
              label="선택"
              background-color="white"
              item-text="text"
              item-value="planCd"
              class="subBar"
              hide-details outlined dense
          />
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModule";
import SearchBtn from "../../../../components/Search/SearchBtn";
export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  data:() => ({
    searchInsurer:"",
    prCd:'',
    planCd:'',
    planCdList:[],
    prCdList: [],
    insurerCode: [
      {value: '전체', cd: ''}
    ],
  }),
  methods: {
    async selectCd() {
      try{
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "PR_INS_CODE",
            type: "KEY"
          }
        });
        for (let i = 0; i < data.length; i++) {
          if (data[i].use === true) {
            const objJson = new Object();
            objJson.value = data[i].value;
            objJson.cd = data[i].cd;
            this.insurerCode.push(objJson);
          }
        }
      } catch(e) {
        console.log(e);
      }
    },
    async getTableId() {
      this.planCdList = [];
      this.prCdList = [];
      const params = {
        insCd: this.searchInsurer,
        srhType: "2"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].prCd + ' (' + data[i].prNm + ')';
          objJson.prCd = data[i].prCd;
          this.prCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async cvrCdList() {
      this.cvrList = [];
      const params = {
        insCd: this.searchInsurer,
        prCd: this.prCd,
        srhType: "3"
      }
      try {
        const { data } = await axiosInstance.get("/admin/prdata/prPrCdNmList", {params});
        for (let i = 0; i < data.length; i++) {
          let objJson = new Object();
          objJson.text = data[i].planCd + ' (' + data[i].planNm + ')';
          objJson.planCd = data[i].planCd;
          this.planCdList.push(objJson);
        }
      } catch (e) {
        console.log(e);
      }
    },
    getSearch() {
      const getPrTakeBaseTb = {
        insCd: this.searchInsurer,
        prCd: this.prCd,
        planCd: this.planCd
      }

      this.$emit('getSearch', getPrTakeBaseTb);
    }
  }
}
</script>