<template>
  <search-page-layout title="설계지원 운영현황">

    <template v-slot:searchBar>
      <design-support-status-search-bar @getSearch="getSearch"/>
    </template>

    <template v-slot:table>
      <design-support-status-table
        :key="tablekey"
        :data="data"
        :type="type"
        :loading="loading"
      />
    </template>
  </search-page-layout>
</template>

<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import DesignSupportStatusSearchBar from "@/pages/Support/components/DesignSupportStatus/DesignSupportStatusSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import DesignSupportStatusTable from "@/pages/Support/components/DesignSupportStatus/DesignSupportStatusTable";
import _ from "lodash";

export default {
  components: {
    DesignSupportStatusTable,
    DesignSupportStatusSearchBar,
    SearchPageLayout
  },
  data:() => ({
    loading: false,
    data: [],
    type: "",
    tablekey: 0,
  }),
  methods: {
    async getSearch(params) {
      this.loading = true;

      let axiosData;

      try {
        axiosData = await axiosInstance.get('/admin/operation/designSupSts', {params});

      } catch (e) {
        console.log(e);
      }
      const { data } = axiosData;
      let allData = {}
      for( var index in data) {
        for( var key in data[index]) {
          if (key == "stsDt") {
            allData[key] = "합계"
          }else {
            if (allData[key] == null) {
              allData[key] = data[index][key]
            } else {
              allData[key] += data[index][key]
            }
          }
        }
      }
      data.unshift(allData);

      if (_.isEmpty(data[0])) {
        alert("데이터가 없습니다.");
      }

      this.data = data;
      this.type = params.dataType;
      this.tablekey++;
      this.loading = false;
    }
  }
}
</script>