<template>
  <v-btn @click="$emit('click')" :disabled="disabled" outlined><slot></slot></v-btn>
</template>

<script>
export default {
  props: ["disabled"],
}
</script>

<style scoped>

</style>