<script>
import { Bar } from 'vue-chartjs';
import _ from "lodash";

export default {
  props: ["totArry", "monthArray", "insuredNm"],
  extends: Bar,
  data() {
    return{
      datasets : [
        {
          label: "",
          type: "bar",
          data: [],
          backgroundColor: "rgba(30,144,255,0.8)",
          hoverBackgroundColor: "rgb(30,144,255)",
          borderWidth: 3
        },
        {
          label: "",
          type: "bar",
          data: [],
          backgroundColor: "rgba(255,69,0,0.8)",
          hoverBackgroundColor: "rgb(255,69,0)",
          borderWidth: 3
        },
        {
          label: "",
          type: "bar",
          data: [],
          backgroundColor: "rgba(169,169,169,0.8)",
          hoverBackgroundColor: "rgb(169,169,169)",
          borderWidth: 3,
        }
      ],
    }
  },

  mounted() {
    if (!_.isEmpty(this.monthArray[0])){
      this.datasets[2].label = this.monthArray[0];
    }
    if (!_.isEmpty(this.monthArray[1])){
      this.datasets[1].label = this.monthArray[1];
    }
    if (!_.isEmpty(this.monthArray[2])){
      this.datasets[0].label = this.monthArray[2];
    }

    let cuntVal = 0;
    cuntVal = this.totArry[0].value.length;
    // 콤마 찍혀있는 문자형 콤마 제거, 데이터 역순 출력
    for(let i in this.totArry) {
      if (this.totArry[i].value[cuntVal-1] !== 0) {
        this.datasets[2].data[i] = this.totArry[i].value[cuntVal-1].split(',').join("");
      } else {
        this.datasets[2].data[i] = 0
      }
    }
    for(let i in this.totArry) {
      if (this.totArry[i].value[cuntVal-4] !== 0) {
        this.datasets[1].data[i] = this.totArry[i].value[cuntVal-4].split(',').join("");
      } else {
        this.datasets[1].data[i] = 0
      }
    }
    for(let i in this.totArry) {
      if (this.totArry[i].value[cuntVal-7] !== 0) {
        this.datasets[0].data[i] = this.totArry[i].value[cuntVal-7].split(',').join("");
      } else {
        this.datasets[0].data[i] = 0
      }
    }
    if (this.insuredNm.length > 10 ) {
      alert("새로고을 해주세요.");
      return;
    }

    this.renderChart(
        {
          labels: this.insuredNm,
          datasets: this.datasets,
          scales: {

          }
        },
        {
          responsive: true, maintainAspectRatio: false, beginAtZero: true,
          tooltips: {
            callbacks: {
              label: function (tooltipItem) { //그래프 콤마
                return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
              }
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true,
                callback: function(value) {
                  if(parseInt(value) >= 1000){
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }else {
                    return value
                  }
                }
              }
            }]
          }
        }
    );
  },
  watch: {
    totArry:function () {
      let cuntVal = 0;
      cuntVal = this.totArry[0].value.length;
      // 콤마 찍혀있는 문자형 콤마 제거, 데이터 역순 출력
      for(let i in this.totArry) {
        if (this.totArry[i].value[cuntVal-1] !== 0) {
          this.datasets[2].data[i] = this.totArry[i].value[cuntVal-1].split(',').join("");
        } else {
          this.datasets[2].data[i] = 0
        }
      }
      for(let i in this.totArry) {
        if (this.totArry[i].value[cuntVal-4] !== 0) {
          this.datasets[1].data[i] = this.totArry[i].value[cuntVal-4].split(',').join("");
        } else {
          this.datasets[1].data[i] = 0
        }
      }
      for(let i in this.totArry) {
        if (this.totArry[i].value[cuntVal-7] !== 0) {
          this.datasets[0].data[i] = this.totArry[i].value[cuntVal-7].split(',').join("");
        } else {
          this.datasets[0].data[i] = 0
        }
      }
      if (this.insuredNm.length > 10 ) {
        alert("새로고을 해주세요.");
        return;
      }
      this.$data._chart.update();
    },
    data:function () {
      this.renderChart(
          {
            labels: this.insuredNm,
            datasets: this.datasets,
            scales: {

            }
          },
          {
            responsive: true, maintainAspectRatio: false, beginAtZero: true,
            tooltips: {
              callbacks: {
                label: function (tooltipItem) { //그래프 콤마
                  return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
                }
              },
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero:true,
                  callback: function(value) {
                    if(parseInt(value) >= 1000){
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }else {
                      return value
                    }
                  }
                }
              }]
            }
          }
      );
    }
  }
}
</script>