<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <v-col cols="4">
            <v-select
                :items="DATE_TYPE"
                background-color="white"
                v-model="searchType" menu-props="auto"
                hide-details outlined dense
            />
          </v-col>
          <date-input
              :start-date="fromDate"
              :end-date="toDate"
              @updateDate="updateDate"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
          <v-col cols="7">
            <span>정산건제외</span>
          </v-col>
          <v-select
              v-model="excSettYn"
              :items="excSettYnList"
              item-text="excSettYnNm"
              item-value="excSettYn"
              background-color="white"
              outlined dense hide-details
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
          <v-col cols="7">
            <span>중복제외</span>
          </v-col>
          <v-select
              v-model="excDupYn"
              :items="excDupYnList"
              item-text="excDupYnNm"
              item-value="excDupYn"
              background-color="white"
              outlined dense hide-details
          />
        </v-col>
      </search-area>

      <v-divider class="mb-2"/>

      <search-area title="유입고객 정보">
        <field-input title="고객명">
          <v-text-field
              v-model="customerNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="차량번호">
          <v-text-field
              v-model="customerCarNo"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>

      <search-area title="회원정보">
        <field-input title="회원명">
          <v-text-field
              v-model="recipientNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="전화번호">
          <v-text-field
              v-model="telNum"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getCarExpirInfo"/>
    </template>
  </search-bar-layout>
</template>

<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, FieldInput},
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-50);
    this.fromDate = fromDate;
    this.toDate = toDate;
  },
  async created() {
    this.DATE_TYPE = await this.getSelectList("CAREXPIRINFO_SELECT_TYPE");
  },
  data: () => ({
    DATE_TYPE: [],
    fromDate: "",
    toDate: "",
    searchType: "CREATE_DATE",
    customerNm: "",
    customerCarNo: "",
    recipientNm: "",
    telNum: "",
    excSettYn: "N",
    excSettYnList: [
      { excSettYnNm: "Y", excSettYn: "Y" },
      { excSettYnNm: "N", excSettYn: "N" }
    ],
    excDupYn: "N",
    excDupYnList: [
      { excDupYnNm: "Y", excDupYn: "Y" },
      { excDupYnNm: "N", excDupYn: "N" }
    ]
  }),
  computed: {

  },
  methods: {
    updateDate(values) {
      this.fromDate = values[0];
      this.toDate = values[1];
    },
    getCarExpirInfo() {

      const params = {
        fromDate : this.fromDate.replace(/[^0-9]/gi, ""),
        toDate : this.toDate.replace(/[^0-9]/gi, ""),
        searchType : this.searchType,
        excSettYn: this.excSettYn,
        excDupYn: this.excDupYn
      }
      if(this.customerNm) params["customerNm"] = this.customerNm;
      if(this.customerCarNo) params["customerCarNo"] = this.customerCarNo;
      if(this.recipientNm) params["recipientNm"] = this.recipientNm;
      if(this.telNum) params["telNum"] = this.telNum;

      this.$emit('getCarExpirInfo', params)
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>