<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>
      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="3">신정원 담보명</v-col>
          <v-col cols="9">
            <v-text-field
                v-model="dataDto.creCvrNm"
                :disabled="this.dataDto.creCvrNm === this.rowData.creCvrNm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">신정원 담보코드</v-col>
          <v-col cols="7">
            <v-text-field
              v-model="dataDto.creCvrCd"
              :disabled="this.dataDto.creCvrCd === this.rowData.creCvrCd"
              outlined dense hide-details
              background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">사용여부</v-col>
          <v-col cols="4">
            <v-select
                v-model="dataDto.useYn"
                :items="useYnList"
                item-text="useYnName"
                item-value="useYnValue"
                outlined dense hide-details
                background-color="white" menu-props="auto" class="subBar"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-5 col-12">
          <v-col cols="">담보설명</v-col>
          <v-col cols="9">
            <v-text-field
                v-model="dataDto.creCvrDesc"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green lighten-1"
          class="white--text col-1"
          @click="deleteData"
        >초기화</v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >저장</v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props: ["rowData"],
  data() {
    return {
      dataDto: {
        'creCvrCd':'',
        'creCvrNm':'',
        'useYn':'Y',
        'creCvrDesc':'',
      },
      useYnList: [
        {useYnName: "Y", useYnValue: "Y"},
        {useYnName: "N", useYnValue: "N"},
      ]
    }
  },
  watch: {
    rowData: function (value) {
      this.dataDto.creCvrCd = value.creCvrCd;
      this.dataDto.creCvrNm = value.creCvrNm;
      this.dataDto.useYn = value.useYn;
      this.dataDto.creCvrDesc = value.creCvrDesc;
    }
  },
  methods: {
    deleteData() {
      this.dataDto.creCvrCd = "";
      this.dataDto.creCvrNm = "";
      this.dataDto.useYn = "Y";
      this.dataDto.creCvrDesc = "";
    },
    async saveData() {
      const prCreCvrTbDto = {
        creCvrCd: this.dataDto.creCvrCd,
        creCvrNm: this.dataDto.creCvrNm,
        useYn: this.dataDto.useYn,
        creCvrDesc: this.dataDto.creCvrDesc,
      }
      try {
        await axiosInstance.post('/admin/prdata/prCreCvrSave', prCreCvrTbDto);
        alert("저장되었습니다.");
        this.dataDto.useYn = "Y";
        this.dataDto.creCvrDesc = "";
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>