<template>
  <search-page-layout title="자동차 실적 조회">

    <template v-slot:searchBar>
      <car-sales-list-search-bar
          @search="getCarSalesList"
      />
    </template>

    <template v-slot:table>
      <car-sales-list-table
          :tableData="tableData"
          :diff = "diff"
          :stDt = "stDt"
          :edDt = "edDt"
          :fromYm = "fromYm"
          @toExcel="toExcel"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "../../layouts/SearchPageLayout";
import CarSalesListSearchBar from "./components/CarSalesList/CarSalesListSearchBar";
import CarSalesListTable from "./components/CarSalesList/CarSalesListTable";
import _ from "lodash";
import func from "@/util/func";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {CarSalesListTable, CarSalesListSearchBar, SearchPageLayout},
  data: () => ({
    tableData: [],
    diff:'',
    stDt:'',
    edDt:'',
    fromYm:''
  }),
  methods: {
    async getCarSalesList(params, diff) {
      this.diff = Math.floor(diff/30 + 1);
      this.stDt = parseInt(params.startYm.substring(5, 6));
      this.fromYm = params.startYm;
      this.edDt = params.endYm;
      try {
        const {data} = await axiosInstance.get('/admin/settlement/sales_performance', {params});
        if (data.length < 1) {
          alert("조회된 목록이 없습니다.");
          return false;
        }
        this.tableData = data;
      } catch (e) {
        console.log(e);
      }
    },
    toExcel() {
      if (_.isEmpty(this.tableData)) return;
      const data = _.cloneDeep(this.tableData);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "기준년월": item["standardYm"],
          "채널": item["joinChannel"],
          "보험사": item["contractInsurerNm"],
          "건수": item["totContCnt"],
          "보험료": item["totContPremAmt"],
          "광고비": item["totContFee"],
        }
        returnData.push(obj);
      }

      const sheetName = `자동차 실적조회`;
      const fileName = `자동차 실적List`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
  }
}
</script>