var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"my-2"},[_c('span',{staticClass:"text-h6"},[_vm._v("목록 (총 "+_vm._s(_vm.tableData.length)+"개)")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.tableData,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.getHeader(_vm.headers),"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"item.insCd",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getInsCdName(value)))])]}},{key:"item.stDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateFormat(value)))])]}},{key:"item.closeDt",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getDateFormat(value)))])]}},{key:"item.createDate",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.replaceDate(value)))])]}},{key:"item.wkTgYn",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.getWkTgYnName(value)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }