<template>
  <v-col cols="12" class="py-0">
    <div style="width: 100%; text-align: right;">
      <outlined-btn class="mt-3 mb-1" :disabled="data.length < 1" @click="$emit('toExcel')">엑셀다운로드</outlined-btn>
    </div>
    <v-flex xs12 sm12 md12 xl12>
      <v-data-table
        class="mt-3 summary-detail-table"
        height="55vh"
        :headers="getHeader(header)"
        :items="data"
        :loading="loading"
        :items-per-page="-1"
        fixed-header calculate-widths
      >
        <template v-slot:item.srcContractPrem="{ value }">
          {{ inputComma(value) }}
        </template>
        <template v-slot:item.srcCustmerTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>
        <template v-slot:item.memberTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>
        <template v-slot:item.contractPrem="{ value }">
          {{ inputComma(value) }}
        </template>
        <template v-slot:item.customerTelNum="{ value }">
          {{ getTelNum(value) }}
        </template>
        <template v-slot:item.rewardAmt="{ value }">
          {{ inputComma(value) }}
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>
<script>
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import TableMixin from "@/mixin/TableMixin";
import {SETTLEMENT_INS_SUMMARY_DETAIL_TABLE} from "@/util/TableHeaders";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin],
  components: {OutlinedBtn},
  data() {
    return {
      header: SETTLEMENT_INS_SUMMARY_DETAIL_TABLE,
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep tr:last-child {
  background-color: #c0c0c0;
  td {
    span {
      cursor: default !important;
    }
  }
}
</style>