<template>
  <search-page-layout title="조직정보 관리">
    <template v-slot:searchBar>
      <OrginfoSearchBar @getOrgInfo="getOrgInfo"  @orgBatch1="orgBatch1" @orgBatch2="orgBatch2" @deleteInfo="deleteInfo"/>
    </template>
    <template v-slot:table>
      <OrginfoTable
          :data="data"
          :loading="loading"
      />
    </template>
  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import OrginfoTable from "@/pages/Operation/components/OrgInfo/OrginfoTable";
import OrginfoSearchBar from "@/pages/Operation/components/OrgInfo/OrginfoSearchBar";

export default {
  props: ["name"],
  components: {
    OrginfoTable,
    OrginfoSearchBar,
    SearchPageLayout,
  },
  data: () => ({
    loading: false,
    data: [],
  }),

  methods: {
    async getOrgInfo(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/operation/orginfo', { params });
        this.data = data;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    // 조직작업 Batch
    async orgBatch1(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/operation/org-batch',  {params}).then(() => {
          alert("상위조직 매핑 성공");
        }).catch(() => {
          alert("상위조직 매핑 실패");
        });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },// 조직작업 Batch
    async orgBatch2(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/operation/org-batch',  {params}).then(() => {
          alert("가명화 작업 성공");
        }).catch(() => {
          alert("가명화 작업 실패");
        });
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    // 업로드자료 삭제
    async deleteInfo(params) {

        alert("현재월 자료만 삭제됩니다.")
        try {
          //await axiosInstance.delete('/admin/operation/delete-info', {data});
          const {data} = await axiosInstance.get('/admin/operation/delete-info',  {params} );
          this.data = data;
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },

}
</script>