<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
    <v-flex>
      <v-card max-width="85vw" max-height="95vh" class="overflow-y-auto">
        <v-card-title class="headline">
          <v-col cols="12">
            <span class="font-weight-bold">설계지원 이력수정</span>
          </v-col>
        </v-card-title>
        <v-divider :dark="true" :light="false"/>
        <v-col cols="12" class="py-0">
          <v-sheet class="background" style="padding: 0px;">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left th-font">
                    FA명: {{data.memberNm}}
                  </th>
                  <th class="text-left th-font">
                    FA연락처: {{getTelNum(data.telNum)}}
                  </th>
                  <th class="text-left th-font">
                    요청일: {{data.createDate}}
                  </th>
                  <th class="text-left th-font">
                    설계지원 ID: {{data.designSupId}}
                  </th>
                  <!-- HD(현대해상) 발급 키(미반영 비활성화) -->
                  <th class="text-left th-font">
                    코드: {{data.hd}}
                  </th>
                </tr>
                </thead>
              </template>
            </v-simple-table>
            <v-container>
              <v-row>
                <!-- 고객명 -->
                <v-col>
                  고객명
                  <v-text-field
                      v-model="data.customerNm"
                      outlined dense hide-details
                      background-color="white"
                  />
                </v-col>
                <!-- 고객전화번호 -->
                <v-col>
                  고객전화번호
                  <v-text-field
                      v-model="data.customerTelNum"
                      outlined dense
                      background-color="white"
                      hint="ex) 01012345678"
                  />
                </v-col>
                <!-- 차량번호 -->
                <v-col>
                  차량번호
                  <v-text-field
                      v-model="data.customerCarNo"
                      outlined dense
                      background-color="white"
                      hint="ex) 12허1234"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <!-- 상태 -->
            <v-container>
              <v-row>
                <!-- 상태 -->
                <v-col>
                  상태
                  <v-select
                      v-model="data.state"
                      :items="stateNmList"
                      outlined dense hide-details
                      background-color="white"
                  />
                </v-col>
                <!-- 상담결과 -->
                <v-col>
                  상담결과
                  <v-select
                      v-model="data.consultResult"
                      :items="modifyConsultResult"
                      outlined dense hide-details
                      background-color="white"
                      @change="changeData"
                  />
                </v-col>
                <!-- 만기일자 -->
                <v-col>
                  만기일자
                  <v-text-field
                      v-model="data.expiryDt"
                      background-color="white"
                      outlined dense
                      :rules="expiryDtCheckRules"
                      hint="ex) 20220101"
                  />
                </v-col>
                <v-col>
                  안내보험사
                  <v-select
                      v-model="data.guideInsurerCd"
                      :items="guideInsurerCdList"
                      item-text="name"
                      item-value="code"
                      background-color="white"
                      outlined dense
                      @change="changeData"
                  />
                </v-col>
              </v-row>
              <v-row class="justify-end">
                <v-col cols="3">
                  이전보험사
                  <v-select
                      v-model="data.preInsurerCd"
                      :items="preInsurerCdList"
                      item-text="name"
                      item-value="value"
                      background-color="white"
                      outlined dense
                      @change="changeData"
                      :disabled="isPrvGroupTM"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <!-- 상담메모 입력 -->
            <search-area>
              <v-row class="px-6" style="margin-top: 10px;">상담내용
                <v-btn class="red white--text" style="margin-left: 10px; margin-top: -5px" @click="initData">초기화</v-btn>
              </v-row>

              <v-col class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
                <v-textarea
                    placeholder="상담내용을 입력해주세요."
                    v-model="data.consultMemo"
                    background-color="white"
                    outlined
                    auto-grow
                />
              </v-col>
            </search-area>
          </v-sheet>
          <v-card-actions>
            <v-btn
                color="blue darken-1"
                class="white--text col-1 float-right"
                @click="deleteData"
                :disabled="checkDeleteGroup"
            >
              삭제
            </v-btn>
            <v-spacer></v-spacer>
            <!-- 저장버튼 -->
            <v-btn
                color="blue darken-1"
                class="white--text col-1 float-right"
                @click="save"
            >
              저장
            </v-btn>
            <!-- 닫기버튼 -->
            <v-btn
                color="red darken-1"
                class="white--text col-1 float-right"
                @click="close"
            >
              닫기
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-card>

      <!-- HD(현대해상) 고유키 발급 팝업(미반영 비활성화) -->
      <show-alert-popup v-if="showCodeAlert === true">
        <template v-slot:card-text>
          <span class="card-text">
            TM 고유키가 발급되었습니다.<br>
            호전환 시 TM 고유키를 입력해주세요.<br>
            <div class="code-text">
              <span>
                 <strong>{{ codeData }}</strong>
              </span>
            </div>
          </span>
        </template>
        <template v-slot:card-button>
          <v-btn
              class="check-button"
              color="blue darken-1" text outlined rounded
              @click="close">
            <span class="check-title">확인</span>
          </v-btn>
        </template>
      </show-alert-popup>

      <!-- 요청입력 저장완료 팝업 -->
      <show-alert-popup v-if="saveSuccess === true">
        <template v-slot:card-text>
          <span class="card-text">요청하신 입력이 저장되었습니다.</span>
        </template>
        <template v-slot:card-button>
          <v-btn
              class="check-button"
              color="blue darken-1" text outlined rounded
              @keydown.space="close"
              v-focus
              @click="close">
            <span class="check-title">확인</span>
          </v-btn>
        </template>
      </show-alert-popup>
    </v-flex>
  </v-overlay>
</template>

<script>

import {axiosInstance} from "@/util/AxiosModule";
import SearchArea from "@/components/Search/SearchArea";
import TableMixin from "@/mixin/TableMixin";
import _ from "lodash";
import ShowAlertPopup from "@/components/Form/ShowAlertPopup";
import CodeMixin from "@/mixin/CodeMixin";
import func from "@/util/func";

export default {
  components: {ShowAlertPopup, SearchArea},
  mixins: [TableMixin, CodeMixin],
  props: ["isShow", "rowData", "isPrvGroupTM", "consultResultList"],
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      }
    }
  },
  data() {
    return {
      data: {},
      // 상태구분값
      stateNmList: [
        { text: "상태값오류", value: "상태값오류"},
        { text: "대기", value: "WAIT"},
        { text: "진행중", value: "PROC"},
        { text: "종료", value: "DONE"},
      ],
      // 만기일자 rules
      expiryDtCheckRules: [
        v => !(v && !(/^[0-9]*$/.test(v))) || '숫자만 입력 가능합니다.',
        v => !(v && !(v.length == 8)) || v.length + '자리 입력. 입력 자리수를 맞춰주세요.',
        v => !(v && !(/^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/.test(v))) || '날짜형식 틀림',
      ],
      guideInsurerCdList: [], // 설계지원 안내가능 보험사
      preInsurerCdList: [ // 이전보험사
        {name: '현대해상', value: 'HD'},
        {name: 'DB손해보험', value: 'DB'},
        {name: 'KB손해보험', value: 'KB'},
        {name: '하나손해보험', value: 'HN'},
        {name: '흥국화재', value: 'HK'},
        {name: '캐롯손해보험', value: 'CR'},
        {name: '한화손해보험', value: 'HW'},
        {name: '롯데손해보험', value: 'LO'},
        {name: 'AXA손해보험', value: 'AXA'},
        {name: '메리츠화재', value: 'MZ'},
        {name: '삼성화재', value: 'SS'},
        {name: 'MG손해보험', value: 'MG'},
        {name: '구매예정', value: 'NEW'}
      ],
      showCodeAlert: false,
      codeData: "",
      saveSuccess: false,
      checkSave: false,
      agreeSave: false,
      modifyConsultResult: []
    }
  },
  async created() {
    this.guideInsurerCdList = await this.getInsuranceList();
    this.guideInsurerCdList.unshift({name: "선택", code: ""});
  },
  computed: {
    checkDeleteGroup() {
      return func.checkUserPrvGroup(["ADM", "DEV", "MKT"]) === "N";
    }
  },
  watch: {
    isShow() {
      this.data = _.cloneDeep(this.rowData);
      this.changeData();
    },
    consultResultList(value) {
      if (value) {
        this.modifyConsultResult = _.cloneDeep(value)
        this.modifyConsultResult = value.filter(item => item.text !== "전체");
      }
    }
  },
  methods: {
    // 닫기버튼
    close() {
      this.showCodeAlert = false;
      this.saveSuccess = false;
      this.$emit('close');
    },
    // 저장버튼
    async save() {
      let designSupTbDto = [];
      designSupTbDto = this.data;
      delete designSupTbDto.createDate;
      delete designSupTbDto.updateDate;
      designSupTbDto.consultMemo = this.data.consultMemo;
      if (designSupTbDto.hd === "없음") {
        designSupTbDto.hd = null;
      }
      if (_.isEmpty(designSupTbDto.consultMemo) || null) {
        alert("상담내용을 입력해주세요.");
        return false;
      }

      if (designSupTbDto.guideInsurerCd === designSupTbDto.preInsurerCd) {
        alert("동일한 보험사로 진행시 미지급됩니다.");
      }
      if (confirm("저장하시겠습니까?")) {
        try {
          const {data} = await axiosInstance.post('/admin/operation/saveDesignSup', designSupTbDto);
          // 현대해상/호전환 미반영 비활성화
          if (data !== "") {
            this.codeData = data;
            this.showCodeAlert = true;
          } else {
            this.saveSuccess = true;
          }
          this.$emit('seqVal', this.data.designSupId);
          this.$emit('updateSetting');
        } catch (e) {
          console.log(e);
        }
      }
    },
    // 초기화버튼
    initData() {
      this.data.consultMemo = "";
    },
    changeData() {
      if ((this.data.consultResult === "호전환") && (this.data.guideInsurerCd === "HD")) {
        this.data.hd = this.rowData.hd;
      } else {
        this.data.hd = "없음";
      }
    },
    // 일부 관리자만 사용가능
    deleteData() {
      if (confirm("삭제하시겠습니까?")) {
        let designSupId = this.rowData.designSupId;
        let inviteCd = this.$store.getters['login/userData'].inviteCd;
        // let inviteCd = "Fm0EIVAcrf";    // 테스트용 코드

        let url = `/admin/operation/cancelDesignSup?designSupId=${designSupId}&inviteCd=${inviteCd}`;
        axiosInstance.post(url)
            .then(response => {
              alert(response.data)
              this.$emit('updateSetting');
              this.close();
            })
            .catch(error => {
              console.log(error);
            });
      }
    }
  }
}
</script>

<style scoped>

.background {
  background-color: rgba(0,0,0,.05);
  border: 1px solid lightgray;
}

.th-font {
  font-size: 15px !important;
}


span.card-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
}

.code-text > span > strong {
  line-height: 80px;
  color: #1772C7;
  font-size: 35px;
}

.v-btn.check-button {
  border: 2px solid #1772C7;
  padding: 5px 10px;
  font-weight: bolder;
}
.v-btn .check-title {
  font-size: 20px;
}

.v-btn.check-button:hover {
  background-color: #1772C7;
}
.v-btn:hover .check-title {
  color: #fff;
}
</style>