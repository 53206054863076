var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"text-h6"},[_vm._v("목록 (총 "+_vm._s(_vm.data.length)+"개)")]),_c('outlined-btn',{staticClass:"float-right",attrs:{"disabled":!_vm.hasTableData},on:{"click":function($event){return _vm.$emit('toExcel')}}},[_vm._v("엑셀다운로드")])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.data,"loading":_vm.loading,"items-per-page":-1,"fixed-header":"","calculate-widths":"","show-select":"","multi-sort":"multi-sort","headers":_vm.getHeader(_vm.headers),"footer-props":{'items-per-page-options': [10, 30, 50, -1]}},on:{"click:row":_vm.getChartDetailDialog},scopedSlots:_vm._u([{key:"item.tFaCnt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.faCnt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.prem",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.salesCnt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.settleCnt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.reward",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}},{key:"item.pyReward",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"jc-fe"},[_vm._v(_vm._s(_vm.inputComma(value)))])]}}])}),_c('sales-list-dialog',{attrs:{"dialog":_vm.dialog,"monthArray":_vm.monthArray,"resData":_vm.resData,"orgName":_vm.orgName,"toYm":_vm.toYm,"fromYm":_vm.fromYm},on:{"close":function($event){_vm.dialog = false},"updateSetting":_vm.setDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }