<template>
  <v-overlay
      :light="true"
      :dark="false"
    v-model="isShow"
    class="d-flex align-center justify-center"
  >
    <slot/>
  </v-overlay>
</template>

<script>
export default {
  props: {
    isShow: Boolean
  }
}
</script>

<style scoped>

</style>