<template>
  <search-bar-layout class="mt-5">
    <template v-slot:contents>
      <search-area>
        <!-- 집계 탭 -->
        <v-row v-if="tab === 0">
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
            <v-col cols="5">기준년월</v-col>
            <v-text-field
              v-model="totalSearch.stYm"
              outlined hide-details dense
              background-color="white"
              @change="changeStYm(totalSearch.stYm)"
            />
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
            <v-col cols="4">상품군</v-col>
            <v-select
              v-model="totalSearch.prProdLineCd"
              :items="prProdLineList"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              outlined hide-details dense
              background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
            <v-col cols="5">보험유형</v-col>
            <v-select
                v-model="totalSearch.insListType"
                :items="insListTypeList"
                item-text="insListTypeNm"
                item-value="insListType"
                outlined hide-details dense
                background-color="white"
                @change="changeListType(totalSearch.insListType)"
            />
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
            <v-col cols="4">보험사</v-col>
            <v-select
                v-model="totalSearch.insCd"
                :items="insLists"
                item-text="insCdNm"
                item-value="insCd"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-end align-center col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
            <v-btn class="mr-3 blue white--text" @click="createTarget" :disabled="!isTotalSearch">대상건 재선정</v-btn>
            <v-btn class="grey white--text" @click="getSearch('total')">조회</v-btn>
          </v-col>
        </v-row>

        <!-- 건별 탭 -->
        <v-row v-if="tab === 1">
          <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
            <v-col cols="4">기준년월</v-col>
            <v-text-field
                v-model="detailSearch.stYm"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
            <v-col cols="4">상품군</v-col>
            <v-select
                v-model="detailSearch.prProdLineCd"
                :items="prProdLineList"
                item-text="prProdLineNm"
                item-value="prProdLineCd"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
            <v-col cols="4">보험사</v-col>
            <v-select
                v-model="detailSearch.insCd"
                :items="insList"
                item-text="insCdNm"
                item-value="insCd"
                outlined hide-details dense
                background-color="white"
            />
          </v-col>
            <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
              <v-col cols="4">작업상태</v-col>
              <v-radio-group v-model="detailSearch.wkStateCd" row :disabled="isTotalSearch" @change="changeWkStateCd">
                <v-radio label="진행" value="ON" />
                <v-radio label="종료" value="OFF" />
              </v-radio-group>
            </v-col>
            <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
              <v-col cols="4">작업단계</v-col>
              <v-select
                  v-model="detailSearch.wkStepCd"
                  :items="wkStepList"
                  item-text="wkStepNm"
                  item-value="wkStepCd"
                  outlined hide-details dense
                  background-color="white"
                  :disabled="isTotalSearch || detailSearch.wkStateCd === 'OFF'"
              />
            </v-col>
          <v-col class="d-flex justify-end align-center col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
            <v-btn class="mr-3 red darken-1 white--text" @click="resetDetail">초기화</v-btn>
            <v-btn class="grey white--text" @click="getSearch('detail')" :disabled="isTotalSearch">조회</v-btn>
          </v-col>
        </v-row>
      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import ProductRecommend from "@/util/ProductRecommend";
import _ from "lodash";
import store from "@/store";

export default {
  components: {SearchArea, SearchBarLayout},
  props: ["tab", "prProdLineList", "insListTypeList", "insList", "isTotalSearch", "rowData"],
  mixins: [CodeMixin, DateMixin],
  data() {
    return {
      wkStepList: [],
      totalSearch: {},
      detailSearch: {},
      defaultInsList: { insCdNm: "전체", insCd: "ALL" },
      insListType: '',
      insLists: [],
      life_list: [],
      non_list: []
    }
  },
  computed: {
    nowDate() {
      const nowDate = new Date();
      let year = nowDate.getFullYear();
      let month = nowDate.getMonth() + 1;
      return `${year}` + `${month < 10 ? 0 + "" + month : month}`;
    }
  },
  async created() {
    this.totalSearch = this.setDefaultSearch();
    this.detailSearch = this.setDefaultSearch();
    this.totalSearch.stYm = this.nowDate;
    this.detailSearch.stYm = this.nowDate;
    this.wkStepList = await ProductRecommend.getWkStepList();
    this.wkStepList.unshift({wkStepNm: "전체", wkStepCd: "ALL"});
  },
  watch: {
    rowData(value) {
      if (value) {
        let rowData = _.cloneDeep(value);
        this.detailSearch.stYm = this.totalSearch.stYm;
        this.detailSearch.prProdLineCd = rowData.prProdLineCd;
        this.detailSearch.insCd = rowData.insCd;
      }
    },
    insList(value) {
      if (value) {
        this.insLists = _.cloneDeep(value);
        this.non_list = value.filter(el => el.insCd.includes('N')); // 손
        this.life_list = value.filter(el => el.insCd.includes('L') && el.insCd !== "ALL"); // 생
      }
    }
  },
  methods: {
    changeStYm(date) {  // 변경된 기준년월 전달
      this.$emit("changeStYm", date);
    },
    changeListType(type) {  // 보험유형에 맞춰 보험사 리스트 변경(집계만 해당)
      if (type === 'N') {       // 손보
        this.insLists = this.non_list;
      }
      else if (type === 'L') {  // 생보
        this.insLists = this.life_list;
      }
      else {  // 전체 - 손보가 먼저
        this.insLists = [this.defaultInsList, ...this.non_list, ...this.life_list];
      }
      // 변경된 리스트 첫번째 보험사 세팅
      this.totalSearch.insCd = this.insLists[0].insCd;
    },
    setDefaultSearch() {
      return {
        stYm:  this.nowDate,     // 기준년월(default: 현재년월)
        prProdLineCd: "ALL",     // 상품군(default: 전체)
        insListType: "",         // 보험유형(default: 전체)
        insCd: "ALL",            // 보험사(default: 전체)
        wkStateCd: "ON",         // 작업상태(default: 진행)
        wkStepCd: "ALL",         // 작업단계(default: 전체)
        prCd: ""
      };
    },
    changeWkStateCd() {
      this.detailSearch.wkStepCd = "ALL";
    },
    getSearch(type) {
      if (type === "total") {
        const params = {
          stYm: this.totalSearch.stYm,
          prProdLineCd: this.totalSearch.prProdLineCd,
          insCd: this.totalSearch.insCd
        };
        this.$emit("search", type, params);
      } else if (type === "detail") {
        const params = {
          stYm: this.detailSearch.stYm,
          prProdLineCd: this.detailSearch.prProdLineCd,
          insCd: this.detailSearch.insCd,
          wkStateCd: this.detailSearch.wkStateCd,
          wkStepCd: this.detailSearch.wkStepCd,
          prCd: ""
        };
        this.$emit("search", type, params);
      }
    },
    createTarget() {
      const message = `${this.totalSearch.stYm.replace(/(\d{4})(\d{2})/, '$1년 $2월')}에 대한 대상건을 재선정하시겠습니까?`;
      const params = {
        stYm: this.totalSearch.stYm,
        regId: store.getters["login/memberId"]
      };
      this.$emit("createTarget", message, params);
    },
    resetDetail() {
      this.detailSearch = this.setDefaultSearch();
      this.stYm = this.nowDate;
      this.$emit("resetDetail");
    }
  }
}
</script>