<template>
  <v-card class="col-12" style="background-color: rgba(0,0,0,.03)">
    <v-row class="mx-2">
      <v-col class="d-flex align-center" cols="4">
        <v-col cols="4">상품군</v-col>
        <v-col>
          <v-select
              v-model="fieldData.mpPrProdLineCd"
              :items="mpPrProdLineList"
              item-text="mpPrProdLineNm"
              item-value="mpPrProdLineCd"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
      </v-col>
      <v-col class="d-flex align-center" cols="4">
        <v-col cols="4">보험사</v-col>
        <v-col>
          <v-select
              v-model="fieldData.insCd"
              :items="insList"
              item-text="insNm"
              item-value="insCd"
              background-color="white"
              hide-details outlined dense
              @change="getPrCdList(fieldData.insCd)"
          />
        </v-col>
      </v-col>
      <v-col class="d-flex align-center" cols="4">
        <v-col cols="5">상품종류</v-col>
        <v-col>
          <v-select
              v-model="fieldData.prProdLineCd"
              :items="prProdLineList"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mx-2">
      <v-col class="d-flex align-center" cols="4">
        <v-col cols="4">
          상품명
          <v-icon @click="showPopup('product')">mdi-land-rows-horizontal</v-icon>
        </v-col>
        <v-col>
          <v-text-field
              v-model="fieldData.prNm"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
      </v-col>
      <v-col class="d-flex align-center" cols="4">
        <v-col cols="4">상품코드</v-col>
        <v-col>
          <v-text-field
              v-model="fieldData.prCd"
              background-color="white"
              hide-details outlined dense disabled
          />
        </v-col>
      </v-col>
      <v-col class="d-flex align-center" cols="4">
        <v-col cols="5">판매일자</v-col>
        <v-col>
          <v-text-field
              v-model="fieldData.stDt"
              background-color="white"
              hide-details outlined dense disabled
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mx-2">
      <v-col class="d-flex align-center" cols="12">
        <v-col cols="1">질문</v-col>
        <v-col>
          <v-text-field
              v-model="fieldData.priQuestion"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mx-2">
      <v-col class="d-flex align-center" cols="12">
        <v-col cols="1">
          답변
          <v-icon @click="showPopup('result')">mdi-land-rows-horizontal</v-icon>
        </v-col>
        <v-col>
          <v-text-field
              v-model="fieldData.aiChat"
              background-color="white"
              hide-details outlined dense disabled
          />
        </v-col>
      </v-col>


    </v-row>

    <v-row class="mx-2">
      <v-col class="d-flex align-center" cols="4">
        <v-col cols="4">우선순위</v-col>
        <v-col>
          <v-text-field
              v-model="fieldData.dispOrder"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
      </v-col>

      <v-col class="d-flex align-center" cols="4">
        <v-col cols="4">사용여부</v-col>
        <v-col>
          <v-select
              v-model="fieldData.useYn"
              :items="useYnList"
              item-text="useYnNm"
              item-value="useYn"
              background-color="white"
              hide-details outlined dense
          />
        </v-col>
      </v-col>

      <v-col>
        <v-col class="text-right">
          <v-btn style="margin-right: 10px" @click="saveData">저장</v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import InsAiChatManagement from "@/util/InsAiChatManagement";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";

export default {
  props: ["rowData"],
  created() {
    this.mpPrProdLineList = InsAiChatManagement.initMpPrProdLineList("선택");
    this.insList = InsAiChatManagement.initInsList("선택");
  },
  data() {
    return {
      mpPrProdLineList: [
        { mpPrProdLineNm: "선택", mpPrProdLineCd: "" }
      ],
      insList: [
        { insNm: "선택", insCd: "" }
      ],
      prProdLineList: [
        { prProdLineNm: "선택", prProdLineCd: "" }
      ],
      useYnList: [
        { useYnNm: "Y", useYn: "Y" },
        { useYnNm: "N", useYn: "N" }
      ],
      fieldData: {
        mpPrProdLineCd: "",
        insCd: "",
        prProdLineCd: "",
        useYn: "Y",
        regId: store.getters["login/memberId"]
      }
    }
  },
  watch: {
    rowData(value) {
      if (value) {
        this.fieldData = {
          ...this.fieldData,
          prCd: value.prCd,
          prNm: value.prNm,
          stDt: value.stDt
        }
      }
    }
  },
  methods: {
    async getPrCdList(insCd) {
      this.prProdLineList = [
        { prProdLineNm: "선택", prProdLineCd: "" }
      ];
      if (insCd === "") {
        return false;
      } else {
        this.prProdLineList = await InsAiChatManagement.prProdLineList("L", insCd, "", "");
      }
    },
    showPopup(type) {
      if (type === "product") {
        if (this.fieldData.insCd === "") {
          alert("보험사를 선택해주세요.");
        } else {
          this.$emit("productNm", this.fieldData);
        }
      } else if (type === "result") {
        this.$emit("result", this.fieldData);
      }
    },
    async saveData() {
      if (confirm(`저장하시겠습니까?`)) {
        let aiPriQuestionTbDto = {};
        aiPriQuestionTbDto = this.fieldData
        aiPriQuestionTbDto.regNm = store.getters["login/userData"].memberNm;

        try {
          await axiosInstance.post("/admin/ai/questionSave", aiPriQuestionTbDto, {
            params: {
              wkDiv: "C"
            }
          })
          alert("데이터가 저장되었습니다.");
          this.refreshData();
        } catch (e) {
          console.log(e);
        }
      }
    },
    refreshData() {
      this.mpPrProdLineList = [
        { mpPrProdLineNm: "선택", mpPrProdLineCd: "" }
      ];
      this.insList = [
        { insNm: "선택", insCd: "" }
      ];
      this.prProdLineList = [
        { prProdLineNm: "선택", prProdLineCd: "" }
      ];
      this.useYnList = [
        { useYnNm: "Y", useYn: "Y" },
        { useYnNm: "N", useYn: "N" }
      ];
      this.fieldData = {
        mpPrProdLineCd: "",
        insCd: "",
        prProdLineCd: "",
        useYn: "Y",
        regId: store.getters["login/memberId"]
      };
      this.$emit("refreshData");
    }
  }
}
</script>