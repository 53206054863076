import Vue from 'vue'
import Vuex from 'vuex'

import login from "@/store/modules/login"
import code from "@/store/modules/code"

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const plugins = [
    createPersistedState({
        paths: [
          "login", "code"
        ],
        storage: window.sessionStorage
    })
]
export default new Vuex.Store({
    modules: {
        login,
        code
    },
    plugins
});