<template>
  <v-card-title class="pb-0 px-2">
    <span>디테일 코드 </span> <span class="total">| 총 : {{totalLength}}개</span>
    <v-btn class="primary ml-auto mr-3" @click="isShow = !isShow">추가</v-btn>
    <v-btn
        class="red white--text"
        :disabled="itemLength < 1"
        @click="$emit('delete')"
    >삭제</v-btn>
    <master-code-table-modal
        :type="type"
        :is-show="isShow"
        :is-immediately="isImmediately"
        :cd-master-id="cdMasterId"
        @close="close"
        @saveDetailCode="saveDetailCode"
        @refreshDetail="refreshDetail"
    />
  </v-card-title>
</template>

<script>
import MasterCodeTableModal from "@/pages/Manage/Code/components/MasterCode/MasterCodeTableModal";
export default {
  components: {MasterCodeTableModal},
  props: {
    type: String,
    itemLength: Number,
    totalLength: Number,
    cdMasterId: String,
    isImmediately: Boolean,
  },
  data: () => ({
    isShow: false,
  }),
  methods: {
    close() {
      this.isShow = false;
    },
    saveDetailCode(data) {
      this.$emit('saveDetailCode', data);
      this.close();
    },
    refreshDetail(detailCode) {
      this.isShow = false;
      this.$emit('refreshDetail', detailCode);
    }
  }
}
</script>

<style scoped>
.total{
  margin-left : 5px;
}

</style>