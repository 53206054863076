var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{attrs:{"height":"50vh","items":_vm.items,"items-per-page":-1,"headers":_vm.getHeader(_vm.headers) || [],"fixed-header":"","disable-sort":"","single-select":"","calculate-widths":""},on:{"dblclick:row":_vm.setModalData},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6"},[_vm._v("목록(총 "+_vm._s(_vm.items.length)+"개)")]),_c('v-btn',{staticClass:"ml-auto primary mb-1",on:{"click":function($event){return _vm.showModal(false)}}},[_vm._v("추가")])],1)]},proxy:true},{key:"item.mobileUrl",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.getUrl(value)))])]}},{key:"item.pcUrl",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.getUrl(value)))])]}},{key:"item.partner",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value === "Y" ? "제휴" : "미제휴"))])]}},{key:"item.insProductType",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.insProductType(value)))])]}},{key:"item.type",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getInsurerType(value, item.twoWheelerYn)))])]}},_vm._l((_vm.filed_YN),function(info,index){return {key:info,fn:function(ref){
var value = ref.value;
return [_c('span',{key:index},[_vm._v(_vm._s(value === "Y" ? "노출" : "미노출"))])]}}})],null,true)})],1),_c('insurer-modal',{attrs:{"is-show":_vm.isShow,"is-modify":_vm.isModify,"insurer-data":_vm.modalData},on:{"close":function($event){_vm.isShow = false},"afterModify":_vm.afterModify}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }