<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <div class="d-flex align-center col-xl-6 col-lg-8 col-md-12 col-12">
          <div class="col col-2">등록일자</div>
          <v-row class="col col-2 calender">
            <span>사용</span>
            <v-col class="d-flex align-center col-xl-9 col-lg-9 col-md-12 col-12" style="height: 35px;">
              <v-col>
                <v-checkbox v-model="useCalYn"/>
              </v-col>
            </v-col>
          </v-row>
          <date-input
              style="width:60vw;"
              :start-date="startDate"
              :end-date="endDate"
              :disabled="!useCalYn"
              :key="useCalYn"
              @updateDate="updateDate"
          />
        </div>
      </search-area>
      <!--      <v-divider class="mb-2"/>-->
      <search-area class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <field-input title="프로그램 명">
          <v-text-field
              v-model="progNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              @keypress.enter="getProgramPrivilege"
          />
        </field-input>
        <field-input title="한글 화면명">
          <v-text-field
              v-model="progNmKr"
              outlined dense hide-details
              background-color="white" menu-props="auto"
              @keypress.enter="getProgramPrivilege"
          />
        </field-input>
        <field-input title="메뉴 그룹">
          <v-select
              v-model="progMnGroup"
              :items="progMnGroupList"
              background-color="white"
              item-text="name"
              item-value="cd"
              hide-details outlined dense
          />
        </field-input>
      </search-area>

      <v-divider class="mb-2"/>

      <search-area class="d-flex align-center col-xl-12 col-lg-12 col-md-12 col-12">
        <field-input title="엑셀 등급">
          <v-select
              v-model="xlsGrade"
              :items="xlsGradeList"
              background-color="white"
              item-text="name"
              item-value="cd"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="권한 그룹">
          <v-select
              v-model="prvGroup"
              :items="prvGroupList"
              background-color="white"
              item-text="value"
              item-value="cd"
              hide-details outlined dense
          />
        </field-input>
        <field-input title="사용 여부">
          <v-select
              v-model="useYn"
              :items="useYnList"
              background-color="white"
              item-text="name"
              item-value="cd"
              hide-details outlined dense
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getProgramPrivilege"/>
    </template>
  </search-bar-layout>
</template>

<script>
import constant from "@/util/constant";
// import func from "@/util/func";
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  name: "ProgramPrivilegeSearchBar",
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, FieldInput},
  props: ["prvList"],

  data: () => ({
    startDate: "",
    endDate: "",
    useCalYn: true,
    useYn: "",        // 사용여부
    useYnList: constant.USE_YN_LIST,

    xlsGrade: "",     // 엑셀등급
    xlsGradeList: constant.XLS_GRADE_LIST,
    prvGroup: "",     // 권한 그룹
    prvGroupList: [],

    progNm: "", // 프로그램 명
    progNmKr: "", // 한글 화면명
    progMnGroup: "", // 메뉴 그룹
    progMnGroupList: constant.PROGRAM_MENU_GROUP_LIST,
  }),

  async beforeMount() {
    const {fromDate, toDate} = this.setDateDiff(-365);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  created() {
    this.prvGroupList = [{value: "전체", cd: ""}, ...this.prvList];
  },

  mounted() {
    this.getProgramPrivilege();
  },

  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },

    getProgramPrivilege() {
      const params = {
        startDate: "",
        endDate: "",
        useYn: this.useYn,
        xlsGrade: this.xlsGrade,
        prvGroup: this.prvGroup,
        // progNm: this.progNm,   // 컴포넌트 명은 여백 없이 영문으로만 구성
        progNmKr: this.progNmKr.trim(),  // 한글명은 여백 존재가능
        progMnGroup: this.progMnGroup,
      }
      if(this.useCalYn) {
        params["startDate"] = this.startDate.replaceAll('-', '');
        params["endDate"] = this.endDate.replaceAll('-', '');
      }
      if (this.progNm) params["progNm"] = this.progNm.replace(/\s/g, '');

      this.$emit('getProgramPrivilege', params);
    },

  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}

.calender {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
</style>