<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="이미지 선택">
          <v-text-field
              v-model="searchData.fileName"
              :disabled=true
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <v-btn
            class="grey white--text"
            @click="searchFile"
        >이미지 선택
        </v-btn>
        <input @input="loadData" accept="image/*" class="d-none" type="file" ref="file"/>
        <img v-if="searchData.fileString" :src="searchData.fileString" ref="img"/>
      </search-area>
    </template>
    <template v-slot:button>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
            @click="clickUpload"
            color="grey" class="white--text px-10"
        >
          처리
        </v-btn>
      </v-col>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import FieldInput from "@/components/Form/FieldInput";
import CodeMixin from "@/mixin/CodeMixin";

export default {
  mixins: [CodeMixin],
  components: {FieldInput, SearchArea, SearchBarLayout},
  data: () => ({
    selectFile:null,
    searchData: {
      fileType:"",
      fileName:"",
      fileSize:"",
      fileString:null
    }
  }),
  methods: {
    //파일을 선택
    searchFile() {
      this.$refs.file.click();
    },
    loadData({target: {files}}) {
      if (!files || files.length < 1)
      {
        return;
      }
      this.selectFile = files[0];
      if (this.selectFile.size > 1048576)
      {
        alert("파일 사이즈는 1MB 이하만 가능합니다.");
        return;
      }

      let reader = new FileReader();
      reader.onload = e => {
        this.$emit('loadImage', e.target.result);
      }
      // this.searchData.fileType = this.selectFile.type;
      this.searchData.fileName = this.selectFile.name;
      // this.searchData.fileSize = this.selectFile.size;

      reader.readAsDataURL(this.selectFile);
    },
    //백그라운드처리 요청
    async clickUpload() {
      this.$emit('search', this.selectFile);
    }
  }
}
</script>
