<template>
  <v-col cols="12" class="py-0">
    <div class="my-2">
      <span class="text-h6">목록 (총 {{ tableData.length }}개)</span>
      <v-btn @click="showModal" class="blue white--text theme--light float-right marginBtn">등록</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
        height="50vh"
        :items="tableData"
        :loading="loading"
        :items-per-page="-1"
        fixed-header calculate-widths
        :headers="headers"
        @click:row="clickContent"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
        >
        <template v-slot:item.minPrem="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.noticeFromDt="{ value }">
          {{ getDateFormat(value) }}
        </template>
        <template v-slot:item.noticeToDt="{ value }">
          {{ getDateFormat(value) }}
        </template>
      </v-data-table>
    </v-flex>
    <product-guide-modal
      :is-show="isShow"
      :detailData = "detailData"
      @close = "close"
      />
    <product-guide-update-dialog
      :show = "show"
      @update="update"
      :detailData = "detailData"
      @close = "close"
      />
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import ProductGuideModal from "./ProductGuideModal";
import ProductGuideUpdateDialog from "./ProductGuideUpdateDialog";
export default {
  components: {ProductGuideUpdateDialog, ProductGuideModal},
  mixins: [TableMixin],
  props: ["tableData", "loading"],
  data: () => ({
    headers: [{text: "보험사", value: "insCd", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상품코드", value: "prCd", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "상품명", value: "prNm", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "최소보험료", value: "minPrem", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "가이드파일명", value: "guideFileNmUrl", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "안내이미지파일명", value: "imgFileNmUrl", class: "teal lighten-4", align: "center", sortable: false, width: "10vw"},
      {text: "공지시기", value: "noticeFromDt", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "공지종기", value: "noticeToDt", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "인기상품여부", value: "popPrYn", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "추천상품여부", value: "prPrYn", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "게시계속여부", value: "displayContYn", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "인기점수", value: "popScore", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
      {text: "등록일시", value: "createDate", class: "teal lighten-4", align: "center", sortable: false, width: "8vw"},
    ],
    isShow: false,
    detailData:[],
    show:false
  }),
  methods: {
    clickContent(row) {
      this.detailData = row;
      this.show = true;
    },
    getDateFormat(date) {
      let d = date ? date.toString() : '';
      return d ? `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6)}` : '';
    },
    showModal() {
      this.isShow = true;
    },
    close() {
      this.isShow = false;
      this.show = false;
      this.detailData = [];
    },
    update() {
      this.show = false;
      this.detailData = [];
      this.$emit('update');
    }
  }
}
</script>