<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <!-- 날짜 선택 -->
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">기간</v-col>
          <v-col cols="9">
            <date-input
              :start-date="frDate"
              :end-date="toDate"
              @updateDate="updateDate"
            />
          </v-col>
        </v-col>
        <!-- 날짜 선택 -->

        <!-- 상품군 선택 -->
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="3">상품군</v-col>
          <v-col cols="7">
            <v-select
              @click="getPrProdLineList"
              v-model="prProdLineCd"
              :items="prProdLineCdList"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              background-color="white"
              dense outlined hide-details
              />
          </v-col>
        </v-col>
        <!-- 상품군 선택 -->

        <!-- 조회 버튼 -->
        <v-col class="col-xl-7 col-lg-5 col-md-6 col-12">
          <div class="float-right">
            <v-btn
                class="grey lighten-1"
                @click="getSearch"
            >조회</v-btn>
          </div>
        </v-col>
        <!-- 조회 버튼 -->
      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import ProductRecommend from "@/util/ProductRecommend";

export default {
  mixins: [DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout},
  data() {
    return {
      frDate: "",
      toDate: "",
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "전체", prProdLineCd: ""},
      ],
      isClick: false
    }
  },
  created() {
    // 기준 날짜 현재일로 부터 -30일 지정
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.frDate = fromDate;
    this.toDate = toDate;
    let startDt = this.getNoDashFormat(this.frDate);
    let endDt = this.getNoDashFormat(this.toDate);

    // 페이지 생성 시 상품군 코드 리스트 불러오기
    this.getPrProdLineList();

    // 페이지 생성 시 ProductRecommendUsageStatus.vue에 기본 조회 정보 전달
    const params = {
      startDt: startDt,
      endDt: endDt,
      prProdLineCd: this.prProdLineCd,
    }
    this.$emit("setData", params);

  },
  methods: {
    // 날짜 재선택시 다시 담기
    updateDate(values) {
      this.frDate = values[0];
      this.toDate = values[1];
    },
    // 상품군 리스트 가져오기
    async getPrProdLineList() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineCdList.unshift({
        prProdLineNm: "전체",
        prProdLineCd: ""
      })
    },
    // 조회값 전달
    getSearch() {
      this.isClick = true;

      // 날짜 데이터 2023-01-01 --> 20230101 로 변경 포멧
      let startDt = this.getNoDashFormat(this.frDate);
      let endDt = this.getNoDashFormat(this.toDate);

      // 조회 기능 전달값
      const params = {
        startDt: startDt,
        endDt: endDt,
        prProdLineCd: this.prProdLineCd,
      }
      // ProductRecommendUsageStatus.vue 에 params 전달(event 명: "getSearch")
      this.$emit("getSearch", params, this.isClick);

      this.isClick = false;
    }
  }
}
</script>