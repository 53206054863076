<template>
  <search-page-layout title="통계 제외 사용자 관리">
    <template v-slot:searchBar>
      <exc-member-search-bar
          @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <exc-member-table
          :data="data"
          :loading="loading"
          @setRow="setRow"
          @getSearch="getSearch"
      />
    </template>

    <template v-slot:inflowHist>
      <exc-member-save
          :key="saveKey"
          :rowData="rowData"
          @refresh="getSearch"
          @reset="setRow"
      />
    </template>
  </search-page-layout>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ExcMemberSearchBar from "@/pages/User/components/ExcMember/ExcMemberSearchBar";
import ExcMemberTable from "@/pages/User/components/ExcMember/ExcMemberTable";
import ExcMemberSave from "@/pages/User/components/ExcMember/ExcMemberSave";

export default {
  components: {SearchPageLayout, ExcMemberSave, ExcMemberTable, ExcMemberSearchBar},

  data() {
    return {
      loading: false,
      data: [],
      saveKey: 0,
      rowData: [],
      isShow: false,
    }
  },

  methods: {
    // 조회
    async getSearch(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.get('/admin/member/getExcMember', { params });

        this.data = data.map((el, idx) => ({
          ...el, index: idx, regDate: this.dateToString(el.regDate)
        }))

        this.setRow(null);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    setRow(row) {
      this.rowData = row;
      this.saveKey++;
    },

    dateToString(date){
      return date.replace('T', ' ').substr(0, 19);
    },

  }
}
</script>