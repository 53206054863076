<template>
  <v-layout>
    <v-card class="col-12 pa-4" style="background-color: rgba(0,0,0,.03)">
      <v-card-title>신규/변경</v-card-title>

      <v-row class="px-6">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">담보코드</v-col>
          <v-col cols="6">
            <v-text-field
                v-model="dataDto.cvrCd"
                @change="changeCvrCd"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-6 col-lg-6 col-md-6 col-12">
          <v-col cols="12">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                <v-text-field
                    v-model="dataDto.cvrNm"
                    outlined dense hide-details
                    background-color="white" menu-props="auto"
                />
                </div>
              </template>
              <span>{{ dataDto.cvrNm }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">담보기본특약구분</v-col>
          <v-col cols="6">
            <v-select
                v-model="dataDto.cvrSpDiv"
                :items="cvrSpDivList"
                item-text="cvrSpDivNm"
                item-value="cvrSpDiv"
                outlined dense hide-details
                background-color="white"
                class="subBar"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">주력담보여부</v-col>
          <v-col cols="6">
            <v-select
                v-model="dataDto.mainCvrYn"
                :items="mainCvrYnList"
                background-color="white"
                item-text="mainCvrYnNm"
                item-value="mainCvrYn"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">표시순서</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="dataDto.dispOrder"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">기본안내가입금액</v-col>
          <v-col cols="6">
            <v-text-field
                v-model="dataDto.baseAccAmt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">FR_가입한도금액</v-col>
          <v-col cols="6">
            <v-text-field
                v-model="dataDto.frAccAmt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">TO_가입한도금액</v-col>
          <v-col cols="6">
            <v-text-field
                v-model="dataDto.toAccAmt"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5">소스구분</v-col>
          <v-col cols="7">
            <v-select
                v-model="dataDto.srcType"
                :items="srcTypeList"
                background-color="white"
                item-text="srcTypeNm"
                item-value="srcType"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="7">갱신주기</v-col>
          <v-col cols="6">
            <v-text-field
                v-model="dataDto.rnwCycle"
                background-color="white"
                item-text="value"
                item-value="cd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green lighten-1"
            class="white--text col-1"
            @click="deleteData"
        >
          초기화
        </v-btn>
        <v-btn
            color="blue darken-4"
            class="white--text col-1"
            @click="saveData"
        >
          저장
        </v-btn>
        <v-btn
            color="red darken-4"
            class="white--text col-1"
            @click="close"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props:["detailRowData", "rowData"],
  data() {
    return {
      dataDto: {
        'srcType':'',
        'frAge':'',
        'toAge':'',
        'rnwCycle':'',
        'cvrCd':'',
        'cvrNm':'',
        'cvrSpDiv':'',
        'mainCvrYn':'Y',
        'dispOrder':'',
        'baseAccAmt':'',
        'frAccAmt':'',
        'toAccAmt':'',
      },
      srcTypeList: [
        {srcTypeNm: "선택", srcType: ""},
        {srcTypeNm: "원수사", srcType: "1"},
        {srcTypeNm: "수기", srcType: "2"}
      ],
      cvrSpDivList: [
        {cvrSpDivNm: "선택", cvrSpDiv: ""},
        {cvrSpDivNm: "기본", cvrSpDiv: "M1"},
        {cvrSpDivNm: "특약", cvrSpDiv: "S1"},
      ],
      mainCvrYnList: [
        {mainCvrYnNm: "Y", mainCvrYn: "Y"},
        {mainCvrYnNm: "N", mainCvrYn: "N"},
      ]
    }
  },
  watch: {
    detailRowData: function (value) {
      this.dataDto.srcType = value.srcType;
      this.dataDto.frAge = value.frAge;
      this.dataDto.toAge = value.toAge;
      this.dataDto.rnwCycle = value.rnwCycle;
      this.dataDto.cvrCd = value.cvrCd;
      this.dataDto.cvrNm = value.cvrNm;
      this.dataDto.cvrSpDiv = value.cvrSpDiv;
      this.dataDto.mainCvrYn = value.mainCvrYn;
      this.dataDto.dispOrder = value.dispOrder;
      this.dataDto.baseAccAmt = value.baseAccAmt;
      this.dataDto.frAccAmt = value.frAccAmt;
      this.dataDto.toAccAmt = value.toAccAmt;
      console.log(">>value", value);
    },
  },
  methods: {
    async changeCvrCd() {
      const params = {
        insCd: this.rowData.insCd,
        cvrCd: this.dataDto.cvrCd
      }
      try {
        const { data } = await axiosInstance.get('/private/prdata/prCvrList', { params });
        for (let i = 0; i < data.length; i++) {
          if (this.dataDto.cvrCd === data[i].cvrCd) {
            this.dataDto.cvrNm = data[i].cvrNm;

          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async saveData() {
      const prPlanDetailTbDto = {
        prProdLineCd: this.rowData.prProdLineCd,
        insCd: this.rowData.insCd,
        prCd: this.rowData.prCd,
        planCd: this.rowData.planCd,
        srcType: this.dataDto.srcType,
        frAge: this.dataDto.frAge,
        toAge: this.dataDto.toAge,
        rnwCycle: this.dataDto.rnwCycle,
        cvrCd: this.dataDto.cvrCd,
        cvrNm: this.dataDto.cvrNm,
        cvrSpDiv: this.dataDto.cvrSpDiv,
        mainCvrYn: this.dataDto.mainCvrYn,
        dispOrder: this.dataDto.dispOrder,
        baseAccAmt: this.dataDto.baseAccAmt,
        frAccAmt: this.dataDto.frAccAmt,
        toAccAmt: this.dataDto.toAccAmt,
      }
      try {
        await axiosInstance.post('/admin/prdata/prPlanDetailSave', prPlanDetailTbDto);
        alert("저장되었습니다.");
        this.dataDto = [];
      } catch (e) {
        console.log(e);
      }
    },
    deleteData() {
      this.dataDto = [];

      this.srcTypeList[0].srcTypeNm = "선택";
      this.srcTypeList[0].srcType = "";
      this.dataDto.srcTypeNm = this.srcTypeList[0].srcTypeNm;
      this.dataDto.srcType = this.srcTypeList[0].srcType;

      this.cvrSpDivList[0].cvrSpDivNm = "선택";
      this.cvrSpDivList[0].cvrSpDiv = "";
      this.dataDto.cvrSpDivNm = this.cvrSpDivList[0].cvrSpDivNm;
      this.dataDto.cvrSpDiv = this.cvrSpDivList[0].cvrSpDiv;

      this.dataDto.mainCvrYn = "Y";
    },
    close() {
      this.dataDto = [];
      this.$emit("close");
    }
  }
}
</script>

<style scoped>
.overflow {
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap !important;;
}
</style>