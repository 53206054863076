<template>
  <search-page-layout title="상품추천 비교 담보 금액">
    <template v-slot:searchBar>
      <product-sp-cvr-amt-search-bar
          @search="search"
      />
    </template>

    <template v-slot:table>
      <product-sp-cvr-amt-table
        :data="data"
        :loading="loading"
        @save="save"
        @changeRow="changeRow"
        @changeUseYn="changeUseYn"
        @getRowData="getRowData"
      />
      <product-sp-cvr-amt-detail-modal
          :key="modalKey"
          :is-show="showModal"
          :cvrData="cvrData"
          @close="closeModal"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import {axiosInstance} from "@/util/AxiosModule";
import ProductSpCvrAmtSearchBar from "@/pages/Product/components/ProductSpCvrAmt/ProductSpCvrAmtSearchBar";
import ProductSpCvrAmtTable from "@/pages/Product/components/ProductSpCvrAmt/ProductSpCvrAmtTable";
import ProductSpCvrAmtDetailModal from "@/pages/Product/components/ProductSpCvrAmt/ProductSpCvrAmtDetailModal";
import _ from "lodash";

export default {
  components: {SearchPageLayout, ProductSpCvrAmtTable, ProductSpCvrAmtSearchBar, ProductSpCvrAmtDetailModal},
  data() {
    return {
      data: [],
      params:{},
      loading: false,

      modalKey: 0,
      showModal: false,
      cvrData: {}
    }
  },
  methods: {
    async search(params) {
      this.loading = true;
      this.params = params;

      try {
        const {data} = await axiosInstance.get('/public/prdata/prSpCvrAmt', {params});
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async save() {
      this.loading = true;

      //안내건수 검증
      let flag = false;
      for (let i = 0; i < this.data.length; i++) {
        if(10 < this.data[i].stepCnt)   // if(10 < this.getStepCnt(this.data[i])) 에서 변경
        {
          flag = true;
          break;
        }
      }
      if(flag)
      {
        alert("안내개수는 최대 10건 이하로 설정해주세요.");
        this.loading = false;
        return;
      }

      const params = {
        "prProdLineCd":this.params.prProdLineCd,
        "prodType":this.params.prodType,
        "cvrType":this.params.cvrType,
        "list":this.data
      }

      try {
        const {data} = await axiosInstance.post('/public/prdata/prSpCvrAmt', params);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    changeRow(colNm, newVal, index) {
      this.data[index-1][colNm] = newVal;
      this.data[index-1]["changeYn"] = "Y";
    },
    changeUseYn(index, useYn){
      this.data[index-1]["useYn"] = useYn;
      this.data[index-1]["changeYn"] = "Y";
    },
    // 상세보기 팝업 ON
    getRowData(index){
      this.modalKey++;
      this.cvrData = _.cloneDeep(this.data[index - 1]);
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.search(this.params); // 닫기와 동시에 테이블 refresh
    }

  }
}
</script>