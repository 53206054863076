<template>
  <v-col cols="12" class="py-0">
    <div class="text-right">
      <span
          v-if="tabs === 1"
          style="margin-right: 10px; font-weight: bold; font-size: 20px;">
        단위:원</span>
      <v-btn
          v-if="tabs === 1"
          class="red white--text px-7"
          style="margin: 0 3px 5px 0;"
          :disabled="this.prData.length <= 0"
          @click="removeData"
      >삭제</v-btn>
    </div>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          v-if="tabs === 0"
          height="50vh"
          :items="cvrData"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths disable-sort
          :headers="getHeader(statusHeader)"
          @click:row="clickStatusRow"
          @dblclick:row="dbClickRow"
          hide-default-footer
      >
        <template v-slot:item.accAmtList="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.insList="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                {{ textLengthOverCut(value, 10, "...") }}
              </div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <!-- 구분 데이터 변환 -->
        <template v-slot:item.cvrDiv="{ value }">
          <div>{{ changedData(value, "1", "의무사항") }}</div>
          <div>{{ changedData(value, "2", "선택사항") }}</div>
        </template>
        <!-- 적용조건기준 데이터 변환 -->
        <template v-slot:item.condBase="{ value }">
          <div>{{ changedData(value, "1", "공통") }}</div>
          <div>{{ changedData(value, "2", "개별") }}</div>
        </template>
        <!-- 보험료합산구분 데이터 변환 -->
        <template v-slot:item.premSumType="{ value }">
          <div>{{ changedData(value, "1", "합산값") }}</div>
          <div>{{ changedData(value, "2", "최대값") }}</div>
          <div>{{ changedData(value, "3", "최소값") }}</div>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
      </v-data-table>
      <v-data-table
          v-else-if="tabs === 1"
          item-key="index"
          height="30vh"
          v-model="selectData"
          :items="prData"
          :loading="loading"
          :items-per-page="-1"
          fixed-header calculate-widths disable-sort
          :headers="getHeader(cvrHeader)"
          @click:row="clickCvrRow" show-select
      >
        <!-- 가입금액 콤마 추가 -->
        <template v-slot:item.baseAccAmt="{ value }">
          <div class="jc-fe">{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.prNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                {{ textLengthOverCut(value, 15, "...") }}
              </div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.cvrNm="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                {{ textLengthOverCut(value, 15, "...") }}
              </div>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <!-- 적용조건기준 데이터 변환 -->
        <template v-slot:item.condBase="{ value }">
          <div>{{ changedData(value, "1", "공통") }}</div>
          <div>{{ changedData(value, "2", "개별") }}</div>
        </template>
        <template v-slot:item.createDate="{ value }">
          <div>{{ getKST(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import TableMixin from "@/mixin/TableMixin";
import {PRODUCT_COMPARE_MANAGEMENT_CVR_TABLE, PRODUCT_COMPARE_MANAGEMENT_STATUS_TABLE} from "@/util/TableHeaders";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [TableMixin, DateMixin],
  props: ["tabs", "cvrData", "prData", "loading"],
  data() {
    return {
      statusHeader:PRODUCT_COMPARE_MANAGEMENT_STATUS_TABLE,
      cvrHeader:PRODUCT_COMPARE_MANAGEMENT_CVR_TABLE,
      selectData: [],
    }
  },
  methods: {
    clickStatusRow(row) {
      this.$emit("statusRow", row);
    },
    dbClickRow(row) {
      this.$emit("dbClickRow", row);
    },
    clickCvrRow(row) {
      this.$emit("cvrRow", row);
    },
    async removeData() {
      let prProdCompPrTbList = this.selectData;

      try {
        await axiosInstance.post('/admin/prdata/prProdCompPrSave', prProdCompPrTbList, {
          params: {
            jobType: "D"
          }
        });
        alert(prProdCompPrTbList.length + "개의 데이터가 삭제되었습니다")
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>