<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area >
        <field-input title="기준년월">
          <v-col cols="5">
            <v-text-field
                v-model="fromYm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
                placeholder="yyyymm"
            />
          </v-col>
        </field-input>
        <field-input title="조직구분">
          <v-col cols="5">
            <v-select
               :items="ORG_TYPE"
               background-color="white"
               v-model="orgGb"
               menu-props="auto"
               hide-details outlined dense
            />
          </v-col>
        </field-input>
        <field-input title="자료구분값">
          <v-radio-group
              v-model="occGb"
              row
          >
            <v-radio label="조직정보"  value="1"/>
            <v-radio label="FA정보" value="2"/>
          </v-radio-group>
        </field-input>
        <!-- field-input title="업로드" >
        <v-text-field style="size: 300px"
           v-model="orgdata"
           :disabled="isUploaded"
        />
        </field-input -->
        <v-btn
           class="grey white--text mr-3"
           @click="clickUpload"
        >엑셀 업로드
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-btn
            class="blue white--text mr-3"
            @click="orgBatch1"
        >상위조직매핑
          <v-icon>mdi-account-group</v-icon>
        </v-btn>
        <v-btn
            class="orange white--text mr-3"
            @click="orgBatch2"
        >가명화 작업
          <v-icon>mdi-marker</v-icon>
        </v-btn>
        <v-btn
            class="red white--text"
            @click="deleteInfo"
        >자료 삭제
          <v-icon>delete</v-icon>
        </v-btn>
     </search-area>
      <input @input="loadData" :accept="SheetJSFT" class="d-none" id="excel" type="file" ref="file"/>
    </template>

    <template v-slot:button>
      <search-btn @click="getOrgInfo"/>
    </template>

  </search-bar-layout>
</template>

<script>
import XLSX from "xlsx";
import {axiosInstance} from "@/util/AxiosModule";
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin, FieldInput],
  props: ["isUploaded"],
  components: { SearchArea, SearchBarLayout, FieldInput},
  async beforeMount() {
    this.fromYm = '';
    this.orgGb = '10';
  },
  async created() {
    this.ORG_TYPE = await this.getSelectList("ORGINFO_SELECT_TYPE");
  },
  data: () => ({
    ORG_TYPE: [],
    fromYm: "",
    yyyymm: "",
    orgNm: "",
    orgCd: "",
    upOrgCd: "",
    orgGb: "",
    occGb: "1",
    orgdata: "",
    SheetJSFT: ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"].map(x => "." + x).join(",")
  }),

  methods: {

    getOrgInfo() {
      const params = {
        fromYm : this.fromYm.replace(/[^0-9]/gi, "")
      }
      if (this.orgGb) params["orgGb"] = this.orgGb;
      this.$emit('getOrgInfo', params)
    },
    // upload excel
    async upload(data) {
      if (!data) {
        alert("파일을 다시 확인해주세요.");
        this.initData();
      } else {
        this.tableData = data;
        this.$emit('upload', data);
      }
    },
    loadData({target: {files}}) {
      if (!files || files.length < 1) alert("올바른 파일이 아닙니다.");
      else this._setReader().readAsBinaryString(files[0]);
    },
    _setReader() {
      const reader = new FileReader();
      reader.onload = ({target: {result}}) => {
        const {SheetNames, Sheets} = XLSX.read(result, {type: 'binary'});
        //업로드 구분값 엑셀자료 레이아웃 분기( 조직/FA )
        if(this.occGb == '1'){
        Sheets[SheetNames].A1.w = "yyyymm"
        Sheets[SheetNames].B1.w = "orgCd"
        Sheets[SheetNames].C1.w = "orgGb"
        Sheets[SheetNames].D1.w = "orgNm"
        Sheets[SheetNames].E1.w = "upOrgCd"
        Sheets[SheetNames].F1.w = "upOrgNm"
        }else{
        Sheets[SheetNames].A1.w = "yyyymm"
        Sheets[SheetNames].B1.w = "orgCd"
        Sheets[SheetNames].C1.w = "orgNm"
        Sheets[SheetNames].D1.w = "deptCd"
        Sheets[SheetNames].E1.w = "ssno7"
        Sheets[SheetNames].F1.w = "fireRegDate"
        Sheets[SheetNames].G1.w = "lifeRegDate"
        Sheets[SheetNames].H1.w = "closeYn"

        }
        const data = XLSX.utils.sheet_to_json(Sheets[SheetNames]);
        //JSON.stringify(data).replace(/[^0-9]/gi)

        this.upload(data);
        if(this.occGb == '1') {
          this.putOrgInfo(data);
        }else{
          this.putFaInfo(data);
        }
      };
      return reader;
    },
    // 업로드 버튼 클릭시 input file
    clickUpload() {
      if(this.occGb == null) {
        alert("자료구분값을 선택하세요.")
      }else {
        this.$refs.file.click();
      }
    },
    // 상위조직 매핑
    orgBatch1() {
      const params = {
        fromYm : this.fromYm.replace(/[^0-9]/gi, ""),
        jobStep : "1",
      }
      this.$emit('orgBatch1', params);
    },
    // 가명화 작업
    orgBatch2() {
      const params = {
        fromYm : this.fromYm.replace(/[^0-9]/gi, ""),
        jobStep : "2",
      }
      this.$emit('orgBatch2', params);
    },
    // 조직정보 input file
    async putOrgInfo(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.post('/admin/operation/orginfo-excel',  params);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    // FA정보 input file
    async putFaInfo(params) {
      this.loading = true;

      try {
        const {data} = await axiosInstance.post('/admin/operation/fainfo-excel',  params);
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    deleteInfo() {
      const params = {
        fromYm : this.fromYm.replace(/[^0-9]/gi, ""),
      }
      this.$emit('deleteInfo', params);
      }
    },
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>