<template>
  <search-page-layout title="상품플랜 관리">
    <template v-slot:searchBar>
      <product-plan-management-search-bar
        @getSearch="getSearch"
      />
    </template>

    <template v-slot:table>
      <product-plan-management-table
        @row="row"
        :data="data"
        :loading="loading"
      />
    </template>

    <template v-slot:inflowHist>
      <product-plan-management-save
        :rowData="rowData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductPlanManagementSearchBar
  from "@/pages/Product/components/ProductPlanManagement/ProductPlanManagementSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import ProductPlanManagementTable from "@/pages/Product/components/ProductPlanManagement/ProductPlanManagementTable";
import ProductPlanManagementSave from "@/pages/Product/components/ProductPlanManagement/ProductPlanManagementSave";

export default {
  components: {ProductPlanManagementSave, ProductPlanManagementTable, ProductPlanManagementSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      rowData: [],
    }
  },
  methods: {
    async getSearch(params) {
      this.loading = true;

      try {
        const { data } = await axiosInstance.get('/private/prdata/prPlanList', { params });
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].createDate = this.data[i].createDate.replace('T', ' ').substr(0, 19);
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    row(row) {
      this.rowData = row;
    }
  }
}
</script>