<template>
  <v-overlay
      v-model="show"
      :light="true"
      :dark="false"
  >
<div  style="	max-height: 500px; width: 100%; background: rgba(0,0,0,0.5); position:relative; z-index:2; padding: 20px;" v-if="viewdialog == true">
  <div style="width: 100%; background: white;  border-radius: 8px;  padding: 20px; overflow:auto;">
      <div v-html="infoData.contents">
      </div>
  </div>
  <div style=" width: 100%; text-align: right;">
          <v-btn color="red darken-4" class="white--text col-2" @click="viewdialog=false"          >
            닫기
          </v-btn>
  </div>
</div>  
<div style="position:relative; left:10px; top:-200px; z-index:1;">
    <v-flex>
      <v-card max-width="70vw" max-height="150vh" class="overflow-y-auto modalBackground" >
        <v-card-title class="headline blue-grey--text">
          <span class="font-weight-bold">니즈환기 자료 </span>
        </v-card-title>
        <v-card-text>
            <v-row class="px-6 mb-2">
              <span class="text-h6 black--text font-weight-bold">기본정보</span>
            </v-row>            
            <v-row class="px-6">
              <v-col class="d-flex flex-column col-4">
                <span class="subtitle-1">등록번호</span>
                <span class="subtitle-1"><b> {{ infoData.needsRaiseDataSeq }} </b></span>
              </v-col>              
              <v-col class="d-flex flex-column col-4">
                <span class="subtitle-1">담보그룹명</span>
              <v-select
                  v-model="infoData.coverageGroupSeq"
                  :items="coverSt"
                  outlined dense
                  background-color="white"
                  item-text="cname"
                  item-value="cvalue"
              />
              </v-col>
              <v-col class="d-flex flex-column col-4">
                <span class="subtitle-1">적용연령대</span>
              <v-select
                  v-model="infoData.ageGroupCd"
                  :items="ageGroupSt"
                  outlined dense
                  background-color="white"
                  item-text="gname"
                  item-value="gvalue"
              />
              </v-col>
            </v-row>              
            <v-row class="px-6">
              <v-col class="d-flex flex-column col-4">
                <span class="subtitle-1">사용용도</span>
              <v-select
                  v-model="infoData.purposeCd"
                  :items="purposeSt"
                  background-color="white"
                  outlined dense
                  item-text="uname"
                  item-value="uvalue"
              />
             </v-col>
              <v-col class="d-flex flex-column col-4">
                <span class="subtitle-1">성별</span>
              <v-select
                  v-model="infoData.sex"
                  :items="sexSt"
                  background-color="white"
                  outlined dense
                  item-text="sname"
                  item-value="svalue"
              />
             </v-col>
              <v-col class="d-flex flex-column col-4">
                <span class="subtitle-1">사용여부</span>
              <v-select
                  v-model="infoData.useYn"
                  :items="useYnSt"
                  background-color="white"
                  outlined dense
                  item-text="yname"
                  item-value="yvalue"
              />
             </v-col>             
            </v-row>
            <v-row class="px-6 mb-2">
              <v-col class="d-flex flex-column col-4">
              <span class="text-h6 black--text font-weight-bold">HTML 등록</span>
              </v-col>
              <v-col class="d-flex flex-column col-2">
                 <v-spacer></v-spacer>                
              </v-col>
              <v-col class="d-flex flex-column col-3">
                 <v-spacer></v-spacer>                
                 <v-btn
                    @click="viewdialog = true"
                 > HTML 확인
                 </v-btn>                
              </v-col>              
              <v-col class="d-flex flex-column col-3">
                 <v-spacer></v-spacer>                
                 <v-btn
                    @click="htmlEditor"
                 > HTML Editor 열기
                 </v-btn>                
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col class="d-flex flex-column col-12">
              <v-textarea
                  v-model="infoData.contents"
                  background-color="white"
                  outlined dense
                  rows="6"
              />
             </v-col>
            </v-row>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="upsubmit"
          > 수정
          </v-btn>
          <v-btn
              color="blue darken-4"
              class="white--text col-2"
              @click="delsubmit"
          > 삭제
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="show=false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>  
  </div>
  </v-overlay>
</template>
<script>
import {axiosInstance} from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import StringMixin from "@/mixin/StringMixin";
import store from "@/store";
import _ from "lodash";

export default {
  mixins: [CodeMixin, StringMixin],
  props: ["dialog", "isModify", "infoRow"],
  data: () => ({
    inputClass: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12',
    regNo: "등록번호",
    viewdialog: false,
    purposeSt: [
      { uname:'간지사용',  uvalue:'1' },
      { uname:'개별사용',  uvalue:'2' },
      { uname:'상품안내',  uvalue:'3' }
    ],
    ageGroupSt: [
      { gname:'전체',     gvalue:'AL' },
      { gname:'0~9세',    gvalue:'0T' },
      { gname:'10~14세',  gvalue:'1A' },
      { gname:'15~19세',  gvalue:'1B' },
      { gname:'20~24세',  gvalue:'2A' },
      { gname:'25~29세',  gvalue:'2B' },
      { gname:'30~34세',  gvalue:'3A' },
      { gname:'35~39세',  gvalue:'3B' },
      { gname:'40~44세',  gvalue:'4A' },
      { gname:'45~49세',  gvalue:'4B' },
      { gname:'50~54세',  gvalue:'5A' },
      { gname:'55~59세',  gvalue:'5B' },
      { gname:'60~64세',  gvalue:'6A' },
      { gname:'65세이상', gvalue:'6U' }
    ],
    sexSt: [
      { sname:'전체',     svalue:'0' },
      { sname:'남자',     svalue:'1' },
      { sname:'여자',     svalue:'2' }
    ],
    coverSt: [
      { cname:'사망',     cvalue: 1 },
      { cname:'후유장해', cvalue: 2 },
      { cname:'3대질병',  cvalue: 3 },
      { cname:'수술',     cvalue: 4 },
      { cname:'실손',     cvalue: 5 },
      { cname:'LTC간병',  cvalue: 6 },
      { cname:'생활(운전자,치아,화재,기타)',  cvalue: 7 },
      { cname:'일당',     cvalue: 8 }
    ],
    useYnSt: [
      { yname:'사용',     yvalue:'Y' },
      { yname:'미사용',   yvalue:'N' }
    ],


   // needsRaiseDataSeq: this.infoRow.needsRaiseDataSeq,
   // searchType1 : this.infoRow.coverageGroupCd,
   // searchType3 : this.infoRow.purposeCd,
   // searchType4 : this.infoRow.sex,
   // searchType5 : "1",
   // contents : this.infoRow.contents,

    searchType0: 0,
    searchType1: 1,
    searchType2: "1",
    searchType3: "AL",
    searchType4: "0",
    searchType5: "1",
     infoData: { 
            "contents":"",
            "coverageGroupSeq":0,
            "coverageGroupNm":"",
            "fileType":"",
            "sex":"0",
            "sexNm":"전체",
            "regId":"",
            "regNm":"",
            "purposeCd":"1",
            "purposeNm":"간지사용",
            "ageGroupCd":"AL",
            "ageGroupNm":"전체",
            "updateDate":"",
            "needsRaiseDataSeq": 0
		},
     initData: { 
            "contents":"",
            "coverageGroupSeq":0,
            "coverageGroupNm":"",
            "fileType":"",
            "sex":"0",
            "sexNm":"전체",
            "regId":"",
            "regNm":"",
            "purposeCd":"1",
            "purposeNm":"간지사용",
            "ageGroupCd":"AL",
            "ageGroupNm":"전체",
            "updateDate":"",
            "needsRaiseDataSeq":0
		}		
  }),

  computed: {

			show: {
				get() {
					return this.dialog
				},
				set(value) {
					this.$emit("close", value)
				},
      },
    
 },  
  watch: {  
    infoRow: function(obj) {
      if(_.isEmpty(obj)) {
        this.infoData = _.cloneDeep(this.initData);
      } else {
        this.infoData = obj;
       }
    }    
  },
  methods: {
      htmlEditor: function () {   
          window.open("https://html5-editor.net/", "_blank");    
      },    
    async upsubmit() {
        confirm(`자료를 수정하시겠습니까?`) && await this.updateContents();
    },        
    async updateContents() {
      try {
        let params = {}; 
        params.needsRaiseDataSeq  = this.infoData.needsRaiseDataSeq;
        params.coverageGroupSeq = this.infoData.coverageGroupSeq;
        params.purposeCd = this.infoData.purposeCd;
        params.ageGroupCd = this.infoData.ageGroupCd;
        params.sex = this.infoData.sex;
        params.contents = this.infoData.contents;
        params.useYn = this.infoData.useYn;
        params.regId = store.getters['login/memberId'];
        try {
          await axiosInstance.put('/admin/operation/needsinfo', params);
          console.log(params);
        } catch (e) {
          console.log(e);
        }               

        this.$emit('updateSetting');
        alert("수정 완료 !!");
				this.$emit("close", false)
      } catch (e) {
        console.log(e);
      }
    },

    async delsubmit() {
        confirm(`자료를 삭제하시겠습니까?`) && await this.deleteContents();
    },        
    async deleteContents() {
      try {
        let params = {};
        params.needsRaiseDataSeq  = this.infoData.needsRaiseDataSeq;
        console.log("delete needsRaiseDataSeq : ", params);       
         try {
           await axiosInstance.delete('/admin/operation/needsinfo', {params});
           console.log(this.needsRaiseDataSeq);
         } catch (e) {
           console.log(e);
         }               
        this.$emit('updateSetting');
        alert("삭제 완료 !!");
				this.$emit("close", false)
      } catch (e) {
        console.log(e);
      }
},
    //async modifyInsurer() {
    //  try {
    //    await axiosInstance.put('/admin/operation/needsinfo', this.params);
    //    this.$emit('afterModify');
    //    this.$emit('close');
    //  } catch (e) {
    //    console.log(e);
    //  }
    //},
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>