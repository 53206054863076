<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          :items="items"
          :headers="getHeader(PARTNER_MANAGE_HEADER) || []"
          @dblclick:row="getPartnerDialog"
          :items-per-page="-1"
          height="50vh" fixed-header
          disable-sort single-select calculate-widths
      >
        <template v-slot:top>
          <v-col cols="12" class="d-flex pa-0">
            <span class="text-h6">목록(총 {{ items.length }}개)</span>
            <v-btn class="ml-auto primary mb-1" @click="showCreateModal">추가</v-btn>
          </v-col>
        </template>

        <template v-slot:item.representTelNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>
        <template v-slot:item.partnerChargeTelNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>
        <template v-slot:item.settlementType="{ value }">
          {{ searchByCode(PARTNER_SETTLEMENT_TYPE, value) }}
        </template>

      </v-data-table>

      <partner-modal
        :is-show="isShow"
        :is-modify="isModify"
        :partner-data="modalData"
        :PARTNER_SETTLEMENT_TYPE="PARTNER_SETTLEMENT_TYPE"
        @close="isShow = false"
      />
    </v-flex>
  </v-col>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import CodeMixin from "@/mixin/CodeMixin";
import PartnerModal from "@/pages/User/components/PartnerSearch/PartnerModal";
import {PARTNER_MANAGE_HEADER} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";


export default {
  mixins: [TableMixin ,CodeMixin],
  components: {PartnerModal},
  props: {
    items: Array
  },
  data: () => ({
    isShow: false,
    isModify: false,
    PARTNER_SETTLEMENT_TYPE: [],
    PARTNER_MANAGE_HEADER: PARTNER_MANAGE_HEADER,
    editableItem: "",
    backupItems: [],
    backupItemsId: [],
    member: {},
    modalData: undefined,
  }),
  async created() {
    this.PARTNER_SETTLEMENT_TYPE = await this.getSelectList('PARTNER_SETTLEMENT_TYPE');
  },
  methods: {
    async getPartnerDialog(evt, { item : { memberId }}) {
      let response;

      try {
        response = await axiosInstance.get('/admin/operation/business-member/detail-info', {
          params: { memberId: memberId }
        });
      } catch (e) {
        console.log(e);
      }

      this.isModify = true;
      this.modalData = response.data;
      this.isShow = true;
    },
    showCreateModal() {
      this.modalData = {};
      this.isModify = false;
      this.isShow = true;
    }
  }
}
</script>

<style scoped>

</style>