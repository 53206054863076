<template>
  <search-layout title="오픈뱅킹 관리">
    <template v-slot:searchBar>
      <search-bar @search="search" @resetData="resetData"  />
    </template>

    <template v-slot:table>
      <v-col cols="12" class="py-0">
        <v-col cols="12" class="d-flex pa-0">
          <span class="text-h6 font-weight-bold">검색 결과</span>
        </v-col>
          <v-col cols="12">
            <v-textarea
                solo
                rows="17"
                v-model="data"
                :readonly="true"
                :disabled="true"
            />
          </v-col>

      </v-col>
    </template>
  </search-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchLayout from "@/layouts/SearchPageLayout";
import SearchBar from "@/pages/Manage/Banking/components/SearchCode/SearchBar";

export default {
  components: {
    SearchBar,
    SearchLayout
  },
  computed: {
  },
  async created() {
  },
  data: () => ({
    data: "",
    condition: "ALL"
  }),
  methods: {
    async search(params) {

      let api = "";
      let isGet = true;
      if(params.type == "REG")
      {
        if(this.isEmpty(params.clientId))
        {
          alert("입력값을 확인해 주세요.");
          return false;
        }
        api = "/admin/operation/kftcAuthorize";
      }
      else if(params.type == "TOKEN")
      {
        if(this.isEmpty(params.clientId) || this.isEmpty(params.clientSecret))
        {
          alert("입력값을 확인해 주세요.");
          return false;
        }
        api = "/admin/operation/kftcToken";
      }
      else if(params.type == "REAL_NAME")
      {
        if(this.isEmpty(params.bankNm) || params.bankNm == "은행선택")
        {
          alert("은행을 선택해주세요.");
          return false;
        }
        if(this.isEmpty(params.bankAccount) || this.isEmpty(params.memberNm) || this.isEmpty(params.ssnoFront))
        {
          alert("입력값을 확인해 주세요.");
          return false;
        }
        api = "/public/accountVerification";
        isGet = false;
      }
      else
      {
        return false;
      }

      if (isGet)
      {
        const { data } = await axiosInstance.get(api, {params});
        this.data = data;
      }
      else
      {
        const { data } = await axiosInstance.post(api, params);
        this.data = data;
      }

      //회원인증등록인경우 팝업 호출이 필요함.
      if(params.type == "REG")
      {
        if(window.open(this.data, "_blank") == null)
        {
          alert("팝업 오픈에 실패했습니다.\n브라우저 팝업 오픈 설정을 확인해주세요.");
        }
      }
    },
    resetData(){
      this.data = "";
    },
    isEmpty(data){
      return (data == null || data == undefined || data == "");
    }
  }
}
</script>

<style scoped>

</style>