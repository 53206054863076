<template>
  <!-- 타이틀 부분 -->
  <search-page-layout title="상품추천 사용자 계정 등록현황">
    <!-- 조회 컴포넌트 -->
    <template v-slot:searchBar>
      <product-recommendation-account-status-search-bar
          @getSearch="getSearch"
      />
    </template>

    <!-- 목록 컴포넌트 -->
    <template v-slot:table>
      <product-recommendation-account-status-table
          :loading="loading"
          :totalData="totalData"
          :targetData="targetData"
      />
    </template>

  </search-page-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import ProductRecommendationAccountStatusSearchBar
  from "@/pages/Product/components/ProductRecommendationAccountStatus/ProductRecommendationAccountStatusSearchBar";
import ProductRecommendationAccountStatusTable
  from "@/pages/Product/components/ProductRecommendationAccountStatus/ProductRecommendationAccountStatusTable";

export default {
  components: {
    ProductRecommendationAccountStatusTable,
    ProductRecommendationAccountStatusSearchBar, SearchPageLayout
  },
  data() {
    return {
      loading: false,
      totalData: [],
      targetData: [],

    }
  },
  methods: {
    // 조회 API
    async getSearch(params) {
      // 로딩 시작
      this.loading = true;
      let axiosData;

      try {
        axiosData = await axiosInstance.get(`/admin/operation/prInsIdSts`, {
          params: {
            frDate: params.frDate,
            toDate: params.toDate,
            selType: 1,
          }
        });
        const { data } = axiosData;
          let allData = {};
          for (let index in data) {
            for (let key in data[index]) {
              if (key === "stsDt") {
                allData[key] = "합계"
              } else {
                if (allData[key] == null) {
                  allData[key] = data[index][key];
                } else {
                  allData[key] += data[index][key];
                }
              }
            }
          }
          data.unshift(allData);
          this.totalData = data;
      } catch (e) {
        console.log(e);
      }

      try {
        const {data} = await axiosInstance.get(`/admin/operation/prInsIdSts`, {
          params: {
            frDate: params.frDate,
            toDate: params.toDate,
            selType: 2,
          }
        });
        this.targetData = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
}
</script>