<template>
  <v-col cols="12" class="d-flex justify-center">
    <v-btn
        @click="$emit('click')"
        :disabled="disabled"
        color="grey" class="white--text px-10"
    >
      조회
    </v-btn>
  </v-col>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
}
</script>