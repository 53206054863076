<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-3 col-md-6 col-12">
          <v-col cols="6">상품군</v-col>
          <v-select
            @click="getPrProdLineCd"
            v-model="prProdLineCd"
            :items="prProdLineCdList"
            item-text="prProdLineNm"
            item-value="prProdLineCd"
            outlined dense hide-details
            background-color="white" menu-props="auto" class="subBar"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-3 col-md-6 col-12">
          <v-col cols="6">등록여부</v-col>
          <v-select
              v-model="regYn"
              :items="regYnList"
              item-text="regYnName"
              item-value="regYn"
              outlined dense hide-details
              background-color="white" menu-props="auto" class="subBar"
          />
        </v-col>
      </search-area>
    </template>

    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBtn from "@/components/Search/SearchBtn";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      regYn: "Y",
      regYnList: [
        {regYnName: "Y", regYn: "Y"},
        {regYnName: "N", regYn: "N"},
      ],
    }
  },
  methods: {
    async getPrProdLineCd() {
      try {
        const { data } = await axiosInstance.get('/public/prdata/prProdLineNmList', {
          params: {
            srhType: "2",
            insDiv: "A",
          }
        });
        this.prProdLineCdList = data;
      } catch (e) {
        console.log(e);
      }
    },
    getSearch() {
      const params = {
        prProdCd: this.prProdLineCd,
        regYn: this.regYn,
      }
      this.$emit("getSearch", params);
    }
  }
}
</script>