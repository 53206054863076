<template>
  <search-page-layout title="보험사 담보 매핑현황">
    <template v-slot:searchBar>
      <ins-cvr-mapping-status-search-bar
        @getSearch="getSearch"
        @updateData="updateData"
        :data="data"
        :selectData="selectData"
      />
    </template>

    <template v-slot:table>
      <ins-cvr-mapping-status-table
        :data="data"
        :loading="loading"
        @row="row"
        @select="select"
      />
    </template>
  </search-page-layout>
</template>
<script>
import _ from "lodash";
import SearchPageLayout from "@/layouts/SearchPageLayout";
import InsCvrMappingStatusSearchBar from "@/pages/Product/components/InsCvrMappingStatus/InsCvrMappingStatusSearchBar";
import {axiosInstance} from "@/util/AxiosModule";
import InsCvrMappingStatusTable from "@/pages/Product/components/InsCvrMappingStatus/InsCvrMappingStatusTable";

export default {
  components: {InsCvrMappingStatusTable, InsCvrMappingStatusSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      rowData: [],
      selectData: [],
      paramsData: [],
    }
  },
  methods: {
    async getSearch(params) {
      this.loading = true;
      this.paramsData = params;
      let prIn = params;

      try {
        const { data } = await axiosInstance.post('/admin/prdata/prCreCvrCdPremMappList', prIn);

        let idx = 0;
        for (const item of data) {item["index"] = idx++;}
        this.data = data;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    row(row) {
      this.rowData = row;
    },
    select(data) {
      this.selectData = data;
    },
    updateData() {
      if (_.isEmpty(this.paramsData)) return;
      this.getSearch(this.paramsData);
      this.selectData = [];
    }
  }
}
</script>