<template>
  <v-flex xl-12 lg-12 md-12 sm-12 xs-12 class="px-5">
    <v-row class="align-center">
      <!-- title 이 있을때만 출력 -->
      <v-col class="col-xl-1 col-lg-12 col-md-12 col-sm-12 col-12 text-no-wrap" v-if="title">
        <span class="font-weight-black">{{ title }}</span>
      </v-col>
      <slot/>
    </v-row>
  </v-flex>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>