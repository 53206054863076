export default {
	methods : {
		replaceOnlyNum(str) {
			return str.replace(/[^0-9]/gi, "");
		},
		getUrl(url) {
			if(!url) return "";
			return url.length > 30 ? `${url.slice(0,30)}...` : url
		},
	}
}