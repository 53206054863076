<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-12">
          <v-col cols="5">조직레벨</v-col>
          <v-col cols="7">
            <v-select
                v-model="orgLevel"
                :items="orgLevelList"
                item-text="orgLevelNm"
                item-value="orgLevel"
                dense hide-details outlined
                background-color="white"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="3">사용일자</v-col>
          <v-col cols="9">
            <date-input
                :start-date="fromDate"
                :end-date="toDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-4 col-lg-4 col-md-6 col-12">
          <v-col cols="3">조회범위</v-col>
          <v-col cols="12">
            <v-radio-group v-model="useFlag" row>
              <v-radio label="실적발생분" value="1"/>
              <v-radio label="미실적발생분포함" value="0"/>
            </v-radio-group>
          </v-col>
        </v-col>
        <v-col class="col-xl-3 col-lg-1 col-md-6 col-12">
          <div class="float-right">
            <v-btn
                class="searchBtn grey lighten-1"
                @click="getSearch"
            >조회</v-btn>
          </div>
        </v-col>
      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-31);
    this.fromDate = fromDate;
    this.toDate = toDate;
  },
  data() {
    return {
      orgLevel: "1",
      orgLevelList: [
        {orgLevelNm: "부문총괄", orgLevel: "1"},
        {orgLevelNm: "부문", orgLevel: "2"},
        {orgLevelNm: "총괄", orgLevel: "3"},
        {orgLevelNm: "소속", orgLevel: "4"},
        {orgLevelNm: "파트너", orgLevel: "5"},
        {orgLevelNm: "부서", orgLevel: "6"},
        {orgLevelNm: "전체", orgLevel: "7"},
      ],
      fromDate: "",
      toDate: "",
      useFlag: "1",
    }
  },
  methods: {
    updateDate(values) {
      this.fromDate = values[0];
      this.toDate = values[1];
    },
    getSearch() {
      const params = {
        orgLevel: this.orgLevel,
        frDt: this.fromDate,
        toDt: this.toDate,
        useFlag: this.useFlag,
      }
      this.$emit("getSearch", params);
    }
  }
}
</script>