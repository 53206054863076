<template>
  <v-col cols="12" class="py-3 px-3">
    <!-- 엑셀다운로드 -->
    <div class="mb-2 text-right">
      <outlined-btn @click="toExcel" :disabled="!hasTableData">엑셀다운로드</outlined-btn>
    </div>

    <v-flex xs12 sm12 lg12 xl12>
      <v-data-table
        height="45vh"
        :items="data"
        :loading="loading"
        :headers="getHeader(headers)"
        fixed-header calculate-widths
        :items-per-page="-1"
        :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.joinCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.userCnt="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.lineCntTot="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.lineCnt1="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.lineCnt2="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.lineCnt3="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.lineCnt4="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.lineCnt5="{ value }">
          <div>{{ inputComma(value) }}</div>
        </template>
        <template v-slot:item.telNum="{ value }">
          <div>{{ getTelNum(value) }}</div>
        </template>
      </v-data-table>
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn";
import {PRODUCT_RECOMMEND_USAGE_OPERATION_STATUS_TABLE} from "@/util/TableHeaders";
import _ from "lodash";
import func from "@/util/func";

export default {
  mixins: [TableMixin],
  props: ["data", "loading"],
  components: {OutlinedBtn},
  data() {
    return {
      headers: PRODUCT_RECOMMEND_USAGE_OPERATION_STATUS_TABLE,
    }
  },
  computed: {
    hasTableData() {
      return this.data.length > 0;
    }
  },
  methods: {
    toExcel() {
      if (_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      for (const item of data) {
        const obj = {
          "부문총괄": item["bmcOrgNm"],
          "부문": item["bmOrgNm"],
          "총괄": item["cgOrgNm"],
          "소속": item["belongNm"],
          "파트너": item["partnerNm"],
          "부서": item["deptNm"],
          "FA명": item["orgNm"],
          "사번": item["orgCd"],
          "연락처": item["telNum"],
          "회원가입수": item["joinCnt"],
          "사용자수": item["userCnt"],
          "사용자건수합계": item["lineCntTot"],
          "암": item["lineCnt1"],
          "통합": item["lineCnt2"],
          "운전자": item["lineCnt3"],
          "자녀": item["lineCnt4"],
          "유병자": item["lineCnt5"],
        }
        returnData.push(obj);
      }

      const sheetName = `장기 상품추천시스템 조직별 사용현황`;
      const fileName = `장기 상품추천시스템 조직별 사용현황`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>

<style scoped>
.v-data-table::v-deep tr:nth-child(1) {
  background-color: #98c0c3;
}
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>