<template>
  <v-col cols="12" class="py-0">
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          fixed-header
          height="50vh"
          disable-sort
          single-select
          calculate-widths
          :items="items"
          :headers="getHeader(headers) || []"
      >
        <template v-slot:item.menuBtn="{}">
          <v-btn color="primary" block>수정</v-btn>
        </template>

        <template v-slot:item.adminBtn="{ item }">
          <v-btn block color="primary" v-if="item.isAdmin === 'N'">추가</v-btn>
          <v-btn block color="secondary" v-else>삭제</v-btn>
        </template>

      </v-data-table>

    </v-flex>
  </v-col>
</template>

<script>
export default {
  props: {
    items: Array,
    headers: Array,
  },
  data: () => ({
    onDialog: true,
    editableItem: "",
    backupItems: [],
    backupItemsId: [],
  }),
  methods: {
    getHeader(headers) {
      return headers.map(header => ({
        text: header[0],
        value: header[1],
        align: 'center',
        class: 'headers text-no-wrap',
        divider: true,
        fixed: true,
      }))
    },
  }
}
</script>

<style scoped>

</style>